import { parseInt } from 'lodash';
import { IAccessoryTableData } from '../../accessory-management/interfaces/accessory-data';
import { IConfigurationTableData } from '../../configuration-management';

export class OrderUtils {
    /**
     * Build a map of { ID: Order } of the given data.
     * This will be used in the server to validate if the user's client is up to date with the database
     *
     * @param data loaded on the screen
     * @param orderParam the order para of the data
     */
    static getOrderValidationMap(data: any[], orderParam: string): IOrderMap {
        const result: any = {};
        data.forEach((item) => {
            if (item.id) {
                result[item.id] = item[orderParam];
            }
        });
        return result;
    }

    /**
     * Build a map of { ID: Order } of the configuration data.
     * This will be used in the server to validate if the user's client is up to date with the database
     *
     * @param data loaded on the screen
     */
    static getOrderValidationMapForConfiguration(data: IConfigurationTableData[]): IOrderMap {
        const result: any = {};
        data.forEach((item) => {
            if (item['CONFIGURATION_ORDER'].value) {
                result[item['CONFIGURATION_ORDER'].id as any] = parseInt(
                    item['CONFIGURATION_ORDER'].value
                );
            }
        });
        return result;
    }

    /**
     * Build a map of { ID: Order } of the accessory data.
     * This will be used in the server to validate if the user's client is up to date with the database
     *
     * @param data loaded on the screen
     */
    static getOrderValidationMapForAccessory(data: IAccessoryTableData[]): IOrderMap {
        const result: any = {};
        data.forEach((item) => {
            if (item.ACCESSORY_ORDER.value) {
                result[item.ACCESSORY_ORDER.id as any] = parseInt(item.ACCESSORY_ORDER.value);
            }
        });
        return result;
    }

    /**
     * This function fixes configuration orders and re-sorts data. It should be called after a drag'n'drop successful save
     *
     * @param originalOrder original order of the moved item
     * @param newOrder new configuration order position
     * @param data array to fix orders
     */
    static updateDataOrdersFromConfiguration(
        originalOrder: number,
        newOrder: number,
        data: IConfigurationTableData[]
    ): IConfigurationTableData[] {
        const wasMovedUp = originalOrder > newOrder;
        if (wasMovedUp) {
            for (let i = newOrder - 1; i < originalOrder; i++) {
                const order = i + 1;
                data[i]['CONFIGURATION_ORDER'].value = order.toString();
                data[i]['CONFIGURATION_ORDER'].displayValue = order.toString();
            }
        } else {
            for (let i = originalOrder - 1; i < newOrder; i++) {
                const order = i + 1;
                data[i]['CONFIGURATION_ORDER'].value = order.toString();
                data[i]['CONFIGURATION_ORDER'].displayValue = order.toString();
            }
        }
        data[originalOrder - 1]['CONFIGURATION_ORDER'].displayValue = originalOrder.toString();
        data[originalOrder - 1]['CONFIGURATION_ORDER'].value = originalOrder.toString();

        data.sort((itemA, itemB) => {
            const firstValue = parseInt(itemA['CONFIGURATION_ORDER'].value);
            const secondValue = parseInt(itemB['CONFIGURATION_ORDER'].value);
            return firstValue - secondValue;
        });
        return data;
    }

    /**
     * This function fixes accessory orders and re-sorts data. It should be called after a drag'n'drop successful save
     *
     * @param originalOrder original order of the moved item
     * @param newOrder new accessory order position
     * @param data array to fix orders
     */
    static updateDataOrdersFromAccessory(
        originalOrder: number,
        newOrder: number,
        data: IAccessoryTableData[]
    ): IAccessoryTableData[] {
        const wasMovedUp = originalOrder > newOrder;
        if (wasMovedUp) {
            for (let i = newOrder - 1; i < originalOrder; i++) {
                const order = i + 1;
                data[i].ACCESSORY_ORDER.value = order.toString();
                data[i].ACCESSORY_ORDER.displayValue = order.toString();
            }
        } else {
            for (let i = originalOrder - 1; i < newOrder; i++) {
                const order = i + 1;
                data[i].ACCESSORY_ORDER.value = order.toString();
                data[i].ACCESSORY_ORDER.displayValue = order.toString();
            }
        }
        data[originalOrder - 1].ACCESSORY_ORDER.displayValue = originalOrder.toString();
        data[originalOrder - 1].ACCESSORY_ORDER.value = originalOrder.toString();

        data.sort((itemA, itemB) => {
            const firstValue = parseInt(itemA.ACCESSORY_ORDER.value);
            const secondValue = parseInt(itemB.ACCESSORY_ORDER.value);
            return firstValue - secondValue;
        });
        return data;
    }

    /**
     * This function fixes orders and re-sorts data. It should be called after a drag'n'drop successful save
     *
     * @param originalOrder original order of the moved item
     * @param updated updated item
     * @param data array to fix orders
     * @param orderParam the order para of the data
     */
    static updateDataOrders(
        originalOrder: number,
        updated: any,
        data: any[],
        orderParam: string
    ): void {
        const newOrder = updated[orderParam];
        const wasMovedUp = originalOrder > newOrder;
        if (wasMovedUp) {
            for (let i = newOrder - 1; i < originalOrder; i++) {
                data[i][orderParam] += 1;
            }
        } else {
            for (let i = originalOrder; i < newOrder; i++) {
                data[i][orderParam] -= 1;
            }
        }
        data[originalOrder - 1][orderParam] = newOrder;

        data.sort((itemA, itemB) => itemA[orderParam] - itemB[orderParam]);
    }


    /**
     * This function update attr orders data by index list.
     * @param data array data
     * @param orderParam the order para of the data
     */
    static updateDataOrdersByIndex(
        data: any[],
        orderParam: string
    ): void {
        data.forEach((value, index)=> {
            value[orderParam] = index+1;
        });
    }


    /**
     * This function update attr orders data by index newOrderList.
     * @param data array data
     * @param newOrderList array data with id and order
     * @param idParam the idParam para of the data
     * @param orderParam the order para of the data
     */
    static updateDataOrdersNewOrder(
        data: any[],
        newOrderList: any[],
        idParam: string,
        orderParam: string
    ) {
        newOrderList.forEach((newOrder)=> {
            const index = data.findIndex(e=> e[idParam]==newOrder.id);
            if (index>-1){
                data[index][orderParam]= newOrder[orderParam];
            }
        });

        return data;
    }

    /**
     * This function fixes orders and re-sorts data to be used after multiple items have been deleted
     *
     * @param data array to fix orders
     * @param orderParam the order para of the data
     */
    static fixOrdersAfterMassDelete(data: any[], orderParam: string): void {
        data.sort((itemA, itemB) => itemA[orderParam] - itemB[orderParam]);
        let order = 0;
        data.forEach((item) => (item[orderParam] = ++order));
    }

    /**
     * This function fixes orders and re-sorts the configuration data to be used after multiple items have been deleted
     *
     * @param data array to fix orders
     * @param orderParam the order para of the data
     */
    static fixOrdersAfterMassiveDeleteConfiguration(data: IConfigurationTableData[]): void {
        data.sort((itemA, itemB) => {
            const firstValue = parseInt(itemA['CONFIGURATION_ORDER'].value);
            const secondValue = parseInt(itemB['CONFIGURATION_ORDER'].value);
            return firstValue - secondValue;
        });
        const size = data.length;
        for (let index = 0; index < size; index++) {
            const newOrder = index + 1;
            data[index]['CONFIGURATION_ORDER'].value = newOrder.toString();
            data[index]['CONFIGURATION_ORDER'].displayValue = newOrder.toString();
        }
        OrderUtils.getOrderValidationMapForConfiguration(data);
    }

    /**
     * This function fixes orders and re-sorts the accessory data to be used after multiple items have been deleted
     *
     * @param data array to fix orders
     * @param orderParam the order para of the data
     */
    static fixOrdersAfterMassiveDeleteAccessory(data: IAccessoryTableData[]): void {
        data.sort((itemA, itemB) => {
            const firstValue = parseInt(itemA.ACCESSORY_ORDER.value);
            const secondValue = parseInt(itemB.ACCESSORY_ORDER.value);
            return firstValue - secondValue;
        });
        const size = data.length;
        for (let index = 0; index < size; index++) {
            const newOrder = index + 1;
            data[index].ACCESSORY_ORDER.value = newOrder.toString();
            data[index].ACCESSORY_ORDER.displayValue = newOrder.toString();
        }
        OrderUtils.getOrderValidationMapForAccessory(data);
    }
}

export interface IOrderMap {
    [id: number]: number;
}
