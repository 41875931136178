import { ITeamManagementI18n } from './team-management-i18n.interface';

export const TEAM_MANAGEMENT: ITeamManagementI18n = {
    TITLE: 'Team Management',
    TABLE: {
        NAME: 'Team Alias',
        DEPARTMENT: 'Department',
        BUSINESS_TYPE: 'Business Type',
        SCOPE_LABELS: 'Scope Labels',
        GROUP: 'Group',
        RULE_SET: 'Rule Set',
        CDC_LOCATION: 'CDC Location',
        FINAL_RECIPIENT: 'Final Recipient',
        REQUEST_OWNER: 'Request Owner',
        NAME_AUTOMATION: 'Name Automation',
        SHIP_TO_LOCATION: 'Ship to Location',
        TEAM_ORDER: 'Team Order',
        TEAM_INFORMATION: 'Team Information',
        TEAM_CURRENT_REQUESTS: 'Current Unit Requests',
        TEAM_PORTABLE_REQUESTS: 'Current Portable Requests',
        TEAM_BOARD_REQUESTS: 'Current Board Requests',
        TEAM_CURRENT_ACCESSORIES_REQUESTS: 'Current Acc Requests',
        TOTAL_REQUESTS: 'Total',
        TOTAL_REQUESTS_PORTABLE: 'Total Portable',
        TOTAL_REQUESTS_BOARDS: 'Total Boards',
        TOTAL_ACC_REQUESTS: 'Total Accessories'
    },
    DIALOG: {
        CREATE_TITLE: 'Creating a Team',
        CLONE_TITLE: 'Clone a Team Association',
        EDIT_TITLE: 'Editing the Team',
        EDIT_ASSOCIATION_TITLE: 'Editing the Team Association',
        EDIT_MASSIVE_TITLE: 'Editing the Team(s)',
        CLONE_MASSIVE_TITLE: 'Cloning the Team(s)',
        MESSAGE: {
            SAVED: 'Team successfully saved',
            UPDATE: 'Team successfully updated',
            ALREADY_EXIST: 'Team already exists',
            CAN_NOT_LABEL: '<b>You can not add a label using: ; , / \' ` " ´ . \\</b>',
            OUTDATED_ORDERS_EDIT:
                'Cannot update the order, the loaded data is outdated. Please refresh the page to change the order.',
            OUTDATED_ORDERS_CREATE:
                'Cannot set an order different from last, the data displayed is outdated. ' +
                'Please set order as last or refresh the page.'
        },
        TEAM_ORDER_HINT:
            'The team being {{ operation }} will be placed directly <strong>after</strong> the one selected.',
        CREATED: 'created',
        EDITED: 'edited',
        RESET_ORDER_EDIT: 'Reset Order',
        RESET_ORDER_CREATE: 'Set to Last',
        REFRESH_TEAMS: 'Refresh teams if you wish to change the original order',
        TEAMS_TO_DISSASOCIATION: 'Teams that will be deleted.',
        TEAM_ALIAS_CURRENT: 'Current Team Alias',
        TEAM_ALIAS_NEW: 'New Team Alias',
        TEAM_ID: 'Id #'

    },
    DIALOG_PROPAGATION: {
        BUTTON_NOT_UPDATE_ASSOCIATIONS: 'Update Team Management only',
        BUTTON_UPDATE_ASSOCIATIONS: 'Update Associations',
        EDIT_VALUE_TITLE: 'Do you want to propagate these values to BuildPlan?',
        EDIT_VALUE_BODY: 'Do you want to propagate these values to BuildPlan?',
        NO_BP_ASSOCIATIONS_FOUND: 'No Build Plan associated values found (including their aliases)',
        NO_TEAM_ASSOCIATIONS_FOUND: 'No Team Management Aliases associated to this value',
        RENAME_TEAM_MANAGEMENT_ALIAS: 'Rename all aliases for ',
        PROPAGATE_TEAM_MANAGEMENT_ALIAS: 'Propagate all values for ',
        RENAME_TEAMS_MANAGEMENT_ALIAS: 'Rename all aliases for the selected teams',
        PROPAGATE_TEAMS_MANAGEMENT_ALIAS: 'Propagate all values for the selected teams',
        TOOLTIP_BTN_UPDATE_TEAM_ONLY:
            'Updates this team management, without updating the Build Plan that may use this value',
        TOOLTIP_BTN_UPDATE_ASSOCIATIONS:
            'Updates the value from this team management, and updates the Build Plan Teams you\'ve checked above',
        TOOLTIP_TEAM_BTN_UPDATE_TEAM_ONLY:
            'Updates this team management, without updating the Build Plan that may use this value',
        TOOLTIP_TEAM_BTN_UPDATE_ASSOCIATIONS:
            'Updates the value from this team management, and updates the Build Plan Teams and the Team Management aliases you\'ve checked above',
        BP_TEAM_VALUE_ASSOCIATIONS: 'The following Build Plans associated values can be updated',
        BP_TEAM_VALUE_ALIAS: 'Their aliases will be updated as well.'
    },
    COMMON: {
        BUTTONS: {
            NEW_BUTTON: 'New Team',
            EDIT_TEAMS: 'Edit {{amount}} Teams(s)'
        },
        TOOLTIPS: {
            EDIT_BUTTON: 'Edit Team'
        },
        HINTS: {
            TEAM_ORDER: 'The team will be inserted in the position that you selected.'
        },
        CONTINUE_DELETE: 'Continue - Delete Scopes',
        CONTINUE_KEEP_TEAM: 'Continue - Keep Scopes',
        DELETE_TITLE: 'Do you want to delete this Team?',
        DELETE_CONTENT: 'This action will delete: {{name}} and could not be undone.',
        WARNING_TOOLTIP: 'The team is already being used and cannot be deleted.',
        DELETE_TOOLTIP: 'Delete Team',
        MESSAGES: {
            ORDER_CHANGING: 'Updating Team order <i class="ml-1 fas fa-spinner fa-spin"></i>',
            ORDER_CHANGE_ERROR:
                'Failed to update team order, the data shown on the screen is outdated. ' +
                'Please refresh before performing the operation.',
            ORDER_CHANGE_SUCCESS: 'Team order successfully updated',
            ORDER_CREATE_ERROR:
                'Cannot set team order to a position different than last, the data shown on the screen is outdated. ' +
                'Please refresh the data or set team order to last.'
        },
        REFRESH_TEAMS: 'Refresh teams',
        CLONE_TEAM: 'Clone team',
        EDIT_TEAM: 'Edit team association',
    },
    BUILD_PLAN_ASSOCIATION: {
        DELETE_WARN_DIALOG_BLOCK:
        ' <strong>Action not permitted <span class="text-danger">{{amount}}</span> team association has item edited for CDC.\n' +
        'For more details see below',
        DELETE_WARN_DIALOG:
        'This action will <strong>delete <span class="text-danger">{{amount}}</span> team association and the <span class="text-danger"> {{totalRequests}}</span> requests of\n' +
        '<span class="text-primary">{{internalProductName}}</span></strong> Build Plan and could not be undone. For more details see below',
        DELETE_WARN:
            'This action will <strong>delete <span class="text-danger">{{amount}}</span> team association and the <span class="text-danger"> {{totalRequests}}</span> proto requests of\n' +
            '<span class="text-primary">{{internalProductName}}</span></strong> Build Plan and could not be undone.',
        DELETE_WARN_ACCESSORY:
            'This action will <strong>delete <span class="text-danger">{{amount}}</span> team association and the <span class="text-danger"> {{totalRequests}}</span> accessory requests of\n' +
            '<span class="text-primary">{{internalProductName}}</span></strong> Build Plan and could not be undone.',
        CONFIRMATION_DELETE_DIALOG_TITLE: 'Delete {{amount}} Team(s) Association',
        CONFIRMATION_EDIT_DIALOG_TITLE: 'Edit {{amount}} Team(s) Association',
        CONFIRMATION_CLONE_DIALOG_TITLE: 'Clone {{amount}} Team(s) Association',
        SEARCH: 'Search team by name',
        ADD_TEAM_TO_BUILD_PLAN: 'Add Team to Build Plan',
        SUCCESSFUL_DELETED: 'Teams Association successfully deleted',
        ADD_TEAM_ASSOCIATION: 'Add Team Association',
        CLEAN_TEAMS_TOOLTIP: 'Disassociate Teams Without Requests',
        CLEAN_TEAMS: 'Clean Teams',
        REBUILD: 'Rebuild',
        REBUILD_TOOLTIP: 'Delete All Teams and Copy from Template',
        TEAM_ALREADY_ASSOCIATED: '',
        SEARCH_TEAM: 'Search by team',
        DELETE_SCOPES_SAME_LABELS:
            'Theses Scope(s) has same scope labels from Team(s). Do you want <b class="text-danger">delete</b> them?',
        DELETE_TEAMS_SCOPE_LABELS:
            'Scope Labels from Team(s) selected to <b class="text-danger">deletion<b/>.',
        ADD_SCOPES_SAME_LABELS:
            'There are some Scopes that are not in the build plan with the same scope labels, do you want to insert them?'
    },
    DIALOG_REBUILD: {
        TITLE: 'Rebuild - Build Plan Associations',
        DESCRIPTION:
            'Are you sure? All current team associations will be deleted and all template teams will be copied here.'
    }
};
