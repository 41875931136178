export enum EConfigurationStatusIcon {
    NEW = 'new_releases',
    OPEN = 'brightness_1',
    LOCKED = 'lock',
    BUILDING = 'local_shipping',
    APPROVED = 'done',
    MANUFACTURED = 'store',
    IN_TRANSIT = 'flight_takeoff',
    COMPLETED = 'check',
    ARCHIVED = 'history',
    CANCELED = 'cancel'
}

export enum EAccessoryStatusIcon {
    NEW = 'new_releases',
    OPEN = 'brightness_1',
    LOCKED = 'lock',
    READY_TO_DELIVER = 'done_all',
    IN_TRANSIT = 'flight_takeoff',
    COMPLETED = 'check',
    CANCELED = 'cancel'
}

export type EAccessoryStatusIconKeys = keyof typeof EAccessoryStatusIcon;
export type EConfigurationStatusIconKeys = keyof typeof EConfigurationStatusIcon;
