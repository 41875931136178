// Angular
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// RxJS
import { Observable, of } from 'rxjs';
// Interfaces
import { IRoleGalvinUsers } from '../interfaces/role-galvin-users.interface';
import { IGalvinUserPermission } from '../interfaces/galvin-user-permission.interface';
import { IGalvinUser } from '../../../auth/_interfaces/galvin-user.interface';
//Enviroments
import { environment } from '../../../../../environments/environment';
//Enums
import { ERolesBuildPlan } from '../../../auth/_enums/roles-build-plan.enum';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class PermissionManagementService {
    private readonly API_PERMISSION_URL = `${environment.baseUrlApi}/build-plan/permission-management/`;
    private readonly API_GALVIN_USER_URL = `${environment.baseUrlApi}/galvin/users`;
    constructor(private http: HttpClient) {}

    /**
     * Get All Roles
     * @return {TRole[]}
     */
    getAllRoles(): Observable<ERolesBuildPlan[]> {
        return this.http
            .get<any>(`${this.API_PERMISSION_URL}roles`)
            .pipe(map((res) => res.content));
    }

    /**
     * Get Galvin User By Role
     * @param role
     * @return {role:string, users:User[]}
     */
    getAllUsersByRole(role: ERolesBuildPlan): Observable<IRoleGalvinUsers> {
        const paramRole = encodeURIComponent(role);
        const url = `${this.API_PERMISSION_URL}role/users?role=${paramRole}`;
        return this.http.get<any>(url).pipe(map((res) => res.content));
    }

    /**
     * Get All Galvin Users
     * @return {IGalvinUser[]}
     */
    getAllGalvinUsers(includeExternal = false): Observable<IGalvinUser[]> {
        return this.http
            .get<any>(`${this.API_GALVIN_USER_URL}?includeExternal=${includeExternal}`)
            .pipe(map((res) => res.content));
    }

    /**
     * Return Galvin User without a role
     * @param role ERolesBuildPlan
     * @return {IGalvinUser[]}
     */
    getGalvinUsersNotInRole(
        role: ERolesBuildPlan,
        includeExternal = false
    ): Observable<IGalvinUser[]> {
        return this.http
            .get<any>(
                `${this.API_GALVIN_USER_URL}/not-in-role/${role}?includeExternal=${includeExternal}`
            )
            .pipe(map((res) => res.content));
    }

    /**
     * Get All Users from cloud sql
     * @return {IGalvinUser[]}
     */
    getAllUsers(): Observable<IGalvinUser[]> {
        return this.http
            .get<any>(`${this.API_GALVIN_USER_URL}/all-users`)
            .pipe(map((res) => res.content));
    }

    /**
     * Add Role to User
     * @param user {IGalvinUserPermission}
     * @return {IGalvinUserPermission}
     */
    addRoleToUser(user: IGalvinUserPermission): Observable<IGalvinUserPermission> {
        /**
         * By Default Interface User:
         * Roles is Array but Request Body requirement change to string
         * So:
         */
        const newUser: any = {
            email: user.email,
            role: user.roles![0]
        };

        return this.http
            .post<any>(`${this.API_PERMISSION_URL}user/role`, newUser)
            .pipe(map((res) => res.content));
    }

    /**
     * Remove Role to Galvin User
     * @param role ERolesBuildPlan
     * @param idUser Number
     */
    removeRoleToUser(role: ERolesBuildPlan, emailUser: string): Observable<any> {
        const paramRoleName = encodeURIComponent(role);
        const url = `${this.API_PERMISSION_URL}user/${emailUser}/role/${paramRoleName}`;

        return this.http.delete(url).pipe(map((res: any) => res.content));
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
            // eslint-disable-next-line no-console
            console.error(error);

            return of(result as T);
        };
    }
}
