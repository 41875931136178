<div ngbDropdown container="body" #dropDown="ngbDropdown" placement="bottom-right" autoClose="outside"
    class="kt-header__topbar-item">
    <div ngbDropdownToggle class="kt-header__topbar-wrapper">
        <sup [@insertRemoveItem] *ngIf="lengthDescription">{{lengthDescription}}</sup>
        <span class="kt-header__topbar-icon" [class.kt-pulse.kt-pulse--brand]="pulse"
            [class.kt-pulse--light]="pulseLight" [class.kt-header__topbar-icon--success]="iconType === 'success'">
            <i *ngIf="!useSVG" [ngClass]="icon"></i>
            <span *ngIf="useSVG" class="kt-svg-icon" [class.kt-svg-icon--success]="iconType === 'success'"
                [inlineSVG]="icon"></span>
            <span class="kt-pulse__ring" [hidden]="!pulse"></span>
        </span>
    </div>

    <div ngbDropdownMenu
        class="dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-lg">
        <form>
            <!--begin: Head -->
            <div class="kt-head kt-head--skin-{{skin}} kt-head--fit-x kt-head--fit-b"
                [ngStyle]="{'background-image': backGroundStyle() }">
                <h3 class="kt-head__title">
                    User Notifications&nbsp;
                    <span class="btn btn-{{ type }} btn-sm btn-bold btn-font-md">
                        {{newNotifications.length}} new
                    </span>
                </h3>
                <ul ktTabClickEvent
                    class="nav nav-tabs nav-tabs-line nav-tabs-bold nav-tabs-line-3x nav-tabs-line-{{ type }} kt-notification-item-padding-x"
                    role="tablist">
                    <li class="nav-item">
                        <a (click)="tab.select('tab-id-1')" class="nav-link active show" data-toggle="tab" href="#"
                            role="tab" aria-selected="true">Notifications</a>
                    </li>
                </ul>
            </div>
            <!--end: Head -->

            <div ngbNav #tab="ngbNav">
                <div ngbNavItem="tab-id-1">
                    <ng-template ngbNavContent>
                        <div [perfectScrollbar]="{wheelPropagation: false}"
                            [ngStyle]="{'max-height': '40vh', 'position': 'relative'}"
                            class="kt-notification kt-margin-t-10 kt-margin-b-10">
                            <p *ngIf="loading">
                                <galvin-progress-spinner></galvin-progress-spinner>
                            </p>
                            <p class="kt-align-center" *ngIf="!hasNotification && !loading">
                                {{'NOTIFICATION.NO_CONTENT' | translate}}
                            </p>
                            <ng-container *ngFor="let notification of notifications">
                                <a [@insertRemoveItem] [attr.id]="notification.actionId"
                                    (click)="onOpen(notification, contentTmpl)"
                                    class="kt-notification__item notification-item"
                                    [class.kt-notification__item--dismissing]="dismissing.isSelected(notification.id)"
                                    [class.kt-notification__item--read]="seen.isSelected(notification.id)">
                                    <div class="kt-notification__item-icon">
                                        <i class="galvin-icon-configuration-history"></i>
                                    </div>
                                    <div class="kt-notification__item-details pl-3">
                                        <div class="kt-notification__item-title">
                                            {{notification.summary}}
                                        </div>
                                        <div class="kt-notification__item-time">
                                            {{notification.createdDate | timeAgo }}
                                        </div>
                                    </div>
                                    <button mat-icon-button ngbTooltip="Dismiss"
                                        (click)="onDismissNotification(notification)" container="body"
                                        class="mat-icon-button kt-notification__item-close notification-item-close">
                                        <i class="material-icons">close</i>
                                    </button>
                                </a>
                            </ng-container>
                            <div class="ps__rail-x" style="left: 0px; bottom: 0px;">
                                <div class="ps__thumb-x" tabindex="0" style="left: 0px; width: 0px;"></div>
                            </div>
                            <div class="ps__rail-y" style="top: 0px; right: 0px;">
                                <div class="ps__thumb-y" tabindex="0" style="top: 0px; height: 0px;"></div>
                            </div>
                        </div>
                    </ng-template>
                </div>
            </div>
            <div [ngbNavOutlet]="tab"></div>
            <button class="btn w-100 primary-text" (click)="dropDown.close()"
                routerLink="/build-plan/notifications">{{'COMMON.SHOW_ALL' | translate}}</button>
        </form>
    </div>
</div>

<!-- TODO remove this dialog after implement the notification screen -->
<ng-template #contentTmpl>
    <div matDialogTitle class="d-flex justify-content-between">
        <h2>{{opened.summary}}</h2>
        <button class="btn btn-clean btn-sm btn-icon btn-icon-md" placement="bottom" content="body" mat-dialog-close
            ngbTooltip="{{'COMMON.CLOSE_DIALOG' | translate}}" type="button">
            <i class="la la-times"></i>
        </button>
    </div>
    <ng-container *ngIf="loadingContent; else content">
        <galvin-progress-spinner></galvin-progress-spinner>
    </ng-container>
    <ng-template #content>
        <mat-dialog-content>
            <div style="overflow: auto"
                [innerHTML]="sanitizer.bypassSecurityTrustHtml(opened.content || opened.summary)"></div>
        </mat-dialog-content>
    </ng-template>
    <mat-dialog-actions align="end">
        <button mat-button color="primary" class="galvin-btn-primary" container="body"
            ngbTooltip="{{'NOTIFICATION.PREVIOUS' | translate}}" [disabled]="!hasPrevious()"
            (click)="loadPreviousNotification()"><i class="material-icons">chevron_left</i></button>
        <button mat-button class="galvin-btn-primary" color="primary" container="body"
            ngbTooltip="{{'NOTIFICATION.NEXT' | translate}}" [disabled]="!hasNext()" (click)="loadNextNotification()"><i
                class="material-icons">chevron_right</i></button>
        <button mat-button class="galvin-btn-secondary"
            mat-dialog-close="dismiss">{{'NOTIFICATION.DISMISS' | translate}}</button>
    </mat-dialog-actions>
</ng-template>