import { TranslateService } from '@ngx-translate/core';
// Angular
import { Pipe, PipeTransform } from '@angular/core';

/**
 * Returns quantity from array request
 */
@Pipe({
    name: 'yesNo'
})
export class yesNoPipe implements PipeTransform {
    /**
     * Pipe Constructor
     *
     * @param _i18n: TranslateService
     */
    constructor(protected _i18n: TranslateService) {}

    /**
     * Transform
     *
     * @param value: boolean or string
     */
    transform(value: boolean | string | undefined): string {
        if (typeof value === 'string') {
            value = value === 'true';
        }

        if (value) {
            return this._i18n.instant('COMMON.BUTTONS.YES');
        }
        return this._i18n.instant('COMMON.BUTTONS.NO');
    }
}
