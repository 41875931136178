import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { checkNullPipe } from './../../../../core/_base/layout/pipes/check-null.pipe';
import { ParseDatePipe } from './../../../../core/_base/layout/pipes/parse-date.pipe';
import { ParseDateTimePipe } from './../../../../core/_base/layout/pipes/parse-datetime.pipe';
import { BuildPlanInformationComponent } from './build-plan-information.component';

@NgModule({
    declarations: [BuildPlanInformationComponent],
    exports: [BuildPlanInformationComponent],
    imports: [CommonModule, NgbTooltipModule, TranslateModule.forChild()],
    providers: [checkNullPipe, ParseDatePipe, ParseDateTimePipe]
})
export class BuildPlanInformationModule {}
