import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { IConfigurationI18n } from '@galvin/core/build-plan/configuration-management/i18n/configuration-i18n.interface';
import { IPrototypeRequestConfiguration } from '@galvin/core/build-plan/prototype-request-management/interfaces/prototype-request-configuration.interface';
import { TranslateService } from '@ngx-translate/core';
import { yesNoPipe } from './../../../../../core/_base/layout/pipes/yes-or-no.pipe';

@Component({
    selector: 'config-status-details-request',
    templateUrl: './configuration-status-details.component.html',
    styleUrls: ['./configuration-status-details.component.scss'],
    animations: [
        trigger('detailExpand', [
            state(
                'collapsed',
                style({ visibility: 'hidden', height: '0px', opacity: 0, overflow: 'hidden' })
            ),
            state(
                'expanded',
                style({
                    height: 'auto',
                    visibility: 'visible'
                })
            ),
            transition('expanded <=> collapsed', animate('225ms ease'))
        ])
    ]
})
export class ConfigStatusDetailsRequestComponent implements OnInit {
    @Input() configuration!: IPrototypeRequestConfiguration;
    expanded = true;
    labels!: IConfigurationI18n;
    items: {
        label: string;
        value: any;
        id: string;
    }[] = [];
    dates: {
        label: string;
        value: any;
        id: string;
    }[] = [];
    constructor(private i18n: TranslateService, private yesNo: yesNoPipe) {}

    ngOnInit(): void {
        this.labels = this.i18n.instant('BUILD_PLAN.CONFIGURATION');

        this.items.push({
            label: this.labels.SWIMLANES.CONFIGURATION_STATUS_DETAIL.HIDDEN,
            value: this.yesNo.transform(this.configuration.isHidden),
            id: 'is-hidden'
        });
        this.items.push({
            label: this.labels.SWIMLANES.CONFIGURATION_STATUS_DETAIL.ARCHIVED,
            value: this.yesNo.transform(this.configuration.isArchived),
            id: 'is-archived'
        });
        this.items.push({
            label: this.labels.SWIMLANES.CONFIGURATION_STATUS_DETAIL.TEMPORARY,
            value: this.yesNo.transform(this.configuration.isTemporary),
            id: 'is-temporary'
        });
        this.items.push({
            label: this.labels.SWIMLANES.CONFIGURATION_STATUS_DETAIL.ALREADY_OPENED,
            value: this.yesNo.transform(this.configuration.isOpened),
            id: 'is-opened'
        });
        this.dates.push({
            label: this.labels.SWIMLANES.CONFIGURATION_STATUS_DETAIL.TEMPORARY_DISABLE_DATE,
            value: this.configuration.temporaryDisableDate,
            id: 'temporary-disable-date'
        });
        this.dates.push({
            label: this.labels.SWIMLANES.CONFIGURATION_STATUS_DETAIL.OPENED_DATE,
            value: this.configuration.openedDate,
            id: 'opened-date'
        });
        this.dates.push({
            label: this.labels.SWIMLANES.CONFIGURATION_STATUS_DETAIL.DUE_DATE,
            value: this.configuration.dueDate,
            id: 'due-date'
        });
    }

    getId(id: string, type: 'param' | 'value'): string {
        return `${id}-${type}`;
    }
}
