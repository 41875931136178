import { Directive, Input, TemplateRef } from '@angular/core';

@Directive({
    selector: '[galvinTreeNodeTmp]'
})
export class GalvinTreeNodeTmpDirective {
    @Input() galvinTreeNodeTmpLevel!: any;

    constructor(public templateRef: TemplateRef<any>) {}
}
