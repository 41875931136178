import { SelectionModel } from '@angular/cdk/collections';
import { CdkDragDrop } from '@angular/cdk/drag-drop';

export class MultiSelect {
    // Put ".selected-drag" on elements when clicking after longPress or Ctrl+Click
    // Initial Variables
    longPressTime = 500; // in ms unit
    verifyLongPress = 0;
    multiSelect = false;
    verifyDragStarted = false;
    ctrlMode = false;
    firstContainer = null as unknown as HTMLElement;
    selectedDrag: SelectionModel<number>;
    singleDragIndex!: number;

    constructor() {
        this.selectedDrag = new SelectionModel<number>(true);
    }

    selectDrag(el: HTMLElement): HTMLElement {
        while (!el.classList.contains('cdk-drag')) {
            el = el.parentElement as HTMLElement;
        }
        return el;
    }

    mouseDown(e: Event, index: number): void {
        const ctrlKey = (e as KeyboardEvent).ctrlKey;
        // If using CTRL
        if (ctrlKey) {
            this.ctrlMode = true;
            this.multiSelect = true;
            if (this.selectedDrag.isSelected(index)) {
                this.selectedDrag.deselect(index);
            } else {
                this.selectedDrag.select(index);
            }
        }
        if (this.selectedDrag.isEmpty()) {
            this.multiSelect = false;
        }
    }

    dragStarted(index: number): void {
        this.singleDragIndex = index;
        this.verifyDragStarted = true;
        clearTimeout(this.verifyLongPress);
    }

    dragEnded(): void {
        this.verifyDragStarted = false;
    }

    dropListDropped(e: CdkDragDrop<string[]>): void {
        const el = e.item.element.nativeElement;
        if (el.classList.contains('selected-drag')) {
            this.multiSelect = false;
        }
    }
}
