import { animate, state, style, transition, trigger } from '@angular/animations';
import { SelectionModel } from '@angular/cdk/collections';
import { Component, ElementRef, HostBinding, Input } from '@angular/core';

@Component({
    selector: 'gvn-toolbar',
    template: '<ng-content></ng-content>',
    styleUrls: ['./toolbar.component.scss'],
    animations: [
        trigger('slideInOut', [
            state(
                'opened',
                style({
                    transform: 'translateY(0)',
                    zIndex: 500,
                    opacity: 1,
                    bottom: 0
                })
            ),
            state(
                'closed',
                style({
                    transform: 'translateY(100%)',
                    opacity: 0,
                    bottom: 0
                })
            ),
            transition('opened => closed', [animate('150ms')]),
            transition('closed => opened', [animate('150ms')])
        ])
    ]
})
export class ToolbarComponent {
    @Input() selection!: SelectionModel<unknown>;
    @Input() selectionMax!: number;

    constructor(private elRef: ElementRef) {}

    @HostBinding('style.width.px')
    get menuWidth(): number {
        return this.elRef.nativeElement.parentElement.offsetWidth;
    }

    @HostBinding('class.mat-elevation-z3')
    get opened(): boolean {
        return this.hasSelection === 'opened';
    }

    @HostBinding('attr.data')
    get state(): string {
        return this.hasSelection;
    }

    @HostBinding('@slideInOut')
    get hasSelection(): 'opened' | 'closed' {
        if (this.selection) {
            return this.selection.hasValue() ? 'opened' : 'closed';
        }
        return 'opened';
    }
}
