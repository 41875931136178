export const GoogleAnalyticsEvents = {
    BUILD_PLAN: {
        CATEGORY: 'Build Plan - Management',
        DELETE: {
            ACTION: 'Build Plan - Delete',
            LABEL: 'Build Plan - Delete - '
        },
        PROTOTYPE_REQUEST: {
            CATEGORY: 'Build Plan - Prototype Request',
            SUBMIT_REQUEST: {
                ACTION: 'Submit Request',
                LABEL: 'Prototype Request - Submit Request'
            }
        },
        PROTOTYPE_REQUEST_BY_APPROVAL: {
            CATEGORY: 'Build Plan - Prototype Request by Approval',
            SUBMIT_REQUEST: {
                ACTION: 'Submit Request by Approval',
                LABEL: 'Prototype Request - Submit Request by Approval'
            }
        },
        ACCESSORY_REQUEST_BY_APPROVAL: {
            CATEGORY: 'Build Plan - Accessory Request by Approval',
            SUBMIT_REQUEST: {
                ACTION: 'Submit Request by Approval',
                LABEL: 'Accessory Request - Submit Request by Approval'
            }
        },
        DETAILS: {
            PRODUCT_DETAILS: {
                MAIN_PRODUCT_DATA: {
                    CATEGORY: 'Build Plan - Details - Main Product Data',
                    PRODUCT_INFO: {
                        EDIT: {
                            ACTION: 'Product Info - Edit',
                            LABEL: 'Main Product Data - Product Info - Edit'
                        },
                        MANAGERS_EDIT: {
                            ACTION: 'Product Info - Edit Managers',
                            LABEL: 'Main Product Data - Product Info - Edit Managers'
                        }
                    },
                    ESTIMATED_TIME: {
                        EDIT: {
                            ACTION: 'Estimated Time of Arrival - Edit',
                            LABEL: 'Main Product Data - Estimated Time of Arrival - Edit'
                        },
                        INSERT: {
                            ACTION: 'Estimated Time of Arrival - Insert',
                            LABEL: 'Main Product Data - Estimated Time of Arrival - INSERT'
                        }
                    }
                }
            },
            CONFIGURATIONS: {
                CATEGORY: 'Build Plan - Details - Configurations',
                INSERT: {
                    ACTION: 'Configuration - Insert',
                    LABEL: 'Configurations - Configuration - Insert'
                },
                DELETE: {
                    ACTION: 'Configuration - Delete',
                    LABEL: 'Configurations - Configuration - Delete'
                },
                EDIT: {
                    ACTION: 'Configuration - Edit',
                    LABEL: 'Configurations - Configuration - Edit'
                },
                ADD_FVC: {
                    ACTION: 'FVC Parameter - Insert',
                    LABEL: 'Configurations - FVC Parameter - Insert'
                },
                ADD_DATES: {
                    ACTION: 'Dates Default - Insert',
                    LABEL: 'Configurations - Dates Default - Insert'
                }
            },
            ACCESSORIES: {
                CATEGORY: 'Build Plan - Details - Accessories',
                INSERT: {
                    ACTION: 'Accessory - Insert',
                    LABEL: 'Accessories - Accessory - Insert'
                },
                DELETE: {
                    ACTION: 'Accessory - Delete',
                    LABEL: 'Accessories - Accessory - Delete'
                },
                EDIT: {
                    ACTION: 'Accessory - Edit',
                    LABEL: 'Accessories - Accessory - Edit'
                },
                ADD_PARAMETER: {
                    ACTION: 'Accessory Parameter - Insert',
                    LABEL: 'Accessories - Accessory Parameter - Insert'
                },
                ADD_DATES: {
                    ACTION: 'Dates Default - Insert',
                    LABEL: 'Accessories - Dates Default - Insert'
                }
            },
            SCOPES: {
                CATEGORY: 'Build Plan - Details - Scopes',
                INSERT: {
                    ACTION: 'Scope Association - Insert',
                    LABEL: 'Scope Association - Insert'
                },
                DELETE: {
                    ACTION: 'Scope Association - Delete',
                    LABEL: 'Scope Association - Delete'
                },
                EDIT: {
                    ACTION: 'Scope Association - Edit',
                    LABEL: 'Scope Association - Edit'
                }
            },
            TEAMS: {
                CATEGORY: 'Build Plan - Details - Teams',
                INSERT: {
                    ACTION: 'Team Association - Insert',
                    LABEL: 'Team Association - Insert'
                },
                DELETE: {
                    ACTION: 'Team Association - Delete',
                    LABEL: 'Team Association - Delete'
                },
                EDIT: {
                    ACTION: 'Team Association - Edit',
                    LABEL: 'Team Association - Edit'
                }
            },
            STATUS_APPROVALS: {
                CATEGORY: 'Build Plan - Details - Status and Approvals',
                CHANGE_STATUS: {
                    ACTION: 'Status - Edit',
                    LABEL: 'Status - Edit - '
                },
                CHANGE_APPROVAL: {
                    ACTION: 'Approval - Edit',
                    LABEL: 'Approval - Edit - '
                }
            },
            SKU_MATRIX: {
                CATEGORY: 'Build Plan - Details - Sku Matrix',
                INSERT: {
                    ACTION: 'Sku Matrix - Insert',
                    LABEL: 'Sku Matrix - Insert'
                },
                DELETE: {
                    ACTION: 'Sku Matrix - Delete',
                    LABEL: 'Sku Matrix - Delete'
                },
                EDIT: {
                    ACTION: 'Sku Matrix - Edit',
                    LABEL: 'Sku Matrix - Edit'
                }
            }
        },
        PERMISSIONS: {
            CATEGORY: 'Build Plan - Permissions',
            INSERT: {
                ACTION: 'Permission - Add',
                LABEL: 'Permission - Add'
            },
            DELETE: {
                ACTION: 'Permission - Remove',
                LABEL: 'Permission - Remove'
            }
        },
        FIELDS_VALUES: {
            ACCESSORY_PARAMETERS: {
                CATEGORY: 'Build Plan - Fields and Values - Accessory Parameters',
                INSERT: {
                    ACTION: 'Accessory Parameter - Insert',
                    LABEL: 'Accessory Parameter - Insert'
                },
                EDIT: {
                    ACTION: 'Accessory Parameter - Edit',
                    LABEL: 'Accessory Parameter - Edit'
                },
                CHANGE_STATUS: {
                    ACTION: 'Status - Edit',
                    LABEL: 'Accessory Parameters - Status - Edit - '
                },
                CHANGE_MANDATORY: {
                    ACTION: 'Mandatory - Edit',
                    LABEL: 'Accessory Parameters - Mandatory - Edit - '
                }
            },
            FVC_PARAMETERS: {
                CATEGORY: 'Build Plan - Fields and Values - FVC Parameters',
                INSERT: {
                    ACTION: 'FVC Parameter - Insert',
                    LABEL: 'FVC Parameter - Insert'
                },
                EDIT: {
                    ACTION: 'FVC Parameter - Edit',
                    LABEL: 'FVC Parameter - Edit'
                },
                CHANGE_STATUS: {
                    ACTION: 'Status - Edit',
                    LABEL: 'FVC Parameters - Status - Edit - '
                },
                CHANGE_MANDATORY: {
                    ACTION: 'Mandatory - Edit',
                    LABEL: 'FVC Parameters - Mandatory - Edit - '
                }
            },
            SPECIAL_REQUIREMENTS: {
                CATEGORY: 'Build Plan - Fields and Values - Special Requirements',
                INSERT: {
                    ACTION: 'Special Requirement- Insert',
                    LABEL: 'Special Requirement - Insert'
                },
                EDIT: {
                    ACTION: 'Special Requirement - Edit',
                    LABEL: 'Special Requirement - Edit'
                },
                CHANGE_STATUS: {
                    ACTION: 'Status - Edit',
                    LABEL: 'Special Requirement - Status - Edit - '
                },
                CHANGE_MANDATORY: {
                    ACTION: 'Mandatory - Edit',
                    LABEL: 'Special Requirement - Mandatory - Edit - '
                }
            },
            DATES: {
                CATEGORY: 'Build Plan - Fields and Values - Dates',
                INSERT: {
                    ACTION: 'Date - Insert',
                    LABEL: 'Date - Insert'
                },
                EDIT: {
                    ACTION: 'Date - Edit',
                    LABEL: 'Date - Edit'
                },
                CHANGE_STATUS: {
                    ACTION: 'Status - Edit',
                    LABEL: 'Dates - Status - Edit - '
                },
                CHANGE_FIXED: {
                    ACTION: 'Fixed - Edit',
                    LABEL: 'Dates - Fixed - Edit - '
                }
            },
            RELATIVE_DATES: {
                CATEGORY: 'Build Plan - Fields and Values - Relative Dates',
                INSERT: {
                    ACTION: 'Relative Date - Insert',
                    LABEL: 'Relative Date - Insert'
                },
                EDIT: {
                    ACTION: 'Relative Date - Edit',
                    LABEL: 'Relative Date - Edit'
                },
                CHANGE_STATUS: {
                    ACTION: 'Status - Edit',
                    LABEL: 'Relative Dates - Status - Edit - '
                }
            },
            SCOPE_PARAMETERS: {
                CATEGORY: 'Build Plan - Fields and Values - Scope Parameters',
                INSERT: {
                    ACTION: 'Scope Parameter - Insert',
                    LABEL: 'Scope Parameter - Insert'
                },
                EDIT: {
                    ACTION: 'Scope Parameter - Edit',
                    LABEL: 'Scope Parameter - Edit'
                },
                CHANGE_STATUS: {
                    ACTION: 'Status - Edit',
                    LABEL: 'Scope Parameters - Status - Edit - '
                },
                CHANGE_MANDATORY: {
                    ACTION: 'Mandatory - Edit',
                    LABEL: 'Scope Parameters - Mandatory - Edit - '
                }
            },
            TEAM_PARAMETERS: {
                CATEGORY: 'Build Plan - Fields and Values - Team Parameters',
                INSERT: {
                    ACTION: 'Team Parameter - Insert',
                    LABEL: 'Team Parameter - Insert'
                },
                EDIT: {
                    ACTION: 'Team Parameter - Edit',
                    LABEL: 'Team Parameter - Edit'
                },
                CHANGE_STATUS: {
                    ACTION: 'Status - Edit',
                    LABEL: 'Team Parameters - Status - Edit - '
                },
                CHANGE_MANDATORY: {
                    ACTION: 'Mandatory - Edit',
                    LABEL: 'Team Parameters - Mandatory - Edit - '
                }
            },
            LOCATIONS: {
                CATEGORY: 'Build Plan - Fields and Values - Locations',
                INSERT: {
                    ACTION: 'Location - Insert',
                    LABEL: 'Location - Insert'
                },
                EDIT: {
                    ACTION: 'Location - Edit',
                    LABEL: 'Location - Edit'
                },
                CHANGE_STATUS: {
                    ACTION: 'Status - Edit',
                    LABEL: 'Locations - Status - Edit - '
                }
            }
        },
        GROUPS: {
            CATEGORY: 'Build Plan - Groups',
            INSERT: {
                ACTION: 'Group - Insert',
                LABEL: 'Group - Insert'
            },
            EDIT: {
                ACTION: 'Group - Edit',
                LABEL: 'Group - Edit'
            },
            CHANGE_STATUS: {
                ACTION: 'Group - Edit',
                LABEL: 'Groups - Status - Edit - '
            },
            USERS: {
                INSERT: {
                    ACTION: 'Users - Insert',
                    LABEL: 'Groups - Users - Insert'
                },
                MANAGER: {
                    ACTION: 'Manager - Edit',
                    LABEL: 'Groups - Users - Manager - Edit - '
                },
                STATUS: {
                    ACTION: 'Status - Edit',
                    LABEL: 'Groups - Users - Status - Edit - '
                }
            }
        },
        SCOPES: {
            CATEGORY: 'Build Plan - Scopes',
            INSERT: {
                ACTION: 'Scopes - Insert',
                LABEL: 'Scopes - Insert'
            },
            EDIT: {
                ACTION: 'Scopes - Edit',
                LABEL: 'Scopes - Edit'
            },
            CHANGE_STATUS: {
                ACTION: 'Status - Edit',
                LABEL: 'Scopes - Status - Edit - '
            }
        },
        TEAMS: {
            CATEGORY: 'Build Plan - Teams',
            INSERT: {
                ACTION: 'Team - Insert',
                LABEL: 'Team - Insert'
            },
            EDIT: {
                ACTION: 'Team - Edit',
                LABEL: 'Team - Edit'
            },
            CHANGE_STATUS: {
                ACTION: 'Status - Edit',
                LABEL: 'Team - Status - Edit - '
            }
        },
        USERS: {
            CATEGORY: 'Build Plan - User management',
            INSERT: {
                ACTION: 'User - Insert',
                LABEL: 'User - Insert'
            },
            EDIT: {
                ACTION: 'User - Edit',
                LABEL: 'User - Edit'
            },
            CHANGE_STATUS: {
                ACTION: 'Status - Edit',
                LABEL: 'User - Status - Edit - '
            }
        },
        REPORTS: {
            CATEGORY: 'Build Plan - Reports',
            SHOW: {
                ACTION: 'Reports - Show Report',
                LABEL: 'Reports - Show Report - '
            },
            EXPORT: {
                ACTION: 'Reports - Export',
                LABEL: 'Reports - Export - '
            }
        },
        FAQ: {
            CATEGORY: 'Build Plan - FAQ',
            OPEN_ITEM: {
                ACTION: 'FAQ - Open FAQ Item',
                LABEL: 'FAQ - Open FAQ Item -'
            }
        }
    },
    CDC: {
        SORTING: {
            CATEGORY: 'CDC - Sorting Tracker',
            NEW_PROTOTYPE: {
                ACTION: 'Sorting - New Prototype',
                LABEL: 'Sorting - New Prototype - '
            },
            BULK_SORTING: {
                ACTION: 'Sorting - Bulk Sorting',
                LABEL: 'Sorting - Bulk Sorting - '
            },
            SINGLE_SORTING: {
                ACTION: 'Sorting - Single Sorting',
                LABEL: 'Sorting - Single Sorting - '
            },
            DELETE: {
                ACTION: 'Sorting - Delete Manually Added Prototype',
                LABEL: 'Sorting - Delete Manually Added Prototype - '
            }
        },
        ACCESSORY_TRACKER: {
            CATEGORY: 'CDC - Accessory Tracker',
            NEW_ACCESSORY: {
                ACTION: 'Accessory Tracker - New Accessory Tracker',
                LABEL: 'Accessory Tracker - New Accessory Tracker - '
            },
            DELETE: {
                ACTION: 'Accessory Tracker - Delete Manually Added Accessory',
                LABEL: 'Accessory Tracker - Delete Manually Added Accessory - '
            }
        }
    },
    SIDE_MENU: {
        CATEGORY: 'Galvin - Sidebar Menu',
        ACTION: 'Menu Item Select',
        LABEL: 'Menu Item - '
    }
};
