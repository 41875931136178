import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { BuildPlanService } from '@galvin/core/build-plan/management';
import { LayoutUtilsService, ToastrType } from '@galvin/core/_base/crud';
import { TranslateService } from '@ngx-translate/core';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError} from 'rxjs/operators';
import { BuildPlanDetailsService } from './build-plan-details.service';

@Injectable()
export class BuildPlanResolver implements Resolve<any> {

    constructor(
        private buildPlanService: BuildPlanService,
        private bpDetailsService: BuildPlanDetailsService,
        private layoutUtils: LayoutUtilsService,
        private i18n: TranslateService,
        private router: Router
    ) {}

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        const { name } = route.params;
        return this.buildPlanService.getDetailsByInternalProductNameCached(decodeURIComponent(name)).pipe(
            catchError((e) => {
                if(e.status == 404 || e.status == 403) {
                    this.layoutUtils.showToastrNotification(
                        this.i18n.instant('BUILD_PLAN.BUILD_PLAN_MANAGEMENT.NOT_FOUND_OR_ACCESS_DENIED'),
                        ToastrType.error
                    );
                } else {
                    this.layoutUtils.showToastrNotification(
                        this.i18n.instant('COMMON.UNKNOWN_ERROR'),
                        ToastrType.error
                    );
                }
                if (state.root.parent) {
                    this.router.navigate(state.root.parent.url);
                } else {
                    this.router.navigate(['/build-plan/management']);
                }
                return of(EMPTY);
            })
        );
    }
}
