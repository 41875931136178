<div id="build-plan-selector" class="selector-with-filter">
    <div class="build-plan-selector-side galvin-icon-phone-large"></div>
    <div class="build-plan-selector-side">
        <div class="build-plan-selected">
            <div *ngIf="selectedBuildPlan">
                <div class="build-plan-name">
                    <div class="d-inline-block text-truncate" [style.max-width.px]="200"
                        [ngbTooltip]="selectedBuildPlan.internalProductName">{{ selectedBuildPlan.internalProductName }}
                    </div>
                </div>
                <div *ngIf="selectedBuildPlanGroup" [attr.data-status]="selectedBuildPlanGroup.value"
                    class="build-plan-status">
                    {{ selectedBuildPlanGroup.name }}
                </div>
            </div>

            <div *ngIf="!selectedBuildPlan">
                <mat-spinner *ngIf="loadingBuildPlansSelector" [diameter]="30"></mat-spinner>
                <span *ngIf="!loadingBuildPlansSelector">—</span>
            </div>
        </div>
        <div *ngIf="enabledSelection" (openChange)="dropdownChange($event)" class="d-inline-block" ngbDropdown>
            <button id="build-plan-dropdown" ngbDropdownToggle [disabled]="loadingBuildPlansSelector">
                {{ 'BUILD_PLAN.PROTOTYPE_REQUEST.HEADER.BUILD_PLAN_SELECTOR.SELECT_BUILD_PLAN' | translate }}
            </button>
            <div aria-labelledby="build-plan-dropdown" ngbDropdownMenu>
                <mat-form-field id="build-plan-selector-filter" appearance="outline"
                    class="mat-form-field-fluid selector-search">
                    <mat-icon matPrefix>search</mat-icon>
                    <input [(ngModel)]="buildPlanSelectorFilter" matInput
                        placeholder="{{ 'BUILD_PLAN.PROTOTYPE_REQUEST.HEADER.BUILD_PLAN_SELECTOR.SEARCH_BUILD_PLAN' | translate }}">
                </mat-form-field>

                <div class="selector-options">
                    <div [hidden]="isGroupFiltered(GROUP_NEW)">
                        <h6 class="selector-option-new">
                            {{ 'BUILD_PLAN.PROTOTYPE_REQUEST.HEADER.BUILD_PLAN_SELECTOR.NEW_BUILD_PLANS' | translate }}
                        </h6>
                        <ul>
                            <li *ngFor="let buildPlan of buildPlans.new" (click)="selectBuildPlan(buildPlan, GROUP_NEW)"
                                [hidden]="isBuildPlanFiltered(buildPlan)" class="dropdown-item galvin-icon-phone">
                                <div class="build-plan-name text-truncate" [ngbTooltip]="buildPlan.internalProductName">
                                    {{ buildPlan.internalProductName }}
                                </div>
                            </li>
                        </ul>
                    </div>

                    <div [hidden]="isGroupFiltered(GROUP_ALREADY_REQUESTED)">
                        <h6 class="selector-option-requested">
                            {{ 'BUILD_PLAN.PROTOTYPE_REQUEST.HEADER.BUILD_PLAN_SELECTOR.ALREADY_REQUESTED' | translate }}
                        </h6>
                        <ul>
                            <li *ngFor="let buildPlan of buildPlans.alreadyRequested"
                                (click)="selectBuildPlan(buildPlan, GROUP_ALREADY_REQUESTED)"
                                [hidden]="isBuildPlanFiltered(buildPlan)" class="dropdown-item galvin-icon-phone">
                                <div class="build-plan-name text-truncate" [ngbTooltip]="buildPlan.internalProductName">
                                    {{ buildPlan.internalProductName }}
                                </div>
                                <span
                                    [ngbTooltip]="'BUILD_PLAN.PROTOTYPE_REQUEST.HEADER.BUILD_PLAN_SELECTOR.TOTAL_DEVICES_REQUESTED' | translate"
                                    class="option-description text-truncate">
                                    {{ buildPlan.requested }}
                                </span>
                            </li>
                        </ul>
                    </div>

                    <div [hidden]="isGroupFiltered(GROUP_LOCKING_DOWN_SOON)">
                        <h6 class="selector-option-locking-down">
                            {{ 'BUILD_PLAN.PROTOTYPE_REQUEST.HEADER.BUILD_PLAN_SELECTOR.LOCKING_SOON' | translate }}
                        </h6>
                        <ul>
                            <li *ngFor="let buildPlan of buildPlans.lockingDownSoon"
                                (click)="selectBuildPlan(buildPlan, GROUP_LOCKING_DOWN_SOON)"
                                [hidden]="isBuildPlanFiltered(buildPlan)" class="dropdown-item galvin-icon-phone">
                                <div class="build-plan-name text-truncate" [ngbTooltip]="buildPlan.internalProductName">
                                    {{ buildPlan.internalProductName }}
                                </div>
                                <span [ngbTooltip]="buildPlan.lockDownDate|parseDateTime:true"
                                    class="option-description text-truncate">
                                    {{ buildPlan.lockDownDate | parseDateTime:true }}
                                </span>
                            </li>
                        </ul>
                    </div>

                    <div [hidden]="isGroupFiltered(GROUP_UNDER_APPROVAL)">
                        <h6 class="selector-option-under-approval">
                            {{ 'BUILD_PLAN.PROTOTYPE_REQUEST.HEADER.BUILD_PLAN_SELECTOR.UNDER_APPROVAL' | translate }}
                        </h6>
                        <ul>
                            <li *ngFor="let buildPlan of buildPlans.underApproval"
                                (click)="selectBuildPlan(buildPlan, GROUP_UNDER_APPROVAL)"
                                [hidden]="isBuildPlanFiltered(buildPlan)" class="dropdown-item galvin-icon-phone">
                                <div class="build-plan-name text-truncate" [ngbTooltip]="buildPlan.internalProductName">
                                    {{ buildPlan.internalProductName }}
                                </div>
                                <span [ngbTooltip]="buildPlan.status" class="option-description text-truncate">
                                    {{ buildPlan.status }}
                                </span>
                            </li>
                        </ul>
                    </div>

                    <div [hidden]="isGroupFiltered(GROUP_BUILDING)">
                        <h6 class="selector-option-building">
                            {{ GROUP_BUILDING }}</h6>
                        <ul>
                            <li *ngFor="let buildPlan of buildPlans.building"
                                (click)="selectBuildPlan(buildPlan, GROUP_BUILDING)"
                                [hidden]="isBuildPlanFiltered(buildPlan)" class="dropdown-item galvin-icon-phone">
                                <div class="build-plan-name text-truncate" [ngbTooltip]="buildPlan.internalProductName">
                                    {{ buildPlan.internalProductName }}
                                </div>
                            </li>
                        </ul>
                    </div>

                    <div [hidden]="isGroupFiltered(GROUP_CLOSED)">
                        <h6 class="selector-option-closed">
                            {{ 'BUILD_PLAN.PROTOTYPE_REQUEST.HEADER.BUILD_PLAN_SELECTOR.CLOSED' | translate }}</h6>
                        <ul>
                            <li *ngFor="let buildPlan of buildPlans.closed"
                                (click)="selectBuildPlan(buildPlan, GROUP_CLOSED)"
                                [hidden]="isBuildPlanFiltered(buildPlan)" class="dropdown-item galvin-icon-phone">
                                <div class="build-plan-name text-truncate" [ngbTooltip]="buildPlan.internalProductName">
                                    {{ buildPlan.internalProductName }}
                                </div>
                                <span [ngbTooltip]="buildPlan.status" class="option-description text-truncate">
                                    {{ buildPlan.status }}
                                </span>
                            </li>
                        </ul>
                    </div>

                    <div [hidden]="hasBuildPlansToShow()" class="selector-no-options">
                        {{ 'BUILD_PLAN.PROTOTYPE_REQUEST.HEADER.BUILD_PLAN_SELECTOR.NO_BUILD_PLANS_FILTER' | translate }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>