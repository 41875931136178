import { Action } from '@ngrx/store';
import { User } from '@galvin/core/auth';

export enum AuthActionTypes {
    LoginGoogle = '[Login Google] Action',
    LoginLenovo = '[Login Lenovo] Action',
    Logout = '[Logout] Action',
    UserLoaded = '[User Loaded] Action'
}

export class LoginGoogle implements Action {
    readonly type = AuthActionTypes.LoginGoogle;
}

export class LoginLenovo implements Action {
    readonly type = AuthActionTypes.LoginLenovo;
}

export class Logout implements Action {
    readonly type = AuthActionTypes.Logout;
}

export class UserLoaded implements Action {
    readonly type = AuthActionTypes.UserLoaded;
    constructor(public payload: { user: User | null }) { }
}

export type AuthActions = LoginGoogle | LoginLenovo | Logout  | UserLoaded;
