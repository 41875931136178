import { AfterViewInit, Directive, ElementRef, EventEmitter, HostListener, Input, NgZone, OnDestroy, Output } from '@angular/core';
import { fromEvent, pipe, Subject, Subscription } from 'rxjs';
import { filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ItemCP } from '../models/item-to-copy';
import { CopyValuesTable } from '@galvin/core/utils/copy-values/copy-values-table';
import { TableSelectionService } from '../services/table-selection.service';
import { cloneDeep, isEqual } from 'lodash';
import { ETrackColor } from '@galvin/core/cdc/sorting/enums/cdc-track-color-enum';

@Directive({
    selector: '[table-range-selection]',
    exportAs: 'table-range-selection'
})
export class TableSelectionDirective implements OnDestroy, AfterViewInit {
    @Input() selectionClass = 'state--selected';
    @Input() cuttingClass = 'state--cutted';
    @Input() enableCopy = false;
    @Input() tagsEnable = 'th,td';
    @Output() cutAction = new EventEmitter();
    @Input() isEditingCell = false;
    @Output() deleteAction = new EventEmitter();
    @Output() pasteAction = new EventEmitter<boolean>();
    selectedRange = new Set<HTMLTableCellElement>();
    cuttedRange = new Set<HTMLTableCellElement>();
    private readonly container: HTMLElement;
    private readonly subscription = new Subscription();
    /* @ts-ignore */
    private table: HTMLTableElement;
    /* @ts-ignore */
    private startCell: HTMLTableCellElement=null;
    private cellIndices = new Map<HTMLTableCellElement, { row: number; column: number }>();
    private selecting: boolean=false;
    private destroy$ = new Subject<void>();
    private selectedCells = new Set<any>();
    private cutting: boolean = false;

    constructor(
        private zone: NgZone,
        element: ElementRef<HTMLElement>,
        private tableSelectionData: TableSelectionService
    ) {
        this.container = element.nativeElement;
    }

    ngAfterViewInit() {
        /* @ts-ignore */
        if (this.container instanceof HTMLTableElement){
            this.table =this.container;
        } else {
            this.table =this.container.querySelector('table') as HTMLTableElement;

        }
        this.zone.runOutsideAngular(() => this.initListeners());

        this.subscription.add(
            this.tableSelectionData.newSelectedCell$.subscribe((selected) => (this.selectCellByFocus(selected)))
        );
        this.subscription.add(
            this.tableSelectionData.clearCuttedCell$.subscribe(() => (this.clearCuttedCells()))
        );
    }

    private initListeners() {
        const withCell = pipe(
            map((event: MouseEvent) => ({event, cell: (event.target as HTMLElement).closest<HTMLTableCellElement>(this.tagsEnable)})),
            filter(({cell}) => !!cell),
        );
        const mouseDown$ = fromEvent<MouseEvent>(this.table, 'mousedown')
            .pipe(
                filter(event => event.button === 0),
                /* @ts-ignore */
                withCell,
                tap(this.startSelection)
            );
        const mouseOver$ = fromEvent<MouseEvent>(this.table, 'mouseover');
        const mouseUp$ = fromEvent(document, 'mouseup').pipe(
            tap(() => this.selecting = false)
        );
        this.handleOutsideClick();

        mouseDown$.pipe(
            switchMap(() => mouseOver$.pipe(takeUntil(mouseUp$))),
            takeUntil(this.destroy$),
            withCell
        ).subscribe( /* @ts-ignore */
            this.select);
    }

    private handleOutsideClick() {
        fromEvent(document, 'mouseup').pipe(
            takeUntil(this.destroy$)
        ).subscribe((event: any) => {
            if (!this.selecting && event.target != HTMLTableCellElement) {
                this.clearCells();
            }
        });
    }

    private startSelection = ({cell, event}: { event: MouseEvent, cell: HTMLTableCellElement }) => {
        this.updateCellIndices();
        if (!event.ctrlKey && !event.shiftKey) {
            this.clearCells();
        }

        if (event.shiftKey) {
            this.select({cell});
        }

        this.selecting = true;
        this.startCell = cell;

        if (!event.shiftKey) {
            this.updateSelectedRange(cell);
        }
    };

    private select = ({cell}: { cell: HTMLTableCellElement }) => {
        this.clearCells();
        this.getCellsBetween(this.startCell, cell).forEach(item => {
            var cellAttributes = JSON.parse(item.getAttribute('data-info') || '{}');

            if(Object.keys(cellAttributes).length === 0){
                var id = item.getAttribute('data-item-id');
                var column = item.getAttribute('data-col');
                if(id && column){
                    var value = item.getAttribute('data-cell-value');
                    cellAttributes = {id, column, value};
                }
            }
            this.selectedRange.add(item);
            this.selectedCells.add(cellAttributes);
            this.updateSelectedCells();
            item.classList.add(this.selectionClass);
        });
    };

    private clearCells() {
        Array.from(this.selectedRange).forEach(cell => {
            cell.classList.remove(this.selectionClass);
        });
        this.selectedRange.clear();
        this.selectedCells.clear();
        this.updateSelectedCells();
    }

    private getCellsBetween(start: HTMLTableCellElement, end: HTMLTableCellElement) {
        const startCoords = this.cellIndices.get(start);
        const endCoords = this.cellIndices.get(end);
        let boundaries:any = null;
        if (startCoords && endCoords){
            boundaries = {
                top: Math.min(startCoords.row, endCoords.row),
                right: Math.max(startCoords.column + start.colSpan - 1, endCoords.column + end.colSpan - 1),
                bottom: Math.max(startCoords.row + start.rowSpan - 1, endCoords.row + end.rowSpan - 1),
                left: Math.min(startCoords.column, endCoords.column),
            };
        }


        const cells:any[] = [];

        iterateCells(this.table, (cell) => {
            const cellV= this.cellIndices.get(cell);
            if (cellV && boundaries){
                const { column, row } = cellV;
                if (column >= boundaries.left && column <= boundaries.right &&
                row >= boundaries.top && row <= boundaries.bottom && !cell.classList.contains('hidden-column')) {
                    cells.push(cell);
                }
            }

        });

        return cells;
    }

    private updateCellIndices() {
        this.cellIndices.clear();
        const matrix:any[] = [];
        iterateCells(this.table, (cell, y, x) => {
            for (let spanX = x; spanX < x + cell.colSpan; spanX++) {
                for (let spanY = y; spanY < y + cell.rowSpan; spanY++) {
                    (matrix[spanY] = matrix[spanY] || [])[spanX] = 1;
                }
            }
            this.cellIndices.set(cell, {row: y, column: x});
        });
    }

    private static deepCopy<T>(el: T): T {
        return cloneDeep(el);
    }

    private changeCuttingRange(adding: boolean) {
        this.cuttedRange.forEach(cell => {
            this.updateSelectedCells();
            cell.classList.remove(this.cuttingClass);
            if (adding) {
                cell.classList.add(this.cuttingClass);
            }
        });
    }

    private updateSelectedCuttedCells() {
        if (this.cuttedRange && this.cuttedRange.size > 0) {
            this.changeCuttingRange(false);
        }
        this.cuttedRange = TableSelectionDirective.deepCopy<Set<HTMLTableCellElement>>(this.selectedRange);
        this.changeCuttingRange(true);
        this.updateSelectedCells();
    }

    private clearCuttedCells() {
        if (this.cuttedRange && this.cuttedRange.size > 0) {
            this.changeCuttingRange(false);
        }
        this.cutting = false;
        this.updateSelectedCells();
    }

    private updateSelectedRange(cell: HTMLTableCellElement) {
        var cellAttributes = JSON.parse(cell.getAttribute('data-info') || '{}');
        if(Object.keys(cellAttributes).length === 0){
            var id = cell.getAttribute('data-item-id');
            var column = cell.getAttribute('data-col');
            if(id && column){
                var value = cell.getAttribute('data-cell-value');
                cellAttributes = {id, column, value};
            }
        }
        if (this.selectedRange.has(cell)) {
            this.selectedRange.delete(cell);

            this.selectedCells.forEach(cell => {
                if (isEqual(cell, cellAttributes)) {
                    this.selectedCells.delete(cell);
                }
            });
        } else {
            this.selectedRange.add(cell);
            this.selectedCells.add(cellAttributes);
        }
        this.updateSelectedCells();
        cell.classList.toggle(this.selectionClass);
    }

    private selectCellByFocus(cell: HTMLTableCellElement ) {
        if(cell) {
            this.clearCells();
            this.updateSelectedRange(cell);
        }
    }

    ngOnDestroy() {
        this.destroy$.next();
    }


    @HostListener('window:keydown',['$event'])
    onKeyPress($event: KeyboardEvent) {
        if(this.enableCopy && !this.isEditingCell){
            //Control + C
            if(($event.ctrlKey || $event.metaKey) && $event.keyCode == 67){ // CTRL + C
                const items = this.litCellHtmltoCpItem();
                let textCp = new CopyValuesTable().itemCpListtoText(items);
                if (this.selectedCells.size > 0) {
                    navigator.clipboard.writeText('');
                    navigator.clipboard.writeText(textCp);
                    this.cutting = false;
                }
            //Control + V
            } else if(($event.ctrlKey || $event.metaKey) && $event.keyCode == 86) { // CTRL + V
                if (this.selectedCells.size > 0) {
                    $event.preventDefault();
                    $event.stopImmediatePropagation();
                }
                this.pasteAction.emit(this.cutting);
                this.clearCuttedCells();
            } else if($event.keyCode == 27) { // ESC - Escape
                this.clearCuttedCells();
            }
        }
    }

    private litCellHtmltoCpItem():ItemCP[] {
        const items: ItemCP[] = [];
        Array.from(this.selectedRange).forEach((cell:HTMLTableCellElement) => {
            const cellIndexes = this.cellIndices.get(cell);
            if (cellIndexes && cellIndexes?.column && cellIndexes?.row){
                let textClipBoard:string=cell.textContent?cell.textContent:'';
                if (cell.hasAttribute('textclipboard')){
                    textClipBoard=cell.getAttribute('textclipboard')+'';
                }else if (cell.querySelectorAll('[textclipboard]').length>0){
                    const tagCp= cell.querySelectorAll('[textclipboard]')[0];
                    textClipBoard=tagCp.textContent+'';
                }
                let color = '';
                const nodeClasses = Object.values(cell.classList);
                if(nodeClasses){
                    nodeClasses.map(el => {
                        if (Object.keys(ETrackColor).includes(el)) {
                            color = el;
                        }
                    });
                }
                let newItem:ItemCP = { 'col': +cellIndexes.column, 'index': +cellIndexes.row, 'value': textClipBoard.trim(), 'color': color};
                items.push(newItem);
            }
        });
        return items;
    }

    updateSelectedCells() {
        this.tableSelectionData.updateSelectedCells(this.selectedCells);
    }
}

function iterateCells(table: HTMLTableElement, callback: (cell: HTMLTableCellElement, y: number, x: number) => void): void {
    for (let y = 0; y < table.rows.length; y++) {
        for (let x = 0; x < table.rows[y].cells.length; x++) {
            callback(table.rows[y].cells[x], y, x);
        }
    }
}
