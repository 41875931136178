import { Directive, Input, TemplateRef } from '@angular/core';
import { GenericFieldService } from '@galvin/views/partials/layout/generic-field/generic-field/generic-field.service';

@Directive({
    selector: '[genericFieldTemplate]'
})
export class GenericFieldTemplateDirective {
    @Input() markAsDefault!: boolean;

    constructor(
        public templateRef: TemplateRef<any>,
        private genericService: GenericFieldService
    ) {}

    @Input('genericFieldTemplate')
    set genericFieldType(key: string) {
        this.genericService.setTemplate(key, this.templateRef, this.markAsDefault);
    }

    isTypeOf(type: string): boolean {
        if (Array.isArray(this.genericFieldType)) {
            return this.genericFieldType.includes(type);
        }
        return this.genericFieldType === type;
    }
}
