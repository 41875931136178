<div class="configuration-header-content header position-relative" [ngbTooltip]="tooltip">
    <ng-template #tooltip>
        {{configuration.name |checkNull}} #{{configuration.idConfiguration}}
        <br>
        {{configuration.recipeConfig |checkNull}}
        <br>
        {{ 'BUILD_PLAN.REQUEST_PREVIEW.TOTAL_REQUEST_FULL_VIEW' | translate}}
        ({{ configuration.totalRequests | checkNull: 0 }})
        <ng-container *ngIf="isHeaderInfoVisible">
            <br>
            {{ LABELS_PROTO_REQUEST.HEADER.FILTERS.HW_REVISION }}:
            {{ configuration.hwRevision |checkNull }}
            <ng-container *ngIf="verifyFieldFvcSecure(configuration)">
                <br>
                <span>{{getFvcSecure(configuration)}}</span>
            </ng-container>
            <br>
            {{ LABELS_PROTO_REQUEST.HEADER.FILTERS.SOURCING }}:
            {{ configuration.sourcing |checkNull }}
            <br>
            {{ LABELS_PROTO_REQUEST.HEADER.FILTERS.BUILD_DATE }}:
            {{ configuration.buildDate?.toString() | parseDate }}
            <br>
            {{ LABELS_PROTO_REQUEST.HEADER.FILTERS.FVC }}:
            {{ configuration.configFvc | checkNull }}
            <br>
            {{ LABELS_PROTO_REQUEST.HEADER.FILTERS.XCVR_KIT }}:
            {{ configuration.xcvrKit | checkNull }}
            <br>
            {{ LABELS_PROTO_REQUEST.HEADER.FILTERS.PRODUCT_SKU }}:
            {{ configuration.productSku | checkNull }}
            <br>
            {{ LABELS_PROTO_REQUEST.HEADER.FILTERS.CMF_COLOR }}:
            {{ configuration.cmfColor | checkNull }}
            <br>
            {{ LABELS_PROTO_REQUEST.HEADER.FILTERS.CMF_COLOR }}:
            {{ configuration.cmfColor | checkNull }}
            <br>
            {{ LABELS_PROTO_REQUEST.HEADER.FILTERS.PCBA_KIT }}:
            {{ configuration.pcbaKit | checkNull }}
        </ng-container>
    </ng-template>

    <div class="header-font-12 text-truncate config-name-id">
        {{ configuration.name | checkNull }} <br>
        #{{ configuration.idConfiguration }}
    </div>

    <div class="position-absolute r-0 position-btn-configuration-details"
        (click)="showRequestsDialog(configuration, $event)">
        <button class="btn-configuration-details" color="primary" mat-icon-button>
            <mat-icon [ngbTooltip]="LABELS_REQUEST_PREV.DETAILS">loupe</mat-icon>
        </button>
    </div>

    <div class="m-0 text-truncate">
        <small class="config-recipe">{{configuration.recipeConfig | checkNull}}</small>
        <ng-container *ngIf="configuration.totalRequests > 0">
            <span class="ml-2 header-font-12 config-request-total">
                ({{ configuration.totalRequests }})
            </span>
        </ng-container>
    </div>

    <div class="header-extra-block" *ngIf="isHeaderInfoVisible">
        <div class="d-flex">
            <small [class]="$any(configuration.hwRevision)"
                [ngClass]="{'text-center': !verifyFieldFvcSecure(configuration)}" class="config-hw-revision">
                <strong>{{ configuration.hwRevision | checkNull }}</strong>
            </small>
            <ng-container *ngIf="verifyFieldFvcSecure(configuration)">
                <small class="config-secure">
                    <i *ngIf="configuration.fvcSecure" class="fas fa-lock fa-1x config-secure-icon"></i>
                    <i *ngIf="!configuration.fvcSecure" class="fas fa-lock-open fa-1x config-non-secure-icon"></i>
                </small>
            </ng-container>
        </div>
        <div class="d-flex">
            <small class="config-source">
                <svg class="svg" viewBox="5 11 20 25" width="18" height="25">
                    <use xlink:href="assets/galvin/icons/source/cellphone_1st.svg#medal"
                        *ngIf="isSourcing(configuration.sourcing, 1)"></use>
                    <use xlink:href="assets/galvin/icons/source/cellphone_2nd.svg#medal"
                        *ngIf="isSourcing(configuration.sourcing, 2)"></use>
                    <use xlink:href="assets/galvin/icons/source/cellphone_3rd.svg#medal"
                        *ngIf="isSourcing(configuration.sourcing, 3)"></use>
                    <use xlink:href="assets/galvin/icons/source/cellphone_4th.svg#medal"
                        *ngIf="isSourcing(configuration.sourcing, 4)"></use>
                    <use xlink:href="assets/galvin/icons/source/cellphone_5th.svg#medal"
                        *ngIf="isSourcing(configuration.sourcing, 5)"></use>
                </svg>
            </small>
            <small
                class="my-auto config-build-date">{{ configuration.buildDate?.toString() | parseChineseDate }}</small>
        </div>
        <div class="d-flex">
            <small class="config-fvc">{{ configuration.configFvc | checkNull }}</small>
            <small class="config-xcvr-kit">{{ configuration.xcvrKit | checkNull }}</small>
        </div>
        <div class="d-flex">
            <small class="config-sku">{{ configuration.productSku | checkNull }}</small>
            <small class="config-cmf-color">
                <div *ngIf="configuration.cmfColor" class="color-circle" [attr.data-color]="configuration.cmfColor">
                </div>
                {{ configuration.cmfColor | checkNull }}
            </small>
        </div>
        <div class="d-flex text-center">
            <small class="text-center config-pcba-kit">{{ configuration.pcbaKit | checkNull }}</small>
        </div>
    </div>
</div>