export class HwRevisionUtils {
    public static readonly BOARD = 'BOARD';
    public static readonly EVB = 'EVB';
    public static readonly EVB2 = 'EVB2';
    public static readonly EVB3 = 'EVB3';
    public static readonly EVT = 'EVT';
    public static readonly EVT2 = 'EVT2';
    public static readonly EVT3 = 'EVT3';
    public static readonly DVT = 'DVT';
    public static readonly DVT1 = 'DVT1';
    public static readonly DVT1A = 'DVT1A';
    public static readonly DVT1B = 'DVT1B';
    public static readonly DVT1C = 'DVT1C';
    public static readonly DVT2 = 'DVT2';
    public static readonly DVT2A = 'DVT2A';
    public static readonly DVT2B = 'DVT2B';
    public static readonly DVT2C = 'DVT2C';
    public static readonly DVT3 = 'DVT3';
    public static readonly DVT3A = 'DVT3A';
    public static readonly DVT3B = 'DVT3B';
    public static readonly DVT3C = 'DVT3C';
    public static readonly PVT = 'PVT';
    public static readonly OTHER = 'OTHER';
    public static readonly NA = 'N/A';

    public static readonly ALL = [
        HwRevisionUtils.BOARD,
        HwRevisionUtils.EVB,
        HwRevisionUtils.EVB2,
        HwRevisionUtils.EVB3,
        HwRevisionUtils.EVT,
        HwRevisionUtils.EVT2,
        HwRevisionUtils.EVT3,
        HwRevisionUtils.DVT,
        HwRevisionUtils.DVT1,
        HwRevisionUtils.DVT1A,
        HwRevisionUtils.DVT1B,
        HwRevisionUtils.DVT1C,
        HwRevisionUtils.DVT2,
        HwRevisionUtils.DVT2A,
        HwRevisionUtils.DVT2B,
        HwRevisionUtils.DVT2C,
        HwRevisionUtils.DVT3,
        HwRevisionUtils.DVT3A,
        HwRevisionUtils.DVT3B,
        HwRevisionUtils.DVT3C,
        HwRevisionUtils.PVT,
        HwRevisionUtils.NA
    ];

    public static readonly SORTING_ORDER = [
        HwRevisionUtils.BOARD,
        HwRevisionUtils.EVB,
        HwRevisionUtils.EVB2,
        HwRevisionUtils.EVB3,
        HwRevisionUtils.EVT,
        HwRevisionUtils.EVT2,
        HwRevisionUtils.EVT3,
        HwRevisionUtils.DVT1,
        HwRevisionUtils.DVT2,
        HwRevisionUtils.DVT3,
        HwRevisionUtils.DVT,
        HwRevisionUtils.PVT,
        HwRevisionUtils.NA
    ];

    ///

    public static readonly ALL_COLORS = {
        BOARD: '#3d7a39',
        EVB: '#1f4d9b',
        EVB2: '#bc6c25',
        EVB3: '#219ebc',
        EVT: '#7832be',
        EVT2: '#6b705c',
        EVT3: '#f4a259',
        DVT: '#1faf92',
        DVT1: '#ad2470',
        DVT1A: '#e36414',
        DVT1B: '#940f22',
        DVT1C: '#e63946',
        DVT2: '#868249',
        DVT2A: '#b37037',
        DVT2B: '#c9184a',
        DVT2C: '#e07a5f',
        DVT3: '#01497c',
        DVT3A: '#5f0f40',
        DVT3B: '#ff8fab',
        DVT3C: '#735d78',
        PVT: '#0078c8',
        OTHER: '#5f5f5f',
    };

    public static isOther(hwRevision: string): boolean {
        return HwRevisionUtils.ALL.indexOf(hwRevision) === -1;
    }

    public static sorter(hwA: string, hwB: string): number {
        hwA = (hwA ? hwA.toUpperCase() : HwRevisionUtils.NA);
        hwB = (hwB ? hwB.toUpperCase() : HwRevisionUtils.NA);
        const aHwRevisionPrefix = (HwRevisionUtils.NA === hwA) ? HwRevisionUtils.NA : String(hwA.match(/^[A-Z]+(?:\d)?/g));
        const bHwRevisionPrefix = (HwRevisionUtils.NA === hwB) ? HwRevisionUtils.NA : String(hwB.match(/^[A-Z]+(?:\d)?/g));

        const aSortIndex: number = HwRevisionUtils.SORTING_ORDER.indexOf(aHwRevisionPrefix);
        const bSortIndex: number = HwRevisionUtils.SORTING_ORDER.indexOf(bHwRevisionPrefix);

        if(aSortIndex === bSortIndex && hwA.includes(HwRevisionUtils.BOARD)){
            return -1;
        }
        if(aSortIndex === bSortIndex && hwB.includes(HwRevisionUtils.BOARD)){
            return 1;
        }
        if (aSortIndex < bSortIndex) {
            return -1;
        }
        if (aSortIndex > bSortIndex) {
            return 1;
        }
        return hwA.localeCompare(hwB, 'en-US', { numeric: true, sensitivity: 'base' });
    }
}