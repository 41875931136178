import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { finalize, map, tap } from 'rxjs/operators';
import { BannerInformationService } from './../../../core/_base/banner-information/banner-information.service';
import { LocalStorageHelper } from '@galvin/core/storage/local-storage-helper.service';
import { UserPermissionsService } from '@galvin/core/auth/services/user-permissions.service';

@Component({
    selector: 'banner-information',
    templateUrl: './banner-information.component.html',
    styleUrls: ['./banner-information.component.scss']
})
export class BannerInformationComponent implements OnInit, OnDestroy{
    show = false;
    content: SafeHtml = '';
    isBigText = true;
    collapsed = false;

    s8n = new Subscription();

    constructor(
        private bannerService: BannerInformationService,
        private sanitizer: DomSanitizer,
        private router: Router,
        private route: ActivatedRoute,
        private elRef: ElementRef,
        private localStorageHelper: LocalStorageHelper,
        private userPermissionsService: UserPermissionsService
    ) {}

    ngOnInit(): void {
        this.s8n.add(
            this.route.queryParams.subscribe((params: Params) => {
                if (!params['e2e_execution']) {
                    if (this.router.url != '/') {
                        this.getActivedBannerInformation();
                    }
                }
            })
        );
    }

    /**
     * Get Actived Banner Information
     */
    getActivedBannerInformation(): void {
        let hasRolesOrAdmin =this.userPermissionsService.hasRoleOrAdmin();
        const localStorageUser = this.localStorageHelper.getLoggedUser();
        if (localStorageUser!=null && hasRolesOrAdmin){

            this.bannerService
                .getActivedBannerInformation()
                .pipe(
                    tap((banner) => {
                        this.show = !!banner;
                    }),
                    map((banner: any) => {
                        if (banner) return this.sanitizer.bypassSecurityTrustHtml(banner.content);
                        return '';
                    }),
                    finalize(() => {
                        if (this.show) {
                        // eslint-disable-next-line no-undef
                            setTimeout(() => {
                                const e = this.elRef.nativeElement.querySelector('.content-info');
                                if(e){
                                    this.isBigText = e.offsetWidth < e.scrollWidth;
                                }
                            }, 3000);
                        }
                    })
                )
                .subscribe((content) => {
                    this.content = content ? content : '';
                });
        }
    }

    closed(): void {
        this.show = false;
    }

    ngOnDestroy(): void {
        this.s8n.unsubscribe();
    }

}
