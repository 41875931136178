import { Component, HostListener, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GenericDialogData } from '@galvin/core/_base/crud/utils/layout-utils.service';
import { Subject } from 'rxjs';

@Component({
    selector: 'galvin-confirm-dialog-generic',
    templateUrl: './galvin-confirm-dialog-generic.component.html'
})
export class GalvinConfirmGenericComponent {
    public isLoading = false;
    public readonly isLoading$: Subject<void>;
    public readonly isCancel$: Subject<void>;
    public labelCancel: string = 'COMMON.BUTTONS.CANCEL';
    public labelConfirm: string = 'COMMON.BUTTONS.CONTINUE';

    constructor(
        public dialogRef: MatDialogRef<GalvinConfirmGenericComponent>,
        @Inject(MAT_DIALOG_DATA) public data: GenericDialogData
    ) {
        if (!data.buttonClass) {
            data.buttonClass = 'galvin-btn-danger';
        }
        this.isLoading$ = new Subject();
        this.isCancel$ = new Subject();

    }

    cancelAction(){
        this.dialogRef.close();
        this.isCancel$.next();
    }

    @HostListener('window:keydown',['$event'])
    onKeyPress($event: KeyboardEvent) {
        if (this.data.isForCdc) {
            switch($event.key) {
            case 'Enter':
                this.isLoading$.next();
                break;
            case 'Escape':
                this.cancelAction();
                break;
            }
        }
    }
}
