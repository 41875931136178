import { Location } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
    IGalvinUserPermission,
    PermissionManagementService
} from '@galvin/core/build-plan/permission-management';
import { IUserManagement, UserManagementService } from '@galvin/core/build-plan/user-management';
import { LayoutUtilsService, ToastrType } from '@galvin/core/_base/crud';
import { GalvinDialogGenericService } from '@galvin/core/_base/general';
import { GenericConfig } from '@galvin/views/partials/content/general/galvin-dialog-generic';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import {
    ERolesBuildPlan,
    ERolesBuildPlanKeys
} from './../../../../core/auth/_enums/roles-build-plan.enum';

@Component({
    selector: 'grant-permission-dialog',
    templateUrl: './grant-permission-dialog.component.html',
    styleUrls: ['./grant-permission-dialog.component.scss']
})
export class GrantPermissionDialogComponent
    extends GenericConfig<GrantPermissionDialogComponent>
    implements OnInit
{
    loading = true;
    user!: IUserManagement;
    readonly subscription = new Subscription();
    userInfo!: { user: string; role: string };

    constructor(
        public i18n: TranslateService,
        public override dialogRef: MatDialogRef<GrantPermissionDialogComponent>,
        dialogService: GalvinDialogGenericService,
        @Inject(MAT_DIALOG_DATA)
        public override readonly data: any,
        private readonly layoutService: LayoutUtilsService,
        private readonly userService: UserManagementService,
        private readonly permissionManagementService: PermissionManagementService,
        private readonly location: Location
    ) {
        super(dialogService, dialogRef, data);
    }

    cancel(): any {
        this.dialogRef.close();
    }

    custom(): any {}

    edit(): any {}

    save(): any {
        this.subscription.add(
            this.permissionManagementService
                .addRoleToUser(<IGalvinUserPermission>{
                    email: this.data.user,
                    roles: [this.data.role]
                })
                .subscribe(
                    () => {
                        this.layoutService.showToastrNotification(
                            this.i18n.instant(
                                'BUILD_PLAN.PERMISSION_MANAGEMENT.DIALOG_GRANT_PERMISSION.PERMISSION_GRANTED'
                            )
                        );
                    },
                    undefined,
                    () => this.dialogRef.close()
                )
        );
    }

    ngOnInit(): void {
        this.location.replaceState('/build-plan/home');
        this.subscription.add(
            this.userService
                .getAllUsers()
                .pipe(map((users) => users.find((u) => u.email === this.data.user)))
                .subscribe(
                    (user) => {
                        this.user = user as IUserManagement;
                        this.userInfo = {
                            user: `${user?.name} ${user?.lastName} <${user?.email}>`,
                            role: this.data.role
                        };
                    },
                    undefined,
                    () => {
                        this.loading = false;
                        if (this.user) {
                            const userRoles = this.user.roles
                                ? (this.user.roles.rolesBuildPlan as ERolesBuildPlan[])
                                : [];
                            const dataRoles: ERolesBuildPlanKeys = this.data.roles.toString();

                            if (
                                'roles' in this.user &&
                                userRoles
                                    .map((p) => p.toString())
                                    .includes(ERolesBuildPlan[dataRoles])
                            ) {
                                this.layoutService.showToastrNotification(
                                    this.i18n.instant(
                                        'BUILD_PLAN.PERMISSION_MANAGEMENT.DIALOG_ADD_PERMISSION.MESSAGE_DUPLICATE_USER'
                                    ),
                                    ToastrType.warning
                                );
                                this.cancel();
                            }
                        }
                    }
                )
        );
    }
}
