import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

// Enviroments
import { environment } from '../../../../../../environments/environment';

// Interfaces
import { IDateDefault } from '../interfaces/date-default.interface';
import { IRelativeDate } from '../interfaces/relative-date.interface';
import { map } from 'rxjs/operators';
import {IDeletableValue} from '@galvin/core/build-plan/field-value-management/fvc-parameters/interfaces/deletable-parameter.interface';

@Injectable({
    providedIn: 'root'
})
export class DatesService {
    private readonly URL_DATES = 'build-plan/field-values-management';
    private readonly API_DATES_URL =
        `${environment.baseUrlApi}/` + this.URL_DATES + '/dates-default';
    private readonly API_RELATIVE_DATES =
        `${environment.baseUrlApi}/` + this.URL_DATES + '/dates-relative-default';

    constructor(private http: HttpClient) {}

    /**
     * Gets all dates default
     */
    getAllDatesDefault(): Observable<Array<IDateDefault>> {
        return this.http.get<any>(`${this.API_DATES_URL}`).pipe(map((res) => res.content));
    }

    /**
     * Gets all enable dates default
     */
    getAllDatesDefaultEnabledTrue(): Observable<Array<IDateDefault>> {
        return this.http.get<any>(`${this.API_DATES_URL}/enabled`).pipe(map((res) => res.content));
    }

    /*
     * Saves a date default
     */
    saveDateDefault(dateDefault: IDateDefault): Observable<IDateDefault> {
        return this.http
            .post<any>(`${this.API_DATES_URL}`, dateDefault)
            .pipe(map((res) => res.content));
    }

    /**
     * Updates a date default
     */
    updateDateDefault(dateDefault: IDateDefault): Observable<IDateDefault> {
        return this.http
            .put<any>(`${this.API_DATES_URL}`, dateDefault)
            .pipe(map((res) => res.content));
    }

    /**
     * Updates the date default status, if it's enabled or not
     */
    updateDateDefaultStatus(relativeDate: IDateDefault): Observable<IDateDefault> {
        return this.http
            .put<any>(`${this.API_DATES_URL}/${relativeDate.id}/update-status`, null)
            .pipe(map((res) => res.content));
    }

    /**
     * Updates a relative date status, if it's enabled or not
     */
    updateRelativeDateStatus(relativeDate: IRelativeDate): Observable<IRelativeDate> {
        return this.http
            .put<any>(`${this.API_RELATIVE_DATES}/${relativeDate.id}/update-status`, null)
            .pipe(map((res) => res.content));
    }

    /**
     * Get all relative dates
     */
    getAllRelativeDates(): Observable<Array<IRelativeDate>> {
        return this.http.get<any>(`${this.API_RELATIVE_DATES}`).pipe(map((res) => res.content));
    }

    /**
     * Get all relative dates enabled
     */
    getAllRelativeDatesEnabled(): Observable<Array<IRelativeDate>> {
        return this.http
            .get<any>(`${this.API_RELATIVE_DATES}/enabled`)
            .pipe(map((res) => res.content));
    }

    /**
     * Saves a relative date
     */
    saveRelativeDate(relativeDate: IRelativeDate): Observable<IRelativeDate> {
        return this.http
            .post<any>(`${this.API_RELATIVE_DATES}`, relativeDate)
            .pipe(map((res) => res.content));
    }

    /**
     * Updates a relative date
     */
    updateRelativeDate(relativeDate: IRelativeDate): Observable<IRelativeDate> {
        return this.http
            .put<any>(`${this.API_RELATIVE_DATES}`, relativeDate)
            .pipe(map((res) => res.content));
    }

    /**
     * Get list of relative-dates that can be deleted or not.
     */
    getDeletableRelativeDateParameters(): Observable<IDeletableValue[]> {
        return this.http.get<any>(
            `${this.API_RELATIVE_DATES}/can-delete`
        ).pipe(map(res => res.content));
    }

    /**
     * Deletes a relative-dates, if it's not used.
     * @Param id relative-dates
     */
    deleteRelativeDateParameter(id: number): Observable<any> {
        return this.http.delete<any>(
            `${this.API_RELATIVE_DATES}/${id}`
        );
    }

    /**
     * Get list of dates-default that can be deleted or not.
     */
    getDeletableDateDefaultParameters(): Observable<IDeletableValue[]> {
        return this.http.get<any>(
            `${this.API_DATES_URL}/can-delete`
        ).pipe(map(res => res.content));
    }

    /**
     * Deletes a dates-default and all its values, if it's not used.
     * @Param id dates-default
     */
    deleteDateDefaultParameter(id: number): Observable<any> {
        return this.http.delete<any>(
            `${this.API_DATES_URL}/${id}`
        );
    }

}
