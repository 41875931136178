import { Directive,  ElementRef,  Input, OnInit, Renderer2, } from '@angular/core';

@Directive({
    selector: '[textClipboard]'
})
export class TextClipboardDirective implements  OnInit{
    _textClipboard = '';

    constructor (private _elRef: ElementRef, private _renderer: Renderer2) {}

    ngOnInit() {
        this.setAttr();
    }

    private setAttr() {
        this._renderer.setAttribute(this._elRef.nativeElement, 'textclipboard', this._textClipboard?this._textClipboard:'');
    }

    @Input() set textClipboard(value: string) {
        if (value==null || value==''){
            this._textClipboard='';
            this.setAttr();
        }else
        if (this._textClipboard!=value){
            this._textClipboard=value;
            this.setAttr();
        }
    }
}
