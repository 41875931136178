import {
    AfterViewInit,
    Component,
    ElementRef,
    HostListener,
    Input,
    OnDestroy,
    OnInit,
    ViewChild
} from '@angular/core';
import { MenuAsideService } from '@galvin/core/_base/layout';
import { Subscription } from 'rxjs';

@Component({
    selector: 'sticky-table-header',
    templateUrl: './sticky-table-header.component.html',
    styleUrls: ['./sticky-table-header.component.scss']
})
export class StickyTableHeaderComponent implements OnInit, OnDestroy, AfterViewInit {
    private subscription = new Subscription();
    private stickyHeaderTHs!: HTMLElement[];
    private originalHeaderTHs!: HTMLElement[];
    private headerHeight!: number;
    private galvinHeader!: HTMLElement;
    private galvinSubHeader!: HTMLElement;
    private galvinMHeader!: HTMLElement;
    private cdkOverlayContainer!: HTMLElement;

    @ViewChild('container') containerRef!: ElementRef;
    @ViewChild('table') tableRef!: ElementRef;

    // Original table and table container elements to create the sticky header from
    @Input() tableElement!: HTMLElement;
    @Input() containerElement!: HTMLElement;

    constructor(private sidebarService: MenuAsideService) {}

    get container(): HTMLElement {
        if (this.containerRef) {
            return this.containerRef.nativeElement;
        }
        return this.createHtmlElement();
    }

    get galvinDesktopHeader(): HTMLElement {
        if (!this.galvinHeader) {
            const d = document.getElementById('kt_header');
            this.galvinHeader = d ? d : this.createHtmlElement();
        }
        return this.galvinHeader;
    }

    get galvinDesktopSubHeader(): HTMLElement {
        if (!this.galvinSubHeader) {
            const d = document.getElementById('kt_subheader');
            this.galvinSubHeader = d ? d : document.createElement('div');
        }
        return this.galvinSubHeader;
    }

    get galvinMobileHeader(): HTMLElement {
        if (!this.galvinMHeader) {
            const d = document.getElementById('kt_header_mobile');
            this.galvinMHeader = d ? d : this.createHtmlElement();
        }
        return this.galvinMHeader;
    }

    get overlayContainer(): HTMLElement {
        if (!this.cdkOverlayContainer) {
            const d = document.getElementById('div.cdk-overlay-container');
            this.cdkOverlayContainer = d ? d : this.createHtmlElement();
        }
        return this.cdkOverlayContainer;
    }

    ngOnInit() {
        this.subscription.add(
            this.sidebarService.sidebarOpenCloseListener$.subscribe(() => {
                this.setHeaderSizes();
            })
        );
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    ngAfterViewInit() {
        this.initStickyTableHeader();
    }

    private createHtmlElement(): HTMLElement {
        return document.createElement('div');
    }

    /**
     * Resize floating scroll if window was resized
     */
    @HostListener('window:resize')
    onResize(): void {
        setTimeout(() => {
            this.setHeaderSizes();
        }, 400);
    }

    /**
     * Fix scrollbar position on window scroll. If the window was scrolled past element's height, floating scroll is hidden
     */
    @HostListener('window:scroll')
    onScroll(): void {
        this.updateHeaderVisibility();
    }

    /**
     * If a click was performed on the fixed header, detect what's the corresponding element on the original header and
     * propagate the click to the original element
     *
     * @param event click event
     */
    @HostListener('click', ['$event'])
    onClick(event: Event) {
        let element: HTMLElement = event.target as HTMLElement;
        let selector = '';

        // If clicked element isn't a TH, navigate through the parents until its TH is found, and build the
        // querySelector for this element
        if (!this.isTHElement(element)) {
            while (!this.isTHElement(element)) {
                selector = this.getElementSelector(element) + (selector ? ' > ' + selector : '');
                element = element.parentElement ? element.parentElement : this.createHtmlElement();
            }
        }

        const atr = element.getAttribute('data-index');
        const index = parseInt(atr ? atr : '');
        if (isNaN(index)) {
            return;
        }

        const originalHeader = this.originalHeaderTHs[index];
        if (selector) {
            const target = originalHeader.querySelector(selector) as HTMLElement;
            target.click();
        } else {
            originalHeader.click();
        }
    }

    /**
     * Init the floating scroll component
     */
    initStickyTableHeader(): void {
        if (!this.tableElement || !this.containerElement) {
            return;
        }
        this.updateHeaderContent();

        this.containerElement.onscroll = () => {
            this.container.scrollLeft = this.containerElement.scrollLeft;
        };

        const observer = new MutationObserver(() => {
            this.updateHeaderContent();
        });
        observer.observe(this.tableElement, { attributes: true, childList: true, subtree: true });
    }

    /**
     * Update the fixed header size
     */
    private setHeaderSizes(): void {
        if (this.stickyHeaderTHs && this.stickyHeaderTHs.length > 0) {
            this.tableElement.querySelectorAll('th').forEach((th, index) => {
                const header = this.stickyHeaderTHs[index];
                header.style.width = window.getComputedStyle(th).getPropertyValue('width');
                header.style.minWidth = window.getComputedStyle(th).getPropertyValue('width');
                header.setAttribute('data-index', String(index));
            });
            this.container.style.maxWidth = window
                .getComputedStyle(this.containerElement)
                .getPropertyValue('width');
            this.container.style.top = this.getGalvinHeaderHeight() + 'px';
            const theadE = this.tableElement.querySelector('thead');
            this.headerHeight = theadE ? theadE.offsetHeight : 0;
            this.updateHeaderVisibility();
        }
    }

    /**
     * Updated the content of the sticky header if any changes were detected in the original element
     */
    private updateHeaderContent(): void {
        if (this.tableRef && this.tableElement) {
            this.tableRef.nativeElement.innerHTML = '';
            const table = this.tableElement.cloneNode(true) as HTMLElement;
            this.tableRef.nativeElement.append(table.querySelector('thead'));
            this.stickyHeaderTHs = Array.from(this.tableRef.nativeElement.querySelectorAll('th'));
            this.originalHeaderTHs = Array.from(this.tableElement.querySelectorAll('th'));
            this.setHeaderSizes();
        }
    }

    /**
     * Updates if the sticky header should be visible or not. It should only be visible if the current window
     * scroll position is within the original container's boundaries
     */
    private updateHeaderVisibility(): void {
        if (
            this.tableElement &&
            !(this.overlayContainer && this.overlayContainer.querySelector('.cdk-overlay-backdrop'))
        ) {
            const windowScrollPosition = window.scrollY + this.getGalvinHeaderHeight();
            const headerPosition =
                this.tableElement.getBoundingClientRect().height +
                this.tableElement.offsetTop -
                this.headerHeight;
            const isScrollPastTable = headerPosition <= windowScrollPosition;
            const isScrollBeforeTable = this.tableElement.offsetTop >= windowScrollPosition;
            this.container.style.display =
                isScrollPastTable || isScrollBeforeTable ? 'none' : 'block';
            this.container.scrollLeft = this.containerElement.scrollLeft;
        }
    }

    /**
     * Get the height of the galvin header (considering if the desktop or mobile header is being displayed).
     * The table sticky header is positioned right below this header.
     */
    private getGalvinHeaderHeight(): number {
        if (this.galvinDesktopHeader && this.galvinDesktopHeader.offsetHeight > 0) {
            return this.galvinDesktopHeader.offsetHeight + this.galvinDesktopSubHeader.offsetHeight;
        }

        return this.galvinMobileHeader ? this.galvinMobileHeader.offsetHeight : 0;
    }

    /**
     * Given a HTMLElement, return the selector for this element. i.e.: 'span.some-class.another-class'
     *
     * @param element
     * @private
     */
    private getElementSelector(element: HTMLElement): string {
        let selector = element.tagName.toLowerCase();
        if (element.classList.value) {
            selector += '.' + element.classList.value.replace(/\s/g, '.');
        }
        return selector;
    }

    private isTHElement(element: HTMLElement): boolean {
        return element.tagName.toLowerCase() === 'th';
    }
}
