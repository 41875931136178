import { ACCESSORY } from '@galvin/core/build-plan/accessory-management';
import { CONFIGURATION } from '../../configuration-management/i18n/configuration.en';
import { BUILD_PLAN_STATUS_APPROVALS } from '../status-approvals/i18n/status-approvals.en';

export const BUILD_PLAN_MANAGEMENT = {
    GPM_TO_LOAD: '<GPM to load>',
    DETAILS_STATUS: 'Status',
    BUILD_PLAN_STATUS: 'Build Plan Status',
    DETAILS_CREATION: 'Creation',
    DETAILS_RELEASE: 'Release',
    DETAILS_LOCKDOWN: 'Lockdown',
    DETAILS_UNDER_APPROVAL_STATUS: 'Under Approval',
    DETAILS_OPEN_TO_REQUESTORS_STATUS: 'Open to Requestors',
    DETAILS_LOCKDOWN_STATUS: 'Lockdown',
    DETAILS_UNDER_DPM_APPROVAL_STATUS: 'Under DPM Approval',
    DETAILS_BUILDING_STATUS: 'Building',
    BUILD_PLAN_CONFIGURATIONS_CANCELLED:
        'This Build Plan has Cancelled Configurations that are not considered in Total Calculation',
    BUILD_PLAN_ACCESSORIES_CANCELLED:
        'This Build Plan has Cancelled Accessories that are not considered in Total Calculation',
    NOT_FOUND_OR_ACCESS_DENIED: 'Build Plan not found or you don\'t have access permission.',
    TOTAL: 'TOTAL',
    TOTAL_PORTABLE: 'TOTAL <br/> PORTABLE',
    TOTAL_ACCESSORIES: 'TOTAL <br/> ACCESSORIES',

    TOTAL_BOARD: 'TOTAL <br/> BOARD',
    TOTAL_SUBBOARD: 'TOTAL <br> SUBBOARD',
    TOTAL_UNIT: 'TOTAL <br/> UNITS',
    PRODUCT_INFO: {
        LABEL: 'Product Info',
        ERASE_FIELD: 'Erase data on the given field',
        EDIT_FIELD: 'Edit data on the given field',
        INTERNAL_PRODUCT_NAME: 'Internal Product Name',
        ENABLE_NOTIFICATIONS_EMAILS: 'Enable Notifications/Emails',
        ACTIVE: 'Active',
        PRODUCT_DEVICE_NAME: 'Product Device Name',
        GPM: 'GPMs',
        HWPM: 'HWPMs',
        PM: 'PMs',
        REGION: 'Region',
        TERRITORY: 'Territory',
        TARGET_COUNTRY: 'Target Country',
        CUSTOMER: 'Customer',
        TEAM: 'Team',
        PURPOSE: 'Purpose',
        LE: 'LE',
        SHIP_TO_COUNTRY: 'Ship to Country',
        CDC_LOCATION: 'CDC Locations',
        REQUEST_OWNER: 'Request Owner',
        FINAL_RECIPIENT: 'Final Recipient',
        HW_VARIANT: 'HW Variant',
        BUILD: 'Build',
        CORE_ID_HEADER: 'Core ID',
        ACTIONS_HEADER: 'Actions',
        PMD_PROJECT_ID: 'PMD Project IDs',
        PMD_PROJECT_NAME: 'PMD Project Names',
        GROUPS: 'Groups',
        CUSTOMS_VALUATIONS_UNITS: 'Customs Valuation / Unit ($)',
        NET_WEIGHT: 'Net Weight (g)',
        VALUE_CDC_TOOLTIP: 'This value is used by CDC'
    },
    BUILD_DATES: {
        BUILD_SMT_START_DATE_POR: 'Build SMT Start Date (POR)',
        BUILD_DATE_CWV: 'Build Date (CWV Actual)',
        BUILD_90_ASSY_FINISH_DATE_POR: 'Build 90% Assy finish date (POR)',
        BUILD_90_ASSY_FINISH_DATE_CWV: 'Build 90% Assy finish date (CWV/Actual)',
        LOCKDOWN_DATE: 'Lockdown date',
        RELEASE_DATE: 'Release date'
    },
    ESTIMATED_TIME_OF_ARRIVAL: {
        LABEL: 'Relative Configuration Estimated Time of Arrival',
        DAYS: 'days',
        ADD: 'Create new relative configuration',
        ADD_DIALOG_TITLE: 'New <b>Relative Configuration</b> association',
        DELETE_CONFIRMATION: 'Are you sure you want to delete relative date: <b>{{name}}</b>?',
        DELETE_CONFIRMATION_DIALOG_TITLE: 'Confirm'
    },
    PLANNED_QUANTITY: {
        PLANNED_QUANTITY: 'FVC Planned Quantity',
        CURRENT_QUANTITY: {
            VALUE: 'FVC Current Quantity',
            TOOLTIP: 'Calculated based on BP Requests'
        },
        FINISHED_QUANTITY: 'FVC Finished Quantity'
    },
    COMMON: {
        COLUMNS: 'Columns'
    },
    STATUS_APPROVALS: BUILD_PLAN_STATUS_APPROVALS,
    CONFIGURATION_MANAGEMENT: CONFIGURATION,
    ACCESSORY_MANAGEMENT: ACCESSORY,
    DELETE_CONFIRMATION_TITLE: 'Confirm Build Plan deletion',
    DELETE_CONFIRMATION:
        'To confirm the deletion of <b>{{ name }}</b>, please type its name on the input below, then press <b>Delete</b>.',
    DELETE_SUCCESS: 'Build Plan {{ name }} deleted successfully.',
    COLLAPSE_HEADER_INFO: 'Collapse Header Information',
    UNCOLLAPSE_HEADER_INFO: 'Uncollapse Header Information',
};
