import { IPrototypeRequestI18n } from './prototype-request-i18n.interface';

export const PROTOTYPE_REQUEST: IPrototypeRequestI18n = {
    TITLE: 'Prototype Request',
    REQUEST: {
        TITLE: 'Request',
        CARDS: {
            RAM: 'RAM',
            STORAGE: 'Storage',
            CID: 'CID',
            REQUESTED_SHORT: 'Rqstd.',
            SECURE_MARKING: 'Secure Marking',
            NO_MARKING: 'No Marking',
            FVC_SECURE: 'Secure',
            FVC_NO_SECURE: 'Non-Secure',
            NO_DATA: 'No configuration found for the selected build plan and team.',
            NO_DATA_FILTER: 'No configuration found for the selected combination of filters.',
            LOAD_ERROR:
                'Unable to load the list of prototype requests. Please try again in a few moments.',
            TOOLTIP_DETAIL: 'See details',
            TOOLTIP_SPECIAL_REQUIREMENT: 'Special Requirement(s) requested for this prototype',
            FVC: 'FVC',
            BR_NUMBER: 'BR#',
            XCVR_KIT: 'XCVR Kit #',
            SKU: 'SKU',
            BUILD_DATE: 'Build Date',
            CID_FROM_RECIPE: 'CID from Build Recipe',
            CANCELED_CONFIGURATION: 'This configuration has been cancelled',
            HIDDEN: 'Hidden',
            ARCHIVED: 'Archived',
            NOT_HIDDEN: 'Not Hidden',
            NOT_ARCHIVED: 'Not Archived'
        }
    },
    HEADER: {
        BUILD_PLAN_SELECTOR: {
            SELECT_BUILD_PLAN: 'Select Build Plan',
            SEARCH_BUILD_PLAN: 'Search Build Plan',
            NEW: 'New',
            NEW_BUILD_PLANS: 'New Build Plans',
            ALREADY_REQUESTED: 'Already Requested',
            LOCKING_SOON: 'Locking Soon',
            UNDER_APPROVAL: 'Under Approval',
            CLOSED: 'Closed',
            TOTAL_DEVICES_REQUESTED: 'Total Devices Requested',
            LOCK_DOWN_DATE: 'Lock Down Date',
            BUILD_PLAN_STATUS: 'Build Plan Status',
            LOAD_ERROR:
                'Unable to load the list of Build Plans. Please try again in a few moments.',
            NO_BUILD_PLANS_FILTER: 'No Build Plans found.'
        },
        TEAM_VIEW: 'Team View',
        FULL_VIEW: 'Full View',
        TEAM_SELECTOR: {
            ALL: 'All',
            ALL_FILTERED: 'Select All Filtered',
            TEAM_SELECTED_TITLE: ' team(s) selected',
            TEAM: 'Team',
            SELECT_TEAM: 'Select Team',
            SEARCH_TEAM: 'Search Team',
            NO_TEAMS_FILTER: 'No teams found.',
            LOAD_ERROR: 'Unable to load the list of Teams. Please try again in a few moments.',
            CDC_LOCATION: 'CDC Location: ',
            SHIP_TO_LOCATION: 'Ship To Location: ',
            REQUEST_OWNER_USER: 'Request Owner :',
            FINAL_RECIPIENT_USER: 'Final Recipient :'
        },
        FILTERS: {
            SEARCH_DEVICE: 'Search device',
            ALL: 'All',
            ALREADY_REQUESTED: 'Already Requested',
            TO_REQUEST: 'To Request',
            CONFIGS_WITH_REQUESTS: 'Configs With Requests',
            TEAMS_WITH_REQUESTS: 'Teams With Requests',
            REQUESTS_WITH_COMMENTS: 'Requests With Comments',
            REQUESTS_WITH_SPECIAL_REQ: 'Requests With Special Requirements',
            RAM_SIZE: 'RAM Size',
            STORAGE_SIZE: 'Storage Size',
            FRONT_COLOR: 'Front Color',
            REAR_COLOR: 'Rear Color',
            BUILD_RECIPE: 'Build Recipe',
            SECURE_MARKING: 'Secure Marking',
            FVC_SECURE: 'Secure',
            FVC_NO_SECURE: 'Non-Secure',
            SOURCING: 'Sourcing',
            MARKET_REGION: 'Market Region',
            PRODUCT_SKU: 'Product SKU',
            CMF_COLOR: 'CMF Color',
            SIM: 'SIM',
            UNIT_TYPE: 'Unit Type',
            BR_NUMBER: 'BR#',
            XCVR_KIT: 'XCVR Kit #',
            PCBA_KIT: 'PCBA Kit #',
            BUILD_DATE: 'Build Date',
            SECURE: 'Secure',
            FVC: 'FVC',
            ALL_DATES: 'All Dates',
            ALL_BUILD_PLANS: 'All Build Plans',
            ALL_STATUS: 'All Status',
            ALL_BUILD_PLAN_STATUS: 'All Build Plan Status',
            CHANGE_TYPE: 'Change Type',
            OPERATION: 'Operation',
            INCREASE: 'Increase',
            DECREASE: 'Decrease',
            EQUAL: 'Equal',
            ALL_REQUESTERS: 'All Requesters',
            ALL_REQUESTS: 'All Requests',
            ALL_HW_REVISION: 'All HW/Revision',
            ALL_APPROVAL_STATUS: 'All Approval Status',
            SEARCH_INPUT_PLACEHOLDER: 'Search table entries',
            REQUESTED_BY_ME: 'Requested by Me',
            GLOSSARY_BTN_LABEL: 'Glossary',
            HW_REVISION: 'HW/Revision',
            SELECTOR_TEAMS_PARAMETERS: 'Filter by Teams Parameters',
            CONFIG_STATUS: 'Config. Status',
            REQUEST_TYPE: 'Request Type',
            HIDDEN: 'Hidden',
            ARCHIVED: 'Archived',
            PROTOTYPE_REQUEST: 'Prototype Requested',
            PROTOTYPE_EDIT: 'Request Updated',
            CONFIGURATION_CHANGE: 'Configuration Updated',
            CONFIGURATION_CHANGE_STATUS: 'Config Status Canceled Updated',
            CONFIGURATION_DELETE: 'Configuration Deleted',
            BP_TEAM_CHANGE: 'Team Updated',
            BP_TEAM_DELETE: 'Team Deleted'
        },
        HW_REVISION_TOTALS: {
            TOTAL: 'Total <br> Units',
            TOTAL_PORTABLE: 'Total Portable'
        },
        HW_REVISION_TOTALS_BOARD: {
            TOTAL: 'Total Board'
        },
        HW_REVISION_TOTALS_SUBBOARD: {
            TOTAL: 'Total SubBoard'
        }
    },
    MY_PROTOTYPES: {
        NO_REQUESTS: 'No Requests',
        REQUESTED_RECEIVED: 'Requested/Received',
        REQUEST_DATE: 'Request Date',
        ESTIMATED_TIME_ARRIVAL_INFO: {
            TITLE: 'Estimated Time of Arrival (ETA) is:',
            SHIP_TO_LOCATION:
                '- Relative Date, if its country is the same as configuration Ship to Location\'s',
            CDC_LOCATION:
                '- Relative Date, if its country is the same as configuration CDC location\'s',
            BUILD_DATE_POR: '- Otherwise use Build Date (POR) from configuration'
        },
        CALENDAR: {
            CLEAN_DATES: 'Clean Dates',
            THESE_DATES_WILL_FILTER_REQUEST_DATE:
                'These dates will filter the Request Date of Prototypes requested.',
            THESE_DATES_WILL_FILTER_REQUEST_DATE_ACCESSORY:
                'These dates will filter the Request Date of Accessory requested.'
        },
        NO_DATA: 'There are no prototypes requested for the selected team(s).'
    },
    PROTO_REQUEST_TABLE: {
        OVERWRITE_REQUESTS_JUSTIFICATION_SPECIAL_REQUIREMENTS_AND_COMMENTS:
            'Overwrite the requests justification, special requirements and comments',
        DISABLED_BECAUSE_CHANGED_IN_REVIEW: 'Disabled because there are several custom comments ou special requirements changed in Request and Review screen, if you want to use it, undo all requests',
        EDIT_JUSTIFICATION_AND_SPECIAL_REQUIREMENTS: 'Edit justification and special requirements',
        EDIT_JUSTIFICATION: 'Edit justification',
        ADD_REQUEST: 'Add request(s) to overwrite',
        REMOVE_REQUEST: 'Remove request(s) from overwrite',
        BLOCKED_REQUEST: 'Action blocked for this cell',
        SPECIAL_REQUIREMENTS: 'Special Requirements',
        SET: 'Set',
        INCREASE: 'Increase',
        REDUCE: 'Reduce',
        UNDO_ALL: 'Undo all',
        MASS_EDIT_REQUEST: 'MASS EDIT {{collectionSize}} REQUESTS selected',
        ADDITIONAL_COMMENTS: 'Additional Comments',
        WRITE_YOUR_COMMENTS: 'Click to write your comment',
        REQUEST_AND_REVIEW: 'Review and request',
        CONFIRM_REVIEW: 'Confirm request',
        BACK_TO_FULLVIEW: 'Back to requests',
        REQUEST_BEING_MODIFIED: 'Request being modified',
        REQUEST_DIFF: 'Request difference',
        REQUEST_OWNER: 'Request Owner',
        FINAL_RECIPIENT: 'Final recipient',
        NO_REQUESTS: 'No requests to review.',
        TEAM: 'Team',
        GROUP: 'Group',
        CDC_LOCATION: 'CDC Location',
        SHIP_TO: 'Ship to',
        SOURCE: 'Source',
        CONFIRM_DELETE_REQUEST_FROM_REVIEW_TITLE: 'Confirm',
        CONFIRM_DELETE_REQUEST_FROM_REVIEW_MESSAGE:
            'Are you sure you want to remove from review this request?',
        FULL_VIEW_CELLS_SUMMARY: {
            SUM: 'Sum',
            COUNT: 'Count',
            COUNT_SELECTED: 'Count Selected',
        },
        REQUESTS_BREAKDOWN_DIALOG: {
            TITLE: 'Requests Breakdown',
            BODY: 'Those are your requests breakdown. The following will happen if you proceed',
            TABLE: {
                REQUESTS_TO_APPROVAL: 'Requests that are going to Approval Process:',
                REQUESTS_SKIPPING_APPROVAL: 'Requests that are skipping Approval Process:',
                REQUESTS_IGNORED: 'Requests that are ignored:',
                TEAM_ALIAS: 'Team Alias',
                CONFIGURATION_ALIAS: 'Configuration Alias',
                CONF_ID: 'Conf. ID#',
                CONF_STATUS: 'Conf. Status',
                QUANTITY: 'Quantity',
            }
        }
    },
    DIALOG: {
        REQUEST_BY_APPROVAL: {
            COMMENT_REASON: 'Comment (Reason)',
            PCM_CR_LIST: 'PCM CR List',
            SAVE: 'Send',
            TITLE: 'Request by Approval'
        },
        LABELS: {
            TEAM_INFO: 'Team Info',
            QUANTITY: 'Quantity',
            HW_REVISION: 'HW Revision',
            DUE_DATE: 'Due Date',
            BUILD_SITE: 'Build Site',
            PRODUCT_SKU: 'Product SKU',
            MARKET_REGION: 'Market/Region',
            DEVICES_TO_ACTIVATE: 'Devices to Activate',
            JUSTIFICATION: {
                JUSTIFICATION: 'Justification',
                AUTOMATED_TESTING: 'Automated Testing',
                MANUAL_TESTING: 'Manual Testing',
                CARRIERS: 'Carriers',
                SHOW_OFF: 'Show Off',
                MARKETING: 'Marketing',
                OTHER: 'Other',
                ADDITIONAL_COMMENTS: 'Additional Comments'
            },
            REQUEST: 'Request',
            REQUEST_APPROVAL: 'Request by Approval',
            REQUEST_OWNER: 'Request Owner',
            FINAL_RECIPIENT: 'Final Recipient',
            CDC_LOCATION: 'CDC Location',
            SHIP_TO_LOCATION: 'Ship To Location',
            GROUP: 'Group',
            ACTIVITY: 'Activity',
            IS_NEW_HW_SPEC: 'New HW Spec Requirement',
            SPECIAL_REQUIREMENT: 'Special Requirement',
            ALL_DEVICES_TO_BE_ACTIVATED: 'All devices are expected to be activated',
            DIFF_DEVICES:
                'This column indicates the differences between the previously requested quantity number to the quantity now modified.',
            ALL_DEVICES_TOOLTIP:
                'This will create a review process by HW PMs as it can affects the prototype manufacturing. It\'s possible this will become a new Configuration/FVC, so, make sure you this is aligned properly with the HW PMs before requesting it.',
            ACTIVITY_TOOLTIP:
                'The prototypes activation will be an important information to be able to measure if all or only part of these devices are being used or not based on network usage',
            ACTIVITY_INFO_TOOLTIP: 'This info might take 10-15 seconds to take place',
            REQUIRED_FIELD: 'Required field',
            NO_CHANGES: 'No changes to submit',
            INCREASED_QUANTITY: 'Increased Quantity',
            DECREASED_QUANTITY: 'Decreased Quantity',
            EQUAL_QUANTITY: 'Quantity Unchanged',
            CHECKED: 'Checked',
            UNCHECKED: 'Unchecked',
            NO_JUSTIFICATIONS: 'all unchecked',
            DEVICES_ACTIVATED_ACTIVITY: 'All devices to be activated',
            DEVICES: 'devices',
            COMMENT: 'Comment',
            INSERT_ACTIVITY: 'Prototype Requested',
            EDIT_ACTIVITY: 'Requested Prototype Updated',
            NO_ACTIVITY_DATA: 'This configuration has no activity data recorded yet.',
            ERROR_ACTIVITY: 'Activity information could not be loaded at this moment.',
            LOADING_ACTIVITY: 'Loading activity information...',
            TOOLTIP_REQUEST_BUTTON:
                'You can\'t make a prototype request because the configuration status is {{configStatus}}',
            ACCESSORY_TOOLTIP_REQUEST_BUTTON:
                'You can\'t make an accessory request because the accessory status is {{accessoryStatus}}',
            REQUEST_SPECIAL_REQUIREMENTS_CHANGED:
                '<One Special Requirements was updated by propagation>',
            REQUEST_CONFIGURATION_OLD_STATUS: 'Previous configuration status: {{ oldStatus }}',
            REQUEST_CONFIGURATION_CHANGED: '<This Configuration was updated on the Build Plan>',
            REQUEST_CONFIGURATION_DELETED: '<This Configuration was deleted on the Build Plan>',
            REQUEST_TEAM_CHANGED: '<This Team was updated on the Build Plan>',
            REQUEST_TEAM_DELETED: '<This Team was deleted on the Build Plan>',
            CONSENT_CONFIRM_REQUEST_TITLE: 'Confirm Request',
            CONSENT_CONFIRM_REQUEST_TEAM_MESSAGE:
                'You aren\'t the first and final recipient of the team below. Do you want to perform the request anyway?',
            CONSENT_CONFIRM_REQUEST_TEAMS_MESSAGE:
                'You aren\'t the first and final recipient of the {{quantity}} teams below. Do you want to perform the request anyway?'
        }
    },
    REQUEST_REVIEW: {
        CONFIGURATION_NOT_OPEN:
            'Some previously selected configurations are no longer opened. Please perform the requests again.',
        TEAMS_NOT_FOUND:
            '{{total}} of the previously selected teams have not been found. Please perform the requests again.',
        CONFIGURATION_NOT_FOUND:
            '{{total}} of the previously selected configurations have not been found. Please perform the requests again.',
        COMPLETE_SUCCEFULLY: 'All requests were performed successfully',
        COMPLETE_WITH_ERROR:
            'The previously ongoing request is no longer available. Please, perform the requests again',
        CONFIRM_REQUEST_DIALOG_TITLE: 'Confirm request review',
        CONFIRM_REQUEST_DIALOG_DESCRIPTION: 'Are you sure you want to perform the requests?',
        TO_BE_DEFINED: 'To be Defined',
        SPLIT_CHANGE_REQUEST:
            'There are configurations "Locked, In Transit, Manufactured, Completed" mixed up with "New, Pending Requirements, Open". Please, split your change request'
    },
    APPROVE_REQUEST: {
        ARE_YOU_WANT: 'Do you really want <strong>{{option}}</strong> this request?',
        DIALOG_TITLE: 'Approval confirmation',
        DIALOG_LEAVE_COMMENT: 'Leave a comment',
        DIALOG_PLACE_HOLDER: 'rejected because...',
        APPROVE: 'Approve',
        REJECT: 'Reject',
        CANCEL: 'Cancel',
        APPROVE_WITH_AMOUNT: 'Approve {{amount}} Request(s)',
        REJECT_WITH_AMOUNT: 'Reject {{amount}} Request(s)',
        CANCEL_WITH_AMOUNT: 'Cancel {{amount}} Request(s)',
        MASS_DIALOG_TITLE: '{{option}} confirmation',
        MASS_DIALOG_MESSAGE: 'Do you really want to <strong>{{option}}</strong> those <strong>{{qty}}</strong> request(s)?',
        APPROVAL_SAVED: 'Approval saved successfully!',
        APPROVAL_SAVING: 'Saving...'
    },
    MASS_EDIT_LABEL: 'MASS EDIT {{selectionSize}} REQUEST selected'
};
