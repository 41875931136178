import { Injectable } from '@angular/core';
import { INotification } from '../notification/notification.interface';

export const LAST_NOTIFICATION = 'last_notification';
export const NOTIFICATION_STORAGE = 'notification_storage';


/**
 * Helper for handling values on local storage
 *
 * @export
 * @class LocalStorageHelper
 */
@Injectable({
    providedIn: 'root'
})
export class NotificationStorageHelper {

    /**
     * Put a new string value on local storage given a key identifier
     *
     * @param {string} key
     * @param {string} value
     * @memberof LocalStorageHelper
     */
    public setItem(key: string, value: string) {
        localStorage.setItem(key, value);
    }

    /**
     * Get a string value from local storage given a string key
     *
     * @param {string} key
     * @returns {string}
     * @memberof LocalStorageHelper
     */
    public getItem(key: string): string {
        return localStorage.getItem(key) as string;
    }


    /**
     * @description Retrieve the last date time  when  called notifications
     *
     * @returns {number} time
     */
    public getLastDateNotification(): number|null {
        return this.getItem(LAST_NOTIFICATION)?+this.getItem(LAST_NOTIFICATION):null;
    }
    /**
     * @description Set the last date time  when  called notifications
     *
     */
    public setLastDateNotification(last: string) {
        if (last) {
            this.setItem(LAST_NOTIFICATION, last);
        }
    }


    /**
     * Put the notifications into the local storage
     *
     * @param {INotification} notifications
     * @memberof INotification
     */
    public setNotificationCache(notifications: INotification[]) {
        if (notifications) {
            this.setItem(NOTIFICATION_STORAGE, JSON.stringify(notifications));
        }
    }

    /**
     * Get notifications from local storage
     *
     * @returns {INotification}
     * @memberof INotification
     */
    public getNotificationCache(): INotification[] {
        if (!this.getItem(NOTIFICATION_STORAGE)){
            return [];
        }
        return JSON.parse(this.getItem(NOTIFICATION_STORAGE));
    }

}