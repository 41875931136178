import { HwRevisionUtils } from './../../../../../../core/build-plan/prototype-request-management/utils/hw-revision.utils';
/* eslint-disable indent */
import { HttpErrorResponse } from '@angular/common/http';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component, ContentChild, ElementRef, EventEmitter,
    HostBinding,
    Input,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
    MAT_MOMENT_DATE_ADAPTER_OPTIONS,
    MomentDateAdapter
} from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker, MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { LayoutUtilsService, ToastrType } from '@galvin/core/_base/crud/utils/layout-utils.service';
import {
    COLUMN_NAME,
    ISuggestAliasInterface,
    SuggestAliasService
} from '@galvin/core/_base/layout';
import { ConfigurationBufferPercentPipe } from '@galvin/core/_base/layout/pipes/configuration-buffer-percent.pipe';
import { ConfigurationConstants } from '@galvin/core/build-plan/configuration-management';
import {
    IConfigurationColumnValue,
    IConfigurationTableValue,
    IConfigurationUpdateValue
} from '@galvin/core/build-plan/configuration-management/interfaces/configuration-table-value.interface';
import { EConfigurationType } from '@galvin/core/build-plan/configuration-management/interfaces/configuration-type.enum';
import { ConfigurationsManagementService } from '@galvin/core/build-plan/configuration-management/services/configurations-management.service';
import { EFieldType, FvcParametersService, IFieldValue, ScopesService, TeamParameterService } from '@galvin/core/build-plan/field-value-management';
import {
    EColors,
    EDataType
} from '@galvin/core/build-plan/field-value-management/enums/data-type.enum';
import { EConfigurationStatus, EScopeColumnType } from '@galvin/core/build-plan/management';
import {
    EConfigurationStatusColor,
    EConfigurationStatusColorKeys
} from '@galvin/core/build-plan/management/enums/type-status-color';
import {
    EConfigurationStatusIcon,
    EConfigurationStatusIconKeys
} from '@galvin/core/build-plan/management/enums/type-status-icon.enum';
import { ETeamColumnType, ITeamManagementI18n } from '@galvin/core/build-plan/team-management';
import { UtilsFlag } from '@galvin/core/utils/utils.flag';
import { UtilsForm } from '@galvin/core/utils/utils.form';
import { StyleUtils } from '@galvin/core/utils/utils.style';
import { UnderCalendarHeaderComponent } from '@galvin/views/pages/build-plan/build-plan-management/configurations/under-calendar-header/under-calendar-header.component';
import { GalvinConfirmGenericComponent } from '@galvin/views/partials/content/general/galvin-confirm-dialog-generic/galvin-confirm-dialog-generic.component';
import { NgSelectComponent } from '@ng-select/ng-select';
import { TranslateService } from '@ngx-translate/core';
import { Moment } from 'moment';
import { EMPTY, Observable, Subscription, of } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { Key } from 'ts-key-enum';
import { GenericFVCFieldColumnRefDirective } from './generic-fvc-field-column.directive';
import { IDialogData } from '@galvin/core/_base/general';
import { UserManagementDialogComponent } from '../../../../../partials/layout/user-management-dialog/user-management-dialog.component';
import { IUserManagement } from '@galvin/core/build-plan/user-management/interfaces/user-management.interface';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap/tooltip/tooltip';

export enum EStatus {
    READ = 'READ',
    EDIT = 'EDIT',
    LOADING = 'LOADING',
    SAVING = 'SAVING'
}

export interface IGenericFvcFieldData {
    row: any;
    value: IConfigurationTableValue;
    column: IConfigurationColumnValue;
    options?: any[];
}

export type GenericFvcFieldFormatViewFn = (v: any) => string;
export type GenericFvcFieldEditFn = (v: any) => void;
export type GenericFvcFieldLoadOptionsFn = (v: IGenericFvcFieldData) => Promise<any[]>;

@Component({
    selector: 'generic-fvc-field',
    templateUrl: './generic-fvc-field.component.html',
    styleUrls: ['./generic-fvc-field.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
        },
        { provide: MAT_DATE_FORMATS, useValue: UtilsForm.matDateFormat() }
    ]
})
export class GenericFvcFieldComponent implements OnInit {

    @HostBinding('class') private classname = 'galvin-fvc-field';

    public field: any = {
        id: null,
        value: null,
        enabled: true,
        item: null,
        displayValue: null
    };
    public oldField: any = {
        id: null,
        value: null,
        enabled: true,
        item: null,
        displayValue: null
    };
    public isSaving = false;
    public isColor!: boolean;
    public validColor!: boolean;
    public isHw = false;
    public isLoadingOptions = false;
    public YES: string;
    public NO: string;
    public provideSelectText!: string;
    public dirty!: boolean;
    public readonly CONFIG_STATUS = ConfigurationConstants.CONFIG_STATUS;
    public isNewParameter = false;
    public formFvcValue!: FormGroup;
    public fieldValues: IFieldValue[] = [];
    public load = true;
    public inputCheck = false;
    public isAddNewValue = false;
    public value_to_add!: HTMLInputElement;
    public hasAddDialogOpen = false;
    public readonly FVC_VALUE =  EConfigurationType.FVC_VALUE;
    public readonly SCOPE_VALUES = EScopeColumnType.SCOPE_VALUES;
    public readonly TEAM_VALUES = ETeamColumnType.TEAM_VALUES;
    public labels!: ITeamManagementI18n;
    public typesFromConfiguration: string[] = [
        EConfigurationType.CONFIGURATION,
        EConfigurationType.CONFIGURATION_FACTORY,
        EConfigurationType.CONFIGURATION_STATUS,
        EConfigurationType.DATES_DEFAULT,
        EConfigurationType.RELATIVE_DATES,
        EConfigurationType.FVC_VALUE
    ];
    @Output()
        loadOptions = new EventEmitter<IGenericFvcFieldData>();
    @Output()
        save = new EventEmitter<boolean>();
    @Output()
        openDialogDateDefault = new EventEmitter<any>();
    readonly calendarHeader = UnderCalendarHeaderComponent;
    @Output() saveRelative = new EventEmitter<any>();
    @Output() configurationStatusChange = new EventEmitter<EConfigurationStatus[]>();

    @ViewChild(MatDatepicker) dataPicker: any;
    @ViewChild('genericSelectField') ngselect?: NgSelectComponent;
    subscriptionHeaderDatePicker!: Subscription;
    @Output()
        openDialogTemporaryDisableDate = new EventEmitter<any>();
    @Input() validationLabel: any;
    @Input() requestFinished$!: Observable<any>;
    @ContentChild(GenericFVCFieldColumnRefDirective)
    genericFVCColumnRef!: GenericFVCFieldColumnRefDirective;
    private readonly SYSTEM_HW_REVISION = 'SYSTEM_HW_REVISION';
    private readonly COLUMN_SCOPE_NAME = 'scope alias';
    private readonly COLUMN_TEAM_ALIAS = 'team alias';
    private readonly LOCATION = 'LOCATION';
    private readonly IS_TEMPORARY = 'IS_TEMPORARY';
    private readonly SKU_MATRIX_TYPE = 'SKU_MATRIX';

    private readonly LOCATION_COLUMNS = [
        ETeamColumnType.CDC_LOCATION.toString(),
        ETeamColumnType.SHIP_TO.toString()
    ];


    constructor(
        public cdr: ChangeDetectorRef,
        private configurationService: ConfigurationsManagementService,
        private i18n: TranslateService,
        private utilsFlag: UtilsFlag,
        private sanitizer: DomSanitizer,
        private suggestAliasService: SuggestAliasService,
        private layoutUtilsService: LayoutUtilsService,
        private elRef: ElementRef<HTMLElement>,
        private configurationBufferPercentPipe: ConfigurationBufferPercentPipe,
        private dialog: MatDialog,
        private fvcParametersService: FvcParametersService,
        private teamParameterService: TeamParameterService,
        private scopesService: ScopesService,
    ) {
        this.YES = this.i18n.instant('COMMON.BUTTONS.YES');
        this.NO = this.i18n.instant('COMMON.BUTTONS.NO');
    }

    public _isEditable = false;

    get isEditable(): boolean {
        return this._isEditable;
    }

    @Input()
    set isEditable(isEditable: boolean) {
        this._isEditable = isEditable;
    }

    private _dateSelected: Moment | null = null;

    get dateSelected(): Moment {
        return this._dateSelected as Moment;
    }

    private _onEditFn?: GenericFvcFieldEditFn;

    @Input()
    set onEditFn(fn: GenericFvcFieldEditFn) {
        this._onEditFn = fn;
    }

    private _formatViewFn?: GenericFvcFieldFormatViewFn;

    @Input()
    set formatViewFn(fn: GenericFvcFieldFormatViewFn) {
        this._formatViewFn = fn;
    }

    private _loadOptionsFn?: GenericFvcFieldLoadOptionsFn;

    @Input()
    set loadOptionsFn(v: GenericFvcFieldLoadOptionsFn) {
        this._loadOptionsFn = v;
    }

    _data!: IGenericFvcFieldData;

    get data(): IGenericFvcFieldData {
        return this._data;
    }

    @Input()
    set data(table: IGenericFvcFieldData) {
        this._data = table;
        if (!this._data.value.status) {
            this._data.value.status = EStatus.READ;
        }

        this.loadInitialData();
        this.cdr.markForCheck();
    }

    @Input() buildPlanId!: number;

    get column(): IConfigurationColumnValue {
        return this._data.column;
    }

    get configuration(): IConfigurationTableValue {
        return this._data.value;
    }

    get status(): EStatus {
        return this.configuration.status;
    }

    set status(v: EStatus) {
        this.configuration.status = v;
    }

    get options(): any[] {
        return this.configuration.options;
    }

    set options(ops: any[]) {
        this.configuration.options = ops;
    }

    @Input()
        forceReloadOptions = false;

    getEditableClass(isEditable: boolean): string {
        return isEditable ? 'read' : 'not-editable';
    }

    ngOnInit(): void {
        this.labels = this.i18n.instant('BUILD_PLAN.TEAM_MANAGEMENT');

        this.loadInitialData();
    }

    loadFieldOptions(): void {
        if (!this._loadOptionsFn) {
            this.configuration.options = [];
            return;
        }

        if (!this.data.options) {
            this.isLoadingOptions = true;
            this._loadOptionsFn(this.data)
                .then((data) => (this.configuration.options = data))
                .finally(() => (this.isLoadingOptions = false));

        }
    }

    onDateSelected(event: MatDatepickerInputEvent<Moment>): void {
        this._dateSelected = event.value;
    }

    formatView(): string {
        return this._formatViewFn?.(this.configuration) as string;
    }

    getLabelSelect(label: string, item$: any = null, item: any = null): string {
        if (item$ && !item.allowedStatus && this.column.id === this.CONFIG_STATUS) {
            item$.disabled = true;
        }
        if (label === '--' || !label) {
            return this.i18n.instant('GENERAL_FORM.VALIDATION.SELECT_OPTION_REQUIRED');
        }
        return label;
    }

    public edit(): void {
        if (!this.isEditable) {
            return;
        }
        this.oldField = { ...this.field };

        if (this.isTypeFromConfiguration()) {
            this.loadOptionsForConfigurations();
        } else {
            this.configuration.status = EStatus.EDIT;
            this.loadOptions.emit(this.data);
        }
        this.forceFocus();
    }

    public cancel(): void {
        this.field = this.oldField;
        this.oldField = null;
        this.configuration.status = EStatus.READ;
        this.dirty = false;
    }

    public getFlag(country: string): string {
        return 'mr-2 fi fis ' + this.utilsFlag.getFlag(country).class;
    }

    public onSave(): void {
        if (this.isSaving) {
            return;
        }
        this.isSaving = true;
        if (this.isTypeFromConfiguration()) {
            this.saveConfiguration();
        } else {
            this.save.emit();
        }
    }

    public isTypeFromConfiguration(): boolean {
        return this.typesFromConfiguration.includes(this.column.type);
    }

    public updateScope(nameUpdate: string): void {
        this.field.value = nameUpdate;
        this.status = EStatus.READ;
    }

    public updateReference(response: IConfigurationTableValue): void {
        const objResult = (response as any)[`${this.column.type}_${this.column.id}`];

        if (!objResult) {
            return;
        }
        this.configuration.id = objResult.id ? objResult.id : null;
        this.configuration.associationId = objResult.associationId;
        this.configuration.item = { ...objResult.item };
        this.configuration.value = objResult.value;
        this.configuration.displayValue = objResult.displayValue;
        this.configuration.dataType = objResult.dataType;

        if (this.column.type === 'DATES_DEFAULT') {
            this.updateRelativeDates(response);
        }

        if (this.column.id === ConfigurationConstants.BUFFER_PERCENT) {
            this.configuration.displayValue = this.configurationBufferPercentPipe.transform(
                objResult.displayValue
            );
            this.updateFVCBuffer(response);
        }

        this.isSaving = false;
        this.save.emit(true);
        this.configuration['status'] = EStatus.READ;
        this.loadInitialData();
        this.cdr.markForCheck();
    }

    public markAsDirty() {
        this.dirty = true;
    }

    public onOpenMatDatePicker(): void {
        this.subscriptionHeaderDatePicker = this.configurationService.resetDateDefault$.subscribe(
            (value) => {
                if (value) {
                    this.saveRelative.emit(this.data);
                    this.dataPicker.close();
                }
            }
        );
    }

    public onCloseMatDatePicker(): void {
        if (this.subscriptionHeaderDatePicker) {
            this.subscriptionHeaderDatePicker.unsubscribe();
        }
        this.dataPicker.datepickerInput._elementRef.nativeElement?.focus();
    }

    public checkIfOverWritten(): any {
        return this.data.column.type === ConfigurationConstants.RELATIVE_DATES &&
            this.data.value.item.dateType === ConfigurationConstants.OVERWRITTEN
            ? this.calendarHeader
            : null;
    }

    public getStatusCssClass(status: EConfigurationStatusColorKeys): string {
        return EConfigurationStatusColor[status];
    }

    public getStatusIcon(status: EConfigurationStatusIconKeys): string {
        return EConfigurationStatusIcon[status];
    }

    public getTooltipToLabelNgSelect(item: any = null): string {
        if (this.LOCATION_COLUMNS.includes(this.column.type)) {
            return item ? item.shortDescription : this.field.value.shortDescription;
        }
        return item ? item.displayValue : this.field.displayValue;
    }

    public updateValue(): void {
        this.configuration.field = this;
        if (this.isTypeFromConfiguration()) {
            switch (this.column.fieldType) {
            case EFieldType.OPTIONS:
                this.field = {
                    id: this.configuration.id,
                    value: this.configuration.value,
                    enabled: true,
                    displayValue:
                            this.column.name === this.LOCATION
                                ? this.sanitizer.bypassSecurityTrustHtml(
                                    `<i class='${this.getFlag(
                                        this.configuration.item.country
                                    )}'></i> ${this.configuration.item.city}`
                                )
                                : this.configuration.displayValue,
                    item: { ...this.configuration.item }
                };

                break;
            case EFieldType.BOOLEAN:
                this.field = {
                    id: this.configuration.id,
                    value: this.configuration.value === 'true',
                    enabled: true,
                    displayValue: this.configuration.value === 'true' ? 'Yes' : 'No',
                    item: {}
                };
                break;
            default:
                this.field = {
                    id: this.configuration.id,
                    value: this.configuration.value,
                    displayValue: this.configuration.displayValue,
                    enabled: true,
                    item: {}
                };
                break;
            }
        } else {
            if (this._formatViewFn) {
                this.field.displayValue = this._formatViewFn(this.configuration);
                this.field.value = this.configuration.data ? this.configuration.data : null;
            }
        }
    }

    private loadInitialData(): void {
        this.updateValue();

        if (this._data.row.CONFIGURATION_NAME) {
            this.suggestAliasService.setGenericComponentFields(
                this,
                this._data.row.CONFIGURATION_NAME.id
            );
        } else {
            if (this._data.column.type) {
                if (this._data.column.description.toLocaleLowerCase() === this.COLUMN_SCOPE_NAME) {
                    this.suggestAliasService.setGenericComponentFields(this, this._data.row.id);
                }
                else if (this._data.column.type.toLocaleUpperCase() === this.SKU_MATRIX_TYPE) {
                    this.suggestAliasService.setGenericComponentFields(this, this._data.row.skuMatrixId);
                }
                if (this._data.value.data) {
                    if (
                        this._data.value.data.scopeValue &&
                        this._data.column.description.toLocaleLowerCase() !== this.COLUMN_SCOPE_NAME
                    ) {
                        this.suggestAliasService.setReferenceForScopesOrTeams(
                            this._data.row.id,
                            parseInt(this._data.column.id),
                            this._data.value.data.scopeValue.value
                        );
                    }
                }
                if (this._data.column.description.toLocaleLowerCase() === this.COLUMN_TEAM_ALIAS) {
                    this.suggestAliasService.setGenericComponentFields(this, this._data.row.id);
                }
            }
        }

        // check if is color
        this.isColor =
            this.configuration &&
            this.configuration.dataType &&
            EColors.includes(this.configuration.dataType);

        this.validColor =
            this.isColor &&
            this.configuration.item &&
            StyleUtils.isColor(this.configuration.item.value.toLowerCase());

        this.isHw = this.configuration.dataType === EDataType.SYSTEM_HW_REVISION;

        // if component on EDIT status we need populate this option list
        if (this.status === EStatus.EDIT && this.options && this.options.length === 0) {
            this.edit();
        }
    }

    private saveConfiguration() {
        const configValue: IConfigurationUpdateValue = {
            associationId: this.configuration.associationId
        };

        switch (this.column.fieldType) {
        case EFieldType.OPTIONS:
            configValue.id = this.field.id;
            configValue.value = this.field.value;
            break;
        case EFieldType.DATE:
            configValue.value = this._dateSelected?.format('YYYY-MM-DD');
            break;
        case EFieldType.BOOLEAN:
            this.field.value = !this.field.value;
            configValue.value = this.field.value;
            break;
        default:
            configValue.value = this.field.value;
            break;
        }

        const configurationId = Number(this._data.row.CONFIGURATION_ID.value);

        if (
            this.column.type === EConfigurationType.CONFIGURATION_STATUS &&
            this.column.id === this.IS_TEMPORARY
        ) {
            const temporaryData = {
                configurationId: Number(this._data.row.CONFIGURATION_ID.value),
                type: this.column.type,
                columnId: this.column.id,
                body: configValue
            };
            this.checkIsTemporaryField(configValue, configurationId, temporaryData);
        } else {
            this.configurationService
                .saveConfiguration(configurationId, this.column.type, this.column.id, configValue)
                .pipe(
                    catchError(({ status, error }: HttpErrorResponse) => {
                        const { msg } = error;
                        if (status === 400) {
                            this.layoutUtilsService.showToastrNotification(
                                msg ? msg : this.i18n.instant('COMMON.ERROR_REQUEST'),
                                ToastrType.error
                            );
                        } else {
                            this.layoutUtilsService.showToastrNotification(
                                this.i18n.instant('COMMON.ERROR_REQUEST'),
                                ToastrType.error
                            );
                        }
                        this.configuration.status = EStatus.READ;
                        this.field = this.oldField;
                        this.isSaving = false;
                        this.cdr.markForCheck();
                        return of([]);
                    })
                )
                .subscribe((response: any) => {
                    response = response as IConfigurationTableValue;
                    if (
                        this.column.type === ConfigurationConstants.CONFIGURATION &&
                        this.data.column.id === ConfigurationConstants.CONFIG_STATUS
                    ) {
                        const newStatus: EConfigurationStatus = this.field.value;
                        const prevStatus: EConfigurationStatus = this.configuration.value;
                        this.configurationStatusChange.emit([prevStatus, newStatus]);
                    }
                    this.updateReference(response);
                    this.openAlias();
                    if (this.column.type === EConfigurationType.DATES_DEFAULT) {
                        this.openDialogDateDefault.emit({
                            configurationId,
                            associationId: configValue.associationId,
                            datesDefaultId: this.data.column.id,
                            displayName: this.data.column.description
                        });
                    }
                });
        }
    }

    private updateRelativeDates(response: IConfigurationTableValue) {
        Object.keys(response).forEach((key) => {
            this._data.row[key] = (response as any)[key];
        });
    }

    private openAlias() {
        if (this._data.row.CONFIGURATION_NAME) {
            const column = this.column.description.toLocaleLowerCase();

            if (this.suggestAliasService.COLUMN_ALIAS_AUTOMATION.some(search_field => search_field === column)) {

                let dataToSuggest = this.suggestAliasService.toSuggestName(this._data.row);

                const currentData: string = this._data.row['CONFIGURATION_NAME'].displayValue;
                if (currentData.toLocaleLowerCase() !== dataToSuggest.toLocaleLowerCase()) {
                    const data: ISuggestAliasInterface = {
                        columnToUpdate: COLUMN_NAME.CONFIG_ALIAS,
                        currentDataFromAlias: { ...this._data.row }['CONFIGURATION_NAME']
                            .displayValue,
                        dataToSuggest: dataToSuggest,
                        rowID: { ...this._data.row }.CONFIGURATION_NAME.id
                    };
                    this.suggestAliasService.openSuggestAlias(data);
                    this.suggestAliasService
                        .getDialog()
                        .afterClosed()
                        .subscribe((nameToUpdate: string) => {
                            if (nameToUpdate) {
                                this.saveConfigurationAlias(
                                    nameToUpdate,
                                    this._data.row['CONFIGURATION_NAME'].id,
                                    'CONFIGURATION',
                                    'NAME'
                                );
                            }
                        });
                }
            }
        }
    }

    /**
     * Loads the configuration service and updates the config alias name
     */
    private saveConfigurationAlias(
        nameUpdate: string,
        configurationId: number,
        type: string,
        columnId: string
    ): void {
        const configValue: IConfigurationUpdateValue = {
            value: nameUpdate
        };

        this.configurationService
            .saveConfiguration(configurationId, type, columnId, configValue)
            .pipe(
                catchError(() => {
                    this.layoutUtilsService.showToastrNotification(
                        this.i18n.instant('COMMON.ERROR_REQUEST'),
                        ToastrType.error
                    );
                    return EMPTY;
                })
            )
            .subscribe((response: IConfigurationTableValue) => {
                this.suggestAliasService.updateConfigurationAliasReference(
                    configurationId,
                    response
                );
                this.onClosingDialogAfterUpdate();
            });
    }

    /**
     * Opens a toast of success and close the current dialog.
     */
    private onClosingDialogAfterUpdate(): void {
        this.layoutUtilsService.showToastrNotification(
            this.i18n.instant('COMMON.SUCCESSFULLY_UPDATED'),
            ToastrType.success
        );
    }

    /**
     * Validates data types, then loads its data.
     */
    private loadOptionsForConfigurations(): void {
        if (this.column.fieldType === EFieldType.OPTIONS) {
            this.configuration.status = EStatus.LOADING;
            if (this.column.id === this.CONFIG_STATUS) {
                this.loadOptionsForConfigurationStatus();
            } else if (
                !this.configuration.options ||
                this.configuration.options.length === 0 ||
                this.forceReloadOptions
            ) {
                this.loadOptionsForAllValues();
            } else {
                this.options = this.configuration.options;
                this.configuration.status = EStatus.EDIT;
                this.cdr.detectChanges();
            }
        } else {
            if (this.column.fieldType !== EFieldType.BOOLEAN) {
                this.configuration.status = EStatus.EDIT;
            }
        }
    }

    /**
     * Loads options for configuration status, disabling or enablig
     * its values based on the configuration status.
     */
    private loadOptionsForConfigurationStatus(): void {
        this.configurationService
            .getOptionsConfigStatus(
                Number(this._data.row.CONFIGURATION_ID.value),
                this.column.type,
                this.column.id

            )
            .pipe(
                catchError(() => {
                    this.configuration.status = EStatus.READ;
                    return of([]);
                }),
                map((response) => {
                    return response;
                })
            )
            .subscribe((response: any) => {
                this.options = response;
                this.configuration.status = EStatus.EDIT;
                this.forceFocus();
                this.cdr.detectChanges();
            });
    }

    /**
     * Loads the options for edit-inline according to its types
     */
    private loadOptionsForAllValues(fieldToSelect?: string): void {
        this.configurationService
            .getOptions(this.column.type, this.column.id, this.buildPlanId)
            .pipe(
                catchError(() => {
                    this.configuration.status = EStatus.READ;
                    return of([]);
                }),
                map((response) => {
                    response.forEach((item: any) => {
                        item.displayValue =
                        this.column.name === this.LOCATION
                            ? this.sanitizer.bypassSecurityTrustHtml(
                                `<i class='${this.getFlag(item.item.country)}'></i>${
                                    item.displayValue
                                } ${item.item.locationType}`
                            )
                            : item.displayValue;
                    });
                    return response;
                })
            )
            .subscribe((response: any) => {
                this.options = response;
                if(this.options[0].dataType === this.SYSTEM_HW_REVISION) {
                    this.options.sort((a,b) => HwRevisionUtils.sorter(a.value, b.value));
                }
                this.configuration.status = EStatus.EDIT;
                this.forceFocus();
                this.cdr.detectChanges();

                this.onVerifyAdded(fieldToSelect);
            });
    }

    private selectAddedValue(fieldToSelect?: string): void {
        this.configuration.status = EStatus.EDIT;
        if(this.requestFinished$){
            this.requestFinished$.pipe(finalize(() => {
                this.onVerifyAdded(fieldToSelect);
            })).subscribe();
        }
    }

   public onVerifyAdded = (field: any) => {
         setTimeout(() => {
            if(field){
                this.ngselect?.select(this.ngselect?.itemsList.filteredItems.find(e => e.label == field)!);
                this.onSave();
            }
         }, 300);
    };

    private checkIsTemporaryField(
        configValue: IConfigurationUpdateValue,
        configurationId: number,
        temporaryData: any
    ): void {
        this.isSaving = false;
        this.openDialogTemporaryDisableDate.emit({
            configurationId: configurationId,
            checked: configValue.value,
            temporaryData: temporaryData,
            genericField: this
        });
    }

    private updateFVCBuffer(response: IConfigurationTableValue) {
        const key =
            ConfigurationConstants.CONFIGURATION +
            ConfigurationConstants.UNDERSCORE +
            ConfigurationConstants.FVC_BUFFER;
        this._data.row.CONFIGURATION_FVC_BUFFER = (response as any)[key];
    }

    public forceFocus(): void {
        setTimeout(() => {
            if (this.elRef) {
                if (this.ngselect) {
                    this.ngselect.open();
                }
                const input = this.elRef.nativeElement.querySelector('input');
                if (input) {
                    input.focus();
                }
                if (this.dataPicker) {
                    this.dataPicker.open();
                }
            }
        }
        , 1);
    }

    private checkDoAction(): void {
        if(!this.hasAddDialogOpen){
            if (this.dirty) {
                this.onSave();
                this.cdr.detectChanges();
            } else {
                this.cancel();
                this.cdr.detectChanges();
            }
        }
    }

    public onKeyDownCell(event: KeyboardEvent): void {
        if (event.key === Key.Enter && !this.hasAddDialogOpen  ) {
            this.checkDoAction();
        }
        else if (event.key === Key.Escape  && !this.hasAddDialogOpen) {
            this.cancel();
        }

    }

    public onBlurCell(): void {
        if (this.data.column.type === ConfigurationConstants.RELATIVE_DATES ||
            this.data.column.fieldType === EFieldType.DATE) {
            //Needed delay to check opened datepicker
            setTimeout(() => {
                if (!this.dataPicker.opened) {
                    this.checkDoAction();
                }
            }
            , 100);
        }
        else {
            this.checkDoAction();
        }
    }

    openConfirmationDialog(inputField: HTMLInputElement, collId: string): void {
        if (inputField.value.trim().length === 0) {
            return;
        }

        const dialogRef = this.dialog.open(GalvinConfirmGenericComponent, {
            disableClose: true,
            autoFocus: true,
            data: {
                title: this.i18n.instant('COMMON.ADD_VALUE'),
                description: `${this.i18n.instant('COMMON.ADD_VALUE_DIALOG')} ${inputField.value}`,
            },
            panelClass: 'custom-panel',
            width: '450px',
        });

        dialogRef.componentInstance.isLoading$.subscribe(() => {
            dialogRef.componentInstance.isLoading = true;
            this.addFvcValue(inputField.value, collId)
                .subscribe(() => {
                    this.loadOptionsForAllValues();
                    this.layoutUtilsService.showToastrNotification(
                        this.i18n.instant('COMMON.SUCCESSFULLY_SAVED'),
                        ToastrType.success
                    );
                }, () => {
                    this.layoutUtilsService.showToastrNotification(
                        this.i18n.instant('COMMON.ERROR_REQUEST'),
                        ToastrType.error
                    );
                })
                .add(() => {
                    dialogRef.close();
                    inputField.value = '';
                });
        });
    }

    private addFvcValue( collId: any,value: any,): Observable<any> {
        return this.fvcParametersService.addFvcValue(Number(collId), { value }).pipe(
            catchError(() => {
                return EMPTY;
            })
        );
    }

    addNewOptionToSelector(_this_: any, coll: any, fieldValue: string) {
        this.hasAddDialogOpen = true;
        const title =  this.i18n.instant('COMMON.ADD_VALUE');
        const description = `${this.i18n.instant('COMMON.ADD_VALUE_DIALOG')} ${fieldValue}`;

        const dialogRef: MatDialogRef<GalvinConfirmGenericComponent, { disableClose: true,
             autoFocus: false}>  = <
            MatDialogRef<GalvinConfirmGenericComponent>
            >
            this.layoutUtilsService.genericDialogConfirmation(
                title,
                description,
                GalvinConfirmGenericComponent,
            );
        this.ngselect?.close();
        this.cancel();
        dialogRef.componentInstance.isLoading$.subscribe(() => {
            dialogRef.componentInstance.isLoading = true;
            if(coll.type == this.FVC_VALUE){
                this.addFvc(dialogRef, coll, fieldValue);
            } else if(coll.type == this.TEAM_VALUES){
                this.addTeamValue(dialogRef, coll, fieldValue);
            } else if(coll.type == this.SCOPE_VALUES){
                this.addScopeValue(dialogRef, coll, fieldValue);

            }
            else{
                this.layoutUtilsService.showToastrNotification(
                    this.i18n.instant('COMMON.ERROR_REQUEST'),
                    ToastrType.error
                );
                dialogRef.close();
            }
        });

        dialogRef.afterClosed().subscribe(() => {
            this.hasAddDialogOpen = false;
        });
    }

    addScopeValue(dialogRef: MatDialogRef<GalvinConfirmGenericComponent>, coll: any, fieldValue: string){
        let scopeValueId: number;
        this.scopesService.getAllScopesParameters().pipe(
            finalize(() => {
                this.scopesService.addScopeValue(scopeValueId, {value: fieldValue, enabled: true}).pipe(
                    catchError(() => {
                        this.layoutUtilsService.showToastrNotification(
                            this.i18n.instant('COMMON.ERROR_REQUEST'),
                            ToastrType.error
                        );
                        return EMPTY;
                    }),
                    finalize(() => {
                        dialogRef.close();
                        this.data.options = ['addValue'];
                        this.loadOptions.emit(this.data);
                        this.selectAddedValue(fieldValue);
                    })
                ).subscribe(
                    () => {
                        this.layoutUtilsService.showToastrNotification(
                            this.i18n.instant('COMMON.SUCCESSFULLY_SAVED'),
                            ToastrType.success
                        );
                    }
                );
            })
        ).subscribe((data) => {
            scopeValueId = data.content.find(e => e.name == coll.description)?.id!;
        });
    }

    addTeamValue(dialogRef: MatDialogRef<GalvinConfirmGenericComponent>, coll: any, fieldValue: string) {
        let teamValueId: number;
        this.teamParameterService.getAllParameters().pipe(
            finalize(() => {
                this.teamParameterService.addTeamValue(teamValueId, {value: fieldValue, enabled: true}).pipe(
                    catchError(() => {
                        this.layoutUtilsService.showToastrNotification(
                            this.i18n.instant('COMMON.ERROR_REQUEST'),
                            ToastrType.error
                        );
                        return EMPTY;
                    }),
                    finalize(() => {
                        dialogRef.close();
                        this.loadOptions.emit(this.data);
                        this.selectAddedValue(fieldValue);
                    })
                ).subscribe(
                    () => {
                        this.layoutUtilsService.showToastrNotification(
                            this.i18n.instant('COMMON.SUCCESSFULLY_SAVED'),
                            ToastrType.success
                        );
                    }
                );
            })
        ).subscribe((data) => {
            teamValueId = data.find(e => e.name == coll.description)?.id!;
        });
    }

    addFvc(dialogRef: MatDialogRef<GalvinConfirmGenericComponent>, coll: any, fieldValue: string){
        this.addFvcValue(coll.id, fieldValue).pipe(
            catchError(() => {
                this.layoutUtilsService.showToastrNotification(
                    this.i18n.instant('COMMON.ERROR_REQUEST'),
                    ToastrType.error
                );
                return EMPTY;
            }),
            finalize(() => {
                dialogRef.close();
                this.data.options = ['addValue'];
                this.loadOptions.emit(this.data);
                this.loadOptionsForAllValues(fieldValue);
            })
        ).subscribe(
            () => {
                this.layoutUtilsService.showToastrNotification(
                    this.i18n.instant('COMMON.SUCCESSFULLY_SAVED'),
                    ToastrType.success
                );
            }
        );
    }


    public addUserByDialog = (_this_: any, coll: any, fieldValue: string): Promise<any> => {
        this.hasAddDialogOpen = true;
        this.ngselect?.close();
        return this.openCreateDialog(fieldValue).then(u=>{
            if(u){
                this.field=u;
                this.onSave();
            }
            this.hasAddDialogOpen = false;
            return u;
        });
    };


    openCreateDialog(email: string):Promise<any> {
        return new Promise((resolve) => {
                const CREATE_BUTTON = this.i18n.instant('COMMON.BUTTONS.CREATE');
                const CANCEL_BUTTON = this.i18n.instant('COMMON.BUTTONS.CANCEL');
                const dialog = this.dialog.open<UserManagementDialogComponent, IDialogData>(
                    UserManagementDialogComponent,
                    {
                        data: {
                            saveOrEditButton: {
                                value: CREATE_BUTTON,
                                name: 'save'
                            },
                            cancelButton: {
                                value: CANCEL_BUTTON,
                                name: 'cancel'
                            },
                            enabled: true,
                            data: {
                                email: email,
                            },
                            sendApi :true,
                        },
                        panelClass: 'custom-dialog'
                    }
                );
                dialog.afterClosed().subscribe((user:IUserManagement) => {
                    let dataUser=null;
                    if (user){
                        dataUser=this.userToGenericItem(user);
                    }
                    resolve(dataUser);
                });
        });
    }


    private userToGenericItem(u: IUserManagement) {
        let { name, lastName } = u;
        name = name || '';
        lastName = lastName || '';
        let fullName=`${name} ${lastName}`;

        let display=`${fullName} <${u.email}>`;
        if (fullName.trim().length==0){
            display=`${u.email}`;
        }

        return {
            ...u,
            name,
            lastName,
            value: `${display}`,
            displayValue: `${display}`
        };
    }

    public checkByColFVCType(status: string): boolean {
        return status === this.FVC_VALUE;
    }

    public checkByScopeColType(status: string): boolean {
        return status === this.SCOPE_VALUES;
    }

    public checkByTeamsColType(status: string): boolean {
        return status === this.TEAM_VALUES;
    }

    public openTooltip(tooltip:NgbTooltip) {
        tooltip.close();
        tooltip.open();
    }

    public closeTooltip(tooltip:NgbTooltip) {
        tooltip.close();
    }
}