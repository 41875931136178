import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { ISpecialRequirementsValue } from '@galvin/core/build-plan/field-value-management/special-requirements/interfaces/special-requirements-value.interface';
import { ISpecialRequirements } from '@galvin/core/build-plan/field-value-management/special-requirements/interfaces/special-requirements.interface';
import { SpecialRequirementsService } from '@galvin/core/build-plan/field-value-management/special-requirements/services/special-requirements.service';
import {
    IPrototypeRequestTeamSpecialRequirements,
    IPrototypeRequestTeamSpecialRequirementsValidation
} from '@galvin/core/build-plan/prototype-request-management/interfaces/prototype-request-team.interface';
import { Observable, Subscription } from 'rxjs';

/**
 * This interface ensures that copy of the value will remain
 */
interface ISpecialRequirementsValueCopy extends ISpecialRequirementsValue {
    originalId?: number;
}

/**
 * This interface ensures that copy of the value will remain
 */
interface IPrototypeRequestTeamSpecialRequirementsCopy
    extends IPrototypeRequestTeamSpecialRequirements {
    specialRequirementsValue: ISpecialRequirementsValueCopy;
}

@Component({
    selector: 'special-requirement-form',
    templateUrl: './special-requirement-form.component.html',
    styleUrls: ['./special-requirement-form.component.scss']
})
export class SpecialRequirementFormComponent implements OnInit, OnDestroy {
    specialRequirements: ISpecialRequirements[] = [];
    specialRequirementsSelected: Record<number, ISpecialRequirementsValueCopy> = {};
    isLoading = true;

    @Input()
        verifyFields!: Observable<void>;
    @Output()
        specialRequirementsChanges = new EventEmitter<IPrototypeRequestTeamSpecialRequirementsValidation>();
    @ViewChild('ngForm') ngForm!: NgForm;
    private eventsSubscription = new Subscription();

    constructor(
        private specialRequirementService: SpecialRequirementsService,
        private cdr: ChangeDetectorRef
    ) {
        this.loadFields();
    }

    private _specialRequirementsList: IPrototypeRequestTeamSpecialRequirementsCopy[] = [];

    get specialRequirementsList(): IPrototypeRequestTeamSpecialRequirementsCopy[] {
        return this._specialRequirementsList;
    }

    @Input()
    set specialRequirementsList(list: IPrototypeRequestTeamSpecialRequirementsCopy[]) {
        this._specialRequirementsList = list || [];
        this.loadSelectedFields();
    }

    ngOnInit(): void {
        if (this.verifyFields) {
            this.eventsSubscription.add(
                this.verifyFields.subscribe(() => this.verifyFieldsSelected())
            );
        }
    }

    ngOnDestroy(): void {
        this.eventsSubscription.unsubscribe();
    }

    verifyFieldsSelected(): void {
        for (const value of Object.values(this.ngForm.form.controls)) {
            value.markAsTouched();
        }
        this.validAndEmitSpecials();
    }

    loadSelectedFields(): void {
        for (const special of this.specialRequirementsList) {
            const remoteSR: ISpecialRequirements =
                this.specialRequirements.find((sr) => sr.id === special.id) || {};
            if (remoteSR != undefined && remoteSR.fieldValues != undefined) {
                const remoteValueSR = (<ISpecialRequirementsValue[]>remoteSR.fieldValues).find(
                    (fv) => fv.id === special.id
                );
                if (!!remoteValueSR && special.value != remoteValueSR.value) {
                    if (!('originalId' in special.specialRequirementsValue)) {
                        special.specialRequirementsValue['originalId'] =
                            special.specialRequirementsValue.id;
                    }
                    special.specialRequirementsValue.id = 0;
                }
            }

            this.specialRequirementsSelected[<number>special.specialRequirement.id] =
                special.specialRequirementsValue;
        }
    }

    loadFields(): void {
        this.specialRequirementService
            .getAllSpecialRequirementsByStatusEnabled()
            .subscribe((requirements) => {
                this.specialRequirements = requirements;
                this.isLoading = false;
                this.loadSelectedFields();
                this.cdr.markForCheck();
            });
    }

    onChangeSelect(
        specialRequirement: ISpecialRequirements,
        specialRequirementValue: unknown
    ): void {
        if (specialRequirementValue) {
            this.specialRequirementsSelected[<number>specialRequirement.id] = <
                ISpecialRequirementsValueCopy
            >specialRequirementValue;
        } else {
            delete this.specialRequirementsSelected[<number>specialRequirement.id];
        }

        this.validAndEmitSpecials();
    }

    private validAndEmitSpecials() {
        const protoReqSpecialRequirements: IPrototypeRequestTeamSpecialRequirementsCopy[] = [];
        for (const [key, value] of Object.entries(this.specialRequirementsSelected)) {
            const specialRequirement = <ISpecialRequirements>(
                this.specialRequirements.find((sr) => `${sr.id}` === key)
            );
            const valueSR = <ISpecialRequirementsValue>(
                (<ISpecialRequirementsValue[]>specialRequirement.fieldValues).find(
                    (fv) => value.id === fv.id
                )
            );
            protoReqSpecialRequirements.push({
                specialRequirement: {
                    id: parseInt(key),
                    fieldName: specialRequirement.fieldName
                },
                specialRequirementsValue: {
                    id: value.originalId || value.id
                },
                value: valueSR.value
            });
        }

        // check if there are missing some required fields to be set
        const valid = !this.specialRequirements.some(
            (e) =>
                e.required &&
                !protoReqSpecialRequirements.some((p) => p.specialRequirement.id === e.id)
        );

        this.specialRequirementsChanges.emit({ specials: protoReqSpecialRequirements, valid });
    }
}
