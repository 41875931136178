import {FAQI18n} from '@galvin/core/build-plan/faq/i18n/faq-i18n.interface';

export const FAQ: FAQI18n = {
    TITLE: 'Frequently Asked Questions (FAQ)',
    SEARCH_FAQ: 'Search FAQ Item',
    NO_ITEMS_FOUND: 'No items found for the search',
    FAQ_LABEL: 'FAQ',
    LANGS: {
        ENGLISH: 'English',
        ENGLISH_ISO: 'en',
        CHINESE: 'Chinese',
        CHINESE_ISO: 'zh'
    }
};
