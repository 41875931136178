<div>
    <div id="chatbot-filling" *ngIf="isOpen" (click)="onSidePanelClose()"></div>
    <div class="chatbot-side-panel">
        <div class="space-filler-on-mobile"></div>

        <ng-template #dfMessengerChat>
            <df-messenger-chat class="h-100">
                <div slot="titlebar" class="d-flex flex-column text-light align-middle df-messenger-titlebar">
                    <div class="d-flex flex-grow-1">
                        <div class="d-flex flex-grow-1">
                            <div class="chatbot-icon-wrapper my-auto mx-2">
                                <img src="assets/galvin/icons/chatbot_button_full.svg" width="48px" height="48px" class="p-2"/>
                            </div>
                            <div class="d-flex p-2 mx-auto">
                                <div class="d-flex flex-column justify-content-between">
                                    <h3 class="kt_head__title my-auto chatbot-translate-left">Galvin Assistant</h3>
                                    <small class="chatbot-translate-right"> Powered by Google Vertex AI </small>
                                </div>
                            </div>
                        </div>
                        <button mat-button (click)="onSidePanelClose()">
                            <img src="assets/galvin/icons/icon-close.svg" width="24px" height="24px" class="chatbot-close-icon"/>
                        </button>
                    </div>
                    <div class="text-dark bg-secondary d-flex justify-content-center">
                        <small><i> This feature is still in Beta </i></small>
                    </div>
                </div>
            </df-messenger-chat>
        </ng-template>

        <ng-container #templateContainer></ng-container>
    </div>
</div>
