import { AfterViewInit, Directive, ElementRef, Injectable, Input } from '@angular/core';
import { Subject } from 'rxjs';

declare var KTToggle: any;

export interface ToggleOptions {
    target?: string;
    targetState?: string;
    togglerState?: string;
}

@Injectable()
export class KTToggleService {
    state = new Subject();
    el: any;
}

/**
 * Toggle
 */
@Directive({
    selector: '[ktToggle]',
    exportAs: 'ktToggle'
})
export class ToggleDirective implements AfterViewInit {
    // Public properties
    @Input() options!: ToggleOptions;
    toggle: any;

    /**
     * Directive constructor
     * @param el: ElementRef
     */
    constructor(private el: ElementRef, private service: KTToggleService) {}

    /**
     * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
     */

    /**
     * After view init
     */
    ngAfterViewInit(): void {
        this.toggle = new KTToggle(this.el.nativeElement, this.options);
        this.service.el = this.toggle;
        this.toggle.on('toggle', (e: any) => {
            this.service.state.next(e.state);
        });
    }
}
