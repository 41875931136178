export enum EBuildPlanStatusIcon {
    NEW = 'new_releases',
    PENDING_APPROVAL = 'hourglass_empty',
    APPROVED = 'done',
    OPEN = 'brightness_1',
    PARTIALLY_OPEN = 'radio_button_unchecked',
    LOCKED = 'lock',
    DPM_PENDING_APPROVAL = 'hourglass_empty',
    DPM_APPROVED = 'done',
    BUILDING = 'build',
    COMPLETED = 'check',
    ARCHIVED = 'history',
    CANCELED = 'cancel'
}

export type EBuildPlanStatusIconKey = keyof typeof EBuildPlanStatusIcon;
