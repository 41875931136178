import { Pipe, PipeTransform } from '@angular/core';
import { ISkuBoardSamplesSourcing } from '@galvin/core/build-plan/reports/interfaces/sku-board-samples-sourcing';
import { ReportUtils } from '@galvin/core/build-plan/reports/utils/report.utils';

@Pipe({
    name: 'getProtosTotal'
})
export class GetProtosTotal implements PipeTransform {

    transform(hwRevision: any, reportData: ISkuBoardSamplesSourcing): any {
        const total = this.getProtosTotal(hwRevision, reportData);
        return total;
    }

    getProtosTotal(hwRevision: string, reportData: ISkuBoardSamplesSourcing): string | number {
        const data = reportData.reportDTO;
        const totalsByHwRevision = [{ hwRevision, totalRequests: 0 }];

        for (const item of data) {
            if (item.hwRevisions === hwRevision) {
                totalsByHwRevision[0].totalRequests += item.totalRequests;
            }
        }

        return ReportUtils.getValue(totalsByHwRevision[0].totalRequests);
    }
}