import { IAccessoryI18n } from '@galvin/core/build-plan/accessory-management';

export const ACCESSORY: IAccessoryI18n = {
    DIALOG_CREATE: {
        CREATE_TITLE: 'Creating a new Accessory',
        EDIT_TITLE: 'Editing the Accessory',
        MESSAGE_CREATED: 'Accessory created',
        MESSAGE_EDITED: 'Accessory edited'
    },
    DIALOG_CLONED: {
        CREATE_TITLE: 'Clone Accessory(ies)',
        MESSAGE_CREATED: 'Accessory created',
        MESSAGE_EDITED: 'Accessory edited',
        MESSAGE_CLONED: 'Accessory(ies) cloned',
        ACCESSORY_ORDER: 'Accessory order',
        ACCESSORY_ORDER_HINT:
            'The cloned accessory will be placed directly <strong>after</strong> the one selected.'
    },
    SORT_TABLE_TITLE: 'Sorting accessories for this Build Plan',
    EXTRA_BLOCK_TABLE_TOOLTIP: {
        CATEGORY: 'Category: {{value}}',
        VENDOR: 'Vendor: {{value}}',
        VENDOR_PART: 'Vendor Part#: {{value}}',
        MOTO_PART: 'Moto Part#: {{value}}'
    },
    DIALOG_EDIT: {
        CREATE_TITLE: 'Editing Accessory(ies)',
        EDIT_TITLE: 'Editing Accessory',
        MESSAGE_CREATED: 'Multiple Accessorys Edited',
        BUTTON_CREATE: 'Edit Accessory(ies)',
        MESSAGE_EDIT_CONFIG_STATUS:
            'You cannot change the status because the previously selected accessories do not have the same status',
        MESSAGE_EDIT_ALREADY_OPEN:
            'You need to update the accessory status to <span class="d-inline-flex align-items-center build-plan-open">' +
            '<span class="material-icons">brightness_1</span><b>Open</b></span>',
        MESSAGE_EDIT_ALREADY_OPEN_FILLED:
            'This field cannot be changed because it has already been previously filled',
        MESSAGE_OPENED_DATE: 'This field is filled automatically',
        MESSAGE_TEMPORARY_DISABLED_DATE: 'This field is enabled when field Temporary is checked',
        ORDER_CHANGING: 'Updating Accessory order <i class="ml-1 fas fa-spinner fa-spin"></i>',
        ORDER_CHANGE_SUCCESS: 'Accessory order successfully updated',
        ORDER_CHANGE_ERROR: 'Failed to update accessory order, the data shown on the screen is outdated. ' +
            'Please refresh before performing the operation.'
    },
    COMMON: {
        BUTTONS: {
            CREATE: 'New Accessory',
            DELETE: 'Delete {{amount}} Accessory(ies)',
            EDIT: 'Edit {{amount}} Accessory(ies)',
            HIDE_SHOW_COLUMNS: 'Hide / Show the columns',
            CLONE: 'Clone {{amount}} Accessory(ies)',
            ADD_PARAMETER: 'Add Accessory parameter'
        },
        MANDATORY_FIELD: 'Mandatory Field',
        CLONE: 'Clone',
        CHOOSE_FIELDS: 'Choose fields to clone the accessories',
        ACCESSORY: 'ACCESSORY',
        ACCESSORY_NAME: 'Accessory Name',
        ACCESSORY_PARAMETERS: 'ACCESSORY PARAMETERS',
        ACCESSORY_STATUS_DETAIL: 'ACCESSORY STATUS DETAIL',
        RESET: 'RESET',
        DELETE_WARN:
            'This action will <strong>delete <span class="text-danger">{{amount}}</span> accessory(ies) ' +
            'and the <span class="text-danger"> {{totalRequests}}</span> accessory requests of\n' +
            '<span class="text-primary">{{internalProductName}}</span></strong> Build Plan and could not be undone.',
        CANT_DELETE_TITLE:
            '<strong><span class="text-danger">{{amount}}</span> accessory(ies) can\'t be delete ' +
            'as it was already opened to the Requestors. Please, Archive or Cancel it/them.</strong>',
        CANT_DELETE_ACCESSORY:
            'This accessory can\'t be delete ' +
            'as it has already been opened to the Requestors. Please, Archive or Cancel it.',
        CONFIRMATION_DELETE_DIALOG_TITLE: 'DELETE {{amount}} Accessory(ies)',
        CONFIRMATION_EDIT_DIALOG_TITLE: 'EDIT {{amount}} Accessory(ies)',
        SUCCESSFUL_DELETED: 'Accessory(ies) successfully deleted'
    },
    ERROR: {
        UPDATE_FIELD_FAILED: 'Failed to update accessory field value.'
    },
    ACCESSORY_FILTER: {
        TITLE: 'Filter by Device Spec'
    },
    ACCESSORY_REQUEST_PREVIEW: {
        MASS_EDIT_LABEL: 'MASS EDIT {{selectionSize}} REQUEST selected',
        DETAILS: 'Show accessory details',
        CLONE: 'Clone Accessory',
        EDIT: 'Edit Accessory',
        TOTAL_REQUEST_FROM_TEAM: 'requests',
        TOTAL_REQUEST_FULL_VIEW: 'Total requests',
        SPECIAL_REQUIREMENTS_TITLE: 'Special Requirements',
        COLLAPSE_HEADER_INFO: 'Collapse Header Information',
        UNCOLLAPSE_HEADER_INFO: 'Uncollapse Header Information',
        CANCELED_ACCESSORY: 'This accessory has been cancelled',
        FILTER_BY_ONLY_REQUESTS: 'Filter by only requests',
        RESET_FILTERS: 'Reset Filters'
    },
    ACCESSORY_PARAMETER: {
        NEW_INDICATOR: 'New!',
        BTN_ADD_ACCESSORY_PARAMETER: 'Add Accessory Parameter',
        SEARCH_ACCESSORY_PARAMETER: 'Search Accessory Parameters',
        ADD_TO_BUILD_PLAN: 'Add to Build Plan',
        ACCESSORY_PARAMETER_ADDED: 'Accessory Parameters(s) added in Build Plan',
        DELETE_CONFIRMATION:
            'Are you sure you want to delete accessory parameter from this build plan: {{name}}?',
        DELETE_CONFIRMATION_DIALOG_TITLE: 'Confirm',
        REMOVE_ACCESSORY_PARAMETER: 'Remove {{name}} Accessory Parameter',
        ACCESSORY_PARAMETER_DELETED: 'Accessory Parameter removed'
    },
    REMOVE_PARAMETER: 'Remove {{name}} parameter',
    DIALOG_SHOW_ACCESSORY_WORKFLOW: {
        INFO: 'Check Accessory Workflow',
        TITLE: 'Accessory Flow'
    },
    PLURAL_NAME: 'Accessories',
};
