<div [ngClass]="data.contentClass || ''">
    <h2 mat-dialog-title>{{data.title}}</h2>
    <ng-container *ngIf="!isLoading">
        <mat-dialog-content>
            <b>
                {{data.description}}
            </b>
        </mat-dialog-content>
        <mat-dialog-actions class="justify-content-end">
            <button (click)="cancelAction()" mat-raised-button>{{labelCancel | translate}}</button>
            <button color="warn" mat-raised-button id="btn-delete-continue" [ngClass]="data.buttonClass || ''"
                (click)='isLoading$.next()'> {{labelConfirm | translate}}</button>
        </mat-dialog-actions>
    </ng-container>
    <ng-container *ngIf="isLoading">
        <mat-dialog-content class="justify-content-center">
            <galvin-progress-spinner></galvin-progress-spinner>
        </mat-dialog-content>
    </ng-container>
</div>