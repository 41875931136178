import { isEmpty } from 'lodash';
// Third
import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';
// Angular
import { EventEmitter, Injectable } from '@angular/core';
// Galvin
import { IBuildPlanDetails } from '../interfaces/build-plan-details.interface';
import { ERolesBuildPlan } from './../../../auth/_enums/roles-build-plan.enum';
import { SecurityService } from './../../../auth/services/security.service';

/**
 * Used as an helper service that carries a {@link IBuildPlanDetails} instance, to avoid this same variable to be loaded
 * everytime that it's needed insite the same component. Also, this class emits any editions that occurs for all it's listeners
 * through the buildPlanDetailsEdited {@link Observable}.
 */
@Injectable({
    providedIn: 'root'
})
export class BuildPlanDetailsService {
    public buildPlanReloadInformation = new EventEmitter<void>();
    public currentUrl = '';
    /**
     * Set as {@link ReplaySubject} so any component that wants to consult the latest state of a given {@link IBuildPlanDetails}
     * can just reuse the one already stored on the current user session.
     */
    private buildPlanDetails: ReplaySubject<IBuildPlanDetails> =
        new ReplaySubject<IBuildPlanDetails>(1);
    buildPlanDetails$ = this.buildPlanDetails.asObservable();
    private buildPlanDetailsBehavior: BehaviorSubject<IBuildPlanDetails | undefined> =
        new BehaviorSubject<IBuildPlanDetails | undefined>(undefined);
    buildPlanDetailsBehavior$ = this.buildPlanDetailsBehavior.asObservable();
    private buildPlanDetailsEdited: Subject<IBuildPlanDetails> = new Subject<IBuildPlanDetails>();
    buildPlanDetailsEdited$ = this.buildPlanDetailsEdited.asObservable();

    public constructor(private securityService: SecurityService) {}

    emit(buildPlan: IBuildPlanDetails, url?: string): void {
        this.currentUrl = url!;
        this.buildPlanDetails.next(buildPlan);
        this.buildPlanDetailsBehavior.next(buildPlan);
    }

    emitEdition(buildPlan: IBuildPlanDetails): void {
        this.buildPlanDetailsEdited.next(buildPlan);
    }

    getBuildPlanDetails(): Observable<IBuildPlanDetails | undefined> {
        return this.buildPlanDetailsBehavior$;
    }

    checkIfUserIsAssociatedBuildPlanOrIsPM(buildPlanDetails: IBuildPlanDetails): ERolesBuildPlan[] {
        const rolesAssociated: ERolesBuildPlan[] = [];
        const { coreId } = this.securityService.getLocalStorageUser()!;

        if (buildPlanDetails && !isEmpty(buildPlanDetails.gpm)) {
            const isGPM = buildPlanDetails.gpm!.some((item) => item === coreId);
            isGPM && rolesAssociated.push(ERolesBuildPlan.BP_GPM);
        }

        if (buildPlanDetails && !isEmpty(buildPlanDetails.hwpm)) {
            const isHWPM = buildPlanDetails.hwpm!.some((item) => item === coreId);
            isHWPM && rolesAssociated.push(ERolesBuildPlan.BP_HW_PM);
        }

        return rolesAssociated;
    }
}
