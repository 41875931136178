import { CommonModule } from '@angular/common';
// Angular
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
// Angular Material
import { MatButtonModule } from '@angular/material/button';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';

import { BannerInformationService } from '@galvin/core/_base/banner-information/banner-information.service';
// NgBootstrap
import { NgbProgressbarModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
// Loading bar
import { LoadingBarModule } from '@ngx-loading-bar/core';
// Translation
import { TranslateModule } from '@ngx-translate/core';
// SVG inline
import { InlineSVGModule } from 'ng-inline-svg';
// Perfect Scrollbar
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { NgxPermissionsModule } from 'ngx-permissions';
// Core Module
import { CoreModule } from '../../core/core.module';
import { PartialsModule } from '../partials/partials.module';
//Galvin components
import { ShareModule } from '../share/share.module';
import { AsideLeftComponent } from './aside/aside-left.component';
import { BaseMinComponent } from './base-min/base-min.component';
import { BaseComponent } from './base/base.component';
import { BrandComponent } from './brand/brand.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderFullScreenComponent } from './header/header-fullscreen/header-fullscreen.component';
import { HeaderMobileComponent } from './header/header-mobile/header-mobile.component';
import { HeaderComponent } from './header/header.component';
import { MenuHorizontalComponent } from './header/menu-horizontal/menu-horizontal.component';
import { TopbarComponent } from './header/topbar/topbar.component';
import { HtmlClassService } from './html-class.service';
import { SubheaderComponent } from './subheader/subheader.component';
import { FloatingButtonComponent } from './floating-button/floating-button.component';

@NgModule({
    declarations: [
        BaseComponent,
        BaseMinComponent,
        FooterComponent,
        FloatingButtonComponent,

        // headers
        HeaderComponent,
        BrandComponent,
        HeaderMobileComponent,
        HeaderFullScreenComponent,

        // subheader
        SubheaderComponent,

        // topbar components
        TopbarComponent,

        // aside left menu components
        AsideLeftComponent,

        // horizontal menu components
        MenuHorizontalComponent
    ],
    exports: [
        BaseComponent,
        BaseMinComponent,
        FooterComponent,
        FloatingButtonComponent,

        // headers
        HeaderComponent,
        BrandComponent,
        HeaderMobileComponent,
        HeaderFullScreenComponent,
        // subheader
        SubheaderComponent,

        // topbar components
        TopbarComponent,

        // aside left menu components
        AsideLeftComponent,

        // horizontal menu components
        MenuHorizontalComponent
    ],
    providers: [HtmlClassService, BannerInformationService],
    imports: [
        CommonModule,
        RouterModule,
        NgxPermissionsModule.forChild(),
        PartialsModule,
        CoreModule,
        PerfectScrollbarModule,
        FormsModule,
        MatProgressBarModule,
        MatTabsModule,
        MatButtonModule,
        MatTooltipModule,
        TranslateModule.forChild(),
        LoadingBarModule,
        InlineSVGModule,
        MatToolbarModule,
        // ng-bootstrap modules
        NgbProgressbarModule,
        NgbTooltipModule,
        ShareModule
    ]
})
export class ThemeModule {}
