import { SelectionModel } from '@angular/cdk/collections';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatListOption, MatSelectionList } from '@angular/material/list';
import { LocalStorageHelper } from '@galvin/core/storage/local-storage-helper.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'freeze-columns',
    templateUrl: 'freeze-columns.component.html',
    styleUrls: ['./freeze-columns.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FreezeColumnsComponent<E = any, G = any> implements AfterViewInit, OnInit, OnDestroy {
    @Input() groups!: G[];
    @Input() groupComparator!: keyof E;
    @Input() groupPropDescription!: keyof G;
    @Input() columnPropDescription!: keyof E;
    @Input() storageKey!: string;
    @Input() defaultFreezeColumns: E[] = [];
    @Input() smallMode = false;
    @Input() autoFill = true;
    @Output() selectionChange = new EventEmitter<E[]>();
    @Input() maxFreezedCol: number = 0;
    @ViewChild(MatSelectionList) selectionList!: MatSelectionList;

    selectedColumns = new FormControl();
    columnsToFreeze!: E[];
    actualMaxCols: number = 0;

    private readonly freezeStorageKey = 'freezeColumns';
    private readonly selectedColumnsModel = new SelectionModel<string>(true);
    public readonly selectedGroupsModel = new SelectionModel<string>(true);
    private readonly subscription = new Subscription();

    constructor(private storageService: LocalStorageHelper) {}

    private _columns!: E[];

    get columns(): E[] {
        return this._columns;
    }

    @Input()
    set columns(value: E[]) {
        if (Array.isArray(value)) {
            this._columns = value;
        }
    }

    ngOnInit(): void {
        if (!this.storageKey) {
            throw new Error('You must set storageKey property');
        }
        this.loadFreezeColumnsFromLocalstorage();
        this.setDefaultFreezeColumns();
    }

    ngAfterViewInit(): void {
        this.subscription.add(
            this.selectionList.selectionChange
                .subscribe(() => {
                    const selectedOptions = this.selectionList.selectedOptions.selected.filter(
                        (option) => typeof option.value === 'string'
                    );
                    if (this.maxFreezedCol > 0 && this.selectionList.selectedOptions.selected.length > this.actualMaxCols && this.actualMaxCols >= this.maxFreezedCol) {
                        this.selectionList.deselectAll();
                        this.updateCheckedColumns();
                        return;
                    }
                    this.actualMaxCols = this.selectionList.selectedOptions.selected.length;
                    setTimeout(() => this.onSelectionChange(selectedOptions), 100);
                })
        );
        if(this.selectionList.options.length > 0){
            this.updateCheckedColumns();
        }
        this.subscription.add(
            this.selectionList.options.changes.subscribe(() => {
                this.updateCheckedColumns();
            })
        );
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    getColumnDescription(column: E | any): string {
        if (this.columnPropDescription) {
            return column[this.columnPropDescription].toString();
        } else {
            return column.toString();
        }
    }

    getGroupDescription(group: G | any): string {
        if (this.groupPropDescription) {
            return group[this.groupPropDescription].toString();
        } else {
            return group.toString();
        }
    }

    onClickGroup(ev: MatCheckboxChange, group: G | any): void {
        const columnsByGroup = this.columns
            .filter((c) => c[this.groupComparator] == group[this.groupComparator.toString()])
            .map((c) => this.getColumnDescription(c));

        if (ev.checked) {
            this.selectedColumnsModel.select(...columnsByGroup);
            if (this.maxFreezedCol > 0 && this.selectedColumnsModel.selected.length > this.maxFreezedCol) {
                let index = this.selectedColumnsModel.selected.length -1;
                //Uncheck cols above max freeze
                while (this.selectedColumnsModel.selected.length > this.maxFreezedCol) {
                    this.selectedColumnsModel.deselect(...[columnsByGroup[index]]);
                    index --;
                }
            } else {
                this.selectedGroupsModel.select(group[this.groupComparator.toString()]);
            }
        } else {
            this.selectedColumnsModel.deselect(...columnsByGroup);
            this.selectedGroupsModel.deselect(group[this.groupComparator.toString()]);
        }

        this.updateCheckedColumns();
    }

    haveFreezedColumns(): boolean {
        return this.maxFreezedCol > 0;
    }

    private loadFreezeColumnsFromLocalstorage() {
        const freezeColumns = JSON.parse(this.storageService.getItem(this.freezeStorageKey));
        if (freezeColumns && this.storageKey in freezeColumns) {
            this.selectedColumnsModel.select(...freezeColumns[this.storageKey]);
        }
        this.actualMaxCols = this.selectedColumnsModel.selected.length;
    }

    private updateCheckedColumns() {
        const selectedOptions = this.selectedColumnsModel.selected;
        this.selectedColumns.patchValue(selectedOptions, { emitEvent: true });

        const selected = this.selectionList.options.filter((opt) =>
            selectedOptions.includes(opt.value)
        );

        this.selectionList.deselectAll();
        this.selectionList.selectedOptions.select(...selected);
        this.onSelectionChange(selected);
        this.actualMaxCols = this.selectedColumnsModel.selected.length;
    }

    private updateCheckboxGroups() {
        if (this.groups) {
            this.groups.filter((group: any) => {
                const columnsByGroup = this.columns.filter((c: any) => {
                    c[this.groupComparator.toString()] == group[this.groupComparator.toString()];
                });
                const filtredColumn = columnsByGroup.filter((cbg) =>
                    this.columnsToFreeze.includes(cbg)
                );
                if (columnsByGroup.length === filtredColumn.length) {
                    this.selectedGroupsModel.select(group[this.groupComparator.toString()]);
                } else {
                    this.selectedGroupsModel.deselect(group[this.groupComparator.toString()]);
                }
            });
        }
    }

    private onSelectionChange(listOptions: MatListOption[]): void {
        const freezeColumns: any = listOptions.map((option) =>
            this._columns.find((col) => this.getColumnDescription(col) === option.value)
        );
        this.columnsToFreeze = freezeColumns;

        this.writeToCache();
        this.updateCheckboxGroups();
        this.selectionChange.emit(this.columnsToFreeze);
    }

    private writeToCache() {
        let cache = JSON.parse(this.storageService.getItem(this.freezeStorageKey));
        if (!cache) {
            cache = {};
        }
        cache[this.storageKey] = this.columnsToFreeze
            .filter((c) => c)
            .map((c) => this.getColumnDescription(c));
        this.storageService.setItem(this.freezeStorageKey, JSON.stringify(cache));
        this.selectedColumnsModel.clear();
        this.columnsToFreeze.forEach((c) => {
            this.selectedColumnsModel.select(this.getColumnDescription(c));
        });
    }

    private setDefaultFreezeColumns() {
        if (this.defaultFreezeColumns.length > 0 && this.selectedColumnsModel.isEmpty() && this.autoFill) {
            const selectedOptions = this.defaultFreezeColumns.map((c) =>
                this.getColumnDescription(c)
            );
            this.selectedColumnsModel.select(...selectedOptions);
        }
    }
}
