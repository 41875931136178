import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { SecurityMotorolaService } from './security-motorola.service';
import { Subject } from 'rxjs';
declare let google: any;


@Injectable()
export class GoogleAuth {

    constructor(private securityMotorolaService: SecurityMotorolaService) {}

    public logging: Subject<boolean> = new Subject();

    private setLoggingSubject(isLoading: boolean): void {
        this.securityMotorolaService.logging.next(isLoading);
        this.logging.next(isLoading);
    }

    public initAuth(idButtonLogin='google-signin'): void {
        this.googleInitialize();
        const bt= document.getElementById(idButtonLogin);
        if (bt) {
            this.googleRenderButton(bt);
        }
    }

    public initCheckToken = () => {
        this.securityMotorolaService.initRefreshToken();
    };

    public destroyCheckToken(){
        this.securityMotorolaService.destroyCheckToken();
    }

    googleInitialize=(onfinally?: ((value: any) => void) | undefined | null): void => {
        google.accounts.id.initialize(
            {
                client_id: environment.googleClientID,
                auto_select: false,
                context: 'signin',
                callback: (data: any) => {
                    if (onfinally) {
                        onfinally(true);
                    }
                    this.setLoggingSubject(true);
                    this.securityMotorolaService.login(data.credential);
                }
            }
        );
    };

    googleRenderButton = (element: HTMLElement) => {
        google.accounts.id.renderButton(element, {
            size: 'large',
            type: 'standard',
            shape: 'rectangular',
            theme: 'filled_blue',
            text: 'signin_with',
            width: 240,
            logo_alignment: 'left',
            cancel_on_tap_outside: 'false'
        });

    };

}
