import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'limitColumn',
})
export class LimitColumnPipe implements PipeTransform {
    transform(arrayValue: any[]): any {
        const value = arrayValue.reduce((acc: string, atual) => {
            return acc.concat(atual.value);
        }, []);

        return value.join(', ');
    }
}
