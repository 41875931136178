import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'BufferPercent'
})
export class ConfigurationBufferPercentPipe implements PipeTransform {
    transform(value: any): string {
        if (value.endsWith('%')) {
            return value;
        }
        const result = parseInt(value) - value;
        return result === 0 ? parseInt(value) + '%' : value + '%';
    }
}
