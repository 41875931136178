import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Subject } from 'rxjs';
import { LayoutConfigService } from './layout-config.service';

@Injectable({
    providedIn: 'root'
})
export class ChatbotSidePanelService {
    isSidePanelOpen!: boolean;
    sidePanelOpenCloseListener$: Subject<boolean> = new Subject<boolean>();
    dialogAgentID: string;

    constructor(private layoutConfigService: LayoutConfigService) {
        this.isSidePanelOpen = layoutConfigService.getConfig('chatbot-side-panel.self.expanded');
        this.dialogAgentID = environment.dialogAgentID;
    }

    /**
     * Called whenever the chatbot side panel status changes from open to closed or vice-versa
     * @param isOpen
     */
    sidePanelOpenCloseChanged(isOpen: boolean): void {
        this.isSidePanelOpen = isOpen;
        this.layoutConfigService.layoutConfig['chatbot-side-panel'] = {
            self: {
                expanded: isOpen,
            }
        };
        this.layoutConfigService.saveConfig(this.layoutConfigService.layoutConfig);
        this.sidePanelOpenCloseListener$.next(isOpen);
    }

    /**
     * Returns proper color depending on module.
     * @returns hex color code
     */
    getHeaderColor(): string {
        return this.layoutConfigService.getConfig('demo') === 'cdc' ? '#279B89' : '#638AB1';
    }

    /*
     * Resets current session and message history.
     */
    restartChat(): void {
        const dfMessenger: any = document.querySelector('df-messenger');
        dfMessenger?.startNewSession({ retainHistory: false });
    }
}
