import { Injectable, TemplateRef } from '@angular/core';

@Injectable()
export class GenericFieldService {
    private templates: Record<string, TemplateRef<any>> = {};

    setTemplate(key: string, templateRef: TemplateRef<any>, isDefault: boolean) {
        if (!(key in this.templates) || !isDefault) {
            this.templates[key] = templateRef;
        }
    }

    getTemplate(key: string): TemplateRef<any> {
        return this.templates[key];
    }
}
