import { SelectionModel } from '@angular/cdk/collections';
import { InjectionToken, TemplateRef } from '@angular/core';
import { IAccessoryValueRequestTableStorage } from '@galvin/core/build-plan/accessory-request-management/interface/accessory-request-table-storage.interface';
import { IProtoRequestTableStorage } from '@galvin/core/build-plan/prototype-request-management/interfaces/proto-request-table-storage.interface';
import { IRequestTableStorage } from '@galvin/core/build-plan/prototype-request-management/interfaces/request-table-storage.interface';
import { LocalStorageHelper } from '@galvin/core/storage/local-storage-helper.service';
import { IProtoRequestCell } from './interfaces/proto-request-cell.interface';

export interface ProtoRequestTableService<C, R> {
    /** apply obj storage in table
     * @param localStorage: IProtoRequestTableStorage
     * @param edited: Record<string, IProtoRequestCell>
     */
    applyLoadStorage(
        localStorage: IProtoRequestTableStorage<
            IRequestTableStorage | IAccessoryValueRequestTableStorage
        >,
        edited: Record<string, IProtoRequestCell<C, R>>
    ): void;

    /** get cell index
     * @param column: C | 'a'
     * @param row: R
     */
    getCellIndex(column: C | 'a', row: R | 'a'): string;

    /** save change by double click in cell
     * @param cell: IProtoRequestCell
     * @param row: R
     * @param column: C
     * @param localStorage: IProtoRequestTableStorage
     */
    onSaveCellValue(
        cell: IProtoRequestCell<C, R>,
        row: R,
        column: C,
        localStorage: IProtoRequestTableStorage<
            IRequestTableStorage | IAccessoryValueRequestTableStorage
        >,
        isImportApproval?: boolean
    ): void;

    /** update change selected
     * @param columnRecord: Record<number, boolean>
     * @param rowRecord: rowRecord: Record<number, boolean>
     * @param  selectionModel: SelectionModel<string>
     * @param  headers: C[]
     * @param edited: Record<string, IProtoRequestCell>
     * @param data: R[]
     */
    updateSelected(
        columnRecord: Record<number, boolean>,
        rowRecord: Record<number, boolean>,
        selectionModel: SelectionModel<string>,
        headers: C[],
        data: R[],
        edited: Record<string, IProtoRequestCell<C, R>>
    ): IProtoRequestCell<C, R>[];

    /** undo change in storage
     * @param prototypeRequestsChanges:any
     * @param column: C
     * @param row: R
     * @param localStorageHelper:LocalStorageHelper
     * @param saveStorageItem: string
     */
    undoStorage(
        prototypeRequestsChanges: any,
        column: C,
        row: R,
        localStorageHelper: LocalStorageHelper,
        saveStorageItem: string
    ): void;

    /** get column
     * @param row: R| 'a'
     * @param column: C | 'a'
     * @param edited: Record<string, IProtoRequestCell>
     * @param data: R[]
     */
    getColumnObject(
        row: R | 'a',
        column: C | 'a',
        edited: Record<string, IProtoRequestCell<C, R>>,
        data: R[]
    ): IProtoRequestCell<C, R>;

    /** toggle select state
     * @param cell: IProtoRequestCell
     * @param editable: IProtoRequestCell
     * @param columnRecord: Record<number, boolean>
     * @param rowRecord: rowRecord: Record<number, boolean>
     * @param elRef: HTMLTableCellElement rowRecord: Record<number, boolean>
     */
    toggleCellState(
        cell: IProtoRequestCell<C, R>,
        editable: IProtoRequestCell<C, R>,
        columnRecord: Record<number, boolean>,
        rowRecord: Record<number, boolean>
    ): void;

    /** Get if column selected
     * @param column: C row that will be verified
     * @param columnRecord: rowRecord: Record<number, boolean>
     */
    selectedColumn(column: C, columnRecord: Record<number, boolean>): boolean;

    /** Get if row selected
     * @param row: R row that will be verified
     * @param rowRecord: rowRecord: Record<number, boolean>
     */
    selectedRow(row: R, rowRecord: Record<number, boolean>): boolean;

    /** Get template input by cell
     * @param editable: IProtoRequestCell editable cell in table
     * @param original: TemplateRef<any> default template
     * @param incoming?: { template: TemplateRef<any> } is have a custom template
     * @param cell?: IProtoRequestCell current cell in table
     * @param defaultEdition?: TemplateRef<any>
     */
    getTemplate(
        editable: IProtoRequestCell<C, R>,
        original: TemplateRef<any>,
        incoming?: { template: TemplateRef<any> },
        cell?: IProtoRequestCell<C, R>,
        defaultEdition?: TemplateRef<any>
    ): TemplateRef<any>;

    /** Get template input by cell
     * @param first: any;
     * @param  last: any;
     * @param headers: C[];
     */
    reduceGroups(
        { first, last }: { first: any; last: any },
        headers: C[]
    ): { [key: string]: { [key: string]: any; colSpan: number } };

    /**
     * @param column: C;
     * @param row: R;
     * @param editable: IProtoRequestCell;
     * @param cellObj: IProtoRequestCell;
     */
    isEditingCell(
        column: C,
        row: R,
        editable: IProtoRequestCell<C, R> | undefined,
        cellObj?: IProtoRequestCell<C, R> | undefined,
    ): boolean;

    /**
     * Check if a prototype or accessory request is canceled
     * @param column
     */
    isCanceled(column: C): boolean;

    sumRequests(value: Array<any>): number;
    sumBoards(value: Array<any>, filteredRevions: string[], allrevisions: any): number;
    sumPortable(value: Array<any>, filteredRevions: string[], allrevisions: any): number;
}

export const PROTO_REQUEST_TABLE_TOKEN = new InjectionToken<ProtoRequestTableService<any, any>>(
    'Manually constructed ProtoRequestTableService'
);
