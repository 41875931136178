import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TodoItemFlatNode } from '@galvin/views/partials/content/general/galvin-tree/todo-item-flat-node.interface';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'propagate-association',
    templateUrl: './propagate-association.component.html',
    styleUrls: ['./propagate-association.component.scss']
})
export class PropagateAssociationComponent<T> {
    constructor(public translate: TranslateService) {}

    @Input() isLoading!: boolean;
    @Input() typeLevelZero!: string;
    @Input() showSuggestName!: boolean;
    @Input() titleFirstSection!: string;
    @Input() titleSecondSection!: string;

    // Data First Section:
    @Input() dataFirstSection: any[] = [];
    @Input() childrenFirstSection!: any;
    @Input() transformerFirstSection!: any;
    @Output() nodesSelectedFirstSection = new EventEmitter();

    // Data Second Section:
    @Input() dataSecondSection: any[] = [];
    @Input() childrenSecondSection!: any;
    @Input() transformerSecondSection!: any;
    @Output() nodesSelectedSecondSection = new EventEmitter();

    onNodesSelectedFirstSection(nodes: TodoItemFlatNode<T>[]): void {
        this.nodesSelectedFirstSection.emit(nodes);
    }

    onNodesSelectedSecondSection(nodes: TodoItemFlatNode<T>[]): void {
        this.nodesSelectedSecondSection.emit(nodes);
    }

    getName(currentName: string, suggestName: string): string {
        return currentName + this.translate.instant('COMMON.PROPAGATE_TO') + suggestName;
    }

    getFirstSectionTitle(): string {
        if (this.dataFirstSection.length > 0) {
            return this.titleFirstSection;
        } else {
            return this.translate.instant('COMMON.PROPAGATE_NO_ASSOCIATIONS');
        }
    }
}
