<div class="filters-container">
    <div class="accessory-filters">
        <p>{{ this.labels.HEADER.FILTERS.FILTER_BY_TITLE }}</p>
        <div *ngIf="loading" class="spinner">
            <galvin-progress-spinner></galvin-progress-spinner>
        </div>
        <div *ngIf="!loading" class="table d-flex flex-wrap">
            <div *ngFor="let parameter of accessoryParameters" [attr.data-type]="parameter.dataType"
                [ngClass]="{hide: !$any(parameter.fieldValues).length}" class="parameter">
                <div *ngIf="$any(parameter.fieldValues).length" class="content">
                    <div class="title">{{ parameter.fieldName }}</div>
                    <div class="values">
                        <ng-container *ngFor="let fieldValue of $any(parameter.fieldValues)">
                            <button (click)="onOptionSelected(fieldValue, parameter)"
                                [attr.data-parameter]="parameter.id + '-' + getValue(fieldValue.value).toLocaleLowerCase()"
                                [ngClass]="{ buttonFilter: isSelected(fieldValue.value, $any(parameter.fieldName)) }"
                                [ngbTooltip]="getValue(fieldValue.value)" class="parameter-value-button" mat-icon-button
                                type="button">{{ getValue(fieldValue.value) }}</button>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>