import { TeamAutomationTypeResource } from './team-automation-type-resource';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResourceService } from '@galvin/core/_base/resource';

import { TeamManagementPaginationResource } from './team-management-pagination-resource';
import { TeamManagementResource } from './team-management-resource';
import { Observable } from 'rxjs';
import { ITeamManagementPagination } from '@galvin/core/build-plan/team-management';
import { ITeamManagement, ITeamManagementProgation } from './../interfaces/team-management.interface';
import { map } from 'rxjs/operators';
import { ETeamAutomationType } from '../enums/team-automation-type.enum';
import { IDeletableValue } from '@galvin/core/build-plan/field-value-management/fvc-parameters/interfaces/deletable-parameter.interface';
import { IBpTeamAssociation, IBpTeamAssociationByStatus } from '../interfaces/team-management-bp-team-associtions.interface';

@Injectable({
    providedIn: 'root'
})
export class TeamManagementService extends ResourceService {
    private teamManagementPaginationResource = new TeamManagementPaginationResource();
    private teamManagementResource = new TeamManagementResource();
    private teamAutomationTypeResource = new TeamAutomationTypeResource();

    constructor(http: HttpClient) {
        super(http);
    }

    getAllPaginated(
        direction: string,
        page: number,
        size: number,
        term?: string
    ): Observable<ITeamManagementPagination> {
        let params = new HttpParams();
        params = params.set('direction', direction.toUpperCase());
        params = params.append('page', page.toString());
        params = params.append('size', size.toString());

        if (term) {
            params = params.append('term', term);
        }

        return this.get<ITeamManagementPagination, TeamManagementPaginationResource>(
            this.teamManagementPaginationResource,
            { params: params }
        ).pipe(
            map((response) => {
                return response.content;
            })
        );
    }

    getAll(): Observable<ITeamManagement[]> {
        this.teamManagementResource._links.self = {
            href: '/build-plan/team-management/'
        };
        return this.get<ITeamManagement, TeamManagementResource>(this.teamManagementResource).pipe(
            map((response) => {
                return response.content;
            })
        );
    }

    getByIdTeam(idTeam: number): Observable<ITeamManagement> {
        this.teamManagementResource._links.self = {
            href: `/build-plan/team-management/${idTeam}`
        };
        return this.get<ITeamManagement, TeamManagementResource>(this.teamManagementResource).pipe(
            map((response) => {
                return response.content;
            })
        );
    }

    getTeamAutomationType(): Observable<ETeamAutomationType> {
        return this.get<ETeamAutomationType, TeamAutomationTypeResource>(
            this.teamAutomationTypeResource
        ).pipe(
            map((response) => {
                return response.content;
            })
        );
    }

    create(team: ITeamManagement): Observable<ITeamManagement> {
        this.teamManagementResource._links.self = {
            href: '/build-plan/team-management'
        };
        this.teamManagementResource.content = team;
        return this.post<ITeamManagement, TeamManagementResource>(this.teamManagementResource).pipe(
            map((response) => {
                return response.content;
            })
        );
    }

    update(team: ITeamManagement): Observable<ITeamManagement> {
        this.teamManagementResource._links.self = {
            href: '/build-plan/team-management'
        };
        this.teamManagementResource.content = team;
        return this.put<ITeamManagement, TeamManagementResource>(this.teamManagementResource).pipe(
            map((response) => {
                return response.content;
            })
        );
    }

    updateMassive(team: ITeamManagement): Observable<ITeamManagement> {
        this.teamManagementResource._links.self = {
            href: '/build-plan/team-management/teams'
        };
        this.teamManagementResource.content = team;
        return this.put<ITeamManagement, TeamManagementResource>(this.teamManagementResource).pipe(
            map((response) => {
                return response.content;
            })
        );
    }

    updateAndPropagate(team: ITeamManagementProgation): Observable<ITeamManagement> {
        this.teamManagementResource._links.self = {
            href: '/build-plan/team-management'
        };
        this.teamManagementResource.content = team;
        return this.put<ITeamManagement, TeamManagementResource>(this.teamManagementResource).pipe(
            map((response) => {
                return response.content;
            })
        );
    }

    updateAndPropagateMassive(team: ITeamManagementProgation): Observable<ITeamManagement> {
        this.teamManagementResource._links.self = {
            href: '/build-plan/team-management/teams'
        };
        this.teamManagementResource.content = team;
        return this.put<ITeamManagement, TeamManagementResource>(this.teamManagementResource).pipe(
            map((response) => {
                return response.content;
            })
        );
    }
    updateEnabled(idTeam: number): Observable<ITeamManagement> {
        this.teamManagementResource._links.self = {
            href: `/build-plan/team-management/${idTeam}/update-team-status`
        };
        return this.put<ITeamManagement, TeamManagementResource>(this.teamManagementResource).pipe(
            map((response) => {
                return response.content;
            })
        );
    }

    updateOrder(team: ITeamManagement): Observable<ITeamManagement> {
        this.teamManagementResource._links.self = {
            href: '/build-plan/team-management/order'
        };
        this.teamManagementResource.content = team;
        return this.put<ITeamManagement, TeamManagementResource>(this.teamManagementResource).pipe(
            map((response) => {
                return response.content;
            })
        );
    }

    getTeamsNotInBuildPlanByScopeLabel(
        buildPlanId: number,
        scopeId: number
    ): Observable<ITeamManagement[]> {
        this.teamManagementResource._links.self = {
            href: `/build-plan/team-management/not-in-build-plan/${buildPlanId}/by-scope/${scopeId}`
        };
        return this.get<ITeamManagement, TeamManagementResource>(this.teamManagementResource).pipe(
            map((response) => {
                return response.content;
            })
        );
    }

    getAllTeamsNotInBuildPlan(buildPlanId: number): Observable<ITeamManagement[]> {
        this.teamManagementResource._links.self = {
            href: `/build-plan/team-management/not-in-build-plan/${buildPlanId}`
        };
        return this.get<ITeamManagement[], TeamManagementResource>(
            this.teamManagementResource
        ).pipe(
            map((response) => {
                return response.content;
            })
        );
    }

    /**
     * Get a list of deletable parameters.
     */
    getDeletableParameters(): Observable<IDeletableValue[]> {
        this.teamManagementResource._links.self = {
            href: '/build-plan/team-management/can-delete'
        };
        return this.get<IDeletableValue, TeamManagementResource>(
            this.teamManagementResource
        ).pipe(map(res => res.content));
    }

    /**
     * Deletes a Scope & all its values, if it's not used.
     * @param idScope
     */
    deleteScopeManagement(idScope: number): Observable<any> {
        this.teamManagementResource._links.self = {
            href: `/build-plan/team-management/${idScope}`
        };
        return this.delete(
            this.teamManagementResource
        );
    }


    /**
     * Get Teams Management Associations by team value ID
     */
    getAllBuildPlanAssociationsByTeamId(idTeam: number): Observable<IBpTeamAssociationByStatus<IBpTeamAssociation>[]> {
        this.teamManagementResource._links.self = {
            href: `/build-plan/team-management/${idTeam}/team-associations`
        };
        return this.get<IBpTeamAssociationByStatus<IBpTeamAssociation>[], TeamManagementResource>(
            this.teamManagementResource
        ).pipe(map(res => res.content));
    }

    /**
     * Get Teams Management Associations by team value IDs
     */
    getAllBuildPlanAssociationsByTeamIds(teamIds: number[]): Observable<IBpTeamAssociationByStatus<IBpTeamAssociation>[]> {
        this.teamManagementResource._links.self = {
            href: '/build-plan/team-management/teams-associations'
        };

        this.teamManagementResource.content = {teamIds};

        return this.post<IBpTeamAssociationByStatus<IBpTeamAssociation>[], TeamManagementResource>(
            this.teamManagementResource
        ).pipe(map(res => res.content));
    }
}
