import { HttpClient } from '@angular/common/http';
import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AuthNoticeService, User } from '@galvin/core/auth';
import { E2eRunnerHandler } from '@galvin/core/utils/e2e/e2e-runner-handler';
import { AppConstants } from '@galvin/views/share/app-constants';
import { Store } from '@ngrx/store';
import jwt_decode from 'jwt-decode';
import { CookieService } from 'ngx-cookie-service';
import { Observable, Subscription } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { AppState } from '../../reducers';
import { LocalStorageHelper } from '../../storage/local-storage-helper.service';
import { SecurityBaseService } from './security-base.service';
import { SecurityMotorolaService } from './security-motorola.service';
import { UserPermissionsService } from './user-permissions.service';
import { NotificationComponentService } from '@galvin/views/partials/layout/topbar/notification/notification.component.service';

@Injectable()
export class SecurityService  extends SecurityBaseService implements OnDestroy{
    protected signInLenovoSubscription?: Subscription;

    public constructor(
        protected override localStorageHelper: LocalStorageHelper,
        protected override cookieService: CookieService,
        protected override ngZone: NgZone,
        protected override router: Router,
        protected override httpClient: HttpClient,
        protected override store: Store<AppState>,
        protected override authNoticeService: AuthNoticeService,
        protected override userPermissionsService: UserPermissionsService,
        private securityMotorolaService:SecurityMotorolaService,
        private notificationComponentService:NotificationComponentService

    ) {
        super(localStorageHelper,cookieService,ngZone,router,httpClient,store,authNoticeService,userPermissionsService);

    }

    override ngOnDestroy() {
        super.ngOnDestroy();
        if (this.signInLenovoSubscription) {
            this.signInLenovoSubscription.unsubscribe();
        }
    }


    /**
     * Removes logged in user information after logout
     */
    public override deleteUser(): void {
        const domain: string | null | undefined = this.user ? this.user.domain : null;
        this.user = null;
        this.localStorageHelper.removeLoggedUser();
        // Delete cookie in case the user was from Lenovo
        if (domain && domain === AppConstants.LENOVO) {
            this.cookieService.delete('jwt');
        }
    }

    /**
     * This method returns an observable boolean when the check the token google
     * and get the user information is completed.
     */
    public waitAuthenticator(): Observable<boolean> {
        const token = this.cookieService.get('jwt');
        if (token) {
            const tokenUser: any = jwt_decode(token);
            const localUser: User = this.getLocalStorageUser() || {
                name: tokenUser.name,
                lastName: tokenUser.lastName,
                picture: tokenUser.picture
            };

            localUser.token = token;
            localUser.domain = 'lenovo';
            this.setUser(localUser);
        }
        return this.waitValidate();
    }


    /**
     * Decodes the token given by google
     *
     * @param token: user token
     */
    public handlePostLogin(token: string) {
        const userDecode: any = jwt_decode(token);
        this.saveUser(userDecode);
        if (new E2eRunnerHandler().isRunnerTest()){
            this.setTokenByTest(token);
        }

        this.redirectHome();
        this.notificationComponentService.loadNotification();
    }

    /**
     * Validates Lenovo user with the server side
     *
     * @param token: user token
     */
    private validateLenovoUser(token: string): void {
        this.signInLenovoSubscription = this.httpClient
            .post<any>(environment.baseUrl + '/lenovo/validate', token)
            .subscribe(
                (data) => {
                    const user = data.content;
                    if (user) {
                        user.token = token;
                        user.domain = AppConstants.LENOVO;
                        this.setUser(user);
                        this.validateUser();
                    } else {
                        this.redirectToLogin();
                    }
                },
                () => {
                    this.authNoticeService.setNotice(
                        'Failed to login Lenovo user. Please make sure you are logging in with valid user credentials.',
                        'danger'
                    );
                }
            );
    }

    /**
     * Function called when user tries to login with Google. This triggers the Google login
     */
    public signInLenovo(): void {
        const user: User | null = this.getLocalStorageUser();
        if (!user) {
            const token: string = this.cookieService.get('jwt');

            if (token) {
                this.validateLenovoUser(token);
            } else {
                this.redirectToLenovoLogin();
            }
        } else {
            if (
                !user.email?.endsWith(AppConstants.MOTOROLA_EMAIL) &&
                !user.email?.endsWith(AppConstants.LENOVO_EMAIL)
            ) {
                this.signOut();
            }
        }
    }

    /**
     * Redirects the user to the appropriated Lenovo login link
     */
    private redirectToLenovoLogin(): void {
        const host: string = window.location.host;

        const loginToRp = AppConstants.ENVIRONMENT_URLS.get(host);

        const loginUrl = `${AppConstants.LENOVO_BASE_URL}/IdpInitiatedSignOn.aspx?loginToRp=${loginToRp}`;

        window.location.replace(loginToRp ? loginUrl : '');
    }

    /**
     * Function called when siging out a user. It clears the user information and redirects the user to login page
     */
    public signOut(redirectLogin = true): void {
        if (this.user && this.user.domain === AppConstants.MOTOROLA) {
            this.securityMotorolaService.logout();
            redirectLogin && this.redirectToLogin();
        } else if (this.user?.domain === AppConstants.LENOVO) {
            this.deleteUser();
            let openedW = window.open(`${AppConstants.LENOVO_BASE_URL}/?wa=wsignout1.0`, '_blank');
            redirectLogin && this.redirectToLogin();
            setTimeout(function() {
                //To prevent error on try show signout view on first time
                openedW?.close();
                window.location.replace(`${AppConstants.LENOVO_BASE_URL}/?wa=wsignout1.0`);
            }, 700);
        }
    }
}
