import { IFieldValue } from '../../field-value-management/interfaces/field-value.interface';
import { TDataType } from '../../field-value-management/enums/data-type.enum';
import { EFieldType } from '../../field-value-management/enums/field-type.enum';

export interface IFilterParameter {
    id?: string;

    type?: string;

    fieldName?: string;

    enabled?: boolean;

    required?: boolean;

    fieldType?: EFieldType;

    dataType?: TDataType | string;

    parameterOrder?: number;

    fieldValues?: Array<IFieldValue>;
}