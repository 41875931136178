import { Pipe, PipeTransform } from '@angular/core';
import { ISkuBoardSamplesSourcing } from '@galvin/core/build-plan/reports/interfaces/sku-board-samples-sourcing';
import { ReportUtils } from '@galvin/core/build-plan/reports/utils/report.utils';

@Pipe({
    name: 'getUnitTypeTotal'
})
export class GetUnitTypeTotal implements PipeTransform {

    transform(hwRevision: any, productType: any, sourcing: any, unitType: any, reportData: ISkuBoardSamplesSourcing): any {
        const total = this.getUnitTypeTotal(hwRevision, productType, sourcing, unitType, reportData);
        return total;
    }

    getUnitTypeTotal(hwRevision: string, productType: string, sourcing: string, unitType: string, reportData: ISkuBoardSamplesSourcing): string | number {
        const filteredItem = reportData.reportDTO?.find(
            objeto => objeto.hwRevisions === hwRevision && objeto.productType === productType && objeto.sourcing === sourcing && objeto.unitType === unitType
        );

        return ReportUtils.getValue(filteredItem?.totalRequests);
    }
}