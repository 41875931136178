import { SelectionModel } from '@angular/cdk/collections';
import { AccessoryRequestService } from '@galvin/core/build-plan/accessory-request-management/services/accessory-request.service';
//Angular
import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    TemplateRef
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {
    ITeamAssociationSelectorItem,
    ITeamSelectorItem
} from '@galvin/core/build-plan/prototype-request-management/interfaces/team-selector-item.interface';
import { PrototypeRequestService } from '@galvin/core/build-plan/prototype-request-management/services/prototype-request.service';
import { UtilsFlag } from '@galvin/core/utils/utils.flag';
import { LayoutUtilsService, ToastrType } from '@galvin/core/_base/crud';
import { TranslateService } from '@ngx-translate/core';
import { isEmpty } from 'lodash';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, finalize, tap } from 'rxjs/operators';
import { TeamSelectorService } from '@galvin/core/build-plan/management/services/team-selector.service';
import {IBuildPlanSelectorItem} from '@galvin/core/build-plan/prototype-request-management/interfaces/build-plan-selector-item.interface';

@Component({
    selector: 'team-selector',
    templateUrl: './team-selector.component.html',
    styleUrls: ['./team-selector.component.scss', '../selectors-common.scss']
})
export class TeamSelectorComponent implements OnInit, OnDestroy {
    loadingTeamsSelector = false;
    teams: ITeamSelectorItem[] = [];
    filtredTeams: ITeamSelectorItem[] = [];

    selectedTeam: ITeamSelectorItem | null = null;

    @Input()
        isAccessory = false;
    @Input()
        isMultiSelection = false;
    @Input()
        useBuildPlanTeams = false;
    @Input()
        cacheKey!: string;
    @Output()
        changeTeam = new EventEmitter<ITeamSelectorItem | null>();
    @Output()
        changeTeams = new EventEmitter<ITeamSelectorItem[]>();
    @Output()
        openChange = new EventEmitter<boolean>();

    checkboxTemplate!: TemplateRef<any>;
    initialSelection = [];
    formSearch: FormGroup;
    buildPlan!: IBuildPlanSelectorItem;

    readonly selection: SelectionModel<ITeamSelectorItem>;
    private subscription: Subscription;
    private ALL_TEAM_SELECTOR_TITLE: string;
    private ALL_TEAM_FILTERED_SELECTOR_TITLE: string;
    private TEAM_SELECTED_TITLE: string;

    constructor(
        private changeDetector: ChangeDetectorRef,
        private layoutUtilsService: LayoutUtilsService,
        private translate: TranslateService,
        private utilsFlag: UtilsFlag,
        private prototypeRequestService: PrototypeRequestService,
        private accessoryRequestService: AccessoryRequestService,
        private teamSelectorService: TeamSelectorService
    ) {
        this.subscription = new Subscription();
        this.selection = new SelectionModel<ITeamSelectorItem>(true, this.initialSelection);
        this.ALL_TEAM_SELECTOR_TITLE = this.translate.instant(
            'BUILD_PLAN.PROTOTYPE_REQUEST.HEADER.TEAM_SELECTOR.ALL'
        );
        this.ALL_TEAM_FILTERED_SELECTOR_TITLE = this.translate.instant(
            'BUILD_PLAN.PROTOTYPE_REQUEST.HEADER.TEAM_SELECTOR.ALL_FILTERED'
        );
        this.TEAM_SELECTED_TITLE = this.translate.instant(
            'BUILD_PLAN.PROTOTYPE_REQUEST.HEADER.TEAM_SELECTOR.TEAM_SELECTED_TITLE'
        );

        this.formSearch = new FormGroup({
            filter: new FormControl('')
        });
    }

    get hasSelection(): boolean {
        if (this.isMultiSelection) {
            return this.selection.selected.length > 0;
        } else {
            return !!this.selectedTeam;
        }
    }

    get teamSelectorFilter(): string {
        return this.formSearch.controls['filter'].value;
    }

    set teamSelectorFilter(value: string) {
        this.formSearch.controls['filter'].setValue('');
    }

    ngOnInit(): void {
        this.initialSetup();

        this.subscription.add(
            this.teamSelectorService.selectedTeams$.subscribe((teamsIds) => this.selectFunction(teamsIds))
        );

        this.subscription.add(
            this.teamSelectorService.selectedTeamsMassive$.subscribe((teamsIds) => this.selectFunction(teamsIds))
        );

        this.subscription.add(
            this.formSearch.valueChanges
                .pipe(
                    debounceTime(100),
                    distinctUntilChanged(),
                    tap((control) => {
                        const filterValue: string = control.filter.toLowerCase();

                        if (filterValue === '') {
                            this.filtredTeams = this.teams;
                        }

                        this.filtredTeams = this.teams.filter((team) => {
                            const isFiltered = !!team.name?.toLowerCase().includes(filterValue);
                            const isTeamAssociationFiltered =
                                team.teamAssociationUnlikeAliasList &&
                                team.teamAssociationUnlikeAliasList.length > 0 &&
                                team.teamAssociationUnlikeAliasList.filter(
                                    (ta) =>
                                        ta.teamAssociationName
                                            .toLowerCase()
                                            .includes(filterValue) ||
                                        ta.buildPlanInternalProductName
                                            .toLowerCase()
                                            .includes(filterValue)
                                ).length > 0;

                            if (
                                team.requestOwnerUser &&
                                team.finalRecipientUser &&
                                team.shipToLocation &&
                                team.cdcLocation
                            ) {
                                const isFirstUserFiltered = this.containsInFilter(
                                    filterValue,
                                    team.requestOwnerUser.email,
                                    team.requestOwnerUser.lastName,
                                    team.requestOwnerUser.name
                                );
                                const isFinalUserFiltered = this.containsInFilter(
                                    filterValue,
                                    team.finalRecipientUser.email,
                                    team.finalRecipientUser.lastName,
                                    team.finalRecipientUser.name
                                );
                                const isShipToLocationFiltered = this.containsInFilter(
                                    filterValue,
                                    team.shipToLocation.city,
                                    team.shipToLocation.country
                                );
                                const isCdcLocationFiltered = this.containsInFilter(
                                    filterValue,
                                    team.cdcLocation.city,
                                    team.cdcLocation.country
                                );

                                return (
                                    isFiltered ||
                                    isTeamAssociationFiltered ||
                                    isShipToLocationFiltered ||
                                    isCdcLocationFiltered ||
                                    isFirstUserFiltered ||
                                    isFinalUserFiltered
                                );
                            } else {
                                return isFiltered || isTeamAssociationFiltered;
                            }
                        });
                        this.changeDetector.detectChanges();
                    })
                )
                .subscribe()
        );
    }

    private selectFunction = (teamsIds: number | number[] | undefined) => {
        if(teamsIds){
            this.initialSetup(teamsIds);
        }else{
            this.initialSetup();
        }
    };

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
        this.changeDetector.detach();
    }

    containsInFilter(filterValue: string, ...params: any[]): boolean {
        return params.some((p) => p && p.toLowerCase().includes(filterValue));
    }

    initialSetup(newTeamId?: number | number[]): void {
        if (this.isAccessory) {
            this.accessoryRequestService.isTeamLoaded = false;
            this.accessoryRequestService.setCachePrefix(this.cacheKey);
        } else {
            this.prototypeRequestService.isTeamLoaded = false;
            this.prototypeRequestService.setCachePrefix(this.cacheKey);
        }
        if (this.useBuildPlanTeams) {
            if (this.isAccessory) {
                this.loadToAccessoryRequestBuildPlanToTeam(newTeamId);
            } else {
                this.loadToPrototypeRequestBuildPlanToTeams(newTeamId);
            }
        } else {
            this.loadFromMyPrototype();
        }
    }

    forceSelectTeamById(teamId?: number | number[]): void{
        if(Array.isArray(teamId)){
            teamId.forEach(ti => this.forceSelectTeam(ti));
        } else if(teamId){
            this.forceSelectTeam(teamId);
        }
    }

    private forceSelectTeam(teamId: number){
        let team =  this.filtredTeams.filter(e => e.id == teamId)[0] as ITeamSelectorItem;
        this.selectTeams(new MouseEvent(''), team);
        this.dropdownChange(false);
    }

    loadFromMyPrototype(): void {
        this.subscription.add(
            this.prototypeRequestService
                .getMyPrototypesTeamSelector()
                .pipe(
                    finalize(() => {
                        this.loadingTeamsSelector = false;
                        this.changeDetector.detectChanges();
                    })
                )
                .subscribe(
                    (response: ITeamSelectorItem[]) => {
                        if (response) {
                            this.teams = response;
                            this.filtredTeams = response;
                            this.setTeamListMultiSelector();
                        }
                    },
                    () => {
                        this.layoutUtilsService.showToastrNotification(
                            this.translate.instant(
                                'BUILD_PLAN.PROTOTYPE_REQUEST.HEADER.TEAM_SELECTOR.LOAD_ERROR'
                            ),
                            ToastrType.error
                        );
                    }
                )
        );
    }

    loadToPrototypeRequestBuildPlanToTeams(newTeamId?: number | number[]): void {
        this.subscription.add(
            this.prototypeRequestService.selectedBuildPlanIdChange.subscribe((buildPlan) => {
                this.buildPlan = buildPlan;
                if (buildPlan.teams && buildPlan.teams.length > 0) {
                    this.setTeams(buildPlan.teams);
                    this.endDropDonwChange();
                } else {
                    this.loadTeamSelector(buildPlan);
                }
            })
        );
        if(this.buildPlan){
            this.loadTeamSelector(this.buildPlan, newTeamId);
        }
    }

    loadToAccessoryRequestBuildPlanToTeam(newTeamId?: number| number[]): void {
        this.subscription.add(
            this.accessoryRequestService.buildPlanSelected$.subscribe((buildPlan) => {
                this.buildPlan = buildPlan;
                this.loadTeamSelector(buildPlan);
            })
        );
        if(this.buildPlan){
            this.loadTeamSelector(this.buildPlan, newTeamId);
        }
    }

    /**
     * Loads the build plans selector data from the server on page init
     */
    loadTeamSelector(buildplan: IBuildPlanSelectorItem, newTeamId?: number | number[]): void {
        this.loadingTeamsSelector = true;
        this.teams = [];
        this.filtredTeams = [];

        this.subscription.add(
            this.prototypeRequestService
                .getTeamSelectorByBuildPlan(buildplan.id)
                .pipe(
                    finalize(() => {
                        if(newTeamId){
                            this.forceSelectTeamById(newTeamId);
                        }
                        this.endDropDonwChange();
                    })
                )
                .subscribe(
                    (response: ITeamSelectorItem[]) => {
                        if (response) {
                            buildplan.teams = response;
                            this.setTeams(response);
                        }
                    },
                    () => {
                        this.layoutUtilsService.showToastrNotification(
                            this.translate.instant(
                                'BUILD_PLAN.PROTOTYPE_REQUEST.HEADER.TEAM_SELECTOR.LOAD_ERROR'
                            ),
                            ToastrType.error
                        );
                        this.isAccessory
                            ? (this.accessoryRequestService.teamSelectedNull = null)
                            : this.prototypeRequestService.selectedTeamIdChange.error(null);
                    }
                )
        );
    }

    setTeams(teams: ITeamSelectorItem[]) {
        this.teams = teams;
        this.filtredTeams = teams;
        this.prototypeRequestService.isTeamLoaded = true;
        this.prototypeRequestService.noTeamFound = false;
        this.setTeamSelector();
        this.setTeamListMultiSelector();
    }

    endDropDonwChange() {
        this.dropdownChange(false);
        this.loadingTeamsSelector = false;
        this.changeDetector.detectChanges();
    }

    /**
     * Checks if a given team or bp team should be filtered
     *
     * @param team: team being filtered
     * @returns true if all the team or bp team is filtered, false otherwise
     */
    isTeamFiltered(team: ITeamSelectorItem): boolean {
        const filterValue = this.teamSelectorFilter.trim().toLowerCase();
        const isFiltered = !team.name?.toLowerCase().includes(filterValue);
        const isRequestOwnerUserFiltered =
            !team.requestOwnerUser?.name?.toLowerCase().includes(filterValue) &&
            !team.requestOwnerUser?.lastName?.toLowerCase().includes(filterValue) &&
            !team.requestOwnerUser?.email?.toLowerCase().includes(filterValue);
        const isFinalRecipientUserFiltered =
            !team.finalRecipientUser?.name?.toLowerCase().includes(filterValue) &&
            !team.finalRecipientUser?.lastName?.toLowerCase().includes(filterValue) &&
            !team.finalRecipientUser?.email?.toLowerCase().includes(filterValue);
        const isShipToLocationFiltered =
            !team.shipToLocation?.city?.toLowerCase().includes(filterValue) &&
            !team.shipToLocation?.country?.toLowerCase().includes(filterValue);
        const isCdcLocationFiltered =
            !team.cdcLocation?.city?.toLowerCase().includes(filterValue) &&
            !team.cdcLocation?.country?.toLowerCase().includes(filterValue);
        const isTeamAssociationFiltered =
            team.teamAssociationUnlikeAliasList &&
            team.teamAssociationUnlikeAliasList.length > 0 &&
            team.teamAssociationUnlikeAliasList.filter(
                (ta) =>
                    ta.teamAssociationName.toLowerCase().includes(filterValue) ||
                    ta.buildPlanInternalProductName.toLowerCase().includes(filterValue)
            ).length > 0;

        team.filtered =
            isFiltered &&
            !isTeamAssociationFiltered &&
            isRequestOwnerUserFiltered &&
            isFinalRecipientUserFiltered &&
            isShipToLocationFiltered &&
            isCdcLocationFiltered;
        return team.filtered;
    }

    /**
     * Called when a team is selected
     *
     * @param team
     */
    selectTeam(team: ITeamSelectorItem): void {
        if (!isEmpty(team)) {
            this.selectedTeam = team;
            this.changeTeam.next(this.selectedTeam);
            if (this.isAccessory) {
                this.accessoryRequestService.teamSelected = team;
                this.accessoryRequestService.noTeamFound = false;
            } else {
                this.prototypeRequestService.setTeamSelection(team.id);
                this.prototypeRequestService.noTeamFound = false;
            }
        } else {
            this.selectedTeam = null;
            this.changeTeam.next(this.selectedTeam);
            if (this.isAccessory) {
                this.accessoryRequestService.teamSelected = { id: -1, name: '' };
                this.accessoryRequestService.noTeamFound = true;
            } else {
                this.prototypeRequestService.setTeamSelection(-1);
                this.prototypeRequestService.noTeamFound = true;
            }
        }
    }

    /**
     * Called when a team is added in the selection
     *
     * @param buildPlan
     */
    selectTeams($event: MouseEvent, team: ITeamSelectorItem): void {
        if ($event !== null) {
            $event.stopPropagation();
        }

        if (!isEmpty(team)) {
            this.selection.toggle(team);
            this.changeTeams.next(this.selection.selected);
        } else {
            this.isAccessory
                ? (this.accessoryRequestService.noTeamFound = true)
                : (this.prototypeRequestService.noTeamFound = true);
        }
    }

    /**
     * Called when a toggle teams is added in the selection
     *
     * @param buildPlan
     */
    selectToggleTeams(): void {
        // select the filtered teams
        if (this.teamSelectorFilter.length > 0) {
            const teamsFiltered: ITeamSelectorItem[] = this.teams.filter(
                (team) => !this.isTeamFiltered(team)
            );
            const teamsSelector: ITeamSelectorItem[] = this.selection.selected.filter((team) =>
                teamsFiltered.includes(team)
            );
            // check if filtered was selected
            if (teamsSelector.length === teamsFiltered.length) {
                // deselect the filtered teams
                teamsFiltered.forEach((team) => this.selection.deselect(team));
            } else {
                this.selection.select(...teamsFiltered);
            }
        } else {
            // select the all teams
            if (this.selection.selected.length === this.teams.length) {
                this.selection.clear();
            } else {
                this.selection.select(...this.teams);
            }
        }
    }

    /**
     * Checks if the selector has any Build Plans being shown (not filtered)
     *
     * @returns true there are build plans being shown in the selector, false otherwise
     */
    hasTeamsToShow(): boolean {
        if(this.teams.length > 0 && this.filtredTeams.length !== 0) {
            return true;
        }
        return false;
    }

    /**
     * Called whenever selector dropdown state changes (open/close). This clears the filter input on close.
     * @param isOpen: true if selector is being opened, false if it's being closed.
     */
    dropdownChange(isOpen: boolean): void {
        if (!isOpen) {
            this.teamSelectorFilter = '';
        }

        // If isMultiSelection is true,then update childs.
        if (!isOpen && this.isMultiSelection) {
            if (this.isAccessory) {
                this.accessoryRequestService.teamsSelected = this.selection.selected;
                this.accessoryRequestService.noTeamFound = false;
            } else {
                this.prototypeRequestService.setTeamsSelection(this.selection.selected);
                this.prototypeRequestService.noTeamFound = false;
            }
        }
        this.openChange.emit(isOpen);
    }

    isSelectorDisabled(): boolean {
        return this.loadingTeamsSelector;
    }

    getTooltipForTeamAssociation(team: ITeamAssociationSelectorItem): string {
        return team.teamAssociationName + ' -- ' + team.buildPlanInternalProductName;
    }

    canShowBpTeams(team: ITeamSelectorItem): boolean {
        return (
            !!team.teamAssociationUnlikeAliasList && team.teamAssociationUnlikeAliasList.length > 0
        );
    }

    /**
     * Get title for teams selected
     **/
    public getTitleTeams(): string {
        let total = this.selection.selected.length;

        if (total === 0) {
            total = this.teams.length;
        }
        return total + this.TEAM_SELECTED_TITLE;
    }

    public getAllOption(): string {
        if (this.teamSelectorFilter.length === 0) {
            return this.ALL_TEAM_SELECTOR_TITLE;
        }
        return this.ALL_TEAM_FILTERED_SELECTOR_TITLE;
    }

    public getFlag(country: string): string {
        return `mr-2 fi fis ${this.utilsFlag.getFlag(country).class}`;
    }

    public trackByFunction(index: number, item: unknown): number | null {
        if (!item) {
            return null;
        }
        return index;
    }

    /**
     * Set the teams selector items
     *
     * @param selectorData: list of build plans for the selector received from the server
     */
    private setTeamSelector(): void {
        const bpTeamId = this.isAccessory
            ? this.accessoryRequestService.getTeamSelection()
            : this.prototypeRequestService.getTeamSelection();
        const bpTeam = this.teams.find((bpTeam) => bpTeam.id === bpTeamId);

        if (!bpTeam) {
            this.selectTeam(this.teams[0]);
        } else {
            this.selectTeam(bpTeam);
        }
    }

    /**
     * Set the teams selector items
     *
     * @param selectorData: list of build plans for the selector received from the server
     */
    private setTeamListMultiSelector(): void {
        const bpTeamIds = this.isAccessory
            ? this.accessoryRequestService.getTeamsSelection()
            : this.prototypeRequestService.getTeamsSelection();
        const teams = this.teams.filter((bpTeam) => bpTeamIds.includes(bpTeam.id));

        //Set Teams for multiple selection
        this.selection.clear();
        if (teams && teams.length > 0) {
            this.selection.select(...teams);
        } else {
            this.selection.select(...this.teams);
        }
        this.changeTeams.next(this.selection.selected);
        this.isAccessory
            ? (this.accessoryRequestService.teamsSelected = this.selection.selected)
            : this.prototypeRequestService.setTeamsSelection(this.selection.selected);
    }
}
