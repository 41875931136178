<!-- region blank placeholder -->
<div #elementRefTmp (scroll)="onScroll($any($event))" class='table-container'>

    <div *ngIf="isLoading" class="loading mt-5 position-absolute">
        <mat-spinner diameter="30"></mat-spinner>
    </div>

    <div [style.height.px]="content.height" [style.width.px]="content.width" class="table-content"></div>

    <div class="table-header">
        <div [style.minWidth.px]="leftHeader?.offsetWidth" class="blank-corner">

            <div>
                <div class="buttons-options d-flex justify-content-between">
                    <div d-flex justify-content-around>
                        <div class="div-quick" *ngIf="showQuickAction"
                            [ngClass]="{'quick-action-show':checkedQuickAction}">
                            <span class="label-quick">
                                {{'BUILD_PLAN.REQUEST_PREVIEW.QUICK_ACTION' | translate}}
                            </span>
                            <mat-slide-toggle [checked]="checkedQuickAction"
                                (change)="onChangeQuick($event)"></mat-slide-toggle>
                        </div>

                    </div>
                    <div *ngIf="renderButtonsTip" class="buttons-edit">
                        <ng-template #tipCut>
                            <b>{{'BUILD_PLAN.REQUEST_PREVIEW.CUT' | translate}} &nbsp;
                                <kbd>{{'BUILD_PLAN.REQUEST_PREVIEW.CUT_SHORTCUT' | translate}} &nbsp;</kbd></b>
                        </ng-template>
                        <ng-template #tipCopy>
                            <b>{{'BUILD_PLAN.REQUEST_PREVIEW.COPY' | translate}} &nbsp;
                                <kbd>{{'BUILD_PLAN.REQUEST_PREVIEW.COPY_SHORTCUT' | translate}} &nbsp;</kbd></b>
                        </ng-template>
                        <ng-template #tipPaste>
                            <b>{{'BUILD_PLAN.REQUEST_PREVIEW.PASTE' | translate}} &nbsp;
                                <kbd>{{'BUILD_PLAN.REQUEST_PREVIEW.PASTE_SHORTCUT' | translate}}</kbd> </b>
                        </ng-template>
                        <ng-template #tipErase>
                            <b>{{'BUILD_PLAN.REQUEST_PREVIEW.ERASE' | translate}} &nbsp;
                                <kbd>{{'BUILD_PLAN.REQUEST_PREVIEW.ERASE_SHORTCUT' | translate}}</kbd></b>
                        </ng-template>
                        <button (click)="cut()" [disabled]="!canCopy" [ngbTooltip]="tipCut" color="primary"
                            mat-raised-button>
                            <mat-icon>content_cut</mat-icon>
                        </button>
                        <button (click)="copy()" [disabled]="!canCopy" [ngbTooltip]="tipCopy" color="primary"
                            mat-raised-button>
                            <mat-icon>content_copy</mat-icon>
                        </button>
                        <button (click)="paste()" [disabled]="!canPaste" [ngbTooltip]="tipPaste" color="primary"
                            mat-raised-button>
                            <mat-icon>content_paste</mat-icon>
                        </button>
                        <button (click)="erase()" [disabled]="!canErase" [ngbTooltip]="tipErase" color="warn"
                            mat-raised-button>
                            <mat-icon>delete_sweep</mat-icon>
                        </button>
                    </div>
                </div>
                <div>

                    <ng-content select="[buttonsActionsHeader]">

                    </ng-content>



                    <div class="icon-colapse-container show-hide-header-extra-block">
                        <span (click)="showHideHeaderInfo()" *ngIf="expandButtonHeader && !isHeaderInfoVisible"
                            class="material-icons header-info-icon"
                            ngbTooltip="{{'BUILD_PLAN.REQUEST_PREVIEW.UNCOLLAPSE_HEADER_INFO' | translate}}">
                            expand_more
                        </span>
                        <span (click)="showHideHeaderInfo()" *ngIf="expandButtonHeader && isHeaderInfoVisible"
                            class="material-icons header-info-icon"
                            ngbTooltip="{{'BUILD_PLAN.REQUEST_PREVIEW.COLLAPSE_HEADER_INFO' | translate}}">
                            expand_less
                        </span>
                    </div>
                </div>
            </div>

        </div>
        <table #tableHeaderElement [style.marginLeft.px]="tablePositionLeft" class="groups" groupRow>
            <thead>
                <tr>
                    <th *ngFor="let number of getSizeTotals()" [style.left.px]="leftHeader?.offsetWidth"
                        class="empty-col"></th>
                    <th *ngFor="let group of groups | keyvalue : stringKeytoNumber; trackBy:trackByFunction"
                        [attr.data-group]="$any(group.value).item.name.toUpperCase()"
                        [colSpan]="$any(group.value).colSpan" class="group" [style.padding-left.px]="0"
                        [style.padding-right.px]="0">
                        <div class="table-header-wrapper">
                            <div [style.left.px]="leftHeaderOffsetWidth + totalRequestOffsetWidth + (isTotalBoardsVisible ? totalBoardsOffsetWidth : 0)"
                                [style.maxWidth.px]="$any(cellSize)?.width - 16">
                                <ng-container [ngTemplateOutletContext]="{item: $any(group.value).item}"
                                    [ngTemplateOutlet]="getTemplate(ghTemplate,groupHeaderRef)"></ng-container>
                            </div>
                        </div>
                    </th>
                </tr>
                <tr>
                    <th  #totalRequestCol *ngIf="isTotalAccessories" [style.left.px]="leftHeaderOffsetWidth" [style.max-width.px]="70"
                        class="position-stick fixed-column" >
                        <div class="d-flex">
                            <span>
                                {{'BUILD_PLAN.REQUEST_PREVIEW.TOTAL_REQUEST_FULL_VIEW' | translate}}
                            </span>
                        </div>
                    </th>
                    <ng-container *ngIf="!isTotalAccessories && isTotalPortableExpanded">
                        <th *ngFor="let group of portableGroups"
                            [style.left.px]="leftHeaderOffsetWidth + $any(totalRequestCol)?.offsetWidth"
                            [style.max-width.px]="70" class="subtotals-col" style="z-index: 999"
                            [attr.data-group]="group.toUpperCase()">
                            <div class="d-flex total-requests-header small-font">
                                <span *ngIf="group === portableGroups[0]" (click)="showHideExpandedTotalPortable()"
                                    class="material-icons hover-icon first-button margin button-size"
                                    ngbTooltip="{{'BUILD_PLAN.REQUEST_PREVIEW.HIDE_DETAILS_PORTABLE' | translate}}">
                                    remove
                                </span>
                                <span [style.margin-left.px]="8">
                                    {{group}}
                                </span>
                            </div>

                        </th>

                    </ng-container>
                    <th #totalRequestCol *ngIf="!isTotalAccessories" [style.left.px]="leftHeader?.offsetWidth"
                        class="position-stick test" style="z-index: 999">
                        <div class=" d-flex total-requests-header fixed-column">
                            <span *ngIf="!isTotalPortableExpanded" (click)="showHideExpandedTotalPortable()"
                                class="material-icons hover-icon button-size first-button" [style.margin-top.px]="11"
                                ngbTooltip="{{'BUILD_PLAN.REQUEST_PREVIEW.SHOW_DETAILS_PORTABLE' | translate}}">
                                add
                            </span>
                            <span *ngIf="isTotalPortableExpanded && portableGroups.length===0" (click)="showHideExpandedTotalPortable()"
                                class="material-icons hover-icon button-size first-button" [style.margin-top.px]="11"
                                ngbTooltip="{{'BUILD_PLAN.REQUEST_PREVIEW.SHOW_DETAILS_PORTABLE' | translate}}">
                                remove
                            </span>
                            <div class="center-title">
                                <span>
                                    {{'BUILD_PLAN.REQUEST_PREVIEW.TOTAL_PORTABLE_REQUEST_FULL_VIEW' | translate}}
                                </span>
                            </div>

                            <div class="colapse-total-boards-container button-middle">
                                <span (click)="showHideTotalBoards()" *ngIf="!isTotalBoardsVisible"
                                    class="material-icons collapsible-icon"
                                    ngbTooltip="{{'BUILD_PLAN.REQUEST_PREVIEW.SHOW_TOTAL_BOARDS' | translate}}">
                                    chevron_right
                                </span>
                                <span (click)="showHideExpandedTotalBoards()"
                                    *ngIf="isTotalBoardsVisible && !isTotalBoardsExpanded"
                                    class="material-icons hover-icon collapsible-icon button-size"
                                    ngbTooltip="{{'BUILD_PLAN.REQUEST_PREVIEW.SHOW_DETAILS_BOARDS' | translate}}">
                                    add
                                </span>
                                <span (click)="showHideExpandedTotalBoards()"
                                    *ngIf="isTotalBoardsVisible && isTotalBoardsExpanded"
                                    class="material-icons hover-icon collapsible-icon button-size"
                                    ngbTooltip="{{'BUILD_PLAN.REQUEST_PREVIEW.HIDE_DETAILS_BOARDS' | translate}}">
                                    remove
                                </span>
                            </div>


                        </div>
                    </th>
                    <ng-container *ngIf="!isTotalAccessories && isTotalBoardsVisible && isTotalBoardsExpanded">
                        <th *ngFor="let group of boardGroups"
                            [style.left.px]="leftHeaderOffsetWidth + totalPortableOffsetWidth"
                            [style.max-width.px]="50" class="subtotals-col" [attr.data-group]="group.toUpperCase()">
                            <div class="d-flex total-requests-header small-font board-group-title">
                                <span [ngClass]="{'small-title': group === 'EVB'}">
                                    {{group}}
                                </span>
                            </div>
                        </th>
                    </ng-container>
                    <th *ngIf="isTotalBoardsVisible && !isTotalAccessories" #totalBoardsCol
                        [style.left.px]="leftHeaderOffsetWidth" [style.max-width.px]="70"
                        class="position-stick">
                        <div class="d-flex total-requests-header fixed-column">
                            <span [style.margin-left.px]="4">
                                {{'BUILD_PLAN.REQUEST_PREVIEW.TOTAL_BOARDS_REQUEST_FULL_VIEW' | translate}}
                            </span>

                            <span (click)="showHideTotalBoards()" *ngIf="isTotalBoardsVisible"
                                class="material-icons collapsible-icon" [style.margin-top.px]="10"
                                [style.margin-left.px]="3"
                                ngbTooltip="{{'BUILD_PLAN.REQUEST_PREVIEW.HIDE_TOTAL_BOARDS' | translate}}">
                                chevron_left
                            </span>

                        </div>

                    </th>
                    <th (click)="toggleColSelect(headers[col], col,$event.ctrlKey,$event.shiftKey)"
                        *ngFor="let col of columns | slice:xRange.first:xRange.last; trackBy:trackByFunction"
                        [attr.data-column]="col" [attr.data-group]="$any(headers[col])?.revision?.name?.toUpperCase()"
                        [class.selectedHeader]="selectedColumn(headers[col])" [style.max-width.px]="cellSize.width"
                        [style.min-width.px]="cellSize.width" [style.top.px]="groupRow.height" class="header">
                        <ng-container [ngTemplateOutletContext]="{item: headers[col]}"
                            [ngTemplateOutlet]="getTemplate(thTemplate, headerTemplateRef)">
                        </ng-container>
                    </th>
                </tr>
            </thead>
        </table>
    </div>
    <div class="table-data">
        <div #leftHeader [style.marginTop.px]="tablePositionTop + tableHeaderOffsetHeight" class="left-header">
            <div (click)="toggleRowSelect(data[row],row,$event.ctrlKey,$event.shiftKey)"
                *ngFor="let row of rows | slice:yRange.first:yRange.last; trackBy:trackByFunction"
                [style.height.px]="cellSize.height"
                class="header row-header">
                <ng-container [ngTemplateOutletContext]="{item: data[row]}"
                    [ngTemplateOutlet]="getTemplate(rhTemplate, rowHeaderRef)"></ng-container>
            </div>
        </div>
        <table [style.marginLeft.px]="tablePositionLeft"
            [style.marginTop.px]="tablePositionTop + tableHeaderOffsetHeight">
            <tbody>

                <tr *ngFor="let row of rows | slice:yRange.first:yRange.last; trackBy:trackByFunction"
                    [style.height.px]="cellSize.height">

                    <td (click)="toggleRowSelect(data[row],row,$event.ctrlKey, $event.shiftKey)"
                        *ngIf="isTotalAccessories" [style.left.px]="leftHeaderOffsetWidth" class="position-stick">
                        <div [style.height.px]="cellSize.height"
                            class="total-request text-center">
                            <span class="col">{{$any(data[row])?.totalRequests}}</span>
                        </div>
                    </td>
                    <ng-container *ngIf="!isTotalAccessories">
                        <ng-container *ngIf="isTotalPortableExpanded">
                            <td [style.left.px]="leftHeaderOffsetWidth" class="subtotals-col"
                                *ngFor="let group of portableGroups">
                                <div [class.selectedTotal]="selectedRow(data[row])" [style.height.px]="cellSize.height"
                                    class="subtotal-request text-center">
                                    <span class="col">{{$any(data[row]).requests | sumPortable: filteredHwRevisions!:
                                        $any(getPortableHwRevisionsByGroup(group))}}</span>
                                </div>
                            </td>
                        </ng-container>
                        <td (click)="toggleRowSelect(data[row],row,$event.ctrlKey, $event.shiftKey)"
                            [style.left.px]="leftHeaderOffsetWidth" class="position-stick" style="z-index: 999">
                            <div [class.selectedTotal]="selectedRow(data[row])" [style.height.px]="cellSize.height"
                                class="total-request text-center">
                                <span class="col">{{$any(data[row]).requests | sumPortable: filteredHwRevisions!:
                                    $any(getPortableHwRevisions)}}</span>
                            </div>
                        </td>
                        <ng-container *ngIf="isTotalBoardsVisible && isTotalBoardsExpanded">
                            <td *ngFor="let group of boardGroups"
                                [style.left.px]="leftHeaderOffsetWidth + $any(totalRequestCol)?.offsetWidth"
                                class="subtotals-col">
                                <div [class.selectedTotal]="selectedRow(data[row])" [style.height.px]="cellSize.height"
                                    class="subtotal-request text-center">
                                    <span class="col">{{$any(data[row]).requests | sumBoards: filteredHwRevisions!:
                                        $any(getBoardsHwRevisionsByGroup(group))}}</span>
                                </div>
                            </td>

                        </ng-container>
                        <td (click)="toggleRowSelect(data[row],row,$event.ctrlKey, $event.shiftKey)"
                            [style.left.px]="leftHeaderOffsetWidth + $any(totalRequestCol)?.offsetWidth"
                            *ngIf="isTotalBoardsVisible" class="position-stick">
                            <div [class.selectedTotal]="selectedRow(data[row])" [style.height.px]="cellSize.height"
                                class="total-request text-center">
                                <span class="col">{{$any(data[row]).requests | sumBoards: filteredHwRevisions!:
                                    $any(getBoardsHwRevisions)}}</span>
                            </div>
                        </td>
                    </ng-container>
                    <td #tableDataCellElement
                        (click)="toggleCellSelect(headers[column], data[row], row, column, tableDataCellElement, $event.ctrlKey, $event.shiftKey)"
                        (dblclick)="toggleCellState(getColumnObject(headers[column], data[row]), tableDataCellElement); $event.stopImmediatePropagation()"
                        *ngFor="let column of columns | slice:xRange.first:xRange.last; trackBy:trackByFunction"
                        [style.minWidth.px]="cellSize.width"
                        [class.selected]="isSelected(headers[column], data[row])"
                        [class.edited]="isEditedCell(headers[column], data[row])"
                        [ngClass]="{'cancelled-prototype': isCanceled(headers[column])}"
                        class="p-0">
                        <div class="d-flex">
                            <div fullviewMentionsCommentIndicator [column]="headers[column]" [row]="data[row]" ></div>
                            <mat-icon *ngIf="!isEditingCell(headers[column], data[row])" (click)="undoEdition($event, headers[column], data[row])" class="edited-cell bt-undo-cell">
                                undo
                            </mat-icon>
                            <ng-container *ngIf="!isEditingCell(headers[column], data[row]);else cellEditing"
                                [ngTemplateOutlet]="getTemplate(cellTemplate, cellRef)"
                                [ngTemplateOutletContext]="getColumnObject(headers[column], data[row])">
                            </ng-container>
                            <ng-template #cellEditing>
                                <ng-container [ngTemplateOutlet]="defaultEditTemplate"
                                    [ngTemplateOutletContext]="getColumnObject(headers[column], data[row])">
                                </ng-container>
                            </ng-template>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
<ng-container *ngIf="isEditable">
    <prototype-request-form (saveStorage)="saveStorageItem && saveStorage($event)"
        (selectionChange)="onSelectionChange($any($event))" (undoAll)="saveStorageItem && undoAll()"
        (undoOnlyEdition)="undoOnlyEdition()" (undoSomeCells)="undoSomeCells($event)" (click)="clearLastSelectedCell()"
        (forceUpdateData)="triggerDataChange()" [canOverwriteJustifications]="canChangeJustificationAndComment"
        [collectionSize]="getSelected().length" [disabled]="getSelected().length <=0" [hasChanges]="hasChanges"
        [hideSpecialRequiments]="hideSpecialRequiments" [loadStorage]="localStorage" [requests]="$any(getSelected())">
    </prototype-request-form>
</ng-container>
<!-- region DEFAULT TEMPLATES -->
<ng-template #ghTemplate let-item="item">
    {{item}}
</ng-template>

<ng-template #thTemplate let-item="item">
    {{item}}
</ng-template>

<ng-template #rhTemplate let-item="item">
    {{item}}
</ng-template>

<ng-template #cellTemplate let-item="item">
    {{item}}
</ng-template>

<ng-template #defaultEditTemplate let-$state="$state" let-column="$column" let-item="item" let-row="$row">
    <div (click)="$event.stopImmediatePropagation()" (dblclick)="$event.stopImmediatePropagation()"
        [style.maxHeight.px]="cellSize.height" [style.maxWidth.px]="cellSize.width" class="edition-container d-flex">
        <input #inputElement [value]="item.quantity" autofocus
            id="edit-request-{{row.idTeam}}-{{column.idConfiguration}}" min="0" type="number"
            (keypress)="onKeyPressCell($event, column, row, inputElement)"
            (keydown)="onKeyDownCell($event, {item: item, $row: row, $column: column, $state: $state})"
            (blur)="onBlurCell($event, inputElement)">
    </div>
</ng-template>
<!-- endregion DEFAULT TEMPLATES -->