export class AppConstants {
    public static MOTOROLA = 'motorola';
    public static LENOVO = 'lenovo';
    public static MOTOROLA_EMAIL = '@motorola.com';
    public static LENOVO_EMAIL = '@lenovo.com';

    public static DEV_M_ENVIRONMENT_URL = 'galvin-dev.motorola.com';
    public static DEV_M_LOGIN_TO_RP = 'Galvin_Dev_M';

    public static DEV_A_ENVIRONMENT_URL = 'galvin-dev.appspot.com';
    public static DEV_A_LOGIN_TO_RP = 'Galvin_Dev_A';

    public static DEV_UCRA_ENVIRONMENT_URL = 'galvin-dev.uc.r.appspot.com';
    public static DEV_UCRA_LOGIN_TO_RP = 'Galvin_Dev_UCRA';

    //MDUAT
    public static MDUAT_M_ENVIRONMENT_URL = 'galvin-mduat.motorola.com';
    public static MDUAT_M_LOGIN_TO_RP = 'Galvin_Mduat_M';

    public static MDUAT_A_ENVIRONMENT_URL = 'galvin-mduat.appspot.com';
    public static MDUAT_A_LOGIN_TO_RP = 'Galvin_Mduat_A';

    public static MDUAT_UCRA_ENVIRONMENT_URL = 'galvin-mduat.uc.r.appspot.com';
    public static MDUAT_UCRA_LOGIN_TO_RP = 'Galvin_Mduat_UCRA';

    //Prod
    public static PROD_M_ENVIRONMENT_URL = 'galvin.motorola.com';
    public static PROD_M_LOGIN_TO_RP = 'Galvin_Prod_M';

    public static PROD_A_ENVIRONMENT_URL = 'galvin-prod.appspot.com';
    public static PROD_A_LOGIN_TO_RP = 'Galvin_Prod_A';

    public static PROD_UCRA_ENVIRONMENT_URL = 'galvin-prod.uc.r.appspot.com';
    public static PROD_UCRA_LOGIN_TO_RP = 'Galvin_Prod_UCRA';

    public static LENOVO_BASE_URL = 'https://stscn.lenovo.com/adfs/ls';

    public static ENVIRONMENT_URLS = new Map<string, string>([
        //Dev
        [AppConstants.DEV_M_ENVIRONMENT_URL, AppConstants.DEV_M_LOGIN_TO_RP],
        [AppConstants.DEV_A_ENVIRONMENT_URL, AppConstants.DEV_A_LOGIN_TO_RP],
        [AppConstants.DEV_UCRA_ENVIRONMENT_URL, AppConstants.DEV_UCRA_LOGIN_TO_RP],
        //Mduat
        [AppConstants.MDUAT_M_ENVIRONMENT_URL, AppConstants.MDUAT_M_LOGIN_TO_RP],
        [AppConstants.MDUAT_A_ENVIRONMENT_URL, AppConstants.MDUAT_A_LOGIN_TO_RP],
        [AppConstants.MDUAT_UCRA_ENVIRONMENT_URL, AppConstants.MDUAT_UCRA_LOGIN_TO_RP],
        //Prod
        [AppConstants.PROD_M_ENVIRONMENT_URL, AppConstants.PROD_M_LOGIN_TO_RP],
        [AppConstants.PROD_A_ENVIRONMENT_URL, AppConstants.PROD_A_LOGIN_TO_RP],
        [AppConstants.PROD_UCRA_ENVIRONMENT_URL, AppConstants.PROD_UCRA_LOGIN_TO_RP]
    ]);
}
