import { IResourceLink } from './resource-link.interface';
import { IResourceLinkCollection } from './resource-link-collection.interface';
import { ResourceLinkNotFound } from './resource-link-not-found.error';

export class GenericResource<T> {
    _links: IResourceLinkCollection = { self: {} as IResourceLink };

    constructor(path?: string, public content?: T | T[]) {
        if (path) {
            this._links.self = { href: path };
        }
    }

    get self(): IResourceLink {
        return this._links.self;
    }

    getLinkByName(name: string): IResourceLink {
        // eslint-disable-next-line no-prototype-builtins
        if (!this._links.hasOwnProperty(name)) {
            throw new ResourceLinkNotFound();
        }
        return this._links[name];
    }
}
