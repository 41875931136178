<div ngbDropdown container="body" #dropDown="ngbDropdown" [ngClass]="{'mx-2': !smallMode}">
    <ng-template #buttonTitle>
        {{'COMMON.BUTTONS.FREEZE'|translate}}
        <span *ngIf="haveFreezedColumns()">({{this.actualMaxCols}}/{{this.maxFreezedCol}})</span>
    </ng-template>
    <button mat-raised-button ngbDropdownToggle class="galvin-btn-primary" [ngbTooltip]="buttonTitle">
        <i class="far fa-snowflake"></i>
        <ng-container *ngIf="!smallMode" >
            <ng-container [ngTemplateOutlet]="buttonTitle" ></ng-container>
            <span class="material-icons">keyboard_arrow_down</span>
        </ng-container>
    </button>
    <div ngbDropdownMenu class="p-2">
        <div class="freeze-columns-dropdown">
            <mat-selection-list>
                <ng-container *ngIf="groups">
                    <ng-container *ngFor="let group of groups">
                        <mat-checkbox (change)="$event && onClickGroup($event, group)"
                            [checked]="selectedGroupsModel.isSelected($any(group[groupPropDescription]))"
                            (click)="$event.stopPropagation()" class="mw-100 freeze-groups" color="primary"
                            [attr.data-group]="group[groupPropDescription]">
                            <ng-template #defaultGroupLabelTemplate>
                                <span class="ml-1 text-dark font-weight-normal text-truncate freeze-group-name"
                                    [ngbTooltip]="$any(group[groupPropDescription])">
                                    <b>{{group[groupPropDescription]}}</b>
                                </span>
                            </ng-template>
                            <ng-template [ngTemplateOutletContext]="{item: group}"
                                [ngTemplateOutlet]="defaultGroupLabelTemplate">
                            </ng-template>
                        </mat-checkbox>
                        <ng-container
                            *ngFor="let column of columns | filterBy:$any(groupComparator):$any(group)[groupComparator]">
                            <mat-list-option [ngbTooltip]="getColumnDescription(column)"
                                [value]="getColumnDescription(column)" color="primary" checkboxPosition="before"
                                class="text-truncate pl-4">
                                {{ getColumnDescription(column) }}
                            </mat-list-option>
                        </ng-container>
                    </ng-container>
                </ng-container>
                <ng-container *ngIf="!groups">
                    <ng-container *ngFor="let column of columns">
                        <mat-list-option [value]="getColumnDescription(column)" color="primary"
                            checkboxPosition="before" class="text-truncate">
                            {{ getColumnDescription(column) }}
                        </mat-list-option>
                    </ng-container>
                </ng-container>
            </mat-selection-list>
        </div>
    </div>
</div>