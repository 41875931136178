<div
    [ngSwitch]="status"
    #elementContainer
    class="generic-fvc-field-container justify-content-center"
    [attr.editable]="isEditable"
>
    <div
        *ngSwitchCase="'READ'"
        (click)="edit()"
        [ngClass]="getEditableClass(isEditable)"
        [class.color-data]="validColor"
        class="d-flex align-items-center"
    >
        <ng-container [ngTemplateOutlet]="genericFVCColumnRef.template"> </ng-container>
    </div>

    <div *ngSwitchCase="'LOADING'" class="d-flex align-self-center align-content-center">
        <mat-spinner diameter="16"></mat-spinner>
    </div>

    <div *ngSwitchCase="'SAVING'" class="d-flex align-self-center align-content-center">
        {{ field.displayValue }}
    </div>

    <div *ngSwitchCase="'EDIT'" class="edit">
        <ng-container *ngIf="column.type | isTypeFromConfiguration:typesFromConfiguration">
            <ng-container *ngTemplateOutlet="configurationTemplate"></ng-container>
        </ng-container>

        <ng-container *ngIf="!(column.type | isTypeFromConfiguration:typesFromConfiguration)">
            <div [ngSwitch]="column.type" class="d-flex align-self-center align-content-center">
                <ng-container
                    *ngSwitchCase="'REQUEST_OWNER'"
                    class="d-flex align-self-center align-content-center"
                >
                    <ng-container *ngTemplateOutlet="ngSelectTemplateUser"></ng-container>
                </ng-container>
                <ng-container
                    *ngSwitchCase="'CDC_LOCATION'"
                    class="d-flex align-self-center align-content-center"
                >
                    <ng-container *ngTemplateOutlet="ngSelectTemplate"></ng-container>
                </ng-container>
                <ng-container
                    *ngSwitchCase="'SHIP_TO'"
                    class="d-flex align-self-center align-content-center"
                >
                    <ng-container *ngTemplateOutlet="ngSelectTemplate"></ng-container>
                </ng-container>
                <ng-container
                    *ngSwitchCase="'FINAL_RECIPIENT'"
                    class="d-flex align-self-center align-content-center"
                >
                    <ng-container *ngTemplateOutlet="ngSelectTemplateUser"></ng-container>
                </ng-container>
                <ng-container
                    *ngSwitchCase="'TEAM_VALUES'"
                    class="d-flex align-self-center align-content-center"
                >
                    <ng-container *ngTemplateOutlet="ngSelectTemplate"></ng-container>
                </ng-container>
                <ng-container
                    *ngSwitchCase="'SCOPE_VALUES'"
                    class="d-flex align-self-center align-content-center"
                >
                    <ng-container *ngTemplateOutlet="ngSelectTemplate"></ng-container>
                </ng-container>
                <ng-container
                    *ngSwitchCase="'NAME'"
                    class="d-flex align-self-center align-content-center"
                >
                    <ng-container *ngTemplateOutlet="textTemplate"></ng-container>
                </ng-container>
                <ng-container
                    *ngSwitchCase="'NAME_AUTOMATION'"
                    class="d-flex align-self-center align-content-center"
                >
                    <ng-container *ngTemplateOutlet="textTemplate"></ng-container>
                </ng-container>
                <ng-container
                    *ngSwitchCase="'GROUP'"
                    class="d-flex align-self-center align-content-center"
                >
                    <ng-container *ngTemplateOutlet="ngSelectTemplate"></ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="'SCOPE_LABELS'">
                    <ng-container *ngTemplateOutlet="ngSelectMultipleTemplate"></ng-container>
                </ng-container>

                <ng-container *ngSwitchCase="'SKU_MATRIX'" class="d-flex align-self-center align-content-center">
                    <ng-container *ngTemplateOutlet="ngSelectTemplate"></ng-container>
                </ng-container>

                <ng-container *ngIf="isSaving" class="loading">
                    <mat-spinner diameter="16"></mat-spinner>
                </ng-container>
            </div>
        </ng-container>
    </div>

    <ng-template #configurationTemplate>
        <div [ngSwitch]="column.fieldType" class="d-flex align-self-center align-content-center">
            <ng-container *ngSwitchCase="'Options'">
                <mat-form-field fxFlex="10" appearance="outline">
                    <ng-select
                        *ngIf="!checkByColFVCType(column.type)"
                        #genericSelectField
                        ngSelectMat
                        (ngModelChange)="markAsDirty()"
                        [disabled]="isSaving"
                        [searchable]="true"
                        [items]="options"
                        appendTo="body"
                        bindLabel="displayValue"
                        [clearable]="false"
                        (keydown)="onKeyDownCell($event)"
                        (keydown.enter)="($event)"
                        (blur)="onBlurCell()"
                        [(ngModel)]="field"
                        class="auto-generic-field"
                        [closeOnSelect]="true"
                    >
                        <ng-template ng-label-tmp let-item="item">
                            <ng-template #tooltipLabel>
                                <div [innerHTML]="getLabelSelect(item?.displayValue)"></div>
                            </ng-template>

                            <div
                                class="d-flex w-100 align-items-center"
                                [ngbTooltip]="tooltipLabel"
                            >
                                <span
                                    class="flex-first"
                                    [innerHTML]="getLabelSelect(item?.displayValue)"
                                ></span>
                            </div>
                        </ng-template>
                        <ng-template ng-option-tmp let-item="item" let-item$="item$">
                            <ng-template #tooltipOption>
                                <div [innerHTML]="getLabelSelect(item?.displayValue)"></div>
                            </ng-template>

                            <div
                                class="d-flex w-100 align-items-center"
                                [ngbTooltip]="tooltipOption"
                                placement="left"
                            >
                                <div
                                    *ngIf="isColor"
                                    class="color-circle"
                                    [style.background-color]="item.value"
                                    [attr.data-color]="item.value"
                                ></div>

                                <span
                                    [ngClass]="getStatusCssClass(item?.value)"
                                    class="material-icons build-plan-card-status-icon"
                                >
                                    {{ getStatusIcon(item?.value) }}
                                </span>

                                <span
                                    class="flex-first"
                                    [innerHTML]="getLabelSelect(item?.displayValue, item$, item)"
                                >
                                </span>
                            </div>
                        </ng-template>
                    </ng-select>
                    <ng-select
                        *ngIf="checkByColFVCType(column.type)"
                        #genericSelectField
                        ngSelectMat
                        (ngModelChange)="markAsDirty()"
                        [disabled]="isSaving"
                        [searchable]="true"
                        [items]="options"
                        appendTo="body"
                        bindLabel="displayValue"
                        [clearable]="false"
                        (keydown)="onKeyDownCell($event)"
                        (keydown.enter)="($event)"
                        (blur)="onBlurCell()"
                        [(ngModel)]="field"
                        class="auto-generic-field"
                        [closeOnSelect]="true"
                        [addTag]="addNewOptionToSelector.bind(this, value_to_add, column)"
                    >
                        <ng-template ng-label-tmp let-item="item">
                            <ng-template #tooltipLabel>
                                <div [innerHTML]="getLabelSelect(item?.displayValue)"></div>
                            </ng-template>

                            <div
                                class="d-flex w-100 align-items-center"
                                [ngbTooltip]="tooltipLabel"
                            >
                                <span
                                    class="flex-first"
                                    [innerHTML]="getLabelSelect(item?.displayValue)"
                                ></span>
                            </div>
                        </ng-template>
                        <ng-template ng-option-tmp let-item="item" let-item$="item$">
                            <ng-template #tooltipOption>
                                <div [innerHTML]="getLabelSelect(item?.displayValue)"></div>
                            </ng-template>

                            <div
                                class="d-flex w-100 align-items-center"
                                [ngbTooltip]="tooltipOption"
                                placement="left"
                            >
                                <div
                                    *ngIf="isColor"
                                    class="color-circle"
                                    [style.background-color]="item.value"
                                    [attr.data-color]="item.value"
                                ></div>

                                <span
                                    [ngClass]="getStatusCssClass(item?.value)"
                                    class="material-icons build-plan-card-status-icon"
                                >
                                    {{ getStatusIcon(item?.value) }}
                                </span>

                                <span
                                    class="flex-first"
                                    [innerHTML]="getLabelSelect(item?.displayValue, item$, item)"
                                >
                                </span>
                            </div>
                        </ng-template>
                    </ng-select>
                </mat-form-field>
            </ng-container>

            <ng-container
                *ngSwitchCase="'String'"
                class="d-flex align-self-center align-content-center"
            >
                <mat-form-field fxFlex="40" appearance="outline">
                    <mat-label></mat-label>
                    <input
                        matInput
                        (ngModelChange)="markAsDirty()"
                        [disabled]="isSaving"
                        [(ngModel)]="field.value"
                        (keydown)="onKeyDownCell($event)"
                        (blur)="onBlurCell()"
                        [name]="field.value"
                    />
                </mat-form-field>
            </ng-container>

            <ng-container
                *ngSwitchCase="'Int'"
                class="d-flex align-self-center align-content-center"
            >
                <mat-form-field fxFlex="40" style="font-size: 10px" appearance="outline">
                    <input
                        min="0"
                        matInput
                        (ngModelChange)="markAsDirty()"
                        type="number"
                        [disabled]="isSaving"
                        (keydown)="onKeyDownCell($event)"
                        (blur)="onBlurCell()"
                        [(ngModel)]="field.value"
                        [name]="field.value"
                    />
                </mat-form-field>
            </ng-container>

            <ng-container
                *ngSwitchCase="'Double'"
                class="d-flex align-self-center align-content-center"
            >
                <mat-form-field fxFlex="40" style="font-size: 10px" appearance="outline">
                    <input
                        min="0"
                        matInput
                        (ngModelChange)="markAsDirty()"
                        type="number"
                        [disabled]="isSaving"
                        (keydown)="onKeyDownCell($event)"
                        (blur)="onBlurCell()"
                        [(ngModel)]="field.value"
                        [name]="field.value"
                    />
                </mat-form-field>
            </ng-container>

            <ng-container *ngSwitchCase="'Boolean'">
                <mat-checkbox
                    class="example-margin"
                    [checked]="field.value"
                    [disabled]="isSaving || !isEditable"
                >
                    <span [ngClass]="{ 'opt-yes': field.value, 'opt-no': !field.value }"> </span>
                </mat-checkbox>
            </ng-container>

            <ng-container
                *ngSwitchCase="'Date'"
                class="d-flex align-self-center align-content-center"
            >
                <mat-form-field appearance="outline">
                    <input
                        matInput
                        [matDatepicker]="picker1"
                        (dateChange)="onDateSelected($event); markAsDirty()"
                        (keydown)="onKeyDownCell($event)"
                        (blur)="onBlurCell()"
                        [value]="field.value"
                        readonly
                    />
                    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                    <mat-datepicker
                        #picker1
                        (opened)="onOpenMatDatePicker()"
                        (closed)="onCloseMatDatePicker()"
                        [calendarHeaderComponent]="checkIfOverWritten()"
                    ></mat-datepicker>
                </mat-form-field>
            </ng-container>

            <ng-container *ngIf="isSaving" class="loading">
                <mat-spinner diameter="16"></mat-spinner>
            </ng-container>
        </div>
    </ng-template>

    <ng-template #textTemplate>
        <mat-form-field fxFlex="40" appearance="outline">
            <input
                matInput
                (ngModelChange)="markAsDirty()"
                type="text"
                [disabled]="isSaving"
                [(ngModel)]="field.value"
                (keydown)="onKeyDownCell($event)"
                (blur)="onBlurCell()"
                [name]="field.value"
            />
        </mat-form-field>
    </ng-template>

    <ng-template #dateTemplate>
        <mat-form-field fxFlex="40" appearance="outline">
            <input
                matInput
                [matDatepicker]="picker1"
                (dateChange)="onDateSelected($event); markAsDirty()"
                (keydown)="onKeyDownCell($event)"
                (blur)="onBlurCell()"
                [value]="field.value"
                readonly
            />
            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
        </mat-form-field>
    </ng-template>
    <ng-template #ngSelectTemplate>
        <mat-form-field fxFlex="40" appearance="outline">
            <ng-container *ngIf="checkByScopeColType(column.type)" fxFlex="40" appearance="outline">
                <ng-select
                    #genericSelectField
                    ngSelectMat
                    (ngModelChange)="markAsDirty()"
                    [disabled]="isSaving"
                    [items]="options"
                    bindLabel="displayValue"
                    [(ngModel)]="field"
                    [clearable]="false"
                    [loading]="isLoadingOptions"
                    (keydown)="onKeyDownCell($event)"
                    (blur)="onBlurCell()"
                    [title]="field?.value"
                    [closeOnSelect]="true"
                    appendTo="body"
                    [required]="true"
                    class="auto-generic-field"
                    [addTag]="this.data.value.data.scopeValue.scopeParameters.enabled && addNewOptionToSelector.bind(this, value_to_add, column)"
                >
                    <ng-template ng-label-tmp let-item="item">
                        <ng-template #tooltipLabel>
                            <div [innerHTML]="getTooltipToLabelNgSelect()"></div>
                        </ng-template>
                        <div class="d-flex">
                            <div
                                *ngIf="isColor"
                                class="color-circle"
                                [attr.data-color]="item.value"
                                [style.background-color]="item?.value"
                            ></div>
                            <span
                                class="flex-first"
                                [ngbTooltip]="tooltipLabel"
                                [innerHTML]="item.displayValue"
                            ></span>
                        </div>
                    </ng-template>

                    <ng-template ng-option-tmp let-item="item">
                        <ng-template #tooltipOption>
                            <div [innerHTML]="getTooltipToLabelNgSelect(item)"></div>
                        </ng-template>
                        <div class="d-flex">
                            <div
                                *ngIf="isColor"
                                class="color-circle"
                                [attr.data-color]="item.value"
                                [style.background-color]="item?.value"
                            ></div>
                            <span class="flex-first"
                            [ngbTooltip]="tooltipOption"
                            triggers="manual"
                            #t1="ngbTooltip"
                            placement="left"
                            (mouseover)="openTooltip(t1)"
                            [innerHTML]="item.displayValue"  (mouseout)="closeTooltip(t1)">{{item.displayValue}}</span>
                        </div>
                    </ng-template>
                </ng-select>
            </ng-container>
            <ng-container
                *ngIf="!checkByScopeColType(column.type)"
            >
                <ng-select
                    #genericSelectField
                    ngSelectMat
                    (ngModelChange)="markAsDirty()"
                    [disabled]="isSaving"
                    [items]="options"
                    bindLabel="displayValue"
                    [(ngModel)]="field"
                    [clearable]="false"
                    [loading]="isLoadingOptions"
                    (keydown)="onKeyDownCell($event)"
                    (blur)="onBlurCell()"
                    [title]="field?.value"
                    [closeOnSelect]="true"
                    appendTo="body"
                    [required]="true"
                    class="auto-generic-field"
                    [addTag]="checkByTeamsColType(column.type) && addNewOptionToSelector.bind(this, value_to_add, column)"
                >
                    <ng-template ng-label-tmp let-item="item">
                        <ng-template #tooltipLabel>
                            <div [innerHTML]="getTooltipToLabelNgSelect()"></div>
                        </ng-template>
                        <div class="d-flex">
                            <div
                                *ngIf="isColor"
                                class="color-circle"
                                [attr.data-color]="item.value"
                                [style.background-color]="item?.value"
                            ></div>
                            <span
                                class="flex-first"
                                [ngbTooltip]="tooltipLabel"
                                [innerHTML]="item.displayValue"
                            ></span>
                        </div>
                    </ng-template>

                    <ng-template ng-option-tmp let-item="item">
                        <ng-template #tooltipOption>
                            <div [innerHTML]="getTooltipToLabelNgSelect(item)"></div>
                        </ng-template>
                        <div class="d-flex">
                            <div
                                *ngIf="isColor"
                                class="color-circle"
                                [attr.data-color]="item.value"
                                [style.background-color]="item?.value"
                            ></div>
                            <span class="flex-first"
                            [ngbTooltip]="tooltipOption"
                            triggers="manual"
                            #t1="ngbTooltip"
                            placement="left"
                            (mouseover)="openTooltip(t1)"
                            [innerHTML]="item.displayValue"  (mouseout)="closeTooltip(t1)">{{item.displayValue}}</span>
                        </div>
                    </ng-template>
                </ng-select>
            </ng-container>

        </mat-form-field>
    </ng-template>

    <ng-template #ngSelectMultipleTemplate>
        <mat-form-field fxFlex="40" appearance="outline">
            <ng-select
                ngSelectMat
                appendTo="body"
                [(ngModel)]="field.value"
                (ngModelChange)="markAsDirty()"
                [disabled]="isSaving"
                [clearable]="true"
                [items]="options"
                [virtualScroll]="true"
                [title]="field?.value"
                [addTag]="validationLabel"
                [multiple]="true"
                [hideSelected]="false"
                [required]="true"
                (keydown)="onKeyDownCell($event)"
                (blur)="onBlurCell()"
                class="auto-generic-field"
            >
                <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
                    {{ item }}
                </ng-template>
            </ng-select>
        </mat-form-field>
    </ng-template>

    <ng-template #ngSelectTemplateUser>
        <mat-form-field fxFlex="40" appearance="outline">
            <ng-container
            >
                <ng-select
                    #genericSelectField
                    ngSelectMat
                    (ngModelChange)="markAsDirty()"
                    [disabled]="isSaving"
                    [items]="options"
                    bindLabel="displayValue"
                    [(ngModel)]="field"
                    [clearable]="false"
                    [loading]="isLoadingOptions"
                    (keydown)="onKeyDownCell($event)"
                    (blur)="onBlurCell()"
                    [title]="field?.value"
                    [closeOnSelect]="true"
                    appendTo="body"
                    [required]="true"
                    class="auto-generic-field"
                    [addTag]="addUserByDialog.bind(this, value_to_add, column)"
                >
                    <ng-template ng-label-tmp let-item="item">
                        <ng-template #tooltipLabel>
                            <div [innerHTML]="getTooltipToLabelNgSelect()"></div>
                        </ng-template>
                        <div class="d-flex">
                            <div
                                *ngIf="isColor"
                                class="color-circle"
                                [attr.data-color]="item.value"
                                [style.background-color]="item?.value"
                            ></div>
                            <span
                                class="flex-first"
                                [ngbTooltip]="tooltipLabel"
                                [innerHTML]="item.displayValue"
                            ></span>
                        </div>
                    </ng-template>

                    <ng-template ng-option-tmp let-item="item">
                        <ng-template #tooltipOption>
                            <div [innerHTML]="getTooltipToLabelNgSelect(item)"></div>
                        </ng-template>
                        <div class="d-flex">
                            <div
                                *ngIf="isColor"
                                class="color-circle"
                                [attr.data-color]="item.value"
                                [style.background-color]="item?.value"
                            ></div>
                            <span class="flex-first"
                            [ngbTooltip]="tooltipOption"
                            triggers="manual"
                            #t1="ngbTooltip"
                            placement="left"
                            (mouseover)="openTooltip(t1)"
                            [innerHTML]="item.displayValue"  (mouseout)="closeTooltip(t1)">{{item.displayValue}}</span>
                        </div>
                    </ng-template>
                </ng-select>
            </ng-container>
        </mat-form-field>
    </ng-template>

</div>
