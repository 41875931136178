import { EAccessoryType } from '../../accessory-management/enums/accessory-type.enum';
import { EFieldType } from '../../field-value-management';
import { TDataType } from '../../field-value-management/enums/data-type.enum';
import { EConfigurationType } from './configuration-type.enum';
import { IFVCSource } from './fvc-sources.interface';
import { EConfigurationTypeValue } from '@galvin/core/build-plan/configuration-management';

export interface IConfigurationColumnValue {
    id: string;
    name: string;
    displayValue?: string;
    description: string;
    enabled: boolean;
    type: keyof typeof EConfigurationTypeValue;
    readonly: boolean;
    hidden: boolean;
    fieldType: EFieldType;
    item: any;
    order: number;
    new?: boolean;
    required?: boolean;
    loading?: boolean;
    disabled?: boolean;
    filtered?: boolean;
}

export enum EStatus {
    READ = 'READ',
    EDIT = 'EDIT',
    LOADING = 'LOADING',
    SAVING = 'SAVING'
}

export interface IConfigurationTableValue {
    status: EStatus;
    options: any[];
    id?: number;
    configurationId?: number;
    value?: any;
    displayValue?: any;
    associationId?: number;
    fieldType: EFieldType;
    dataType: TDataType;
    item: any;
    enabled: boolean;
    column?: any;
    data?: any;
    field?: any;
}

export interface ConfigurationUpdatedFieldsResponse{
    responseData:IConfigurationTableData[];

}

export interface IConfigurationUpdateValue {
    id?: number;
    value?: any;
    associationId?: number;
}

export interface IConfigurationData {
    columns: IConfigurationColumnValue[];
    rows: IConfigurationTableData[];
    fvcSources?: IFVCSource[];
}

export interface IConfigurationTableData {
    CONFIGURATION_ID: IConfigurationTableValue;

    [key: string]: IConfigurationTableValue;
}

export interface IConfigurationUpdateMultipleColumns {
    buildPlanID?: number;
    configurationsID?: Array<number>;
    fields?: Array<IUpdateColumnsType>;
}

export interface IUpdateColumnsType {
    columnID?: string;
    type?: EConfigurationType | EAccessoryType;
    value?: string;
    valueID?: string | null;
    item?: any;
}
