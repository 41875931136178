import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from './../../../../environments/environment';
import { IBannerInformation } from './interfaces/banner-information.interface';

@Injectable({
    providedIn: 'root'
})
export class BannerInformationService {
    readonly API_URL = `${environment.baseUrlApi}/galvin/banner-information`;

    constructor(private http: HttpClient) {}

    /**
     * Get Actived Banner Information
     */
    getActivedBannerInformation(): Observable<IBannerInformation> {
        return this.http.get<any>(`${this.API_URL}/active`).pipe(map((res) => res.content));
    }
}
