import { Component, Input } from '@angular/core';
import { ITooltip } from '@galvin/core/_base/general/galvin-dialog-generic/interfaces/dialog-data.interface';
import { GalvinDialogGenericService } from '../../../../../../core/_base/general/galvin-dialog-generic/services/galvin-dialog-generic.service';
import { IButtonConfig } from './../../../../../../core/_base/general/galvin-dialog-generic/interfaces/button-config.interface';

@Component({
    selector: 'footer-dialog-generic',
    templateUrl: './footer-dialog-generic.component.html',
    styleUrls: ['./footer-dialog-generic.component.scss']
})
export class FooterDialogGenericComponent {
    @Input() cancelButton: any;
    @Input() customButton: any;
    @Input() saveOrEditButton: any;
    @Input() enabled = true;
    @Input() saving = false;
    @Input() tooltips!: ITooltip;

    constructor(private genericService: GalvinDialogGenericService) {}

    saveOrEdit(): void {
        if (this.enabled) {
            this.genericService.save.next();
        }
    }

    customSave(): void {
        if (this.enabled) {
            this.genericService.custom.next();
        }
    }

    cancel(): void {
        this.genericService.cancel$.next();
    }

    getTooltipSaveOrEdit() {
        return this.saveOrEditButton.name === 'save' ? this.tooltips.save : this.tooltips.edit;
    }

    checkIfExist(button: IButtonConfig): boolean {
        return button && button.value ? true : false;
    }

    canShowButton(button: IButtonConfig): boolean {
        return button && button.value ? true : false;
    }

    canDisabledCancelButton(): boolean {
        return this.saving;
    }

    canDisabledCreateButton(): boolean {
        return !this.genericService.buttonEnabled || !this.enabled || this.saving;
    }
}
