import { SelectionModel } from '@angular/cdk/collections';
import {
    CdkDragDrop,
    CdkDragStart,
    moveItemInArray,
    transferArrayItem
} from '@angular/cdk/drag-drop';
import {
    AfterContentChecked,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ContentChild,
    Directive,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    NgZone,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    ViewChild,
    ViewChildren
} from '@angular/core';
import { EDirection } from '@galvin/core/utils/direction.enum';
import { CellRefDirective } from '@galvin/views/partials/layout/configuration-table/cell-ref-directive';
import { GroupHeaderRefDirective } from '@galvin/views/partials/layout/configuration-table/group-header-ref-directive';
import { RowCollapseRefDirective } from '@galvin/views/partials/layout/configuration-table/row-collapse-directive';
import { TableHeaderRefDirective } from '@galvin/views/partials/layout/configuration-table/table-header-ref-directive';
import { TableCellSize } from '@galvin/views/partials/layout/table-edit-inline/table-cell-size.interface';
import { ITableEditInlineHeader } from '@galvin/views/partials/layout/table-edit-inline/table-edit-inline-header.interface';
import { TableEditInlineService } from '@galvin/views/partials/layout/table-edit-inline/table-edit-inline.service';
import { TableGroup } from '@galvin/views/partials/layout/table-edit-inline/table-group.interface';
import { TableGroupsReduceFunction } from '@galvin/views/partials/layout/table-edit-inline/table-groups-reduce-function.type';
import { TableRange } from '@galvin/views/partials/layout/table-edit-inline/table-range.interface';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { MultiDrag } from './multi-drag';
import { MultiSelect } from './multi-select';
import {
    CanSelectRowsFunction,
    CellSizeFunction,
    HighlightFunction
} from './table-groups-reduce-function.type';

@Directive({
    selector: '[galvinTableHeader]'
})
export class GalvinTableHeaderDirective {
    @Input('galvinTableHeader')
        ref: unknown;
}

export interface ITableEditInlineFreezeColumns {
    index: number;
    leftPosition: number;
    data: any;
}

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'table-edit-inline',
    templateUrl: './table-edit-inline.component.html',
    styleUrls: ['./table-edit-inline.component.scss'],
    exportAs: 'tableEditInline',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableEditInlineComponent<H extends Partial<ITableEditInlineHeader> = any, R = any>
implements OnInit, OnDestroy,  AfterContentChecked
{
    colSpanX = 0;
    @ContentChild(TableHeaderRefDirective) tableHeaderRef!: TableHeaderRefDirective;
    @ContentChild(CellRefDirective) cellRef!: CellRefDirective;
    @ContentChild(RowCollapseRefDirective) rowCollapseRef!: RowCollapseRefDirective;
    @ContentChild(GroupHeaderRefDirective) groupRef!: GroupHeaderRefDirective;

    @ViewChild('elementRefTemp') elementRef!: ElementRef<HTMLElement>;
    @ViewChild('empty') empty!: ElementRef<HTMLElement>;
    @ViewChild('freezeColRef') freezeColRef!: ElementRef<HTMLElement>;
    @ViewChild('tableHeaderElRef') tableHeaderElRef!: ElementRef<HTMLTableElement>;
    @ViewChild('mainTbody') mainTbody!: ElementRef<HTMLTableSectionElement>;

    @ViewChildren(GalvinTableHeaderDirective)
        tableHeadersRef!: QueryList<GalvinTableHeaderDirective>;

    @Input() cellSize: TableCellSize = { width: 100, height: 20 };
    @Input() horizontalBuffer!: number;
    @Input() verticalBuffer!: number;
    @Input() loadingTable = false;
    @Input() cdkScrollDisabled = true;
    @Input() groupList = true;
    @Input() compareDrop = '';
    @Input() collapse = false;
    @Input() groupsReduceFn!: TableGroupsReduceFunction;
    @Input() canSelectRowFn!: CanSelectRowsFunction<any>;
    @Input() cellSizeFn!: CellSizeFunction<any>;
    @Input() highlightFn!: HighlightFunction<any>;
    @Input() isMultiDrag = false;
    @Input() scrollInCollpaseView = false;


    cdkDrop = new EventEmitter();

    // eslint-disable-next-line @angular-eslint/no-output-native
    @Output() get drop(): EventEmitter<any> {
        return this.cdkDrop;
    }

    public columnsToDisplay!: { description: string; show: boolean }[];
    public columnsSize: Record<number, number> = {};
    public cellSizeList: Record<number, TableCellSize> = {};
    public normalGroupLeftPosition = 0;
    public xRange: TableRange = { first: 0, last: 0 };
    public yRange: TableRange = { first: 0, last: 0 };
    public content: TableCellSize = { height: 0, width: 0 };
    public currentScrollLeft = 0;
    public currentScrollTop = 0;
    public previousIndex!: any;
    public size = 0;
    public emptyHeaderOffsetWidth = 0;

    readonly multiSelect: MultiSelect;
    readonly highlightedRows: SelectionModel<number>;
    readonly multiDrag: MultiDrag;
    readonly freezeColumnList: Set<ITableEditInlineFreezeColumns> = new Set();
    readonly freezeColumnIndex: Set<number> = new Set();
    public spaceBottom!: number;
    xRangeHeader: TableRange = { first: 0, last: 0 };
    subjectHorizontal = new Subject<number>();
    subjectVertical = new Subject<number>();
    /* endregion Getters/Setters */
    public spaceRight!: number;
    private viewPortCapacity: { horizontal: number; vertical: number } = { horizontal: 0, vertical: 0 };
    private buffer: { horizontal: number; vertical: number } = { horizontal: 0, vertical: 0 };
    private s8n = new Subscription();
    private dataSubject = new Subject();
    private _collapse: SelectionModel<unknown>;
    private isUpdatingView = false;
    private dataUpdated = false;
    private isEmpty = true;

    constructor(
        public cdr: ChangeDetectorRef,
        private tableService: TableEditInlineService,
        private ngZone: NgZone
    ) {
        this._collapse = new SelectionModel();
        this.highlightedRows = new SelectionModel<number>(true);
        this.multiSelect = new MultiSelect();
        this.multiDrag = new MultiDrag(this.multiSelect);
    }

    // tslint:disable-next-line:variable-name
    private _rows: Array<[number, any[]]> = [];

    /* region Getters/Setters */
    get rows(): [number, any][] {
        return this._rows || [];
    }

    get tableHeight(): number {
        return this.data.length;
    }

    get tableWidth(): number {
        return this.headers.length;
    }

    get columns(): number[] {
        return Array.from(this._headers.keys());
    }

    get tablePositionTop(): number {
        const firstIndex = this.rows[this.yRange.first] ? this.rows[this.yRange.first][0] : 0;
        return this.cellSize.height * firstIndex;
    }

    get tablePositionLeft(): number {
        if (this.cellSizeFn != null) {
            return Object.values(this.cellSizeList)
                .slice(0, this.xRange.first)
                .reduce((cv, pv) => cv + pv.width, 0);
        }
        return this.cellSize.width * (this.columns[this.xRange.first] || 0);
    }

    get canShowSelectionToggle(): boolean {
        return this.selection && this.columns.length > 0 && !this.disabledSelection;
    }

    get hasSelection(): boolean {
        return this.selection && this.selection.hasValue();
    }

    get hasColumnFreeze(): boolean {
        return this.freezeColumnIndex.size > 0;
    }

    // tslint:disable-next-line:variable-name
    private _headers: Array<H> = [];
    private heardesUpdated = false;

    get headers(): Array<H> {
        return this._headers;
    }

    @Input()
    set headers(data: Array<H>) {
        this.heardesUpdated = true;
        this._headers = data || [];
        this.measureViewPortRenderCapacity();
        this.reduceGroups(this.xRange);
        this.cdr.detectChanges();
        this.calcFreezeColumn();
    }

    // tslint:disable-next-line:variable-name
    private _data: any[] = [];

    // tslint:disable-next-line:no-any
    get data(): any[] {
        return this._data;
    }

    @Input()
    set data(data: any[]) {
        if (data) {
            this._data = data;
            this.dataUpdated = true;
            this._rows = Array.from(this._data.entries());
            this.dataSubject.next(data);
            this.isEmpty = data.length === 0;
        }
    }

    _viewPortSize: TableCellSize = {
        width: 0,
        height: 0
    };

    /**
     * clientWidth and clientHeight is the inner size of element
     * including padding but excluding borders and scrollbars.
     */
    get viewPortSize(): TableCellSize {
        return this._viewPortSize;
    }

    // tslint:disable-next-line:variable-name
    public _emptyObject: unknown;

    @Input()
    set emptyObject(obj: unknown) {
        this._emptyObject = obj;
    }

    private _hasGroupsReduceFn!: boolean;

    get hasGroupsReduceFn(): boolean {
        return this._hasGroupsReduceFn;
    }

    // tslint:disable-next-line:variable-name
    private _selection!: SelectionModel<any>;

    get selection(): SelectionModel<any> {
        return this._selection;
    }

    @Input()
    set selection(selectionModel: SelectionModel<any>) {
        this._selection = selectionModel;
    }

    private _disabledSelection = false;

    get disabledSelection(): boolean {
        return this._disabledSelection;
    }

    @Input()
    set disabledSelection(disabledSelection: boolean) {
        this._disabledSelection = disabledSelection;
    }

    private _disabledDragAndDrop!: boolean;

    get disabledDragAndDrop(): boolean {
        return this._disabledDragAndDrop;
    }

    @Input()
    set disabledDragAndDrop(disabledDragAndDrop: boolean) {
        this._disabledDragAndDrop = disabledDragAndDrop;
    }

    // tslint:disable-next-line:variable-name
    private _groups!: TableGroup[];

    get groups(): TableGroup[] {
        return this._groups ? this._groups : [];
    }

    @Input()
    set groups(data: TableGroup[]) {
        this._groups = data;
    }

    private _freezeColumns: Array<any> = [];

    get freezeColumns(): Array<any> {
        return this._freezeColumns;
    }

    @Input()
    set freezeColumns(value: Array<any>) {
        this._freezeColumns = value;
        this.ngZone.runOutsideAngular(() => {
            this.freezeColumnIndex.clear();
            this.freezeColumnList.clear();
            this.calcFreezeColumn();
            this.reduceGroups(this.xRange);
            this.updateColsPanX();
            this.cdr.markForCheck();
        });

        this.handleCalcScroll(this.elementRef?.nativeElement?.scrollLeft);
    }

    isNotCollapsed(indexRow: number): boolean {
        return !this._collapse.isSelected(indexRow);
    }

    toggleCollapseRow(indexRow: number): void {
        this._collapse.toggle(indexRow);
        setTimeout(() => {
            this.cdr.markForCheck();
        }, 0);
    }

    ngOnDestroy(): void {
        this.s8n.unsubscribe();
    }

    public getDisplayedColumns(): string[] {
        return this.headers.map((val) => (val && val.description ? val.description : ''));
    }

    getSpaceBottom(mainTbody: ElementRef<HTMLTableSectionElement>): number {
        if (mainTbody && this.tableHeaderElRef) {
            return Math.max(
                0,
                this.content.height -
                    this.currentScrollTop -
                    mainTbody.nativeElement.offsetHeight -
                    this.tableHeaderElRef.nativeElement.offsetHeight
            );
        }
        return 0;
    }

    getSpaceRight(mainTbody: ElementRef<HTMLTableSectionElement>): number {
        if (mainTbody && this.tableHeaderElRef) {
            return Math.max(
                0,
                this.content.width - this.currentScrollLeft - mainTbody.nativeElement.offsetWidth
            );
        }
        return 0;
    }

    public isAllSelected(): boolean {
        return (
            this.selection &&
            this.rows &&
            this.selection.hasValue() &&
            this.selection.selected.length ===
                this.rows.filter((value) => !this.canSelectRow(value[1])).length
        );
    }

    public masterToggle(): void {
        if (this.isAllSelected()) {
            this.selection.clear();
        } else {
            this.selection.select(...this.rows.filter((value) => !this.canSelectRow(value[1])));
        }
    }

    public scrollToBottom(): void {
        this.cdr.detectChanges();
        setTimeout(() => {
            const element = this.elementRef.nativeElement;
            element.scrollTo({ top: element.scrollHeight + this.cellSize.height });
        }, 500);
    }

    /**
     * Extract headers and groups, measure content size and initialize table data
     */
    public updateView(): void {
        // this order is important!
        this.isUpdatingView = true;
        this.measureContentSize();
        this.highlightRows();
        this.subjectVertical.next(this.currentScrollTop + 1);
        this.subjectHorizontal.next(this.currentScrollLeft + 1);
        this.isUpdatingView = false;
    }

    handleCalcScroll(scrollLeft: number) {
        if (this.cellSizeFn != null) {
            const posX = this.getRangeWithCustomWidth(scrollLeft);
            if (
                this.currentScrollLeft &&
                !this.heardesUpdated &&
                !this.isEmpty &&
                (posX.first === this.xRange.first || posX.last === this.xRange.last)
            ) {
                return;
            }
            this.xRangeHeader = posX;
        } else {
            const posX = this.getRange(
                scrollLeft,
                this.tableWidth,
                this.cellSize.width,
                this.viewPortCapacity?.horizontal,
                this.buffer.horizontal
            );
            if (!this.isUpdatingView) {
                if (
                    this.currentScrollLeft &&
                    !this.heardesUpdated &&
                    !this.isEmpty &&
                    (posX.first === this.xRange.first || posX.last === this.xRange.last)
                ) {
                    return;
                }
            }
            this.xRangeHeader = posX;
        }
        this.xRange = this.xRangeHeader;
        this.currentScrollLeft = scrollLeft;
        this.updateColsPanX();
        this.heardesUpdated = false;
        this.cdr.detectChanges();
    }

    /* region Angular Component lifecycle */
    ngOnInit(): void {
        this.onResize();
        this.cdr.detectChanges();

        this.s8n.add(
            this.dataSubject.pipe().subscribe(() => {
                this.measureViewPortRenderCapacity();
                this.updateView();
            })
        );
        this.s8n.add(this.tableService.scrollToBottom$.subscribe(() => this.scrollToBottom()));
        this._hasGroupsReduceFn = this.groupsReduceFn !== null && this.groupsReduceFn !== undefined;
        this.subjectHorizontal
            .pipe(debounceTime(100), distinctUntilChanged())
            .subscribe((scrollLeft: number) => {
                this.handleCalcScroll(scrollLeft);
                this.cdr.detectChanges();
            });

        this.subjectVertical.pipe(distinctUntilChanged()).subscribe((scrollTop: number) => {
            const posY = this.getRange(
                scrollTop,
                this.tableHeight,
                this.cellSize.height,
                this.viewPortCapacity.vertical,
                this.buffer.vertical
            );
            if (!this.isUpdatingView) {
                if (
                    this.currentScrollTop &&
                    !this.dataUpdated &&
                    !this.isEmpty &&
                    (posY.first === this.yRange.first || posY.last === this.yRange.last)
                ) {
                    return;
                }
            }

            this.yRange = posY;
            this.currentScrollTop = scrollTop;
            this.spaceBottom = this.getSpaceBottom(this.mainTbody);
            this.dataUpdated = false;

            this.cdr.detectChanges();
        });
    }

    public canShowGroups(): boolean {
        return this.groups.length > 0 || this.hasGroupsReduceFn;
    }

    /**
     * Measure first and last item based on the scroll position
     * e.g.: getRange(AXIS.X) return first and last position of columns
     * @param scrollPosition scroll Position
     * @param lastOfStack ''
     * @param itemDimension ''
     * @param capacity ''
     * @param bufferSize ''
     */
    getRange(
        scrollPosition: number,
        lastOfStack: number,
        itemDimension: number,
        capacity: number,
        bufferSize: number
    ): TableRange {
        capacity += bufferSize * 2;

        let first = Math.floor(scrollPosition / itemDimension) - bufferSize;
        if (first < 1) {
            first = 0;
        }

        let last = first + capacity;
        if (last > lastOfStack) {
            first = lastOfStack - capacity;
            if (first < 0) {
                first = 0;
            }
            last = lastOfStack;
        }
        return { first, last };
    }

    getRowObject(row: R): R {
        return row;
    }

    public measureContentSize(): void {
        let contentWidth = 0;
        if (this.cellSizeFn) {
            this.headers.forEach((col, index) => {
                this.cellSizeList[index] = this.cellSizeFn(col);
                contentWidth += this.cellSizeList[index].width;
            });
        } else {
            contentWidth = this.tableWidth * this.cellSize.width;
        }

        this.content = {
            height: this.tableHeight * this.cellSize.height,
            width: contentWidth + this._headers.length - this.size / 2
        };
    }

    ngAfterContentChecked(): void {
        this.updateViewPortSize();
    }

    @HostListener('window:resize')
    public onResize(): void {
        this.updateViewPortSize();
        this.measureViewPortRenderCapacity();
        this.updateView();
    }

    public trackByFunction(index: number, item: unknown): number | null {
        return item ? index : null;
    }

    /**
     * Measure view port size and set to viewPortCapacity property
     */
    public measureViewPortRenderCapacity(): void {
        const viewPort = this.viewPortSize;
        let cellWidth = this.cellSize.width;

        if (this.cellSizeFn != null) {
            this.measureContentSize();
            cellWidth = this.content.width / this.headers.length;
        }

        const horizontal = Math.ceil(viewPort.width / cellWidth);
        const vertical = Math.ceil(viewPort.height / this.cellSize.height);
        this.viewPortCapacity = { horizontal, vertical };
        this.buffer = {
            horizontal: this.horizontalBuffer,
            vertical: this.verticalBuffer
        };
    }

    getCellWidth(colIndex: number): number {
        return this.getCellSize(colIndex).width;
    }

    getCellSize(colIndex: number): TableCellSize {
        if (this.cellSizeList[colIndex]) {
            return this.cellSizeList[colIndex];
        }
        return this.cellSize;
    }

    updateColumnsWidth(): void {
        if (this.tableHeadersRef) {
            this.tableHeadersRef.forEach((item, index) => {
                this.columnsSize[index] = (item as any).ref.getBoundingClientRect().width;
            });
            this.cdr.detectChanges();
        }
    }

    canSelectRow(row: R): boolean {
        return this.canSelectRowFn && !this.canSelectRowFn(row);
    }


    isSelectedDrag(index: number): boolean {
        return this.multiSelect.selectedDrag.isSelected(index);
    }

    onDragStarted(e: CdkDragStart, index: number): void {
        this.previousIndex = index;
        if (this.isMultiDrag) {
            this.multiSelect.dragStarted(index);
            this.multiDrag.dragStarted(e, index);
        }
    }

    onPointerDown(e: Event, index: number): void {
        if (this.isMultiDrag) {
            this.multiSelect.mouseDown(e, index);
        }
    }

    onDragEnded(): void {
        if (this.isMultiDrag) {
            this.multiSelect.dragEnded();
        }
    }

    onDrop(e: CdkDragDrop<string[]>): void {
        if (this.isMultiDrag && this.multiSelect.selectedDrag.selected.length > 0) {
            this.multiDrop(e);
        } else {
            this.singleDrop(e);
        }
    }

    isColumnSticky(column: number): boolean {
        return this.freezeColumnIndex.has(column);
    }

    getFreezeIndexValue(index: number): number {
        for (let e of this.freezeColumnIndex.entries()) {
            if ( e[1] === index) {
                return e[1];
            }
        }
        return -1;
    }

    public onScroll(event: { target: { scrollLeft: number; scrollTop: number } }): void {
        const { scrollLeft, scrollTop } = event.target;
        if (this.data.length > 0) {
            this.subjectHorizontal.next(scrollLeft);
            this.subjectVertical.next(scrollTop);
        }
    }

    getTableContentSize(): number {
        return this.content?.height + this.tableHeaderElRef?.nativeElement?.offsetHeight;
    }

    getColSpanGroup(): number {
        return this.selection && !this.cdkScrollDisabled ? 2 : 1;
    }

    getCdkScrollCellWidth(): number {
        return !this.cdkScrollDisabled ? 24 : 0;
    }

    sizeGroup() {
        let size = 0;
        this.freezeColumnList.forEach((value) => {
            size = size + this.getCellWidth(value.index);
        });
        this.size = size;
    }

    private highlightRows() {
        this.highlightedRows.clear();
        if (!this.highlightFn) {
            return;
        }
        this.data.forEach((value, index) => {
            if (this.highlightFn(value)) {
                this.highlightedRows.select(index);
            }
        });
    }

    private singleDrop(event: CdkDragDrop<string[]>): void {
        const currentData = this.data.slice(this.yRange.first, this.yRange.last);
        const currentIndex = this.data.findIndex(
            (val) => val[this.compareDrop] === currentData[event.currentIndex][this.compareDrop]
        );

        let id = this._data[this.previousIndex][this.compareDrop];
        if (this._data[this.previousIndex][this.compareDrop].id != undefined) {
            id = this._data[this.previousIndex][this.compareDrop].id;
        }

        this.cdkDrop.emit({
            previous: this._data[this.previousIndex],
            current: this._data[currentIndex],
            id,
            data: this._data[this.previousIndex]
        });
        moveItemInArray(this.data, this.previousIndex, currentIndex);
        this._rows = [];
        this._rows = Array.from(this._data.entries());

        if (this.selection !== undefined) {
            this.selection.clear();
        }
    }

    private multiDrop(event: CdkDragDrop<string[]>) {
        // If current element has ".selected-drag"
        if (event.item.element.nativeElement.classList.contains('selected-drag')) {
            this.multiDrag.dropListDropped(event);
        } else {
            if (event.previousContainer === event.container) {
                moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
            } else {
                transferArrayItem(
                    event.previousContainer.data,
                    event.container.data,
                    event.previousIndex,
                    event.currentIndex
                );
            }
        }

        const currentData = this.data.slice(this.yRange.first, this.yRange.last);

        const changeItems = this.data.filter((item, index) =>
            this.multiSelect.selectedDrag.selected.includes(index)
        );

        const shouldEmit = !changeItems
            .map((item) => item[this.compareDrop])
            .includes(currentData[event.currentIndex][this.compareDrop]);

        const direction =
            event.currentIndex > event.previousIndex ? EDirection.DOWN : EDirection.UP;

        if (shouldEmit) {
            this.cdkDrop.emit({
                changeItems,
                droppedItem: currentData[event.currentIndex],
                direction
            });
            this.multiSelect.selectedDrag.clear();
        }

        this._rows = [];
        this._rows = Array.from(this._data.entries());

        if (this.selection !== undefined) {
            this.selection.clear();
        }
    }

    private getRangeWithCustomWidth(scrollLeft: number) {
        let widthSize = 0;
        let first = 0;
        for (const col in this.cellSizeList) {
            if (this.freezeColumnIndex.has(Number(col))) {
                continue;
            }
            widthSize = widthSize + this.cellSizeList[col].width;
            if (widthSize > scrollLeft) {
                first = Number(col);
                break;
            }
        }

        first = Math.max(first - this.horizontalBuffer, 0);
        const last = this.getLastOfRangeWithCustomWidth(first);

        return { first, last };
    }

    private getLastOfRangeWithCustomWidth(first: number) {
        let last = first + this.horizontalBuffer;
        let maxWidth = 0;

        do {
            if (!(last in this.cellSizeList)) {
                break;
            }
            maxWidth += this.cellSizeList[last].width;
            last++;
        } while (maxWidth <= this.viewPortSize.width - last / 2);

        return Math.min(last + this.horizontalBuffer + 1, this.tableWidth);
    }

    private updateViewPortSize() {
        this._viewPortSize.width = this.elementRef?.nativeElement.getBoundingClientRect().width;
        this._viewPortSize.height = this.elementRef?.nativeElement.getBoundingClientRect().height;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    private reduceGroups(xRange: TableRange): void {
        const range = { first: 0, last: this.headers.length - 1 };
        if (this.hasGroupsReduceFn) {
            this._groups = this.groupsReduceFn(range, this.headers, this.freezeColumns || []);
        }
    }

    private calcFreezeColumn() {
        let leftPosition = 0;

        if (!this.cdkScrollDisabled) {
            leftPosition += 24;
        }

        if (this.canShowSelectionToggle) {
            leftPosition += 42;
        }

        if (Array.isArray(this._freezeColumns)) {
            const freezeColumnList: any[] = [];
            this._freezeColumns.forEach((col) => {
                const index = this.headers.findIndex((h) => h.id === col.id && h.type === col.type);
                if (index > -1) {
                    const data = this.headers[index];
                    this.freezeColumnIndex.add(index);
                    freezeColumnList.push({ index, leftPosition, data });
                }
            });
            // sort freeze columns
            freezeColumnList
                .sort((a, b) => a.index - b.index)
                .forEach((item) => {
                    item.leftPosition = leftPosition;
                    const realIndex = this.getFreezeIndexValue(item.index);
                    leftPosition += this.getCellSize(realIndex).width;
                    const freezeColumnListAsArray = [...this.freezeColumnList];
                    const freezeColumnExists = freezeColumnListAsArray.some(value => value.index === item.index);
                    if(!freezeColumnExists){
                        this.freezeColumnList.add(item);
                    }
                });
            this.sizeGroup();
            this.cdr.markForCheck();
        }
        this.normalGroupLeftPosition = leftPosition;
    }

    private updateColsPanX() {
        if (this.xRangeHeader.first > 0) {
            let qtFreezed = 0;
            let firstItem = (this.xRangeHeader.first + 1);
            for (let index = 0; index < this.xRangeHeader.first; index++) {
                if (this.freezeColumnIndex.has(index)) {
                    qtFreezed++;
                }
            }
            this.colSpanX = firstItem - qtFreezed;
        } else {
            this.colSpanX = 0;
        }
    }
    public isShowScrollInCollpaseView(){
        return this.scrollInCollpaseView && this.rows.length>0;
    }
}
