// Actions
// Models
import {AuthActions, AuthActionTypes, User} from '@galvin/core/auth';

export interface AuthState {
    loggedIn: boolean;
    token?: string;
    user?: User;
    isUserLoaded: boolean;
}

export const initialAuthState: AuthState = {
    loggedIn: false,
    token: undefined,
    user: undefined,
    isUserLoaded: false
};

export function authReducer(state = initialAuthState, action: AuthActions): AuthState {
    switch (action.type) {
    case AuthActionTypes.Logout:
        return initialAuthState;

    case AuthActionTypes.UserLoaded: {
        const _user: User = <User>action.payload.user;
        return {
            ...state,
            user: _user,
            isUserLoaded: true,
            loggedIn: true,
            token: <string>_user.token
        };
    }

    default:
        return state;
    }
}
