// Angular
import {Pipe, PipeTransform} from '@angular/core';

/**
 * Return a natural number
 */
@Pipe({
    name: 'abs'
})
export class AbsPipe implements PipeTransform {
    /**
     * Transform
     *
     * @param value: number
     */
    transform(value: number): number {
        return Math.abs(value);
    }
}
