<div id="trigger" class="generic-value total"
    [ngbPopover]="popComments" #popover="ngbPopover" [container]="container"
    [placement]="placement" [popoverClass]="'popover-mention-comment'"
    (click)="showSPRPopover()" (hidden)="cancelChangesWhenClosePopover()"
    autoClose="outside" triggers="manual"> <ng-content></ng-content> </div>

<ng-template #popComments>
    <div class="popover-container">
        <div class="d-flex align-items-center" *ngIf="nameCreateBy">
            <mat-icon class="d-flex align-items-center user-icon">person</mat-icon>
            <span class="lead text-wrap font-weight-bold">
                {{nameCreateBy}}
            </span>
        </div>
        <div class="d-flex mt-1 text-wrap" *ngIf="createdDate">
            <i>{{createdDate | date: formatDate }}</i>
        </div>
        <div class="text-center mb-4 mt-2">
            <textarea #textarea data-test="comment-mention-textarea" [mention]="allUsersEmail"
            [disabled]="!allUsersEmail.length || !editable"
            [(ngModel)]="!allUsersEmail.length && editable ? '' : inputValue "
            [mentionConfig]="{labelKey:'email'}"
            (keydown)="onDeleteInput($event)"
            (itemSelected)="onSelectItem($event)" class="form-control" rows="5" maxlength="1000"
            [placeholder]="!allUsersEmail.length ? ('COMMON.MENTIONS.LOADING' | translate) : ('COMMON.MENTIONS.PLACEHODER' | translate) " >
            </textarea>
        </div>

        <ng-container class="text-center mt-2" *ngIf="editable && allUsersEmail.length">
            <div class="mentions-list">
                <div *ngFor="let item of selectedUsers; let i=index">
                    <mat-checkbox  [(ngModel)]='item.selected' data-test="popover-checkbox" >
                        {{'COMMON.MENTIONS.NOTIFY' | translate}} {{item.label}}
                    </mat-checkbox>
                </div>
            </div>
        </ng-container>

        <div class="text-center mt-4">
            <button id="cancelButton" type="button" mat-raised-button class="mr-3" (click)="cancelChanges()">
                {{(editable? 'COMMON.MENTIONS.CANCEL' : 'COMMON.MENTIONS.CLOSE') | translate}}
            </button>
            <button id="applyButton" *ngIf="editable" type="button" mat-raised-button color="primary" (click)="applyComments()">{{'COMMON.MENTIONS.APPLY' | translate}}</button>
        </div>
    </div>

</ng-template>