import { Injectable } from '@angular/core';
import { LocalStorageUser } from '../auth/_models/local-storage-user.model';
import {
    IAccessoryValueRequestTableStorage
} from '../build-plan/accessory-request-management/interface/accessory-request-table-storage.interface';
import {
    IProtoRequestTableStorage
} from '../build-plan/prototype-request-management/interfaces/proto-request-table-storage.interface';
import {
    IRequestTableStorage
} from '../build-plan/prototype-request-management/interfaces/request-table-storage.interface';
import { LAST_NOTIFICATION, NOTIFICATION_STORAGE } from './notification-storage-helper.service';

export const LOGGED_USER = 'loggedUser';
export const TOKEN_EXP = 'refreshToken_exp';
export const RUNNER_TEST = 'runnerTest';
export const TOKEN_BY_TEST = 'token_by_test';

/**
 * Helper for handling values on local storage
 *
 * @export
 * @class LocalStorageHelper
 */
@Injectable({
    providedIn: 'root'
})
export class LocalStorageHelper {
    static readonly CONTENT_COPY_KEY = 'content_copy';

    /**
     * Put a new string value on local storage given a key identifier
     *
     * @param {string} key
     * @param {string} value
     * @memberof LocalStorageHelper
     */
    public setItem(key: string, value: string) {
        localStorage.setItem(key, value);
    }

    /**
     * Get a string value from local storage given a string key
     *
     * @param {string} key
     * @returns {string}
     * @memberof LocalStorageHelper
     */
    public getItem(key: string): string {
        return localStorage.getItem(key) as string;
    }

    /**
     * Put the logged user into the local storage
     *
     * @param {LocalStorageUser} loggedUser
     * @memberof LocalStorageHelper
     */
    public setLoggedUser(loggedUser: LocalStorageUser) {
        if (loggedUser) {
            this.setItem(LOGGED_USER, JSON.stringify(loggedUser));
        }
    }

    /**
     * Put qt executed refresh token into local storage
     *
     * @param {number} qty
     * @memberof LocalStorageHelper
     */
    public setQtyRefreshToken(qty: number) {
        if (!isNaN(parseInt(`${qty}`, 10))) {
            this.setItem(TOKEN_EXP, qty.toString());
        }
    }

    /**
     * Get qt executed refresh token from local storage
     *
     * @returns {number}
     * @memberof LocalStorageHelper
     */
    public getQtyRefreshToken(): number {
        if (isNaN(parseInt(`${this.getItem(TOKEN_EXP)}`, 10)) ) {
            return 0;
        }
        return +this.getItem(TOKEN_EXP).toString();
    }

    /**
 * Get token by test from local storage
 *
 * @returns {string}
 * @memberof LocalStorageHelper
 */
    public setTokenByTest(exp: string) {
        if (exp) {
            this.setItem(TOKEN_BY_TEST, exp);
        }
    }

    /**
     * Get token by test  from local storage
     *
     * @returns {string}
     * @memberof LocalStorageHelper
     */
    public getTokenByTest(): string {
        return this.getItem(TOKEN_BY_TEST);
    }

    public getRunnerTest(): string {
        return this.getItem(RUNNER_TEST);
    }


    /**
     * Remove the logged user into the local storage
     *
     * @memberof LocalStorageHelper
     */
    public removeLoggedUser() {
        this.removeItem(TOKEN_EXP);
        this.removeItem(LOGGED_USER);
        this.removeItem(NOTIFICATION_STORAGE);
        this.removeItem(LAST_NOTIFICATION);

    }

    /**
     * Get logged user from local storage
     *
     * @returns {LocalStorageUser}
     * @memberof LocalStorageHelper
     */
    public getLoggedUser(): LocalStorageUser {
        return JSON.parse(this.getItem(LOGGED_USER));
    }

    /**
     * Remove an item from the local storage
     *
     * @param {string} key
     * @memberof LocalStorageHelper
     */
    public removeItem(key: string) {
        localStorage.removeItem(key);
    }

    /**
     * @description remove all changes in storage by buildPlan
     *
     * @param idBuildPlan is used to find requests
     * @param key is used to handle storage
     */
    public undoAllRequestByBuildPlan<G>(key: string, idBuildPlan: number): void {
        const requests: IProtoRequestTableStorage<G>[] = JSON.parse(this.getItem(key));
        const index = this.getIndexIfExist(requests, idBuildPlan);
        if (index !== -1) {
            requests.splice(index, 1);
            this.setItem(key, JSON.stringify([...requests]));
        }
    }

    /**
     * @description Get PrototypeRequests by buildPlan
     *
     * @param idBuildPlan is used to find requests
     * @return IProtoRequestTableStorage
     */
    public getPrototypeRequestsChanges(
        idBuildPlan: number
    ): IProtoRequestTableStorage<IRequestTableStorage> | null {
        const requests: IProtoRequestTableStorage<IRequestTableStorage>[] = JSON.parse(
            this.getItem('requests')
        );
        return requests ? requests.find((v) => v.idBuildPlan === idBuildPlan) as IProtoRequestTableStorage<IRequestTableStorage> : null;
    }

    /**
     * @description Create a prototypeRequest or if exist prototype with the same buildPlanId, update.
     *
     * @param requests prototype relative to the request of each cell in the table
     * @param item storage key
     */
    public setPrototypeRequestsChanges(
        item: string,
        requests: IProtoRequestTableStorage<IRequestTableStorage>
    ): void {
        const requestStorage: IProtoRequestTableStorage<IRequestTableStorage>[] = JSON.parse(
            this.getItem(item)
        );
        if (requestStorage) {
            const index = this.getIndexIfExist(requestStorage, requests.idBuildPlan);
            if (index !== -1) {
                requestStorage[index] = { ...requests };
                this.setItem(item, JSON.stringify(requestStorage));
            } else {
                requestStorage.push(requests);
                this.setItem(item, JSON.stringify(requestStorage));
            }
        } else {
            this.setItem(item, JSON.stringify([requests]));
        }
    }

    /**
     * @description get index of protoRequestTable by buildPlanId.
     *
     * @param requests prototype relative to the request of each cell in the table
     * @param idBuildPlan buildPlan
     */
    public getIndexIfExist<G>(
        requests: IProtoRequestTableStorage<G>[],
        idBuildPlan: number
    ): number {
        return requests.findIndex((v) => v.idBuildPlan === idBuildPlan);
    }

    public getContentCopy(): any {
        const contentCopy = this.getItem(LocalStorageHelper.CONTENT_COPY_KEY);
        return contentCopy ? JSON.parse(contentCopy) : {};
    }

    public setContentCopy(contentCopy: any): void {
        this.setItem(LocalStorageHelper.CONTENT_COPY_KEY, JSON.stringify(contentCopy));
    }

    /**
     * @description Defines a new value on local storage with a filter identifier key by loggedUser
     *
     * @param {string} filterKey identifies the page filter value
     * @param {any} value the filter value to store
     * @returns {void}
     * @memberof LocalStorageHelper
     */
    public setPageCache(filterKey: string, value: any): void {
        let user_values : any[] = [{
            'filterKey' : filterKey,
            'value' : value
        }];
        const key_user_filter = this.getUserFilterName();
        const stored_cache = this.getUserCacheFilter();

        if (!this.isEmpty(stored_cache)) {

            if(Array.isArray(stored_cache)) {
                // * Check for existing filter value already stored
                let others = stored_cache.filter((f:any) => f.filterKey !== filterKey);
                if (others.length > 0) {
                    others.map((item) => user_values.push(item));
                }
            }
            else {
                user_values.push(stored_cache);
            }
        }
        if (user_values.find(fk => fk.value.length > 0)) {
            this.setItem(key_user_filter, JSON.stringify(user_values));
        }
    }

    /**
     * @description Retrieves from local storage the filter value by an identifier key
     *
     * @param {string} filterKey identifies the page filter value
     * @returns {any}
     * @memberof LocalStorageHelper
     */
    public getPageCache(filterKey: string): any {

        const stored_cache = this.getUserCacheFilter();
        if (!this.isEmpty(stored_cache)) {

            if (Array.isArray(stored_cache)) {
                // * Check for existing filter value already stored
                let found = stored_cache.filter((f:any) => f.filterKey === filterKey).map((f:any) => f.value);
                //* Important to leave returning the first array item
                if (found.length > 0) {
                    return found[0];
                }
            }
            else if (stored_cache.filterKey === filterKey) {
                return stored_cache.value;
            }
        }
        return {};
    }

    /**
     * @description Clears from local storage a filter value by an identifier key
     *
     * @param {string} filterKey identifies the page filter value
     * @returns {void}
     * @memberof LocalStorageHelper
     */
    public clearItemPageCache(filterKey: string): void {
        const stored_cache = this.getUserCacheFilter();
        if (!this.isEmpty(stored_cache)) {
            stored_cache.map((item:any) => {
                if (item.filterKey === filterKey) {
                    item.value = {};
                }
            });
            const key_user_filter = this.getUserFilterName();
            this.setItem(key_user_filter, JSON.stringify(stored_cache));
        }
    }

    /**
     * @description Removes from local storage the filter value by an identifier key
     *
     * @param {string} filterKey identifies the page filter value
     * @returns {void}
     * @memberof LocalStorageHelper
     */
    public removeItemPageCache(filterKey: string): void {
        let stored_cache = this.getUserCacheFilter();
        if (!this.isEmpty(stored_cache)) {
            stored_cache = stored_cache.filter((sc:any) => sc.filterKey !== filterKey);
            const key_user_filter = this.getUserFilterName();
            // * Removes filter when theres no more filterKeys
            if (this.isEmpty(stored_cache) || this.isEmptyArray(stored_cache)) {
                this.removeItem(key_user_filter);
            }
            else {
                this.setItem(key_user_filter, JSON.stringify(stored_cache));
            }
        }
    }

    public hasFilterKeyPageCache(filterKey: string): boolean {
        const stored_cache = this.getUserCacheFilter();
        if (!this.isEmpty(stored_cache)) {
            return stored_cache.some((sc:any) => sc.filterKey === filterKey);
        }
        return false;
    }

    /**
     * @description Get AccessoryRequests by buildPlan
     *
     * @param idBuildPlan is used to find requests
     * @return IAccessoryRequestTableStorage
     */
    public getAccessoryRequestsChanges(
        idBuildPlan: number
    ): IProtoRequestTableStorage<IAccessoryValueRequestTableStorage> | null {
        const requests: IProtoRequestTableStorage<IAccessoryValueRequestTableStorage>[] =
            JSON.parse(this.getItem('accessory-requests'));
        return requests ? requests.find((v) => v.idBuildPlan === idBuildPlan) as IProtoRequestTableStorage<IAccessoryValueRequestTableStorage> : null;
    }

    /**
     * @description Create a prototypeRequest or if exist prototype with the same buildPlanId, update.
     *
     * @param requests prototype relative to the request of each cell in the table
     * @param item storage key
     */
    public setAccessoryRequestsChanges(
        item: string,
        requests: IProtoRequestTableStorage<IAccessoryValueRequestTableStorage>
    ): void {
        const requestStorage: IProtoRequestTableStorage<IAccessoryValueRequestTableStorage>[] =
            JSON.parse(this.getItem(item));
        if (requestStorage) {
            const index = this.getIndexIfExist<IAccessoryValueRequestTableStorage>(
                requestStorage,
                requests.idBuildPlan
            );
            if (index !== -1) {
                requestStorage[index] = { ...requests };
                this.setItem(item, JSON.stringify(requestStorage));
            } else {
                requestStorage.push(requests);
                this.setItem(item, JSON.stringify(requestStorage));
            }
        } else {
            this.setItem(item, JSON.stringify([requests]));
        }
    }

    private getUserFilterName(): string {
        const user = this.getLoggedUser();
        return 'filters_' + ( (user) ? user.name?.toLowerCase() : '');
    }

    /**
     * @description Retrieves the localStorage values for the current loggedUser.
     *
     * @returns {any} Json object used for cache form filters
     */
    private getUserCacheFilter() : any{
        const key_user_filter = this.getUserFilterName();
        if (key_user_filter.length > 0) {
            const stored_filter = this.getItem(key_user_filter);
            return stored_filter ? JSON.parse(stored_filter) : {};
        }
        return {};
    }

    private isEmpty = (obj: Object) => Object.getOwnPropertyNames(obj).length === 0 &&
        obj.constructor === Object;

    private isEmptyArray = (obj: Object) => Array.isArray(obj) ? obj.length == 0 : false;
}