import { IRequestPreviewI18n } from '@galvin/views/pages/build-plan/build-plan-management/request-preview/i18n/request-preview-i18n.interface';

export const REQUEST_PREVIEW: IRequestPreviewI18n = {
    MASS_EDIT_LABEL: 'MASS EDIT {{selectionSize}} REQUEST selected',
    DETAILS: 'Show configuration details',
    DETAILS_ACCESSORY: 'Show accessory details',
    FILTER_BY_ONLY_REQUESTS: 'Show only Requests',
    RESET_FILTERS: 'Reset filters',
    TOTAL_REQUEST_FROM_TEAM: 'requests',
    TOTAL_REQUEST_FULL_VIEW: 'Total requests',
    TOTAL_PORTABLE_REQUEST_FULL_VIEW: 'Total Portable',
    TOTAL_BOARDS_REQUEST_FULL_VIEW: 'Total Boards',
    SHOW_TOTAL_BOARDS:'Show Total Boards',
    HIDE_TOTAL_BOARDS:'Hide Total Boards',
    SHOW_DETAILS_PORTABLE: 'Show Detailed Total Portable',
    HIDE_DETAILS_PORTABLE: 'Hide Detailed Total Portble',
    SHOW_DETAILS_BOARDS: 'Show Detailed Total Boards',
    HIDE_DETAILS_BOARDS: 'Hide Detailed Total Boards',
    CLONE: 'Clone Configuration',
    EDIT: 'Edit Configuration',
    SPECIAL_REQUIREMENTS_TITLE: 'Special Requirements',
    COLLAPSE_HEADER_INFO: 'Collapse Header Information',
    UNCOLLAPSE_HEADER_INFO: 'Uncollapse Header Information',
    CANCELED_CONFIGURATION: 'This configuration has been cancelled',
    NO_DATA: 'No data found for the selected build plan.',
    NO_DATA_FILTER: 'No data found for the selected combination of filters.',
    COPY: 'Copy Requests',
    COPY_SHORTCUT: 'Ctrl + C',
    CUT: 'Cut Requests',
    CUT_SHORTCUT: 'Ctrl + X',
    PASTE: 'Paste Requests',
    PASTE_SHORTCUT: 'Ctrl + V',
    ERASE: 'Erase Requests',
    ERASE_SHORTCUT: 'Delete',
    COPY_SUCCESS: 'Requests Copied to Clipboard',
    PASTE_OPERATION_EXCEED_LIMIT: 'Paste operation not allowed: exceed table limits',
    SELECTOR_TEAMS_PARAMETERS: 'Filter by Teams Parameters',
    FILTER: 'Filter by any field',
    QUICK_ACTION: 'Quick Actions',
};
