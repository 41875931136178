// Angular
import { Component, Input, OnInit } from '@angular/core';

// Generic
import { IDialogData } from './../../../../../core/_base/general/index';
import { ConfigGenericModal } from './config-generic-modal';

@Component({
    selector: 'galvin-dialog-generic',
    templateUrl: './galvin-dialog-generic.component.html',
    styleUrls: ['./galvin-dialog-generic.component.scss']
})
export class GalvinDialogGenericComponent extends ConfigGenericModal implements OnInit {
    @Input() data!: IDialogData;
    @Input() save!: any;
    @Input() componentName!: any;

    ngOnInit(): void {
        this.configGenericModal(this.data);
    }
}
