import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'getColumnObject'
})
export class GetColumnObjectPipe implements PipeTransform {
    transform(column: number, row: any, headers: any, _emptyObject: any) {
        const rowObj: any = row;
        const colObj = headers[column];
        const key = colObj.type + '_' + colObj.id;

        if (!rowObj[key]) {
            rowObj[key] = {...(_emptyObject as object)};
        }

        return rowObj[key];
    }
}