import { Component, Input } from '@angular/core';

@Component({
    selector: 'galvin-progress-spinner',
    templateUrl: './galvin-progress-spinner.component.html',
    styleUrls: ['./galvin-progress-spinner.component.scss']
})
export class GalvinProgressSpinnerComponent {
    @Input() diameter = 50;

    get imgSize(): number {
        return this.diameter - 15;
    }
}
