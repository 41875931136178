import { ILocationOnlyCountry } from '@galvin/core/build-plan/field-value-management/location/interfaces/location-only-country.interface';
import { IUserWithoutRole } from '@galvin/core/build-plan/permission-management/interfaces/user-without-role.interface';
import { IProtoRequest } from '@galvin/views/partials/layout/proto-request-table/interfaces/proto-request.interface';

export interface IProtoRequestTeam<R = IProtoRequest> {
    idTeam: number;
    idTeamProtoRequest?: number;
    idTeamAssociation?: number;
    teamAlias?: string;
    teamOrder?: number;
    teamValues?: string;
    requests?: R[];
    function?: string;
    region?: string;
    category?: string;
    team?: string;
    requestOwnerUser?: IUserWithoutRole;
    finalRecipientUser?: IUserWithoutRole;
    cdcLocation?: ILocationOnlyCountry;
    shipToLocation?: ILocationOnlyCountry;
}