import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class TeamSelectorService {
    private updateTeams = new Subject<number | undefined>();
    private updateTeamsMassive = new Subject<number[] | undefined>();

    selectedTeams$ = this.updateTeams.asObservable();
    selectedTeamsMassive$ = this.updateTeamsMassive.asObservable();

    updateSelection(teamId?: number ) {
        this.updateTeams.next(teamId);
    }

    updateSelectionMassive(teamIds?: number[]) {
        this.updateTeamsMassive.next(teamIds);
    }
}