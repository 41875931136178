export class SkuMatrixConstants {

    public static readonly NULL_VALUE = '--';

    // fvc values arrays
    public static readonly FVC_PARAMS_NAME_FOR_SKU_MATRIX = [
        'PCBA Kit #',
        'XCVR Kit #',
        'Market Region',
        'CMF Color',
        'Memory RAM Size',
        'SIM'
    ];

    public static readonly FVC_IDS = [
        'SYSTEM_MARKET_REGION',
        'SYSTEM_SIM',
        'SYSTEM_CMF_COLOR',
        'SYSTEM_RAM_SIZE'
    ];

    public static readonly KITS = [
        'SYSTEM_PCBA_KIT',
        'SYSTEM_XCVR_KIT'
    ];

    public static readonly FVC_VALUES = ['2', '7', '8', '13'];
}