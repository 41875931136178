import { IProtoRequestHeader } from '@galvin/views/partials/layout/proto-request-table/interfaces/proto-request-header.interface';
import { EProtoRequestTableCellState } from '@galvin/views/partials/layout/proto-request-table/interfaces/proto-request-table-cell-state.enum';
import { IProtoRequestTeam } from '@galvin/views/partials/layout/proto-request-table/interfaces/proto-request-team.interface';

export interface IProtoRequestCell<C = IProtoRequestHeader, R = IProtoRequestTeam, I = any> {
    item: Partial<I>;
    operation?: string;
    $column: C;
    $row: R;
    $state?: EProtoRequestTableCellState;
}
