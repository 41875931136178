import { environment } from 'environments/environment';

export class MenuConfig {
    public defaults: any = {
        cdc: {
            aside: {
                self: {},
                items: [
                    {
                        title: 'Home',
                        root: true,
                        icon: 'galvin-icon-menu-cdc-home',
                        page: '/cdc/home',
                        bullet: 'dot'
                    },
                    {
                        title: 'CDC Tracker',
                        root: true,
                        icon: 'galvin-icon-menu-sorting',
                        page: '/cdc',
                        bullet: 'dot',
                        submenu: [
                            {
                                title: 'Prototype Sorting',
                                page: '/cdc/prototype-sorting'
                            },
                            {
                                title: 'Accessory Tracker',
                                page: '/cdc/accessory-tracker'
                            }
                        ]
                    },
                    environment.featureFlag
                        ? {}
                        : {
                            title: 'Shipment',
                            root: true,
                            icon: 'galvin-icon-menu-shipment',
                            page: '/cdc/sorting',
                            bullet: 'dot',
                            submenu: [
                                {
                                    title: 'Create Shipment',
                                    page: '/cdc/create-shipment'
                                },
                                {
                                    title: 'List Shipment',
                                    page: '/cdc/list-shipment'
                                }
                            ]
                        }
                ]
            }
        },
        'build-plan': {
            aside: {
                self: {},
                items: [
                    {
                        title: 'Home',
                        root: true,
                        icon: 'galvin-icon-menu-home',
                        page: '/build-plan/home',
                        bullet: 'dot'
                    },
                    {
                        title: 'Prototype Request',
                        root: true,
                        icon: 'galvin-icon-menu-prototype-request',
                        page: '/build-plan/prototype-request',
                        bullet: 'dot',
                        submenu: [
                            {
                                title: 'Request',
                                page: '/build-plan/prototype-request/full-view'
                            },
                            {
                                title: 'Request History',
                                page: '/build-plan/prototype-request/request-history'
                            },
                            {
                                title: 'Requests Approval',
                                page: '/build-plan/prototype-request/requests-approval'
                            },
                            {
                                title: 'My Prototypes',
                                page: '/build-plan/prototype-request/my-prototypes'
                            }
                        ]
                    },
                    {
                        title: 'Accessory Request',
                        root: true,
                        icon: 'galvin-icon-menu-accessory-request',
                        page: '/build-plan/accessory-request',
                        bullet: 'dot',
                        submenu: [
                            {
                                title: 'Request',
                                page: '/build-plan/accessory-request/full-view'
                            },
                            {
                                title: 'Requests Approval',
                                page: '/build-plan/accessory-request/requests-approval'
                            },
                            {
                                title: 'My Accessories',
                                page: '/build-plan/accessory-request/my-accessories'
                            }
                        ]
                    },
                    {
                        title: 'Build Plan',
                        root: true,
                        icon: 'galvin-icon-menu-build-plan',
                        page: '/build-plan/management',
                        bullet: 'dot',
                        submenu: [
                            {
                                title: 'Management',
                                page: '/build-plan/management'
                            },
                            {
                                title: 'Import Approval',
                                page: '/build-plan/import-approval'
                            }
                        ]
                    },
                    {
                        title: 'Permissions',
                        root: true,
                        icon: 'galvin-icon-menu-permission',
                        page: '/build-plan/permissions',
                        bullet: 'dot'
                    },
                    {
                        title: 'Notifications',
                        root: true,
                        icon: 'galvin-icon-menu-notification',
                        page: '/build-plan/notifications',
                        bullet: 'dot'
                    },
                    {
                        title: 'Fields & Values',
                        root: true,
                        icon: 'galvin-icon-fields-values',
                        page: '/build-plan/field-value-management',
                        bullet: 'dot',
                        submenu: [
                            {
                                title: 'FVC Parameters',
                                page: '/build-plan/field-value-management/fvc-parameters'
                            },
                            {
                                title: 'Accessory Parameters',
                                page: '/build-plan/field-value-management/accessory-parameters'
                            },
                            {
                                title: 'Special Requirements',
                                page: '/build-plan/field-value-management/special-requirements'
                            },
                            {
                                title: 'Dates',
                                page: '/build-plan/field-value-management/dates'
                            },
                            {
                                title: 'Relative Dates',
                                page: '/build-plan/field-value-management/relative-dates'
                            },
                            {
                                title: 'Scope Parameters',
                                page: '/build-plan/field-value-management/scope-parameters'
                            },
                            {
                                title: 'Team Parameters',
                                page: '/build-plan/field-value-management/team-parameters'
                            },
                            {
                                title: 'Locations',
                                page: '/build-plan/field-value-management/locations'
                            }
                        ]
                    },
                    {
                        title: 'Groups',
                        root: true,
                        icon: 'galvin-icon-menu-groups',
                        page: '/build-plan/groups',
                        bullet: 'dot'
                    },
                    {
                        title: 'Scopes',
                        root: true,
                        icon: 'galvin-icon-menu-scopes',
                        page: '/build-plan/scopes',
                        bullet: 'dot'
                    },
                    {
                        title: 'Teams',
                        root: true,
                        icon: 'galvin-icon-menu-teams',
                        page: '/build-plan/teams',
                        bullet: 'dot'
                    },
                    {
                        title: 'User Management',
                        root: true,
                        icon: 'galvin-icon-user-management',
                        page: '/build-plan/user-management',
                        bullet: 'dot'
                    },
                    {
                        title: 'DSR',
                        root: true,
                        icon: 'galvin-home-work',
                        page: '/build-plan/dsr',
                        bullet: 'dot'
                    },
                    {
                        title: 'Reports',
                        root: true,
                        icon: 'galvin-icon-reports',
                        page: '/build-plan/reports',
                        bullet: 'dot'
                    },
                    {
                        title: 'FAQ',
                        root: true,
                        icon: 'galvin-icon-faq',
                        page: '/build-plan/faq',
                        bullet: 'dot'
                    }
                ]
            }
        },
        finance: {
            aside: {
                self: {},
                items: [
                    {
                        title: 'Home',
                        root: true,
                        icon: 'galvin-icon-menu-cdc-home',
                        page: '/finance/home',
                        bullet: 'dot'
                    },
                    environment.featureFlag
                        ? {}
                        : {
                            title: 'PO/Quotation',
                            root: true,
                            icon: 'galvin-icon-menu-po-quotation',
                            page: '/finance/po-quotation',
                            bullet: 'dot',
                        }
                ]
            }
        }
    };

    public get configs(): any {
        return this.defaults;
    }
}
