// RXJS
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
// Angular
import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// Galvin resource
import { environment } from '../../../../environments/environment';
import { GenericResource } from './generic-resource';

export const RESOURCE_PATH = new InjectionToken<string>('Resource Path', { factory: () => '/' });

@Injectable({
    providedIn: 'root'
})
export class ResourceService {
    protected baseUrl = environment.baseUrlApi;

    constructor(
        protected http: HttpClient,
        @Optional()
        @Inject(RESOURCE_PATH)
        private _resourcePath?: string
    ) {
        this._headers = this._headers.set('Content-Type', 'application/json');
        this._headers = this._headers.set('Accept', 'application/json');
    }

    protected _headers = new HttpHeaders();

    get headers(): HttpHeaders {
        return this._headers;
    }

    get uri(): string {
        if (this._resourcePath) {
            return `${this.baseUrl}${this._resourcePath}`;
        }
        return this.baseUrl;
    }

    get<T, R extends GenericResource<any>>(
        resource: R | string = '',
        options?: any
    ): Observable<any> {
        return this.mapResponseToGenericResource(
            this.http.get<T>(this.buildResourceURI(resource), this.mergeRequestOptions(options))
        );
    }

    post<T, R extends GenericResource<any>>(resource: R, options?: any): Observable<any> {
        return this.mapResponseToGenericResource(
            this.http.post<T>(
                this.buildResourceURI(resource),
                resource.content,
                this.mergeRequestOptions(options)
            )
        );
    }

    put<T, R extends GenericResource<any>>(resource: R, options?: any): Observable<any> {
        return this.mapResponseToGenericResource(
            this.http.put<T>(
                this.buildResourceURI(resource),
                resource.content,
                this.mergeRequestOptions(options)
            )
        );
    }

    patch<T, R extends GenericResource<any>>(resource: R, options?: any): Observable<any> {
        return this.mapResponseToGenericResource(
            this.http.patch<T>(
                this.buildResourceURI(resource),
                resource.content,
                this.mergeRequestOptions(options)
            )
        );
    }

    delete<T extends GenericResource<any>>(resource: T | string, options?: any): Observable<any> {
        return this.mapResponseToGenericResource(
            this.http.delete(this.buildResourceURI(resource), this.mergeRequestOptions(options))
        );
    }

    protected buildResourceURI<T extends GenericResource<any>>(resource: T | string) {
        let path = '';

        if (typeof resource == 'string') {
            path = resource;
        } else if ('self' in resource) {
            path = resource.self.href;
        }

        if (path === undefined) {
            path = '';
        }
        return `${this.uri}${path}`;
    }

    protected mapResponseToGenericResource<T>(
        observableRequest: Observable<any>
    ): Observable<GenericResource<T>> {
        return observableRequest.pipe(
            map((response) => Object.assign(new GenericResource<T>(), response))
        );
    }

    protected hasContentId<T extends Object>(_resource: T) {
        let content: any;

        // eslint-disable-next-line no-prototype-builtins
        if (_resource.hasOwnProperty('content')) {
            content = (<any>_resource).content;
        }

        // eslint-disable-next-line no-prototype-builtins
        if (!!content && content.hasOwnProperty('id')) {
            return !!content.id;
        }

        return false;
    }

    protected mergeRequestOptions(_options?: { headers: HttpHeaders }) {
        let options = { headers: this.headers };
        if (_options) {
            options = { ...options, ..._options };
        }
        return options;
    }
}
