// Angular
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

// Enviroments
import {environment} from '../../../../../environments/environment';
import {map} from 'rxjs/operators';
import {IBuildPlanDatesRelativeAssociation} from '../interfaces/build-plan-dates-relative-association.interface';
import {IRelativeDate} from '../../field-value-management';

@Injectable({
    providedIn: 'root'
})
export class BuildPlanDateRelativeAssociationService {
    private readonly URL_BUILD_PLAN =
        'build-plan/build-plan-management/{id-build-plan}/relative-date-association';
    private readonly API_BUILD_PLAN_URL = `${environment.baseUrlApi}/` + this.URL_BUILD_PLAN;

    constructor(private http: HttpClient) {
    }

    getBuildPlanDatesRelativeAssociationByBuildPlan(buildPlanId: number): Observable<IBuildPlanDatesRelativeAssociation[]> {
        return this.http.get(`${this.getBasePath(buildPlanId)}`).pipe(map((response: any) => response.content));
    }

    createFromDatesRelative(
        datesRelative: IRelativeDate,
        buildPlanId: number
    ): Observable<IBuildPlanDatesRelativeAssociation> {
        return this.http
            .post<IBuildPlanDatesRelativeAssociation>(
                `${this.getBasePath(buildPlanId)}`,
                datesRelative
            )
            .pipe(
                map((response: any) => {
                    return response.content;
                })
            );
    }

    /**
     * Update only the Days field
     */
    updateDays(association: {id: number, days: number, buildPlan: {id: number}}): Observable<any> {
        return this.http.put<any>(
            `${this.getBasePath(association.buildPlan.id)}/update-days`,
            association
        );
    }

    delete(association: {id: number, days: number, buildPlan: {id: number}}): Observable<any> {
        return this.http.delete<any>(
            `${this.getBasePath(association.buildPlan.id)}/${association.id}`
        );
    }

    private getBasePath(id: number): string {
        return this.API_BUILD_PLAN_URL.replace('{id-build-plan}', `${id}`);
    }
}
