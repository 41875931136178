import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { GenericFvcFieldComponent } from '@galvin/views/pages/build-plan/build-plan-management/configurations/generic-fvc-field/generic-fvc-field.component';
import { SuggestAliasComponent } from '@galvin/views/partials/layout/suggest-alias/suggest-alias.component';
import { IGenericFVcFieldComponentReference } from '../interface/generfic-fvc-field.interface';
import { ISuggestAliasInterface } from '../interface/suggest-alias.interface';
import { SuggestAliasListComponent } from '@galvin/views/partials/layout/suggest-alias-list/suggest-alias-list.component';
import { EConfigurationType, IConfigurationTableData, IConfigurationTableValue } from '@galvin/core/build-plan/configuration-management';
import { COLUMN_NAME } from '../enum/column-name.enum';

@Injectable()
export class SuggestAliasService {
    private dialogRef!: MatDialogRef<SuggestAliasComponent>;
    private dialogRefList!: MatDialogRef<SuggestAliasListComponent>;
    private genericFields: IGenericFVcFieldComponentReference[] = [];

    constructor(private dialog: MatDialog) {}

    private getGenericFieldsByRow(row: string) {
        return this.genericFields.filter((el) => {
            return el.row.toString() === row.toString();
        });
    }

    private readonly SYSTEM_HW_REVISION = 'SYSTEM_HW_REVISION';
    private readonly COLUMN_MARKET_REGION = 'market region';
    private readonly COLUMN_SIM = 'sim';
    private readonly COLUMN_HW_REVISION = 'hw revision';
    private readonly COLUMN_BUILD_RECIPE = 'build recipe';
    private readonly COLUMN_CMF_COLOR = 'cmf color';
    private readonly COLUMN_FVC = 'fvc';
    private readonly SYSTEM_MARKET_REGION = 'SYSTEM_MARKET_REGION';
    private readonly SYSTEM_SIM = 'SYSTEM_SIM';
    private readonly SYSTEM_BUILD_RECIPE = 'SYSTEM_BUILD_RECIPE';
    private readonly SYSTEM_CMF_COLOR = 'SYSTEM_CMF_COLOR';
    private readonly SYSTEM_CONFIGURATION_FVC = 'FVC';

    public readonly COLUMN_ALIAS_AUTOMATION = [
        this.COLUMN_HW_REVISION,
        this.COLUMN_MARKET_REGION,
        this.COLUMN_BUILD_RECIPE,
        this.COLUMN_SIM,
        this.COLUMN_CMF_COLOR,
        this.COLUMN_FVC
    ];

    public readonly SYSTEM_ALIAS_AUTOMATION = [
        this.SYSTEM_HW_REVISION,
        this.SYSTEM_MARKET_REGION,
        this.SYSTEM_BUILD_RECIPE,
        this.SYSTEM_SIM,
        this.SYSTEM_CMF_COLOR,
        this.SYSTEM_CONFIGURATION_FVC
    ];

    /**
     * Loads the dialog, using SuggestAliasComponent component as a template
     * and uploads the data to render it
     */
    openSuggestAlias(data: ISuggestAliasInterface): void {
        this.dialogRef = this.dialog.open(SuggestAliasComponent, {
            panelClass: 'custom-panel',
            width: '450px',
            data,
            disableClose: true,
            hasBackdrop: true
        });
    }

    /**
     * Loads the dialog, using SuggestAliasListComponent component as a template
     * and uploads the data to render it
     */
    openSuggestAliasList(data: ISuggestAliasInterface[]): void {
        this.dialogRefList = this.dialog.open(SuggestAliasListComponent, {
            panelClass: 'custom-panel-suggestion-list',
            width: '950px',
            data,
            disableClose: true,
            hasBackdrop: true
        });
    }

    public getSuggestions(list:IConfigurationTableData[]): ISuggestAliasInterface[] {
        const data: ISuggestAliasInterface[] = [];
        list.forEach((newValue:any) => {
            const clonedTeamAlias: string = newValue.CONFIGURATION_NAME.displayValue;
            // Generate suggest new Team Alias
            let dataToSuggest = this.toSuggestName(newValue);

            if (clonedTeamAlias.toLocaleLowerCase() !== dataToSuggest.toLocaleLowerCase()) {

                const newSuggestAlias: ISuggestAliasInterface = {
                    columnToUpdate: COLUMN_NAME.CONFIG_ALIAS,
                    currentDataFromAlias: clonedTeamAlias,
                    dataToSuggest: dataToSuggest,
                    rowID: newValue.CONFIGURATION_ID.id
                };
                data.push(newSuggestAlias);
            }
        });
        return data.filter(e=>e.dataToSuggest.length>0);
    }

    public toSuggestName(newValue: IConfigurationTableData) {
        let listLabels = this.SYSTEM_ALIAS_AUTOMATION.map(dataType => {
            return this.getValueFromFVC(newValue, dataType);
        });
        let dataToSuggest = listLabels.filter(e => e != '').join(' ');
        dataToSuggest = dataToSuggest.trim().replace(/\s?undefined\s?/g, '');
        return dataToSuggest;
    }

    public getValueFromFVC(row: IConfigurationTableData | IConfigurationTableValue, searchTerm: string): string {
        const fvcValues: any[] = Object.entries(row)
            .filter((key) => key[0].startsWith(EConfigurationType.FVC_VALUE))
            .map((value) => value[1]);
        for (const fvcValue of fvcValues) {
            if (fvcValue.dataType === searchTerm) {
                return fvcValue.displayValue === undefined ? '' : fvcValue.displayValue.replace('--', '');
            }
        }
        const configValues: any[] = Object.entries(row)
            .filter((key) => key[0].endsWith(EConfigurationType.CONFIGURATION_FACTORY+'_FVC'))
            .map((value) => value[1]);
        if(configValues[0]){
            return configValues[0].displayValue === undefined ? '' : configValues[0].displayValue.replace('--', '');
        }
        return '';
    }

    /**
     * Retuns the current rendered Dialog
     * @returns current dialog
     */
    getDialog(): MatDialogRef<SuggestAliasComponent> {
        return this.dialogRef;
    }

    /**
     * Retuns the current rendered List Dialog
     * @returns current dialog
     */
    getListDialog(): MatDialogRef<SuggestAliasListComponent> {
        return this.dialogRefList;
    }

    /**
     * Destroys all the current generic fields stored on this service
     */
    initialSetup(): void {
        this.genericFields = [];
    }

    /***
     * Set the generic field components from configuration Build Plan Management
     * @param genericFvcFieldComponent component
     * @param row identifier
     */
    setGenericComponentFields(
        genericFvcFieldComponent: GenericFvcFieldComponent,
        row: string
    ): void {
        const currentElements = this.getGenericFieldsByRow(row);
        if (currentElements.length === 0) {
            const generic: IGenericFVcFieldComponentReference = {
                genericField: genericFvcFieldComponent,
                row,
                additionalField: []
            };
            this.genericFields.push(generic);
        }
    }

    /**
     * Set the generic field components
     * @param row identifier
     * @param column column identifier
     * @param dataToConcatenate concatenated string
     */
    setReferenceForScopesOrTeams(row: string, column: number, dataToConcatenate: string): void {
        const currentElements = this.getGenericFieldsByRow(row);
        if (currentElements.length > 0) {
            currentElements[0].additionalField!.push({
                id: column,
                value: dataToConcatenate
            });
            currentElements[0].additionalField = [
                ...currentElements[0].additionalField!.filter(
                    (v, i, a) => a.findIndex((t) => t.id === v.id) === i
                )
            ];
        }
    }

    /**
     * Updates the config alias from configuration component
     * @param configurationId identifier
     * @param response data to update the generic field
     */
    updateConfigurationAliasReference(configurationId: number, response: any): void {
        const currentElements = this.genericFields.filter((gf) => {
            return gf.genericField.data.row.CONFIGURATION_ID.id === configurationId;
        });

        if (currentElements.length > 0) {
            if (currentElements[0].genericField) {
                currentElements[0].genericField.data.row['CONFIGURATION_NAME'] = response['CONFIGURATION_NAME'];
                currentElements[0].genericField.updateReference(response);
            }
        }
    }

    /**
     * Returns the current generic fields stored on this service
     * @param identifier
     */
    getReferences(row: string | null = null): IGenericFVcFieldComponentReference[] {
        if (row) {
            return this.getGenericFieldsByRow(row);
        }
        return this.genericFields;
    }
}
