// Angular
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

// Services
import { GalvinDialogGenericService } from '@galvin/core/_base/general';

@Injectable()
export abstract class GenericConfig<T> implements OnDestroy {
    /**
     * variable that unsubscribes save Observable
     */
    public unsubSave: Subscription = new Subscription();

    /**
     * variable that unsubscribes cancel Observable
     */
    public unsubCancel: Subscription = new Subscription();

    /**
     * variable that unsubscribe close Observable
     */
    public unsubClose: Subscription = new Subscription();

    /**
     * variable that unsubscribes custom Observable
     */
    public unsubCustom: Subscription = new Subscription();

    private alreadyClosed = false;

    constructor(
        private genericService: GalvinDialogGenericService,
        public dialogRef: MatDialogRef<T>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.unsubSave = this.genericService.save$.subscribe(() => {
            if (this.data && this.data.saveOrEditButton) {
                this.data.saveOrEditButton.name === 'save' ? this.save() : this.edit();
            }
        });

        this.unsubCancel = this.genericService.cancel$.subscribe(() => {
            this.cancel();
            if (!this.alreadyClosed) {
                this.close();
            }
        });

        this.unsubCustom = this.genericService.custom$.subscribe(() => {
            this.custom();
        });

        this.unsubClose = this.genericService.close$.subscribe(() => {
            if (!this.alreadyClosed) {
                this.close();
            }
        });
    }

    /**
     * this method unsubscribe  @var {Subscription} unsubSave , @var { Subscription} unsubCancel .
     * observation with you need implement ngOnDestroy in your components , it's necessary unsubscribe unsubscribe these variables manually.
     */
    ngOnDestroy(): void {
        this.unsubSave.unsubscribe();
        this.unsubCancel.unsubscribe();
        this.unsubClose.unsubscribe();
        this.unsubCustom.unsubscribe();
        this.alreadyClosed = false;
    }

    /**
     * this method is called when save button is clicked
     */
    abstract save(): any;

    /**
     * this method is called when edit button is clicked
     */
    abstract edit(): any;

    /**
     * this method is called when cancel button is clicked
     */
    abstract cancel(): any;

    /**
     * this method is called when custom button is clicked
     */
    abstract custom(): any;

    /**
     * this method close dialog
     */
    close(param: any = false) {
        this.alreadyClosed = true;
        this.dialogRef.close(param);
    }
}
