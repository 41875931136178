import { SecurityService } from '@galvin/core/auth';
import { Subscription } from 'rxjs';
// Angular
import { ChangeDetectionStrategy, Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Params, Router } from '@angular/router';
// Layout
import { ChatbotSidePanelService, LayoutConfigService, SplashScreenService, TranslationService } from './core/_base/layout';
// language list
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { locale as enLang } from './core/_config/i18n/en';

// Environment
import { environment } from '../environments/environment';
import { GoogleAuth } from './core/auth/services/google-auth';
import { AppConstants } from './views/share/app-constants';

declare let gtag: any;

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'body[kt-root]',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit, OnDestroy {
    @HostBinding('@.disabled')
    public animationsDisabled = false;

    // Public properties
    title = 'Galvin';
    loader!: boolean;
    private sub8n = new Subscription(); // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

    /**
     * Component constructor
     *
     * @param translationService: TranslationService
     * @param router: Router
     * @param route
     * @param layoutConfigService: LayoutCongifService
     * @param matIconRegistry
     * @param domSanitizer
     * @param splashScreenService: SplashScreenService
     */
    constructor(
        private translationService: TranslationService,
        private router: Router,
        private route: ActivatedRoute,
        private layoutConfigService: LayoutConfigService,
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer,
        private splashScreenService: SplashScreenService,
        private googleAuth: GoogleAuth,
        private securityService: SecurityService,
        private chatbotService: ChatbotSidePanelService
    ) {
        // register translations
        this.translationService.loadTranslations(enLang);
        // Register new icons
        this.matIconRegistry.addSvgIcon(
            'galvin-team-view',
            this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/galvin/icons/team_work.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'galvin-fullscreen',
            this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/galvin/icons/fullscreen.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'galvin-home-work',
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                '/assets/galvin/icons/home_work_black.svg'
            )
        );

        // Send all route changes to Google Analytics on prodution environment
        if (environment.production) {
            this.router.events.subscribe((event) => {
                if (event instanceof NavigationEnd) {
                    gtag('event', 'page_view', {
                        page_path: event.urlAfterRedirects
                    });
                }
            });
        }
    }

    /**
     * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
     */

    /**
     * On init
     */
    ngOnInit(): void {
        // enable/disable loader
        this.loader = this.layoutConfigService.getConfig('loader.enabled');

        const routerSubscription = this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                // hide splash screen
                this.splashScreenService.hide();

                // scroll to top on every route change
                // eslint-disable-next-line no-undef
                window.scrollTo(0, 0);

                const user = this.securityService.getInMemoryUser();
                if (user == null) {
                    this.googleAuth.initAuth();
                } else {
                    if (user && user.domain === AppConstants.MOTOROLA){
                        this.googleAuth.initCheckToken();
                    }else{
                        this.googleAuth.destroyCheckToken();
                    }

                }

                // to display back the body content
                // eslint-disable-next-line no-undef
                setTimeout(() => {
                    // eslint-disable-next-line no-undef
                    document.body.classList.add('kt-page--loaded');
                }, 500);
            }
        });
        this.sub8n.add(routerSubscription);

        this.sub8n.add(
            this.route.queryParams.subscribe((params: Params) => {
                if (params['e2e_execution']) {
                    this.animationsDisabled = true;
                }
            })
        );
    }

    /**
     * On Destroy
     */
    ngOnDestroy(): void {
        this.chatbotService.restartChat();
        this.googleAuth.destroyCheckToken();
        this.sub8n.unsubscribe();
    }
}
