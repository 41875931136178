import { Injectable } from '@angular/core';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
    providedIn: 'root'
})
export class PopoverService {

    ngbpopover: NgbPopover[] = [];

    closeAll(){
        this.ngbpopover.forEach(e => e.close());
        this.ngbpopover = [];
    }

}
