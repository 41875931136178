import { Component } from '@angular/core';

@Component({
    selector: 'floating-button',
    templateUrl: './floating-button.component.html',
    styleUrls: ['./floating-button.component.scss']
})
export class FloatingButtonComponent {
    readonly galvinSupportEmail = 'galvinsupport@motorola.com';
    opened = false;
    showHelp = true;

    open(){
        this.opened = !this.opened;
    }

    dismissHelp(){
        this.showHelp = false;
    }
}
