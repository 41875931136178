import { Pipe, PipeTransform } from '@angular/core';
import { ISkuBoardSamplesSourcing } from '@galvin/core/build-plan/reports/interfaces/sku-board-samples-sourcing';
import { ReportUtils } from '@galvin/core/build-plan/reports/utils/report.utils';

@Pipe({
    name: 'getTotal'
})
export class GetTotalPipe implements PipeTransform {

    transform(hwRevision: any, productType: string, sourcing: string, reportData: ISkuBoardSamplesSourcing): any {
        const total = this.getTotal(hwRevision, productType, sourcing, reportData);
        return total;
    }

    getTotal(hwRevision: string, productType: string, sourcing: string, reportData: ISkuBoardSamplesSourcing): string | number {
        const filteredItem = reportData.reportDTO?.filter(
            item => item.hwRevisions === hwRevision && item.sourcing === sourcing && item.productType === productType
        );

        const totalsRequest = filteredItem?.reduce((acc, number) => {
            return acc + number.totalRequests;
        }, 0);

        return ReportUtils.getValue(totalsRequest);
    }
}