import { ChangeDetectorRef, Component, Inject } from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS, MatDateFormats } from '@angular/material/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MatCalendar } from '@angular/material/datepicker';
import { ConfigurationsManagementService } from '@galvin/core/build-plan/configuration-management/services/configurations-management.service';

@Component({
    selector: 'under-calendar-header',
    templateUrl: './under-calendar-header.component.html',
    styleUrls: ['./under-calendar-header.component.scss']
})
export class UnderCalendarHeaderComponent<D> {
    private _destroyed = new Subject<void>();

    constructor(
        private configurationService: ConfigurationsManagementService,
        private _calendar: MatCalendar<D>,
        private _dateAdapter: DateAdapter<D>,
        @Inject(MAT_DATE_FORMATS) private _dateFormats: MatDateFormats,
        cdr: ChangeDetectorRef
    ) {
        _calendar.stateChanges.pipe(takeUntil(this._destroyed)).subscribe(() => cdr.markForCheck());
    }

    get periodLabel() {
        return this._dateAdapter
            .format(this._calendar.activeDate, this._dateFormats.display.monthYearLabel)
            .toLocaleUpperCase();
    }

    ngOnDestroy(): void {
        this._destroyed.next();
        this._destroyed.complete();
    }

    previousClicked(mode: 'month' | 'year'): void {
        this._calendar.activeDate =
            mode === 'month'
                ? this._dateAdapter.addCalendarMonths(this._calendar.activeDate, -1)
                : this._dateAdapter.addCalendarYears(this._calendar.activeDate, -1);
    }

    nextClicked(mode: 'month' | 'year'): void {
        this._calendar.activeDate =
            mode === 'month'
                ? this._dateAdapter.addCalendarMonths(this._calendar.activeDate, 1)
                : this._dateAdapter.addCalendarYears(this._calendar.activeDate, 1);
    }

    resetDate() {
        this.configurationService.resetDateDefault$.next(true);
    }
}
