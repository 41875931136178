import {Pipe, PipeTransform} from '@angular/core';
import { IAccessoryTypeRequestsTotals } from '@galvin/core/build-plan/accessory-request-management/interface/accessory-request-team.interface';
import { IMyAccessoryRequest } from '@galvin/core/build-plan/accessory-request-management/interface/my-accessory-requests';
import {EBuildPlanStatusLabel} from '@galvin/core/build-plan/management';
import {
    IMyAccessoryFilters
} from '@galvin/core/build-plan/prototype-request-management/interfaces/my-accessory-filters.interface';
import { DateTimeUtils } from '@galvin/core/utils/moment-utils';
import {
    IBuildPlanWithRequestsAccessory
} from '@galvin/views/pages/build-plan/accessory-team-request/my-accessories/my-accessories.component';
import {TranslateService} from '@ngx-translate/core';
import { isEmpty } from 'lodash';

@Pipe({
    name: 'filterMyAccessoryBuildPlan'
})
export class FilterMyAccessoryBuildPlanPipe implements PipeTransform {
    readonly allowedToShow: string[] = [
        EBuildPlanStatusLabel.OPEN.toLocaleLowerCase(),
        EBuildPlanStatusLabel.PARTIALLY_OPEN.toLocaleLowerCase(),
        EBuildPlanStatusLabel.LOCKED.toLocaleLowerCase(),
        EBuildPlanStatusLabel.DPM_PENDING_APPROVAL.toLocaleLowerCase(),
        EBuildPlanStatusLabel.DPM_APPROVED.toLocaleLowerCase(),
        EBuildPlanStatusLabel.BUILDING.toLocaleLowerCase(),
        EBuildPlanStatusLabel.COMPLETED.toLocaleLowerCase(),
        EBuildPlanStatusLabel.ARCHIVED.toLocaleLowerCase(),
        EBuildPlanStatusLabel.CANCELED.toLocaleLowerCase()
    ];
    private readonly ALL: string;

    constructor(private i18n: TranslateService) {
        this.ALL = this.i18n.instant('BUILD_PLAN.PROTOTYPE_REQUEST.HEADER.FILTERS.ALL');
    }

    transform(
        value: IBuildPlanWithRequestsAccessory[],
        filter: IMyAccessoryFilters
    ): IBuildPlanWithRequestsAccessory[] {
        return this.filterGTOpen(value || []).filter((bp) => {
            return (
                this.filterByText(bp, <string>filter?.searchInput) &&
                this.filterAccessoryType(bp, filter.accessoryType) &&
                this.validateProduct(filter.products, bp)
            );
        });
    }

    filterByText(buildPlan: IBuildPlanWithRequestsAccessory, inputSearch?: string | undefined): boolean {

        const searchText = inputSearch != undefined ? inputSearch?.trim().toLocaleLowerCase() : '';

        const atRequestsTotals = buildPlan['requests'] as IAccessoryTypeRequestsTotals;
        const reqTotals = atRequestsTotals?.sortedAccessoryTypes?.map( ac =>
            `${ac} ${atRequestsTotals.totalByAccessoryType[ac].toString()}`
        ).join('|').toLocaleLowerCase();

        const hasDetailRecords = !isEmpty(buildPlan.detailRecords);
        const detailRecords = hasDetailRecords ? ((buildPlan.detailRecords as IMyAccessoryRequest[])
            .map(c => `${c.id} ${c.teamName} ${c.accessoryName} ${c.accessoryId} ${c.accessoryType} ` +
            `${c.teamId} ${c.accessoryStatus} ${c.requester.name} ${c.requester.lastName} ` +
            DateTimeUtils.completeDateTimeFormat(c.requestDate.toString()) + ` ${c.group} ` +
            `${c.requestOwnerUser.name} ${c.requestOwnerUser.lastName} ${c.finalRecipientUser.name} ${c.finalRecipientUser.lastName} ` +
            `${(c.cdcLocation as any).shortDescription} ${(c.shipToLocation as any).shortDescription}`)
            .join('|')
            .toLocaleLowerCase()) : '';

        const lockDownDate = buildPlan.lockDownDate != undefined ?
            DateTimeUtils.completeDateTimeFormat(buildPlan.lockDownDate?.toString()).toLocaleLowerCase() : '';
        return (
            inputSearch === undefined ||
            buildPlan.internalProductName?.toLocaleLowerCase().includes(searchText) ||
            buildPlan.status?.toLocaleLowerCase().includes(searchText) ||
            (buildPlan.totalRequests ||'').toString().includes(searchText) ||
            (buildPlan.id ||'').toString().includes(searchText) ||
            detailRecords.includes(searchText) ||
            lockDownDate.includes(searchText) ||
            (reqTotals ||'').includes(searchText)
        );
    }

    filterAccessoryType(
        buildPlan: IBuildPlanWithRequestsAccessory,
        accessoryType = this.ALL
    ): boolean {
        return (
            accessoryType === null ||
            accessoryType.toLocaleLowerCase() === this.ALL.toLocaleLowerCase() ||
            buildPlan.requests.sortedAccessoryTypes.includes(accessoryType)
        );
    }

    filterGTOpen(collection: IBuildPlanWithRequestsAccessory[] | undefined): IBuildPlanWithRequestsAccessory[] {
        return (collection || []).filter(
            (bp) => bp.status && this.allowedToShow.includes(bp.status.toLocaleLowerCase())
        );
    }

    private validateProduct(
        bps: IBuildPlanWithRequestsAccessory[] | undefined,
        buildPlan: IBuildPlanWithRequestsAccessory
    ): boolean {
        return (
            !bps ||
            bps.length <= 0 ||
            typeof bps[0] === 'string' ||
            bps.some((i) => i.id === buildPlan.id)
        );
    }
}
