import {IExportsI18n} from './exports-i18n.interface';

export const EXPORTS: IExportsI18n = {
    REQUESTS: {
        KT_HEADER_EXPORT_REQUEST: 'Export Requests',
        KT_HEADER_EXPORT_HISTORY: 'Export History',
        FIRST_STEP: 'Select Export Mode',
        SECOND_STEP: 'Name your Export and Complete it',
        OPTION_ALL_CONFIGURATIONS: 'ALL CONFIGURATIONS',
        OPTION_SOME_CONFIGURATIONS: 'ONLY SOME CONFIGURATIONS',
        OPTION_ALL_ACCESSORIES: 'ALL ACCESSORIES',
        OPTION_SOME_ACCESSORIES: 'ONLY SOME ACCESSORIES',
        TITLE: 'Select the configurations you want to export',
        LABEL_CONFIGURATION: 'Select Configurations',
        LABEL_ACCESSORY: 'Select Accessories',
        BUTTON_EXPORT: 'Export Build Plan',
        BUTTON_EXPORT_LABEL: 'Give a name to your report',
        TOASTR_MSG_SUCCESS: 'Export send successfully. Downloading file',
        TOASTR_MSG_MAKING_EXPORT: 'Exporting data <i class="ml-1 fas fa-spinner fa-spin"></i>',
        VALIDATORS_REQUIRED_MSG: 'Please, provide a valid name to the export file',
        REMOVE_TEAMS_WITHOUT_REQUESTS: 'Remove Teams Without Requests'
    },
    HISTORY: {
        VIEW_DETAILS: 'View/Details',
        DOWNLOAD: 'Download',
        COLUMNS: {
            EXPORTED_DATE: 'EXPORTED DATE',
            USER: 'USER',
            NAME: 'NAME'
        },
        PROCESSING_DOWNLOAD:
            'Processing Download for the file: {{namefile}} <i class="ml-1 fas fa-spinner fa-spin"></i>'
    }
};
