import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ToolbarComponent} from './toolbar.component';
import {StateExpectedDirective} from '@galvin/views/partials/layout/toolbar/state-expected.directive';


@NgModule({
    declarations: [ToolbarComponent, StateExpectedDirective],
    exports: [
        ToolbarComponent,
        StateExpectedDirective
    ],
    imports: [
        CommonModule
    ]
})
export class ToolbarModule {
}
