import { Directive, Input, TemplateRef } from '@angular/core';

@Directive({
    selector: '[galvinTreeNodeTooltipTmp]'
})
export class GalvinTreeNodeTooltipTmpDirective {
    @Input() galvinTreeNodeTooltipTmpLevel!: any;

    constructor(public templateRef: TemplateRef<any>) {}
}
