/* eslint-disable @angular-eslint/no-output-on-prefix */
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { IFilterParameter } from '@galvin/core/build-plan/configuration-management/interfaces/configuration-filter-parameter.interface';
import { IFieldValue } from '@galvin/core/build-plan/field-value-management';

export interface IOptionsModel {
    [parameter: string]: {
        [value: string]: boolean;
    };
}

@Component({
    selector: 'configuration-parameters-filters',
    templateUrl: './configuration-parameters-filters.component.html',
    styleUrls: ['./configuration-parameters-filters.component.scss']
})
export class ConfigurationParametersFiltersComponent {
    @Input() loading = false;
    fvcParameters: IFilterParameter[] = [];
    optionsModel: IOptionsModel = {};

    @Input() searchForm: FormGroup = new FormGroup({});

    @Output() onFilterSelection = new EventEmitter<{
        parameter: IFilterParameter;
        value: IFieldValue;
        selected: boolean;
        optionsSelected?: IOptionsModel;
    }>();

    @Input() set optionsSelected(optionsModel: IOptionsModel) {
        this.optionsModel = optionsModel;
    }

    @Input() set parameters(parameters: IFilterParameter[]) {
        if (!parameters) {
            return;
        }

        this.fvcParameters = parameters.filter(
            (fvc) => fvc.fieldValues && fvc.fieldValues.length > 0
        );
        this.fvcParameters.sort((fvc1, fvc2) => {
            const fvc1Values = fvc1.fieldValues as IFieldValue[];
            const fvc2Values = fvc2.fieldValues as IFieldValue[];

            return fvc1Values.length > fvc2Values.length ? 1 : 0;
        });
    }

    /**
     * Function called when a filter option is selected, it updates the configuration filters
     *
     * @param fieldValue the value selected
     * @param parameter the parameter selected
     */
    onOptionSelected(fieldValue: IFieldValue, parameter: IFilterParameter): void {
        const field = parameter.fieldName as string;
        const value = fieldValue.value as string;

        if (!this.optionsModel[field]) {
            this.optionsModel[field] = {};
        }

        if (this.optionsModel[field][value] === undefined) {
            this.optionsModel[field][value] = false;
        }

        this.optionsModel[field][value] = !this.optionsModel[field][value];
        this.onFilterSelection.emit({
            parameter: parameter,
            value: fieldValue,
            selected: this.optionsModel[field][value],
            optionsSelected: this.optionsModel
        });
    }

    /**
     * Checks if a given configuration is selected
     *
     * @param value field value
     * @param parameter FVC parameter
     */
    isSelected(value: string, parameter: string): boolean {
        return (
            this.optionsModel && this.optionsModel[parameter] && this.optionsModel[parameter][value]
        );
    }

    /**
     * Given a value, return the string that should be displayed on the filter option
     *
     * @param value the display string
     */
    getValue(value: string): string {
        return value ? value : '—';
    }
}
