import { IAccessoryRequestI18n } from './accessory-request-i18n.interface';

export const ACCESSORY_REQUEST: IAccessoryRequestI18n = {
    TITLE: 'Accessory Request',
    REQUEST: {
        TITLE: 'Request',
        CARDS: {
            VENDOR_PART: 'Vendor Part#',
            MOTO_PART: 'Moto Part#',
            REQUESTED_SHORT: 'Rqstd.',
            NO_DATA: 'No accessory found for the selected build plan and team.',
            NO_DATA_FILTER: 'No accessory found for the selected combination of filters.',
            LOAD_ERROR:
                'Unable to load the list of accessory requests. Please try again in a few moments.',
            TOOLTIP_DETAIL: 'See details',
            CANCELED_ACCESSORY: 'This accessory has been cancelled'
        }
    },
    DIALOG: {
        LABELS: {
            TEAM_INFO: 'Team Info',
            QUANTITY: 'Quantity',
            DUE_DATE: 'Due Date',
            BUILD_SITE: 'Build Site',
            JUSTIFICATION: {
                JUSTIFICATION: 'Justification',
                AUTOMATED_TESTING: 'Automated Testing',
                MANUAL_TESTING: 'Manual Testing',
                CARRIERS: 'Carriers',
                SHOW_OFF: 'Show Off',
                MARKETING: 'Marketing',
                OTHER: 'Other',
                ADDITIONAL_COMMENTS: 'Additional Comments'
            },
            REQUEST: 'Request',
            REQUEST_OWNER: 'Request Owner',
            FINAL_RECIPIENT: 'Final Recipient',
            CDC_LOCATION: 'CDC Location',
            SHIP_TO_LOCATION: 'Ship To Location',
            GROUP: 'Group',
            ACTIVITY: 'Activity',
            ALL_DEVICES_TO_BE_ACTIVATED: 'All accessories are expected to be activated',
            DIFF_DEVICES:
                'This column indicates the differences between the previously requested quantity number to the quantity now modified.',
            ALL_DEVICES_TOOLTIP:
                'This will create a review process by HW PMs as it can affects the prototype manufacturing. It\'s possible this will become a new Accessory/Parameter, so, make sure you this is aligned properly with the HW PMs before requesting it.',
            ACTIVITY_TOOLTIP:
                'The accesories activation will be an important information to be able to measure if all or only part of these devices are being used or not based on network usage',
            ACTIVITY_INFO_TOOLTIP: 'This info might take 10-15 seconds to take place',
            REQUIRED_FIELD: 'Required field',
            NO_CHANGES: 'No changes to submit',
            INCREASED_QUANTITY: 'Increased Quantity',
            DECREASED_QUANTITY: 'Decreased Quantity',
            EQUAL_QUANTITY: 'Quantity Unchanged',
            CHECKED: 'Checked',
            UNCHECKED: 'Unchecked',
            NO_JUSTIFICATIONS: 'all unchecked',
            DEVICES_ACTIVATED_ACTIVITY: 'All devices to be activated',
            DEVICES: 'devices',
            COMMENT: 'Comment',
            NO_ACTIVITY_DATA: 'This accessory has no activity data recorded yet.',
            ERROR_ACTIVITY: 'Activity information could not be loaded at this moment.',
            LOADING_ACTIVITY: 'Loading activity information...',
            TOOLTIP_REQUEST_BUTTON:
                'You can\'t make a accessory request because the accessory status is {{accessoryStatus}}',
            ACCESSORY_TOOLTIP_REQUEST_BUTTON:
                'You can\'t make an accessory request because the accessory status is {{accessoryStatus}}',
            REQUEST_ACCESSORY_CHANGED: '<This Accessory was updated on the Build Plan>',
            REQUEST_TEAM_CHANGED: '<This Team was updated on the Build Plan>'
        }
    },
    HEADER: {
        TEAM_VIEW: 'Team View',
        FULL_VIEW: 'Full View',
        TEAM_SELECTOR: {
            ALL: 'All',
            ALL_FILTERED: 'Select All Filtered',
            TEAM_SELECTED_TITLE: ' team(s) selected',
            TEAM: 'Team',
            SELECT_TEAM: 'Select Team',
            SEARCH_TEAM: 'Search Team',
            NO_TEAMS_FILTER: 'No teams found.',
            LOAD_ERROR: 'Unable to load the list of Teams. Please try again in a few moments.',
            CDC_LOCATION: 'CDC Location: ',
            SHIP_TO_LOCATION: 'Ship To Location: ',
            REQUEST_OWNER_USER: 'Request Owner :',
            FINAL_RECIPIENT_USER: 'Final Recipient :'
        },
        TOTAL: 'Total',
        CANCELED:
            'This Build Plan has Cancelled Accessories that are not considered in Total Calculation',
        FILTERS: {
            SEARCH_ACCESSORY: 'Search accessory',
            ALL: 'All',
            ALREADY_REQUESTED: 'Already Requested',
            TO_REQUEST: 'To Request',
            ACCESSORIES_WITH_REQUESTS: 'Accessories with Requests',
            TEAMS_WITH_REQUESTS: 'Teams with Requests',
            REQUESTED_BY_ME: 'Requested by me',
            ALL_STATUS: 'All Status',
            ALL_ACCESSORY_TYPE: 'All Accessory Type',
            ALL_BUILD_PLANS: 'All Build Plans',
            ALL_REQUESTERS: 'All requesters',
            ALL_APPROVAL_STATUS: 'All Approval Status',
            SEARCH_INPUT_PLACEHOLDER: 'Search table entries',
            CATEGORY: 'Category',
            VENDOR: 'Vendor',
            VENDOR_PART: 'Vendor Part#',
            MOTO_PART: 'Moto Part#',
            FILTER_BY_TITLE: 'Filter by Accessories Specs',
            GLOSSARY_BTN_LABEL: 'Glossary',
            SELECTOR_TEAMS_PARAMETERS: 'Filter by Teams Parameters',
            ACCESSORY_TYPE: 'Accessory Type'
        }
    },
    MY_ACCESSORIES: {
        NO_REQUESTS: 'No Requests',
        REQUESTED_RECEIVED: 'Requested/Received',
        REQUEST_DATE: 'Request Date',
        CALENDAR: {
            CLEAN_DATES: 'Clean Dates',
            THESE_DATES_WILL_FILTER_REQUEST_DATE:
                'These dates will filter the Request Date of Accessories requested.'
        },
        NO_DATA: 'There are no accessories requested for the selected team(s).'
    },
    REQUEST_REVIEW: {
        ACCESSORY_NOT_OPEN:
            'Some previously selected accessories are no longer opened. Please perform the requests again.',
        TEAMS_NOT_FOUND:
            '{{total}} of the previously selected teams have not been found. Please perform the requests again.',
        ACCESSORY_NOT_FOUND:
            '{{total}} of the previously selected accessories have not been found. Please perform the requests again.',
        COMPLETE_SUCCEFULLY: 'All requests were performed successfully',
        COMPLETE_WITH_ERROR:
            'The previously ongoing request is no longer available. Please, perform the requests again',
        CONFIRM_REQUEST_DIALOG_TITLE: 'Confirm request review',
        CONFIRM_REQUEST_DIALOG_DESCRIPTION: 'Are you sure you want to perform the requests?',
        TO_BE_DEFINED: 'To be Defined',
        SPLIT_CHANGE_REQUEST:
            'There are accessories "Locked, In Transit, Manufactured, Completed" mixed up with "New, Pending Requirements, Open". Please, split your change request'
    },
    ACCESSORY_REQUEST_TABLE: {
        CONFIRM_DELETE_REQUEST_FROM_REVIEW_TITLE: 'Confirm',
        CONFIRM_DELETE_REQUEST_FROM_REVIEW_MESSAGE:
            'Are you sure you want to remove from review this request?'
    },
    SWIMLANES: {
        STATUS: {
            NEW: 'New',
            OPEN: 'Open',
            LOCKED: 'Locked',
            MANUFACTURED: 'Manufactured',
            IN_TRANSIT: 'In Transit',
            COMPLETED: 'Completed',
            CANCELED: 'Canceled'
        }
    }
};
