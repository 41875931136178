import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { IPrototypeRequestI18n } from '@galvin/core/build-plan/prototype-request-management/i18n/prototype-request-i18n.interface';
import { IRequestPreviewI18n } from '@galvin/views/pages/build-plan/build-plan-management/request-preview';
import { IProtoRequestHeader } from '@galvin/views/partials/layout/proto-request-table';
import { IDataRequestDetailsDialog } from '@galvin/views/partials/layout/request-details-dialog/data-request-details-dialog.interface';
import { RequestDetailsDialogComponent } from '@galvin/views/partials/layout/request-details-dialog/request-details-dialog.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'configuration-header',
    templateUrl: './configuration-header.component.html',
    styleUrls: ['./configuration-header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfigurationHeaderComponent {
    readonly LABELS_PROTO_REQUEST: IPrototypeRequestI18n;
    readonly LABELS_REQUEST_PREV: IRequestPreviewI18n;

    @Input() configuration!: IProtoRequestHeader;
    @Input() isHeaderInfoVisible!: boolean;
    @Input() idBuildPlan!: number;
    @Input() buildPlanName!: string;

    constructor(
        public translate: TranslateService,
        public cdr: ChangeDetectorRef,
        private dialog: MatDialog
    ) {
        this.LABELS_PROTO_REQUEST = this.translate.instant('BUILD_PLAN.PROTOTYPE_REQUEST');
        this.LABELS_REQUEST_PREV = this.translate.instant('BUILD_PLAN.REQUEST_PREVIEW');
    }

    verifyFieldFvcSecure(protoRequest: IProtoRequestHeader): boolean {
        return protoRequest && 'fvcSecure' in protoRequest;
    }

    getFvcSecure(protoRequest: IProtoRequestHeader): string {
        return protoRequest.fvcSecure
            ? this.LABELS_PROTO_REQUEST.REQUEST.CARDS.FVC_SECURE
            : this.LABELS_PROTO_REQUEST.REQUEST.CARDS.FVC_NO_SECURE;
    }

    /**
     * Open dialog of request prototype
     * @param header
     * @param ev
     */
    showRequestsDialog(header: IProtoRequestHeader, ev: Event): void {
        ev.stopPropagation();
        const param: IDataRequestDetailsDialog = {
            configurationId: header.idConfiguration,
            buildPlanId: this.idBuildPlan,
            buildPlanName: this.buildPlanName
        };

        this.dialog.open(RequestDetailsDialogComponent, {
            height: '796px',
            width: '1269px',
            data: param
        });
    }

    isSourcing(configSource: string | undefined, source: number): boolean {
        if (configSource === undefined) {
            return false;
        }
        const match = configSource.match(/\d+/g);
        return !!match && parseInt(match[0]) === source;
    }
}
