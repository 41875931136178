import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

declare let gtag: any;

@Injectable()
export class GoogleAnalyticsService {
    constructor() {}

    /**
     * Event emitter to send data to Google Analytics
     *
     * @param eventCategory
     * @param eventAction
     * @param eventLabel
     * @param eventValue
     */
    public sendEvent(
        eventCategory: string,
        eventAction: string,
        eventLabel: string | null = null,
        eventValue: number | null = null
    ): void {
        if (environment.production) {
            gtag('send', 'event', {
                eventCategory: eventCategory,
                eventLabel: eventLabel,
                eventAction: eventAction,
                eventValue: eventValue
            });
        }
    }
}
