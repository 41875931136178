import {ITeamParametersI18n} from './team-parameters-i18n.interface';

export const TEAM_PARAMETERS: ITeamParametersI18n = {
    FIELD_NAME: 'Name',
    FIELD_TYPE: 'Field Type',
    ORDER: 'Team Parameter Order',
    ORDER_HINT: 'The Team Parameter will be inserted in the position that you selected.',
    FIELD_VALUE: 'Enter with a Value',
    OBJECT_VALUES: 'Values',
    NEW_TYPE_VALUE: 'New type value',
    NEW_FIELD_OPTIONS: 'New field options',
    FIELDS: '1. Team Parameter',
    VALUES: '2. Values',
    DIALOG_NEW_TITLE: 'Creating a new Team Parameter',
    DIALOG_EDIT_TITLE: 'Editing the Team Parameter',
    FIELD_REQUIRED: '{{field_name}} is required!',
    NAME_ALREADY_EXIST: '{{field_name}} already exists!',
    ADD_TEAM_PARAMETERS: 'ADD TEAM PARAMETER',
    ALREADY_EXIST: 'The value already exists!',
    DELETE_TITLE: 'Do you want to delete this Team Parameter?',
    DELETE_CONTENT: 'This action will delete: {{name}} and could not be undone.',
    DELETE_TOOLTIP: 'Delete parameter',
    WARNING_TOOLTIP: 'The parameter is already being used and cannot be deleted.',
    MAX_LENGTH_ERROR: 'Max chars reached!',
    ERROR_TEAM_VALUE_REQUIRED: 'Please provide a valid value',
    ADD_TEAM_PARAMETER_VALUE_LABEL: 'Add/Edit values',
    MESSAGE_SAVED: 'Team Parameter saved',
    MESSAGE_UPDATED: 'Team Parameter updated',
    TOOLTIP_EDIT: 'Edit Team Parameter',
    DELETE_VALUE_TITLE: 'Do you want to delete this Team value?',
    DELETE_VALUE_CONTENT: 'This action will delete: {{name}} and could not be undone.',
    CREATE_VALUE_TITLE: 'Do you want to create this Team value?',
    CREATE_VALUE_CONTENT: 'This action will create: {{name}}',
    EDIT_VALUE_TITLE: 'Do you want to edit this Team value?',
    EDIT_VALUE_CONTENT: 'This action will edit to: {{name}}',
    DELETE_VALUE_TOOLTIP: 'Delete team value',
    WARNING_VALUE_TOOLTIP: 'The value is already being used and cannot be deleted.',
    BP_TEAM_VALUE_ASSOCIATIONS: 'The following Build Plans associated values can be updated to {{teamValue}}. ',
    BP_CONFIGURATION_ALIAS_ASSOCIATIONS: 'We suggest you to update the configuration\'s aliases to {{teamValue}}. ',
    BP_TEAM_VALUE_ALIAS: 'Their aliases will be updated as well.',
    TEAM_MANAGEMENT_VALUE_ASSOCIATIONS: 'The following Team Management will have their aliases updated to {{teamValue}} ',
    UPDATE_ASSOCIATED_VALUES: 'Do you want to update their values?',
    TOOLTIP_BP_STATUS: 'Build Plan Status',
    TOOLTIP_BP_INTERNAL_NAME: 'Build Plan Internal Product Name',
    ASSOCIATIONS_UPDATED: 'Associations updated!',
    NO_BP_ASSOCIATIONS_FOUND: 'No Build Plan Associations to this value (including BP Aliases)',
    NO_TEAM_ASSOCIATIONS_FOUND: 'No Team Management Aliases associated to this value',
    BUTTON_NOT_UPDATE_ASSOCIATIONS: 'Update Team Value',
    BUTTON_UPDATE_ASSOCIATIONS: 'Update Associations',
    TEAM_MANY_ALIASES_MSG_ERROR: 'The team aliases must be unique, you can not update them all at the same time',
    TOOLTIP_BTN_UPDATE_TEAM_ONLY: 'Updates the value from this parameter, without updating the Build Plan Teams that may use this value',
    TOOLTIP_BTN_UPDATE_ASSOCIATIONS: 'Updates the value from this parameter, and it updates the Build Plan Teams you\'ve checked above',
    TOOLTIP_TEAM_BTN_UPDATE_TEAM_ONLY: 'Updates the value from this parameter, without updating the Build Plan or the Team Management values that may use this value',
    TOOLTIP_TEAM_BTN_UPDATE_ASSOCIATIONS: 'Updates the value from this parameter, and updates the Build Plan Teams and the Team Management values you\'ve checked above',
};
