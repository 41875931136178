import { LayoutConfigModel } from '../_base/layout';

export class LayoutConfig {
    public galvin: LayoutConfigModel = {
        demo: 'galvin',
        self: {
            layout: 'fluid',
            body: {
                'background-image': './assets/media/misc/bg-1.jpg'
            },
            logo: {
                dark: './assets/media/logos/logo-light.png',
                light: './assets/media/logos/logo-dark.png',
                brand: './assets/media/logos/logo-light.png',
                green: './assets/media/logos/logo-light.png'
            }
        },
        loader: {
            enabled: true,
            type: 'spinner-logo',
            logo: './assets/galvin/icons/icon_bp_logo.svg',
            message: 'Please wait...'
        },
        colors: {
            state: {
                brand: '#5d78ff',
                dark: '#282a3c',
                light: '#ffffff',
                primary: '#5867dd',
                success: '#34bfa3',
                info: '#36a3f7',
                warning: '#ffb822',
                danger: '#fd3995'
            },
            base: {
                label: [
                    '#c5cbe3',
                    '#a1a8c3',
                    '#3d4465',
                    '#3e4466'
                ],
                shape: [
                    '#f0f3ff',
                    '#d9dffa',
                    '#afb4d4',
                    '#646c9a'
                ]
            }
        },
        header: {
            self: {
                skin: 'light',
                fixed: {
                    desktop: true,
                    mobile: true
                }
            },
            menu: {
                self: {
                    display: false,
                    layout: 'default',
                    'root-arrow': false
                },
                desktop: {
                    arrow: false,
                    toggle: 'click',
                    submenu: {
                        skin: 'light',
                        arrow: false
                    }
                },
                mobile: {
                    submenu: {
                        skin: 'dark',
                        accordion: true
                    }
                }
            }
        },
        subheader: {
            display: true,
            layout: 'subheader-galvin',
            fixed: true,
            width: 'fluid',
            style: 'solid',
            icon: 'galvin-icon-build-plan-logo'
        },
        content: {
            width: 'fluid'
        },
        brand: {
            self: {
                skin: 'light'
            }
        },
        aside: {
            self: {
                skin: 'dark',
                display: true,
                fixed: true,
                minimize: {
                    toggle: true,
                    default: false
                }
            },
            footer: {
                self: {
                    display: true
                }
            },
            menu: {
                dropdown: false,
                scroll: false,
                submenu: {
                    accordion: true,
                    dropdown: {
                        arrow: true,
                        'hover-timeout': 500
                    }
                }
            }
        },
        footer: {
            self: {
                width: 'fluid',
                fixed: false
            }
        },
        'chatbot-side-panel': {
            self: {
                expanded: false
            }
        }
    };

    public cdc: LayoutConfigModel = {
        demo: 'cdc',
        self: {
            layout: 'fluid',
            body: {
                'background-image': './assets/media/misc/bg-1.jpg'
            },
            logo: {
                dark: './assets/media/logos/logo-light.png',
                light: './assets/media/logos/logo-dark.png',
                brand: './assets/media/logos/logo-light.png',
                green: './assets/media/logos/logo-light.png'
            }
        },
        loader: {
            enabled: true,
            type: 'spinner-logo',
            logo: './assets/galvin/icons/icon_bp_logo.svg',
            message: 'Please wait...'
        },
        colors: {
            state: {
                brand: '#5d78ff',
                dark: '#282a3c',
                light: '#ffffff',
                primary: '#5867dd',
                success: '#34bfa3',
                info: '#36a3f7',
                warning: '#ffb822',
                danger: '#fd3995'
            },
            base: {
                label: [
                    '#c5cbe3',
                    '#a1a8c3',
                    '#3d4465',
                    '#3e4466'
                ],
                shape: [
                    '#f0f3ff',
                    '#d9dffa',
                    '#afb4d4',
                    '#646c9a'
                ]
            }
        },
        header: {
            self: {
                skin: 'light',
                fixed: {
                    desktop: true,
                    mobile: true
                }
            },
            menu: {
                self: {
                    display: false,
                    layout: 'default',
                    'root-arrow': false
                },
                desktop: {
                    arrow: false,
                    toggle: 'click',
                    submenu: {
                        skin: 'light',
                        arrow: false
                    }
                },
                mobile: {
                    submenu: {
                        skin: 'dark',
                        accordion: true
                    }
                }
            }
        },
        subheader: {
            display: true,
            layout: 'subheader-galvin',
            fixed: true,
            width: 'fluid',
            style: 'solid',
            icon: 'galvin-icon-cdc-logo'
        },
        content: {
            width: 'fluid'
        },
        brand: {
            self: {
                skin: 'cdc'
            }
        },
        aside: {
            self: {
                skin: 'cdc',
                display: true,
                fixed: true,
                minimize: {
                    toggle: true,
                    default: false
                }
            },
            footer: {
                self: {
                    display: true
                }
            },
            menu: {
                dropdown: false,
                scroll: false,
                submenu: {
                    accordion: true,
                    dropdown: {
                        arrow: true,
                        'hover-timeout': 500
                    }
                }
            }
        },
        footer: {
            self: {
                width: 'fluid',
                fixed: false
            }
        },
        'chatbot-side-panel': {
            self: {
                expanded: false
            }
        }
    };

    public finance: LayoutConfigModel = {
        demo: 'finance',
        self: {
            layout: 'fluid',
            body: {
                'background-image': './assets/media/misc/bg-1.jpg'
            },
            logo: {
                dark: './assets/media/logos/logo-light.png',
                light: './assets/media/logos/logo-dark.png',
                brand: './assets/media/logos/logo-light.png',
                green: './assets/media/logos/logo-light.png'
            }
        },
        loader: {
            enabled: true,
            type: 'spinner-logo',
            logo: './assets/galvin/icons/icon_finance_logo.svg',
            message: 'Please wait...'
        },
        colors: {
            state: {
                brand: '#5d78ff',
                dark: '#282a3c',
                light: '#ffffff',
                primary: '#5867dd',
                success: '#34bfa3',
                info: '#36a3f7',
                warning: '#ffb822',
                danger: '#fd3995'
            },
            base: {
                label: [
                    '#c5cbe3',
                    '#a1a8c3',
                    '#3d4465',
                    '#3e4466'
                ],
                shape: [
                    '#f0f3ff',
                    '#d9dffa',
                    '#afb4d4',
                    '#646c9a'
                ]
            }
        },
        header: {
            self: {
                skin: 'light',
                fixed: {
                    desktop: true,
                    mobile: true
                }
            },
            menu: {
                self: {
                    display: false,
                    layout: 'default',
                    'root-arrow': false
                },
                desktop: {
                    arrow: false,
                    toggle: 'click',
                    submenu: {
                        skin: 'light',
                        arrow: false
                    }
                },
                mobile: {
                    submenu: {
                        skin: 'dark',
                        accordion: true
                    }
                }
            }
        },
        subheader: {
            display: true,
            layout: 'subheader-galvin',
            fixed: true,
            width: 'fluid',
            style: 'solid',
            icon: 'galvin-icon-finance-logo'
        },
        content: {
            width: 'fluid'
        },
        brand: {
            self: {
                skin: 'finance'
            }
        },
        aside: {
            self: {
                skin: 'finance',
                display: true,
                fixed: true,
                minimize: {
                    toggle: true,
                    default: false
                }
            },
            footer: {
                self: {
                    display: true
                }
            },
            menu: {
                dropdown: false,
                scroll: false,
                submenu: {
                    accordion: true,
                    dropdown: {
                        arrow: true,
                        'hover-timeout': 500
                    }
                }
            }
        },
        footer: {
            self: {
                width: 'fluid',
                fixed: false
            }
        },
        'chatbot-side-panel': {
            self: {
                expanded: false
            }
        }
    };

    /**
	 * Good place for getting the remote config
	 */
    public get configs(): LayoutConfigModel {
        return this.galvin;
    }

    public get cdcConfigs(): LayoutConfigModel {
        return this.cdc;
    }

    public get financeConfigs(): LayoutConfigModel {
        return this.finance;
    }
}
