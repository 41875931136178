<div class="delete-fvc cursor-pointer" [ngClass]="{'async-loading-animation' : isLoadingDelectableParameters}"
     *ngIf="canDeleteParameter"
     placement="left"
     [ngbTooltip]="getTooltipMessageForDelectableParameter"
     (click)="emit()">
    <mat-icon class="galvin-icon-danger">delete</mat-icon>
</div>
<div class="warning-fvc" *ngIf="!canDeleteParameter" [ngbTooltip]="getTooltipMessageForWarningParameter">
    <mat-icon class="galvin-icon-danger">warning_amber</mat-icon>
</div>
