import { ChangeDetectorRef, Component, Input, OnDestroy, EventEmitter, Output, ViewChild } from '@angular/core';
import { PopoverService } from '@galvin/core/_base/layout/services/popover.service';
import { IUserManagement } from '@galvin/core/build-plan/user-management';
import { UserManagementService } from '@galvin/core/build-plan/user-management/services/user-management.service';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap/popover/popover';
import { PlacementArray } from '@ng-bootstrap/ng-bootstrap/util/positioning';
import { Subscription, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ILabelSelected } from '../models/label-selected.model';
import { ICommentWithMentions } from '../models/icomment-with-mentions.model';
import { cloneDeep } from 'lodash';

export const REGEX_MENTIONS_EMAIL = /@[\w.-]+@[\w.-]+\.[\w.-]+/g;
@Component({
    selector: 'mention-comment[defaultText]',
    templateUrl: './mention-comment.component.html',
    styleUrls: ['./mention-comment.component.scss']
})
export class MentionCommentComponent implements OnDestroy {

    inputValue: string = '';
    selectedUsers: ILabelSelected[] = [];
    allUsersEmail: string[] = [];

    private applyingChanges: boolean = false;
    private inputValueDefault:string = '';
    private selectedUsersDefault: ILabelSelected[] = [];

    readonly formatDate = 'MMM dd yyyy, HH:mm:ss';
    private readonly sub: Subscription;

    @Input() placement: PlacementArray = 'left';
    @Input() container: string = 'body';
    @Input() nameCreateBy!: string;
    @Input() createdDate?: Date;
    @Input() editable: boolean = true;

    @Output() applyActions: EventEmitter<ICommentWithMentions> = new EventEmitter<ICommentWithMentions>();

    @ViewChild('popover') private popover!: NgbPopover;

    constructor(
        private userManagementService: UserManagementService,
        private cdr: ChangeDetectorRef,
        private popoverService : PopoverService,
    ) {
        this.sub = new Subscription();
    }

    @Input()
    set defaultText(text: string){
        this.inputValue = text;
        if(text && !this.selectedUsers.length){
            this.updateUserListByText(text);
        }
    }

    @Input()
    set usersToNotify(values: string[]){
        if (this.selectedUsers?.length){
            this.setSelectedValues(values);
        }
    }

    ngOnDestroy(): void {
        this.sub.unsubscribe();
    }

    onSelectItem(item: {email: string}): void{
        if(!this.selectedUsers.map(e => e.label).includes(item.email)){
            this.selectedUsers.push({'label': item.email,'selected':false});
            this.cdr.detectChanges();
        }
    }

    onDeleteInput(event: KeyboardEvent): void {
        if(event.code === 'Backspace' || event.code === 'Delete' || event.code === 'Space'){
            let oldInput = this.inputValue;
            setTimeout(() => {
                if(this.inputValue.trim() == ''){
                    this.selectedUsers = [];
                }else{
                    let removedItems: string[] = [];
                    this.selectedUsers = this.selectedUsers.filter((e) =>{
                        if(this.inputValue.includes(`@${e.label}`)){
                            return true;
                        }else{
                            removedItems.push(e.label);
                            return false;
                        }
                    });
                    removedItems.forEach((e => {
                        this.inputValue = oldInput.replace(new RegExp(` ?@${e}`), '');
                        oldInput = this.inputValue;
                        this.cdr.detectChanges();
                    }));
                    this.selectedUsers = this.selectedUsers.filter(e => !removedItems.includes(e.label));
                }
            }, 1);
        }
    }

    showSPRPopover(): void {
        this.applyingChanges = false;
        this.saveInitialState();
        this.loadAllUsers();

        this.popoverService.closeAll();
        this.popover.open();
        this.popoverService.ngbpopover.push(this.popover);
    }

    closeSPRPopover(): void {
        this.popover.close();
    }

    cancelChanges(): void {
        this.restoreDefaultValues();
        this.closeSPRPopover();
    }

    applyComments(): void {
        this.applyingChanges = true;
        const selectedUsers = this.selectedUsers.filter(e=>e.selected==true);
        const comentWithMentions: ICommentWithMentions = {
            commentText: this.inputValue,
            usersToNotify: selectedUsers.map(e => e.label)
        };
        this.applyActions.emit(comentWithMentions);
        this.closeSPRPopover();
    }

    isSelected(value:string, listItems:string[]): boolean{
        return listItems?.includes(value);
    }

    getEmailsByText(text: string): string[]{
        try{
            const emails: string[] = (text.match(REGEX_MENTIONS_EMAIL) || []).map((email) => email.trim().slice(1));
            return [...new Set(emails)];
        }catch{
            return [];
        }
    }

    private updateUserListByText(text: string){
        const selectedUsers = this.getEmailsByText(text);
        this.selectedUsers =  selectedUsers.map(e=> {
            return  {'label': e,'selected':false} as ILabelSelected;
        });
    }

    private setSelectedValues(values:string[]) {
        this.selectedUsers.forEach(e => {
            e.selected = this.isSelected(e.label, values);
        });
    }

    private saveInitialState(){
        this.inputValueDefault = this.inputValue;
        this.selectedUsersDefault = cloneDeep(this.selectedUsers);
    }

    private restoreDefaultValues(){
        this.inputValue = this.inputValueDefault;
        this.selectedUsers = cloneDeep(this.selectedUsersDefault);
    }

    private loadAllUsers() {
        if(this.editable){
            this.sub.add(this.userManagementService
                .getAllUsersToComment().pipe(
                    catchError(() => {
                        return of([]);
                    })
                )
                .subscribe((response: IUserManagement[]) => {
                    this.allUsersEmail = response.map(e => e.email);
                    this.cdr.detectChanges();
                }));
        }
    }

    cancelChangesWhenClosePopover(){
        if(!this.applyingChanges){
            this.restoreDefaultValues();
        }
    }

}
