import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy,  UrlSegment} from '@angular/router';
import { PageRenderService } from './page-render.service';

@Injectable({
    providedIn: 'root'
})
export class CustomReuseStrategy implements RouteReuseStrategy {
    storedHandles: { [key: string]: DetachedRouteHandle } = {};
    constructor(private pageRender: PageRenderService){}
    shouldDetach(route: ActivatedRouteSnapshot): boolean {
        return route.data['reuseRoute'] || false;
    }

    store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
        const id = this.createIdentifier(route);
        if (route.data['reuseRoute']&&!id.includes('request-review')) {
            this.storedHandles[id] = handle;
        }
    }

    shouldAttach(route: ActivatedRouteSnapshot): boolean {
        const id = this.createIdentifier(route);
        const handle = this.storedHandles[id];
        const reload = route.queryParams['reload'];
        const canAttach = !!route.routeConfig && !!handle && !this.needReloadIfFullView(id,reload);
        if(this.getUrlPathById(id) == '/build-plan/prototype-request/team-view' || this.getUrlPathById(id) == '/build-plan/prototype-request/full-view'){
            localStorage.setItem('/build-plan/prototype-request',this.getUrlPathById(id) as string);
        }
        if(this.getUrlPathById(id) == '/build-plan/accessory-request/team-view' || this.getUrlPathById(id) == '/build-plan/accessory-request/full-view'){
            localStorage.setItem('/build-plan/accessory-request',this.getUrlPathById(id) as string);
        }
        return canAttach;
    }

    retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null{
        const id = this.createIdentifier(route);
        if (!route.routeConfig || !this.storedHandles[id]) return null;
        setTimeout(()=>{
            this.pageRender.tableDataChange.next(true);
        });
        return this.storedHandles[id];
    }

    shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
        return future.routeConfig === curr.routeConfig;
    }

    private createIdentifier(route: ActivatedRouteSnapshot) {
        const segments: UrlSegment[][] = route.pathFromRoot.map(r => r.url);
        const subpaths = ([] as UrlSegment[]).concat(...segments).map(segment => segment.path);
        return segments.length + '-' + subpaths.join('/');
    }

    private getUrlPathById(id :string) :string | null{
        if(!id) return null;
        return '/'+id.substring(2);
    }

    private needReloadIfFullView(url:string,reload:boolean):boolean{
        if(url.includes('full-view')){
            if(reload){
                return true;
            }
            return false;
        }
        return false;
    }
}