import { Directive, EmbeddedViewRef, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { AbstractControl } from '@angular/forms';

/**
 * A structural directive that conditionally includes a template based on the state
 * of a AbstractControl optionally strict to an specific error(s) and an expression
 * coerced to Boolean.
 *
 * A shorthand form of the directive, `*invalidField="control"`, is generally used.
 *
 * Shorthand form with "strictTo" and "validateOn":
 *
 * ```
 * <input [formControl]='control'>
 * <div *invalidField="control strictTo: 'required' validateOn: canValidate">
 * ```
 */
@Directive({ selector: '[invalidField]' })
export class InvalidFieldDirective {
    static ngTemplateGuard_strictTo: 'binding';
    static ngTemplateGuard_validateOn: 'binding';
    private _control!: AbstractControl;
    private _errorCollection: undefined | string[];
    private _embeddedViewRef!: EmbeddedViewRef<any>;

    constructor(private _templateRef: TemplateRef<any>, private _viewContainer: ViewContainerRef) {}

    private _validateOn = true;

    /**
     * convenience
     */
    get validateOn(): boolean {
        return this._validateOn;
    }

    @Input() set invalidField(control: AbstractControl) {
        this._control = control;
        this._updateView();
    }

    /**
     * @param error string | string[] key errors where the directive must be strict verified
     */
    @Input() set invalidFieldStrictTo(error: string | string[]) {
        if (error instanceof Array) {
            this._errorCollection = error;
        } else {
            this._errorCollection = [error];
        }
        this._updateView();
    }

    /**
     * A boolean value to define if the template must be renderer even if the other condition are true
     */
    @Input() set invalidFieldValidateOn(condition: boolean) {
        this._validateOn = condition;
        this._updateView();
    }

    /**
     * Search errors from this._errorCollection into this._control.errors keys
     * @private
     */
    private get hasError(): boolean {
        const errors = this._control.errors;
        if (this.isStrict()) {
            if (errors !== null) {
                for (const error of <string[]>this._errorCollection) {
                    if (error in errors) {
                        return true;
                    }
                }
            }
            return false;
        }
        return true;
    }

    /**
     * Template is only rendered if return is true.
     * @param dir InvalidFieldDirective
     * @param control AbstractControl
     */
    static ngTemplateGuard_invalidField(dir: InvalidFieldDirective, control: AbstractControl) {
        return InvalidFieldDirective.invalid(control);
    }

    /**
     * @param control AbstractControl
     */
    static invalid(control: AbstractControl): boolean {
        return control.invalid && (control.dirty || control.touched);
    }

    /**
     * Render the template view
     * @private
     */
    private _updateView() {
        if (!InvalidFieldDirective.invalid(this._control) && this.hasError && this.validateOn) {
            if (!this._embeddedViewRef) {
                this._embeddedViewRef = this._viewContainer.createEmbeddedView(this._templateRef);
            }
        } else {
            this._viewContainer.clear();
        }
    }

    private isStrict(): boolean {
        return this._errorCollection !== undefined && this._errorCollection.length > 0;
    }
}
