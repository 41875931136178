<div>
    <div mat-dialog-title class="d-flex justify-content-between">
        <div class="kt-portlet__head-label mw-100">
            <h3>{{data.title}}</h3>
        </div>

        <button ngbTooltip="{{'COMMON.CLOSE_DIALOG' | translate}}" placement="bottom" id="btn-header-close"
            container="galvin-dialog-generic" type="button" (click)="closeDialog()" [disabled]="isExecuting"
            class="btn btn-clean btn-sm btn-icon btn-icon-md">
            <i class="la la-times"></i>
        </button>
    </div>

    <ng-container *ngIf="!isLoading">
        <mat-dialog-content>
            <b>
                {{data.description}}
            </b>
            <mat-list *ngIf="data.listData.length > 0" class="data-list">
                <div *ngFor="let item of data.listData">
                    <mat-list-item class="align-items-center" [ngbTooltip]="item">
                        <span class="galvin-icon-team-selector icon-team"></span>
                        <span class="text-truncate">{{item}}</span>
                    </mat-list-item>
                    <mat-divider></mat-divider>
                </div>
            </mat-list>
        </mat-dialog-content>
        <mat-dialog-actions class="justify-content-end">
            <button [mat-dialog-close]="false" id="btn-cancel"
                mat-raised-button>{{'COMMON.BUTTONS.CANCEL' | translate}}</button>
            <button color="warn" mat-raised-button id="btn-continue" [ngClass]="data.buttonClass || ''"
                (click)='isConfirm$.next()'>{{'COMMON.BUTTONS.CONTINUE' | translate}}</button>
        </mat-dialog-actions>
    </ng-container>
    <ng-container *ngIf="isLoading">
        <mat-dialog-content class="justify-content-center">
            <galvin-progress-spinner></galvin-progress-spinner>
        </mat-dialog-content>
    </ng-container>
</div>