export enum EFieldType {
    BOOLEAN = 'Boolean',
    DATE = 'Date',
    INT = 'Int',
    DOUBLE = 'Double',
    OPTIONS = 'Options',
    TEXT = 'String'
}

export type EFieldTypeKeys = keyof typeof EFieldType;
