import {TemplateRef} from '@angular/core';
import {ValidationErrors} from '@angular/forms';
import {EGenericFieldState} from '@galvin/views/partials/layout/generic-field/generic-field/generic-field-state.enum';


export type StateTemplateRecord = {
    [key in EGenericFieldState]?: TemplateRef<unknown>;
};

export enum EGenericFieldImmutableType {
    custom = 'custom',
    toggle = 'toggle',
    checkbox = 'checkbox',
    file = 'file'
}

export enum EGenericFieldCommonType {
    color = 'color',
    date = 'date',
    datetimeLocal = 'datetimeLocal',
    email = 'email',
    image = 'image',
    month = 'month',
    number = 'number',
    int = 'number',
    double = 'number',
    radio = 'radio',
    range = 'range',
    reset = 'reset',
    search = 'search',
    tel = 'tel',
    text = 'text',
    string = 'text',
    time = 'time',
    url = 'url',
    week = 'week',
    select = 'select',
    options = 'select',
    percent = 'percent',
}

export type RegisterFunction<T> = (fn: T) => void;

export type GenericFieldValidatorFn<T> = (value: T) => ValidationErrors;
