<kt-portlet>
    <header-dialog-generic [title]="title" [loadSave]="save" [app]="app"></header-dialog-generic>
    <kt-portlet-body class="{{ app }}">
        <ng-content></ng-content>
    </kt-portlet-body>
    <kt-portlet-footer>
        <footer-dialog-generic [tooltips]="tooltips" [saving]="save" [enabled]="enabled" [saveOrEditButton]="saveOrEditButton"
            [cancelButton]="cancelButton" [customButton]="customButton">
        </footer-dialog-generic>
    </kt-portlet-footer>
</kt-portlet>
