<div class="search-filter flex-wrap">
    <div class="search-input-area">
        <search-galvin class="search-input-container" [disabled]="isLoading"
            (inputChange)="onSearchInputChange($any($event))" [filter]="true" [loadSearch]="isSearching"
            [optionsSelected]="getOptionsSelected()" (clearFilterBy)="clearFvcParameters()" [textSearch]="selectedFilters.inputSearch!"
            placeholder="{{'BUILD_PLAN.TEAM_MANAGEMENT.BUILD_PLAN_ASSOCIATION.SEARCH_TEAM' | translate}}">
            <div *cellRef="let form=form">
                <configuration-parameters-filters [searchForm]="form" [parameters]="fvcParameters"
                    [optionsSelected]="getOptionsSelected()" (onFilterSelection)="onParameterFilterSelection($event)">
                </configuration-parameters-filters>
            </div>
        </search-galvin>
    </div>

    <multi-selectors [disabled]="isLoading" [itemsSelector]="teamsParameters"
        [titleSelector]="titleSelectorTeamsParameters()" (selectorChange)="selectorChanged($event)"
        [recoverySelected]="teamsParametersSelected">
    </multi-selectors>

    <div id="hw-revision-filters" class="btn-group flex-wrap">
        <button (click)="onHwRevisionSelected(ALL)" [ngClass]="{'active' : isFilterHwRevisionActive(ALL)}" [value]="ALL"
            [disabled]="isLoading" type="button" class="btn btn-secondary filter-option-button">
            {{ALL}}
        </button>
        <button *ngFor="let hwRevision of hwRevisions" (click)="onHwRevisionSelected(hwRevision)"
            [ngClass]="{'active' : isFilterHwRevisionActive(hwRevision)}" [value]="hwRevision" [disabled]="isLoading"
            type="button" class="btn btn-secondary filter-option-button">
            {{hwRevision}}
        </button>
    </div>

    <div id="requested-filters-requester-preview" class="btn-group flex-wrap">
        <button (click)="onRequestTypeSelected(ALL)" [disabled]="isLoading" type="button"
            [ngClass]="{'active' : isFilterActive(ALL)}" class="btn btn-secondary filter-option-button" [value]="ALL">
            {{ ALL }}
        </button>
        <button (click)="onRequestTypeSelected(CONFIGS_WITH_REQUESTS)" [disabled]="isLoading" type="button"
            [ngClass]="{'active' : isFilterActive(CONFIGS_WITH_REQUESTS)}"
            class="btn btn-secondary filter-option-button" [value]="CONFIGS_WITH_REQUESTS">
            {{ CONFIGS_WITH_REQUESTS }}
        </button>
        <button (click)="onRequestTypeSelected(TEAMS_WITH_REQUESTS)" [disabled]="isLoading" type="button"
            [ngClass]="{'active' : isFilterActive(TEAMS_WITH_REQUESTS)}" class="btn btn-secondary filter-option-button"
            [value]="TEAMS_WITH_REQUESTS">{{ TEAMS_WITH_REQUESTS }}</button>
    </div>
</div>

<div *ngIf="isLoading || noData || noDataFilters" class="request-preview-centered-container">
    <galvin-progress-spinner [diameter]="70" class="centered-item" *ngIf="isLoading"></galvin-progress-spinner>

    <div *ngIf="noData && !noDataFilters && !isLoading" class="centered-item no-data-message">
        {{ 'BUILD_PLAN.REQUEST_PREVIEW.NO_DATA' | translate }}
    </div>

    <div *ngIf="noDataFilters && !noData && !isLoading" class="centered-item no-data-message">
        <p>{{ 'BUILD_PLAN.REQUEST_PREVIEW.NO_DATA_FILTER' | translate }}</p>
        <button class="galvin-btn-primary text-btn-reset-filters" color="primary" mat-raised-button
            (click)="resetAllFiltersSelected()">
            {{labels.RESET_FILTERS}}
        </button>
    </div>
</div>

<div [ngClass]="{'proto-request-table-container': !fullScreen, 'proto-request-table-container-fullscreen': fullScreen}">
    <proto-request-table #protoRequestTable [cellSize]="{width: 136, height: 35}" [data]="data" [headers]="headers"
    [isTotalAccessories]="false"
    [allProtoHwRevisions]="allHwRevisions" [setFilteredHwRevision]="hwRevisionsSelected"
    [isLoading]="isLoading" [style.visibility]="isLoading ? 'hidden' : 'visible'" [hidden]="noData || noDataFilters"
    (showHeaderInfo)="showHideHeaderInfo($event)" [renderButtonsTip]="false" (updateSelectedCells)="updateSelectedCells($event)" >
    <!-- group header-->
        <div *groupHeaderRef="let group=item" [ngbTooltip]="headerTooltip">{{group.groupName}}
            <ng-template #headerTooltip>
                {{group.name}}
            </ng-template>
        </div>
        <div *tableHeaderRef="let header=item; let group=groupRow" [style.max-height.px]="(isHeaderInfoVisible) ? 120 : 56"
            class="d-inline-block w-100 text-truncate" [ngbTooltip]="tooltip">
            <ng-template #tooltip>
                <ng-template #cancelledTooltip>
                    {{this.labels.CANCELED_CONFIGURATION}}
                </ng-template>
                <ng-container *ngIf="!isCancelledConfiguration(header); else cancelledTooltip">
                    {{header?.name |checkNull}} #{{header?.idConfiguration}}
                    <br>
                    {{header?.recipeConfig |checkNull}}
                    <br>
                    {{ 'BUILD_PLAN.REQUEST_PREVIEW.TOTAL_REQUEST_FULL_VIEW' | translate}}
                    ({{ header.totalRequests | checkNull: 0 }})
                    <ng-container *ngIf="isHeaderInfoVisible">
                        <br *ngIf="verifyFieldFvcSecure(header)">
                        <span *ngIf="verifyFieldFvcSecure(header)">
                            {{getFvcSecure(header)}}
                        </span>
                        <br>
                        {{ 'BUILD_PLAN.PROTOTYPE_REQUEST.HEADER.FILTERS.SOURCING' | translate}}:
                        {{ header?.sourcing | checkNull }}
                        <br>
                        {{ 'BUILD_PLAN.PROTOTYPE_REQUEST.HEADER.FILTERS.BUILD_DATE' | translate}}:
                        {{ header?.buildDate | parseChineseDate }}
                        <br>
                        {{ 'BUILD_PLAN.PROTOTYPE_REQUEST.HEADER.FILTERS.FVC' | translate}}:
                        {{ header?.configFvc | checkNull }}
                        <br>
                        {{ 'BUILD_PLAN.PROTOTYPE_REQUEST.HEADER.FILTERS.PRODUCT_SKU' | translate}}:
                        {{ header?.productSku | checkNull }}
                        <br>
                        {{ 'BUILD_PLAN.PROTOTYPE_REQUEST.HEADER.FILTERS.BR_NUMBER' | translate}}:
                        {{ header?.brNumber | checkNull }}
                        <br>
                        {{ 'BUILD_PLAN.PROTOTYPE_REQUEST.HEADER.FILTERS.XCVR_KIT' | translate}}:
                        {{ header?.xcvrKit | checkNull }}
                        <br>
                        {{ 'BUILD_PLAN.PROTOTYPE_REQUEST.HEADER.FILTERS.CMF_COLOR' | translate}}:
                        {{ header?.cmfColor | checkNull }}
                    </ng-container>
                </ng-container>
            </ng-template>

            <div class="header-name header-font-12 text-truncate">
                {{header?.name | checkNull}} <br>
                #{{header?.idConfiguration}}
            </div>

            <div class="position-absolute r-0 position-btn-configuration-details"
                (click)="showRequestsDialog(header,$event)">
                <button class="btn-configuration-details" color="primary" mat-icon-button>
                    <mat-icon [ngbTooltip]="labels.DETAILS">loupe</mat-icon>
                </button>
            </div>
            <div class="position-absolute r-1 position-btn-filter-by-only-requests"
                (click)="filterByOnlyRequestsInAConfiguration(header, $event)">
                <button class="btn-filter-by-only-requests" [ngClass]="{filtering: filteringOnlyRequests(header)}"
                    color="primary" mat-icon-button>
                    <mat-icon [ngbTooltip]="labels.FILTER_BY_ONLY_REQUESTS">filter_alt</mat-icon>
                </button>
            </div>

            <div class="m-0 text-truncate">
                <small>{{header?.recipeConfig | checkNull}}</small>
                <ng-container *ngIf="header.totalRequests > 0">
                    <span class="ml-2 header-font-12">
                        ({{ header.totalRequests }})
                    </span>
                </ng-container>
            </div>

            <div class="header-extra-block" *ngIf="isHeaderInfoVisible">
                <div class="m-0" *ngIf="verifyFieldFvcSecure(header)">
                    <small>
                        <i *ngIf="header.fvcSecure" class="fas fa-lock fa-1x"></i>
                        <i *ngIf="!header.fvcSecure" class="fas fa-lock-open fa-1x"></i>
                    </small>
                </div>

                <div class="d-flex">
                    <small>
                        <svg class="svg" viewBox="5 11 20 25" width="18" height="25">
                            <use xlink:href="assets/galvin/icons/source/cellphone_1st.svg#medal"
                                *ngIf="isSourcing(header?.sourcing, 1)"></use>
                            <use xlink:href="assets/galvin/icons/source/cellphone_2nd.svg#medal"
                                *ngIf="isSourcing(header?.sourcing, 2)"></use>
                            <use xlink:href="assets/galvin/icons/source/cellphone_3rd.svg#medal"
                                *ngIf="isSourcing(header?.sourcing, 3)"></use>
                            <use xlink:href="assets/galvin/icons/source/cellphone_4th.svg#medal"
                                *ngIf="isSourcing(header?.sourcing, 4)"></use>
                            <use xlink:href="assets/galvin/icons/source/cellphone_5th.svg#medal"
                                *ngIf="isSourcing(header?.sourcing, 5)"></use>
                        </svg>
                    </small>
                    <small class="my-auto">{{ header?.buildDate | parseDate }}</small>
                </div>
                <div class="d-flex">
                    <small>{{ header?.configFvc | checkNull }}</small>
                    <small>{{ header?.productSku | checkNull }}</small>
                    <small>{{ header?.brNumber | checkNull }}</small>
                </div>
                <div class="d-flex">
                    <small>{{ header?.xcvrKit | checkNull }}</small>
                    <small>
                        <div *ngIf="header?.cmfColor" class="color-circle" [attr.data-color]="header?.cmfColor"></div>
                        {{ header?.cmfColor | checkNull }}
                    </small>
                </div>
            </div>
        </div>

        <div *rowHeaderRef="let rh=item" class="team-data">
            <div>
                <div [ngbTooltip]="rh.teamAlias" >
                    <span>{{rh.teamAlias}}</span>
                </div>
                <div>
                    <ng-template #teamInfo let-team="team">
                        <div class="team-info-container" *ngIf="team">
                            <!-- Tooltip templates  -->
                            <ng-template #tipRequestOwner>
                                <i class="fa fa-user pr-1 request-owner-user-icon"></i>
                                <b>{{'BUILD_PLAN.PROTOTYPE_REQUEST.HEADER.TEAM_SELECTOR.REQUEST_OWNER_USER' | translate }}</b>
                                {{rh.requestOwnerUser.name}} {{rh.requestOwnerUser.lastName}} ({{rh.requestOwnerUser.email}})
                            </ng-template>

                            <ng-template #tipFinalRecipient>
                                <i class="fa fa-user pr-1 final-recipient-user-icon"></i>
                                <b>{{'BUILD_PLAN.PROTOTYPE_REQUEST.HEADER.TEAM_SELECTOR.FINAL_RECIPIENT_USER' | translate }}</b>
                                {{rh.finalRecipientUser.name}} {{rh.finalRecipientUser.lastName}} ({{rh.finalRecipientUser.email}})
                            </ng-template>

                            <ng-container *ngIf="team.requestOwnerUser">
                                <div [ngbTooltip]="tipRequestOwner" class="info-data" >
                                    <div>
                                        <i class="fa fa-user request-owner-user-icon"></i>
                                    </div>
                                    <div>
                                        <div>
                                            <span>{{team.requestOwnerUser.name}} {{team.requestOwnerUser.lastName}}</span>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>

                            <ng-container *ngIf="team.finalRecipientUser">
                                <div [ngbTooltip]="tipFinalRecipient" class="info-data">
                                    <div>
                                        <i class="fa fa-user final-recipient-user-icon"></i>
                                    </div>
                                    <div>
                                        <div>
                                            <span>{{team.finalRecipientUser.name}} {{team.finalRecipientUser.lastName}}</span>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </ng-template>

                    <ng-container [ngTemplateOutlet]="teamInfo"
                    [ngTemplateOutletContext]="{
                        team: {
                            requestOwnerUser: rh.requestOwnerUser,
                            finalRecipientUser: rh.finalRecipientUser
                        }
                    }" >
                    </ng-container>
                </div>
            </div>
            <div>
            <div [ngbTooltip]="rh.teamValues" class="team-values-container">
                    <span>
                        {{rh?.teamValues}}
                    </span>
                </div>
                <div>
                    <ng-template #teamLocation let-team="team">
                        <div class="team-info-container" *ngIf="team">
                            <ng-template #tipCdcLocation>
                                <b>{{'BUILD_PLAN.PROTOTYPE_REQUEST.HEADER.TEAM_SELECTOR.CDC_LOCATION' | translate}} </b>
                                <i class='{{this.getFlag(team.cdcLocation.country)}}'></i> <br>
                                {{team.cdcLocation.city}}
                            </ng-template>

                            <ng-template #tipShipToLocation>
                                <b>{{'BUILD_PLAN.PROTOTYPE_REQUEST.HEADER.TEAM_SELECTOR.SHIP_TO_LOCATION' | translate}}</b>
                                <i class='{{this.getFlag(team.shipToLocation.country)}}'></i> <br>
                                {{team.shipToLocation.city}}
                            </ng-template>

                            <ng-container *ngIf="team.cdcLocation.country">
                                <div [ngbTooltip]="tipCdcLocation" class="info-data">
                                    <div>
                                        <i class='{{this.getFlag(team.cdcLocation.country)}}'></i>
                                    </div>
                                    <div>
                                    <div>
                                            <span>{{team.cdcLocation.city}}</span>
                                    </div>
                                    </div>
                                </div>
                            </ng-container>

                            <ng-container *ngIf="team.shipToLocation.country">
                                <div [ngbTooltip]="tipShipToLocation" class="info-data">
                                    <div>
                                        <i class='{{this.getFlag(team.shipToLocation.country)}}'></i>
                                    </div>
                                    <div>
                                        <div>
                                            <span>{{team.shipToLocation.city}}</span>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </ng-template>

                    <ng-container [ngTemplateOutlet]="teamLocation"
                    [ngTemplateOutletContext]="{
                        team: {
                            cdcLocation: rh.cdcLocation,
                            shipToLocation: rh.shipToLocation
                        }
                    }" >
                    </ng-container>
                </div>
            </div>
        </div>

        <div *cellRef="let item=item;">
            <div class="row m-0 justify-content-center align-items-center" [ngbPopover]="popComment" #popover="ngbPopover"
                [disablePopover]="disableSPRPopover(item)" container="body" placement="right left"
                [popoverClass]="POPOVER_COMMENT_CLASS" (mouseenter)="showSPRPopover(popover)" autoClose="outside"
                triggers="manual" (mouseleave)="closeSPRPopover(popover)">
                <ng-container *ngIf="renderSpecialRequirements(item)">
                    <mat-icon class="d-flex align-items-center special-requirement-icon">flash_on
                    </mat-icon>
                </ng-container>

                <div class="col">
                    {{item?.quantity > 0 ? item.quantity : null}}
                </div>
            </div>
            <ng-template #popComment>
                <popover-special-requirements [data]="item" [showRequestDate]="true"
                    (mouseleave)="closeSPRPopover(popover)">
                </popover-special-requirements>
            </ng-template>
        </div>
    </proto-request-table>
</div>
<fullview-cells-summary [cellsValues]="selectedCellsQuantities" ></fullview-cells-summary>
