import { ReportsI18n } from './reports-i18n.interface';

export const REPORTS: ReportsI18n = {
    REPORTS: {
        CURRENT_LOCATION_CONFIGURATION_BU: 'Current BU vs Location BU vs Configuration',
        FUNCTION_HW_REVISION: 'Function vs HW Revision',
        DEPARTMENT_HW_REVISION: 'Department vs HW Revision',
        BU_HW_REVISION: 'BU vs HW Revision',
        XCVR_LOCATION_BU: 'Current XCVR Kit# vs LocationBU',
        PCBA_XCVR_BU_BUSINESS_LOCATION: 'PCBA/XCVR vs BU+Business Region',
        PCBA_XCVR_BU_CDC_LOCATION: 'PCBA/XCVR vs BU+CDC Location',
        PCBA_XCVR_BU_FINAL_LOCATION: 'PCBA/XCVR vs BU+Final Location',
        EVOLUTION_BREAKDOWN_HW_REVISION: 'Evolution Breakdown - HW Revision',
        EVOLUTION_BREAKDOWN_DEPARTMENT: 'Evolution Breakdown - Department',
        EVOLUTION_BREAKDOWN_FUNCTION: 'Evolution Breakdown - Function',
        EVOLUTION_BREAKDOWN_BUSINESS_UNIT_TYPE: 'Evolution Breakdown - Business UnitType',
        BU_LOCATION_BU_XCVR: 'Current BU vs LocationBU vs XCVR Kit#',
        BU_LOCATION_BU_PCBA: 'Current BU vs LocationBU vs PCBA Kit#',
        FUNCTION_REGION_CATEGORY: 'Function vs Region vs Category',
        SKU_BOARD_SAMPLES_SOURCING: 'SKU vs Board/Samples vs Sourcing',
        ACTIVATION_UPTIME_REPORT: 'Activation/Uptime',
        INFORMATION_ICON: '<strong>Requested:</strong> Total Units requested in Galvin for this Team/Function in this HW Revision.<br><strong>Sorted:</strong> The amount of prototypes that were assigned to an original request. (<a target="_blank" rel="noopener noreferrer" href="https://galvin.motorola.com/#/cdc/prototype-sorting?buildPlan=Velar&product=VELAR24">CDC Sorting</a>)<br><strong>Act/Sorted%:</strong> The Percentage of units Activated (from BQ) the total Sorted in Galvin.<br><strong>Uptime < 10% Act:</strong> The percentage of units that were Activated AND Sorted that has screen uptime less than 10% of the time for a certain HW Revision.<br><strong>Uptime < 25% Act:</strong> The percentage of units that were Activated AND Sorted that has screen uptime less than 25% of the time for a certain HW Revision.<br><br>Check the complete Report Explanation <a target="_blank" rel="noopener noreferrer" href="https://docs.google.com/document/d/1DbYHZErMzlzqVVg5Nqo_wr3zgZEz6arVsA4e9Wh5I94/edit">here</a>.',
        COST_MODEL: 'Cost Model'
    },
    ACCESSORY_REPORTS: {
        FUNCTION_ACCESSORY_TYPE: 'Function vs Accessory',
        DEPARTMENT_ACCESSORY_TYPE: 'Department vs Accessory',
        BU_ACCESSORY_TYPE: 'BU vs Accessory',
        BREAKDOWN_ACCESSORY_TYPE: 'Evolution Breakdown - Accessory',
        BREAKDOWN_ACCESSORY_DEPARTMENT: 'Evolution Breakdown - Department',
        BREAKDOWN_ACCESSORY_TEAM_FUNCTION: 'Evolution Breakdown - Function',
        BREAKDOWN_ACCESSORY_BUSINESS_TYPE: 'Evolution Breakdown - Business Unit Type',
        ACCESSORY_FUNCTION_REGION_CATEGORY: 'Function vs Region vs Category',
        BU_LOCATION_ACCESSORY: 'Current BU vs Location BU vs Accessory'
    },
    CHARTS: {
        FVC_PLANNED_LABEL: 'FVC Planned',
        TOTAL_PLANNED_LABEL: 'Total Planned',
        BUFFER_LABEL: 'Buffer',
        TOOLTIPS: {
            BREAKDOWN_MODE_DEFAULT: 'Prototypes grouped by {{breakdownMode}}'
        }
    },
    FILTERS: {
        REPORT: 'Report',
        BUILD_PLAN: 'Build Plan Name',
        SHOW_REPORT: 'Show Report',
        UNIT_TYPE: 'Unit Type',
        REQUEST_TYPE: 'Request Type',
        FILTER_REPORT: 'Filter Report',
        FILTERS: 'Filters',
        REPORT_CATEGORY: 'Select a report category ',
        HW_REVISION: 'Hw Revision',
        TEAM_NAME: 'Team Name',
        ALL: 'All'
    },
    BU_LOCATION_ACCESSORY: {
        TOTAL_REQUESTS: 'Total Requests',
        SHOW_HIDE_INFO: 'Show/Hide info'
    },
    CURRENT_LOCATION_CONFIGURATION_BU: {
        TOTAL_REQUESTS: 'Total Portables',
        SHOW_HIDE_INFO: 'Show/Hide info'
    },
    CURRENT_LOCATION_CONFIGURATION_BU_BOARD: {
        TOTAL_BOARDS: 'Total Boards',
        SHOW_HIDE_INFO: 'Show/Hide info',
    },
    TEAM_VALUE_HW_REVISION: {
        FUNCTION: 'Function',
        DEPARMENT: 'Department',
        BU: 'BU'
    },
    BU_LOCATION_BU_XCVR_PCBA: {
        CURRENT_BU: 'Current BU',
        LOCATION_BU: 'Location BU',
        TOTAL_PROTOS: 'Total Protos',
        TOTAL_PORTABLES: 'Total Portables',
        TOTAL_BOARDS: 'Total Boards',
        TOTAL_ALL_BUSINESS_NO_BUFFER: 'Total business (Region with no buffer)',
        TOTAL_ALL_BUSINESS_ADD_BUFFER: 'Total business (Region + Buffer")'
    },
    FVC_VALUE: {
        HW_REVISION: 'Hw Revision',
        XCVR: 'XCVR Kit #',
        PCBA: 'PCBA Kit #',
        PCBA_XCVR: 'PCBA/XCVR Kit#',
        CDC_LOCATION: 'CDC Location',
        FINAL_LOCATION: 'Final Location'
    },
    COMMON: {
        TOTAL: 'TOTAL',
        GRAND_TOTAL: 'Grand Total no buffer',
        GRAND_TOTAL_WITH_BUFFER: 'Grand Total + Buffer',
        HAS_CANCELLED_CONFIGS:
            'This Build Plan contains canceled configurations that are not included in the final report',
        HAS_CANCELLED_ACCS:
            'This Build Plan contains canceled accessories that are not included in the final report',
        EXPORT_BUTTON: 'Export to XLSX',
        PROCESSING_DOWNLOAD:
            '<i class="ml-1 fas fa-spinner fa-spin"></i> The XLSX file is being created, please wait',
        NO_DATA: 'No report data found for the selected Build Plan',
        SHOW_HIDE_INFO: 'Show/Hide info',
        BUFFER: 'BUFFER',
        PRODUCT_CATEGORY: 'Product Category',
        TOTAL_UNITS: 'Total Units',
        SOURCE_TOTAL_1ST_2ND: '1st/2nd source total',
        NO_DATA_FILTER: 'No report data found for the selected Build Plan with these filters.'
    }
};
