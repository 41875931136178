import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IConfigurationI18n } from '@galvin/core/build-plan/configuration-management/i18n/configuration-i18n.interface';
import { ISuggestAliasInterface } from '@galvin/core/_base/layout';
import { TranslateService } from '@ngx-translate/core';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource } from '@angular/material/table';

interface IDialogUpdateTeamAlias {
    id: number,
    teamAlias: string,
    doUpdate: boolean
}

@Component({
    selector: 'suggest-alias-list-component',
    templateUrl:'./suggest-alias-list.component.html',
    styleUrls: ['./suggest-alias-list.component.scss']
})
export class SuggestAliasListComponent implements OnInit {
    public labels!: IConfigurationI18n;
    private updateData: IDialogUpdateTeamAlias[] = [];
    private readonly ENABLE_ALL_BUTTONS_MIN_VALUE : number = 1;
    displayedColumns: string[] = [ 'select', 'rowId', 'currentDataFromAlias', 'dataToSuggest'];
    public selectionConfigurationRow = new SelectionModel<number>(true, []);
    configurationsList: Array<number> = [];
    title: string = '';
    dataSource: MatTableDataSource<any> = new MatTableDataSource<ISuggestAliasInterface>([]);

    constructor(
        public dialogRef: MatDialogRef<SuggestAliasListComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ISuggestAliasInterface[],
        private i18n: TranslateService
    ) {}

    ngOnInit(): void {
        this.labels = this.i18n.instant(
            'BUILD_PLAN.BUILD_PLAN_MANAGEMENT.CONFIGURATION_MANAGEMENT'
        );
        this.title = this.labels.SUGGEST_ALIAS.TITLE.replace('{{columnToUpdate}}', this.data[0].columnToUpdate);
        this.configurationsList = this.data.map(
            (configurations) => configurations.rowID
        ) as number[];
        this.dataSource = new MatTableDataSource<ISuggestAliasInterface>(this.data);
        this.masterConfigToggleCheckBox(true);
    }

    checkToDisabled(item: ISuggestAliasInterface):boolean {
        return item.currentDataFromAlias.length == 0;
    }

    checkToShowAll():boolean {
        return this.data.length > this.ENABLE_ALL_BUTTONS_MIN_VALUE;
    }

    onButtonUpdateAll(): void {
        if(this.data){
            for (let index = 0; index < this.selectionConfigurationRow.selected.length; index++) {

                const dataSelected = this.data.filter(item => this.selectionConfigurationRow.selected.includes(item.rowID!));

                this.onChooseOptionWithoutCheckClose(dataSelected[index], true);
            }
            this.dialogRef.close(this.updateData);
        }
    }

    hasItemSelected(): boolean {
        return this.selectionConfigurationRow.selected.length > 0;
    }

    hasEmptyItem(): Boolean {
        return this.data.filter(item => item.dataToSuggest == '').length > 0;
    }

    private onChooseOptionWithoutCheckClose(item: ISuggestAliasInterface, chosenUpdateOpt: boolean) {
        const newUpdateItem : IDialogUpdateTeamAlias = {
            id: item.rowID!,
            teamAlias: item.dataToSuggest,
            doUpdate: chosenUpdateOpt
        };
        this.updateData.push(newUpdateItem);
    }

    public closeDialog() {
        this.dialogRef.close();
    }

    /**
     * Add checked configurations at configuration step to a list
     * @param id
     */
    selectConfigurationId(id: number): void {
        this.selectionConfigurationRow.toggle(id);
    }

    /** Select all rows if they are not all selected; otherwise clear selection. */
    masterConfigToggleCheckBox(checkedAll: boolean): void {
        if (checkedAll) {
            this.selectionConfigurationRow.select(...this.configurationsList);
        } else {
            this.selectionConfigurationRow.clear();
        }
    }

    /** Whether the number of selected elements matches the total number of rows. */
    isConfigurationsAllSelected(): boolean {
        const numSelected = this.selectionConfigurationRow.selected.length;
        const numRows = this.data.length;
        return numSelected === numRows;
    }
}
