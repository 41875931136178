import { CdkDragDrop, CdkDragStart } from '@angular/cdk/drag-drop';
import { MultiSelect } from './multi-select';

export class MultiDrag {
    // Adjusts clicked items that have ".selected-drag" to organize together
    // Initial Variables
    dragList = ['']; // has the value of the selected items in sequence from listData
    dragListCopy = ['']; // a copy of the listData but with the selected elements marked with "DragErase" to delete later
    dragErase = Symbol('DragErase') as any; // a symbol to have unique value when deleting

    constructor(public multiSelect: MultiSelect) {}

    dragStarted(e: CdkDragStart, index: number): void {
        if (this.multiSelect.selectedDrag.isSelected(index)) {
            // If the dragged element has ".selected-drag"
            //prepare
            const listData = e.source.dropContainer.data; // get list data value
            this.dragList = []; // reset the dragList
            this.dragListCopy = [...listData]; // copy listData into variable
            const DOMdragEl = e.source.element.nativeElement; // dragged element
            const DOMcontainer = Array.from(DOMdragEl.parentElement!.children); // container where all draggable elements are
            const DOMdragElIndex = DOMcontainer.indexOf(DOMdragEl); // index of the dragged element
            const allSelected = document.querySelectorAll('.selected-drag'); // get all the ".selected-drag"

            // Goes through all ".selected-drag"
            allSelected.forEach((eli) => {
                // get index of current element
                const CurrDOMelIndexi = DOMcontainer.indexOf(eli);

                // Add listData of current ".selected-drag" to dragList
                this.dragList.push(listData[CurrDOMelIndexi]);

                // Replaces current position in dragListCopy with "DragErase" (to erase exact position later)
                this.dragListCopy[CurrDOMelIndexi] = this.dragErase;

                // Put opacity effect (by CSS class ".hide") on elements (after starting Drag)
                if (DOMdragElIndex !== CurrDOMelIndexi) {
                    eli.classList.add('hide');
                }
            });
        }
    }

    dropListDropped(e: CdkDragDrop<string[]>): void {
        if (e.previousContainer === e.container) {
            // If in the same container

            const posAdjust = e.previousIndex < e.currentIndex ? 1 : 0; // Adjusts the placement position
            this.dragListCopy.splice(e.currentIndex + posAdjust, 0, ...this.dragList); // put elements in dragListCopy
            this.dragListCopy = this.dragListCopy.filter((el) => el !== this.dragErase); // remove the "DragErase" from the list

            // Pass item by item to final list
            for (let i = 0; i < e.container.data.length; i++) {
                e.container.data[i] = this.dragListCopy[i];
            }
        } else {
            // If in different containers

            // remove the "DragErase" from the list
            this.dragListCopy = this.dragListCopy.filter((el) => el !== this.dragErase);

            // Pass item by item to initial list
            for (let i = 0; i < e.previousContainer.data.length; i++) {
                e.previousContainer.data[i] = this.dragListCopy[i];
            }
            for (let i = 0; i < this.dragList.length; i++) {
                e.previousContainer.data.pop();
            }

            const otherListCopy = [...e.container.data]; // list of new container
            otherListCopy.splice(e.currentIndex, 0, ...this.dragList); // put elements in otherListCopy

            // Pass item by item to final list
            for (let i = 0; i < otherListCopy.length; i++) {
                e.container.data[i] = otherListCopy[i];
            }
        }

        // Remove ".hide"
        const allHidden = document.querySelectorAll('.hide');
        allHidden.forEach((el) => {
            el.classList.remove('hide');
        });
        // Remove ".selected-drag" after 300ms
        setTimeout(() => {
            this.multiSelect.selectedDrag.clear();
        }, 300);

        this.dragListCopy = []; // reset the dragListCopy
        this.dragList = []; // reset the dragList
    }
}
