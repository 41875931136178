// Angular
import { ChangeDetectorRef, Component, HostListener, OnInit } from '@angular/core';
// Layout
import { ChatbotSidePanelService, LayoutConfigService, MenuAsideService, ToggleOptions } from '../../../../core/_base/layout';
import { ToolbarService } from '../../services/toolbar.service';

@Component({
    selector: 'kt-header-fullscreen',
    templateUrl: './header-fullscreen.component.html',
    styleUrls: ['./header-fullscreen.component.scss']
})
export class HeaderFullScreenComponent implements OnInit {
    // Public properties
    headerLogo!: string;
    asideDisplay!: boolean;
    clickforfullscreen: string = 'Show the menus (CTRL+SHIFT+F)';
    clickforCollpaseSideBar: string = 'Collapse side menu';
    clickforToggleChatbot: string = 'Toggle AI assistant panel';
    toggleOptions: ToggleOptions = {
        target: 'body',
        targetState: 'kt-header__topbar--mobile-on',
        togglerState: 'kt-header-mobile__toolbar-topbar-toggler--active'
    };


    toggleOptionsMenu: ToggleOptions = {
        target: 'body',
        targetState: 'kt-aside--minimize',
        togglerState: 'kt-aside__brand-aside-toggler--active'
    };



    /**
     * Component constructor
     *
     * @param layoutConfigService: LayoutConfigService
     */
    constructor(private layoutConfigService: LayoutConfigService,
             public toolbarService:ToolbarService,
             private changeDetector: ChangeDetectorRef,
             private sidebarService: MenuAsideService,
             private chatbotSidePanelService: ChatbotSidePanelService
    ) {}

    /**
     * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
     */

    /**
     * On init
     */
    ngOnInit() {
        this.headerLogo = this.layoutConfigService.getStickyLogo();
        this.asideDisplay = this.layoutConfigService.getConfig('aside.self.display');
    }

    closeFullScreen(){
        document.body.classList.remove('page-fullscreen');
        this.toolbarService.setFullScreen(false);
        this.changeDetector.detectChanges();

    }

    @HostListener('window:keydown', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) {
        if (event.ctrlKey && event.shiftKey && event.key === 'F' ) {
            this.closeFullScreen();
        }
    }

    /**
     * Called whenever the sidebar is shown/hidden
     */
    onSidebarToggle() {
        // The timeout accounts for the sidebar open/close animation
        this.changeDetector.detectChanges();
        setTimeout(() => {
            this.sidebarService.sidebarOpenCloseChanged(!this.sidebarService.isSidebarOpen);
        }, 500);
    }

    /**
     * Called whenever the chatbot side panel is shown/hidden
     */
    onChatbotSidePanelToggle() {
        this.chatbotSidePanelService.sidePanelOpenCloseChanged(!this.chatbotSidePanelService.isSidePanelOpen);
    }
}
