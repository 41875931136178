import {Pipe, PipeTransform} from '@angular/core';
import { IAccessoryRequestTeam } from '@galvin/core/build-plan/accessory-request-management/interface/accessory-request-team.interface';

@Pipe({
    name: 'filterMyAccessory'
})
export class FilterMyAccessoryPipe implements PipeTransform {

    transform(
        value: IAccessoryRequestTeam[],
        filterFn: (v: IAccessoryRequestTeam[]) => IAccessoryRequestTeam[],
        additionalCondition = true
    ): unknown[] {
        if (value && additionalCondition) {
            return filterFn.apply(this, [value]);
        }
        return value;
    }

}
