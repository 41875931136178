import { Directive, ElementRef, HostBinding, Input } from '@angular/core';

@Directive({
    selector: '[diffOriginal]'
})
export class DiffDirective {
    constructor(private elRef: ElementRef<HTMLElement>) {}

    get element(): HTMLElement {
        return this.elRef.nativeElement;
    }

    @HostBinding('attr.data-original')
    private _diffOriginal = 0;

    @HostBinding('class')
    private className = 'diff';

    @Input()
    set diffOriginal(v: number) {
        if (v != undefined) {
            this._diffOriginal = v;
        }
    }

    @HostBinding('attr.data-new')
    private _diffNew = 0;

    @Input()
    set diffNew(v: number) {
        if (v != undefined) {
            this._diffNew = v;
        }
    }

    @HostBinding('class.galvin-icon-down')
    get isDecreased(): boolean {
        return this._diffOriginal > this._diffNew;
    }

    @HostBinding('class.galvin-icon-up')
    get isIncreased(): boolean {
        return !this.isDecreased;
    }

    @HostBinding('style.color')
    get getDiffColor() {
        return this.isDecreased ? 'red' : 'green';
    }
}
