export enum EBuildPlanStatusColor {
    NEW = 'build-plan-pending-requirements',
    PENDING_APPROVAL = 'build-plan-under-approval',
    APPROVED = 'build-plan-open',
    OPEN = 'build-plan-open',
    PARTIALLY_OPEN = 'build-plan-open',
    LOCKED = 'build-plan-locked',
    DPM_PENDING_APPROVAL = 'build-plan-under-approval',
    DPM_APPROVED = 'build-plan-open',
    BUILDING = 'build-plan-building',
    COMPLETED = 'build-plan-completed',
    ARCHIVED = 'build-plan-locked',
    CANCELED = 'build-plan-canceled'
}
export type EBuildPlanStatusColorkey = keyof typeof EBuildPlanStatusColor;
