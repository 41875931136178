// Angular
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
// Interfaces & Enums
import { IListBuildPlanApprovals } from '../';
// Enviroments
import { environment } from '../../../../../../environments/environment';
import { IBuildPlanApprovals } from '../interfaces/approvals.interface';
import { IApprovalsSave } from './../interfaces/approvals-save.interface';

@Injectable({
    providedIn: 'root'
})
export class BuildPlanApprovalsService {
    isPendingApprovals: Subject<boolean> = new Subject<boolean>();
    private readonly URL_BUILD_PLAN = 'build-plan/build-plan-management';
    private readonly API_BUILD_PLAN_URL = `${environment.baseUrlApi}/` + this.URL_BUILD_PLAN;

    constructor(private http: HttpClient) {}

    /**
     * Get All Build Plan Approvals
     */
    getAllApprovals(idBuildPlan: number): Observable<IListBuildPlanApprovals[]> {
        return this.http
            .get<IListBuildPlanApprovals[]>(`${this.API_BUILD_PLAN_URL}/${idBuildPlan}/approvals`)
            .pipe(
                map((response: any) => {
                    return response.content;
                })
            );
    }

    /**
     * Update Approval trough approvalType
     * @param idBuildPlan
     * @param approvalType
     */
    updateApproval(idBuildPlan: number, body: IApprovalsSave): Observable<IBuildPlanApprovals> {
        return this.http
            .put<IBuildPlanApprovals>(`${this.API_BUILD_PLAN_URL}/${idBuildPlan}/approvals`, body)
            .pipe(
                map((response: any) => {
                    return response.content;
                })
            );
    }
}
