<div class="prototype-request-form-content d-flex flex-wrap justify-content-between">
    <section class="request-quantity mx-2">
        <h3>
            {{'BUILD_PLAN.PROTOTYPE_REQUEST.PROTO_REQUEST_TABLE.MASS_EDIT_REQUEST' | translate:i18nMassEditRequestParam}}
        </h3>
        <div class="d-flex">


            <div class="request-quantity-wrapper d-flex">
                <input [formControl]="quantity" class="request-quantity-input" id="request-form-quantity-input" matInput
                    min="0" type="number">
                <div class="actions col d-flex align-items-center">
                    <button (click)="onDone()" [disabled]="disabled || !isDoneEnabled" class="set-button"
                        color="primary" mat-button>
                        <mat-icon>done</mat-icon>
                        {{'BUILD_PLAN.PROTOTYPE_REQUEST.PROTO_REQUEST_TABLE.SET' | translate}}
                    </button>
                </div>
            </div>
            <div class="request-quantity-wrapper">
                <div class="actions col d-flex align-items-center">
                    <button (click)="onReduce()" [disabled]="disabled" class="reduce-button" color="primary" mat-button>
                        <mat-icon>remove</mat-icon>
                        {{'BUILD_PLAN.PROTOTYPE_REQUEST.PROTO_REQUEST_TABLE.REDUCE' | translate}}
                    </button>
                    <button (click)="onIncrease()" [disabled]="disabled" class="increase-button" color="primary"
                        mat-button>
                        <mat-icon>add</mat-icon>
                        {{'BUILD_PLAN.PROTOTYPE_REQUEST.PROTO_REQUEST_TABLE.INCREASE' | translate}}
                    </button>
                </div>
            </div>
            <div class="request-quantity-wrapper">
                <button (click)="undoAllChanges()" [disabled]="!hasChanges" class="undo-all-button" color="primary"
                    mat-button>
                    <mat-icon>undo</mat-icon>
                    {{'BUILD_PLAN.PROTOTYPE_REQUEST.PROTO_REQUEST_TABLE.UNDO_ALL' | translate}}
                </button>
            </div>
        </div>
    </section>
    <ng-container [formGroup]="requestForm">
        <section class="justification-options col">
            <!-- OVERWRITE JUSTIFICATION CHECKBOX  -->
            <div class="justification-overwrite-checkbox"
            [ngbTooltip]="!canOverwriteJustifications ? tooltipDisabledBecauseChangedInReview : null"
            >
                <div>
                    <ng-template #overwriteTooltip>
                        {{getOverwriteTooltipAndIcon().tooltip | translate}}
                    </ng-template>

                    <div [ngbTooltip]="overwriteTooltip">
                        <button
                        mat-icon-button
                        [disabled]="!hasOriginalFilledCellsSelected() || !isOverwriteAllEnabled()"
                        (click)="cellsSelected() ? removeRequests() : addRequests()" >
                            <mat-icon class="overwrite-icon" >
                                {{getOverwriteTooltipAndIcon().icon}}
                            </mat-icon>
                    </button>
                    </div>

                    <ng-template #overwriteAllLabel>
                        {{'BUILD_PLAN.PROTOTYPE_REQUEST.PROTO_REQUEST_TABLE.OVERWRITE_REQUESTS_JUSTIFICATION_SPECIAL_REQUIREMENTS_AND_COMMENTS' | translate}}
                    </ng-template>
                    <div class="overwrite-checkbox-container">
                        <mat-checkbox #overwriteAll="matCheckbox"
                        (change)="onJustificationChange($event.checked,bottomSheetJustificationsContainer)"
                        class="overwrite-all" formControlName="overwriteAll"
                        [ngbTooltip]="overwriteAllLabel"
                        >
                        </mat-checkbox>
                        <ng-container *ngTemplateOutlet="overwriteAllLabel"></ng-container>
                    </div>
                    <ng-template #tooltipEditJustificationAndSpecialRequirements>
                        {{'BUILD_PLAN.PROTOTYPE_REQUEST.PROTO_REQUEST_TABLE.EDIT_JUSTIFICATION_AND_SPECIAL_REQUIREMENTS' | translate}}
                    </ng-template>
                    <ng-template #tooltipEditJustification>
                        {{'BUILD_PLAN.PROTOTYPE_REQUEST.PROTO_REQUEST_TABLE.EDIT_JUSTIFICATION' | translate}}
                    </ng-template>
                    <ng-template #tooltipDisabledBecauseChangedInReview>
                        {{'BUILD_PLAN.PROTOTYPE_REQUEST.PROTO_REQUEST_TABLE.DISABLED_BECAUSE_CHANGED_IN_REVIEW' | translate}}
                    </ng-template>
                    <button
                    [disabled]="!overwriteAll.checked || !commentControl.enabled"
                    (click)="overwriteAll.checked && openToEdit(bottomSheetJustificationsContainer)"
                    class="galvin-btn-success edit-button"
                    [ngbTooltip]="hideSpecialRequiments ? tooltipEditJustification : tooltipEditJustificationAndSpecialRequirements"
                    color="success"
                    mat-flat-button
                    >
                        <mat-icon>
                            edit
                        </mat-icon>
                    </button>
                </div>

                <div class="fullview-cells-summary-wrapper">
                    <fullview-cells-summary [cellsValues]="getRequestsQuantities()" ></fullview-cells-summary>
                </div>
            </div>
            <div class="d-flex flex-fill">
                <!-- JUSTIFICATION AND SPECIAL REQUIREMENTS LIST -->
                <div class="d-flex col-6 flex-column align-items-end">
                    <div class="flex-fill justification-n-special-requirement">
                        <div class="justification-section col" >
                            <ng-container *ngIf="hasJustification()">
                                <b>
                                    {{'BUILD_PLAN.PROTOTYPE_REQUEST.DIALOG.LABELS.JUSTIFICATION.JUSTIFICATION' | translate}}</b>
                                <ul>
                                    <ng-container *ngFor="let justification of justificationsChecked">
                                        <li class="text-truncate">{{justification}}</li>
                                    </ng-container>
                                </ul>
                            </ng-container>
                        </div>
                        <div [hidden]="hideSpecialRequiments" class="special-requirement-section col" *ngIf="hasSpecialRequirements()">
                            <b>{{'BUILD_PLAN.PROTOTYPE_REQUEST.PROTO_REQUEST_TABLE.SPECIAL_REQUIREMENTS' | translate}}</b>
                            <ul *ngIf="overwriteAll.checked && canOverwriteJustifications">
                                <ng-container *ngFor="let specialRequirement of specialRequirements">
                                    <li class="text-truncate" [ngbTooltip]="SRContent">
                                        <ng-container [ngTemplateOutlet]="SRContent"></ng-container>
                                    </li>
                                    <ng-template #SRContent>
                                        <b>{{ specialRequirement.specialRequirement.fieldName}}:</b>
                                        {{ specialRequirement.value}}
                                    </ng-template>
                                </ng-container>
                            </ul>
                        </div>
                    </div>
                </div>
                <!-- ADDITIONAL COMMENT AND FORWARD BUTTON -->
                <div class="comment-n-forward d-flex justify-content-end flex-fill">
                    <mention-comment
                        [defaultText]="commentMention"
                        [editable]="isEnabledMention"
                        [nameCreateBy]="loggedUser"
                        [usersToNotify]="usersToNotify"
                        [placement]="'top-left'"
                        (applyActions)="applyComment($event)"
                        style="width: 70%;">

                        <div class="d-flex flex-fill align-items-center"
                            [ngbTooltip]="!canOverwriteJustifications ? tooltipDisabledBecauseChangedInReview : null">

                            <mat-form-field class="flex-fill" appearance="outline">
                                <div [ngClass]="getClassByAdditionalComment()"></div>
                                    <input matInput style="margin: -10px 0px 6px 0px;" [value]="commentMention" [readonly]="true" id="request-form-comment-input"
                                        placeholder="{{'BUILD_PLAN.PROTOTYPE_REQUEST.PROTO_REQUEST_TABLE.WRITE_YOUR_COMMENTS' | translate}}">
                            </mat-form-field>

                        </div>

                    </mention-comment>

                    <button id="request-review-button" class="galvin-btn-primary" [disabled]="!hasChanges" (click)="goToRequestReview()"
                        color="primary" mat-button>
                        {{'BUILD_PLAN.PROTOTYPE_REQUEST.PROTO_REQUEST_TABLE.REQUEST_AND_REVIEW' | translate}}
                        <mat-icon>arrow_forward</mat-icon>
                    </button>
                </div>

            </div>
        </section>
    </ng-container>
</div>

<ng-template #bottomSheetJustificationsContainer>
    <div class="bottom-sheet-container">
        <div class="dismiss-bar">
            <mat-icon (click)="dismissSheet()">expand_more</mat-icon>
        </div>
        <kt-portlet [formGroup]="$any(justificationFormGroup)">
            <kt-portlet-header [class]="'kt-portlet__head--lg justify-content-between'">
                <h3 class="kt-portlet__head-title" ktPortletTitle>
                    {{'BUILD_PLAN.PROTOTYPE_REQUEST.DIALOG.LABELS.JUSTIFICATION.JUSTIFICATION' | translate}}
                </h3>
            </kt-portlet-header>
            <kt-portlet-body>
                <div class="justifications-container">
                    <ng-container *ngFor="let j of justificationOptions;">
                        <mat-checkbox (change)="updateJustifications()" [formControlName]="$any(j.key)" color="primary">
                            {{j.description | translate}}</mat-checkbox>
                    </ng-container>
                </div>
            </kt-portlet-body>
        </kt-portlet>
        <div [hidden]="hideSpecialRequiments">

            <kt-portlet>
                <kt-portlet-header [class]="'kt-portlet__head--lg justify-content-between'">
                    <h3 class="kt-portlet__head-title" ktPortletTitle>
                        {{ 'BUILD_PLAN.PROTOTYPE_REQUEST.DIALOG.LABELS.SPECIAL_REQUIREMENT' | translate }}
                    </h3>
                </kt-portlet-header>
                <kt-portlet-body>
                    <special-requirement-form
                        (specialRequirementsChanges)="$event.valid && onSpecialRequirementsChange($event.specials)"
                        [specialRequirementsList]="specialRequirements"></special-requirement-form>
                </kt-portlet-body>
            </kt-portlet>
        </div>
    </div>
</ng-template>