import { Injectable } from '@angular/core';
import { environment } from '@galvin/../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ITeamParameters } from '@galvin/core/build-plan/field-value-management/team-parameters';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ResourceService } from '@galvin/core/_base/resource';
import { TeamResource } from '@galvin/core/build-plan/field-value-management/team-parameters/services/team-resource';
import { IFieldValue } from '../..';
import { IDeletableValue } from '@galvin/core/build-plan/field-value-management/fvc-parameters/interfaces/deletable-parameter.interface';
import { IBpValueAssociation, IUpdateIds } from '../../interfaces/field-value.interface';
import { ITeamManagementValueAssociation } from '../interfaces/team-values-associations.interface';

@Injectable({
    providedIn: 'root'
})
export class TeamParameterService extends ResourceService {
    private teamResource = new TeamResource();

    constructor(private httpClient: HttpClient) {
        super(httpClient);
    }

    private _path = 'build-plan/field-values-management/teams/parameters';

    get path() {
        return this._path;
    }

    private _baseURI = `${environment.baseUrlApi}/${this._path}`;

    get baseURI() {
        return this._baseURI;
    }

    /**
     * Get All Team Parameters(Teams)
     */
    getAllParametersWithValues(): Observable<ITeamParameters[]> {
        return this.httpClient.get<any>(`${this._baseURI}/values`).pipe(map((res) => res.content));
    }

    /**
     * Get All Team Parameters(Teams)
     */
    getAllParameters(): Observable<ITeamParameters[]> {
        return this.httpClient.get<any>(`${this._baseURI}`).pipe(map((res) => res.content));
    }

    getParameterByIdWithValues(id?: number): Observable<ITeamParameters> {
        return this.httpClient
            .get<any>(`${this._baseURI}/${id}/values`)
            .pipe(map((res) => res.content));
    }

    /**
     * Get All Team Parameters Enabled for Group Managers, Requestors and Build Plan Admins
     */
    allTeamParametersEnabled(): Observable<ITeamParameters[]> {
        return this.httpClient.get<any>(`${this._baseURI}/enabled`).pipe(map((res) => res.content));
    }

    /**
     * Update a TeamParameter with values optionally
     * @param teamParameter ITeamParameters
     */
    updateParameter(teamParameter: ITeamParameters): Observable<ITeamParameters> {
        const updateURI = `${this._baseURI}/${teamParameter.id}`;
        return this.httpClient.put<any>(updateURI, teamParameter).pipe(map((res) => res.content));
    }

    /**
     * Create a Parameter with values Optionally
     * @param teamParameter
     */
    createParameter(teamParameter: ITeamParameters): Observable<ITeamParameters> {
        const createURI = `${this._baseURI}`;
        return this.httpClient.post<any>(createURI, teamParameter).pipe(map((res) => res.content));
    }

    /**
     * Update a TeamParameter Required Status (Enabled/ Disabled)
     */
    updateFvcParameterRequiredStatus(id: number): Observable<ITeamParameters> {
        const createURI = `${this._baseURI}`;
        return this.httpClient
            .put<any>(`${createURI}/${id}/update-team-required-status`, null)
            .pipe(map((res) => res.content));
    }

    /**
     * Update a FVC Status (Enabled/ Disabled)
     */
    updateFvcParameterStatus(id: number): Observable<ITeamParameters> {
        const createURI = `${this._baseURI}`;

        return this.httpClient
            .put<any>(`${createURI}/${id}/update-team-status`, null)
            .pipe(map((res) => res.content));
    }

    /**
     * validates if team name exists
     * @param name name team.
     * @param id id team if exists.
     */
    public getByNameUsingGET(name: string, id?: number): Observable<any> {
        this.teamResource._links.self = {
            href: `/${this.path}/name/${encodeURIComponent(String(name))}`
        };
        let queryParameters = new HttpParams();
        if (id) {
            queryParameters = queryParameters.set('id', String(id));
        }
        return this.get<ITeamParameters, TeamResource>(this.teamResource, {
            params: queryParameters
        }).pipe(map((value) => value.content));
    }

    /**
     * Get a list of deletable team parameters.
     */
    getDeletableParameters(): Observable<IDeletableValue[]> {
        this.teamResource._links.self = {
            href: `/${this.path}/can-delete`
        };
        return this.get<IDeletableValue, TeamResource>(this.teamResource).pipe(
            map((res) => res.content)
        );
    }

    /**
     * Delete a team and all values if not used.
     */
    deleteTeamParameter(teamParameterId: number): Observable<any> {
        this.teamResource._links.self = {
            href: `/${this.path}/${teamParameterId}`
        };
        return this.delete(this.teamResource);
    }

    /**
     * Get deletable values.
     */
    getDeletableValues(parameter: number): Observable<IDeletableValue> {
        return this.httpClient
            .get<any>(`${this._baseURI}/${parameter}/values/can-delete`)
            .pipe(map((res) => res.content));
    }

    /**
     * Delete a value by value id.
     */
    deleteTeamValue(value: number): Observable<void> {
        return this.httpClient.delete<any>(`${this._baseURI}/values/${value}`);
    }

    /**
     * Get BP Team Values Associations by team value ID
     */
    getBpTeamAssociations(parameterId: number, valueId: number): Observable<IBpValueAssociation> {
        return this.httpClient
            .get<any>(
                `${this._baseURI}/${parameterId}/values/${valueId}/build-plans/team-associations`
            )
            .pipe(map((res) => res.content));
    }

    /**
     * Get Teams Management Associations by team value ID
     */
    getTeamManagementAssociations(
        parameterId: number,
        valueId: number
    ): Observable<ITeamManagementValueAssociation> {
        return this.httpClient
            .get<any>(`${this._baseURI}/${parameterId}/values/${valueId}/teams-management`)
            .pipe(map((res) => res.content));
    }

    /**
     * Updates BP Team Associations
     */
    updateBpTeamAssociations(
        parameterId: number,
        valueId: number,
        data: IUpdateIds
    ): Observable<void> {
        return this.httpClient.put<any>(
            `${this._baseURI}/${parameterId}/values/${valueId}/build-plans/team-associations`,
            data
        );
    }

    /**
     * Updates BP & Team Managment Alias
     */
    updateBpAndTeamManagementAssociations(
        parameterId: number,
        valueId: number,
        data: IUpdateIds
    ): Observable<void> {
        return this.httpClient.put<any>(
            `${this._baseURI}/${parameterId}/values/${valueId}/team-alias/teams-management/bp-team-associations`,
            data
        );
    }

    /**
     * Update status of value from a parameter  (Enabled/ Disabled)
     */
    updateTeamValueStatus(parameterId: number, valueId: number): Observable<ITeamParameters> {
        return this.httpClient
            .put<any>(`${this._baseURI}/${parameterId}/values/${valueId}/update-status/`, null)
            .pipe(map((res) => res.content));
    }

    /**
     * Update value by id parameter
     */
    updateTeamValue(parameterId: number, fieldValue: IFieldValue): Observable<IFieldValue> {
        return this.httpClient
            .put<any>(`${this._baseURI}/${parameterId}/values/${fieldValue.id}`, fieldValue)
            .pipe(map((res) => res.content));
    }

    /**
     * add value by id parameter
     */
    addTeamValue(parameterId: number, fieldValue: IFieldValue): Observable<ITeamParameters> {
        return this.httpClient
            .post<any>(`${this._baseURI}/${parameterId}/values`, [fieldValue])
            .pipe(map((res) => res.content));
    }
}
