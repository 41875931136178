import {Directive, TemplateRef} from '@angular/core';

@Directive({
    selector: '[groupHeaderRef]'
})
export class GroupHeaderRefDirective {

    constructor(public template: TemplateRef<any>) {
    }

}
