import { CDCSortingI18n } from './sorting-i18n.interface';

export const CDC_SORTING: CDCSortingI18n = {
    SORTING_BP_REQUESTS: 'Prototype Sorting',
    ACCESSORY_TRACKER_TITLE: 'Accessory Tracker',
    SCAN_MODE_ACTIVE: 'Scan Mode is Active',
    FILTERS: {
        BUILD_PLAN: 'Build Plan',
        CONFIGURATIONS: 'Configurations',
        ACCESSORY: 'Accessory',
        FVCS: 'FVCs',
        TEAM: 'Teams',
        REQUEST_OWNERS: 'Request Owners',
        FINAL_RECIPIENTS: 'Final Recipients',
        CDC_LOCATION: 'CDC Location',
        SHIP_TO_LOCATION: 'Ship to Location',
        SORTED: 'Sorted',
        UNSORTED: 'Unsorted',
        HAS_SPECIAL_REQUIREMENTS: 'Has Special Requirements',
        INCLUDE_NO_CONFIGURATION: 'Include entries with no configuration',
        INCLUDE_NO_ACCESSORY: 'Include entries with no accessory',
        INCLUDE_NO_TEAM: 'Include entries with no team/location',
        UPARTS_PRODUCT: 'uParts Product',
        UPARTS_PART_NUMBER: 'uParts Part Numbers',
        YES: 'Yes',
        NO: 'No',
        APPLY_FILTERS: 'Apply Filters',
        ALL: 'All',
        UNDO: 'Undo',
        UNDO_TOOLTIP: 'You have filter changes that have not been applied. Do you wish to undo those changes?',
        HAS_REQUIREMENTS: 'Has Requirements',
        NO_REQUIREMENTS: 'No Requirements',
        SELECT_FILTERS: 'Select Filters',
        MISSING_SELECTION: 'A required filter is missing',
        ALL_CONFIGURATIONS: 'All Configurations',
        ALL_ACCESSORY: 'All Accessory',
        ALL_LOCATIONS: 'All Locations',
        ALL_PART_NUMBERS: 'All Part Numbers',
        ALL_PRODUCTS: 'All Products',
        SEARCH_REQUEST: 'Search Request',
        BUILD_PLAN_FILTERS: 'Build Plan Filters',
        UPARTS_FILTERS: 'uParts Filters',
        FILTER_BY: 'Filter By'
    },
    TABLE: {
        NO_DATA: 'No data found for the selected filters',
        NO_FILTERS: 'No filters have been selected',
        CDC_TRACKING_GROUP: 'CDC Tracking Info',
        TEAM: 'Team',
        REQUEST_OWNER: 'Request Owner',
        FINAL_RECIPIENT: 'Final Recipient',
        CDC_LOCATION: 'CDC Location',
        SHIP_TO_LOCATION: 'Ship to Location',
        DEPARTMENT_FUNCTION: 'Department / Function',
        CONFIGURATION: 'Configuration',
        FVC: 'FVC',
        QUANTITY: 'Qty.',
        SORTED_BY: 'Sorted By',
        SUGGESTED_UPARTS_ID: 'Suggested uParts ID',
        SCANNED_UPARTS_ID: 'Scanned uParts ID',
        UPARTS_ID: 'uParts Track ID',
        UPARTS: 'uParts',
        SORTED_DATE: 'Sorted Date',
        PN: 'PN',
        UPARTS_FVC: 'uParts FVC',
        OWNER: 'Owner',
        IMEI1: 'IMEI1',
        IMEI2: 'IMEI2',
        LENS_SECURITY_CODE: 'Lens Security Code',
        UPARTS_CREATED_DATE: 'Created Date',
        UPARTS_UPDATED_DATE: 'Updated Date',
        UPARTS_SYNC_DATE: 'Last Sync on Galvin',
        SPECIAL_NOTES: 'Special Notes to CDC',
        SHIPMENT_TRACKING: 'Shipment Tracking',
        RESHIP_TRACKING: 'Re-Shipment Tracking',
        SHIPMENT_DATE: 'Shipment Date',
        TARGET_RECEIPT_DATE: 'Target Receipt Date',
        ACTUAL_RECEIPT_DATE: 'Actual Receipt Date',
        AWB_TRACKING: 'AWB Tracking #',
        NOTES: 'Notes',
        RESHIP_DATE: 'Re-Ship Date',
        RESHIP_AWB: 'Re-Ship AWB',
        FILTER: 'Filter',
        NEW_UPARTS_OPTION: 'New option:',
        PASTE_VALUES: 'Paste values here',
        SAVE: 'Save',
        CANCEL: 'Cancel',
        UNSORT: 'Unsort',
        MANUAL_UPARTS_ID: 'This ID was manually inserted and was not suggested by the application',
        MANUAL_UPARTS_INVALID: 'Please insert a valid uParts Track ID. Invalid value "{{ input }}"',
        DIFFERENT_FVC: 'The uParts FVC and the request FVC are different',
        N_A: 'N/A',
        TABLE_FOOTER: 'Page {currentPage} of {totalPages}: showing {{ beginning }} to {{ end }} of {{ total }} entries',
        TABLE_NO_DATA: 'No data found for the given filter inputs',
        CLEAR_COLUMN_FILTERS: 'Clear all column input filters',
        MANUALLY_ADDED_PROTOTYPES: 'Manually Added Prototype Rows',
        MANUALLY_ADDED_ACCESSORIES: 'Manually Added Accessory Rows',
        MANUAL_COLOR: 'Yellow',
        SINGLE_SORTING_DISABLED: 'Single sorting is disabled until Suggested uParts Track IDs finish loading.',
        UPDATE_SUCCESS: 'Column updated successfully.',
        UPDATE_FAIL: 'Failed to update column.',
        NOT_EDITABLE_FIELD: {
            WITHOUT_UPART: 'This entry depends on the uParts Track ID filled in.',
            IMEI2_ALREADY_EXISTS: 'IMEI2 filled automatically.',
        },
        SPECIAL_REQUIREMENTS: 'Spec. Reqs.',
        REQUEST_COMMENTS: 'Req. CMT',
        INFORMATION: 'Double click or click the edit icon to edit editables columns which are:<br>Shipment Tracking Swimlane<br>'
        + 'Final Recipient<br>Ship To Location<br>Special Notes to CDC<br>uParts Track ID<br>IMEI2<br>Lens Security Code<br>Re-Shipment Tracking.',
        RESET_ICON_TOOLTIP: 'Reset to {{oldValue}}.',
        ACCESSORY: 'Accessory',
        MOTO_PART: 'Moto Part#',
        CATEGORY: 'Category',
        VENDOR: 'Vendor',
        VENDOR_PN: 'Vendor Part#',
        ALLOCATED_QTY: 'Allocated Qty.',
        CHANGE_COLOR: 'Change Color'
    },
    ACTIONS: {
        SUGGEST_UPARTS_TRACK_ID: 'Suggest uParts Track IDs for non-sorted',
        ADD_NEW_PROTOTYPE: 'Add New Prototype',
        SORT_SUGGESTED: 'Sort Suggested Devices',
        DELETE_NEW_PROTOTYPE: 'Delete New Prototype(s)',
        CANCEL_SUGGESTION: 'Cancel Suggestion',
        SCAN_MODE: 'Scan Mode',
        APPLY: 'Apply',
        ADD_NEW_ACCESSORY: 'Add New Accessory',
        DELETE_NEW_ACCESSORY: 'Delete New Accessory(ies)'
    },
    DIALOG: {
        NEW_PROTOTYPE: 'New Prototype',
        QUANTITY: 'Quantity',
        GENERAL: 'General',
        SUCCESS: 'New Prototypes added successfully',
        FAIL: 'Failed to add new prototypes. Please try again in a few moments or contact an administrator.',
        DELETE: {
            CONFIRMATION_DELETE_TITLE: 'DELETE {{amount}} added new prototype(s).',
            SUCCESS: 'Prototype(s) deleted successfully.',
            FAIL: 'Failed to delete protoypes. Please try again in a few moments or contact an administrator.'
        },
        NEW_ACCESSORY: 'New Accessory',
        ACCESSORY_SUCCESS: 'New Accessory added successfully',
        ACCESSORY_FAIL: 'Failed to add new accessory. Please try again in a few moments or contact an administrator.',
        ACCESSORY_DELETE: {
            CONFIRMATION_DELETE_TITLE: 'DELETE {{amount}} added new accessory(ies).',
            SUCCESS: 'Accessory(ies) deleted successfully.',
            FAIL: 'Failed to delete accessory. Please try again in a few moments or contact an administrator.'
        },
        ACCESSORY_CONFIRMATION_BULK_DELETE_TITLE: 'Delete Confirmation',
        ACCESSORY_CONFIRMATION_TEXT: 'Are you sure you want to delete {{quantity}} cell(s)? This action will be permanent.'
    },
    SORTING: {
        MANUAL_UPARTS_EXISTS: 'This Track ID "{{ input }}" is already sorted to another request',
        MANUAL_FVC_DIFFER: 'The referenced uPart has a different FVC',
        SORTING_SUCCESSFUL: 'Sorting successful for ',
        UNSORTING_SUCCESSFUL: 'Unsorting successful for ',
        SAVING_SINGLE_SORTING: '<i class="ml-1 fas fa-spinner fa-spin"></i> Saving sorting for ',
        REMOVING_SINGLE_SORTING: '<i class="ml-1 fas fa-spinner fa-spin"></i> Removing sorting for ',
        NO_ITEMS_TEXT: 'None for this FVC and selected PNs',
        BULK_SUGGESTION_WARN: 'Some requests were unable to be matched with suggestions from uParts',
        SUGGESTIONS_EXCEEDED: 'There are more prototypes on uParts than prototype requests on Galvin for the following FVCs: {{ fvcs }}. You can add new prototypes to assign the remaining assets.',
        NOT_ENOUGH_SUGGESTIONS: 'There are more prototype requests on Galvin than prototypes on uParts for the following FVCs: {{ fvcs }}. There will be missing request assignments.',
        ALREADY_SCANNED: 'You already scanned this track ID',
        ALREADY_SORTED: 'This Track ID is already sorted in an other Build Plan',
        UPARTS_ALREADY_SORTED: 'The following uParts Track IDs already were sorted: {{ uPartsTrackIds }}.'
    },
    ASSIGN_REQUEST: {
        UPARTS_INVALID: 'No entries were found for the following uParts Track IDs: {{ uPartsTrackIds }}.',
        UPARTS_FVC_NOT_FOUND: 'No suggestions were found for the following FVCs: {{ fvcs }}.',
        ASSIGN_SUCCESSFUL: 'Prototypes sorted successfully.',
        ASSIGN_HELPER_TEXT: 'Please select the requests to be sorted.',
        UPARTS_INVALID_APPLY: 'There are some invalid uParts Track IDs.',
        APPLY_SCANNED_UPARTS: '<i class="ml-1 fas fa-spinner fa-spin"></i> Loading sorting uParts.'
    },
    SUGGEST_UPARTS_TRACK_ID: {
        LOADING_SUGGESTION: '<i class="ml-1 fas fa-spinner fa-spin"></i> Loading suggestions',
        SUGGEST_CONDITION: 'Suggest uParts Track IDs for non-sorted requests, with configuration fvc and without special requirements.',
        NON_CANDIDATES: 'FVCs without suggestions due to special requirements: {{ fvcs }}.'
    },
    DELETE: {
        DELETE_HELPER_TEXT: 'Only manually added prototypes can be deleted.',
        DELETE_ACCESSORY_HELPER_TEXT: 'Only manually added accessories can be deleted.',
    }
};