// Angular
import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    Output
} from '@angular/core';
// RxJS
import { Subscription } from 'rxjs';
// Auth
import { AuthNotice, AuthNoticeService } from '@galvin/core/auth';

@Component({
    selector: 'kt-auth-notice',
    templateUrl: './auth-notice.component.html'
})
export class AuthNoticeComponent implements OnInit, OnDestroy {
    private _type = new EventEmitter();
    @Output() get type() {
        return this._type;
    }

    set type(v: any) {
        this._type.next(v);
    }

    private _message = new EventEmitter();

    // eslint-disable-next-line @angular-eslint/no-output-native
    @Output() get message() {
        return this._message;
    }

    set message(v: any) {
        this._message.next(v);
    }

    // Private properties
    private subscriptions: Subscription[] = [];

    /**
     * Component Constructure
     *
     * @param authNoticeService
     * @param cdr
     */
    constructor(public authNoticeService: AuthNoticeService, private cdr: ChangeDetectorRef) {}

    /*
     * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
     */

    /**
     * On init
     */
    ngOnInit() {
        this.subscriptions.push(
            this.authNoticeService.onNoticeChanged$.subscribe((notice: AuthNotice | null) => {
                notice = Object.assign({}, { message: '', type: '' }, notice);
                this.message = notice.message;
                this.type = notice.type;
                this.cdr.markForCheck();
            })
        );
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        this.subscriptions.forEach((sb) => sb.unsubscribe());
    }
}
