// Angular
import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    ViewEncapsulation
} from '@angular/core';
import { Router } from '@angular/router';
// RxJS
import { Subject, Subscription } from 'rxjs';
// Store
import { AppState } from '@galvin/core/reducers';
import { Store } from '@ngrx/store';
// Auth
import { AuthNoticeService, SecurityService } from '../../../../core/auth';
import { LoginGoogle, LoginLenovo } from '../../../../core/auth/_actions/auth.actions';
// Cookies
import { AppConstants } from '@galvin/views/share/app-constants';
import { CookieService } from 'ngx-cookie-service';
import { GoogleAuth } from '@galvin/core/auth/services/google-auth';

declare global {
    interface Window {
        handleCredentialResponse: (response: any) => {};
    }
}
@Component({
    selector: 'kt-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class LoginComponent implements OnInit, OnDestroy, AfterViewInit {
    private unsubscribe: Subject<any>;
    protected signInSubscription?: Subscription;
    public showLenovoLoginButton = true;

    public signinGoogle = false;
    public signinLenovo = false;

    public logging = false;

    /**
     * Component constructor
     *
     * @param router: Router
     * @param authNoticeService: AuthNoticeService
     * @param store: Store<AppState>
     * @param cookieService: CookieService
     * @param securityService: SecurityService
     */
    constructor(
        private router: Router,
        private authNoticeService: AuthNoticeService,
        private store: Store<AppState>,
        private cookieService: CookieService,
        private securityService: SecurityService,
        private cdr: ChangeDetectorRef,
        private googleAuth: GoogleAuth,

    ) {
        this.unsubscribe = new Subject();
    }

    /**
     * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
     */

    ngAfterViewInit(): void {
        this.googleAuth.initAuth();
    }

    /**
     * On init
     */
    ngOnInit(): void {
        this.initAuthNotice();
        this.checkAlreadyLogged();

        const currentURL: string = window.location.host;

        this.showLenovoLoginButton = AppConstants.ENVIRONMENT_URLS.has(currentURL);

        if (this.cookieService.get('jwt')) {
            this.signInWithLenovo();
        }

        this.signInSubscription = this.googleAuth.logging.subscribe((isLoading: boolean) => {
            this.logging = isLoading;
            this.cdr.detectChanges();
        });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        this.cdr.detach();
        this.authNoticeService.setNotice(undefined);
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.signInSubscription?.unsubscribe();
    }

    /**
     * Form initalization
     * Default params, validators
     */
    initAuthNotice() {
        // demo message to show
        if (!this.authNoticeService.onNoticeChanged$.getValue()) {
            this.authNoticeService.setNotice(undefined);
        }
    }

    signInWithGoogle(): void {
        this.signinGoogle = true;
        this.store.dispatch(new LoginGoogle());
        this.store.toPromise().finally(() => {
            this.signinGoogle = false;
        });
    }

    signInWithLenovo(): void {
        this.signinLenovo = true;
        this.store.dispatch(new LoginLenovo());
        this.store.toPromise().finally(() => {
            this.signinGoogle = false;
        });
    }

    private checkAlreadyLogged(): void {
        if (this.securityService.getInMemoryUser()) {
            this.router.navigate(['/']);
        }
    }
}
