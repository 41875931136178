import { NoopScrollStrategy, OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';
// Angular
import { RouterModule } from '@angular/router';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { SuggestAliasService } from '@galvin/core/_base/layout';
import { checkNullPipe } from '@galvin/core/_base/layout/pipes/check-null.pipe';
import { ConfigurationBufferPercentPipe } from '@galvin/core/_base/layout/pipes/configuration-buffer-percent.pipe';
import { FilterFullViewConfigurationPipe } from '@galvin/core/_base/layout/pipes/filter-full-view-configurations.pipe';
import { FilterFullViewTeamsPipe } from '@galvin/core/_base/layout/pipes/filter-full-view-teams.pipe';
import { ParseDatetimeSimplePipe } from '@galvin/core/_base/layout/pipes/parse-datetime-simple.pipe';
import { ParseDateTimePipe } from '@galvin/core/_base/layout/pipes/parse-datetime.pipe';
import { yesNoPipe } from '@galvin/core/_base/layout/pipes/yes-or-no.pipe';
import { FilterEnableDeleteAccessoryTrackerCdcPipe } from '@galvin/core/_base/layout/pipes/filter-enabled-delete-accessory-tracker-cdc.pipe';
import { BuildPlanSelectorComponent } from '@galvin/views/pages/build-plan/prototype-request/header/build-plan-selector/build-plan-selector.component';
import { TeamSelectorComponent } from '@galvin/views/pages/build-plan/prototype-request/header/team-selector/team-selector.component';
import { BuildPlanStatusIconComponent } from '@galvin/views/partials/content/general/build-plan-status-icon/build-plan-status-icon.component';
import { GalvinConfirmGenericComponent } from '@galvin/views/partials/content/general/galvin-confirm-dialog-generic/galvin-confirm-dialog-generic.component';
import { ConfigurationParametersFiltersComponent } from '@galvin/views/partials/layout/configuration-filters/configuration-parameters-filters.component';
import { GroupHeaderRefDirective } from '@galvin/views/partials/layout/configuration-table/group-header-ref-directive';
import { GroupRowDirective } from '@galvin/views/partials/layout/configuration-table/group-row-directive';
import { RowHeaderRefDirective } from '@galvin/views/partials/layout/configuration-table/row-header-ref-directive';
import { TableHeaderRefDirective } from '@galvin/views/partials/layout/configuration-table/table-header-ref-directive';
import { GenericFieldModule } from '@galvin/views/partials/layout/generic-field/generic-field.module';
import { GrantPermissionDialogComponent } from '@galvin/views/partials/layout/grant-permission-dialog/grant-permission-dialog.component';
import { SpecialRequirementFormModule } from '@galvin/views/partials/layout/request-details-dialog/special-requirement-form/special-requirement-form.module';
import {
    GalvinTableHeaderDirective,
    TableEditInlineComponent
} from '@galvin/views/partials/layout/table-edit-inline/table-edit-inline.component';


// SVG inline
import { InlineSVGModule } from 'ng-inline-svg';
import { CurrencyMaskModule } from 'ng2-currency-mask';
// Perfect Scrollbar
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
// Core module
import { CoreModule } from '../../core/core.module';
//Services
import { GalvinDialogGenericService } from '../../core/_base/general/galvin-dialog-generic/services/galvin-dialog-generic.service';
import { AccessoryHeaderRequestDetailsComponent } from '../pages/build-plan/accessory-team-request/accessory-request-dialog/accessory-header-request-details/accessory-header-request-details.component';
import { AccessoryParametersDetailsComponent } from '../pages/build-plan/accessory-team-request/accessory-request-dialog/accessory-parameters-details/accessory-parameters-details.component';
import { AccessoryRequestDialogComponent } from '../pages/build-plan/accessory-team-request/accessory-request-dialog/accessory-request-dialog.component';
import { GenericFVCFieldColumnRefDirective } from '../pages/build-plan/build-plan-management/configurations/generic-fvc-field/generic-fvc-field-column.directive';
import { GenericFvcFieldComponent } from '../pages/build-plan/build-plan-management/configurations/generic-fvc-field/generic-fvc-field.component';
import { SaveGenericFvcFieldComponent } from '../pages/build-plan/build-plan-management/configurations/generic-fvc-field/save-button-generic-fvc-field/save-generic-fvc-field.component';
import { FilterByPipe } from './../../core/_base/layout/pipes/filter-by.pipe';
import { GalvinUserPipe } from './../../core/_base/layout/pipes/galvin-user.pipe';
import { ParseChineseDatePipe } from './../../core/_base/layout/pipes/parse-chinese-date.pipe';
import { ParseDatePipe } from './../../core/_base/layout/pipes/parse-date.pipe';
import { GetTotalPipe } from '../pages/build-plan/reports/sku-board-samples-sourcing/pipes/get-totals.pipe';
import { GetSourcingTotalPipe } from '../pages/build-plan/reports/sku-board-samples-sourcing/pipes/get-sourcing-total.pipe';
import { GetProtosTotal } from '../pages/build-plan/reports/sku-board-samples-sourcing/pipes/get-protos-total.pipe';
import { GetUnitTypeTotal } from '../pages/build-plan/reports/sku-board-samples-sourcing/pipes/get-unit-type-total.pipe';
import { BannerInformationComponent } from './banner-information/banner-information.component';
// CRUD Partials
import {
    ActionNotificationComponent,
    AlertComponent,
    DeleteEntityDialogComponent,
    FetchEntityDialogComponent,
    UpdateStatusDialogComponent
} from './content/crud';
import { ConsentConfirmDialog } from './content/general/consent-dialog-generic/consent-confirm-dialog.component';
// mock/build-plan.interceptor
import { FooterDialogGenericComponent } from './content/general/galvin-dialog-generic/footer-dialog-generic/footer-dialog-generic.component';
//Dialogs
import { GalvinDialogGenericComponent } from './content/general/galvin-dialog-generic/galvin-dialog-generic.component';
import { HeaderDialogGenericComponent } from './content/general/galvin-dialog-generic/header-dialog-generic/header-dialog-generic.component';
import { GalvinTreeNodeTmpDirective } from './content/general/galvin-tree/galvin-tree-node-tmp.directive';
import { GalvinTreeNodeTooltipTmpDirective } from './content/general/galvin-tree/galvin-tree-node-tooltip-tmp.directive';
import { GalvinTreeComponent } from './content/general/galvin-tree/galvin-tree.component';
// General
import { NoticeComponent } from './content/general/notice/notice.component';
import { PortletModule } from './content/general/portlet/portlet.module';
import { PropagateAssociationComponent } from './content/general/propagate-association/propagate-association.component';
import { ToggleComponent } from './content/general/toggle-component/toggle.component';
// Layout partials
import {
    ChatbotButtonComponent,
    ChatbotSidePanelComponent,
    ConfigurationHeaderComponent,
    FloatingScrollbarComponent,
    MultiSelectorsComponent,
    NotificationComponent,
    PopoverLabelTotalComponent,
    PopoverListGenericComponent,
    PopoverSpecialRequirementsComponent,
    SearchResultComponent,
    SplashScreenComponent,
    StickyTableHeaderComponent,
    SubheaderGalvinComponent,
    SubheaderSearchComponent,
    UserProfileComponent
} from './layout';
import { AccessoryHeaderComponent } from './layout/accessory-header/accessory-header.component';
import { AccessoryParametersFilterComponent } from './layout/accessory-parameters-filter/accessory-parameters-filter.component';
import { CellRefDirective } from './layout/configuration-table/cell-ref-directive';
import { RowCollapseRefDirective } from './layout/configuration-table/row-collapse-directive';
import { GalvinDeleteItemTableComponent } from './layout/galvin-delete-item-table/galvin-delete-item-table.component';
import { GalvinProgressSpinnerModule } from './layout/galvin-progress-spinner/galvin-progress-spinner.module';
import {
    MenuShowColumnsComponent,
    MenuShowColumnsTmpDirective
} from './layout/menu-show-columns/menu-show-columns.component';
import { RequestActivityComponent } from './layout/request-details-dialog/activity/request-activity.component';
import { ConfigStatusDetailsRequestComponent } from './layout/request-details-dialog/configuration-status-details/configuration-status-details.component';
import { DatesDefaultDetailsComponent } from './layout/request-details-dialog/dates-default-details/dates-default-details.component';
import { FactoryDetailsComponent } from './layout/request-details-dialog/factory-details/factory-details.component';
import { FvcParametersDetailsComponent } from './layout/request-details-dialog/fvc-parameters-details/fvc-parameters-details.component';
import { HeaderRequestDetailsComponent } from './layout/request-details-dialog/header-request-details/header-request-details.component';
import { RelativeDatesDetailsComponent } from './layout/request-details-dialog/relative-dates-details/relative-dates-details.component';
import { RequestApprovalCommentDialogComponent } from './layout/request-details-dialog/request-approval-comment-dialog/request-approval-comment-dialog.component';
import { RequestDetailsDialogComponent } from './layout/request-details-dialog/request-details-dialog.component';
import { SkeletonLoadDialogComponent } from './layout/request-details-dialog/skeleton-load-dialog/skeleton-load-dialog.component';
import { TitleExpandedComponent } from './layout/request-details-dialog/title-expanded/title-expanded.component';
import { SearchGalvinComponent } from './layout/search-galvin/search-galvin.component';
import { SuggestAliasComponent } from './layout/suggest-alias/suggest-alias.component';
import { FreezeColumnsComponent } from './layout/table-edit-inline/freeze-columns/freeze-columns.component';
import { CdkDropListScrollContainerDirective } from './layout/table-edit-inline/scroll-drag-drop.directive';
import { TableConfigSortComponent } from './layout/table-sort/table-config-sort/table-config-sort/table-config-sort.component';
import { TableSortComponent } from './layout/table-sort/table-sort.component';
import { UserManagementDialogComponent } from './layout/user-management-dialog/user-management-dialog.component';
import { SuggestAliasListComponent } from './layout/suggest-alias-list/suggest-alias-list.component';
import { GetBodySourcingColor } from '../pages/build-plan/reports/sku-board-samples-sourcing/pipes/get-body-sourcing-color.pipe';
import { GetHeaderSourcingColor } from '../pages/build-plan/reports/sku-board-samples-sourcing/pipes/get-header-sourcing-color.pipe';
import { GetColumnObjectPipe } from './layout/table-edit-inline/pipes/get-column-object.pipe';
import { GetLeftPositionPipe } from './layout/table-edit-inline/pipes/get-left-position.pipe';
import { IsAllSelectedPipe } from './layout/table-edit-inline/pipes/is-all-selected.pipe';
import { IsTypeFromConfiguration } from './layout/table-edit-inline/pipes/is-type-from-configuration.pipe';
import { GetDisplayColumnByGroup } from './layout/table-edit-inline/pipes/get-display-column-by-group.pipe';
import { HasOptionsSelectedPipe } from './layout/search-galvin/pipes/has-options-selected.pipe';


const galvinComponents= [

    NoticeComponent,
    ActionNotificationComponent,
    DeleteEntityDialogComponent,
    GalvinConfirmGenericComponent,
    ConsentConfirmDialog,
    FetchEntityDialogComponent,
    UpdateStatusDialogComponent,
    AlertComponent,
    ToggleComponent,
    BuildPlanStatusIconComponent,
    ConfigurationParametersFiltersComponent,
    GalvinTreeComponent,
    PropagateAssociationComponent,
    BuildPlanSelectorComponent,
    ConfigurationHeaderComponent,
    FloatingScrollbarComponent,
    StickyTableHeaderComponent,
    //Requests Component
    RequestDetailsDialogComponent,
    SkeletonLoadDialogComponent,
    RequestActivityComponent,
    FvcParametersDetailsComponent,
    DatesDefaultDetailsComponent,
    RelativeDatesDetailsComponent,
    FactoryDetailsComponent,
    ConfigStatusDetailsRequestComponent,
    HeaderRequestDetailsComponent,
    TitleExpandedComponent,
    ConfigurationHeaderComponent,
    // Topbar components
    SearchResultComponent,
    SplashScreenComponent,
    SubheaderGalvinComponent,
    SubheaderSearchComponent,
    NotificationComponent,
    UserProfileComponent,
    TeamSelectorComponent,
    ChatbotButtonComponent,
    // Dialogs
    GalvinDialogGenericComponent,
    HeaderDialogGenericComponent,
    FooterDialogGenericComponent,
    SearchGalvinComponent,
    GenericFvcFieldComponent,
    SaveGenericFvcFieldComponent,
    SuggestAliasComponent,
    TableEditInlineComponent,
    GalvinTableHeaderDirective,
    MenuShowColumnsComponent,
    AccessoryRequestDialogComponent,
    AccessoryHeaderRequestDetailsComponent,
    AccessoryParametersDetailsComponent,
    // Directives
    CdkDropListScrollContainerDirective,
    RowCollapseRefDirective,
    CellRefDirective,
    TableHeaderRefDirective,
    RowHeaderRefDirective,
    GroupRowDirective,
    GroupHeaderRefDirective,
    GenericFVCFieldColumnRefDirective,
    MenuShowColumnsTmpDirective,
    // Pipes
    ParseDatePipe,
    ParseDateTimePipe,
    ParseChineseDatePipe,
    ParseDatetimeSimplePipe,
    GalvinTreeNodeTmpDirective,
    GalvinTreeNodeTooltipTmpDirective,
    checkNullPipe,
    ConfigurationBufferPercentPipe,
    yesNoPipe,
    GalvinUserPipe,
    FilterFullViewTeamsPipe,
    FilterFullViewConfigurationPipe,
    FilterByPipe,
    GalvinDeleteItemTableComponent,
    GalvinTreeComponent,
    FilterEnableDeleteAccessoryTrackerCdcPipe,
    GetTotalPipe,
    GetSourcingTotalPipe,
    GetProtosTotal,
    GetUnitTypeTotal,
    GetBodySourcingColor,
    GetHeaderSourcingColor,
    GetColumnObjectPipe,
    GetLeftPositionPipe,
    IsAllSelectedPipe,
    IsTypeFromConfiguration,
    GetDisplayColumnByGroup,
    HasOptionsSelectedPipe,
    // Popover components
    PopoverSpecialRequirementsComponent,
    PopoverListGenericComponent,
    PopoverLabelTotalComponent,
    AccessoryParametersFilterComponent,
    BannerInformationComponent,
    // Selector components
    MultiSelectorsComponent,
    TableSortComponent,
    TableConfigSortComponent,
    FreezeColumnsComponent,
    GrantPermissionDialogComponent,
    AccessoryHeaderComponent,
    RequestApprovalCommentDialogComponent,
    ChatbotSidePanelComponent,
    UserManagementDialogComponent,
    SuggestAliasListComponent
];

@NgModule({
    declarations: [
        galvinComponents
    ],
    exports: [
        // Modules
        CommonModule,
        CoreModule,
        PortletModule,
        GalvinProgressSpinnerModule,
        CKEditorModule,
        GenericFieldModule,
        SpecialRequirementFormModule,
        galvinComponents
    ],
    imports: [
        CommonModule,
        RouterModule,
        PerfectScrollbarModule,
        InlineSVGModule,
        CoreModule,
        PortletModule,
        GalvinProgressSpinnerModule,
        SpecialRequirementFormModule,
        OverlayModule,
        NgxSkeletonLoaderModule,
        CurrencyMaskModule,
        CKEditorModule,
        GenericFieldModule
    ],
    providers: [
        GalvinDialogGenericService,
        SuggestAliasService,
        {
            provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
            useValue: {
                position: 'before'
            }
        },
        {
            provide: MAT_DIALOG_DEFAULT_OPTIONS,
            useValue: {
                hasBackdrop: true,
                panelClass: 'kt-mat-dialog-container__wrapper',
                height: 'auto',
                width: '900px',
                disableClose: true,
                useValue: { scrollStrategy: new NoopScrollStrategy() }
            }
        }
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ]
})
export class PartialsModule {}
