import { animate, state, style, transition, trigger } from '@angular/animations';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ContentChild,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnInit,
    Output,
    TemplateRef,
    ViewChild
} from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash';
import { debounceTime, map, tap } from 'rxjs/operators';
import { IOptionsModel } from '../configuration-filters/configuration-parameters-filters.component';
import { CellRefDirective } from '../configuration-table/cell-ref-directive';

@Component({
    selector: 'search-galvin',
    templateUrl: './search-galvin.component.html',
    styleUrls: ['./search-galvin.component.scss'],
    animations: [
        trigger('detailExpand', [
            state(
                'collapsed',
                style({
                    display: 'none',
                    height: '0px',
                    minHeight: '0',
                    position: 'absolute',
                    opacity: 0
                })
            ),
            state(
                'expanded',
                style({
                    position: 'fixed',
                    background: ' #FFFFFF 0% 0% no-repeat padding-box',
                    border: '1px solid #4D4F5C33',
                    borderRadius: '0px 0px 4px 4px',
                    boxShadow: '0px 10px 10px #0000001A',
                    zIndex: 4,
                    opacity: 1,
                })
            ),
            transition('expanded <=> collapsed', animate('225ms ease'))
        ])
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchGalvinComponent implements OnInit, AfterViewInit {
    public expandedElement: any;
    @Input() placeholder = 'Search device';
    @Input() loadSearch = false;
    @Input() filter = false;
    @Input() formGroup!: AbstractControl;
    @Input() optionsSelected: IOptionsModel = {};
    @Input() clearButton = false;
    @Input() disableUpdateInFirstLoad = false;
    @Input() searchByEnter = false;
    @Output() inputChange = new EventEmitter<AbstractControl>();
    @Output() openFilter = new EventEmitter<AbstractControl>();
    @Output() clearFilterBy = new EventEmitter<AbstractControl>();
    _textSearch:string='';

    readonly POPOVER_FILTERS_CLASS = 'popover-filters';

    @ViewChild('searchInput', { static: true }) public searchInput!: ElementRef;

    isDisabled = false;
    insideClick!: boolean;
    currentPopover!: NgbPopover;
    firstLoad = true;
    searchTip = '';

    @Input() set disabled(isDisabled: boolean) {
        if (this.searchInput) {
            this.searchInput.nativeElement.disabled = isDisabled;
        }
        this.isDisabled = isDisabled;
    }

    @Input() set textSearch(textSearch: string) {
        if(textSearch){
            this._textSearch=textSearch;
        }
    }

    searchForm!: FormGroup;

    @HostListener('click') clickInside(): void {
        this.insideClick = true;
    }

    @HostListener('document:click') clickout(): void {
        if (!this.insideClick) {
            this.expandedElement = false;
        }
        this.insideClick = false;
    }

    constructor(private i18n: TranslateService, private cdr: ChangeDetectorRef) {}

    @ContentChild(CellRefDirective, { read: TemplateRef }) cellRef!: any;

    ngOnInit(): void {
        this.searchForm = new FormGroup({
            searchInput: new FormControl(null),
            filter: new FormControl(null)
        });
        if (this._textSearch){
            this.searchForm.controls['searchInput'].setValue(this._textSearch);
        }

        if (!this.searchByEnter) {
            this.searchForm.valueChanges
                .pipe(
                    tap(() => {
                        this.loadSearch = true;
                    }),
                    map((val: AbstractControl) => val),
                    debounceTime(500)
                )
                .subscribe((res) => {
                    if((this.disableUpdateInFirstLoad && !this.firstLoad) || !this.disableUpdateInFirstLoad){
                        this.inputChange.next(res);
                    }
                    this.firstLoad = false;
                    this.loadSearch = false;
                });
        }

        if(this.searchByEnter) {
            this.searchTip = 'Enter to search';
        }

    }

    ngAfterViewInit(): void {
        if (this.formGroup) {
            this.searchForm.addControl('dropdown', this.formGroup);
        }
    }

    public onClickEvent(): void {
        this.expandedElement = !this.expandedElement;
        this.openFilter.next(this.expandedElement);
    }

    public fvcSelected(): string[] {
        return Object.keys(this.optionsSelected);
    }

    public hasOptionsSelected(): boolean {
        let optionsSelected = false;

        for (const p of this.fvcSelected()) {
            optionsSelected = this.getValueFromParameter(p).length > 0;
            if (optionsSelected) {
                break;
            }
        }
        return optionsSelected;
    }

    public getValueFromParameter(parameter: string): string[] {
        return Object.keys(this.optionsSelected[parameter]);
    }

    /**
     * Transform all values selected into a string to be displayed
     * @param separator character used to separate each element
     * @param limit max allowed size of displayed elements
     */
    public getValueFromParameterShort(separator = ', ', limit = 2): string {
        const allValuesSelected: any[] = [];

        const fvcSelected = this.fvcSelected();
        fvcSelected.forEach((parameter) => {
            const valueSelected = this.getValueFromParameter(parameter);
            valueSelected.forEach((value) => {
                allValuesSelected.push(value);
            });
        });

        const cloneAllValueSelected = cloneDeep(allValuesSelected);

        const removed = allValuesSelected.slice(0, limit);
        const length = cloneAllValueSelected.length - removed.length;

        if (cloneAllValueSelected.length > limit) {
            const textLength = this.i18n.instant('COMMON.MORE_ELEMENTS_ON_ARRAY', { length });
            return `${removed.join(separator)} ${textLength}`;
        }

        return `${removed.join(separator)}`;
    }

    public getCountFilter(): number {
        let count = 0;

        this.fvcSelected().forEach((t) => {
            count += this.getValueFromParameter(t).length;
        });

        return count;
    }

    public showFilterPopover(popover: NgbPopover): void {
        if (this.currentPopover) {
            this.currentPopover.close();
            this.cdr.markForCheck();
        }
        popover.open();
        this.currentPopover = popover;
    }

    public closeFilterPopover(popover: NgbPopover): void {
        setTimeout(() => {
            if (!document.querySelectorAll(`.${this.POPOVER_FILTERS_CLASS}:hover`).length) {
                popover.close();
            }
        }, 250);
    }

    public onSearchInputClear(): void {
        this.searchForm.setValue({ searchInput: null, filter: null });
        this.inputChange.emit(this.searchForm.value);
    }

    public doSearch(): void {
        if (this.searchByEnter) {
            this.inputChange.emit(this.searchForm.value);
        }
    }
}
