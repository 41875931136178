<div class="kt-grid kt-grid--hor kt-grid--root kt-login kt-login--v1" id="kt_login">
    <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--desktop kt-grid--ver-desktop kt-grid--hor-tablet-and-mobile">
        <div class="background-img">
            <div class="logo">
                <img src="../../../../assets/galvin/icons/icon_bp_logo.svg">
            </div>
            <div class="message">
                <div class="welcome">
                    {{ 'AUTH.LOGIN.WELCOME' | translate }}
                    <b>{{ 'COMMON.GALVIN' | translate }}</b>
                </div>
                <div class="kt-login__subtitle">{{ 'AUTH.LOGIN.WELCOME_SUBTITLE' | translate }}</div>
            </div>
            <div class="login-placement">
                <div class="kt-grid__item kt-grid__item--fluid  kt-grid__item--order-tablet-and-mobile-1 kt-login__wrapper">
                    <router-outlet></router-outlet>
                </div>
            </div>
            <div class="kt-grid__item kt-login__aside login-info">
                <div class="kt-login__info">
                    <div class="kt-login__menu">
                        <a href="https://www.lenovo.com/us/en/privacy" class="kt-link">{{ 'AUTH.LOGIN.INFORMATIONS.FOOTER_LINKS.PRIVACY_CONTENT' | translate }}</a>
                        <a href="mailto: privacy@lenovo.com" class="kt-link">{{ 'AUTH.LOGIN.INFORMATIONS.FOOTER_LINKS.LEGAL' | translate }}</a>
                        <a href="mailto:  galvinsupport@motorola.com" class="kt-link">{{ 'AUTH.LOGIN.INFORMATIONS.FOOTER_LINKS.GALVIN_SUPPORT' | translate }}</a>
                    </div>
                    <div class="kt-login__copyright">
                        {{ 'AUTH.LOGIN.INFORMATIONS.COPYRIGHT' | translate }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
