import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ConfigurationRequestTableService } from '@galvin/core/build-plan/configuration-management/services/configuration-request-table.service';
import { CoreModule } from '@galvin/core/core.module';
import { PortletModule } from '@galvin/views/partials/content/general/portlet/portlet.module';
import { PROTO_REQUEST_TABLE_TOKEN } from '@galvin/views/partials/layout/proto-request-table/proto-request-table.service';
import { PrototypeRequestFormComponent } from '@galvin/views/partials/layout/prototype-request-form/prototype-request-form.component';
import { SpecialRequirementFormModule } from '@galvin/views/partials/layout/request-details-dialog/special-requirement-form/special-requirement-form.module';
import { NgbPopoverModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { GroupHeaderRefDirective } from './directives/group-header-ref.directive';
import { GroupRowDirective } from './directives/group-row.directive';
import { RowHeaderRefDirective } from './directives/row-header-ref.directive';
import { TableHeaderRefDirective } from './directives/table-header-ref.directive';
import { CellRefDirective } from './index';
import { ProtoRequestTableComponent } from './proto-request-table.component';
import { FullviewCellsSummaryComponent } from '../fullview-cells-summary/fullview-cells-summary.component';

@NgModule({
    declarations: [
        ProtoRequestTableComponent,
        PrototypeRequestFormComponent,
        GroupHeaderRefDirective,
        TableHeaderRefDirective,
        RowHeaderRefDirective,
        CellRefDirective,
        GroupRowDirective,
        FullviewCellsSummaryComponent,
    ],
    exports: [
        ProtoRequestTableComponent,
        PrototypeRequestFormComponent,
        FullviewCellsSummaryComponent,
        CellRefDirective,
        GroupHeaderRefDirective,
        TableHeaderRefDirective,
        RowHeaderRefDirective,
        GroupRowDirective
    ],
    imports: [
        CommonModule,
        CoreModule,
        SpecialRequirementFormModule,
        ScrollingModule,
        PerfectScrollbarModule,
        MatProgressSpinnerModule,
        MatButtonModule,
        MatBottomSheetModule,
        TranslateModule.forChild(),
        ReactiveFormsModule,
        FormsModule,
        MatIconModule,
        MatInputModule,
        MatIconModule,
        MatFormFieldModule,
        MatCheckboxModule,
        PortletModule,
        NgbTooltipModule,
        NgbPopoverModule
    ],
    providers: [
        ConfigurationRequestTableService,
        { provide: PROTO_REQUEST_TABLE_TOKEN, useClass: ConfigurationRequestTableService }
    ]
})
export class ProtoRequestTableModule {}
