import { AccessoryRequestService } from '@galvin/core/build-plan/accessory-request-management/services/accessory-request.service';
//Angular
import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output
} from '@angular/core';
import { EBuildPlanStatusLabel } from '@galvin/core/build-plan/management';
import { IPrototypeRequestI18n } from '@galvin/core/build-plan/prototype-request-management/i18n/prototype-request-i18n.interface';
import { IBuildPlanSelectorItem } from '@galvin/core/build-plan/prototype-request-management/interfaces/build-plan-selector-item.interface';
import { PrototypeRequestService } from '@galvin/core/build-plan/prototype-request-management/services/prototype-request.service';
import { LayoutUtilsService, ToastrType } from '@galvin/core/_base/crud';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { BuildPlanSelectorService } from '@galvin/core/build-plan/prototype-request-management/services/build-plan-selector.service';
import { LocalStorageHelper } from '@galvin/core/storage/local-storage-helper.service';

export interface IBuildPlansByGroup {
    new: IBuildPlanSelectorItem[];
    alreadyRequested: IBuildPlanSelectorItem[];
    lockingDownSoon: IBuildPlanSelectorItem[];
    underApproval: IBuildPlanSelectorItem[];
    building: IBuildPlanSelectorItem[];
    closed: IBuildPlanSelectorItem[];
}
@Component({
    selector: 'build-plan-selector',
    templateUrl: './build-plan-selector.component.html',
    styleUrls: ['./build-plan-selector.component.scss', '../selectors-common.scss']
})
export class BuildPlanSelectorComponent implements OnInit, OnDestroy {
    private subscription: Subscription;
    private serviceSubscriptions: Subscription;
    readonly GROUP_NEW: string = 'new';
    readonly GROUP_ALREADY_REQUESTED: string = 'alreadyRequested';
    readonly GROUP_LOCKING_DOWN_SOON: string = 'lockingDownSoon';
    readonly GROUP_UNDER_APPROVAL: string = 'underApproval';
    readonly GROUP_BUILDING: string = 'building';
    readonly GROUP_CLOSED: string = 'closed';
    readonly BUILDING: string = EBuildPlanStatusLabel.BUILDING;
    private readonly labels: IPrototypeRequestI18n;
    loadingBuildPlansSelector: boolean = true;
    @Input() isAccessory = false;
    @Input() enabledSelection = true;
    @Input() filterByRole = false;
    @Input() excludedUnderApproval = true;
    @Input() disableCache = false;
    buildPlans!: IBuildPlansByGroup;
    @Output() buildPlan = new EventEmitter<IBuildPlanSelectorItem>();
    selectedBuildPlan: IBuildPlanSelectorItem | null = null;
    selectedBuildPlanGroup: {
        value: string;
        name: string;
    } | null = null;
    buildPlanSelectorFilter = '';
    LOCAL_STORAGE_ALL_BUILD_PLANS = 'buildPlanSelector@AllBuildPlans';
    constructor(
        private changeDetector: ChangeDetectorRef,
        private layoutUtilsService: LayoutUtilsService,
        private translate: TranslateService,
        private prototypeRequestService: PrototypeRequestService,
        private accessoryRequestService: AccessoryRequestService,
        private buildPlanSelectorService: BuildPlanSelectorService,
        private localStorageHelper: LocalStorageHelper
    ) {
        this.subscription = new Subscription();
        this.serviceSubscriptions = new Subscription();
        this.buildPlans = {
            new: [],
            alreadyRequested: [],
            lockingDownSoon: [],
            underApproval: [],
            building: [],
            closed: []
        };
        this.labels = this.translate.instant(
            'BUILD_PLAN.PROTOTYPE_REQUEST.HEADER.BUILD_PLAN_SELECTOR'
        );
    }
    ngOnInit(): void {
        this.loadBuildPlanSelector();
        this.subscribeRequestHasBeenDone();
    }
    ngOnDestroy(): void {
        this.subscription.unsubscribe();
        this.serviceSubscriptions.unsubscribe();
        this.changeDetector.detach();
    }

    /**
     * Loads the build plans selector data from the server on page init
     */
    loadBuildPlanSelector(): void {
        if (this.isAccessory) {
            this.accessoryRequestService.isBuildPlanSelectorLoaded = false;
        } else {
            this.prototypeRequestService.isBuildPlanSelectorLoaded = false;
        }
        this.loadingBuildPlansSelector = true;
        this.buildPlans = {
            new: [],
            alreadyRequested: [],
            lockingDownSoon: [],
            underApproval: [],
            building: [],
            closed: []
        };
        this.setSelectedBuildPlan(null);

        const bpsOnLocalStorage = this.getAllBuildPlanLocalStorage();

        if(bpsOnLocalStorage && !this.disableCache) {
            this.setBuildPlanSelector(bpsOnLocalStorage);
            this.loadingBuildPlansSelector = false;

            if (this.isAccessory) {
                this.accessoryRequestService.isBuildPlanSelectorLoaded = true;
            } else {
                this.prototypeRequestService.isBuildPlanSelectorLoaded = true;
            }

            this.changeDetector.detectChanges();
        }else{
            this.subscription.add(
                this.prototypeRequestService
                    .getBuildPlanSelector(this.filterByRole, this.excludedUnderApproval)
                    .pipe(
                        finalize(() => {
                            this.loadingBuildPlansSelector = false;
                            if (this.isAccessory) {
                                this.accessoryRequestService.isBuildPlanSelectorLoaded = true;
                            } else {
                                this.prototypeRequestService.isBuildPlanSelectorLoaded = true;
                            }
                            this.changeDetector.detectChanges();
                        })
                    )
                    .subscribe(
                        (response: IBuildPlanSelectorItem[]) => {
                            if (response) {
                                const responseWithoutTeams = response.map(r => {
                                    // eslint-disable-next-line
                                    const { teams , ...rest } = r;

                                    return rest;
                                });

                                this.setAllBuildPlanLocalStorage(responseWithoutTeams);
                                this.setBuildPlanSelector(responseWithoutTeams);
                            }
                        },
                        () => {
                            this.layoutUtilsService.showToastrNotification(
                                (this.labels as any)['LOAD_ERROR'] as string,
                                ToastrType.error
                            );
                            this.prototypeRequestService.selectedBuildPlanIdChange.error(null);
                        }
                    )
            );
        }
    }

    setSelectedBuildPlanGroup(group: string) {
        switch (group) {
        case this.GROUP_NEW:
            this.selectedBuildPlanGroup = {
                value: this.GROUP_NEW,
                name: (this.labels as any)['NEW']
            };
            break;
        case this.GROUP_ALREADY_REQUESTED:
            this.selectedBuildPlanGroup = {
                value: this.GROUP_ALREADY_REQUESTED,
                name: (this.labels as any)['ALREADY_REQUESTED']
            };
            break;
        case this.GROUP_LOCKING_DOWN_SOON:
            this.selectedBuildPlanGroup = {
                value: this.GROUP_LOCKING_DOWN_SOON,
                name: (this.labels as any)['LOCKING_SOON']
            };
            break;
        case this.GROUP_UNDER_APPROVAL:
            this.selectedBuildPlanGroup = {
                value: this.GROUP_UNDER_APPROVAL,
                name: (this.labels as any)['UNDER_APPROVAL']
            };
            break;
        case this.GROUP_BUILDING:
            this.selectedBuildPlanGroup = {
                value: this.GROUP_BUILDING,
                name: this.BUILDING
            };
            break;
        case this.GROUP_CLOSED:
            this.selectedBuildPlanGroup = {
                value: this.GROUP_CLOSED,
                name: (this.labels as any)['CLOSED']
            };
            break;
        default:
            break;
        }
    }

    setAllBuildPlanLocalStorage(bps: IBuildPlanSelectorItem[]) {
        if(bps) {
            this.localStorageHelper.setItem(this.LOCAL_STORAGE_ALL_BUILD_PLANS, JSON.stringify(bps));
        }else{
            this.localStorageHelper.removeItem(this.LOCAL_STORAGE_ALL_BUILD_PLANS);
        }
    }

    getAllBuildPlanLocalStorage(): IBuildPlanSelectorItem[] | null {
        const bps = this.localStorageHelper.getItem(this.LOCAL_STORAGE_ALL_BUILD_PLANS);
        return bps ? JSON.parse(bps) : null;
    }

    /**
     * Called when a build plan is selected
     *
     * @param buildPlan
     * @param group
     */
    selectBuildPlan(buildPlan: IBuildPlanSelectorItem, group: string): void {
        this.setSelectedBuildPlan(buildPlan);
        this.buildPlanSelectorService.setBuildPlanSelection(buildPlan);
        if (this.isAccessory) {
            this.accessoryRequestService.buildPlanSelected = buildPlan;
        } else {
            this.prototypeRequestService.setBuildPlanSelection(buildPlan);
            this.prototypeRequestService.bpInternalProductNameSelected.next(
                buildPlan.internalProductName
            );
        }
        this.setSelectedBuildPlanGroup(group);
    }
    /**
     * Checks if a given group should be filtered. It checks if all the group's items are filtered.
     *
     * @param group: group being validated
     * @returns true if all the group items are filtered, false otherwise
     */
    isGroupFiltered(group: string): boolean {
        return (this.buildPlans as any)[group].every((item: any) => item.filtered);
    }
    /**
     * Checks if a given build plan should be filtered
     *
     * @param buildPlan: build plan being validated
     * @returns true if all the build plan is filtered, false otherwise
     */
    isBuildPlanFiltered(buildPlan: IBuildPlanSelectorItem): boolean {
        const filterValue = this.buildPlanSelectorFilter.trim().toLowerCase();
        const isFiltered = !buildPlan.internalProductName.toLowerCase().includes(filterValue);
        buildPlan.filtered = isFiltered;
        return isFiltered;
    }
    /**
     * Checks if the selector has any Build Plans being shown (not filtered)
     *
     * @returns true there are build plans being shown in the selector, false otherwise
     */
    hasBuildPlansToShow(): boolean {
        let allFiltered = true;
        if (this.buildPlans) {
            Object.keys(this.buildPlans).some((group: string) => {
                if (!this.isGroupFiltered(group)) {
                    allFiltered = false;
                    return true;
                }
                return false;
            });
        }
        return !allFiltered;
    }

    private refreshBpList() {
        this.subscription.add(
            this.prototypeRequestService
                .getBuildPlanSelector(this.filterByRole, this.excludedUnderApproval)
                .pipe(
                    finalize(() => {
                        this.changeDetector.detectChanges();
                    })
                )
                .subscribe(
                    (response: IBuildPlanSelectorItem[]) => {
                        if (response) {
                            const responseWithoutTeams = response.map(r => {
                                // eslint-disable-next-line
                                const { teams , ...rest } = r;
                                return rest;
                            });

                            this.setAllBuildPlanLocalStorage(responseWithoutTeams);
                            this.updateBuildPlanSelector(responseWithoutTeams);
                        }
                    },
                    () => {
                        this.layoutUtilsService.showToastrNotification(
                            (this.labels as any)['LOAD_ERROR'] as string,
                            ToastrType.error
                        );
                        this.prototypeRequestService.selectedBuildPlanIdChange.error(null);
                    }
                )
        );
    }

    /**
     * Called whenever selector dropdown state changes (open/close). This clears the filter input on close.
     * @param isOpen: true if selector is being opened, false if it's being closed.
     */
    dropdownChange(isOpen: boolean): void {
        if (!isOpen) {
            this.buildPlanSelectorFilter = '';
        }else{
            this.refreshBpList();
        }
    }

    public updateBuildPlanSelector(selectorData: IBuildPlanSelectorItem[]): void {
        const tmpBuildPlans: any = {
            new: [],
            alreadyRequested: [],
            lockingDownSoon: [],
            underApproval: [],
            building: [],
            closed: []
        };

        selectorData.forEach((buildPlan) => {
            const selectedGroup = this.selectBuildPlanGroup(tmpBuildPlans, buildPlan);

            if(this.selectedBuildPlan?.id === buildPlan.id) {
                this.setSelectedBuildPlan(buildPlan);
                if(selectedGroup) {
                    this.setSelectedBuildPlanGroup(selectedGroup);
                }
            }
        });

        this.buildPlans = tmpBuildPlans;

        this.sortBuildPlansSelectorByName();
    }
    /**
     * Builds the build plans selector list, separating the build plans into 3 groups:
     * locking down soon (lock down date is within a week),
     * already requested (already has prototypes requested),
     * new (no prototypes requested)
     *
     * @param selectorData: list of build plans for the selector received from the server
     */
    public setBuildPlanSelector(selectorData: IBuildPlanSelectorItem[]): void {
        if (!selectorData.length) {
            this.setSelectedBuildPlan(null);
            if (this.isAccessory) {
                this.accessoryRequestService.buildPlanSelected = null;
            } else {
                this.prototypeRequestService.setBuildPlanSelection(null);
                this.prototypeRequestService.bpInternalProductNameSelected.next(null);
            }
        }

        const selectedBuildPlan = this.isAccessory
            ? this.accessoryRequestService.getBuildPlanSelection()
            : this.prototypeRequestService.getBuildPlanSelection();
        selectorData.forEach((buildPlan) => {
            const selectedGroup = this.selectBuildPlanGroup(this.buildPlans, buildPlan) as string;
            if (selectedBuildPlan && buildPlan.id === selectedBuildPlan) {
                this.selectBuildPlan(buildPlan, selectedGroup);
            }
        });
        this.sortBuildPlansSelectorByName();
        // If there's no build plan selection information, set the first build plan from the selector as the selected one
        if (!this.selectedBuildPlan) {
            Object.keys(this.buildPlans).some((group: string) => {
                if ((this.buildPlans as any)[group].length) {
                    this.selectBuildPlan((this.buildPlans as any)[group][0], group);
                    return true;
                }
                return false;
            });
        }
    }
    /**
     * Sort the build plans selector by the build plan names (case insensitive) in each group
     */
    private sortBuildPlansSelectorByName(): void {
        for (const group in this.buildPlans) {
            (this.buildPlans as any)[group].sort(
                (buildPlanA: IBuildPlanSelectorItem, BuildPlanB: IBuildPlanSelectorItem) => {
                    return buildPlanA.internalProductName.localeCompare(
                        BuildPlanB.internalProductName,
                        'en',
                        {
                            sensitivity: 'base'
                        }
                    );
                }
            );
        }
    }
    /**
     * Given a build plan, should evaluate and select a group according to business rules.
     *
     * @param buildPlan A Build Plan to evaluate and select Group
     * @returns A string to classify the Build Plan Group
     */
    private selectBuildPlanGroup(bpsList: IBuildPlansByGroup, buildPlan: IBuildPlanSelectorItem): string | null {
        const status = buildPlan.status;
        const lockDownDate = buildPlan.lockDownDate;
        const limitLockdownDate = new Date();
        limitLockdownDate.setDate(limitLockdownDate.getDate() + 7);
        let selectedGroup: string | null = null;
        switch (status) {
        case EBuildPlanStatusLabel.NEW:
        case EBuildPlanStatusLabel.PENDING_APPROVAL:
        case EBuildPlanStatusLabel.APPROVED:
            bpsList.new.push(buildPlan);
            selectedGroup = this.GROUP_NEW;
            break;
        case EBuildPlanStatusLabel.OPEN:
        case EBuildPlanStatusLabel.PARTIALLY_OPEN:
            if (this.isBuildPlanLockingDownSoon(lockDownDate, limitLockdownDate)) {
                bpsList.lockingDownSoon.push(buildPlan);
                selectedGroup = this.GROUP_LOCKING_DOWN_SOON;
            } else if (!buildPlan.requested || buildPlan.requested === 0) {
                bpsList.new.push(buildPlan);
                selectedGroup = this.GROUP_NEW;
            } else {
                bpsList.alreadyRequested.push(buildPlan);
                selectedGroup = this.GROUP_ALREADY_REQUESTED;
            }
            break;
        case EBuildPlanStatusLabel.LOCKED:
        case EBuildPlanStatusLabel.DPM_PENDING_APPROVAL:
        case EBuildPlanStatusLabel.DPM_APPROVED:
            bpsList.underApproval.push(buildPlan);
            selectedGroup = this.GROUP_UNDER_APPROVAL;
            break;
        case EBuildPlanStatusLabel.BUILDING:
            bpsList.building.push(buildPlan);
            selectedGroup = this.GROUP_BUILDING;
            break;
        case EBuildPlanStatusLabel.COMPLETED:
        case EBuildPlanStatusLabel.CANCELED:
        case EBuildPlanStatusLabel.ARCHIVED:
            bpsList.closed.push(buildPlan);
            selectedGroup = this.GROUP_CLOSED;
            break;
        default:
        }
        return selectedGroup || null;
    }
    /**
     * Given a build plan lock down date and the limit date (a week from current date), check if the build plan
     * is locking down soon or not.
     *
     * @param buildPlanLockDown: build plan lock down date
     * @param limitDate: limit date to determine if the build plan is locking down soon
     * @returns boolean indicating if is locking down soon or not
     */
    private isBuildPlanLockingDownSoon(buildPlanLockDown: Date, limitDate: Date): boolean {
        if (!buildPlanLockDown || !limitDate) {
            return false;
        }
        buildPlanLockDown = new Date(buildPlanLockDown);
        const limitDateUTC = Date.UTC(
            limitDate.getFullYear(),
            limitDate.getMonth(),
            limitDate.getDate(),
            limitDate.getHours(),
            limitDate.getMinutes(),
            limitDate.getSeconds()
        );
        const lockDownDateUTC = Date.UTC(
            buildPlanLockDown.getFullYear(),
            buildPlanLockDown.getMonth(),
            buildPlanLockDown.getDate(),
            buildPlanLockDown.getHours(),
            buildPlanLockDown.getMinutes(),
            buildPlanLockDown.getSeconds()
        );
        return lockDownDateUTC < limitDateUTC;
    }
    /**
     * Subscribes to changes on the view mode to load Build Plan selectors
     */
    private subscribeRequestHasBeenDone() {
        if (this.isAccessory) {
            this.accessoryRequestRequested();
        } else {
            this.protoRequestRequested();
        }
    }
    private accessoryRequestRequested(): void {
        this.serviceSubscriptions.add(
            this.accessoryRequestService.requestedCountUpdate$.subscribe(() => {
                this.loadBuildPlanSelector();
            })
        );
    }
    private protoRequestRequested(): void {
        this.serviceSubscriptions.add(
            this.prototypeRequestService.requested.subscribe(() => {
                this.loadBuildPlanSelector();
            })
        );
    }
    /**
     * Given a new Build Plan, set the selectedBuildPlan value
     *
     * @param bp selected Build Plan
     */
    public setSelectedBuildPlan(bp: IBuildPlanSelectorItem | null): void {
        this.selectedBuildPlan = bp;
        this.buildPlan.next(this.selectedBuildPlan as IBuildPlanSelectorItem);
    }
}
