import {IFvcParameterSimple} from '@galvin/core/build-plan/field-value-management/fvc-parameters/interfaces/fvc-parameter.interface';

export interface IFVCSource {
    id?: number;
    buildPlanId?: number;
    fvcParameterId?: number;
    fvcValueId: number;
    source: number;
}

export interface IAccessoryValue {
    parameterId: number;
    parameterName: string;
    parameterOrder: number;
    value: string;
    valueId: number;
}

export interface IFVCParametersAndSources {
    fvcParameters: IFvcParameterSimple[];
    fvcSources: IFVCSource[];
}

export interface ISourceOption {
    value: number;
    text: string;
    enabled?: boolean;
}

export const FVCSources = {
    1: {text: '1st Source', name: 'Teal', short: '1st'},
    2: {text: '2nd Source', name: 'Cotton', short: '2nd'},
    3: {text: '3rd Source', name: 'Lavender', short: '3rd'},
    4: {text: '4th Source', name: 'Peach', short: '4th'},
    5: {text: '5th Source', name: 'Cream', short: '5th'}
};
