import { IScopeI18n } from './scope-i18n.interface';

export const SCOPE_MANAGEMENT: IScopeI18n = {
    TITLE: 'Scopes',
    COMMON: {
        FIELDS: {
            NAME: 'Scope name'
        },
        BUTTONS: {
            NEW: 'New Scope',
            EDIT_SCOPES: 'Edit {{amount}} Scope(s)'
        },
        DELETE_TITLE: 'Do you want to delete this scope?',
        DELETE_CONTENT: 'This action will delete : {{name}} and could not be undone.',
        WARNING_TOOLTIP: 'The scope is already being used and cannot be deleted.',
        DELETE_TOOLTIP: 'Delete scope',
        MESSAGES: {
            SUCCESS: 'Scope(s) successfully saved',
            DELETE: 'Scope(s) successfully deleted',
            SCOPE_ALREADY_EXIST: 'Scope name already exist',
            SCOPE_PARAM_VALUE_INCORRECT: 'Scope values or scope parameters are incorrect',
            ORDER_CHANGING: 'Updating Scope order <i class="ml-1 fas fa-spinner fa-spin"></i>',
            ORDER_CHANGE_SUCCESS: 'Scope order successfully updated',
            ORDER_CHANGE_ERROR:
                'Failed to update scope order, the data shown on the screen is outdated. ' +
                'Please refresh before performing the operation.'
        },
        COLUMNS: {
            SCOPE: 'Scope',
            SCOPE_ALIAS: 'Scope Alias',
            ACTIVE: 'Active',
            ORDER: 'Scope Order',
            SCOPE_VALUES: 'Scope Values',
            SCOPE_LABELS: 'Scope Labels'
        },
        REFRESH_SCOPES: 'Refresh scopes'
    },
    DIALOG_TITLE: {
        CREATE: 'Creating a Scope',
        EDIT_VALUE_TITLE: 'Do you want to propagate these values to BuildPlan?',
        EDIT: 'Editing the Scope:',
        EDIT_MASSIVE_TITLE: 'Do you want to propagate these values to BuildPlan?',
        PROPAGATE_SCOPES_MANAGEMENT_ALIAS: 'Propagate all values for the selected scopes',
        MESSAGE: {
            CAN_NOT_LABEL: '<b>You can not add a label using: ; , / \' ` " ´ . \\</b>'
        },
        BUTTONS: {
            CONTINUE_DELETE: 'Continue - Delete Team(s)',
            CONTINUE_KEEP_TEAM: 'Continue - Keep Team(s)'
        }
    },
    TOOLTIP_EDIT: 'Edit Scope',
    HINT_SCOPE_ORDER: 'The scope will be inserted after the one you choose.',
    BUILD_PLAN_ASSOCIATION: {
        DELETE_WARN: 'Are you sure you want to delete? This action can not be undone',
        CONFIRMATION_DELETE_DIALOG_TITLE: 'Delete {{amount}} Scope Association',
        SEARCH: 'Search scope by name',
        ADD_SCOPE_TO_BUILD_PLAN: 'Add Scope to Build Plan',
        SUCCESSFUL_DELETED: 'Scopes Association successfully deleted',
        ADD_SCOPE_ASSOCIATION: 'Add Scope Association',
        SCOPE_ALREADY_ASSOCIATED: 'Scope Already exists in Build Plan',
        SEARCH_SCOPE: 'Search by scope',
        ERROR_DUPLICATE: 'Build Plan already related to the Scope',
        DESCRIPTION_REMOVE_SCOPE:
            'The following Teams on this Build Plan have at least one Scope Label configured that matches the same Labels in the Scope association you are deleting. Do you want also to delete these Teams, and consequently their prototypes requests & accessory requests permanently?',
        DESCRIPTION_ADD_SCOPE:
            'There are some teams that are not in the build plan with the same scope labels, do you want to insert them?'
    },
    DIALOG: {
        EDIT_MASSIVE_TITLE: 'Editing the Scope(s)',
        BTN_SCOPE_MANAGEMENT_ONLY: 'Update scope management',
        BTN_SCOPE_MANAGEMENT_ASSOCIATIONS: 'Update associations',
        TOOLTIP_SCOPE_MANAGEMENT_ONLY: 'Updates the Scope Management without its associations',
        TOOLTIP_SCOPE_MANAGEMENT_ASSOCIATIONS:
            'Updates the Scope Management and all its associations',
        SCOPE_ORDER_HINT:
            'The scope being {{ operation }} will be placed directly <strong>after</strong> the one selected.',
        CREATED: 'created',
        EDITED: 'edited',
        OUTDATED_ORDERS_EDIT:
            'Cannot update the order, the loaded data is outdated. Please refresh the page to change the order.',
        OUTDATED_ORDERS_CREATE:
            'Cannot set an order different from last, the data displayed is outdated. ' +
            'Please set order as last or refresh the page.',
        REFRESH_SCOPES: 'Refresh scopes if you wish to change the original order',
        RESET_ORDER_EDIT: 'Reset Order',
        RESET_ORDER_CREATE: 'Set to Last'
    }
};
