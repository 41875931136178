import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { isEqual } from 'lodash';
import memoizeOne from 'memoize-one';

export enum EOptions {
    SUM = 'SUM',
    COUNT = 'COUNT',
    COUNT_SELECTED = 'COUNT_SELECTED'
}

@Component({
    selector: 'fullview-cells-summary',
    templateUrl: './fullview-cells-summary.component.html',
    styleUrls: ['./fullview-cells-summary.component.scss']
})
export class FullviewCellsSummaryComponent implements OnInit {
    readonly options = Object.values(EOptions);
    selectedOption: EOptions = EOptions.SUM;
    @Input() cellsValues: Array<number> = [];

    constructor(
        private i18n: TranslateService
    ) {}

    ngOnInit(): void {
        this.selectedOption = EOptions.SUM;
    }

    getOptionLabel(option: EOptions){
        const i18nPrefix = 'BUILD_PLAN.PROTOTYPE_REQUEST.PROTO_REQUEST_TABLE.FULL_VIEW_CELLS_SUMMARY';
        return this.i18n.instant(`${i18nPrefix}.${option}`);
    }

    optionChange(option: EOptions) {
        this.selectedOption = option;
    }

    getValue(option: EOptions) {
        switch(option){
        case EOptions.SUM:
            return this.calcSum(this.cellsValues);
        case EOptions.COUNT:
            return this.calcCount(this.cellsValues);
        case EOptions.COUNT_SELECTED:
            return this.calcCountCells(this.cellsValues);
        default:
            return 0;
        }
    }

    hasSelectedValues(): boolean {
        return this.cellsValues.length > 0;
    }

    private calcCount = memoizeOne((cellsValues: Array<number>) => {
        return cellsValues.filter(value => value > 0).length;
    }, isEqual);

    private calcCountCells = memoizeOne((cellsValues: Array<number>) => {
        return cellsValues.length;
    }, isEqual);

    private calcSum = memoizeOne((cellsValues: Array<number>) => {
        return cellsValues.reduce((acc, curr) => acc + curr, 0);
    }, isEqual);
}
