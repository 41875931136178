import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    forwardRef,
    HostBinding,
    HostListener,
    Input,
    Output,
    TemplateRef,
    ViewChild
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { EGenericFieldState } from '@galvin/views/partials/layout/generic-field/generic-field/generic-field-state.enum';
import {
    EGenericFieldCommonType,
    EGenericFieldImmutableType,
    GenericFieldValidatorFn,
    RegisterFunction,
    StateTemplateRecord
} from '@galvin/views/partials/layout/generic-field/generic-field/generic-field.interfaces';
import { GenericFieldService } from '@galvin/views/partials/layout/generic-field/generic-field/generic-field.service';
import { TranslateService } from '@ngx-translate/core';
import { Moment } from 'moment';
import { Key } from 'ts-key-enum';

@Component({
    selector: 'generic-field',
    exportAs: 'genericField',
    templateUrl: './generic-field.component.html',
    styleUrls: ['./generic-field.component.scss'],
    providers: [
        GenericFieldService,
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => GenericFieldComponent),
            multi: true
        }
    ]
})
export class GenericFieldComponent implements AfterViewInit, ControlValueAccessor {
    @HostBinding('class') private className = 'generic-field';

    //region Templates
    @ViewChild('defaultEditionTmpl') defaultEditionTmpl!: TemplateRef<any>;
    @ViewChild('defaultFieldTmpl') defaultFieldTmpl!: TemplateRef<any>;
    @ViewChild('savingTmpl') savingTmpl!: TemplateRef<any>;
    @ViewChild('genericFieldContainerRef', { static: true }) genericFieldContainerRef!: ElementRef;
    //endregion
    @HostBinding('class.readonly')
    @Input()
        readonly = false;

    @Input() handleClick: (mouseEvent: MouseEvent) => void = () => {
        if (
            !this.readonly &&
            this.state !== EGenericFieldState.editing &&
            this.state !== EGenericFieldState.saving &&
            this.type in EGenericFieldCommonType &&
            !this.isDisabled
        ) {
            if (!(this.type in this.fieldTmplRecord)) {
                this.fieldTmplRecord[this.type] = this.getFieldTemplate();
            }
            this.setState(EGenericFieldState.editing);
        }
    };
    @Input() fieldConfig!: Record<string, string | boolean | number | Array<any>>;
    @Input() validators!: GenericFieldValidatorFn<unknown>[];
    @HostBinding('attr.data-value') fieldValue: unknown | any;
    //region Description
    @Output() stateChange = new EventEmitter<EGenericFieldState>();
    @Output() errorsFromValidators = new EventEmitter<any>();
    @HostBinding('class.disabled') isDisabled = false;
    //endregion
    @HostBinding('class.invalid') invalid = false;
    isSingleClick = true;
    private stateTmplRecord: StateTemplateRecord = {};
    private fieldTmplRecord: Record<string, TemplateRef<any>> = {};
    private registerOnChangeFn!: RegisterFunction<unknown>;
    private registerOnTouchedFn!: RegisterFunction<unknown>;
    private originValue: unknown;
    private _cell: any;
    public dirty!: boolean;
    public addNewValue: boolean = false;

    constructor(
        private cdr: ChangeDetectorRef,
        private elRef: ElementRef<HTMLElement>,
        private genericFieldService: GenericFieldService,
        private i18n: TranslateService
    ) {}

    private _type!: EGenericFieldCommonType | EGenericFieldImmutableType;

    get self(): GenericFieldComponent {
        return this;
    }

    @HostBinding('attr.data-type')
    get type(): EGenericFieldCommonType | EGenericFieldImmutableType {
        return this._type;
    }

    set type(value: EGenericFieldCommonType | EGenericFieldImmutableType) {
        if (value) {
            this.elRef.nativeElement.classList.remove(this._type);
            this.elRef.nativeElement.classList.add(value);
            this._type = value;
        }
    }

    get cell(): any {
        return this._cell;
    }

    @Input()
    set cell(value: any) {
        this._cell = value;
    }

    private _state: EGenericFieldState = EGenericFieldState.reading;

    get state(): EGenericFieldState {
        return this._state;
    }

    //region Input
    @HostBinding('attr.data-state')
    @Input()
    set state(value: EGenericFieldState) {
        this.setState(value);
    }

    get fieldType(): EGenericFieldCommonType | EGenericFieldImmutableType {
        return this.type;
    }

    @Input()
    set fieldType(v: EGenericFieldCommonType | EGenericFieldImmutableType) {
        if (v) {
            this.type = v;
        }
    }

    get isEditing(): boolean {
        return this.state === EGenericFieldState.editing;
    }

    @HostBinding('class.immutable')
    get isImmutable(): boolean {
        return this.type in EGenericFieldImmutableType;
    }

    get stateTmpl(): TemplateRef<any> | null {
        return this.stateTmplRecord[this.state] as TemplateRef<any>;
    }

    get fieldTmpl(): TemplateRef<any> {
        return this.fieldTmplRecord[this.type] || this.getFieldTemplate();
    }

    get commonTypes(): string[] {
        return [
            EGenericFieldCommonType.text,
            EGenericFieldCommonType.number,
            EGenericFieldCommonType.color,
            EGenericFieldCommonType.date,
            EGenericFieldCommonType.datetimeLocal,
            EGenericFieldCommonType.email,
            EGenericFieldCommonType.image,
            EGenericFieldCommonType.month,
            EGenericFieldCommonType.search,
            EGenericFieldCommonType.tel,
            EGenericFieldCommonType.time,
            EGenericFieldCommonType.url,
            EGenericFieldCommonType.week
        ];
    }

    ngAfterViewInit(): void {
        this.stateTmplRecord[EGenericFieldState.editing] = this.defaultEditionTmpl;
        this.stateTmplRecord[EGenericFieldState.saving] = this.savingTmpl;
        if (!this.readonly && this.isImmutable) {
            this.stateTmplRecord[EGenericFieldState.reading] = this.getFieldTemplate();
        }
        this.cdr.detectChanges();
    }

    onCancel(event: MouseEvent): void {
        if (event) {
            event.stopPropagation();
        }
        this.fieldValue = this.originValue;
        this.invalid = false;
        this.toggleEditionMode();
        this.dirty = false;
    }

    onSave(event: MouseEvent): void {
        if (event) {
            event.stopPropagation();
        }
        this.validate();
        this.dirty = false;
        if (this.invalid) {
            return;
        }
        this.toggleEditionMode();
        this.propagateChange(this.fieldValue);
    }

    /**
     * @description Validate if exist validation to field.
     */
    validate(): void {
        if (this.validators && this.validators.length > 0) {
            this.invalid = false;
            const errors: any[] = [];
            this.validators.forEach((fn) => {
                const error = fn(this.fieldValue);
                if (error) {
                    this.invalid = true;
                    errors.push(error);
                }
            });
            if (errors.length > 0) {
                this.errorsFromValidators.emit(errors);
            }
        }
    }

    /**
     * Register a function to call on value change.
     * @param fn
     */
    registerOnChange(fn: RegisterFunction<unknown>): void {
        this.registerOnChangeFn = fn;
    }

    /**
     * Register a function to call on value touched.
     * @param fn
     */
    registerOnTouched(fn: RegisterFunction<unknown>): void {
        this.registerOnTouchedFn = fn;
    }

    /**
     * Set disabled state.
     * @param isDisabled
     */
    setDisabledState(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
        this.cdr.markForCheck();
    }

    /**
     * Propagate value to the component view.
     * @param value
     */
    writeValue(value: unknown): void {
        if (value !== undefined && value !== null) {
            this.fieldValue = value;
            this.originValue = value;
            this.cdr.markForCheck();
            return;
        }
        this.fieldValue = null;
        this.originValue = null;
    }

    /**
     * Propagate change to original value.
     * @param event
     */
    propagateChange(event: unknown): void {
        if (!this.readonly && !this.isDisabled) {
            this.originValue = this.fieldValue;
            if (this.registerOnChangeFn) {
                this.registerOnChangeFn(event);
            }
            this.cdr.detectChanges();
        }
    }

    isSaving(): boolean {
        return this._state === EGenericFieldState.saving;
    }

    getTemplate(state: EGenericFieldState): TemplateRef<any> {
        return this.stateTmplRecord[state] as TemplateRef<any>;
    }

    @HostListener('dblclick', ['$event'])
    dblClick(event: MouseEvent) {
        if (!this.readonly && this.state !== EGenericFieldState.editing) {
            this.isSingleClick = false;
            this.handleClick(event);
        }
    }

    setValueFromDatePicker(value: Moment): void {
        this.fieldValue = value.toISOString().slice(0, 10);
        setTimeout(() => {
            this.forceFocus();
        }
        , 100);
    }

    /**
     * Toggle edition/reading mode.
     * @private
     */
    private toggleEditionMode() {
        if (!this.isImmutable) {
            if (this.state === EGenericFieldState.editing) {
                this.setState(EGenericFieldState.reading);
            } else {
                this.setState(EGenericFieldState.editing);
            }
        }
    }

    @HostListener('click', ['$event'])
    private onClick(): void {
        this.isSingleClick = true;
        setTimeout(() => {
            if (!this.isSingleClick) {
                return;
            }
            if (
                !this.readonly &&
                this.state !== EGenericFieldState.editing &&
                this.state !== EGenericFieldState.saving &&
                this.type in EGenericFieldCommonType &&
                !this.isDisabled
            ) {
                if (!(this.type in this.fieldTmplRecord)) {
                    this.fieldTmplRecord[this.type] = this.getFieldTemplate();
                }
                this.setState(EGenericFieldState.editing);
                this.forceFocus();
            }
        }, 250);
    }

    /**
     * Set component state and dispatch an detect change event
     * @param state
     * @private
     */
    private setState(state: EGenericFieldState): void {
        if (this.cell) {
            this.cell.state = state;
        }
        this.elRef.nativeElement.classList.remove(this._state);
        this._state = state;
        this.elRef.nativeElement.classList.add(this._state);
        this.stateChange.emit(this.state);
        this.cdr.detectChanges();
    }

    /**
     * It Search for field by type on Query List of content and own List,
     * to know what render when component become into edition state.
     * @private
     */
    private getFieldTemplate(): TemplateRef<any> {
        const template = this.genericFieldService.getTemplate(this.fieldType);
        return template ? template : this.defaultFieldTmpl;
    }

    public forceFocus(): void {
        setTimeout(() => {
            if (this.elRef) {
                const input = this.elRef.nativeElement.querySelector('input');
                if (input) {
                    input.focus();
                }
            }
        }
        , 1);
    }

    public markAsDirty() {
        this.dirty = true;
    }

    private checkDoAction(event: any): void {
        if(!this.addNewValue){
            if (this.dirty) {
                this.onSave(event);
            } else {
                this.onCancel(event);
            }
        }
    }

    public onKeyDownCell(event: any): void {
        if(!this.addNewValue){
            if (event.key === Key.Enter) {
                this.checkDoAction(event);
            }
            else if (event.key === Key.Escape ) {
                this.onCancel(event);
            }
        }
    }

    public onBlurCell(event: any, openedDate?: any): void {
        if (this.type === EGenericFieldCommonType.date && openedDate) {
            //Needed delay to check opened datepicker
            setTimeout(() => {
                if (!openedDate.opened) {
                    this.checkDoAction(event);
                }
            }
            , 100);
        }
        else {
            this.checkDoAction(event);
        }
    }

}
