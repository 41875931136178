import { Component, HostBinding, Input } from '@angular/core';

@Component({
    selector: 'save-generic-fvc-field',
    templateUrl: './save-generic-fvc-field.component.html',
    styleUrls: ['./save-generic-fvc-field.component.scss']
})
export class SaveGenericFvcFieldComponent {
    @Input() isSaving!: boolean;
    @HostBinding('class.disabled')
    @Input()
        disabled!: boolean;
    constructor() {}
}
