import { ChangeDetectorRef, Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GoogleAnalyticsEvents } from '@galvin/core/analytics/google-analytics-events';
import { GoogleAnalyticsService } from '@galvin/core/analytics/google-analytics.service';
import { UserPermissionsService } from '@galvin/core/auth/services/user-permissions.service';
import { ERolesBuildPlan } from '@galvin/core/auth/_enums/roles-build-plan.enum';
import { EAccessoryStatusLabel } from '@galvin/core/build-plan/accessory-management/enums/accessory-type.enum';
import { IAccessoryRequestTeam } from '@galvin/core/build-plan/accessory-request-management/interface/accessory-request-team.interface';
import { AccessoryRequestManagementService } from '@galvin/core/build-plan/accessory-request-management/services/accessory-request-management.service';
import { IAccessoryValue } from '@galvin/core/build-plan/configuration-management';
import { IBuildPlan } from '@galvin/core/build-plan/management';
import { BuildPlanAccessoryRequestPreviewService } from '@galvin/core/build-plan/management/services/build-plan-accessory-request-preview.service';
import { ETeamProtoRequestJustification } from '@galvin/core/build-plan/prototype-request-management/enums/team-proto-request-justification-enum';
import { IAccessoryRequest } from '@galvin/core/build-plan/prototype-request-management/interfaces/prototype-request-with-configuration-and-team.interface';
import { IRequestActivity } from '@galvin/core/build-plan/prototype-request-management/interfaces/request-activity.interface';
import { LayoutUtilsService, ToastrType } from '@galvin/core/_base/crud';
import { IDialogData } from '@galvin/core/_base/general';
import { RequestApprovalCommentDialogComponent } from '@galvin/views/partials/layout/request-details-dialog/request-approval-comment-dialog/request-approval-comment-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { EMPTY, Observable, of, Subscription } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { IAccessoryRequestTeamPayloadRequest } from '@galvin/core/build-plan/accessory-request-management/services/accessory-request-team.interface';
import { LocalStorageHelper } from '@galvin/core/storage/local-storage-helper.service';
import { ICommentWithMentions } from '@galvin/core/comments/models/icomment-with-mentions.model';

@Component({
    selector: 'app-accessory-request-dialog',
    templateUrl: './accessory-request-dialog.component.html',
    styleUrls: ['./accessory-request-dialog.component.scss']
})
export class AccessoryRequestDialogComponent implements OnInit {
    devicesNumber = 0;
    requestForm!: FormGroup;
    justificationForm!: FormGroup;
    accessoryRequest!: IAccessoryRequest;

    loadRequest!: boolean;
    submitted!: boolean;

    requestActivity!: IRequestActivity[];
    loadingRequestActivity!: boolean;
    errorLoadingActivity!: boolean;
    requestButtonLabel!: string;
    changedComment = false;
    loggedUser!: string;
    comment = '';
    usersToNotify: string[] = [];

    private accessoryDataFromServer!: IAccessoryRequestTeam;
    private readonly JUSTIFICATIONS = 'justifications';
    private subscription = new Subscription();
    private readonly allowedStatusToRequest = [
        EAccessoryStatusLabel.OPEN,
        EAccessoryStatusLabel.LOCKED,
        EAccessoryStatusLabel.READY_TO_DELIVER,
        EAccessoryStatusLabel.IN_TRANSIT,
        EAccessoryStatusLabel.COMPLETED
    ];

    constructor(
        private dialog: MatDialog,
        private dialogRef: MatDialogRef<AccessoryRequestDialogComponent>,
        private fb: FormBuilder,
        private layoutUtilsService: LayoutUtilsService,
        private translate: TranslateService,
        private googleAnalytics: GoogleAnalyticsService,
        private accessoryRequestManagementService: AccessoryRequestManagementService,
        private buildPlanAccessoryRequestPreviewService: BuildPlanAccessoryRequestPreviewService,
        private permissionService: UserPermissionsService,
        private localStorageHelper: LocalStorageHelper,
        private cdr: ChangeDetectorRef,
        @Optional()
        @Inject(MAT_DIALOG_DATA)
        public data: any
    ) {
        this.loggedUser = `${this.localStorageHelper?.getLoggedUser()?.name} ${this.localStorageHelper?.getLoggedUser()?.lastName || ''}`;
    }

    get accessoryData(): IAccessoryRequestTeam {
        return this.accessoryDataFromServer || this.data.accessory;
    }

    set accessoryData(v: IAccessoryRequestTeam) {
        this.accessoryDataFromServer = v;
    }

    get buildPlan(): IBuildPlan {
        return this.data.buildPlan;
    }

    get requestPreview(): boolean {
        return this.data.requestPreview;
    }

    get disableForm(): boolean {
        return this.data.readOnly;
    }

    ngOnInit(): void {
        this.requestButtonLabel = this.getRequestButtonLabel();
        this.loadRequest = true;
        this.loadingRequestActivity = true;
        this.errorLoadingActivity = false;
        this.submitted = false;

        if (this.requestPreview) {
            this.loadAllAccessoriesParameters();
        } else {
            this.loadAccessoryParameters();
            this.loadAccessoryHistory();
        }
    }

    parseDate(date: Date): string {
        if (date === null || date === undefined) {
            return '--';
        }
        return moment(date).format('MMM DD, YYYY');
    }

    subscribeForm(): void {
        this.requestForm.valueChanges.subscribe((rest) => {
            if (rest.quantity < 0) {
                this.requestForm.controls['quantity'].setValue(0);
            }
            if (rest.quantity > 10000) {
                this.requestForm.controls['quantity'].setValue(10000);
            }
        });
    }

    close(): void {
        if (!this.submitted) {
            this.dialogRef.close();
        }
    }

    formBuilder(): void {
        this.comment = this.accessoryRequest.teamRequest?.additionalComments || '';
        this.requestForm = this.fb.group({
            quantity: [{value:this.accessoryRequest.teamRequest?.quantity || 0, disabled: this.disableForm}, Validators.required],
            justifications: this.fb.group({
                CARRIERS: [{value:this.getJustificationValue(ETeamProtoRequestJustification.CARRIERS), disabled: this.disableForm}],
                MARKETING: [{value:this.getJustificationValue(ETeamProtoRequestJustification.MARKETING), disabled: this.disableForm}],
                SHOW_OFF: [{value:this.getJustificationValue(ETeamProtoRequestJustification.SHOW_OFF), disabled: this.disableForm}],
                MANUAL_TESTING: [
                    {value:this.getJustificationValue(ETeamProtoRequestJustification.MANUAL_TESTING), disabled: this.disableForm}
                ],
                AUTOMATED_TESTING: [
                    {value:this.getJustificationValue(ETeamProtoRequestJustification.AUTOMATED_TESTING), disabled: this.disableForm}
                ],
                OTHER: [{value:this.getJustificationValue(ETeamProtoRequestJustification.OTHER), disabled: this.disableForm}]
            }),
            status: [this.accessoryRequest.teamRequest?.status || 'Mock 1 st pass']
        });

        this.justificationForm = this.requestForm.get(this.JUSTIFICATIONS) as FormGroup;
    }

    isValid(formControlName: string, firstColor?: string, secondColor?: string): string {
        if (firstColor && secondColor) {
            return this.requestForm.get(formControlName)?.invalid ? firstColor : secondColor;
        }
        return this.requestForm.get(formControlName)?.invalid ? 'red' : '#e2e5ec';
    }

    checkIfExist(formControlName: string): unknown {
        return this.requestForm.get(formControlName)?.value ? '#f7f8fa' : '#FFF';
    }

    checkValidForm(): boolean {
        return !this.submitted && this.requestForm.valid;
    }

    submit(): void {
        this.submitted = true;
        this.requestForm.disable();
        let response: Observable<any>;
        if (this.isAccessoryStatusOpen() || this.hasPermittedRole()) {
            const payload = this.requestForm.value as IAccessoryRequestTeamPayloadRequest;
            payload.additionalComments = this.comment;
            payload.usersMentionsNotification = this.usersToNotify;
            response =
                this.accessoryRequestManagementService.upsertAccessoryTeamRequestsByAccessoryAndTeam(
                    this.accessoryData.accessoryId,
                    this.accessoryData.bpTeamId as number,
                    payload
                );
            this.googleAnalytics.sendEvent(
                GoogleAnalyticsEvents.BUILD_PLAN.PROTOTYPE_REQUEST.CATEGORY,
                GoogleAnalyticsEvents.BUILD_PLAN.PROTOTYPE_REQUEST.SUBMIT_REQUEST.ACTION,
                GoogleAnalyticsEvents.BUILD_PLAN.PROTOTYPE_REQUEST.SUBMIT_REQUEST.LABEL
            );
        } else {
            const title = this.translate.instant(
                'BUILD_PLAN.PROTOTYPE_REQUEST.DIALOG.REQUEST_BY_APPROVAL.TITLE'
            );
            response = this.dialog
                .open<RequestApprovalCommentDialogComponent, IDialogData>(
                    RequestApprovalCommentDialogComponent,
                    {
                        panelClass: 'custom-dialog',
                        width: '450px',
                        disableClose: true,
                        hasBackdrop: true,
                        data: {
                            title,
                            cancelButton: {
                                name: 'cancel',
                                value: this.translate.instant('COMMON.BUTTONS.CANCEL')
                            },
                            saveOrEditButton: {
                                name: 'save',
                                value: this.translate.instant('COMMON.BUTTONS.SAVE')
                            },
                            enabled: true
                        }
                    }
                )
                .afterClosed()
                .pipe(
                    switchMap((data) => {
                        if (data) {
                            this.googleAnalytics.sendEvent(
                                GoogleAnalyticsEvents.BUILD_PLAN.ACCESSORY_REQUEST_BY_APPROVAL
                                    .CATEGORY,
                                GoogleAnalyticsEvents.BUILD_PLAN.ACCESSORY_REQUEST_BY_APPROVAL
                                    .SUBMIT_REQUEST.ACTION,
                                GoogleAnalyticsEvents.BUILD_PLAN.ACCESSORY_REQUEST_BY_APPROVAL
                                    .SUBMIT_REQUEST.LABEL
                            );
                            const approvalRequest = {
                                ...this.requestForm.value,
                                ...data
                            };
                            approvalRequest.buildPlanTeamAssociationId =
                                this.data.accessory.bpTeamId;
                            approvalRequest.accessoryId = this.data.accessory.accessoryId;
                            delete approvalRequest.status;

                            return this.accessoryRequestManagementService
                                .upsertAccessoryTeamRequestsByAccessoryAndTeamByApproval(
                                    approvalRequest
                                )
                                .toPromise()
                                .then(() => approvalRequest);
                        }
                        this.submitted = false;
                        this.requestForm.enable();
                        return EMPTY;
                    })
                );
        }
        response.subscribe(
            (res) => {
                this.dialogRef.close(res);
            },
            ({ error, status }) => {
                const { msg } = error;
                if (status === 400) {
                    this.layoutUtilsService.showToastrNotification(
                        msg ? msg : this.translate.instant('COMMON.ERROR_REQUEST'),
                        ToastrType.error
                    );
                } else {
                    this.layoutUtilsService.showToastrNotification(
                        this.translate.instant('COMMON.ERROR_REQUEST'),
                        ToastrType.error
                    );
                }
                this.submitted = false;
            }
        );
    }

    /**
     * Checks if there's any activity information to show
     *
     * @return true if there is activity information, false otherwise
     */
    hasActivityInformation(): boolean {
        return this.requestActivity && this.requestActivity.length > 0;
    }

    getTooltipRequestButton(): string {
        if (this.isNotChangeForm()) {
            return this.translate.instant('BUILD_PLAN.PROTOTYPE_REQUEST.DIALOG.LABELS.NO_CHANGES');
        }
        return '';
    }

    isNotChangeForm(): boolean {
        return this.requestForm.invalid || !(this.hasChanges(this.requestForm.getRawValue()) || this.changedComment);
    }

    getAccessories(): IAccessoryValue[] {
        if (!this.requestPreview && this.accessoryRequest.accessory?.sections?.accessoryValueList) {
            return this.accessoryRequest.accessory?.sections?.accessoryValueList;
        }
        if (this.requestPreview && this.accessoryRequest.sections?.accessoryValueList) {
            return this.accessoryRequest?.sections?.accessoryValueList;
        }

        return [];
    }

    applyComments(commentWithMentions: ICommentWithMentions): void {
        this.comment = commentWithMentions.commentText;
        this.usersToNotify = commentWithMentions.usersToNotify;
        this.changedComment = true;
    }

    private loadAccessoryParameters(): void {
        this.subscription.add(
            this.accessoryRequestManagementService
                .getAccessoryTeamRequestsByAccessoryAndTeam(
                    this.accessoryData.accessoryId,
                    this.accessoryData.bpTeamId as number
                )
                .pipe(
                    catchError(() => {
                        this.layoutUtilsService.showToastrNotification(
                            this.translate.instant('COMMON.UNKNOWN_ERROR'),
                            ToastrType.error
                        );
                        this.close();
                        return of(null);
                    })
                )
                .subscribe(
                    (res: any) => {
                        this.accessoryRequest = res;
                        this.formBuilder();
                        this.subscribeForm();
                        this.loadRequest = false;
                    },
                    () => {
                        this.formBuilder();
                        this.subscribeForm();
                        this.loadRequest = false;
                    }
                )
        );
    }

    isRequestApproval(): boolean {
        return (
            this.accessoryHasAllowedStatus() &&
            !this.isAccessoryStatusOpen() &&
            !this.hasPermittedRole()
        );
    }

    private loadAllAccessoriesParameters(): void {
        this.subscription.add(
            this.buildPlanAccessoryRequestPreviewService
                .getAllAccessories(this.buildPlan.id as number, this.accessoryData.accessoryId)
                .pipe(
                    catchError(() => {
                        this.layoutUtilsService.showToastrNotification(
                            this.translate.instant('COMMON.UNKNOWN_ERROR'),
                            ToastrType.error
                        );
                        this.close();
                        return of(null);
                    })
                )
                .subscribe(
                    (res: any) => {
                        this.accessoryData = res;
                        this.accessoryRequest = res;
                        this.loadRequest = false;
                    },
                    () => {
                        this.loadRequest = false;
                    }
                )
        );
    }

    private loadAccessoryHistory(): void {
        this.subscription.add(
            this.accessoryRequestManagementService
                .getPrototypeRequestsHistoryByAccessoryAndTeam(
                    this.accessoryData.accessoryId,
                    this.accessoryData.bpTeamId as number
                )
                .pipe(
                    catchError(() => {
                        this.loadingRequestActivity = false;
                        this.errorLoadingActivity = true;
                        return of(null);
                    })
                )
                .subscribe(
                    (res: any) => {
                        this.requestActivity = res;
                        this.loadingRequestActivity = false;
                    },
                    () => {
                        this.errorLoadingActivity = true;
                    }
                )
        );
    }

    private getJustificationValue(
        key: ETeamProtoRequestJustification
    ): boolean | { order?: number; value?: boolean } | undefined {
        return this.accessoryRequest.teamRequest?.justifications?.[key] || false;
    }

    private accessoryHasAllowedStatus() {
        const accessoryStatus = this.accessoryData.accessoryStatus;
        return this.allowedStatusToRequest.some((item) => accessoryStatus === item);
    }

    private hasPermittedRole() {
        const allowedRoles = [
            ERolesBuildPlan.BP_GPM,
            ERolesBuildPlan.BP_HW_PM,
            ERolesBuildPlan.BP_ADMIN
        ];
        return this.permissionService.verifyPermissionsForBuildPlan(allowedRoles);
    }

    private getRequestButtonLabel(): string {
        let requestLabel = 'BUILD_PLAN.PROTOTYPE_REQUEST.DIALOG.LABELS.REQUEST';
        if (this.isRequestApproval()) {
            requestLabel = 'BUILD_PLAN.PROTOTYPE_REQUEST.DIALOG.LABELS.REQUEST_APPROVAL';
        }
        return this.translate.instant(requestLabel);
    }

    /**
     * Checks if the user made changes to any of the original form values
     *
     * @param form current form
     * @return true if form has changes, false otherwise
     */
    private hasChanges(form: any): boolean {
        let hasChanges = false;
        Object.keys(form).some((item: any) => {
            if (
                !form[item] &&
                this.accessoryRequest.teamRequest &&
                !(this.accessoryRequest.teamRequest as any)[item]
            ) {
                return false;
            }
            if (item === this.JUSTIFICATIONS) {
                Object.keys(form[item]).some((justification: any) => {
                    if (
                        form[item][justification] !==
                        (this.accessoryRequest.teamRequest as any)[item][
                            (ETeamProtoRequestJustification as any)[justification]
                        ]
                    ) {
                        hasChanges = true;
                    }
                    return hasChanges;
                });
            } else if (form[item] !== (this.accessoryRequest.teamRequest as any)[item]) {
                hasChanges = true;
            }
            return hasChanges;
        });
        return hasChanges;
    }

    private isAccessoryStatusOpen(): boolean {
        return this.accessoryData.accessoryStatus === EAccessoryStatusLabel.OPEN;
    }
}
