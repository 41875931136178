
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'getObjectKeys'
})
export class GetObjectKeysPipe implements PipeTransform {
    transform(value: Object): string[] {
        return Object.keys(value);
    }
}
