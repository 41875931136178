import { IFvcParametersI18n } from './fvc-parameters-i18n.interface';

export const FVC_PARAMETERS: IFvcParametersI18n = {
    MESSAGE_UPDATED: 'FVC Value updated',
    EDIT_VALUE_TITLE: 'Do you want to edit this FVC Value?',
    BP_FVC_VALUE_ASSOCIATIONS:
        'The following Build Plans associated values can be updated to {{fvcValue}}. ',
    BP_CONFIGURATION_ALIAS_ASSOCIATIONS:
        'It is suggested that you change the configuration\'s aliases to reflect this value\'s edition.',
    TOOLTIP_BP_STATUS: 'Build Plan Status',
    TOOLTIP_BP_INTERNAL_NAME: 'Build Plan Internal Product Name',
    BUTTON_NOT_UPDATE_ASSOCIATIONS: 'Update Fvc Value',
    BUTTON_UPDATE_ASSOCIATIONS: 'Update Associations',
    TOOLTIP_BTN_UPDATE_VALUE_ONLY:
        'Updates the value from this parameter, without updating the Build Plan configurations that may use this value',
    TOOLTIP_BTN_UPDATE_ASSOCIATIONS:
        'Updates the value from this parameter, and it updates the Build Plan configurations you\'ve checked above',
    TOOLTIP_BTN_UPDATE_ASSOCIATIONS_AND_ALIASES:
        'Updates the value from this parameter, and updates the Build Plan configurations (and their aliases) you\'ve checked above',
    CREATE_BUTTON: 'Add Fvc Parameter',
    FVC_PARAMETER: 'Fvc Parameter',
    EDIT_TOOLTIP: 'Edit Fvc Parameter',
    DELETE_TOOLTIP: 'Delete Fvc Parameter',
    WARNING_TOOLTIP: 'The parameter is already being used and cannot be deleted or you don\'t have permission to do this action.',
    DELETE_TITLE: 'Do you want to delete this FVC parameter?',
    DELETE_CONTENT: 'This action will delete: {{name}} and could not be undone.',
    TOOLTIP_UPDATE_ALIAS:
        'The configuration alias will be updated from {{name}} to {{suggestName}}',
    ADD_OR_EDIT_DIALOG: {
        CREATE_TITLE: 'Create a new FVC Parameter',
        EDIT_TITLE: 'Editing the FVC Parameter: ',
        FVC_VALUE: 'Enter with a Value',
        ADD_FVC_VALUE: 'Write a FVC Value',
        ORDER_VALUE: 'FVC Parameter Order',
        FIELD: '1.Field',
        FIELD_VALUES: '2.Values',
        ERROR_FIELD_NAME: 'Please provide a valid name',
        ERROR_DATA_TYPE: 'Please select a data type',
        ERROR_FIELD_TYPE: 'Please select a field type',
        ERROR_FVC_VALUE_REQUIRED: 'Please provide a valid value',
        ERROR_FVC_VALUE: 'This FVC value already exist',
        SELECT_DATA_TYPE: 'Select a data type',
        SELECT_FIELD_TYPE: 'Select a field type',
        MESSAGE_SAVED: 'FVC Parameter saved',
        MESSAGE_UPDATED: 'FVC Parameter updated',
        FVC_PARAMETER_DUPLICATE: 'FVC Parameter Already Exists',
        DELETE_VALUE_TITLE: 'Do you want to delete this FVC value?',
        DELETE_VALUE_CONTENT: 'This action will delete: {{name}} and could not be undone.',
        CREATE_VALUE_TITLE: 'Do you want to create this FVC value?',
        CREATE_VALUE_CONTENT: 'This action will create: {{name}}',
        EDIT_VALUE_TITLE: 'Do you want to edit this FVC value?',
        EDIT_VALUE_CONTENT: 'This action will edit to: {{name}}',
        DELETE_VALUE_TOOLTIP: 'Delete FVC value',
        WARNING_VALUE_TOOLTIP: 'The value is already being used and cannot be deleted.'
    }
};
