// Angular
import { Pipe, PipeTransform } from '@angular/core';
import { DateTimeUtils } from './../../../utils/moment-utils';

@Pipe({
    name: 'parseChineseDate'
})
export class ParseChineseDatePipe implements PipeTransform {
    /**
     * Transform to string with chinese time zone
     *
     * @param value string with Date information to transform
     * @returns formatted String date like Mar 03, 2021 GMT+08
     */
    transform(value: string | undefined): string {
        if (value != '--' && value != undefined) {
            return DateTimeUtils.chineseDateFormat(value, false);
        }
        return '--';
    }
}
