import {
    HttpEvent,
    HttpHandler,
    HttpHeaders,
    HttpInterceptor,
    HttpRequest,
    HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SecurityService, User } from '@galvin/core/auth';
import { LayoutUtilsService, ToastrType } from '@galvin/core/_base/crud';
import { TranslateService } from '@ngx-translate/core';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError,  tap } from 'rxjs/operators';
import { AppConstants } from '../../views/share/app-constants';
import { SecurityMotorolaService, internalToken, tokenSymbol } from '../auth/services/security-motorola.service';
import { E2eRunnerHandler } from '../utils/e2e/e2e-runner-handler';

/**
 * HTTP Interceptor to detect when the session is expired and handle that to redirect user to login page
 *
 * @export
 * @class GalvinHttpInterceptor
 * @implements {HttpInterceptor}
 */
@Injectable()
export class GalvinHttpInterceptor implements HttpInterceptor {

    private readonly FORCE_QUEUE_EXECUTION_PARAMETER = 'force-queue-execution';
    private readonly RUNNER_TEST_PARAMETER = 'runner-test';

    public constructor(
        private securityService: SecurityService,
        private securityMotorolaService: SecurityMotorolaService,
        private translate: TranslateService,
        private layoutUtilsService: LayoutUtilsService,
        private router: Router
    ) {}

    /**
     * Function that intercepts each request to make sure user authentication token is up to date and sent with every request
     *
     * @param {HttpRequest<any>} request
     * @param {HttpHandler} next
     * @returns {Observable<HttpEvent<any>>}
     * @memberof GalvinHttpInterceptor
     */
    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const publicEndpoints = ['/lenovo/validate'];

        const isPublicEndpoint = publicEndpoints.some((url) => request.url.endsWith(url));

        if (isPublicEndpoint) {
            return next.handle(request);
        }

        const user: User = this.securityService.getInMemoryUser() as any;
        // Do not change the original request, instead of that clone i

        if (user?.domain === AppConstants.MOTOROLA && !this.securityService.getInMemoryUser()) {
            this.redirectToSignOut();
        }

        const req: HttpRequest<any> | null = user
            ? this.cloneRequestWithAuthorization(request, user)
            : request.clone();

        if (req) {
            return next.handle(req).pipe(
                tap((httpEvent: HttpEvent<any>) =>{
                    // Skip request
                    if(httpEvent.type === 0){
                        return;
                    }
                    if (httpEvent instanceof HttpResponse) {
                        if(httpEvent.headers.has('x-token')) {
                            const token=httpEvent.headers.get('x-token');
                            internalToken[tokenSymbol] = token?token:'';
                        }
                    }
                }),
                catchError((err) => {
                    switch (err.status) {
                    case 0:
                        if (err.url.indexOf('notifications/latest') >-1 || err.url.indexOf('auth/validate') >-1) {
                            // eslint-disable-next-line no-console
                            console.error(err);
                            return EMPTY;
                        }
                        this.layoutUtilsService.showToastrNotification(
                            this.translate.instant('COMMON.NO_CONNECTION'),
                            ToastrType.error
                        );
                        break;

                    case 302:
                        this.layoutUtilsService.showToastrNotification(
                            this.translate.instant('COMMON.ON_MAINTENANCE'),
                            ToastrType.warning
                        );
                        this.router.navigate(['/']);
                        setTimeout(() => {
                            location.reload();
                        }, 1000);
                        break;
                    case 401:
                        this.redirectToSignOut();
                        break;
                    }
                    return throwError(err);
                })
            );
        }
        return EMPTY;
    }

    /**
     * Function that clones the request and adds authorization headers
     *
     * @param request: HttpRequest
     * @param user: logged in user
     */
    private cloneRequestWithAuthorization(
        request: HttpRequest<any>,
        user: User
    ): HttpRequest<any> | null {

        let token = user.token;

        let _headers =  new HttpHeaders({
            Domain: <string>user.domain
        });

        const e2eRunnerHandler = new E2eRunnerHandler();

        if (e2eRunnerHandler.isRunnerTest()){
            _headers = _headers.set(this.RUNNER_TEST_PARAMETER, 'true');
        }

        if (user.domain === AppConstants.MOTOROLA) {
            token = internalToken[tokenSymbol];
            _headers =  new HttpHeaders({
                Authorization: <string>token,
                Domain: <string>user.domain
            });
            if (e2eRunnerHandler.canForceCloudTaskExecution()){
                _headers = _headers.set(this.FORCE_QUEUE_EXECUTION_PARAMETER, 'true');
            }
            return request.clone({
                headers: _headers,
                withCredentials: true
            });
        }else{
            _headers = new HttpHeaders({
                Authorization: <string>token,
                Domain: <string>user.domain,
            });
            if (e2eRunnerHandler.canForceCloudTaskExecution()){
                _headers = _headers.set(this.FORCE_QUEUE_EXECUTION_PARAMETER, 'true');
            }
            return request.clone({
                headers: _headers
            });
        }
    }
    /**
     * Signs out user and redirects to login page
     */
    private redirectToSignOut(): void {
        this.securityService.signOut(false);
        location.href = '/';
    }
}
