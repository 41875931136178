export class ConfigurationConstants {
    public static UNDERSCORE = '_';
    public static DASH = '--';
    public static CONFIGURATION = 'CONFIGURATION';
    public static CONFIGURATION_STATUS = 'CONFIGURATION_STATUS';
    public static OVERWRITTEN = 'OVERWRITTEN';
    public static DATE_DEFAULT = 'DATE_DEFAULT';
    public static RELATIVE_DATES = 'RELATIVE_DATES';
    public static CONFIG_STATUS = 'CONFIG_STATUS';
    public static LOCATION = 'LOCATION';
    public static HW_REVISION = 'HW Revision';
    public static BUILD_RECIPE = 'Build Recipe';
    public static ORDER = 'ORDER';
    public static NAME = 'NAME';
    public static ID = 'ID';
    public static IS_OPENED = 'IS_OPENED';
    public static IS_TEMPORARY = 'IS_TEMPORARY';
    public static FVC_PLANNED_QUANTITY = 'FVC_PLANNED_QUANTITY';
    public static FVC_CURRENT_QUANTITY = 'FVC_CURRENT_QUANTITY';
    public static FVC_FINISHED_QUANTITY = 'FVC_FINISHED_QUANTITY';
    public static BUFFER_PERCENT = 'BUFFER_PERCENT';
    public static FVC_BUFFER = 'FVC_BUFFER';
    public static CONFIGURATION_STATUS_DUE_DATE = 'CONFIGURATION_STATUS_DUE_DATE';
    public static CONFIGURATION_STATUS_TEMPORARY_DISABLE_DATE =
        'CONFIGURATION_STATUS_TEMPORARY_DISABLE_DATE';
}
