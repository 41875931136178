import { HttpClient } from '@angular/common/http';
import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AuthNoticeService, User, UserLoaded, currentUser } from '@galvin/core/auth';
import { AppConstants } from '@galvin/views/share/app-constants';
import { Store, select } from '@ngrx/store';
import { CookieService } from 'ngx-cookie-service';
import { EMPTY, Observable, Subject, Subscription, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { AppState } from '../../reducers';
import { LocalStorageHelper } from '../../storage/local-storage-helper.service';
import { LocalStorageUser, localStorageUserToUser, userToLocalStorageUser } from '../_models/local-storage-user.model';
import { UserPermissionsService } from './user-permissions.service';

@Injectable({
    providedIn: 'root'
})
export class SecurityBaseService implements OnDestroy{
    public logging: Subject<boolean> = new Subject();

    protected user?: User | null;

    protected server: string = environment.baseUrlApi;
    public urlToAccess?: string | null;
    protected validateCalled = false;

    protected signInSubscription?: Subscription;

    public constructor(
        protected localStorageHelper: LocalStorageHelper,
        protected cookieService: CookieService,
        protected ngZone: NgZone,
        protected router: Router,
        protected httpClient: HttpClient,
        protected store: Store<AppState>,
        protected authNoticeService: AuthNoticeService,
        protected userPermissionsService: UserPermissionsService,
    ) { }


    ngOnDestroy() {
        // Cancel auth service subscription to prevent from leaking
        if (this.signInSubscription) {
            this.signInSubscription.unsubscribe();
        }
    }



    /**
     * Retrieves logged user (if any) from local storage
     */
    public getLocalStorageUser(): User | null {
        const localStorageUser: LocalStorageUser = this.localStorageHelper.getLoggedUser();
        const localUser: User | null = localStorageUser
            ? localStorageUserToUser(localStorageUser)
            : null;
        if (!this.store.pipe(select(currentUser))) {
            this.store.dispatch(new UserLoaded({ user: localUser }));
        }
        return localUser;
    }


    /**
     * Returns user object (copy) from this service
     */
    public getInMemoryUser(): User | null {
        if (!this.user) {
            this.user = this.localStorageHelper.getLoggedUser();
        }

        return this.user ? { ...this.user } : null;
    }

    /**
     * Sets new user information as the logged in user, removing sensible information from local storage
     *
     * @param user
     */
    public setUser(user: User): void {
        //set user picture
        if (this.user) {
            user.token = this.user.token;
            user.sub = this.user.sub;
            user.domain = this.user.domain;
            if (this.user.picture) {
                user.picture = this.user.picture;
            } else {
                user.picture = 'assets/images/user.png';
            }
        }
        const userStorage: User | null = this.getLocalStorageUser();
        if (userStorage && userStorage.expirationDate) {
            user.expirationDate = userStorage.expirationDate;
        }

        this.user = user ? { ...user } : null;
        this.localStorageHelper.setLoggedUser(userToLocalStorageUser(user));
        this.store.dispatch(new UserLoaded({ user }));
    }
    /**
     * Set exp from the storage
     *
     * @param user
     */
    public setTokenByTest(exp: string) {
        this.localStorageHelper.setTokenByTest(exp);
    }
    /**
     * Get exp from the storage
     *
     * @param user
     */

    public getTokenByTest() {
        return this.localStorageHelper.getTokenByTest();
    }

    /**
     * Set qty refresh token into the storage
     *
     * @param qty
     */
    public setQtyRefreshTokenExp(qty: number) {
        this.localStorageHelper.setQtyRefreshToken(qty);
    }

    /**
     * Get qty refresh token from the storage
     *
     * @returns {number}
     */
    public getQtyRefreshTokenExp() {
        return this.localStorageHelper.getQtyRefreshToken();
    }

    /**
     * Set user info from the database
     *
     * @param user
     */
    private setUserInfo(user: User): void {
        const localStorageUser: LocalStorageUser = this.localStorageHelper.getLoggedUser();
        localStorageUser.active = user.active;
        localStorageUser.lastLogin = user.lastLogin;
        localStorageUser.firstAccess = user.firstAccess;
        localStorageUser.preferences = user.preferences;
        localStorageUser.name = user.name;
        localStorageUser.lastName = user.lastName;
        localStorageUser.email = user.email;
        localStorageUser.token = user.token;
        localStorageUser.sub = user.sub;
        localStorageUser.domain = user.domain;

        if (user.roles) {
            this.userPermissionsService.setUserRoles(user.roles);
        }
        this.userPermissionsService.setInformationUser(user);
    }

    /**
     * Removes logged in user information after logout
     */
    public deleteUser(): void {
        const domain: string | null | undefined = this.user ? this.user.domain : null;
        this.user = null;
        this.localStorageHelper.removeLoggedUser();
        // Delete cookie in case the user was from Lenovo
        if (domain && domain === AppConstants.LENOVO) {
            this.cookieService.delete('jwt');
        }
    }

    /**
     * Redirect to login and save the URL from attempt access
     * @param urlToAccess URL that the user want to go
     */
    private failedAttempt(): Observable<boolean> {
        this.redirectToLogin(this.urlToAccess?this.urlToAccess:'');
        return of(false);
    }

    /**
     * Return an observable boolean if the validate was recovered correctly
     *
     */
    public waitValidate(): Observable<boolean> {
        if (this.validateCalled) {
            return of(true);
        }
        this.validateCalled = true;

        const localUser: User | null = this.getLocalStorageUser();
        if (this.router.url === '/login' && localUser !== null) {
            this.setUserInfo((this.getLocalStorageUser() as User));
            this.redirectHome();
            return of(true);
        }

        return this.postValidate().pipe(
            catchError(() => {
                this.failedAttempt();
                return EMPTY;
            }),
            map((user: User) => {
                this.setUserInfo(user);
                this.setUser(user);
                this.redirectHome();
                return true;
            })
        );
    }

    /**
     *  Recover user information
     */
    public postValidate(): Observable<User> {
        return this.httpClient
            .post<any>(this.server + '/galvin-user-logged/informations', {})
            .pipe(map((res) => res.content));
    }

    /**
     * Validates user with the server
     *
     * @param urlToRedirect
     */
    protected validateUser(urlToRedirect?: string): void {
        this.logging.next(true);
        this.signInSubscription = this.httpClient
            .post<any>(this.server + '/galvin-user-logged/informations', {})
            .pipe(map((res) => res.content))
            .subscribe(
                (user: User) => {
                    this.setUserInfo(user);
                    this.logging.next(false);

                    // If the user was on login page, redirect to home or the previous URL he/she was trying to access
                    // if not on login page it means the user was already logged in and just being validated, so not redirects are needed
                    if (this.router.url === '/login') {
                        // This is required according to https://github.com/angular/angular/issues/25837#issuecomment-434049467
                        this.ngZone
                            .run(() => this.router.navigate([urlToRedirect ? urlToRedirect : '/']))
                            .then();
                    }
                },
                () => {
                    this.redirectToLogin();
                    this.authNoticeService.setNotice(
                        'Failed to login Motorola user. Please make sure you are logging in with a valid Motorola email.',
                        'danger'
                    );
                    this.logging.next(false);
                }
            );
    }

    public saveUser(tokenUser: any) {
        const localUser: User = {
            name: tokenUser.given_name,
            lastName: tokenUser.lastName,
            picture: tokenUser.picture,
            token: '',
            sub: tokenUser.sub,
            domain: AppConstants.MOTOROLA,
            email: tokenUser.email
        };

        this.setUser(localUser);
    }

    /**
     * Clears the user information and redirects the user to login page
     */
    public redirectToLogin(urlToAccess?: string): void {
        this.validateCalled = false;
        this.deleteUser();
        if (urlToAccess && urlToAccess!='/'){
            this.router.navigate(['login'],{ queryParams: { 'redirect_to': urlToAccess} });
        }else{
            this.router.navigate(['login']);

        }
    }

    // If the user was on login page, redirect to home or the previous URL he/she was trying to access
    // if not on login page it means the user was already logged in and just being validated, so not redirects are needed
    protected redirectHome() {
        if (this.router.url.includes('/login')) {
            this.ngZone.run(() => this.router.navigate(['/'])).then();
            this.urlToAccess = null;
        }
    }

}
