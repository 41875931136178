import { Pipe, PipeTransform } from '@angular/core';
import { IProtoRequestHeader } from '@galvin/views/partials/layout/proto-request-table';

@Pipe({
    name: 'filterFullViewConfiguration'
})
export class FilterFullViewConfigurationPipe implements PipeTransform {
    transform(values: IProtoRequestHeader[], filter: IProtoRequestHeader[]): IProtoRequestHeader[] {
        if (!filter || filter.length === 0) {
            return values;
        }
        const test = (values || []).filter((config) =>
            filter.find((c) => config.idConfiguration === c.idConfiguration)
        );

        return test;
    }
}
