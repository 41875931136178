/**
 * Galvin API
 * Galvin is a PDT platform development initiative to centralize and manage all Prototypes lifecycle at Motorola
 *
 * OpenAPI spec version: 1.0
 * Contact: galvinteam@motorola.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ETeamProtoRequestJustification } from '@galvin/core/build-plan/prototype-request-management/enums/team-proto-request-justification-enum';
import { ISpecialRequirementsValue } from '../../field-value-management/special-requirements/interfaces/special-requirements-value.interface';
import { ISpecialRequirements } from '../../field-value-management/special-requirements/interfaces/special-requirements.interface';
import { IGalvinUser } from './../../../auth/_interfaces/galvin-user.interface';
import { ILocation } from './../../field-value-management/location/interfaces/location.interface';

export interface IPrototypeRequestTeamPayload {
    bpTeamAssociationId: number;
    requests: Array<IPrototypeRequestTeam>;
}

export type IPrototypeRequestTeamJustification =
    | { [key: string]: { order?: number; value?: boolean } }
    | { [key in ETeamProtoRequestJustification]: boolean };

export interface IPrototypeRequestTeamSpecialRequirementsValidation {
    specials: IPrototypeRequestTeamSpecialRequirements[];
    valid: boolean;
}
export interface IPrototypeRequestTeamSpecialRequirements {
    id?: number;
    specialRequirement: ISpecialRequirements;
    specialRequirementsValue: ISpecialRequirementsValue;
    value?: string;
}
export interface IPrototypeRequestTeam {
    activationPercentage?: number;
    activationQuantity?: number;
    additionalComments?: string;
    allDevicesToBeActivated?: boolean;
    idTeamProtoRequest?: number;
    isNewHWSpec?: boolean;
    isSpecialRequirement?: boolean;
    justifications?: IPrototypeRequestTeamJustification;
    quantity?: number;
    requestedTime?: Date;
    status?: string;
    teamAlias?: string;
    teamValues?: string;
    configurationId?: number;
    requestOwner?: IGalvinUser;
    finalRecipient?: IGalvinUser;
    location?: ILocation;
    shipTo?: ILocation;
    group?: string;
    specialRequirementsList?: IPrototypeRequestTeamSpecialRequirements[];
    usersMentionsNotification?: string[];

}
