export const BUILD_PLANS = {
    NEW_BUILD_PLAN: 'New Build Plan',
    NEW_BUILD_PLANS_HEADER: 'New Build Plans',
    OPEN_BUILD_PLANS_HEADER: 'Open Build Plans',
    COMPLETED_BUILD_PLANS_HEADER: 'Completed Build Plans',
    CARD_PLANNED_RELEASE: 'Planned Release',
    CARD_CREATED: 'Created',
    CARD_LOCKDOWN: 'Lockdown',
    SEARCH: {
        LABEL: 'Search Build Plan',
        ALL_STATUS: 'All',
        UNDER_APPROVAL_STATUS: 'Under Approval',
        OPEN_STATUS: 'Open',
        LOCKED_STATUS: 'Locked',
        BUILDING_STATUS: 'Building',
        COMPLETED_STATUS: 'Completed'
    }
};
