import { Injectable } from '@angular/core';
export interface IUtilsFlag {
    initial: string;
    country: string;
    class: string;
}
@Injectable()
export class UtilsFlag {
    private flags: IUtilsFlag[] = [];

    constructor() {
        this.setFlags();
    }

    setFlags(): void {
        this.flags = [];
        this.flags.push({
            initial: 'AF'.toLowerCase(),
            country: 'Afghanistan',
            class: 'fi fi-' + 'AF'.toLowerCase()
        });
        this.flags.push({
            initial: 'AX'.toLowerCase(),
            country: 'Aland Islands',
            class: 'fi fi-' + 'AX'.toLowerCase()
        });
        this.flags.push({
            initial: 'AL'.toLowerCase(),
            country: 'Albania',
            class: 'fi fi-' + 'AL'.toLowerCase()
        });
        this.flags.push({
            initial: 'DZ'.toLowerCase(),
            country: 'Algeria',
            class: 'fi fi-' + 'DZ'.toLowerCase()
        });
        this.flags.push({
            initial: 'AS'.toLowerCase(),
            country: 'American Samoa',
            class: 'fi fi-' + 'AS'.toLowerCase()
        });
        this.flags.push({
            initial: 'AD'.toLowerCase(),
            country: 'Andorra',
            class: 'fi fi-' + 'AD'.toLowerCase()
        });
        this.flags.push({
            initial: 'AO'.toLowerCase(),
            country: 'Angola',
            class: 'fi fi-' + 'AO'.toLowerCase()
        });
        this.flags.push({
            initial: 'AI'.toLowerCase(),
            country: 'Anguilla',
            class: 'fi fi-' + 'AI'.toLowerCase()
        });
        this.flags.push({
            initial: 'AG'.toLowerCase(),
            country: 'Antigua and Barbuda',
            class: 'fi fi-' + 'AG'.toLowerCase()
        });
        this.flags.push({
            initial: 'AR'.toLowerCase(),
            country: 'Argentina',
            class: 'fi fi-' + 'AR'.toLowerCase()
        });
        this.flags.push({
            initial: 'AM'.toLowerCase(),
            country: 'Armenia',
            class: 'fi fi-' + 'AM'.toLowerCase()
        });
        this.flags.push({
            initial: 'AW'.toLowerCase(),
            country: 'Aruba',
            class: 'fi fi-' + 'AW'.toLowerCase()
        });
        this.flags.push({
            initial: 'AU'.toLowerCase(),
            country: 'Australia',
            class: 'fi fi-' + 'AU'.toLowerCase()
        });
        this.flags.push({
            initial: 'AT'.toLowerCase(),
            country: 'Austria',
            class: 'fi fi-' + 'AT'.toLowerCase()
        });
        this.flags.push({
            initial: 'AZ'.toLowerCase(),
            country: 'Azerbaijan',
            class: 'fi fi-' + 'AZ'.toLowerCase()
        });
        this.flags.push({
            initial: 'BS'.toLowerCase(),
            country: 'Bahamas',
            class: 'fi fi-' + 'BS'.toLowerCase()
        });
        this.flags.push({
            initial: 'BH'.toLowerCase(),
            country: 'Bahrain',
            class: 'fi fi-' + 'BH'.toLowerCase()
        });
        this.flags.push({
            initial: 'BD'.toLowerCase(),
            country: 'Bangladesh',
            class: 'fi fi-' + 'BD'.toLowerCase()
        });
        this.flags.push({
            initial: 'BB'.toLowerCase(),
            country: 'Barbados',
            class: 'fi fi-' + 'BB'.toLowerCase()
        });
        this.flags.push({
            initial: 'BY'.toLowerCase(),
            country: 'Belarus',
            class: 'fi fi-' + 'BY'.toLowerCase()
        });
        this.flags.push({
            initial: 'BE'.toLowerCase(),
            country: 'Belgium',
            class: 'fi fi-' + 'BE'.toLowerCase()
        });
        this.flags.push({
            initial: 'BZ'.toLowerCase(),
            country: 'Belize',
            class: 'fi fi-' + 'BZ'.toLowerCase()
        });
        this.flags.push({
            initial: 'BJ'.toLowerCase(),
            country: 'Benin',
            class: 'fi fi-' + 'BJ'.toLowerCase()
        });
        this.flags.push({
            initial: 'BM'.toLowerCase(),
            country: 'Bermuda',
            class: 'fi fi-' + 'BM'.toLowerCase()
        });
        this.flags.push({
            initial: 'BT'.toLowerCase(),
            country: 'Bhutan',
            class: 'fi fi-' + 'BT'.toLowerCase()
        });
        this.flags.push({
            initial: 'BO'.toLowerCase(),
            country: 'Bolivia (Plurinational State of)',
            class: 'fi fi-' + 'BO'.toLowerCase()
        });
        this.flags.push({
            initial: 'BQ'.toLowerCase(),
            country: 'Bonaire, Sint Eustatius and Saba',
            class: 'fi fi-' + 'BQ'.toLowerCase()
        });
        this.flags.push({
            initial: 'BA'.toLowerCase(),
            country: 'Bosnia and Herzegovina',
            class: 'fi fi-' + 'BA'.toLowerCase()
        });
        this.flags.push({
            initial: 'BW'.toLowerCase(),
            country: 'Botswana',
            class: 'fi fi-' + 'BW'.toLowerCase()
        });
        this.flags.push({
            initial: 'BR'.toLowerCase(),
            country: 'Brazil',
            class: 'fi fi-' + 'BR'.toLowerCase()
        });
        this.flags.push({
            initial: 'IO'.toLowerCase(),
            country: 'British Indian Ocean Territory',
            class: 'fi fi-' + 'IO'.toLowerCase()
        });
        this.flags.push({
            initial: 'BN'.toLowerCase(),
            country: 'Brunei Darussalam',
            class: 'fi fi-' + 'BN'.toLowerCase()
        });
        this.flags.push({
            initial: 'BG'.toLowerCase(),
            country: 'Bulgaria',
            class: 'fi fi-' + 'BG'.toLowerCase()
        });
        this.flags.push({
            initial: 'BF'.toLowerCase(),
            country: 'Burkina Faso',
            class: 'fi fi-' + 'BF'.toLowerCase()
        });
        this.flags.push({
            initial: 'BI'.toLowerCase(),
            country: 'Burundi',
            class: 'fi fi-' + 'BI'.toLowerCase()
        });
        this.flags.push({
            initial: 'CV'.toLowerCase(),
            country: 'Cabo Verde',
            class: 'fi fi-' + 'CV'.toLowerCase()
        });
        this.flags.push({
            initial: 'KH'.toLowerCase(),
            country: 'Cambodia',
            class: 'fi fi-' + 'KH'.toLowerCase()
        });
        this.flags.push({
            initial: 'CM'.toLowerCase(),
            country: 'Cameroon',
            class: 'fi fi-' + 'CM'.toLowerCase()
        });
        this.flags.push({
            initial: 'CA'.toLowerCase(),
            country: 'Canada',
            class: 'fi fi-' + 'CA'.toLowerCase()
        });
        this.flags.push({
            initial: 'KY'.toLowerCase(),
            country: 'Cayman Islands',
            class: 'fi fi-' + 'KY'.toLowerCase()
        });
        this.flags.push({
            initial: 'CF'.toLowerCase(),
            country: 'Central African Republic',
            class: 'fi fi-' + 'CF'.toLowerCase()
        });
        this.flags.push({
            initial: 'TD'.toLowerCase(),
            country: 'Chad',
            class: 'fi fi-' + 'TD'.toLowerCase()
        });
        this.flags.push({
            initial: 'CL'.toLowerCase(),
            country: 'Chile',
            class: 'fi fi-' + 'CL'.toLowerCase()
        });
        this.flags.push({
            initial: 'CN'.toLowerCase(),
            country: 'China',
            class: 'fi fi-' + 'CN'.toLowerCase()
        });
        this.flags.push({
            initial: 'CX'.toLowerCase(),
            country: 'Christmas Island',
            class: 'fi fi-' + 'CX'.toLowerCase()
        });
        this.flags.push({
            initial: 'CC'.toLowerCase(),
            country: 'Cocos (Keeling) Islands',
            class: 'fi fi-' + 'CC'.toLowerCase()
        });
        this.flags.push({
            initial: 'CO'.toLowerCase(),
            country: 'Colombia',
            class: 'fi fi-' + 'CO'.toLowerCase()
        });
        this.flags.push({
            initial: 'KM'.toLowerCase(),
            country: 'Comoros',
            class: 'fi fi-' + 'KM'.toLowerCase()
        });
        this.flags.push({
            initial: 'CK'.toLowerCase(),
            country: 'Cook Islands',
            class: 'fi fi-' + 'CK'.toLowerCase()
        });
        this.flags.push({
            initial: 'CR'.toLowerCase(),
            country: 'Costa Rica',
            class: 'fi fi-' + 'CR'.toLowerCase()
        });
        this.flags.push({
            initial: 'HR'.toLowerCase(),
            country: 'Croatia',
            class: 'fi fi-' + 'HR'.toLowerCase()
        });
        this.flags.push({
            initial: 'CU'.toLowerCase(),
            country: 'Cuba',
            class: 'fi fi-' + 'CU'.toLowerCase()
        });
        this.flags.push({
            initial: 'CW'.toLowerCase(),
            country: 'Curaçao',
            class: 'fi fi-' + 'CW'.toLowerCase()
        });
        this.flags.push({
            initial: 'CY'.toLowerCase(),
            country: 'Cyprus',
            class: 'fi fi-' + 'CY'.toLowerCase()
        });
        this.flags.push({
            initial: 'CZ'.toLowerCase(),
            country: 'Czech Republic',
            class: 'fi fi-' + 'CZ'.toLowerCase()
        });
        this.flags.push({
            initial: 'CI'.toLowerCase(),
            country: 'Côte d\'Ivoire',
            class: 'fi fi-' + 'CI'.toLowerCase()
        });
        this.flags.push({
            initial: 'CD'.toLowerCase(),
            country: 'Democratic Republic of the Congo',
            class: 'fi fi-' + 'CD'.toLowerCase()
        });
        this.flags.push({
            initial: 'DK'.toLowerCase(),
            country: 'Denmark',
            class: 'fi fi-' + 'DK'.toLowerCase()
        });
        this.flags.push({
            initial: 'DJ'.toLowerCase(),
            country: 'Djibouti',
            class: 'fi fi-' + 'DJ'.toLowerCase()
        });
        this.flags.push({
            initial: 'DM'.toLowerCase(),
            country: 'Dominica',
            class: 'fi fi-' + 'DM'.toLowerCase()
        });
        this.flags.push({
            initial: 'DO'.toLowerCase(),
            country: 'Dominican Republic',
            class: 'fi fi-' + 'DO'.toLowerCase()
        });
        this.flags.push({
            initial: 'EC'.toLowerCase(),
            country: 'Ecuador',
            class: 'fi fi-' + 'EC'.toLowerCase()
        });
        this.flags.push({
            initial: 'EG'.toLowerCase(),
            country: 'Egypt',
            class: 'fi fi-' + 'EG'.toLowerCase()
        });
        this.flags.push({
            initial: 'SV'.toLowerCase(),
            country: 'El Salvador',
            class: 'fi fi-' + 'SV'.toLowerCase()
        });
        this.flags.push({
            initial: 'GB'.toLowerCase(),
            country: 'ENG England',
            class: 'fi fi-' + 'GB'.toLowerCase()
        });
        this.flags.push({
            initial: 'GQ'.toLowerCase(),
            country: 'Equatorial Guinea',
            class: 'fi fi-' + 'GQ'.toLowerCase()
        });
        this.flags.push({
            initial: 'ER'.toLowerCase(),
            country: 'Eritrea',
            class: 'fi fi-' + 'ER'.toLowerCase()
        });
        this.flags.push({
            initial: 'EE'.toLowerCase(),
            country: 'Estonia',
            class: 'fi fi-' + 'EE'.toLowerCase()
        });
        this.flags.push({
            initial: 'ET'.toLowerCase(),
            country: 'Ethiopia',
            class: 'fi fi-' + 'ET'.toLowerCase()
        });
        this.flags.push({
            initial: 'FK'.toLowerCase(),
            country: 'Falkland Islands',
            class: 'fi fi-' + 'FK'.toLowerCase()
        });
        this.flags.push({
            initial: 'FO'.toLowerCase(),
            country: 'Faroe Islands',
            class: 'fi fi-' + 'FO'.toLowerCase()
        });
        this.flags.push({
            initial: 'FM'.toLowerCase(),
            country: 'Federated States of Micronesia',
            class: 'fi fi-' + 'FM'.toLowerCase()
        });
        this.flags.push({
            initial: 'FJ'.toLowerCase(),
            country: 'Fiji',
            class: 'fi fi-' + 'FJ'.toLowerCase()
        });
        this.flags.push({
            initial: 'FI'.toLowerCase(),
            country: 'Finland',
            class: 'fi fi-' + 'FI'.toLowerCase()
        });
        this.flags.push({
            initial: 'FR'.toLowerCase(),
            country: 'France',
            class: 'fi fi-' + 'FR'.toLowerCase()
        });
        this.flags.push({
            initial: 'GF'.toLowerCase(),
            country: 'French Guiana',
            class: 'fi fi-' + 'GF'.toLowerCase()
        });
        this.flags.push({
            initial: 'PF'.toLowerCase(),
            country: 'French Polynesia',
            class: 'fi fi-' + 'PF'.toLowerCase()
        });
        this.flags.push({
            initial: 'TF'.toLowerCase(),
            country: 'French Southern Territories',
            class: 'fi fi-' + 'TF'.toLowerCase()
        });
        this.flags.push({
            initial: 'GA'.toLowerCase(),
            country: 'Gabon',
            class: 'fi fi-' + 'GA'.toLowerCase()
        });
        this.flags.push({
            initial: 'GM'.toLowerCase(),
            country: 'Gambia',
            class: 'fi fi-' + 'GM'.toLowerCase()
        });
        this.flags.push({
            initial: 'GE'.toLowerCase(),
            country: 'Georgia',
            class: 'fi fi-' + 'GE'.toLowerCase()
        });
        this.flags.push({
            initial: 'DE'.toLowerCase(),
            country: 'Germany',
            class: 'fi fi-' + 'DE'.toLowerCase()
        });
        this.flags.push({
            initial: 'GH'.toLowerCase(),
            country: 'Ghana',
            class: 'fi fi-' + 'GH'.toLowerCase()
        });
        this.flags.push({
            initial: 'GI'.toLowerCase(),
            country: 'Gibraltar',
            class: 'fi fi-' + 'GI'.toLowerCase()
        });
        this.flags.push({
            initial: 'GR'.toLowerCase(),
            country: 'Greece',
            class: 'fi fi-' + 'GR'.toLowerCase()
        });
        this.flags.push({
            initial: 'GL'.toLowerCase(),
            country: 'Greenland',
            class: 'fi fi-' + 'GL'.toLowerCase()
        });
        this.flags.push({
            initial: 'GD'.toLowerCase(),
            country: 'Grenada',
            class: 'fi fi-' + 'GD'.toLowerCase()
        });
        this.flags.push({
            initial: 'GP'.toLowerCase(),
            country: 'Guadeloupe',
            class: 'fi fi-' + 'GP'.toLowerCase()
        });
        this.flags.push({
            initial: 'GU'.toLowerCase(),
            country: 'Guam',
            class: 'fi fi-' + 'GU'.toLowerCase()
        });
        this.flags.push({
            initial: 'GT'.toLowerCase(),
            country: 'Guatemala',
            class: 'fi fi-' + 'GT'.toLowerCase()
        });
        this.flags.push({
            initial: 'GG'.toLowerCase(),
            country: 'Guernsey',
            class: 'fi fi-' + 'GG'.toLowerCase()
        });
        this.flags.push({
            initial: 'GN'.toLowerCase(),
            country: 'Guinea',
            class: 'fi fi-' + 'GN'.toLowerCase()
        });
        this.flags.push({
            initial: 'GW'.toLowerCase(),
            country: 'Guinea-Bissau',
            class: 'fi fi-' + 'GW'.toLowerCase()
        });
        this.flags.push({
            initial: 'GY'.toLowerCase(),
            country: 'Guyana',
            class: 'fi fi-' + 'GY'.toLowerCase()
        });
        this.flags.push({
            initial: 'HT'.toLowerCase(),
            country: 'Haiti',
            class: 'fi fi-' + 'HT'.toLowerCase()
        });
        this.flags.push({
            initial: 'VA'.toLowerCase(),
            country: 'Holy See',
            class: 'fi fi-' + 'VA'.toLowerCase()
        });
        this.flags.push({
            initial: 'HN'.toLowerCase(),
            country: 'Honduras',
            class: 'fi fi-' + 'HN'.toLowerCase()
        });
        this.flags.push({
            initial: 'HK'.toLowerCase(),
            country: 'Hong Kong',
            class: 'fi fi-' + 'HK'.toLowerCase()
        });
        this.flags.push({
            initial: 'HU'.toLowerCase(),
            country: 'Hungary',
            class: 'fi fi-' + 'HU'.toLowerCase()
        });
        this.flags.push({
            initial: 'IS'.toLowerCase(),
            country: 'Iceland',
            class: 'fi fi-' + 'IS'.toLowerCase()
        });
        this.flags.push({
            initial: 'IN'.toLowerCase(),
            country: 'India',
            class: 'fi fi-' + 'IN'.toLowerCase()
        });
        this.flags.push({
            initial: 'ID'.toLowerCase(),
            country: 'Indonesia',
            class: 'fi fi-' + 'ID'.toLowerCase()
        });
        this.flags.push({
            initial: 'IR'.toLowerCase(),
            country: 'Iran (Islamic Republic of)',
            class: 'fi fi-' + 'IR'.toLowerCase()
        });
        this.flags.push({
            initial: 'IQ'.toLowerCase(),
            country: 'Iraq',
            class: 'fi fi-' + 'IQ'.toLowerCase()
        });
        this.flags.push({
            initial: 'IE'.toLowerCase(),
            country: 'Ireland',
            class: 'fi fi-' + 'IE'.toLowerCase()
        });
        this.flags.push({
            initial: 'IM'.toLowerCase(),
            country: 'Isle of Man',
            class: 'fi fi-' + 'IM'.toLowerCase()
        });
        this.flags.push({
            initial: 'IL'.toLowerCase(),
            country: 'Israel',
            class: 'fi fi-' + 'IL'.toLowerCase()
        });
        this.flags.push({
            initial: 'IT'.toLowerCase(),
            country: 'Italy',
            class: 'fi fi-' + 'IT'.toLowerCase()
        });
        this.flags.push({
            initial: 'JM'.toLowerCase(),
            country: 'Jamaica',
            class: 'fi fi-' + 'JM'.toLowerCase()
        });
        this.flags.push({
            initial: 'JP'.toLowerCase(),
            country: 'Japan',
            class: 'fi fi-' + 'JP'.toLowerCase()
        });
        this.flags.push({
            initial: 'JE'.toLowerCase(),
            country: 'Jersey',
            class: 'fi fi-' + 'JE'.toLowerCase()
        });
        this.flags.push({
            initial: 'JO'.toLowerCase(),
            country: 'Jordan',
            class: 'fi fi-' + 'JO'.toLowerCase()
        });
        this.flags.push({
            initial: 'KZ'.toLowerCase(),
            country: 'Kazakhstan',
            class: 'fi fi-' + 'KZ'.toLowerCase()
        });
        this.flags.push({
            initial: 'KE'.toLowerCase(),
            country: 'Kenya',
            class: 'fi fi-' + 'KE'.toLowerCase()
        });
        this.flags.push({
            initial: 'KI'.toLowerCase(),
            country: 'Kiribati',
            class: 'fi fi-' + 'KI'.toLowerCase()
        });
        this.flags.push({
            initial: 'XK'.toLowerCase(),
            country: 'Kosovo',
            class: 'fi fi-' + 'XK'.toLowerCase()
        });
        this.flags.push({
            initial: 'KW'.toLowerCase(),
            country: 'Kuwait',
            class: 'fi fi-' + 'KW'.toLowerCase()
        });
        this.flags.push({
            initial: 'KG'.toLowerCase(),
            country: 'Kyrgyzstan',
            class: 'fi fi-' + 'KG'.toLowerCase()
        });
        this.flags.push({
            initial: 'LA'.toLowerCase(),
            country: 'Laos',
            class: 'fi fi-' + 'LA'.toLowerCase()
        });
        this.flags.push({
            initial: 'LV'.toLowerCase(),
            country: 'Latvia',
            class: 'fi fi-' + 'LV'.toLowerCase()
        });
        this.flags.push({
            initial: 'LB'.toLowerCase(),
            country: 'Lebanon',
            class: 'fi fi-' + 'LB'.toLowerCase()
        });
        this.flags.push({
            initial: 'LS'.toLowerCase(),
            country: 'Lesotho',
            class: 'fi fi-' + 'LS'.toLowerCase()
        });
        this.flags.push({
            initial: 'LR'.toLowerCase(),
            country: 'Liberia',
            class: 'fi fi-' + 'LR'.toLowerCase()
        });
        this.flags.push({
            initial: 'LY'.toLowerCase(),
            country: 'Libya',
            class: 'fi fi-' + 'LY'.toLowerCase()
        });
        this.flags.push({
            initial: 'LI'.toLowerCase(),
            country: 'Liechtenstein',
            class: 'fi fi-' + 'LI'.toLowerCase()
        });
        this.flags.push({
            initial: 'LT'.toLowerCase(),
            country: 'Lithuania',
            class: 'fi fi-' + 'LT'.toLowerCase()
        });
        this.flags.push({
            initial: 'LU'.toLowerCase(),
            country: 'Luxembourg',
            class: 'fi fi-' + 'LU'.toLowerCase()
        });
        this.flags.push({
            initial: 'MO'.toLowerCase(),
            country: 'Macau',
            class: 'fi fi-' + 'MO'.toLowerCase()
        });
        this.flags.push({
            initial: 'MG'.toLowerCase(),
            country: 'Madagascar',
            class: 'fi fi-' + 'MG'.toLowerCase()
        });
        this.flags.push({
            initial: 'MW'.toLowerCase(),
            country: 'Malawi',
            class: 'fi fi-' + 'MW'.toLowerCase()
        });
        this.flags.push({
            initial: 'MY'.toLowerCase(),
            country: 'Malaysia',
            class: 'fi fi-' + 'MY'.toLowerCase()
        });
        this.flags.push({
            initial: 'MV'.toLowerCase(),
            country: 'Maldives',
            class: 'fi fi-' + 'MV'.toLowerCase()
        });
        this.flags.push({
            initial: 'ML'.toLowerCase(),
            country: 'Mali',
            class: 'fi fi-' + 'ML'.toLowerCase()
        });
        this.flags.push({
            initial: 'MT'.toLowerCase(),
            country: 'Malta',
            class: 'fi fi-' + 'MT'.toLowerCase()
        });
        this.flags.push({
            initial: 'MH'.toLowerCase(),
            country: 'Marshall Islands',
            class: 'fi fi-' + 'MH'.toLowerCase()
        });
        this.flags.push({
            initial: 'MQ'.toLowerCase(),
            country: 'Martinique',
            class: 'fi fi-' + 'MQ'.toLowerCase()
        });
        this.flags.push({
            initial: 'MR'.toLowerCase(),
            country: 'Mauritania',
            class: 'fi fi-' + 'MR'.toLowerCase()
        });
        this.flags.push({
            initial: 'MU'.toLowerCase(),
            country: 'Mauritius',
            class: 'fi fi-' + 'MU'.toLowerCase()
        });
        this.flags.push({
            initial: 'YT'.toLowerCase(),
            country: 'Mayotte',
            class: 'fi fi-' + 'YT'.toLowerCase()
        });
        this.flags.push({
            initial: 'MX'.toLowerCase(),
            country: 'Mexico',
            class: 'fi fi-' + 'MX'.toLowerCase()
        });
        this.flags.push({
            initial: 'MD'.toLowerCase(),
            country: 'Moldova',
            class: 'fi fi-' + 'MD'.toLowerCase()
        });
        this.flags.push({
            initial: 'MC'.toLowerCase(),
            country: 'Monaco',
            class: 'fi fi-' + 'MC'.toLowerCase()
        });
        this.flags.push({
            initial: 'MN'.toLowerCase(),
            country: 'Mongolia',
            class: 'fi fi-' + 'MN'.toLowerCase()
        });
        this.flags.push({
            initial: 'ME'.toLowerCase(),
            country: 'Montenegro',
            class: 'fi fi-' + 'ME'.toLowerCase()
        });
        this.flags.push({
            initial: 'MS'.toLowerCase(),
            country: 'Montserrat',
            class: 'fi fi-' + 'MS'.toLowerCase()
        });
        this.flags.push({
            initial: 'MA'.toLowerCase(),
            country: 'Morocco',
            class: 'fi fi-' + 'MA'.toLowerCase()
        });
        this.flags.push({
            initial: 'MZ'.toLowerCase(),
            country: 'Mozambique',
            class: 'fi fi-' + 'MZ'.toLowerCase()
        });
        this.flags.push({
            initial: 'MM'.toLowerCase(),
            country: 'Myanmar',
            class: 'fi fi-' + 'MM'.toLowerCase()
        });
        this.flags.push({
            initial: 'NA'.toLowerCase(),
            country: 'Namibia',
            class: 'fi fi-' + 'NA'.toLowerCase()
        });
        this.flags.push({
            initial: 'NR'.toLowerCase(),
            country: 'Nauru',
            class: 'fi fi-' + 'NR'.toLowerCase()
        });
        this.flags.push({
            initial: 'NP'.toLowerCase(),
            country: 'Nepal',
            class: 'fi fi-' + 'NP'.toLowerCase()
        });
        this.flags.push({
            initial: 'NL'.toLowerCase(),
            country: 'Netherlands',
            class: 'fi fi-' + 'NL'.toLowerCase()
        });
        this.flags.push({
            initial: 'NC'.toLowerCase(),
            country: 'New Caledonia',
            class: 'fi fi-' + 'NC'.toLowerCase()
        });
        this.flags.push({
            initial: 'NZ'.toLowerCase(),
            country: 'New Zealand',
            class: 'fi fi-' + 'NZ'.toLowerCase()
        });
        this.flags.push({
            initial: 'NI'.toLowerCase(),
            country: 'Nicaragua',
            class: 'fi fi-' + 'NI'.toLowerCase()
        });
        this.flags.push({
            initial: 'NE'.toLowerCase(),
            country: 'Niger',
            class: 'fi fi-' + 'NE'.toLowerCase()
        });
        this.flags.push({
            initial: 'NG'.toLowerCase(),
            country: 'Nigeria',
            class: 'fi fi-' + 'NG'.toLowerCase()
        });
        this.flags.push({
            initial: 'NU'.toLowerCase(),
            country: 'Niue',
            class: 'fi fi-' + 'NU'.toLowerCase()
        });
        this.flags.push({
            initial: 'NF'.toLowerCase(),
            country: 'Norfolk Island',
            class: 'fi fi-' + 'NF'.toLowerCase()
        });
        this.flags.push({
            initial: 'KP'.toLowerCase(),
            country: 'North Korea',
            class: 'fi fi-' + 'KP'.toLowerCase()
        });
        this.flags.push({
            initial: 'MK'.toLowerCase(),
            country: 'North Macedonia',
            class: 'fi fi-' + 'MK'.toLowerCase()
        });
        this.flags.push({
            initial: 'GB'.toLowerCase(),
            country: 'NIR Northern Ireland',
            class: 'fi fi-' + 'GB'.toLowerCase()
        });
        this.flags.push({
            initial: 'MP'.toLowerCase(),
            country: 'Northern Mariana Islands',
            class: 'fi fi-' + 'MP'.toLowerCase()
        });
        this.flags.push({
            initial: 'NO'.toLowerCase(),
            country: 'Norway',
            class: 'fi fi-' + 'NO'.toLowerCase()
        });
        this.flags.push({
            initial: 'OM'.toLowerCase(),
            country: 'Oman',
            class: 'fi fi-' + 'OM'.toLowerCase()
        });
        this.flags.push({
            initial: 'PK'.toLowerCase(),
            country: 'Pakistan',
            class: 'fi fi-' + 'PK'.toLowerCase()
        });
        this.flags.push({
            initial: 'PW'.toLowerCase(),
            country: 'Palau',
            class: 'fi fi-' + 'PW'.toLowerCase()
        });
        this.flags.push({
            initial: 'PA'.toLowerCase(),
            country: 'Panama',
            class: 'fi fi-' + 'PA'.toLowerCase()
        });
        this.flags.push({
            initial: 'PG'.toLowerCase(),
            country: 'Papua New Guinea',
            class: 'fi fi-' + 'PG'.toLowerCase()
        });
        this.flags.push({
            initial: 'PY'.toLowerCase(),
            country: 'Paraguay',
            class: 'fi fi-' + 'PY'.toLowerCase()
        });
        this.flags.push({
            initial: 'PE'.toLowerCase(),
            country: 'Peru',
            class: 'fi fi-' + 'PE'.toLowerCase()
        });
        this.flags.push({
            initial: 'PH'.toLowerCase(),
            country: 'Philippines',
            class: 'fi fi-' + 'PH'.toLowerCase()
        });
        this.flags.push({
            initial: 'PN'.toLowerCase(),
            country: 'Pitcairn',
            class: 'fi fi-' + 'PN'.toLowerCase()
        });
        this.flags.push({
            initial: 'PL'.toLowerCase(),
            country: 'Poland',
            class: 'fi fi-' + 'PL'.toLowerCase()
        });
        this.flags.push({
            initial: 'PT'.toLowerCase(),
            country: 'Portugal',
            class: 'fi fi-' + 'PT'.toLowerCase()
        });
        this.flags.push({
            initial: 'PR'.toLowerCase(),
            country: 'Puerto Rico',
            class: 'fi fi-' + 'PR'.toLowerCase()
        });
        this.flags.push({
            initial: 'QA'.toLowerCase(),
            country: 'Qatar',
            class: 'fi fi-' + 'QA'.toLowerCase()
        });
        this.flags.push({
            initial: 'CG'.toLowerCase(),
            country: 'Republic of the Congo',
            class: 'fi fi-' + 'CG'.toLowerCase()
        });
        this.flags.push({
            initial: 'RO'.toLowerCase(),
            country: 'Romania',
            class: 'fi fi-' + 'RO'.toLowerCase()
        });
        this.flags.push({
            initial: 'RU'.toLowerCase(),
            country: 'Russia',
            class: 'fi fi-' + 'RU'.toLowerCase()
        });
        this.flags.push({
            initial: 'RW'.toLowerCase(),
            country: 'Rwanda',
            class: 'fi fi-' + 'RW'.toLowerCase()
        });
        this.flags.push({
            initial: 'RE'.toLowerCase(),
            country: 'Réunion',
            class: 'fi fi-' + 'RE'.toLowerCase()
        });
        this.flags.push({
            initial: 'BL'.toLowerCase(),
            country: 'Saint Barthélemy',
            class: 'fi fi-' + 'BL'.toLowerCase()
        });
        this.flags.push({
            initial: 'SH'.toLowerCase(),
            country: 'Saint Helena, Ascension and Tristan da Cunha',
            class: 'fi fi-' + 'SH'.toLowerCase()
        });
        this.flags.push({
            initial: 'KN'.toLowerCase(),
            country: 'Saint Kitts and Nevis',
            class: 'fi fi-' + 'KN'.toLowerCase()
        });
        this.flags.push({
            initial: 'LC'.toLowerCase(),
            country: 'Saint Lucia',
            class: 'fi fi-' + 'LC'.toLowerCase()
        });
        this.flags.push({
            initial: 'MF'.toLowerCase(),
            country: 'Saint Martin',
            class: 'fi fi-' + 'MF'.toLowerCase()
        });
        this.flags.push({
            initial: 'PM'.toLowerCase(),
            country: 'Saint Pierre and Miquelon',
            class: 'fi fi-' + 'PM'.toLowerCase()
        });
        this.flags.push({
            initial: 'VC'.toLowerCase(),
            country: 'Saint Vincent and the Grenadines',
            class: 'fi fi-' + 'VC'.toLowerCase()
        });
        this.flags.push({
            initial: 'WS'.toLowerCase(),
            country: 'Samoa',
            class: 'fi fi-' + 'WS'.toLowerCase()
        });
        this.flags.push({
            initial: 'SM'.toLowerCase(),
            country: 'San Marino',
            class: 'fi fi-' + 'SM'.toLowerCase()
        });
        this.flags.push({
            initial: 'ST'.toLowerCase(),
            country: 'Sao Tome and Principe',
            class: 'fi fi-' + 'ST'.toLowerCase()
        });
        this.flags.push({
            initial: 'SA'.toLowerCase(),
            country: 'Saudi Arabia',
            class: 'fi fi-' + 'SA'.toLowerCase()
        });
        this.flags.push({
            initial: 'GB'.toLowerCase(),
            country: 'SCT Scotland',
            class: 'fi fi-' + 'GB'.toLowerCase()
        });
        this.flags.push({
            initial: 'SN'.toLowerCase(),
            country: 'Senegal',
            class: 'fi fi-' + 'SN'.toLowerCase()
        });
        this.flags.push({
            initial: 'RS'.toLowerCase(),
            country: 'Serbia',
            class: 'fi fi-' + 'RS'.toLowerCase()
        });
        this.flags.push({
            initial: 'SC'.toLowerCase(),
            country: 'Seychelles',
            class: 'fi fi-' + 'SC'.toLowerCase()
        });
        this.flags.push({
            initial: 'SL'.toLowerCase(),
            country: 'Sierra Leone',
            class: 'fi fi-' + 'SL'.toLowerCase()
        });
        this.flags.push({
            initial: 'SG'.toLowerCase(),
            country: 'Singapore',
            class: 'fi fi-' + 'SG'.toLowerCase()
        });
        this.flags.push({
            initial: 'SX'.toLowerCase(),
            country: 'Sint Maarten',
            class: 'fi fi-' + 'SX'.toLowerCase()
        });
        this.flags.push({
            initial: 'SK'.toLowerCase(),
            country: 'Slovakia',
            class: 'fi fi-' + 'SK'.toLowerCase()
        });
        this.flags.push({
            initial: 'SI'.toLowerCase(),
            country: 'Slovenia',
            class: 'fi fi-' + 'SI'.toLowerCase()
        });
        this.flags.push({
            initial: 'SB'.toLowerCase(),
            country: 'Solomon Islands',
            class: 'fi fi-' + 'SB'.toLowerCase()
        });
        this.flags.push({
            initial: 'SO'.toLowerCase(),
            country: 'Somalia',
            class: 'fi fi-' + 'SO'.toLowerCase()
        });
        this.flags.push({
            initial: 'ZA'.toLowerCase(),
            country: 'South Africa',
            class: 'fi fi-' + 'ZA'.toLowerCase()
        });
        this.flags.push({
            initial: 'GS'.toLowerCase(),
            country: 'South Georgia and the South Sandwich Islands',
            class: 'fi fi-' + 'GS'.toLowerCase()
        });
        this.flags.push({
            initial: 'KR'.toLowerCase(),
            country: 'South Korea',
            class: 'fi fi-' + 'KR'.toLowerCase()
        });
        this.flags.push({
            initial: 'SS'.toLowerCase(),
            country: 'South Sudan',
            class: 'fi fi-' + 'SS'.toLowerCase()
        });
        this.flags.push({
            initial: 'ES'.toLowerCase(),
            country: 'Spain',
            class: 'fi fi-' + 'ES'.toLowerCase()
        });
        this.flags.push({
            initial: 'LK'.toLowerCase(),
            country: 'Sri Lanka',
            class: 'fi fi-' + 'LK'.toLowerCase()
        });
        this.flags.push({
            initial: 'PS'.toLowerCase(),
            country: 'State of Palestine',
            class: 'fi fi-' + 'PS'.toLowerCase()
        });
        this.flags.push({
            initial: 'SD'.toLowerCase(),
            country: 'Sudan',
            class: 'fi fi-' + 'SD'.toLowerCase()
        });
        this.flags.push({
            initial: 'SR'.toLowerCase(),
            country: 'Suriname',
            class: 'fi fi-' + 'SR'.toLowerCase()
        });
        this.flags.push({
            initial: 'SJ'.toLowerCase(),
            country: 'Svalbard and Jan Mayen',
            class: 'fi fi-' + 'SJ'.toLowerCase()
        });
        this.flags.push({
            initial: 'SZ'.toLowerCase(),
            country: 'Swaziland',
            class: 'fi fi-' + 'SZ'.toLowerCase()
        });
        this.flags.push({
            initial: 'SE'.toLowerCase(),
            country: 'Sweden',
            class: 'fi fi-' + 'SE'.toLowerCase()
        });
        this.flags.push({
            initial: 'CH'.toLowerCase(),
            country: 'Switzerland',
            class: 'fi fi-' + 'CH'.toLowerCase()
        });
        this.flags.push({
            initial: 'SY'.toLowerCase(),
            country: 'Syrian Arab Republic',
            class: 'fi fi-' + 'SY'.toLowerCase()
        });
        this.flags.push({
            initial: 'TW'.toLowerCase(),
            country: 'Taiwan',
            class: 'fi fi-' + 'TW'.toLowerCase()
        });
        this.flags.push({
            initial: 'TJ'.toLowerCase(),
            country: 'Tajikistan',
            class: 'fi fi-' + 'TJ'.toLowerCase()
        });
        this.flags.push({
            initial: 'TZ'.toLowerCase(),
            country: 'Tanzania',
            class: 'fi fi-' + 'TZ'.toLowerCase()
        });
        this.flags.push({
            initial: 'TH'.toLowerCase(),
            country: 'Thailand',
            class: 'fi fi-' + 'TH'.toLowerCase()
        });
        this.flags.push({
            initial: 'TL'.toLowerCase(),
            country: 'Timor-Leste',
            class: 'fi fi-' + 'TL'.toLowerCase()
        });
        this.flags.push({
            initial: 'TG'.toLowerCase(),
            country: 'Togo',
            class: 'fi fi-' + 'TG'.toLowerCase()
        });
        this.flags.push({
            initial: 'TK'.toLowerCase(),
            country: 'Tokelau',
            class: 'fi fi-' + 'TK'.toLowerCase()
        });
        this.flags.push({
            initial: 'TO'.toLowerCase(),
            country: 'Tonga',
            class: 'fi fi-' + 'TO'.toLowerCase()
        });
        this.flags.push({
            initial: 'TT'.toLowerCase(),
            country: 'Trinidad and Tobago',
            class: 'fi fi-' + 'TT'.toLowerCase()
        });
        this.flags.push({
            initial: 'TN'.toLowerCase(),
            country: 'Tunisia',
            class: 'fi fi-' + 'TN'.toLowerCase()
        });
        this.flags.push({
            initial: 'TR'.toLowerCase(),
            country: 'Turkey',
            class: 'fi fi-' + 'TR'.toLowerCase()
        });
        this.flags.push({
            initial: 'TM'.toLowerCase(),
            country: 'Turkmenistan',
            class: 'fi fi-' + 'TM'.toLowerCase()
        });
        this.flags.push({
            initial: 'TC'.toLowerCase(),
            country: 'Turks and Caicos Islands',
            class: 'fi fi-' + 'TC'.toLowerCase()
        });
        this.flags.push({
            initial: 'TV'.toLowerCase(),
            country: 'Tuvalu',
            class: 'fi fi-' + 'TV'.toLowerCase()
        });
        this.flags.push({
            initial: 'UG'.toLowerCase(),
            country: 'Uganda',
            class: 'fi fi-' + 'UG'.toLowerCase()
        });
        this.flags.push({
            initial: 'UA'.toLowerCase(),
            country: 'Ukraine',
            class: 'fi fi-' + 'UA'.toLowerCase()
        });
        this.flags.push({
            initial: 'AE'.toLowerCase(),
            country: 'United Arab Emirates',
            class: 'fi fi-' + 'AE'.toLowerCase()
        });
        this.flags.push({
            initial: 'GB'.toLowerCase(),
            country: 'United Kingdom',
            class: 'fi fi-' + 'GB'.toLowerCase()
        });
        this.flags.push({
            initial: 'UM'.toLowerCase(),
            country: 'United States Minor Outlying Islands',
            class: 'fi fi-' + 'UM'.toLowerCase()
        });
        this.flags.push({
            initial: 'US'.toLowerCase(),
            country: 'United States of America (USA)',
            class: 'fi fi-' + 'US'.toLowerCase()
        });
        this.flags.push({
            initial: 'UY'.toLowerCase(),
            country: 'Uruguay',
            class: 'fi fi-' + 'UY'.toLowerCase()
        });
        this.flags.push({
            initial: 'UZ'.toLowerCase(),
            country: 'Uzbekistan',
            class: 'fi fi-' + 'UZ'.toLowerCase()
        });
        this.flags.push({
            initial: 'VU'.toLowerCase(),
            country: 'Vanuatu',
            class: 'fi fi-' + 'VU'.toLowerCase()
        });
        this.flags.push({
            initial: 'VE'.toLowerCase(),
            country: 'Venezuela (Bolivarian Republic of)',
            class: 'fi fi-' + 'VE'.toLowerCase()
        });
        this.flags.push({
            initial: 'VN'.toLowerCase(),
            country: 'Vietnam',
            class: 'fi fi-' + 'VN'.toLowerCase()
        });
        this.flags.push({
            initial: 'VG'.toLowerCase(),
            country: 'Virgin Islands (British)',
            class: 'fi fi-' + 'VG'.toLowerCase()
        });
        this.flags.push({
            initial: 'VI'.toLowerCase(),
            country: 'Virgin Islands (U.S.)',
            class: 'fi fi-' + 'VI'.toLowerCase()
        });
        this.flags.push({
            initial: 'GB'.toLowerCase(),
            country: 'WLS Wales',
            class: 'fi fi-' + 'GB'.toLowerCase()
        });
        this.flags.push({
            initial: 'WF'.toLowerCase(),
            country: 'Wallis and Futuna',
            class: 'fi fi-' + 'WF'.toLowerCase()
        });
        this.flags.push({
            initial: 'EH'.toLowerCase(),
            country: 'Western Sahara',
            class: 'fi fi-' + 'EH'.toLowerCase()
        });
        this.flags.push({
            initial: 'YE'.toLowerCase(),
            country: 'Yemen',
            class: 'fi fi-' + 'YE'.toLowerCase()
        });
        this.flags.push({
            initial: 'ZM'.toLowerCase(),
            country: 'Zambia',
            class: 'fi fi-' + 'ZM'.toLowerCase()
        });
        this.flags.push({
            initial: 'ZW'.toLowerCase(),
            country: 'Zimbabwe',
            class: 'fi fi-' + 'ZW'.toLowerCase()
        });
    }

    getFlag(country: string): IUtilsFlag {
        let flag = this.flags.filter(
            (flag) => flag.country.toLocaleLowerCase() === country.toLowerCase()
        );

        if (flag.length > 0) {
            return flag[0];
        }

        flag = this.flags.filter(
            (flag) => flag.country.toLowerCase().indexOf(country.toLowerCase()) > -1
        );

        if (flag.length > 0) {
            return flag[0];
        }

        return {
            initial: 'FM'.toLowerCase(),
            country: 'Federated States of Micronesia',
            class: 'fi fi-' + 'FM'.toLowerCase()
        };
    }

    getFlagClassByInitial(countryInitial: string): string{
        let flag = this.flags.filter((flag) => flag.initial.toLocaleLowerCase() === countryInitial.toLowerCase());

        if (flag.length > 0) {
            return flag[0].class;
        }

        flag = this.flags.filter((flag) => flag.initial.toLowerCase().indexOf(countryInitial.toLowerCase()) > -1);

        if (flag.length > 0) {
            return flag[0].class;
        }

        return 'flag-icon flag-icon-' + 'FM'.toLowerCase();
    }

    getFlags(): IUtilsFlag[] {
        return this.flags;
    }
}
