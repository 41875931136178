import { Pipe, PipeTransform } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { CanSelectRowsFunction } from '../table-groups-reduce-function.type';

@Pipe({
    name: 'isAllSelected'
})
export class IsAllSelectedPipe implements PipeTransform {
    transform(selection: SelectionModel<any>, rows: Array<[number, any[]]>, canSelectRowFn: CanSelectRowsFunction<any>) {
        return (
            selection &&
            rows &&
            selection.hasValue() &&
            selection.selected.length ===
                rows.filter((value) => !this.canSelectRow(value[1], canSelectRowFn)).length
        );
    }

    canSelectRow(row: any, canSelectRowFn: CanSelectRowsFunction<any>): boolean {
        return canSelectRowFn && !canSelectRowFn(row);
    }
}