import { IBuildPlanStatusApprovalsI18n } from './status-approvals.interface';

export const BUILD_PLAN_STATUS_APPROVALS: IBuildPlanStatusApprovalsI18n = {
    BUILD_PLAN_STATUS: {
        TITLE: 'Build Plan Status',
        BUTTON_CHANGE: 'Change Status',
        MESSAGE: {
            SUCCESS: 'Build Plan Status updated'
        },
        WORKFLOW: 'Build Plan Workflow',
        WORKFLOW_INFO: 'View build plan workflow',
        CONFIRMATION: {
            CHANGE_STATUS_TITLE: 'Change of Status',
            LOCKED_ACCESOSRY_TITLE:
                'Move <span class="d-inline-flex align-items-center build-plan-open"><span class="material-icons">brightness_1</span><b>Open</b></span>&nbsp; Accessories\' status to <span class="d-inline-flex align-items-center build-plan-locked"><span class="material-icons">lock</span><b>Locked</b></span>',
            LOCKED_CONFIGURATION_TITLE:
                'Move <span class="d-inline-flex align-items-center build-plan-open"><span class="material-icons">brightness_1</span><b>Open</b></span>&nbsp; Configurations\' status to <span class="d-inline-flex align-items-center build-plan-locked"><span class="material-icons">lock</span><b>Locked</b></span>',
            LOCKED_CONFIGURATION:
                'If yes, all <b>open</b> configurations from {{buildPlanName}} will be automatically moved to <b>locked</b> status.<br>The following configurations would be updated:',
            LOCKED_ACCESSORY:
                'If yes, all <b>open</b> accessories from {{buildPlanName}} will be automatically moved to <b>locked</b> status.<br>The following accessories would be updated:',
            COMPLETED_TITLE: 'Cannot mark {{buildPlanName}} as Completed',
            COMPLETED:
                'Build Plan {{buildPlanName}} contains configurations or accessories not yet completed or canceled. Please make sure <b>all</b> configurations and accessories from this Build Plan are either completed or canceled before marking it as completed.',
            CANCELED_TITLE: 'Are you sure you want to cancel the Build Plan {{buildPlanName}}?',
            CANCELED:
                'Please note that <b>all</b> configurations and accessories from this Build Plan will also be canceled.',
            CANCEL_CONFIM: 'Yes, proceed',
            CANCEL_DENY: 'No, don\'t proceed',
            NO_CONFIGURATIONS:
                'No configurations that would be affected by this change were found.',
            NO_ACCESSORIES: 'No accessories that would be affected by this change were found.'
        },
        PENDING_APPROVAL: 'One or more approvals pending'
    },
    APPROVALS_STATUS: {
        TITLE: 'Approvals Status',
        BUTTONS: {
            APPROVED: 'Approved',
            APPROVE: 'Approve',
            NOT_APPLICABLE: 'Not Applicable'
        },
        SECTION: {
            CONFIGURATION_SCOPE_APPROVAL: 'Configuration & Scope Approvals',
            DPM_APPROVAL: 'DPMs Approvals',
            PM_APPROVAL: 'PMs Approvals',
            CONFIGURATION_SCOPE_DETAILS:
                'Only Users with GTM, GPM and HWPM permissions will be able to approve in this section',
            DPM_DETAILS:
                'Only DPMs (i.e. Department Leads) will be able to approve in this section. Department Leads are categorized as any member of a Galvin Group of type Department',
            PM_DETAILS: 'Only Users with PM permission will be able to approve in this section'
        },
        TEXT_PENDING_APPROVAL: '{{name}} Pending Approval',
        DPM_APPROVALS_REQUIRED: 'All DPM approvals are required for PM approval',
        TEXT_APPROVED: '{{type}} Approved by {{name}} <{{email}}> on {{date}}',
        TEXT_NOT_APPLICABLE: '{{type}} set Not Applicable by {{name}} <{{email}}> on {{date}}',
        MESSAGE: {
            SUCCESS: '{{name}} updated'
        },
        HW_REVISION: 'HW/Revision',
        FVC_TOTAL_CURRENT: 'FVC total requested quantity at the time of approval',
        FVC_TOTAL_PLANNED: 'FVC total planned quantity at the time of approval',
        FVC_DEPARTMENT_CURRENT: 'FVC department requested quantity at the time of approval',
        FVC_DEPARTMENT_PLANNED: 'FVC department planned quantity at the time of approval',
        PLANNED: 'Planned',
        CURRENT_REQ: 'Current Req.',
        TITLE_POPOVER_TABLE: 'Stats at Approval time',
        TOTAL_BP: 'Total BP'
    },
    CHECK_LINKS: {
        TITLE: 'Check Links',
        NOTIFICATIONS: 'Notifications',
        SEE_NOTIFICATIONS: 'See your notifications'
    }
};
