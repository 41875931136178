<span class="generic-value total"
    [ngbPopover]="popAccessories" #popover="ngbPopover"
    placement="left" [popoverClass]="'popover-comment'"
    (mouseenter)="showSPRPopover()" (mouseleave)="closeSPRPopover()"
    autoClose="outside" triggers="manual"> <ng-content></ng-content> </span>

<ng-template #popAccessories >
    <div class="popover-container text-center" (mouseleave)="closeSPRPopover()">
        <div class="item" *ngFor="let item of getItems()">
            <div class="laber">{{ item.label }}</div>
            <div class="total-number">{{ item.total }}</div>
        </div>
    </div>
</ng-template>