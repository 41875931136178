import { Component, Input } from '@angular/core';
import {
    EBuildPlanStatusColor,
    EBuildPlanStatusIcon,
    EBuildPlanStatusLabel
} from '@galvin/core/build-plan/management';
import { EBuildPlanStatusColorkey } from '@galvin/core/build-plan/management/enums/build-type-status-color.enum';
import { EBuildPlanStatusIconKey } from '@galvin/core/build-plan/management/enums/build-type-status-icon.enum';
import { EBuildPlanStatusLabelKey } from '@galvin/core/build-plan/management/enums/build-type-status-label.enum';

@Component({
    selector: 'build-plan-status-icon',
    templateUrl: './build-plan-status-icon.component.html',
    styleUrls: ['./build-plan-status-icon.component.scss']
})
export class BuildPlanStatusIconComponent {
    @Input() bpStatus!: string;

    @Input() toolTip!: boolean;

    getStatusCssClass(status: EBuildPlanStatusColorkey): string {
        return EBuildPlanStatusColor[status];
    }

    getStatusIcon(status: EBuildPlanStatusIconKey): string {
        return EBuildPlanStatusIcon[status];
    }

    getStatusLabel(status: EBuildPlanStatusLabelKey): string {
        return EBuildPlanStatusLabel[status];
    }
}
