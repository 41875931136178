<div class="mat-calendar-header">
    <div class="mat-calendar-controls">
        <button mat-button type="button"
                class="mat-focus-indicator mat-calendar-period-button mat-button mat-button-base">
            <span class="mat-button-wrapper">
                <span id="mat-calendar-button-4">{{periodLabel}}</span>
                <div class="mat-calendar-arrow"></div>
            </span>
            <span class="mat-ripple mat-button-ripple"></span>
            <span class="mat-button-focus-overlay"></span>
        </button>
        <button mat-button (click)="resetDate()">
            {{'BUILD_PLAN.BUILD_PLAN_MANAGEMENT.CONFIGURATION_MANAGEMENT.COMMON.RESET'|translate}}
            <span class="material-icons">access_time</span>
        </button>

        <button mat-icon-button (click)="previousClicked('month')" type="button"
                class="mat-focus-indicator mat-calendar-previous-button mat-icon-button mat-button-base">
            <span class="mat-button-wrapper"></span>
            <span class="mat-ripple mat-button-ripple mat-button-ripple-round"></span>
            <span class="mat-button-focus-overlay"></span>
        </button>
        <button mat-icon-button type="button" (click)="nextClicked('month')"
                class="mat-focus-indicator mat-calendar-next-button mat-icon-button mat-button-base">
            <span class="mat-button-wrapper"></span>
            <span class="mat-ripple mat-button-ripple mat-button-ripple-round"></span>
            <span class="mat-button-focus-overlay"></span>
        </button>
    </div>
</div>
