<!-- begin:: Footer -->
<div class="kt-footer kt-grid__item kt-grid kt-grid--desktop kt-grid--ver-desktop" id="kt_footer">
    <div class="kt-container" [ngClass]="{ 'kt-container--fluid': fluid }">

        <div class="kt-footer__copyright">
            Copyright {{ today | date: "yyyy" }}&nbsp;&copy;&nbsp; Motorola Mobility Technologies, Ltd. All Rights
            Reserved. Motorola Confidential Restricted - Internal Only
        </div>
        <div class="kt-footer__sha1">
            {{sha1}}
        </div>
    </div>

</div>
<!-- end:: Footer -->