<div class="floating" *ngIf="showHelp">
    <ng-container *ngIf="opened">
            <a class="circle" target="_blank" [ngbTooltip]="'Raise Jira Issue'" placement="left" container="body" href="https://idart.mot.com/secure/CreateIssueDetails!init.jspa?pid=38190&issuetype=10&priority=3&summary=[GALVIN%20Support]%20%3CPlease,%20add%20your%20request%20summary%20here%3E&description=%3CPlease%20fill%20the%20information%20below%20and%20provide%20a%20brief%20description%20of%20your%20request%3E&components=106724&customfield_10005=10002&customfield_16116=28228">
                <img  width="70%" src="../../../../assets/galvin/icons/icon_raise_cr.svg">
            </a>
            <a class="circle" target="_blank" [ngbTooltip]="'Galvin Support Email'" placement="left" container="body" [href]="'mailto:'+galvinSupportEmail">
                <mat-icon class="icon-size"> email </mat-icon>
            </a>
            <a class="circle" target="_blank"  [ngbTooltip]="'FAQ'" placement="left" container="body" [routerLink]="'/build-plan/faq'">
                <img  width="60%" src="../../../../assets/galvin/icons/icon_floating_button_faq (2).svg">
            </a>
            <div class="circle" [ngbTooltip]="'Galvin AI'" placement="left" container="body">
                <kt-chatbot-button class="ai-chatbot"></kt-chatbot-button>
            </div>
    </ng-container>
    <a  (click)="open()">
        <div class="close-button" *ngIf="!opened" (click)="dismissHelp()"><mat-icon>close</mat-icon></div>
        <div class="circle main">
            <mat-icon class="icon-main" *ngIf="!opened" [ngbTooltip]="'Support'" placement="left"  tooltipClass="help-tooltip">help_outline</mat-icon>
            <mat-icon class="icon-main" *ngIf="opened">close</mat-icon>
        </div>
    </a>
</div>
