export interface IConfigurationCloneColumns {
    id: number;
    name: string;
    order: number;
    configuration: any;
    fvcColumns: any;
    factoryColumns: any;
    configurationColumns: any;
    configurationStatusColumns: any;
    datesColumns: any[];
    datesRelativeColumns: any;
    buildPlanId?: number;
    status?: string;
}
