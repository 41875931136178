import {IScopeParameterResponse} from './../interfaces/scope-parameter.response.interface';
import {IScopeParameter, IScopeValue} from './../../scopes';
import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';

// Enviroments
import {environment} from '../../../../../../environments/environment';
import {map} from 'rxjs/operators';
import {ResourceService} from '@galvin/core/_base/resource';
import {ScopeResource} from '@galvin/core/build-plan/field-value-management/scopes/services/scope-resource';
import {IDeletableValue} from '@galvin/core/build-plan/field-value-management/fvc-parameters/interfaces/deletable-parameter.interface';
import {
    IBuildPlanScopeValueAssociation,
    IBuildPlanScopeValueAssociationStatus,
    IScopeValueAssociation
} from '@galvin/core/build-plan/field-value-management/scopes/interfaces/build-plan-scope-value-association.interface';

@Injectable({
    providedIn: 'root'
})
export class ScopesService extends ResourceService {
    private scopeResource = new ScopeResource();

    private readonly BASE_URL = '/build-plan/field-values-management/scopes';
    private readonly API_URL = `${environment.baseUrlApi}/build-plan/field-values-management/scopes`;

    constructor(private httpClient: HttpClient) {
        super(httpClient);
    }

    public getAllScopesAndValues(): Observable<IScopeParameterResponse> {
        return this.httpClient.get<IScopeParameterResponse>(this.API_URL + '/parameters/values');
    }

    public getAllScopesParameters(): Observable<IScopeParameterResponse> {
        return this.httpClient.get<IScopeParameterResponse>(this.API_URL + '/parameters');
    }

    public getScopeParameterWithValues(idParameter: number): Observable<IScopeParameterResponse> {
        return this.httpClient.get<IScopeParameterResponse>(
            `${this.API_URL}/parameters/${idParameter}/values`
        );
    }

    public updateScopeParameterStatus(
        scopeParameter: IScopeParameter
    ): Observable<IScopeParameter> {
        return this.httpClient.put<IScopeParameter>(
            `${this.API_URL}/parameters/${scopeParameter.id}/update-status`,
            null
        );
    }

    public updateScopeParameterMandatoryStatus(
        scopeParameter: IScopeParameter
    ): Observable<IScopeParameter> {
        return this.httpClient.put<IScopeParameter>(
            `${this.API_URL}/parameters/${scopeParameter.id}/update-mandatory-status`,
            null
        );
    }

    public addScopeParameter(scopeParameter: IScopeParameter): Observable<IScopeParameterResponse> {
        return this.httpClient.post<IScopeParameterResponse>(
            `${this.API_URL}/parameters/`,
            scopeParameter
        );
    }

    public updateScopeParameter(
        scopeParameter: IScopeParameter,
        scopeValues: IScopeValue[]
    ): Observable<IScopeParameterResponse> {
        scopeParameter.scopeValues = scopeValues;
        return this.httpClient.put<IScopeParameterResponse>(
            `${this.API_URL}/parameters/${scopeParameter.id}`,
            scopeParameter
        );
    }

    /**
     * validates if scope name exists
     * @param name name parameter
     * @param id id if exists
     */
    public getByNameUsingGET(name: string, id?: number): Observable<IScopeParameter> {
        this.scopeResource._links.self = {
            href: `${this.API_URL}/parameters/name/${encodeURIComponent(String(name))}`
        };
        let queryParameters = new HttpParams();
        if (id) {
            queryParameters = queryParameters.set('id', String(id));
        }
        return this.get<IScopeParameter, ScopeResource>(this.scopeResource,
            {params: queryParameters}
        ).pipe(map(value => value.content));
    }

    /**
     * Get a list of deletable Scope Parameters.
     */
    getDeletableScopeParameters(): Observable<IDeletableValue[]> {
        return this.httpClient.get<any>(
            `${this.API_URL}/parameters/can-delete`
        ).pipe(map(res => res.content));
    }

    /**
     * Delete a scope and all values if not used.
     * @params idScope
     */
    deleteScopeParameter(idScope: number): Observable<any> {
        return this.httpClient.delete<any>(
            `${this.API_URL}/parameters/${idScope}`
        );
    }

    /**
     * Get a list of deletable values.
     * @param parameter parameter id
     */
    getDeletableValues(parameter: number): Observable<IDeletableValue> {
        return this.httpClient.get<any>(
            `${this.API_URL}/parameters/${parameter}/values/can-delete`
        ).pipe(map(res => res.content));
    }

    /**
     * Delete a value by value id.
     * @param value value is the id from scope value
     */
    deleteValue(value: number): Observable<void> {
        return this.httpClient.delete<any>(
            `${this.API_URL}/parameters/values/${value}`
        );
    }

    /**
     * Update status of scope value from scope parameter
     * @param parameterId scope parameter id
     * @param valueId scope value id
     */
    updateScopeValueStatus(parameterId: number, valueId: number): Observable<IScopeParameter> {
        this.scopeResource._links.self = {
            href: `${this.BASE_URL}/parameters/${parameterId}/update-status/${valueId}`
        };
        return this.put<IScopeParameter, ScopeResource>(this.scopeResource).pipe(map(value => value.content));
    }

    /**
     * Updates a value by scope parameter & scope value.
     * @param parameterId scope parameter id
     * @param fieldValue scope value object
     */
    updateScopeValue(parameterId: number, fieldValue: any): Observable<IScopeValue> {

        this.scopeResource._links.self = {
            href: `${this.BASE_URL}/parameters/${parameterId}/values/${fieldValue.id}`
        };
        this.scopeResource.content = fieldValue;
        return this.put<IScopeParameter, ScopeResource>(this.scopeResource)
            .pipe(map(v => v.content));
    }

    /**
     * add value by id parameter
     * @param parameterId scope parameter id
     * @param fieldValue scope value object
     */
    addScopeValue(parameterId: number, fieldValue: IScopeValue): Observable<IScopeValue> {
        return this.httpClient.post<any>(
            `${this.API_URL}/parameters/${parameterId}/values`,
            fieldValue
        ).pipe(map(res => res.content));
    }


    /**
     * Gets Build Plan Scope Associations from a list of scope values
     * All users Build Plan except Group Manager and Requestor
     * @param idParameter id-parameter
     * @param idValue id-value
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBuildPlanScopeAssociationsUsingGET(idParameter: number, idValue: number): Observable<IBuildPlanScopeValueAssociation> {
        this.scopeResource._links.self = {
            href: `${this.BASE_URL}/parameters/${encodeURIComponent(String(idParameter))}/values/${encodeURIComponent(String(idValue))}/build-plans/scope-associations`
        };
        return this.get<IBuildPlanScopeValueAssociationStatus, ScopeResource>(this.scopeResource).pipe(map(res => res.content));
    }

    /**
     * Gets Scope Management Associations from a scope value
     * All users Build Plan except Group Manager and Requestor
     * @param idParameter id-parameter
     * @param idValue id-value
     */
    public getScopeManagementAssociationsUsingGET(idParameter: number, idValue: number, value?: string): Observable<IScopeValueAssociation> {
        let queryParameters = new HttpParams();

        if (value) {
            queryParameters = queryParameters.set('value', String(value));
        }

        this.scopeResource._links.self = {
            href: `${this.BASE_URL}/parameters/${encodeURIComponent(String(idParameter))}/values/${encodeURIComponent(String(idValue))}/scopes-management`
        };
        return this.get<any, ScopeResource>(this.scopeResource, {params: queryParameters}).pipe(map(res => res.content));
    }
}
