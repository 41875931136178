// Angular
import { Pipe, PipeTransform } from '@angular/core';

/**
 * Return group an object array by a property
 */
@Pipe({
    name: 'filterBy'
})
export class FilterByPipe implements PipeTransform {
    /**
     * Transform
     *
     * @param array: number
     */
    transform(array: any[], property: string, value: string): any[] | undefined {
        if (Array.isArray(array)) {
            return array.filter((a) => a[property] === value);
        }
        return [];
    }
}
