<div class="container-dialog flex-grow-1" [ngClass]="{'clean-grid-template':requestPreview}">
    <div class="header">
        <div class="grid-header" [ngClass]="{'clean-grid-template-areas':requestPreview}">
            <section class="d-flex" id="loadSectionIconHeader1">
                <div class="d-flex text-default pt-3">

                    <ngx-skeleton-loader class="pb-4 pl-4" count="1" appearance="circle"
                        [theme]="{width: '48px', height: '51px','border-radius': '0px'}">
                    </ngx-skeleton-loader>
                    <ngx-skeleton-loader count="3"
                        [theme]="{width: '100px', height: '12px','border-radius': '0px', 'margin':'0'}">
                    </ngx-skeleton-loader>
                </div>
            </section>


            <section class="d-flex justify-content-md-between justify-content-sm-between justify-content-lg-center">
                <ngx-skeleton-loader class="mt-2" count="1" [style.width.%]="100" appearance="circle"
                    [theme]="{width: '95%', height: '69px','border-radius': '0px'}">
                </ngx-skeleton-loader>
            </section>
        </div>

    </div>
    <div class="side-bar" [hidden]="requestPreview">
        <div class="h-100 load-container">
            <section class="header-side-bar d-flex align-items-center justify-content-between pl-3 pr-3">
                <ngx-skeleton-loader count="2" [style.width.%]="100" appearance="circle"
                    [theme]="{width: '95%', height: '12px','border-radius': '0px'}">
                </ngx-skeleton-loader>
            </section>
            <section class="form-group side-bar-content  p-3 m-0" id="loadQuantityContainer1">

                <ngx-skeleton-loader count="1" [style.width.%]="100" appearance="circle"
                    [theme]="{width: '95%', height: '12px','border-radius': '0px'}">
                </ngx-skeleton-loader>
                <ngx-skeleton-loader count="1" [style.width.%]="100" appearance="circle"
                    [theme]="{width: '95%', height: '52px','border-radius': '0px'}">
                </ngx-skeleton-loader>
                <ngx-skeleton-loader count="1" [style.width.%]="100" appearance="circle"
                    [theme]="{width: '95%', height: '12px','border-radius': '0px'}">
                </ngx-skeleton-loader>

            </section>
            <section class="form-group side-bar-content  p-3 m-0" id="loadDeviceContainer1">

                <ngx-skeleton-loader count="1" [style.width.%]="100" appearance="circle"
                    [theme]="{width: '95%', height: '12px','border-radius': '0px'}">
                </ngx-skeleton-loader>
                <ngx-skeleton-loader count="1" [style.width.%]="100" appearance="circle"
                    [theme]="{width: '95%', height: '52px','border-radius': '0px'}">
                </ngx-skeleton-loader>
                <ngx-skeleton-loader count="1" [style.width.%]="100" appearance="circle"
                    [theme]="{width: '95%', height: '12px','border-radius': '0px'}">
                </ngx-skeleton-loader>

            </section>
            <section class="form-group  pl-3 pr-4 m-0" id="loadJustification1">
                <div>
                    <div class="d-flex flex-wrap">
                        <ngx-skeleton-loader class="flex-grow-side-bar mb-0" count="1" [style.width.%]="100"
                            appearance="circle" [theme]="{width: '50%', height: '12px','border-radius': '0px'}">
                        </ngx-skeleton-loader>

                    </div>
                    <div class="d-flex flex-wrap">
                        <ngx-skeleton-loader class="flex-grow-side-bar mb-0" count="1" [style.width.%]="100"
                            appearance="circle" [theme]="{width: '95%', height: '12px','border-radius': '0px'}">
                        </ngx-skeleton-loader>

                    </div>
                    <div class="d-flex flex-wrap skeleton-side-bar">
                        <ngx-skeleton-loader class="flex-grow-side-bar mb-0" count="1" [style.width.%]="100"
                            appearance="circle" [theme]="{width: '95%', height: '12px','border-radius': '0px'}">
                        </ngx-skeleton-loader>

                    </div>
                    <div class="d-flex flex-wrap skeleton-side-bar">
                        <ngx-skeleton-loader class="flex-grow-side-bar mb-0" count="1" [style.width.%]="100"
                            appearance="circle" [theme]="{width: '95%', height: '12px','border-radius': '0px'}">
                        </ngx-skeleton-loader>

                    </div>
                </div>
            </section>
            <section class="form-group  side-bar-content p-3 m-0" id="loadCommentsContainer1">
                <div>
                    <div class="d-flex flex-wrap">
                        <ngx-skeleton-loader class="flex-grow-side-bar mb-0" count="1" [style.width.%]="100"
                            appearance="circle" [theme]="{width: '50%', height: '12px','border-radius': '0px'}">
                        </ngx-skeleton-loader>

                    </div>
                    <div class="d-flex flex-wrap">
                        <ngx-skeleton-loader class="flex-grow-side-bar mb-0" count="1" [style.width.%]="100"
                            appearance="circle" [theme]="{width: '95%', height: '56px','border-radius': '0px'}">
                        </ngx-skeleton-loader>

                    </div>
                    <div class="d-flex flex-wrap">
                        <ngx-skeleton-loader class="flex-grow-side-bar mb-0" count="1" [style.width.%]="100"
                            appearance="circle" [theme]="{width: '50%', height: '12px','border-radius': '0px'}">
                        </ngx-skeleton-loader>

                    </div>
                </div>
            </section>
            <div class="footer-side-bar  d-flex align-items-end w-100 pl-3 pr-3">
                <ngx-skeleton-loader class="flex-grow-side-bar mb-0" count="1" [style.width.%]="100" appearance="circle"
                    [theme]="{width: '95%', height: '50px','border-radius': '0px'}">
                </ngx-skeleton-loader>
            </div>
        </div>
    </div>

    <div class="content mt-4 ml-5" [ngClass]="{'clean-size-grid-template-areas':requestPreview}">
        <div class="d-flex flex-column overflow-auto align-items-start" [style.height.px]="665">
            <div class="mh-100">
                <div class="d-flex flex-column mb-5">
                    <ngx-skeleton-loader count="1" class="m-2"
                        [theme]="{width: '250px', height: '12px','border-radius': '0px', 'margin':'0'}">
                    </ngx-skeleton-loader>
                    <div class="row w-100">
                        <div class="col-12">
                            <div class="d-flex flex-row justify-content-between">
                                <ngx-skeleton-loader count="40" class="m-2"
                                    [theme]="{width: '100px', height: '12px','border-radius': '0px', 'margin':'0'}">
                                </ngx-skeleton-loader>
                                <ngx-skeleton-loader count="40" class="m-2"
                                    [theme]="{width: '100px', height: '12px','border-radius': '0px', 'margin':'0'}">
                                </ngx-skeleton-loader>
                                <ngx-skeleton-loader [hidden]="!requestPreview" count="40" class="m-2"
                                    [theme]="{width: '100px', height: '12px','border-radius': '0px', 'margin':'0'}">
                                </ngx-skeleton-loader>
                            </div>
                        </div>
                    </div>
                    <div class="row w-100">
                        <div [class]="requestPreview ? 'col-3' : 'col-6'">
                            <ngx-skeleton-loader count="1" class="mr-2"
                                [theme]="{width: '250px', height: '12px','border-radius': '0px', 'margin':'0'}">
                            </ngx-skeleton-loader>
                            <div class="d-flex flex-row justify-content-between">
                                <ngx-skeleton-loader count="20" class="m-2"
                                    [theme]="{width: '100px', height: '12px','border-radius': '0px', 'margin':'0'}">
                                </ngx-skeleton-loader>
                            </div>
                        </div>
                        <div [class]="requestPreview ? 'col-3' : 'col-6'">
                            <ngx-skeleton-loader count="1" class="mr-2"
                                [theme]="{width: '250px', height: '12px','border-radius': '0px', 'margin':'0'}">
                            </ngx-skeleton-loader>
                            <div class="d-flex flex-row justify-content-between">
                                <ngx-skeleton-loader count="20" class="m-2"
                                    [theme]="{width: '100px', height: '12px','border-radius': '0px', 'margin':'0'}">
                                </ngx-skeleton-loader>
                            </div>
                        </div>
                        <div [class]="requestPreview ? 'col-3' : 'col-6'">
                            <ngx-skeleton-loader count="1" class="mr-2"
                                [theme]="{width: '250px', height: '12px','border-radius': '0px', 'margin':'0'}">
                            </ngx-skeleton-loader>
                            <div class="d-flex flex-row justify-content-between">
                                <ngx-skeleton-loader count="20" class="m-2"
                                    [theme]="{width: '100px', height: '12px','border-radius': '0px', 'margin':'0'}">
                                </ngx-skeleton-loader>
                            </div>
                        </div>
                        <div [class]="requestPreview ? 'col-3' : 'col-6'">
                            <ngx-skeleton-loader count="1" class="mr-2"
                                [theme]="{width: '250px', height: '12px','border-radius': '0px', 'margin':'0'}">
                            </ngx-skeleton-loader>
                            <div class="d-flex flex-row justify-content-between">
                                <ngx-skeleton-loader count="20" class="m-2"
                                    [theme]="{width: '100px', height: '12px','border-radius': '0px', 'margin':'0'}">
                                </ngx-skeleton-loader>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div [hidden]="requestPreview"
            class="d-flex flex-column overflow-auto position-relative mr-4 ml-4 activity-container"
            id="activityContainer">
            <ngx-skeleton-loader count="1"
                [theme]="{width: '250px', height: '12px','border-radius': '0px', 'margin':'0'}">
            </ngx-skeleton-loader>
            <div class="d-flex flex-column mt-4 mb-2 content-activity">
                <div class="d-flex">
                    <ngx-skeleton-loader appearance="circle" [theme]="{ width: '38px', height: '38px' }">
                    </ngx-skeleton-loader>
                    <div class="d-flex flex-column ml-3 mr-3 activity-text-content">
                        <ngx-skeleton-loader count="3"
                            [theme]="{width: '200px','border-radius': '0',height: '10px','margin-bottom': '0px'}">
                        </ngx-skeleton-loader>
                    </div>
                </div>
                <div class="d-flex">
                    <ngx-skeleton-loader appearance="circle" [theme]="{ width: '38px', height: '38px' }">
                    </ngx-skeleton-loader>
                    <div class="d-flex flex-column ml-3 mr-3 activity-text-content">
                        <ngx-skeleton-loader count="3"
                            [theme]="{width: '200px','border-radius': '0',height: '10px','margin-bottom': '0px'}">
                        </ngx-skeleton-loader>
                    </div>
                </div>
                <div class="d-flex">
                    <ngx-skeleton-loader appearance="circle" [theme]="{ width: '38px', height: '38px' }">
                    </ngx-skeleton-loader>
                    <div class="d-flex flex-column ml-3 mr-3 activity-text-content">
                        <ngx-skeleton-loader count="3"
                            [theme]="{width: '200px','border-radius': '0',height: '10px','margin-bottom': '0px'}">
                        </ngx-skeleton-loader>
                    </div>
                </div>
                <div class="d-flex">
                    <ngx-skeleton-loader appearance="circle" [theme]="{ width: '38px', height: '38px' }">
                    </ngx-skeleton-loader>
                    <div class="d-flex flex-column ml-3 mr-3 activity-text-content">
                        <ngx-skeleton-loader count="3"
                            [theme]="{width: '200px','border-radius': '0',height: '10px','margin-bottom': '0px'}">
                        </ngx-skeleton-loader>
                    </div>
                </div>
                <div class="d-flex">
                    <ngx-skeleton-loader appearance="circle" [theme]="{ width: '38px', height: '38px' }">
                    </ngx-skeleton-loader>
                    <div class="d-flex flex-column ml-3 mr-3 activity-text-content">
                        <ngx-skeleton-loader count="3"
                            [theme]="{width: '200px','border-radius': '0',height: '10px','margin-bottom': '0px'}">
                        </ngx-skeleton-loader>
                    </div>
                </div>
                <div class="d-flex">
                    <ngx-skeleton-loader appearance="circle" [theme]="{ width: '38px', height: '38px' }">
                    </ngx-skeleton-loader>
                    <div class="d-flex flex-column ml-3 mr-3 activity-text-content">
                        <ngx-skeleton-loader count="3"
                            [theme]="{width: '200px','border-radius': '0',height: '10px','margin-bottom': '0px'}">
                        </ngx-skeleton-loader>
                    </div>
                </div>
                <div class="d-flex">
                    <ngx-skeleton-loader appearance="circle" [theme]="{ width: '38px', height: '38px' }">
                    </ngx-skeleton-loader>
                    <div class="d-flex flex-column ml-3 mr-3 activity-text-content">
                        <ngx-skeleton-loader count="3"
                            [theme]="{width: '200px','border-radius': '0',height: '10px','margin-bottom': '0px'}">
                        </ngx-skeleton-loader>
                    </div>
                </div>

            </div>
        </div>
    </div>