<mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
    <!-- Leaf node -->
    <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding [attr.data-level]="node.level">
        <button mat-icon-button disabled></button>
        <ng-template #defaultContentTooltipNode>
            <ng-container [ngTemplateOutlet]="getNodeTooltip(node.level)" [ngTemplateOutletContext]="{node:node}">
            </ng-container>
        </ng-template>
        <mat-checkbox class="checklist-leaf-node" [checked]="checklistSelection.isSelected(node)"
            (change)="todoLeafItemSelectionToggle(node)" [ngbTooltip]="defaultContentTooltipNode">
            <ng-container [ngTemplateOutlet]="getNodeTemplate(defaultContentNode,node.level)"
                [ngTemplateOutletContext]="{node:node}">
            </ng-container>
        </mat-checkbox>
    </mat-tree-node>

    <!-- expandable node -->
    <mat-tree-node *matTreeNodeDef="let node; when: hasChild" [attr.data-level]="node.level" matTreeNodePadding>
        <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'toggle ' + node.name">
            <mat-icon class="mat-icon-rtl-mirror">
                {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
            </mat-icon>
        </button>

        <ng-template #defaultContentTooltipNode>
            <ng-container [ngTemplateOutlet]="getNodeTooltip(node.level)" [ngTemplateOutletContext]="{node:node}">
            </ng-container>
        </ng-template>

        <mat-checkbox [checked]="descendantsAllSelected(node)" [indeterminate]="descendantsPartiallySelected(node)"
            (change)="todoItemSelectionToggle(node)" [ngbTooltip]="defaultContentTooltipNode">
            <ng-container [ngTemplateOutlet]="getNodeTemplate(defaultContentNode,node.level)"
                [ngTemplateOutletContext]="{node:node}">
            </ng-container>
        </mat-checkbox>
    </mat-tree-node>
</mat-tree>

<ng-template #defaultContentNode let-node="node">
    {{node.name}}
</ng-template>