import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { IPrototypeRequestI18n } from '@galvin/core/build-plan/prototype-request-management/i18n/prototype-request-i18n.interface';
import { TranslateService } from '@ngx-translate/core';
import { isEmpty } from 'lodash';

interface ISelector {
    [Key: string]: { group: string; value: string }[];
}

interface ISelected {
    [Key: string]: string[];
}
@Component({
    selector: 'multi-selectors',
    templateUrl: './multi-selectors.component.html',
    styleUrls: ['./multi-selectors.component.scss']
})
export class MultiSelectorsComponent implements OnInit, OnChanges {
    labels!: IPrototypeRequestI18n;
    labelSelector!: { name: string; key: string }[];
    itemsSelected: ISelected = {};
    isDisabled = false;
    readonly MAX_TITLE_LENGTH: number = 45;

    @Input()
    set disabled(isDisabled: boolean) {
        this.isDisabled = isDisabled;
    }

    @Input()
        itemsSelector: ISelector = {};

    @Input()
        titleSelector!: string;

    @Input()
        recoverySelected: ISelected = {};

    @Output()
        selectorChange = new EventEmitter<ISelected>();

    constructor(private translate: TranslateService) {}

    ngOnInit(): void {
        this.labels = this.translate.instant('BUILD_PLAN.PROTO_REQUEST');
        if (this.recoverySelected) {
            this.itemsSelected = this.recoverySelected;
        }
    }

    ngOnChanges(): void {
        if (this.itemsSelector) {
            this.labelSelector = Object.keys(this.itemsSelector).map((item) => {
                const name = item[0].toUpperCase() + item.substr(1);

                return { name, key: item };
            });
        }

        if (this.recoverySelected) {
            this.itemsSelected = this.recoverySelected;
        }
    }

    dropdownChange(isOpen: boolean): void {
        if (!isOpen) {
            this.selectorChange.emit(this.itemsSelected);
        }
    }

    onItemSelected(items: string[], label: string): void {
        this.itemsSelected[label] = items;
    }

    infoItemSelected(): string {
        let text = '';
        Object.keys(this.itemsSelected).forEach((item) => {
            if (!isEmpty(this.itemsSelected[item])) {
                if (text === '') {
                    text = item[0].toUpperCase() + item.substr(1);
                } else {
                    text += ` | ${item[0].toUpperCase() + item.substr(1)}`;
                }
                let selectedItemArr = new Array;
                this.itemsSelected[item].forEach((seletedItem) => {
                    seletedItem = this.shortVersionName(seletedItem);
                    selectedItemArr.push(seletedItem);
                });
                text += '('+selectedItemArr.join(',')+')';
            }
        });

        const itemsSelected = this.translate.instant('COMMON.ITEM_SELECTED', { text });
        return text === '' ? this.titleSelector : itemsSelected;
    }

    infoTooltipMore(items: string[]): string {
        const quantity = items.length - 3;
        return this.translate.instant('COMMON.NUMBER_MORE', { quantity });
    }
    searchCustomerReg(term: string, item: any) : boolean{
        if(item.value){
            const regex = new RegExp(term, 'i');
            return regex.test(item.value);
        }
        return false;
    }
    shortVersionName(name:string):string{
        if(name){
            return name.split('-')[0].trim();
        }
        return '';
    }
    titleInfoShort(title:string) : string {
        if(title.length > this.MAX_TITLE_LENGTH) {
            return title.substring(0,this.MAX_TITLE_LENGTH) + '...';
        }
        return title;
    }
}
