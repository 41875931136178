import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IConfigurationI18n } from '@galvin/core/build-plan/configuration-management/i18n/configuration-i18n.interface';
import { ISuggestAliasInterface } from '@galvin/core/_base/layout';
import { TranslateService } from '@ngx-translate/core';

export enum EStatus {
    READ = 'READ'
}
@Component({
    selector: 'suggest-alias-component',
    templateUrl: './suggest-alias.component.html',
    styleUrls: ['./suggest-alias.component.scss']
})
export class SuggestAliasComponent implements OnInit {
    public nameUpdate!: string;
    public labels!: IConfigurationI18n;

    constructor(
        public dialogRef: MatDialogRef<SuggestAliasComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ISuggestAliasInterface,
        private i18n: TranslateService
    ) {}

    ngOnInit(): void {
        this.labels = this.i18n.instant(
            'BUILD_PLAN.BUILD_PLAN_MANAGEMENT.CONFIGURATION_MANAGEMENT'
        );
        this.nameUpdate = this.data.dataToSuggest;
    }

    /**
     * Closes the current Suggest Alias Component Dialog
     */
    onCloseDialog(): void {
        this.dialogRef.close();
    }


    get buttonDisabled():boolean{
        return this.nameUpdate.length == 0;
    }

    /**
     * Function triggered on button Update click. It loads the proper function
     * according to the previous windows: BP Management tabs, Team Management
     * or Scope Management.
     */
    onButtonUpdateClick(): void {
        this.dialogRef.close(this.nameUpdate);
    }
}
