import { Component, Input, OnInit } from '@angular/core';
import { ERequestChangeType } from '@galvin/core/build-plan/prototype-request-management';
import { ERequestOperationType } from '@galvin/core/build-plan/prototype-request-management/enums/requests-operation-type-enum';
import { ETeamProtoRequestJustification } from '@galvin/core/build-plan/prototype-request-management/enums/team-proto-request-justification-enum';
import { IRequestActivity } from '@galvin/core/build-plan/prototype-request-management/interfaces/request-activity.interface';

@Component({
    selector: 'request-activity',
    templateUrl: './request-activity.component.html',
    styleUrls: ['./request-activity.component.scss']
})
export class RequestActivityComponent implements OnInit {
    readonly INCREASE = ERequestChangeType.INCREASE;
    readonly DECREASE = ERequestChangeType.DECREASE;
    readonly EQUAL = ERequestChangeType.EQUAL;

    requestActivity: IRequestActivity[] = [];

    @Input()
    set activity(requestActivity: IRequestActivity[]) {
        this.formatData(requestActivity);
    }

    public loadRequest = true;

    ngOnInit(): void {
        this.loadRequest = true;
    }

    /**
     * Checks if the change type of a certain activity matches the expected type
     *
     * @param activityType type of activity
     * @param type expected type
     * @return boolean
     */
    isChangeType(activity: IRequestActivity, type: ERequestChangeType): boolean {
        if (!this.isWasAChangeInRequest(activity.operation)) {
            return false;
        }

        return activity.requestChangeType === type;
    }

    /**
     * Check if the change belongs Configuration
     * @param operation type of iteration
     * @returns a boolean value
     */
    isChangeRequestOperationTypeFromConfiguration(
        operationType: ERequestOperationType | undefined
    ): boolean {
        if (this.isWasAChangeInRequest(operationType)) {
            return false;
        }

        return (
            operationType === ERequestOperationType.CONFIGURATION_EDIT ||
            operationType === ERequestOperationType.CONFIGURATION_FVC_DELETE ||
            operationType === ERequestOperationType.CONFIGURATION_EDIT_MASSIVE ||
            operationType === ERequestOperationType.CONFIGURATION_PROPAGATE
        );
    }

    /**
     * Check if the change belongs BP Team
     * @param operation type of iteration
     * @returns a boolean value
     */
    isChangeRequestOperationTypeFromTeam(
        operationType: ERequestOperationType | undefined
    ): boolean {
        if (this.isWasAChangeInRequest(operationType)) {
            return false;
        }

        return (
            operationType === ERequestOperationType.BP_TEAM_EDIT ||
            operationType === ERequestOperationType.BP_TEAM_LABELS ||
            operationType === ERequestOperationType.BP_TEAM_PROPAGATE
        );
    }

    /**
     * Check if the change belongs BP Team
     * @param operation type of iteration
     * @returns a boolean value
     */
    isChangeRequestOperationTypeFromSpecialRequirements(
        operationType: ERequestOperationType | undefined
    ): boolean {
        if (this.isWasAChangeInRequest(operationType)) {
            return false;
        }

        return operationType === ERequestOperationType.SPECIAL_REQUIREMENTS_PROPAGATE;
    }

    /**
     * Check if the change belongs request
     * @param operation type of iteration
     * @returns a boolean value
     */
    isWasAChangeInRequest(operation: ERequestOperationType | undefined): boolean {
        return (
            (operation && operation === ERequestOperationType.INSERT) ||
            operation === ERequestOperationType.EDIT
        );
    }

    /**
     * Get a message for template history
     *
     * @param operation type of iteration
     * @returns a message key for translate
     */
    getMessageForIndirectEdition(operation: ERequestOperationType | undefined): string {
        let message = '';

        switch (operation) {
        case ERequestOperationType.CONFIGURATION_EDIT:
        case ERequestOperationType.CONFIGURATION_EDIT_MASSIVE:
        case ERequestOperationType.CONFIGURATION_PROPAGATE:
        case ERequestOperationType.CONFIGURATION_FVC_DELETE:
        case ERequestOperationType.CONFIGURATION_DELETE:
            message =
                    'BUILD_PLAN.PROTOTYPE_REQUEST.DIALOG.LABELS.REQUEST_CONFIGURATION_CHANGED';
            break;
        case ERequestOperationType.BP_TEAM_EDIT:
        case ERequestOperationType.BP_TEAM_LABELS:
        case ERequestOperationType.BP_TEAM_PROPAGATE:
        case ERequestOperationType.BP_TEAM_DELETE:
            message = 'BUILD_PLAN.PROTOTYPE_REQUEST.DIALOG.LABELS.REQUEST_TEAM_CHANGED';
            break;
        case ERequestOperationType.SPECIAL_REQUIREMENTS_PROPAGATE:
            message =
                    'BUILD_PLAN.PROTOTYPE_REQUEST.DIALOG.LABELS.REQUEST_SPECIAL_REQUIREMENTS_CHANGED';
            break;
        }

        return message;
    }
    /**
     * Given an array of justifications, return the values of the array as a string os items separated by comma
     *
     * @param justification array of ETeamProtoRequestJustification
     * @return string from array
     */
    getJustificationAsString(justification: ETeamProtoRequestJustification[]): string {
        return justification.join(', ');
    }

    /**
     * Given an activity return the user who performed it in the format 'User Name (email)'
     *
     * @param activity IRequestActivity
     * @return user who did the activity
     */
    getActivityUser(activity: IRequestActivity): string {
        if (activity.user.name && activity.user.lastName) {
            return (
                activity.user.name + ' ' + activity.user.lastName + ' (' + activity.user.email + ')'
            );
        }
        return activity.user.email;
    }

    /**
     * Format activity data received from server to ensure all enums are properly mapped
     *
     * @param requestActivity: activity received from server
     */
    private formatData(requestActivity: IRequestActivity[]): void {
        requestActivity.forEach((activity) => {
            const changeType: ERequestChangeType = ERequestChangeType[activity.requestChangeType];
            activity.requestChangeType = changeType;

            if (activity.justifications) {
                const justifications: ETeamProtoRequestJustification[] = [];
                activity.justifications.forEach((j) => {
                    // It can generate side effects
                    justifications.push(j);
                });
                activity.justifications = justifications;
            }
        });
        this.requestActivity = requestActivity;
    }
}
