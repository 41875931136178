import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class TableSelectionService {
    private selectedCells = new BehaviorSubject<any>('');
    private newSelectedCell = new BehaviorSubject<any>('');
    private clearCuttedCells = new BehaviorSubject<any>('');

    selectedCells$ = this.selectedCells.asObservable();
    newSelectedCell$ = this.newSelectedCell.asObservable();
    clearCuttedCell$ = this.clearCuttedCells.asObservable();

    updateSelectedCells(selectedCells: Set<any>) {
        this.selectedCells.next(selectedCells);
    }

    changeSelectedByFocus(cell: HTMLTableCellElement) {
        this.newSelectedCell.next(cell);
    }

    clearCutttedCells() {
        this.clearCuttedCells.next(null);
    }
}