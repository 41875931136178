import { ISkuMatrixI18n } from './sku-matrix-i18n.interface';

export const SKU_MATRIX_MANAGEMENT: ISkuMatrixI18n = {
    TITLE: 'Sku Matrix',
    COMMON: {
        FIELDS: {
            NAME: 'Sku Matrix name'
        },
        BUTTONS: {
            NEW: 'New Sku Matrix',
            EDIT_SKU_MATRIXS: 'Edit {{amount}} Sku Matrix(s)'
        },
        DELETE_TITLE: 'Do you want to delete this sku matrix?',
        DELETE_CONTENT: 'This action will delete : {{name}} and could not be undone.',
        WARNING_TOOLTIP: 'The sku matrix is already being used and cannot be deleted.',
        DELETE_TOOLTIP: 'Delete sku matrix',
        MESSAGES: {
            SUCCESS: 'Sku Matrix(s) saved successfully',
            DELETE: 'Sku Matrix(s) deleted successfully',
            SKU_MATRIX_ALREADY_EXIST: 'Sku Matrix name already exist',
            SKU_MATRIX_PARAM_VALUE_INCORRECT: 'Sku Matrix values or Sku Matrix parameters are incorrect',
            ORDER_CHANGING: 'Updating Sku Matrix order <i class="ml-1 fas fa-spinner fa-spin"></i>',
            ORDER_CHANGE_SUCCESS: 'Sku Matrix order updated successfully',
            ORDER_CHANGE_ERROR:
                'Failed to update sku matrix order, the data shown on the screen is outdated. ' +
                'Please refresh before performing the operation.',
            LOADING_ERROR: 'Error on fetching sku matrix data. Message: {{message}}'
        },
        COLUMNS: {
            SKU_MATRIX: 'Sku Matrix',
            SKU_MATRIX_ALIAS: 'Sku Matrix Alias',
            ACTIVE: 'Active',
            ORDER: 'Sku Matrix Order',
            SKU_MATRIX_VALUES: 'Sku Matrix Values',
            SKU_MATRIX_LABELS: 'Sku Matrix Labels'
        },
        REFRESH_SKU_MATRIXS: 'Refresh Sku Matrix'
    },
    DIALOG_CREATE: {
        TITLE: 'Create a new Sku Matrix',
        PCBA_NAME: 'PcbaKit Name',
        XCVR_NAME: 'XcvrKit Name',
        NAME_ERROR:
            'The internal product name must have only numbers, letters, parenthesis and hifen.',
        DATA_IMPORT: 'Data Import',
        SUCCESSFUL: 'Sku Matrix created successfully!',
        NAME_ALREADY_EXISTS: 'There is already a {{field}} with that name.',
        MESSAGE_CREATING:
            'Creating the Sku Matrix <i class="ml-1 fas fa-spinner fa-spin"></i>',
        INVALID_VALUE: 'The value is invalid for this field.',
        PARAMS_ALREADY_EXISTS: 'These Parameters( MarketRegion, CMF Color, Memory Size and Sim ) already exists for this Build Plan.',
    },
    DIALOG_TITLE: {
        CREATE: 'Creating a Sku Matrix',
        EDIT_VALUE_TITLE: 'Do you want to propagate these values to BuildPlan?',
        EDIT: 'Editing the Sku Matrix:',
        EDIT_MASSIVE_TITLE: 'Do you want to propagate these values to BuildPlan?',
        PROPAGATE_SKU_MATRIXS_MANAGEMENT_ALIAS: 'Propagate all values for the selected sku matrix',
        MESSAGE: {
            CAN_NOT_LABEL: '<b>You can not add a label using: ; , / \' ` " ´ . \\</b>'
        },
        BUTTONS: {
            DELETE: 'Delete Sku Matrix(s)',
            CLONE: 'Clone Sku Matrix(s)'
        }
    },
    TOOLTIP_EDIT: 'Edit Sku Matrix',
    HINT_SKU_MATRIX_ORDER: 'The sku matrix will be inserted after the one you choose.',
    BUILD_PLAN_ASSOCIATION: {
        DELETE_WARN: 'Are you sure you want to delete? This action can not be undone',
        CONFIRMATION_DELETE_DIALOG_TITLE: 'Delete {{amount}} Sku Matrix',
        CONFIRMATION_CLONE_DIALOG_TITLE: 'Clone Sku Matrix',
        SEARCH: 'Search sku matrix by name',
        ADD_SKU_MATRIX_TO_BUILD_PLAN: 'Add Sku Matrix to ({{ bpName }}) Build Plan',
        SUCCESSFUL_DELETED: 'Sku Matrix deleted successfully',
        ADD_SKU_MATRIX_ASSOCIATION: 'Add Sku Matrix',
        TITLE_APPLY_SKU_MATRIX_TO_BUILD_PLAN: 'Apply Sku Matrix to ({{ bpName }}) Build Plan',
        SKU_MATRIX_ALREADY_ASSOCIATED: 'Sku Matrix Already exists in Build Plan',
        SEARCH_SKU_MATRIX: 'Search by sku matrix',
        ERROR_DUPLICATE: 'Build Plan already related to the Sku Matrix',
        DESCRIPTION_REMOVE_SKU_MATRIX:
            'The following Teams on this Build Plan have at least one Sku Matrix Label configured that matches the same Labels in the Sku Matrix you are deleting. Do you want also to delete these Teams, and consequently their prototypes requests & accessory requests permanently?',
        DESCRIPTION_ADD_SKU_MATRIX:
            'There are some teams that are not in the build plan with the same sku matrix labels, do you want to insert them?',
        APPLY_SKU_MATRIX_TO_BUILD_PLAN: 'Apply {{amount}} Sku Matrix',
        APPLY_CONFIG_ALIAS_NAME: 'Conf. Alias Name',
        APPLY_SKU_MATRIX_MARK: 'Mark the Configuration(s) wished for applying the Sku Matrix Kit Names',
        APPLY_SKU_MATRIX_MESSAGE: 'After checking and confirm the settings above, the configuration(s) PCBA KIT and XCVR KIT will be updated',
        APPLY_SKU_MATRIX_KIT_MESSAGE: 'The sku Matrix setting bellow will be applied to all the configurations selected',
        APPLY_SKU_MATRIX_CONFIRM: 'Confirm',

        APPLY_SKU_MATRIX_ID_NAME: 'Sku Matrix Id #',
        APPLY_SKU_MATRIX_FROM_PCBA_KIT_NAME: 'Current Pcba Kit #',
        APPLY_SKU_MATRIX_FROM_XCVR_KIT_NAME: 'Current Xcvr Kit #',
        APPLY_SKU_MATRIX_TO_PCBA_KIT_NAME: 'After Apply Pcba Kit #',
        APPLY_SKU_MATRIX_TO_XCVR_KIT_NAME: 'After Apply Xcvr Kit #',
        APPLY_SKU_MATRIX_SUCCESS: 'Sku Matrix applied successfully',
        APPLY_SKU_MATRIX_MISSING_CONFIG: 'Add Sku Matrix not Allowed. Missing some build plan configurations: {{configurations}}',
        APPLY_SKU_MATRIX_APPLYING: 'Applying Sku Matrix to Build Plan Configurations.<i class="ml-1 fas fa-spinner fa-spin"></i>'
    },
    DIALOG: {
        EDIT_MASSIVE_TITLE: 'Editing the Sku Matrix(s)',
        BTN_SKU_MATRIX_MANAGEMENT_ONLY: 'Update sku matrix management',
        BTN_SKU_MATRIX_MANAGEMENT_ASSOCIATIONS: 'Update Sku Matrix',
        TOOLTIP_SKU_MATRIX_MANAGEMENT_ONLY: 'Updates the Sku Matrix Management without its associations',
        TOOLTIP_SKU_MATRIX_MANAGEMENT_ASSOCIATIONS:
            'Updates the Sku Matrix Management and all its associations',
        SKU_MATRIX_ORDER_HINT:
            'The sku matrix being {{ operation }} will be placed directly <strong>after</strong> the one selected.',
        CREATED: 'created',
        EDITED: 'edited',
        OUTDATED_ORDERS_EDIT:
            'Cannot update the order, the loaded data is outdated. Please refresh the page to change the order.',
        OUTDATED_ORDERS_CREATE:
            'Cannot set an order different from the last, the data displayed is outdated. ' +
            'Please set order as last or refresh the page.',
        REFRESH_SKU_MATRIXS: 'Refresh sku matrix if you wish to change the original order',
        RESET_ORDER_EDIT: 'Reset Order',
        RESET_ORDER_CREATE: 'Set to Last',
        CLONE_MASSIVE_TITLE: 'Cloning the Sku Matrix(s) from ({{bpName}}) to another(s) Build Plan(s)',
        CLONE_THIS_BUILD_PLAN: 'Clone the Sku Matrix to this Build Plan',
        CLONE_TO_ALL_BPS: 'Select all Build Plans',
        CHOOSE_BUILD_PLANS: 'Choose all build plan(s) to clone the Sku Matrix settings',
        CLONE_TO_MESSAGE_HELP: 'Choose all build plan(s) wanted to clone(Have the same Sku Matrix settings) from build plan {{bpName}}',
        CLONE_SUCCESS: 'Sku Matrix(s) cloned successfully'
    }
};
