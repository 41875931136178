import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { IConfigurationI18n } from '@galvin/core/build-plan/configuration-management/i18n/configuration-i18n.interface';
import { IPrototypeRequestConfiguration } from '@galvin/core/build-plan/prototype-request-management/interfaces/prototype-request-configuration.interface';
import { TranslateService } from '@ngx-translate/core';
import { checkNullPipe } from './../../../../../core/_base/layout/pipes/check-null.pipe';

@Component({
    selector: 'factory-details',
    templateUrl: './factory-details.component.html',
    styleUrls: ['./factory-details.component.scss'],
    animations: [
        trigger('detailExpand', [
            state(
                'collapsed',
                style({ visibility: 'hidden', height: '0px', opacity: 0, overflow: 'hidden' })
            ),
            state(
                'expanded',
                style({
                    height: 'auto',
                    visibility: 'visible'
                })
            ),
            transition('expanded <=> collapsed', animate('225ms ease'))
        ])
    ]
})
export class FactoryDetailsComponent implements OnInit {
    @Input() configuration!: IPrototypeRequestConfiguration;
    expanded = true;
    labels!: IConfigurationI18n;
    items: {
        label: string;
        value: any;
        id: string;
    }[] = [];

    constructor(private i18n: TranslateService, private checkNull: checkNullPipe) {}

    ngOnInit(): void {
        this.labels = this.i18n.instant('BUILD_PLAN.CONFIGURATION');
        this.items.push({
            label: this.labels.SWIMLANES.FACTORY.FVC_FINISHED_QUANTITY,
            value: this.checkNull.transform(this.configuration.fvcFinishedQuantity),
            id: 'fvc-finished-quantity'
        });
        this.items.push({
            label: this.labels.SWIMLANES.FACTORY.FVC,
            value: this.checkNull.transform(this.configuration.fvc),
            id: 'fvc'
        });
        this.items.push({
            label: this.labels.SWIMLANES.FACTORY.BR_NUMBER,
            value: this.checkNull.transform(this.configuration.brNumber),
            id: 'br-number'
        });
        this.items.push({
            label: this.labels.SWIMLANES.FACTORY.LDA_BR,
            value: this.checkNull.transform(this.configuration.ldaBr),
            id: 'lda-br'
        });
        this.items.push({
            label: this.labels.SWIMLANES.FACTORY.PCB_VERSION,
            value: this.checkNull.transform(this.configuration.pcbVersion),
            id: 'pcb-version'
        });
        this.items.push({
            label: this.labels.SWIMLANES.FACTORY.SUB_PCB,
            value: this.checkNull.transform(this.configuration.subPcb),
            id: 'sub-pcb'
        });
    }

    getId(id: string, type: 'param' | 'value'): string {
        return `${id}-${type}`;
    }
}
