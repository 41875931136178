import { IAccessoriesParametersI18n } from './accessories-parameters-i18n.interface';

export const ACCESSORY_PARAMETERS: IAccessoriesParametersI18n = {
    MESSAGE_UPDATED: 'Accessory Value updated',
    EDIT_VALUE_TITLE: 'Do you want to edit this Accessory Value?',
    BP_ACCESSORY_VALUE_ASSOCIATIONS:
        'The following Build Plans associated values can be updated to {{accessoryValue}}. ',
    BP_CONFIGURATION_ALIAS_ASSOCIATIONS:
        'It is suggested that you change the accessory\'s aliases to reflect this value\'s edition.',
    TOOLTIP_BP_STATUS: 'Build Plan Status',
    TOOLTIP_BP_INTERNAL_NAME: 'Build Plan Internal Product Name',
    BUTTON_NOT_UPDATE_ASSOCIATIONS: 'Update Accessory Value',
    BUTTON_UPDATE_ASSOCIATIONS: 'Update Associations',
    TOOLTIP_BTN_UPDATE_VALUE_ONLY:
        'Updates the value from this parameter, without updating the Build Plan accessories that may use this value',
    TOOLTIP_BTN_UPDATE_ASSOCIATIONS:
        'Updates the value from this parameter, and it updates the Build Plan accessories you\'ve checked above',
    TOOLTIP_BTN_UPDATE_ASSOCIATIONS_AND_ALIASES:
        'Updates the value from this parameter, and updates the Build Plan accessories (and their aliases) you\'ve checked above',
    CREATE_BUTTON: 'Add Accessory Parameter',
    ACCESSORY_PARAMETER: 'Accessory Parameter',
    EDIT_TOOLTIP: 'Edit Accessory Parameter',
    DELETE_TOOLTIP: 'Delete Accessory Parameter',
    WARNING_TOOLTIP: 'The parameter is already being used and cannot be deleted or you don\'t have permission to do this action.',
    DELETE_TITLE: 'Do you want to delete this Accessory Parameter?',
    DELETE_CONTENT: 'This action will delete: {{name}} and could not be undone.',
    TOOLTIP_UPDATE_ALIAS: 'The accessory alias will be updated from {{name}} to {{suggestName}}',
    ADD_OR_EDIT_DIALOG: {
        CREATE_TITLE: 'Create a new Accessory Parameter',
        EDIT_TITLE: 'Editing the Accessory Parameter: ',
        ACCESSORY_VALUE: 'Enter with a Value',
        ADD_ACCESSORY_VALUE: 'Write a Accessory Value',
        ORDER_VALUE: 'Accessory Parameter Order',
        FIELD: '1.Field',
        FIELD_VALUES: '2.Values',
        ERROR_FIELD_NAME: 'Please provide a valid name',
        ERROR_DATA_TYPE: 'Please select a data type',
        ERROR_FIELD_TYPE: 'Please select a field type',
        ERROR_ACCESSORY_VALUE_REQUIRED: 'Please provide a valid value',
        ERROR_ACCESSORY_VALUE: 'This Accessory value already exist',
        SELECT_DATA_TYPE: 'Select a data type',
        SELECT_FIELD_TYPE: 'Select a field type',
        MESSAGE_SAVED: 'Accessory Parameter saved',
        MESSAGE_UPDATED: 'Accessory Parameter updated',
        ACCESSORY_PARAMETER_DUPLICATE: 'Accessory Parameter Already Exists',
        DELETE_VALUE_TITLE: 'Do you want to delete this Accessory value?',
        DELETE_VALUE_CONTENT: 'This action will delete: {{name}} and could not be undone.',
        CREATE_VALUE_TITLE: 'Do you want to create this Accessory value?',
        CREATE_VALUE_CONTENT: 'This action will create: {{name}}',
        EDIT_VALUE_TITLE: 'Do you want to edit this Accessory value?',
        EDIT_VALUE_CONTENT: 'This action will edit to: {{name}}',
        DELETE_VALUE_TOOLTIP: 'Delete Accessory value',
        WARNING_VALUE_TOOLTIP: 'The value is already being used and cannot be deleted.'
    }
};
