import { ItemCP } from '@galvin/core/_base/layout/models/item-to-copy';

export class CopyValuesTable {

    constructor() {}

    public itemCpListtoText(selectionToCopyList:ItemCP[]) {
        let indexes = selectionToCopyList.map(e => e.index);
        var minIndex = Math.min.apply(Math, indexes);

        var maxIndex = Math.max.apply(Math, indexes);

        let colIndexes = selectionToCopyList.map(e => e.col);

        var minIndexCol = Math.min.apply(Math, colIndexes);
        var maxIndexCol = Math.max.apply(Math, colIndexes);

        let textCp:string = '';
        for (let i = minIndex; i <= maxIndex; i++) {
            for (let c = minIndexCol; c <= maxIndexCol; c++) {
                let item = selectionToCopyList.find((e) => e.col == c && e.index == i);
                if (item) {
                    if (item.color) {
                        textCp = `${textCp}${item.value}¢${item.color}`;
                    }
                    else {
                        textCp = `${textCp}${item.value}`;
                    }
                } else {
                    textCp = `${textCp} `;
                }
                textCp = textCp + '\t';
            }
            textCp = textCp + '\n';
        }
        return textCp.replace(/\t\n/g,'\n');
    }
}