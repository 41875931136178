import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'title-expanded',
    templateUrl: './title-expanded.component.html',
    styleUrls: ['./title-expanded.component.scss'],
    animations: [
        trigger('detailExpand', [
            state(
                'collapsed',
                style({ visibility: 'hidden', height: '0px', opacity: 0, overflow: 'hidden' })
            ),
            state(
                'expanded',
                style({
                    height: 'auto',
                    visibility: 'visible'
                })
            ),
            transition('expanded <=> collapsed', animate('225ms ease'))
        ])
    ]
})
export class TitleExpandedComponent {
    @Input() title!: string;
    @Input() expanded!: boolean;
    @Output() expandedOut = new EventEmitter<boolean>();
}
