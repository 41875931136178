import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'galvin-delete-item-table',
    templateUrl: './galvin-delete-item-table.component.html',
    styleUrls: ['./galvin-delete-item-table.component.scss']
})
export class GalvinDeleteItemTableComponent {
    @Input() item: any;
    @Input() canDeleteParameter: any;
    @Input() getTooltipMessageForDelectableParameter: any;
    @Input() getTooltipMessageForWarningParameter: any;
    @Input() isLoadingDelectableParameters: any;
    @Output() deleteItem = new EventEmitter();

    fvc: any;

    emit() {
        this.deleteItem.emit(this.item);
    }
}
