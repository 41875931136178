export enum EBuildPlanStatusLabel {
    NEW = 'New',
    PENDING_APPROVAL = 'Pending Approval',
    APPROVED = 'Approved',
    OPEN = 'Open',
    PARTIALLY_OPEN = 'Partially Open',
    LOCKED = 'Locked',
    DPM_PENDING_APPROVAL = 'DPM Pending Approval',
    DPM_APPROVED = 'DPM Approved',
    BUILDING = 'Building',
    COMPLETED = 'Completed',
    ARCHIVED = 'Archived',
    CANCELED = 'Canceled'
}

export enum EBuildPlanStatus {
    NEW = 'NEW',
    PENDING_APPROVAL = 'PENDING_APPROVAL',
    APPROVED = 'APPROVED',
    OPEN = 'OPEN',
    PARTIALLY_OPEN = 'PARTIALLY_OPEN',
    LOCKED = 'LOCKED',
    DPM_PENDING_APPROVAL = 'DPM_PENDING_APPROVAL',
    DPM_APPROVED = 'DPM_APPROVED',
    BUILDING = 'BUILDING',
    COMPLETED = 'COMPLETED',
    ARCHIVED = 'ARCHIVED',
    CANCELED = 'CANCELED'
}

export type EBuildPlanStatusLabelKey = keyof typeof EBuildPlanStatusLabel;
export type EBuildPlanStatusKey = keyof typeof EBuildPlanStatus;
