import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IAccessoryRequestTeam } from '@galvin/core/build-plan/accessory-request-management/interface/accessory-request-team.interface';
import { ResourceService } from '@galvin/core/_base/resource';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class BuildPlanAccessoryRequestPreviewService extends ResourceService {
    private readonly URL_ACCESSORY_REQUEST = '/build-plan/build-plan-management';

    constructor(private httpClient: HttpClient) {
        super(httpClient);
    }

    /**
     * Get a accessory team requests given accessory and bp team
     *
     * @param accessoryId id of bp to get the report data from
     * @param teamAssociationId id of team association
     */
    getAllAccessories(
        buildPlanId: number,
        accessoryId: number
    ): Observable<IAccessoryRequestTeam[]> {
        return this.get(
            `${this.URL_ACCESSORY_REQUEST}/${buildPlanId}/accessory-team-request-preview/accessories/${accessoryId}`
        ).pipe(
            map((response) => {
                return response.content;
            })
        );
    }
}
