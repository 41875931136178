import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { EOrder } from './order.enum';
import { ITableSortColumn } from './table-sort-columns.interface';
import { ETypeNulls } from './type-nulls.enum';

@Component({
    selector: 'table-sort',
    templateUrl: './table-sort.component.html',
    styleUrls: ['./table-sort.component.scss']
})
export class TableSortComponent {
    @Input() columns: ITableSortColumn[] = [];
    @Output() dispatchSelectedColumns = new EventEmitter();
    @Input() title = '';

    @ViewChild('tableSortDialog') tableSortDialog!: TemplateRef<any>;

    dialogRef!: MatDialogRef<any, any>;
    selectedColumns: ITableSortColumn[] = [];

    typeOrders = [
        { displayValue: '', id: EOrder.ASC },
        { displayValue: '', id: EOrder.DESC }
    ];
    typeNulls = [
        { displayValue: '', id: ETypeNulls.FIRST },
        { displayValue: '', id: ETypeNulls.LAST },
        { displayValue: '', id: ETypeNulls.IGNORE }
    ];

    formGroup!: FormGroup;

    private readonly columnFormControl = 'column';
    private readonly typeOrderFormControl = 'typeOrder';
    private readonly typeNullFormControl = 'typeNull';

    constructor(
        private dialog: MatDialog,
        private i18n: TranslateService,
        private fb: FormBuilder
    ) {
        this.typeOrders[0].displayValue = this.i18n.instant('COMMON.ASC');
        this.typeOrders[1].displayValue = this.i18n.instant('COMMON.DESC');
        this.typeNulls[0].displayValue = this.i18n.instant('COMMON.FIRST_NULL');
        this.typeNulls[1].displayValue = this.i18n.instant('COMMON.LAST_NULL');
        this.typeNulls[2].displayValue = this.i18n.instant('COMMON.IGNORE_NULLS');

        this.initialForm();
    }

    get column(): AbstractControl | null {
        return this.formGroup.get(this.columnFormControl);
    }
    get typeOrder(): AbstractControl | null {
        return this.formGroup.get(this.typeOrderFormControl);
    }
    get typeNull(): AbstractControl | null {
        return this.formGroup.get(this.typeNullFormControl);
    }

    openDialog(): void {
        this.dialogRef = this.dialog.open(this.tableSortDialog, {
            panelClass: 'custom-panel',
            width: '969px',
            hasBackdrop: true,
            disableClose: true
        });
    }

    closeDialog(sort = false): void {
        if (sort) {
            this.dispatchSelectedColumns.emit(this.formGroup.getRawValue());
        }
        this.dialogRef.close();
    }

    private initialForm() {
        const column = new FormControl(null, [Validators.required]);
        const typeOrder = new FormControl(null, [Validators.required]);
        const typeNull = new FormControl(null, [Validators.required]);

        this.formGroup = this.fb.group({ column, typeOrder, typeNull });
    }
}
