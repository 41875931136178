import { IUserManagementI18n } from './user-management-i18n.interface';

export const USER_MANAGEMENT: IUserManagementI18n = {
    COMMON: {
        BUTTONS: {
            NEW_BUTTON: 'New User'
        },
        NAME_MODULE: 'User Management',
        TITLE_GROUPS_POPOVER: 'Groups'
    },
    DIALOG: {
        CREATE_TITLE: 'Creating a User',
        USER_CREATED: 'User created successfully.',
        EMAIL_FIELD: 'Email',
        COREID_FIELD: 'CoreId',
        NAME_FIELD: 'Name',
        LAST_NAME_FIELD: 'Last Name',
        COUNTRY_FIELD: 'Country',
        VALIDATIONS: {
            INVALID: 'Invalid e-mail.',
            EMAIL_ALREADY_EXISTS: 'A user with this email already exists.',
            INVALID_CORE_ID: 'CoreId Invalid. Only alphanumerics are allowed (e.g : user1).',
            INVALID_NAME: 'Name Invalid. Only letters are allowed.',
            EXTERNAL_DOMAIN:
                'This is a non-Motorola or Lenovo email.' +
                'This user will NOT be able to access the application, only receive notifications.',
            INVALID_DOMAIN_EDIT: 'Motorola/Lenovo users cannot be converted into external users.'
        },
        TOOLTIPS: {
            CODE_ID: 'Core ID is only for Motorola and Lenovo users.'
        }
    }
};
