import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ToolbarService {

    public isFullScreen: boolean = false;

    private _fullScreen: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    fullScreenObservable = this._fullScreen.asObservable();

    setFullScreen(value:boolean){
        this.isFullScreen=value;
        this._fullScreen.next(value);
    }

    getFullScreen() {
        return this._fullScreen.asObservable();
    }
}