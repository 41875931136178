<div *ngIf="!loadRequest" class="d-flex flex-column h-100">
  <div
    class="container-dialog flex-grow-1"
    [ngClass]="{ 'clean-grid-template': requestPreview }"
  >
    <header-request-details
      class="header"
      [requestPreview]="requestPreview"
      [protoRequest]="protoRequest"
      (closeDialog)="close()"
    >
    </header-request-details>
    <div class="side-bar" *ngIf="!requestPreview">
      <form
        class="d-flex flex-column"
        [formGroup]="requestForm"
        (ngSubmit)="checkValidForm() && submit()"
      >
        <!-- right side request -->
        <section
          class="header-side-bar d-flex align-items-start justify-content-between p-3"
        >
          <div class="content-header d-flex flex-column">
            <span class="text-default title-header">
              {{
                "BUILD_PLAN.PROTOTYPE_REQUEST.DIALOG.LABELS.REQUEST" | translate
              }}
            </span>
            <span
              class="d-inline-block text-default text-truncate sub-title-header"
              placement="left"
              container="galvin-dialog-generic"
              [ngbTooltip]="teamRequest.teamAlias"
            >
              {{ teamRequest.teamAlias }}
            </span>
            <span
              class="d-inline-block text-default text-truncate"
              placement="left"
              container="galvin-dialog-generic"
              [ngbTooltip]="teamRequest.teamValues"
            >
              {{ teamRequest.teamValues }}
            </span>
          </div>

          <button
            container="galvin-dialog-generic"
            ngbTooltip="{{ 'COMMON.CLOSE_DIALOG' | translate }}"
            type="button"
            (click)="close()"
            class="close-side-bar btn btn-clean btn-sm btn-icon btn-icon-md dialog-close-button"
          >
            <i class="la la-times la-bold"></i>
          </button>
        </section>
        <div class="content-side-bar">
          <section class="m-0">
            <div
              class="d-flex flex-column align-items-start justify-content-between m-3"
            >
              <label class="text-default title-section w-100">
                {{
                  "BUILD_PLAN.PROTOTYPE_REQUEST.DIALOG.LABELS.TEAM_INFO"
                    | translate
                }}
              </label>
              <div class="d-flex flex-column" [style.width.%]="95">
                <span
                  class="d-inline-block text-default text-truncate sub-title-header"
                >
                  {{
                    "BUILD_PLAN.PROTOTYPE_REQUEST.DIALOG.LABELS.REQUEST_OWNER"
                      | translate
                  }}
                </span>
                <span
                  class="d-inline-block text-default text-truncate"
                  container="galvin-dialog-generic"
                  [ngbTooltip]="teamRequest.requestOwner | galvinUser"
                >
                  {{ teamRequest.requestOwner | galvinUser }}
                </span>

                <span
                  class="d-inline-block text-default text-truncate sub-title-header"
                >
                  {{
                    "BUILD_PLAN.PROTOTYPE_REQUEST.DIALOG.LABELS.FINAL_RECIPIENT"
                      | translate
                  }}
                </span>
                <span
                  class="d-inline-block text-default text-truncate"
                  container="galvin-dialog-generic"
                  [ngbTooltip]="teamRequest.finalRecipient | galvinUser"
                >
                  {{ teamRequest.finalRecipient | galvinUser }}
                </span>

                <span
                  class="d-inline-block text-default text-truncate sub-title-header"
                >
                  {{
                    "BUILD_PLAN.PROTOTYPE_REQUEST.DIALOG.LABELS.CDC_LOCATION"
                      | translate
                  }}
                </span>
                <div
                  class="d-inline-block text-default text-truncate"
                  container="galvin-dialog-generic"
                  [ngbTooltip]="teamRequest.location?.shortDescription"
                >
                  <span
                    [ngClass]="getFlag($any(teamRequest.location?.country))"
                  ></span>
                  {{ teamRequest.location?.city }}
                </div>

                <span
                  class="d-inline-block text-default text-truncate sub-title-header"
                >
                  {{
                    "BUILD_PLAN.PROTOTYPE_REQUEST.DIALOG.LABELS.SHIP_TO_LOCATION"
                      | translate
                  }}
                </span>
                <div
                  class="d-inline-block text-default text-truncate"
                  container="galvin-dialog-generic"
                  [ngbTooltip]="teamRequest.shipTo?.shortDescription"
                >
                  <span
                    [ngClass]="getFlag($any(teamRequest.shipTo?.country))"
                  ></span>
                  {{ teamRequest.shipTo?.city }}
                </div>

                <span
                  class="d-inline-block text-default text-truncate sub-title-header"
                >
                  {{
                    "BUILD_PLAN.PROTOTYPE_REQUEST.DIALOG.LABELS.GROUP"
                      | translate
                  }}
                </span>
                <span
                  class="d-inline-block text-default text-truncate"
                  container="galvin-dialog-generic"
                  [ngbTooltip]="teamRequest.group"
                >
                  {{ teamRequest.group }}
                </span>
              </div>
            </div>

            <div class="form-group m-3" id="quantityContainer">
              <label class="text-default title-section">
                {{
                  "BUILD_PLAN.PROTOTYPE_REQUEST.DIALOG.LABELS.QUANTITY"
                    | translate
                }}
              </label>
              <div class="d-flex flex-column align-items-end position-relative">
                <i
                  class="material-icons position-absolute p-4"
                  [style.color]="'#87A5C4'"
                  >edit</i
                >
                <input
                  type="number"
                  required
                  class="form-control text-default text-input color-main-blue"
                  [ngStyle]="{ 'border-color': isValid('quantity') }"
                  formControlName="quantity"
                  min="0"
                  [style.height.px]="52"
                />
              </div>
              <div
                *ngIf="requestForm.controls['quantity'].invalid"
                class="invalid-fields"
              >
                {{
                  "BUILD_PLAN.PROTOTYPE_REQUEST.DIALOG.LABELS.REQUIRED_FIELD"
                    | translate
                }}
              </div>
              <label class="checkbox-container mb-0">
                <input
                  type="checkbox"
                  formControlName="allDevicesToBeActivated"
                  (click)="changeActiveDevices()"
                />
                <span class="checkmark"></span>
                <label
                  class="text-default checkbox-label m-0 mr-1"
                  id="labelCheckBoxQuantity"
                >
                  {{
                    "BUILD_PLAN.PROTOTYPE_REQUEST.DIALOG.LABELS.ALL_DEVICES_TO_BE_ACTIVATED"
                      | translate
                  }}
                </label>
                <i
                  class="galvin-icon-information"
                  container="galvin-dialog-generic"
                  ngbTooltip="{{
                    'BUILD_PLAN.PROTOTYPE_REQUEST.DIALOG.LABELS.ACTIVITY_TOOLTIP'
                      | translate
                  }}"
                >
                </i>
              </label>
            </div>
          </section>

          <section
            class="form-group side-bar-content pl-3 pr-3 mb-4"
            id="devicesContainer"
          >
            <label class="text-default title-section">
              {{
                "BUILD_PLAN.PROTOTYPE_REQUEST.DIALOG.LABELS.DEVICES_TO_ACTIVATE"
                  | translate
              }}
            </label>
            <div class="d-flex flex-column align-items-end position-relative">
              <span
                class="pt-1 pr-3 position-absolute text-right text-input"
                [style.color]="'#638AB166'"
              >
                {{ devicesNumber }}
              </span>
              <input
                type="text"
                currencyMask
                [min]="0"
                [max]="100"
                [options]="{
                  suffix: '%',
                  prefix: '',
                  precision: 2,
                  thousands: ',',
                  decimal: '.'
                }"
                formControlName="activationPercentage"
                class="form-control text-default text-input color-main-blue"
                id="devicesToActiveInput"
                [readOnly]="requestForm.value.allDevicesToBeActivated"
                [ngStyle]="{
                  'border-color': isValid('activationPercentage'),
                  'background-color': checkIfExist('allDevicesToBeActivated')
                }"
                [style.height.px]="52"
              />
              <div
                *ngIf="requestForm.controls['activationPercentage'].invalid"
                class="invalid-fields"
              >
                {{
                  "BUILD_PLAN.PROTOTYPE_REQUEST.DIALOG.LABELS.REQUIRED_FIELD"
                    | translate
                }}
              </div>
            </div>
          </section>

          <section class="form-group pl-3 pr-3 m-0" id="justification">
            <form [formGroup]="justificationForm">
              <label class="text-default title-section">
                {{
                  "BUILD_PLAN.PROTOTYPE_REQUEST.DIALOG.LABELS.JUSTIFICATION.JUSTIFICATION"
                    | translate
                }}
              </label>
              <div class="d-flex flex-wrap">
                <label class="checkbox-container flex-grow-side-bar mb-0">
                  <input type="checkbox" formControlName="AUTOMATED_TESTING" />
                  <span class="checkmark"></span>
                  <label class="text-default checkbox-label m-0 mr-1">
                    {{
                      "BUILD_PLAN.PROTOTYPE_REQUEST.DIALOG.LABELS.JUSTIFICATION.AUTOMATED_TESTING"
                        | translate
                    }}
                  </label>
                </label>
                <label class="checkbox-container flex-grow-side-bar mb-0">
                  <input type="checkbox" formControlName="MANUAL_TESTING" />
                  <span class="checkmark"></span>
                  <label class="text-default checkbox-label m-0 mr-1">
                    {{
                      "BUILD_PLAN.PROTOTYPE_REQUEST.DIALOG.LABELS.JUSTIFICATION.MANUAL_TESTING"
                        | translate
                    }}
                  </label>
                </label>
                <label class="checkbox-container flex-grow-side-bar mb-0">
                  <input type="checkbox" formControlName="CARRIERS" />
                  <span class="checkmark"></span>
                  <label class="text-default checkbox-label m-0 mr-1">
                    {{
                      "BUILD_PLAN.PROTOTYPE_REQUEST.DIALOG.LABELS.JUSTIFICATION.CARRIERS"
                        | translate
                    }}
                  </label>
                </label>
                <label class="checkbox-container flex-grow-side-bar mb-0">
                  <input type="checkbox" formControlName="SHOW_OFF" />
                  <span class="checkmark"></span>
                  <label class="text-default checkbox-label m-0 mr-1">
                    {{
                      "BUILD_PLAN.PROTOTYPE_REQUEST.DIALOG.LABELS.JUSTIFICATION.SHOW_OFF"
                        | translate
                    }}
                  </label>
                </label>
                <label class="checkbox-container flex-grow-side-bar mb-0">
                  <input type="checkbox" formControlName="MARKETING" />
                  <span class="checkmark"></span>
                  <label class="text-default checkbox-label m-0 mr-1">
                    {{
                      "BUILD_PLAN.PROTOTYPE_REQUEST.DIALOG.LABELS.JUSTIFICATION.MARKETING"
                        | translate
                    }}
                  </label>
                </label>
                <label class="checkbox-container flex-grow-side-bar mb-0">
                  <input type="checkbox" formControlName="OTHER" />
                  <span class="checkmark"></span>
                  <label class="text-default checkbox-label m-0 mr-1">
                    {{
                      "BUILD_PLAN.PROTOTYPE_REQUEST.DIALOG.LABELS.JUSTIFICATION.OTHER"
                        | translate
                    }}
                  </label>
                </label>
              </div>
            </form>
          </section>

          <section
            class="form-group side-bar-content p-3 m-0"
            id="additionalCommentsContainer"
          >
            <label class="text-default title-section">{{
              "BUILD_PLAN.PROTOTYPE_REQUEST.DIALOG.LABELS.JUSTIFICATION.ADDITIONAL_COMMENTS"
                | translate
            }}</label>

            <mention-comment
              [defaultText]="comment"
              [editable]="!readOnly"
              [usersToNotify]="usersToNotify"
              [nameCreateBy]="loggedUser"
              (applyActions)="applyComments($event)"
              [container]="'additionalCommentsContainer'"
            >
              <textarea
                class="form-control fake-disable"
                rows="3"
                placeholder="Write your comments here"
                [value]="comment"
                readonly
                maxlength="1000"
              ></textarea>
            </mention-comment>

            <!-- Special Requirement checkbox -->
            <label class="checkbox-container mb-0">
              <input type="checkbox" formControlName="isSpecialRequirement" />
              <span class="checkmark"></span>
              <label class="text-default checkbox-label m-0 mr-1">
                {{
                  "BUILD_PLAN.PROTOTYPE_REQUEST.DIALOG.LABELS.SPECIAL_REQUIREMENT"
                    | translate
                }}
              </label>
            </label>
            <div
              *ngIf="requestForm.controls['isSpecialRequirement'].value"
              class="special-requirement-list"
            >
              <div
                *ngFor="
                  let spr of requestForm.controls['specialRequirementsList']
                    .value
                "
              >
                <strong>{{ spr.specialRequirement.fieldName }}:</strong>
                <span class="text-justify">
                  {{ spr.value }}
                </span>
              </div>
            </div>

            <!-- Is New Hardware checkbox -->
            <label class="checkbox-container mb-0">
              <input type="checkbox" formControlName="isNewHWSpec" />
              <span class="checkmark"></span>
              <label
                class="text-default checkbox-label m-0 mr-1"
                id="labelAdditionalCommentsContainer"
              >
                {{
                  "BUILD_PLAN.PROTOTYPE_REQUEST.DIALOG.LABELS.IS_NEW_HW_SPEC"
                    | translate
                }}
              </label>
              <i
                class="galvin-icon-information"
                container="galvin-dialog-generic"
                ngbTooltip="{{
                  'BUILD_PLAN.PROTOTYPE_REQUEST.DIALOG.LABELS.ALL_DEVICES_TOOLTIP'
                    | translate
                }}"
                container="request-details-dialog"
                placement="bottom"
              ></i>
            </label>
          </section>
        </div>
        <!-- REQUEST BUTTON -->
        <div
          class="footer-side-bar d-flex align-items-end w-100 p-3 flex-grow-1"
          *ngIf="!readOnly"
        >
          <div
            class="side-bar-button-container"
            container="galvin-dialog-generic"
            [ngbTooltip]="getTooltipRequestButton()"
          >
            <button
              mat-flat-button
              [disabled]="disableButton || submitted"
              class="w-100 side-bar-button d-flex align-items-center justify-content-center"
              [class.galvin-btn-primary]="isRequestApproval()"
            >
              <span class="d-flex align-items-center justify-content-center">
                {{ requestButtonLabel }}
              </span>
            </button>
          </div>
        </div>
        <!-- REQUEST BUTTON -->
      </form>
    </div>

    <!-- Left side request info -->
    <mat-drawer-container
      *ngIf="!requestPreview"
      class="specialRequimentDrawer"
      [autosize]="false"
      [hasBackdrop]="false"
    >
      <mat-drawer
        [autoFocus]="false"
        [disableClose]="true"
        #specialRequimentDrawer
        [style.witdh.px]="988"
        position="end"
        mode="push"
      >
        <div class="mt-4 ml-5">
          <galvin-progress-spinner
            class="galvin-load"
            *ngIf="submitted"
            [diameter]="100"
          >
          </galvin-progress-spinner>
          <special-requirement-form
            [verifyFields]="validFields.asObservable()"
            [specialRequirementsList]="
              $any(protoRequest.teamRequest?.specialRequirementsList)
            "
            (specialRequirementsChanges)="specialRequirementsChanges($event)"
          >
          </special-requirement-form>

          <div
            class="special-requirement close"
            [hidden]="!specialRequimentDrawer.opened"
            (click)="specialRequimentDrawer.toggle()"
          ></div>
        </div>
      </mat-drawer>

      <ng-container [ngTemplateOutlet]="details"> </ng-container>

      <div *ngIf="isSpecialRequirementChecked()">
        <div
          class="special-requirement open"
          [hidden]="specialRequimentDrawer.opened"
          (click)="specialRequimentDrawer.toggle()"
        ></div>
      </div>
    </mat-drawer-container>

    <!-- Details for no request dialog -->
    <ng-container *ngIf="requestPreview" [ngTemplateOutlet]="details">
    </ng-container>
  </div>
</div>
<skeleton-load-dialog
  [requestPreview]="requestPreview"
  *ngIf="loadRequest"
></skeleton-load-dialog>

<ng-template #details>
  <div
    class="content mt-4 ml-5 left-request-side"
    [ngClass]="{ 'clean-size-grid-template-areas': requestPreview }"
  >
    <galvin-progress-spinner
      class="galvin-load"
      *ngIf="submitted"
      [diameter]="100"
    >
    </galvin-progress-spinner>

    <div class="overflow-auto details-container">
      <div class="d-flex flex-column">
        <div class="row w-100 justify-content-start">
          <fvc-parameters-details
            [ngClass]="requestPreview ? 'three-columns' : 'two-columns'"
            class="col-12"
            [fvcs]="configuration.sections?.fvcList || []"
            *ngIf="configuration.sections?.fvcList"
          >
          </fvc-parameters-details>
        </div>
        <div class="row w-100 justify-content-start">
          <dates-default-details
            [class]="requestPreview ? 'col-auto' : 'col-6'"
            *ngIf="configuration.sections?.datesDefaultList"
            [dates]="configuration.sections?.datesDefaultList || []"
          >
          </dates-default-details>
          <relative-dates-details
            [class]="requestPreview ? 'col-auto' : 'col-6'"
            *ngIf="configuration.sections?.datesRelativeList"
            [dates]="configuration.sections?.datesRelativeList || []"
          >
          </relative-dates-details>
          <factory-details
            [class]="requestPreview ? 'col-auto' : 'col-6'"
            *ngIf="configuration"
            [configuration]="configuration"
          >
          </factory-details>
          <config-status-details-request
            [class]="requestPreview ? 'col-auto' : 'col-6'"
            *ngIf="configuration"
            [configuration]="configuration"
          >
          </config-status-details-request>
        </div>
      </div>
    </div>
    <div
      *ngIf="!requestPreview"
      class="d-flex flex-column overflow-auto position-relative mr-4 ml-4 activity-container"
      id="activityContainer"
    >
      <span id="activitySpan" class="text-default title-container">
        {{ "BUILD_PLAN.PROTOTYPE_REQUEST.DIALOG.LABELS.ACTIVITY" | translate }}
        <i
          class="galvin-icon-information"
          container="body"
          ngbTooltip="{{
            'BUILD_PLAN.PROTOTYPE_REQUEST.DIALOG.LABELS.ACTIVITY_INFO_TOOLTIP'
              | translate
          }}"
        >
        </i>
      </span>
      <mat-form-field>
        <mat-select
          #operationFilter
          disableOptionCentering
          panelClass="select-dropdown"
          (selectionChange)="onOperationSelected($event)"
          (closed)="onFilterChange()"
          [ngModel]="$any(formFilter.get(OPERATION)).value"
          multiple
          [ngbTooltip]="filterLabels.HEADER.FILTERS.OPERATION"
          [placeholder]="filterLabels.HEADER.FILTERS.OPERATION"
        >
          <mat-option
            #allOperationOption
            (onSelectionChange)="onSelectOptionAll($event, operationFilter)"
            class="sticky-top"
            [value]="ALL"
          >
            {{ ALL }}
          </mat-option>
          <mat-option
            *ngFor="let operation of operationGroupFilterOptions"
            [disabled]="allOperationOption.selected"
            [value]="operation"
          >
            {{ getOperationLabel(operation) }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <request-activity
        *ngIf="hasActivityInformation()"
        [activity]="requestActivity"
      ></request-activity>
      <div *ngIf="!hasActivityInformation()" class="activity-message">
        <span *ngIf="loadingRequestActivity" id="loadingRequestActivity">
          <galvin-progress-spinner class="loading"></galvin-progress-spinner>
          {{
            "BUILD_PLAN.PROTOTYPE_REQUEST.DIALOG.LABELS.LOADING_ACTIVITY"
              | translate
          }}
        </span>
        <span *ngIf="hasNoActivity()" id="noRequestActivityData">
          {{
            "BUILD_PLAN.PROTOTYPE_REQUEST.DIALOG.LABELS.NO_ACTIVITY_DATA"
              | translate
          }}
        </span>
        <span *ngIf="errorLoadingActivity" id="requestActivityError">
          {{
            "BUILD_PLAN.PROTOTYPE_REQUEST.DIALOG.LABELS.ERROR_ACTIVITY"
              | translate
          }}
        </span>
      </div>
    </div>
  </div>
</ng-template>
