export enum EConfigurationStatusColor {
    NEW = 'configuration-new',
    PENDING_APPROVAL = 'build-plan-under-approval',
    APPROVED = 'build-plan-open',
    OPEN = 'build-plan-open',
    PARTIALLY_OPEN = 'build-plan-open',
    LOCKED = 'build-plan-locked',
    DPM_PENDING_APPROVAL = 'build-plan-under-approval',
    DPM_APPROVED = 'build-plan-open',
    BUILDING = 'build-plan-building',
    COMPLETED = 'build-plan-completed',
    ARCHIVED = 'build-plan-locked',
    CANCELED = 'build-plan-canceled'
}

export enum EAccessoryStatusColor {
    NEW = 'configuration-new',
    OPEN = 'build-plan-open',
    LOCKED = 'build-plan-locked',
    READY_TO_DELIVER = 'build-plan-building',
    IN_TRANSIT = 'build-plan-in-transit',
    COMPLETED = 'build-plan-completed',
    CANCELED = 'build-plan-canceled'
}

export type EAccessoryStatusColorKeys = keyof typeof EAccessoryStatusColor;
export type EConfigurationStatusColorKeys = keyof typeof EConfigurationStatusColor;
