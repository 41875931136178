// Angular
import {Pipe, PipeTransform} from '@angular/core';

/**
 * Sort an string[]
 */
@Pipe({
    name: 'sort'
})
export class SortPipe implements PipeTransform {
    transform(list: string[]) {
        return list.sort((a, b) => a.localeCompare(b, 'en', {numeric: true}));
    }
}