import { Component, Input, ViewChild } from '@angular/core';
import { LabelTotal } from '@galvin/core/_base/layout/models/label-total.model';
import { PopoverService } from '@galvin/core/_base/layout/services/popover.service';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'popover-label-total',
    templateUrl: 'popover-label-total.component.html',
    styleUrls: ['./popover-label-total.component.scss']
})
export class PopoverLabelTotalComponent {
    public currentPopover!: NgbPopover;

    @Input() data!: LabelTotal[];

    @ViewChild('popover') private popover!: NgbPopover;

    constructor(
        private popoverService : PopoverService,
    ) {}

    showSPRPopover(): void {
        this.popoverService.closeAll();
        setTimeout(() => {
            if (document.querySelectorAll('.total:hover').length) {
                this.popover.open();
                this.popoverService.ngbpopover.push(this.popover);
            }
        }, 200);
    }

    closeSPRPopover(): void {
        setTimeout(() => {
            if (!document.querySelectorAll('.popover-body:hover').length) {
                this.popover.close();
            }
        }, 200);
    }

    getItems(): LabelTotal[] {
        return this.data ? this.data : [];
    }
}
