<!-- begin:: Content Head -->
    <mat-toolbar class="subheader-height subheader kt-subheader kt-grid__item " [ngClass]="{ 'kt-container--clear': clear }"
        [hidden]="subheaderService.disabled$ | async" id="kt_subheader">
        <div class="kt-container" [ngClass]="{ 'kt-container--fluid': fluid }">
            <div class="kt-subheader__main">
                <!-- begin:: Brand -->
                <div  class="kt-aside__brand kt-grid__item" id="kt_aside_brand">
                    <div class="kt-aside__brand-tools">
                        <button ktToggle [title]="isSidebarOpen() ? 'Collapse' : 'Uncollapse'" [options]="toggleOptions" (click)="onSidebarToggle()"
                            class="kt-aside__brand-aside-toggler kt-aside__brand-aside-toggler--left" id="kt_aside_toggler">
                            <span [inlineSVG]="'./assets/media/icons/svg/Navigation/Angle-double-left.svg'"></span>
                            <span [inlineSVG]="'./assets/media/icons/svg/Navigation/Angle-double-right.svg'"></span>
                        </button>
                    </div>
                </div>
                <!-- end:: Brand -->
                <div [ngClass]="'icon ' + layoutConfigService.getConfig().subheader.icon"></div>
                <h3 *ngIf="title" class="kt-subheader__title">{{ title }}</h3>
                <h4 *ngIf="desc" class="kt-subheader__desc">{{ desc }}</h4>
                <div class="kt-subheader__breadcrumbs" *ngIf="breadcrumbs.length > 0">
                    <span class="kt-subheader__separator" [hidden]="true"></span>
                    <a class="kt-subheader__breadcrumbs-home">
                        <i class="flaticon2-shelter"></i>
                    </a>
                    <ng-container *ngFor="let item of breadcrumbs">
                        <span class="kt-subheader__breadcrumbs-separator"></span>
                        <a [routerLink]="item.page" [queryParams]="$any(item).queryParams"
                            class="kt-subheader__breadcrumbs-link">
                            {{ item.title }}
                        </a>
                    </ng-container>
                </div>
            </div>
            <div class="kt-subheader__toolbar">
                <div class="kt-subheader__wrapper"></div>
            </div>
        </div>
        <button
            *ngIf="isGalvinHome()"
            [ngbTooltip]="clickforfullscreen"
            (click)="fullScreen()"
            mat-icon-button
            class="fullscreen-icon"
            aria-label="icon-button with share icon"
        >
            <span class="material-icons"> keyboard_control_key </span>
        </button>
    </mat-toolbar >

<!-- end:: Content Head -->