import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EAccessoryTypeColors } from '@galvin/core/build-plan/accessory-management/enums/accessory-type.enum';
import { IAccessoryTeamRequestPreviewAccessory } from '@galvin/core/build-plan/accessory-management/interfaces/accessory-team-request-preview-accessory';
import { IAccessoryRequestI18n } from '@galvin/core/build-plan/accessory-request-management/i18n/accessory-request-i18n.interface';
import { AccessoryRequestDialogComponent } from '@galvin/views/pages/build-plan/accessory-team-request/accessory-request-dialog/accessory-request-dialog.component';
import { IRequestPreviewI18n } from '@galvin/views/pages/build-plan/build-plan-management/request-preview';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'accessory-header',
    templateUrl: './accessory-header.component.html',
    styleUrls: ['./accessory-header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccessoryHeaderComponent {
    readonly LABELS_ACCESSORY_REQUEST: IAccessoryRequestI18n;
    readonly LABELS_REQUEST_PREV: IRequestPreviewI18n;

    @Input() accessory!: IAccessoryTeamRequestPreviewAccessory;
    @Input() isHeaderInfoVisible!: boolean;
    @Input() idBuildPlan!: number;
    @Input() buildPlanName!: string;

    constructor(
        public translate: TranslateService,
        public cdr: ChangeDetectorRef,
        private dialog: MatDialog
    ) {
        this.LABELS_ACCESSORY_REQUEST = this.translate.instant('BUILD_PLAN.ACCESSORY_REQUEST');
        this.LABELS_REQUEST_PREV = this.translate.instant('BUILD_PLAN.REQUEST_PREVIEW');
    }
    /**
     * Open dialog of request prototype
     * @param header
     * @param ev
     */
    showRequestsDialog(header: IAccessoryTeamRequestPreviewAccessory, ev: Event): void {
        ev.stopPropagation();

        const param = {
            accessory: { accessoryId: header.accessoryId },
            buildPlan: { id: this.idBuildPlan, internalProductName: this.buildPlanName },
            requestPreview: true
        };

        this.dialog.open(AccessoryRequestDialogComponent, {
            height: '796px',
            width: '1269px',
            data: param
        });
    }

    /**
     * Get Color from Accessory Type
     * @param accessoryType a Accessory Type
     * @returns a hex color
     */
    public getColor(accessoryType: string | undefined): string {
        if (accessoryType === undefined) {
            return '';
        }

        const type = Object.values(EAccessoryTypeColors).find((key) =>
            accessoryType.toUpperCase().includes(key.toUpperCase())
        );

        return !type ? 'OTHER' : type;
    }
}
