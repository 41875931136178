import {GenericResource} from '@galvin/core/_base/resource';
import {IPrototypeRequestWithConfigurationAndTeam} from '../interfaces/prototype-request-with-configuration-and-team.interface';
import { IPrototypeRequestTeam } from '../interfaces/prototype-request-team.interface';

export class PrototypeRequestResource extends GenericResource<IPrototypeRequestWithConfigurationAndTeam> {
    constructor(public override content?: IPrototypeRequestWithConfigurationAndTeam) {
        super('/build-plan/team-management', content);
    }
}


export class PrototypeRequestTeamResource extends GenericResource<IPrototypeRequestTeam> {
    constructor(public override content?: IPrototypeRequestTeam) {
        super('/build-plan/team-management', content);
    }
}
