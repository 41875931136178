import { Injectable } from '@angular/core';
/**
 * Utils to enums.
 */
@Injectable()
export class EnumUtils {
    constructor() {}

    /**
     * Key Enum by value
     * For Example: CONFIGURATION_TYPE = 'CONFIGURATION'
     * The return will be CONFIGURATION_TYPE
     * @param e The Enum
     * @param value The string will be search
     */
    getKeyEnumByValue<R = any>(e: any, value: string): R {
        let i = 0;
        let indexFound = 0;
        for (const data in e) {
            if (e[data].toLowerCase() === value.toLowerCase()) {
                indexFound = i;
                break;
            }
            i++;
        }
        return Object.keys(e)[indexFound] as any;
    }

    /**
     * Check if contains Key Enum by value
     * For Example: CONFIGURATION_TYPE = 'CONFIGURATION'
     * The return will be true if 'CONFIGURATION' is in enum
     * @param e The Enum
     * @param value The string will be search
     */
    containsKeyEnumByValue(e: any, value: string): boolean {
        for (const data in e) {
            if (e[data].toLowerCase() === value.toLowerCase()) {
                return true;
            }
        }
        return false;
    }

    /**
     * Key Enum by value
     * For Example: CONFIGURATION_TYPE = 'CONFIGURATION'
     * The return will be CONFIGURATION_TYPE
     * @param e The Enum
     * @param value The string will be search
     */
    getKeyEnumByValueWithIndeOf(e: any, value: string): string {
        let i = 0;
        let indexFound = 0;
        for (const data in e) {
            if (e[data].toLowerCase().indexOf(value.toLowerCase()) > -1) {
                indexFound = i;
                break;
            }
            i++;
        }
        return Object.keys(e)[indexFound];
    }

    /**
     * Return All Keys from enum.
     * @param e the ENUM
     */
    getAllKeys(e: any): string[] {
        return Object.keys(e);
    }

    /**
     * Return All Values from Enum
     * @param e The Enum
     */
    getAllValues(e: any): string[] {
        const response: string[] = [];
        for (const data in e) {
            response.push(e[data]);
        }
        return response;
    }
}
