export enum ETeamColumnType {
    NAME = 'NAME',
    NAME_AUTOMATION = 'NAME_AUTOMATION',
    REQUEST_OWNER = 'REQUEST_OWNER',
    FINAL_RECIPIENT = 'FINAL_RECIPIENT',
    CDC_LOCATION = 'CDC_LOCATION',
    SHIP_TO = 'SHIP_TO',
    GROUP = 'GROUP',
    TEAM_VALUES = 'TEAM_VALUES',
    REQUEST = 'REQUEST',
    ACC_REQUEST = 'ACC_REQUEST',
    TOTAL_REQUESTS = 'TOTAL_REQUESTS',
    TOTAL_ACC_REQUESTS = 'TOTAL_ACC_REQUESTS',
    TEAM_ORDER = 'TEAM_ORDER',
    SCOPE_LABELS = 'SCOPE_LABELS',
    TOTAL_BOARDS = 'TOTAL_BOARDS'
}
