import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { EOrder } from '../../order.enum';
import { ITableSortColumn } from '../../table-sort-columns.interface';
import { ETypeNulls } from '../../type-nulls.enum';

export interface IGroupId {
    column: string;
    typeOrder: string;
    typeNull: string;
}

@Component({
    selector: 'table-config-sort',
    templateUrl: './table-config-sort.component.html',
    styleUrls: ['./table-config-sort.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class TableConfigSortComponent {
    @Input() columns: ITableSortColumn[] = [];
    @Output() dispatchSelectedColumns = new EventEmitter();
    @Input() title = '';
    @Input() smallMode = false;
    @Input() set defaultSortingGroups(data: any[]) {
        if(data){
            this._defaultSortingGroups = data;
        }
    }
    private _defaultSortingGroups: any[] = [];
    get defaultSortingGroups(): any[] {
        return this._defaultSortingGroups;
    }
    @ViewChild('tableSortDialog') tableSortDialog!: TemplateRef<any>;
    @ViewChild('checkbox') checkbox!: MatCheckbox;

    notSelectedColumns!: ITableSortColumn[];
    formGroup!: FormGroup;
    dialogRef!: MatDialogRef<any, any>;
    defaultSort!: string[];
    checkboxStatus!: boolean;

    selectedColumns: ITableSortColumn[] = [];
    defaultGroups: FormGroup[] = [];
    selected: string[] = [];
    groupName = 'sortGroup';
    firstOpen = true;
    typeOrders = [
        { displayValue: '', id: EOrder.ASC },
        { displayValue: '', id: EOrder.DESC }
    ];
    typeNulls = [
        { displayValue: '', id: ETypeNulls.FIRST },
        { displayValue: '', id: ETypeNulls.LAST },
        { displayValue: '', id: ETypeNulls.IGNORE }
    ];

    private readonly columnFormControl = 'column';
    private readonly typeOrderFormControl = 'typeOrder';
    private readonly typeNullFormControl = 'typeNull';

    constructor(
        private dialog: MatDialog,
        private i18n: TranslateService,
        private fb: FormBuilder
    ) {
        this.typeOrders[0].displayValue = this.i18n.instant('COMMON.ASC');
        this.typeOrders[1].displayValue = this.i18n.instant('COMMON.DESC');
        this.typeNulls[0].displayValue = this.i18n.instant('COMMON.FIRST_NULL');
        this.typeNulls[1].displayValue = this.i18n.instant('COMMON.LAST_NULL');
        this.typeNulls[2].displayValue = this.i18n.instant('COMMON.IGNORE_NULLS');
    }

    get column(): AbstractControl | null {
        return this.formGroup.get(this.columnFormControl);
    }
    get typeOrder(): AbstractControl | null {
        return this.formGroup.get(this.typeOrderFormControl);
    }
    get typeNull(): AbstractControl | null {
        return this.formGroup.get(this.typeNullFormControl);
    }
    get getFormData(): FormArray {
        return this.formGroup.get(this.groupName) as FormArray;
    }
    get getCheckboxStatus(): boolean {
        if(this.checkboxStatus != undefined){
            return this.checkboxStatus;
        }
        return true;
    }

    openDialog(): void {
        this.dialogRef = this.dialog.open(this.tableSortDialog, {
            panelClass: 'custom-panel',
            width: '969px',
            hasBackdrop: true,
            disableClose: true
        });
        this.initialSetup();
    }

    closeDialog(sort = false): void {
        if (sort) {
            let groups: IGroupId[] = [];
            this.formGroup.getRawValue().sortGroup.forEach((e: any) => {
                groups.push({
                    column: e.column.id,
                    typeOrder: e.typeOrder.id,
                    typeNull: e.typeNull.id,
                });
            });
            this.dispatchSelectedColumns.emit(groups);
        }
        this.dialogRef.close();
    }

    initialSetup(){
        this.notSelectedColumns = this.columns.filter(i => !this.selected.includes(i.name));
        this.defaultSort = ['HW Revision', 'Sourcing', 'Unit Type', 'Build Recipe', 'Market Region'];

        if(this.firstOpen){
            if(this.defaultSortingGroups?.length > 0){
                this.formGroup = this.fb.group({
                    sortGroup: this.fb.array([])
                });
                this.defaultSortingGroups.forEach((e: any) => {
                    const control = this.formGroup.get(this.groupName) as FormArray;
                    const column = new FormControl(null, [Validators.required]);
                    const typeOrder = new FormControl(null, [Validators.required]);
                    const typeNull = new FormControl(null, [Validators.required]);

                    column.setValue(this.columns.find(i => i.id == e.column));
                    typeOrder.setValue(this.typeOrders.find(i => i.id == e.typeOrder));
                    typeNull.setValue(this.typeNulls.find(i => i.id == e.typeNull));
                    const group = this.fb.group({ column, typeOrder, typeNull });
                    this.defaultGroups.push(group);
                    control.push(group);
                });
            }else{
                this.formGroup = this.fb.group({
                    sortGroup: this.fb.array([])
                });
                this.addDefaultGroup();
            }
            this.firstOpen = false;
        }
    }

    addDefaultGroup() {
        this.defaultSort.forEach(name => {
            const control = this.formGroup.get(this.groupName) as FormArray;
            const column = new FormControl(null, [Validators.required]);
            const typeOrder = new FormControl(null, [Validators.required]);
            const typeNull = new FormControl(null, [Validators.required]);

            column.setValue(this.columns.find(e => e.name == name));
            typeOrder.setValue(this.typeOrders[0]);
            typeNull.setValue(this.typeNulls[1]);

            const group = this.fb.group({ column, typeOrder, typeNull });
            this.defaultGroups.push(group);
            control.push(group);
        });
        this.updateSelectedList();
    }

    addSortGroup() {
        const control = this.formGroup.get(this.groupName) as FormArray;
        const column = new FormControl(null, [Validators.required]);
        const typeOrder = new FormControl(null, [Validators.required]);
        const typeNull = new FormControl(null, [Validators.required]);

        control.push(this.fb.group({ column, typeOrder, typeNull }));
    }

    remove(index: number) {
        const control = this.formGroup.get(this.groupName) as FormArray;
        control.removeAt(index);
        this.updateSelectedListAndDisableCheckbox();
    }

    updateSelectedList(){
        this.selected = [];
        this.formGroup.getRawValue().sortGroup.forEach((e: any) => {
            if(e.column){
                this.selected.push(e.column.name);
            }
        });
        this.notSelectedColumns = this.columns.filter(i => !this.selected.includes(i.name));
    }

    updateSelectedListAndDisableCheckbox(){
        this.checkbox.checked = false;
        this.checkboxStatus = false;
        this.updateSelectedList();
    }

    verifAlreadySelected(selected: string){
        return this.selected.includes(selected);
    }

    disableSortButton(){
        return this.selected.length <= 0;
    }

    checkBoxChange(event: any){
        const control = this.formGroup.get(this.groupName) as FormArray;
        this.checkbox.checked = event.checked;
        this.checkboxStatus = event.checked;
        if(event.checked){
            while (control.length) {
                control.removeAt(0);
            }
            this.addDefaultGroup();
        }else{
            this.defaultGroups.forEach(e => {
                control.removeAt(control.value.indexOf(e.value));
            });
            this.defaultGroups = [];
            if(!control.length){
                this.addSortGroup();
            }
        }
        this.updateSelectedList();
    }
}

