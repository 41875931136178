import { Pipe, PipeTransform } from '@angular/core';
import { EBuildPlanStatusLabel } from '@galvin/core/build-plan/management';
import { IhwRevisions, IMyPrototypeConfiguration, IMyPrototypeFilters } from '@galvin/core/build-plan/prototype-request-management';
import { DateTimeUtils } from '@galvin/core/utils/moment-utils';
import { IBuildPlanWithRequests } from '@galvin/views/pages/build-plan/prototype-request/my-prototypes/my-prototypes.component';
import { TranslateService } from '@ngx-translate/core';
import { isEmpty } from 'lodash';

@Pipe({
    name: 'filterMyPrototypeBuildPlan'
})
export class FilterMyPrototypeBuildPlanPipe implements PipeTransform {
    readonly allowedToShow: string[] = [
        EBuildPlanStatusLabel.OPEN.toLocaleLowerCase(),
        EBuildPlanStatusLabel.PARTIALLY_OPEN.toLocaleLowerCase(),
        EBuildPlanStatusLabel.LOCKED.toLocaleLowerCase(),
        EBuildPlanStatusLabel.DPM_PENDING_APPROVAL.toLocaleLowerCase(),
        EBuildPlanStatusLabel.DPM_APPROVED.toLocaleLowerCase(),
        EBuildPlanStatusLabel.BUILDING.toLocaleLowerCase(),
        EBuildPlanStatusLabel.COMPLETED.toLocaleLowerCase(),
        EBuildPlanStatusLabel.ARCHIVED.toLocaleLowerCase(),
        EBuildPlanStatusLabel.CANCELED.toLocaleLowerCase()
    ];
    private readonly ALL: string;

    constructor(private i18n: TranslateService) {
        this.ALL = this.i18n.instant('BUILD_PLAN.PROTOTYPE_REQUEST.HEADER.FILTERS.ALL');
    }

    transform(
        value: IBuildPlanWithRequests[],
        filter: IMyPrototypeFilters
    ): IBuildPlanWithRequests[] {
        return this.filterGTOpen(value ||[]).filter((bp) => {
            return (
                this.filterByText(bp, <string>filter.inputSearch) &&
                this.filterHWRevision(bp, filter.hwRevision || []) &&
                this.validateProduct(<IBuildPlanWithRequests[]>filter.products, bp)
            );
        });
    }

    filterByText(buildPlan: IBuildPlanWithRequests, inputSearch?: string): boolean {

        const searchText = inputSearch != undefined ? inputSearch?.trim().toLocaleLowerCase() : '';

        const hasPortable = !isEmpty(buildPlan.requestsUnitType?.['Portable']);
        const portable = hasPortable ? ((buildPlan.requestsUnitType?.['Portable'] as IhwRevisions[])
            .map((p) => `${p.hwRevision} ${p.total}`)
            .join('|')
            .toLocaleLowerCase()) : '';

        const totalPortable = hasPortable ? ((buildPlan.requestsUnitType?.['Portable'] as IhwRevisions[])
            .reduce((sum, c) => sum + c.total, 0).toString()) : '';

        const hasTotal = !isEmpty(buildPlan.requestsUnitType?.['TOTAL']);
        const unitTotal = hasTotal ? ((buildPlan.requestsUnitType?.['TOTAL'] as IhwRevisions[])
            .reduce((sum, c) => sum + c.total, 0).toString()) : '';

        const hasPcbaTotal = !isEmpty(buildPlan.requestsUnitType?.['PCBA_ONLY']);
        const pcbaTotal = hasPcbaTotal ? (buildPlan.requestsUnitType?.['PCBA_ONLY'] as IhwRevisions[])
            .reduce((sum, c) => sum + c.total, 0).toString() : '';

        const hasPcbaSubTotal = !isEmpty(buildPlan.requestsUnitType?.['PCBA_ONLY_SubBoard']);
        const pcbaSubTotal = hasPcbaSubTotal ? (buildPlan.requestsUnitType?.['PCBA_ONLY_SubBoard'] as IhwRevisions[])
            .reduce((sum, c) => sum + c.total, 0).toString() : '';

        const lockDownDate = buildPlan.lockDownDate != undefined ?
            DateTimeUtils.completeDateTimeFormat(buildPlan.lockDownDate?.toString()).toLocaleLowerCase() : '';

        const hasDetailRecords = !isEmpty(buildPlan.detailRecords);
        const detailRecords = hasDetailRecords ? ((buildPlan.detailRecords as IMyPrototypeConfiguration[])
            .map(c => `${c.id} ${c.teamName} ${c.configurationId} ${c.configName} ${c.hwRevision} ${c.brNumber ||''} ${c.fvc ||''} ` +
            `${c.buildRecipe} ${c.sim} ${c.cmfColor} ${c.memoryStorageSize} ${c.memoryStorageSupplier ||''} ` +
            `${c.memoryRamSize} ${c.teamId} ${c.configStatus} ` +
            DateTimeUtils.completeDateTimeFormat(c.requestDate.toString()) + ` ${c.group} ` +
            `${c.requestOwnerUser.name} ${c.requestOwnerUser.lastName} ${c.requestOwnerUser.email} ` +
            `${c.finalRecipientUser.name} ${c.finalRecipientUser.lastName} ${c.finalRecipientUser.email} ` +
            `${c.cdcLocation.city} ${c.cdcLocation.country}` +
            `${c.shipToLocation.city} ${c.shipToLocation.country}` +
            `${c.requester.name} ${c.requester.lastName} ${c.requester.email}` +
            `${(c.cdcLocation as any).shortDescription} ${(c.shipToLocation as any).shortDescription}`)
            .join('|')
            .toLocaleLowerCase()) : '';

        return (
            inputSearch === undefined ||
            buildPlan.internalProductName?.toLocaleLowerCase().includes(searchText) ||
            buildPlan.status?.toLocaleLowerCase().includes(searchText) ||
            lockDownDate.includes(searchText) ||
            portable.includes(searchText) ||
            totalPortable.includes(searchText) ||
            pcbaTotal.includes(searchText) ||
            pcbaSubTotal.includes(searchText) ||
            unitTotal.includes(searchText) ||
            detailRecords.includes(searchText) ||

            (buildPlan.id ||'').toString().includes(searchText)
        );
    }

    filterHWRevision(buildPlan: IBuildPlanWithRequests, hwRevision: string[]): boolean {
        var hasHwRevision = false;
        hwRevision.forEach((revision) => {
            if (buildPlan.requests != undefined &&
                buildPlan.requests.sortedHWRevisions != undefined && buildPlan.requests.sortedHWRevisions.includes(revision)) {
                hasHwRevision = true;
            }
        });
        return (
            hwRevision === null ||
            hwRevision.length === 0 ||
            hasHwRevision
        );
    }

    filterGTOpen(collection: IBuildPlanWithRequests[]): IBuildPlanWithRequests[] {
        return (collection || []).filter(
            (bp) => bp.status && this.allowedToShow.includes(bp.status.toLocaleLowerCase())
        );
    }

    private validateProduct(
        bps: IBuildPlanWithRequests[],
        buildPlan: IBuildPlanWithRequests
    ): boolean {
        return (
            !bps ||
            bps.length <= 0 ||
            typeof bps[0] === 'string' ||
            !!bps.find((i) => i.id === buildPlan.id)
        );
    }
}
