import moment from 'moment-timezone';
export class DateTimeUtils {
    /**
     * MM/DD/YYYY hh:mm A
     */
    static FORMAT_DATETIME_PICKER = 'MM/DD/YYYY hh:mm A';
    /**
     * MMM DD, YYYY hh:mm:ss A
     */
    static FORMAT_DATETIME_COMPLETE = 'MMM DD, YYYY hh:mm:ss A';
    /**
     * MMM DD, YYYY HH:mm
     */
    static FORMAT_DATETIME_GALVIN = 'MMM DD, YYYY HH:mm';
    /**
     * MMM DD, YYYY
     */
    static FORMAT_DATE = 'MMM DD, YYYY';
    /**
     * 'MMM, YYYY' (Month, year)
     */
    static FORMAT_MONTH_YEAR = 'MMM, YYYY';

    /**
     * 'DD-MM-YYYY' (DAY, MONTH, YEAR)
     */
    static FORMAT_BR_DATE = 'DD-MM-YYYY';

    /**
     * 'YYYY-MM-DD' (YEAR, MONTH, DAY)
     */
    static FORMAT_US = 'YYYY-MM-DD';

    /**
     * Get an Abreviation for the local time zone
     * @returns A string abreviation of time zone like CST, AMT, BST
     */
    static getGuessAbrevTimezone(): string {
        return moment.tz(moment.tz.guess()).zoneAbbr();
    }

    /**
     * Get a GMT string from input date time
     * @param input A date time to proccess
     * @returns A GMT formatted like GMT+04
     */
    static getGMT(input: Date): string {
        return 'GMT' + moment(input).local().format('ZZ').substring(0, 3);
    }

    /**
     * Converts a string date to a string formated date
     * @param input A input string containing a date
     * @returns A formatted string like "Mar 03, 1989 12:00:00 AM UTC"
     */
    static completeDateTimeFormat(input: string): string {
        return this.completeDateTimeFormatFromDate(new Date(input));
    }

    /**
     * Converts a string date to a string formated date
     *
     * If the date already contains the timezone in it, hasTimezone will be true.
     * If the date doesn't contain timezone information, it's inferred it's UTC,
     * so it will convert using .utc(keepLocalTime: false)
     * @param input A input string containing a date
     * @param hasTimezone (Default: false) If has timezone
     * @returns A formatted string like "Mar 03, 1989 12:00 GMT+00"
     */
    static galvinLocalDateTimeFormat(input: string, hasTimezone = false): string {
        return this.galvinLocalDateTimeFormatFromDate(new Date(input), hasTimezone);
    }

    /**
     * Converts a string date to a string formated date
     *
     * If the date already contains the timezone in it, hasTimezone will be true.
     * If the date doesn't contain timezone information, it's inferred it's UTC,
     * so it will convert using .utc(keepLocalTime: false)
     * @param input A input string containing a date
     * @param hasTimezone (Default: true) If has timezone
     * @returns A formatted string like "Mar, 1989 GMT+08"
     */
    static chineseDateFormat(input: string, hasTimezone = true): string {
        return this.chineseDateFormatFromDate(new Date(input), hasTimezone);
    }

    /**
     * Converts a string date to a string formated date
     *
     * If the date already contains the timezone in it, hasTimezone will be true.
     * If the date doesn't contain timezone information, it's inferred it's UTC,
     * so it will convert using .utc(keepLocalTime: false)
     * @param input A input string containing a date
     * @param hasTimezone (Default: false) If has timezone
     * @returns A formatted string like "Mar 03, 1989"
     */
    static localDateFormat(input: string, hasTimezone = false): string {
        return this.localDateFormatFromDate(new Date(input), hasTimezone);
    }

    static completeDateTimeFormatConvertTimezone(input: string): string {
        const date = new Date(input);
        const gmt = this.getGMT(date);
        return `${moment(date).utc(true).local().format(this.FORMAT_DATETIME_GALVIN)} ${gmt}`;
    }

    /**
     * Converts a input Date to a string formated date
     * @param input A input Date
     * @returns A formatted string like "Mar 03, 1989 12:00:00 AM UTC"
     */
    static completeDateTimeFormatFromDate(input: Date): string {
        return `${moment(input).utc(false).format(this.FORMAT_DATETIME_COMPLETE)} UTC`;
    }

    /**
     * Converts a input Date to a string formated date
     * @param input A input Date
     * @returns A formatted string like "Mar, 1989"
     */
    static getMonthYearFormatted(input: Date): string {
        return `${moment(input).utc(false).format(this.FORMAT_MONTH_YEAR)}`;
    }

    static getDateFormatUS(input: Date): string {
        return `${moment(input).utc(false).format(this.FORMAT_US)}`;
    }

    /**
     * Converts a input Date to a string formated date
     *
     * If the date already contains the timezone in it, hasTimezone will be true.
     * If the date doesn't contain timezone information, it's inferred it's UTC,
     * so it will convert using .utc(keepLocalTime: false)
     * @param input A input Date
     * @param hasTimezone (Default: false) If has timezone
     * @returns A formatted string like "Mar 03, 1989 12:00 GMT-04"
     */
    static galvinLocalDateTimeFormatFromDate(input: Date, hasTimezone = false): string {
        const gmt = this.getGMT(input);
        if (hasTimezone) {
            return `${moment(input).local().format(this.FORMAT_DATETIME_GALVIN)} ${gmt}`;
        }
        return `${moment(input).utc(true).local().format(this.FORMAT_DATETIME_GALVIN)} ${gmt}`;
    }

    /**
     * Converts a input Date to a string formated date
     *
     * If the date already contains the timezone in it, hasTimezone will be true.
     * If the date doesn't contain timezone information, it's inferred it's UTC,
     * so it will convert using .utc(keepLocalTime: false)
     * @param input A input Date
     * @param hasTimezone (Default: true) If has timezone
     * @returns A formatted string like "Mar 03, 1989 GMT+08"
     */
    static chineseDateFormatFromDate(input: Date, hasTimezone = true): string {
        return `${moment(input).utc(hasTimezone).format(this.FORMAT_DATE)} ${'GMT+08'}`;
    }

    static dateFromBRDate(input: string, hasTimezone = true): string {
        return `${moment(new Date(input)).utc(hasTimezone).format(this.FORMAT_BR_DATE)}`;
    }

    /**
     * Converts a input Date to a string formated date
     *
     * If the date already contains the timezone in it, hasTimezone will be true.
     * If the date doesn't contain timezone information, it's inferred it's UTC,
     * so it will convert using .utc(keepLocalTime: false)
     * @param input A input Date
     * @param hasTimezone (Default: false) If has timezone
     * @returns A formatted string like "Mar 03, 1989"
     */
    static localDateFormatFromDate(input: Date, hasTimezone = false): string {
        if (hasTimezone) {
            return `${moment(input).local().format(this.FORMAT_DATE)}`;
        }
        return `${moment(input).utc(hasTimezone).format(this.FORMAT_DATE)}`;
    }

    /**
     * Converts a input Date to a string formated date without time zone
     * @param input A input Date
     * @returns A formatted string like "Mar 03, 2021"
     */
    static localDateFormatFromDateWithoutTimeZone(input: Date | string): string {
        return moment(input).utc(true).format(this.FORMAT_DATE);
    }

    /**
     * Converts a input string to a date without time zone
     * @param input A input Date
     * @returns A formatted string like "2021-03-22"
     */
    static localDateWithoutTimeZone(input: string): any {
        return moment(input).utcOffset(0, true);
    }

    /**
     * Returns if a date is after another date
     * @param date1 first date
     * @param date2 second date used to compare with fisrt date
     * @param compareMode compare mod can be: days, month, second
     * @returns a boolean
     */
    static isDateAfterAnother(date1: any, date2: any, compareMode = 'days'): number {
        return date1.isAfter(date2, compareMode);
    }

    static timeStampDateToAFile(name: string | Date): string {
        return moment(name).format('YYYY-MM-DD-hh-mm-A');
    }
}
