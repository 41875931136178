<div id="team-selector" class="selector-with-filter">
    <div>
        <span class="team-label">
            {{'BUILD_PLAN.PROTOTYPE_REQUEST.HEADER.TEAM_SELECTOR.TEAM' | translate}}
        </span>
        <div (openChange)="dropdownChange($event)" class="dropdown-inline-block" ngbDropdown
            [autoClose]="isMultiSelection ? 'outside':'inside'">
            <button id="team-selector-dropdown" ngbDropdownToggle [disabled]="isSelectorDisabled()"
                [attr.data-loading]="loadingTeamsSelector">
                <span *ngIf="!hasSelection" class="team-not-selected">
                    {{ 'BUILD_PLAN.PROTOTYPE_REQUEST.HEADER.TEAM_SELECTOR.SELECT_TEAM' | translate }}
                    <mat-spinner *ngIf="loadingTeamsSelector" [diameter]="20"></mat-spinner>
                </span>
                <ng-container *ngIf="isMultiSelection; then teamsTitle; else teamTitle">
                </ng-container>

                <ng-template #teamsTitle>
                    <div *ngIf="hasSelection" class="team-selected" [ngbTooltip]="getTitleTeams()">

                        <ng-container *ngIf="selection.selected.length > 1">
                            {{ getTitleTeams()}}
                        </ng-container>

                        <div *ngIf="selection.selected.length === 1">
                            <b class="team-name">{{ selection.selected[0].name }}</b>
                            <ng-container [ngTemplateOutletContext]=" {team: selection.selected[0]}"
                                [ngTemplateOutlet]="teamInfo">
                            </ng-container>
                        </div>
                    </div>
                </ng-template>

                <ng-template #teamTitle>
                    <div *ngIf="hasSelection" class="team-selected d-flex align-items-center"
                        [ngbTooltip]="selectedTeam?.name">
                        <span class="galvin-icon-team-selector"></span>
                        <b class="team-name">{{ selectedTeam?.name }}</b>
                    </div>
                    <ng-container [ngTemplateOutletContext]=" {team: selectedTeam}" [ngTemplateOutlet]="teamInfo">
                    </ng-container>
                </ng-template>
            </button>

            <div aria-labelledby="team-selector-dropdown" ngbDropdownMenu>
                <form [formGroup]="formSearch">
                    <mat-form-field id="team-selector-filter" appearance="outline"
                        class="mat-form-field-fluid selector-search">
                        <mat-icon matPrefix>search</mat-icon>
                        <input matInput formControlName="filter"
                            [placeholder]="'BUILD_PLAN.PROTOTYPE_REQUEST.HEADER.TEAM_SELECTOR.SEARCH_TEAM' | translate">
                    </mat-form-field>
                </form>

                <div class="selector-options">

                    <ng-container *ngIf="isMultiSelection; then teamsMode; else teamMode">
                    </ng-container>

                    <ng-template #teamsMode>
                        <cdk-virtual-scroll-viewport class="dropdown-items-viewport" [itemSize]="45"
                            [minBufferPx]="1800" [maxBufferPx]="1800">
                            <ul>
                                <li *ngIf="hasTeamsToShow(); else noFilterMessage"
                                    [ngClass]="{ 'checked': selection.selected.length === teams.length }"
                                    (click)="selectToggleTeams()"
                                    class="dropdown-item dropdown-all-item galvin-icon-team-selector">
                                    {{ getAllOption() }}
                                </li>
                                <li *cdkVirtualFor="let team of filtredTeams;let i=index; trackBy:trackByFunction"
                                    [ngClass]="{ 'checked': selection.isSelected(team) }"
                                    (click)="selectTeams($event, team)" class="dropdown-item galvin-icon-team-selector">
                                    <span [ngbTooltip]="team.name"><b>{{ team.name }} </b></span>
                                    <ng-container [ngTemplateOutletContext]="{team:team}" [ngTemplateOutlet]="teamInfo">
                                    </ng-container>
                                    <div class="bp-teams" *ngIf="canShowBpTeams(team)">
                                        <div class="bp-team galvin-icon-sub-team-selector text-truncate"
                                            *ngFor="let teamAssociation of team.teamAssociationUnlikeAliasList">
                                            <div [ngbTooltip]="getTooltipForTeamAssociation(teamAssociation)">
                                                {{getTooltipForTeamAssociation(teamAssociation)}}
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </cdk-virtual-scroll-viewport>
                    </ng-template>

                    <ng-template #teamMode>
                        <cdk-virtual-scroll-viewport class="dropdown-items-viewport" [itemSize]="45"
                            [minBufferPx]="1800" [maxBufferPx]="1800">
                            <ul>
                                <li *cdkVirtualFor="let team of filtredTeams; let i=index; trackBy:trackByFunction"
                                    [ngClass]="{ 'checked': selectedTeam === team }" (click)="selectTeam(team)"
                                    class="dropdown-item galvin-icon-team-selector">
                                    <span [ngbTooltip]="team.name" class="team-name"><b>{{team.name}}</b></span>
                                    <ng-container [ngTemplateOutletContext]="{team: team}"
                                        [ngTemplateOutlet]="teamInfo">
                                    </ng-container>
                                </li>
                            </ul>
                        </cdk-virtual-scroll-viewport>
                    </ng-template>

                    <ng-template #noFilterMessage>
                        <div class="selector-no-options">
                            {{ 'BUILD_PLAN.PROTOTYPE_REQUEST.HEADER.TEAM_SELECTOR.NO_TEAMS_FILTER' | translate }}
                        </div>
                    </ng-template>
                </div>
            </div>
        </div>
    </div>
</div>


<ng-template #userInfo let-user="user" let-type="type" let-icon="icon">
    <i class='fa fa-user pr-1 {{icon}}-user-icon'></i>
    <b>{{'BUILD_PLAN.PROTOTYPE_REQUEST.HEADER.TEAM_SELECTOR.' + type.toUpperCase() + '_USER' | translate }}
    </b> {{user.name}} {{user.lastName}}
    ({{user.email}})
</ng-template>

<ng-template #teamInfo let-team="team">
    <ng-template #tipRequestOwner>
        <ng-container [ngTemplateOutletContext]="{user: team.requestOwnerUser, type: 'request_owner', icon: 'request-owner'}"
            [ngTemplateOutlet]="userInfo">
        </ng-container>
    </ng-template>

    <ng-template #tipFinalRecipient>
        <ng-container [ngTemplateOutletContext]="{user: team.finalRecipientUser, type: 'final_recipient', icon: 'final-recipient'}"
            [ngTemplateOutlet]="userInfo">
        </ng-container>
    </ng-template>

    <ng-template #tipCdcLocation>
        <b>{{'BUILD_PLAN.PROTOTYPE_REQUEST.HEADER.TEAM_SELECTOR.CDC_LOCATION' | translate}} </b> <i
            class='{{this.getFlag(team.cdcLocation.country)}}'></i> <br>
        {{team.cdcLocation.city}}
    </ng-template>

    <ng-template #tipShipToLocation>
        <b>{{'BUILD_PLAN.PROTOTYPE_REQUEST.HEADER.TEAM_SELECTOR.SHIP_TO_LOCATION' | translate}}</b> <i
            class='{{this.getFlag(team.shipToLocation.country)}}'></i> <br>
        {{team.shipToLocation.city}}
    </ng-template>

    <div class="w-100" *ngIf="team">

        <small>
            <ng-container *ngIf="team.requestOwnerUser">
                <div [ngbTooltip]="tipRequestOwner" class="w-25 text-truncate d-inline-block text-left">
                    <i class='fa fa-user pr-1 request-owner-user-icon'></i>
                    <span>{{team.requestOwnerUser.name}} {{team.requestOwnerUser.lastName}}</span>
                </div>
            </ng-container>

            <ng-container *ngIf="team.finalRecipientUser">
                <div [ngbTooltip]="tipFinalRecipient" class="w-25 text-truncate d-inline-block text-left">
                    <i class='fa fa-user pr-1 final-recipient-user-icon'></i>
                    {{team.finalRecipientUser.name}} {{team.finalRecipientUser.lastName}}
                </div>
            </ng-container>

            <ng-container *ngIf="team.cdcLocation">
                <div [ngbTooltip]="tipCdcLocation" class="w-25 text-truncate d-inline-block text-left">

                    <i class='{{this.getFlag(team.cdcLocation.country)}}'></i>
                    {{team.cdcLocation.city}}

                </div>
            </ng-container>

            <ng-container *ngIf="team.shipToLocation">
                <div [ngbTooltip]="tipShipToLocation" class="w-25 text-truncate d-inline-block text-left">
                    <i class='{{this.getFlag(team.shipToLocation.country)}}'></i>
                    {{team.shipToLocation.city}}
                </div>
            </ng-container>
        </small>
    </div>
</ng-template>