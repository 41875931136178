import { Pipe, PipeTransform } from '@angular/core';
import { IOptionsModel } from '../../configuration-filters/configuration-parameters-filters.component';

@Pipe({
    name: 'hasOptionsSelected'
})
export class HasOptionsSelectedPipe implements PipeTransform {
    transform(optionsSelected: IOptionsModel) {
        let optionsSelectedTmp = false;

        for (const p of this.fvcSelected(optionsSelected)) {
            optionsSelectedTmp = this.getValueFromParameter(p, optionsSelected).length > 0;
            if (optionsSelectedTmp) {
                break;
            }
        }
        return optionsSelectedTmp;
    }

    public getValueFromParameter(parameter: string, optionsSelected: IOptionsModel): string[] {
        return Object.keys(optionsSelected[parameter]);
    }

    public fvcSelected(optionsSelected: IOptionsModel): string[] {
        return Object.keys(optionsSelected);
    }
}