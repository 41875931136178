import { Component, Input } from '@angular/core';
import { ISpecialRequirementsI18n } from '@galvin/core/build-plan/field-value-management/special-requirements/i18n/special-requirements-i18n.interface';
import { TranslateService } from '@ngx-translate/core';
import { isEmpty } from 'lodash';
import { IRequestSpecialRequirementSimple } from './../../proto-request-table/interfaces/request-special-requirement.interface';
import { LocalStorageHelper } from '@galvin/core/storage/local-storage-helper.service';
import { REGEX_MENTIONS_EMAIL } from '@galvin/core/comments/mention-comment/mention-comment.component';

export interface IPopoverSpecialRequirements {
    user?: {
        name?: string;
        lastName?: string;
    };
    requestDate?: Date;
    additionalComments?: string;
    specialRequirementList?: IRequestSpecialRequirementSimple[];
}

@Component({
    selector: 'popover-special-requirements',
    templateUrl: './popover-special-requirements.component.html',
    styleUrls: ['./popover-special-requirements.component.scss']
})
export class PopoverSpecialRequirementsComponent {
    @Input() data!: IPopoverSpecialRequirements;
    @Input() showRequestDate = false;

    readonly labels: ISpecialRequirementsI18n;
    readonly formatDate = 'MMM dd yyyy, HH:mm:ss';

    constructor(private translateService: TranslateService, private localtorageHelper: LocalStorageHelper) {
        this.labels = this.translateService.instant(
            'BUILD_PLAN.FIELD_VALUE_MANAGEMENT.SPECIAL_REQUIREMENTS'
        );
    }

    get userFullName(): string {
        const user = this.data.user || this.localtorageHelper.getLoggedUser();
        return `${user?.name || ''} ${user?.lastName || ''}`;
    }

    hasSpecialRequirements(data: IPopoverSpecialRequirements): boolean {
        if (!isEmpty(data.specialRequirementList)) {
            return true;
        }
        return false;
    }

    mentionsHighlight(text: string): string {
        const user = this.localtorageHelper.getLoggedUser();
        if(text){
            text = text.replace(REGEX_MENTIONS_EMAIL, match => `<span class="mention-text">${match}</span>`);
            if(user.email){
                text = text.replace('@'+user.email, match => `<span class="mention-text-logged-user">${match}</span>`);
            }
        }
        return text;
    }
}
