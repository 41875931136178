import { Component, EventEmitter, Input, Output } from '@angular/core';
// tslint:disable-next-line:max-line-length
import { EApplication } from '@galvin/core/notification/notification.interface';
import { GalvinDialogGenericService } from '../../../../../../core/_base/general/galvin-dialog-generic/services/galvin-dialog-generic.service';

@Component({
    selector: 'header-dialog-generic',
    templateUrl: './header-dialog-generic.component.html',
    styleUrls: ['./header-dialog-generic.component.scss']
})
export class HeaderDialogGenericComponent {
    @Input() title!: string;
    @Input() loadSave = false;
    @Input() app!: EApplication;
    @Output() closeAction= new EventEmitter();
    @Input() closeByService= true;

    constructor(private service: GalvinDialogGenericService) {}

    showProgressBar(): boolean {
        return this.service.showProgressBar || this.loadSave;
    }

    close(): void {
        if (!this.loadSave) {
            if(!this.closeByService){
                this.closeAction.emit();
            }else{
                this.service.close$.next(false);
            }
        }
    }
}
