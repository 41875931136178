<title-expanded [title]="'BUILD_PLAN.CONFIGURATION.COMMON.ACCESSORY_PARAMETERS'| translate" [expanded]="expanded"
    (expandedOut)="expanded = $event">
</title-expanded>
<div class="d-flex flex-row" id="accessory-container" [@detailExpand]="expanded  ? 'expanded' : 'collapsed'">
    <ul class="p-0 d-flex flex-row flex-wrap h-100 mt-4 justify-content-start">
        <li class="d-flex justify-content-between align-items-center mb-3 mr-3" [style.width.px]="180"
            *ngFor="let accessory of accessories">
            <span container="galvin-dialog-generic" [ngbTooltip]="accessory.parameterName"
                container="galvin-dialog-generic" class="text-default text-truncate text-normal property-text">
                {{accessory.parameterName}} </span>
            <div class="d-flex justify-content-center align-items-center tag-prototypes color-dark-gray"
                [style.width.px]="75" [style.min-width.%]="36" [attr.data-source]="accessory.source">
                <span container="galvin-dialog-generic" container="galvin-dialog-generic"
                    [ngbTooltip]="getValue($any(accessory.value)) | checkNull" placement="left"
                    class="text-normal d-inline-block text-truncate p-2 color-dark-gray">
                    {{ getValue($any(accessory.value)) | checkNull}}
                </span>
            </div>
        </li>
    </ul>
</div>