export interface IAccessoryI18n {
    DIALOG_CREATE: {
        CREATE_TITLE: string,
        EDIT_TITLE: string,
        MESSAGE_CREATED: string,
        MESSAGE_EDITED: string
    };
    DIALOG_CLONED: {
        CREATE_TITLE: string;
        MESSAGE_CREATED: string;
        MESSAGE_EDITED: string;
        MESSAGE_CLONED: string;
        ACCESSORY_ORDER: string;
        ACCESSORY_ORDER_HINT: string;
    };
    SORT_TABLE_TITLE: string,
    EXTRA_BLOCK_TABLE_TOOLTIP: {
        CATEGORY: string,
        VENDOR: string,
        VENDOR_PART: string,
        MOTO_PART: string
    }
    DIALOG_EDIT: {
        CREATE_TITLE: string;
        EDIT_TITLE: string;
        MESSAGE_CREATED: string;
        BUTTON_CREATE: string;
        MESSAGE_EDIT_CONFIG_STATUS: string;
        MESSAGE_EDIT_ALREADY_OPEN_FILLED: string;
        MESSAGE_EDIT_ALREADY_OPEN: string;
        MESSAGE_OPENED_DATE: string;
        MESSAGE_TEMPORARY_DISABLED_DATE: string;
        ORDER_CHANGE_ERROR: string;
        ORDER_CHANGE_SUCCESS: string;
        ORDER_CHANGING: string;
    };
    COMMON: {
        BUTTONS: {
            CREATE: string;
            DELETE: string;
            EDIT: string;
            HIDE_SHOW_COLUMNS: string;
            CLONE: string;
            ADD_PARAMETER: string;
        };
        MANDATORY_FIELD: string;
        CLONE: string;
        CHOOSE_FIELDS: string;
        ACCESSORY: string;
        ACCESSORY_NAME: string;
        ACCESSORY_PARAMETERS: string;
        ACCESSORY_STATUS_DETAIL: string;
        RESET: string;
        DELETE_WARN: string;
        CANT_DELETE_TITLE: string;
        CANT_DELETE_ACCESSORY: string;
        CONFIRMATION_DELETE_DIALOG_TITLE: string;
        CONFIRMATION_EDIT_DIALOG_TITLE: string;
        SUCCESSFUL_DELETED: string;

    };
    ERROR: {
        UPDATE_FIELD_FAILED: string;
    };
    ACCESSORY_FILTER: {
        TITLE: string;
    };
    ACCESSORY_REQUEST_PREVIEW: {
        MASS_EDIT_LABEL: string;
        TOTAL_REQUEST_FROM_TEAM: string;
        DETAILS: string;
        CLONE: string;
        EDIT: string;
        TOTAL_REQUEST_FULL_VIEW: string;
        SPECIAL_REQUIREMENTS_TITLE: string;
        COLLAPSE_HEADER_INFO: string;
        UNCOLLAPSE_HEADER_INFO: string;
        CANCELED_ACCESSORY: string;
        FILTER_BY_ONLY_REQUESTS: string;
        RESET_FILTERS: string;
    };
    ACCESSORY_PARAMETER: {
        NEW_INDICATOR: string;
        BTN_ADD_ACCESSORY_PARAMETER: string;
        SEARCH_ACCESSORY_PARAMETER: string;
        ADD_TO_BUILD_PLAN: string;
        ACCESSORY_PARAMETER_ADDED: string;
        DELETE_CONFIRMATION: string;
        DELETE_CONFIRMATION_DIALOG_TITLE: string;
        REMOVE_ACCESSORY_PARAMETER: string;
        ACCESSORY_PARAMETER_DELETED: string;
    };
    REMOVE_PARAMETER: string;
    DIALOG_SHOW_ACCESSORY_WORKFLOW: {
        INFO: string,
        TITLE: string
    };
    PLURAL_NAME: string;
}
