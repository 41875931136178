import {
    IAccessoryRequest
} from '@galvin/core/build-plan/prototype-request-management/interfaces/prototype-request-with-configuration-and-team.interface';
// Angular
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {
    IAccessoryRequestTeam
} from '@galvin/core/build-plan/accessory-request-management/interface/accessory-request-team.interface';
import {
    IPrototypeRequestI18n
} from '@galvin/core/build-plan/prototype-request-management/i18n/prototype-request-i18n.interface';
import {ResourceService} from '@galvin/core/_base/resource';
import {TranslateService} from '@ngx-translate/core';
import {Observable} from 'rxjs';
// Environments
import {map} from 'rxjs/operators';
import {AccessoryRequestManagementResource, AccessoryRequestTeamManagementResource} from './accessory-request-management-resource';
import { IAccessoryRequestTeamPayloadRequest } from './accessory-request-team.interface';

@Injectable({
    providedIn: 'root'
})
export class AccessoryRequestManagementService extends ResourceService {
    private readonly URL_ACCESSORY_REQUEST = '/build-plan/accessory-team-request';

    public readonly ALL: string;
    private readonly labels: IPrototypeRequestI18n;

    constructor(private httpClient: HttpClient, private translate: TranslateService) {
        super(httpClient);
        this.labels = this.translate.instant('BUILD_PLAN.PROTOTYPE_REQUEST');
        this.ALL = this.translate.instant('BUILD_PLAN.PROTOTYPE_REQUEST.HEADER.FILTERS.ALL');
    }

    /**
     * Get Current BU vs Location BU vs Configuration data for a given BP
     *
     * @param buildPlanId id of bp to get the report data from
     * @param teamAssociationId id of team association
     */
    getRequestCardsTeamView(
        buildPlanId: number,
        teamAssociationId: number
    ): Observable<IAccessoryRequestTeam[]> {
        return this.get(
            `${this.URL_ACCESSORY_REQUEST}/build-plans/${buildPlanId}/team-association/${teamAssociationId}/requests`
        ).pipe(
            map((response) => {
                return response.content;
            })
        );
    }


    /**
     * Get a accessory team requests given accessory and bp team
     *
     * @param accessoryId id of bp to get the report data from
     * @param teamAssociationId id of team association
     */
    getAccessoryTeamRequestsByAccessoryAndTeam(
        accessoryId: number,
        teamAssociationId: number
    ): Observable<IAccessoryRequestTeam[]> {
        return this.get(
            `${this.URL_ACCESSORY_REQUEST}/accessories/${accessoryId}/teams/${teamAssociationId}`
        ).pipe(
            map((response) => {
                return response.content;
            })
        );
    }

    /**
     * Get a accessory team requests given accessory and bp team
     *
     * @param accessoryId
     * @param teamAssociationId id of team association
     */
    getPrototypeRequestsHistoryByAccessoryAndTeam(
        accessoryId: number,
        teamAssociationId: number
    ): Observable<IAccessoryRequestTeam[]> {
        return this.get(
            `${this.URL_ACCESSORY_REQUEST}/accessories/${accessoryId}/teams/${teamAssociationId}/history`
        ).pipe(
            map((response) => {
                return response.content;
            })
        );
    }

    upsertAccessoryTeamRequestsByAccessoryAndTeam(accessoryId: number, teamAssociationId: number, body: IAccessoryRequestTeamPayloadRequest) {
        const accessoryRequestResource = new AccessoryRequestTeamManagementResource();
        accessoryRequestResource._links.self = {
            href: `${this.URL_ACCESSORY_REQUEST}/accessories/${accessoryId}/teams/${teamAssociationId}`
        };
        accessoryRequestResource.content = body;

        return this.post<IAccessoryRequest, AccessoryRequestTeamManagementResource>(
            accessoryRequestResource
        ).pipe(
            map((response) => {
                return response.content;
            })
        );
    }

    upsertAccessoryTeamRequestsByAccessoryAndTeamByApproval(body: any) {
        const accessoryRequestResource = new AccessoryRequestManagementResource();
        accessoryRequestResource._links.self = {
            href: '/build-plan/accessory-request-approval'
        };
        accessoryRequestResource.content = body;

        return this.post<IAccessoryRequest, AccessoryRequestManagementResource>(
            accessoryRequestResource
        ).pipe(
            map((response) => {
                return response.content;
            })
        );
    }
}
