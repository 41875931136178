//Intefaces
import { EApplication } from '@galvin/core/notification/notification.interface';
import { ITooltip } from '@galvin/core/_base/general/galvin-dialog-generic/interfaces/dialog-data.interface';
import { IButtonConfig, IDialogData } from './../../../../../core/_base/general/index';

export abstract class ConfigGenericModal {
    public title!: string;
    public cancelButton!: IButtonConfig;
    public saveOrEditButton!: IButtonConfig;
    public customButton!: IButtonConfig;
    public enabled!: boolean;
    public tooltips!: ITooltip;
    public app!: EApplication;

    /**
     * This method build the buttons variable
     * @param {IDialogData} data - dialog configuration
     */
    configGenericModal(data: IDialogData): void {
        const { cancelButton, saveOrEditButton, customButton, title } = data;
        this.cancelButton = cancelButton as IButtonConfig;
        this.saveOrEditButton = saveOrEditButton as IButtonConfig;
        this.customButton = customButton as IButtonConfig;
        this.title = title+'';
        this.enabled = 'enabled' in data ? (data.enabled as boolean) : true;
        this.tooltips = { ...data.tooltips };
        if (data.customButton) {
            this.tooltips.custom = this.tooltips.custom
                ? this.tooltips.custom
                : data.customButton.value;
        }
        this.tooltips.save = this.tooltips.save
            ? this.tooltips.save
            : this.checkIfExist(data.saveOrEditButton);
        this.tooltips.edit = this.tooltips.edit
            ? this.tooltips.edit
            : this.checkIfExist(data.saveOrEditButton);
        this.tooltips.cancel = this.tooltips.cancel
            ? this.tooltips.cancel
            : this.checkIfExist(data.cancelButton);
        this.app = data.app as EApplication;
    }

    public checkIfExist(value: IButtonConfig | undefined): string {
        return value != undefined && value ? value.value : '';
    }
}
