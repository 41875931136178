export interface INotifications {
    PERIODS: { [key: string]: string };
    SEARCH_PLACEHOLDER: string;
    ALL_PRODUCTS: string;
    ALL_DATES: string;
    BEGIN_DATE: string;
    END_DATE: string;
    FROM: string;
    TO: string;
    NOTIFICATION: string;
    SUBJECT: string;
    MESSAGE: string;
    CHOICES: {
        NOTIFICATION: string;
        EMAIL: string;
        BOTH: string;
    };
    DIALOG: {
        TITLE: string;
        STEP_ONE_SELECT_INFO: string;
        STEP_TWO_WRITE_YOU_MESSAGE: string;
        YOU_WANT_TO_SEND_AS: string;
        TEMPLATES: string;
        BUILD_PLANS: string;
        RECEIVERS: string;
        PREVIEW_IN_TEMPLATE: string;
        NOTIFICATION_CREATED_WITH_SUCCESS: string;
        NOTIFICATION_NOT_CREATED: string;
        USER: string;
        GROUP: string;
        BP_HWPM: string;
        ALL_HWPM: string;
        ALL_USERS: string;
        ALL_USERS_WITH_REQUEST: string;
        MORE: string;
    };
    EVENTS: {
        BP_REQUESTS_CHANGE: string;
        BP_CREATION: string;
        BP_OPEN: string;
        BP_ACCESSORY_REQUESTS_CHANGE: string;
        BP_CONFIGURATION_CHANGE: string;
        BP_TEAM_REMOVED: string;
        BP_CONFIGURATION_REMOVED: string;
    };
}
export const NOTIFICATIONS: INotifications = {
    ALL_PRODUCTS: 'All Products',
    ALL_DATES: 'All Dates',
    BEGIN_DATE: 'Begin Date',
    END_DATE: 'End Date',
    FROM: 'From',
    TO: 'To',
    NOTIFICATION: 'Notification',
    SEARCH_PLACEHOLDER: 'Search Notification',
    SUBJECT: 'Subject',
    MESSAGE: 'Message',
    CHOICES: {
        NOTIFICATION: 'Notification',
        EMAIL: 'Email',
        BOTH: 'Both'
    },
    DIALOG: {
        TITLE: 'Send Notification',
        STEP_ONE_SELECT_INFO: '1. Select the info for the notification',
        STEP_TWO_WRITE_YOU_MESSAGE: '2. Write your message',
        YOU_WANT_TO_SEND_AS: 'You want to send this message as',
        TEMPLATES: 'Template',
        BUILD_PLANS: 'Build Plan',
        RECEIVERS: 'Receivers (Users & Groups)',
        PREVIEW_IN_TEMPLATE: 'Preview notification with template',
        NOTIFICATION_CREATED_WITH_SUCCESS: 'Notification created with success!',
        NOTIFICATION_NOT_CREATED: 'Could not create the notification',
        USER: 'User:',
        GROUP: 'Group:',
        BP_HWPM: 'Build Plan HWPMs',
        ALL_HWPM: 'All HWPMs',
        ALL_USERS: 'All Users',
        ALL_USERS_WITH_REQUEST: 'All Users with requests',
        MORE: 'more...'
    },
    PERIODS: {
        TODAY: 'Today',
        YESTERDAY: 'Yesterday',
        WEEK: 'This Week',
        JANUARY: 'January',
        FEBRUARY: 'February',
        MARCH: 'March',
        APRIL: 'April',
        MAY: 'May',
        JUNE: 'June',
        JULY: 'July',
        AUGUST: 'August',
        SEPTEMBER: 'September',
        OCTOBER: 'October',
        NOVEMBER: 'November',
        DECEMBER: 'December'
    },
    EVENTS: {
        BP_REQUESTS_CHANGE: 'Request Changed',
        BP_CREATION: 'Build Plan created',
        BP_OPEN: 'Build Plan Open',
        BP_ACCESSORY_REQUESTS_CHANGE: 'Accessory Request Change',
        BP_CONFIGURATION_CHANGE: 'Configuration Changed',
        BP_TEAM_REMOVED: 'Team Removed',
        BP_CONFIGURATION_REMOVED: 'Configuration Removed'
    }
};
