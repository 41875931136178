<div ngbDropdown container="body" #dropDown="ngbDropdown" class="d-inline-block menu-show-dropdown" [title]="titleDropdown">
    <div class="d-flex align-items-center h-100">
        <button mat-raised-button ngbDropdownToggle class="galvin-btn-primary" id="btn-dropdown-menu"
            data-test="btn-columns_dropdown">

            <span *ngIf="showViewList" class="material-icons">view_list</span>
            <ng-container *ngIf="!smallMode">
                <span *ngIf="showText">
                    {{ 'BUILD_PLAN.BUILD_PLAN_MANAGEMENT.COMMON.COLUMNS' | translate }}
                </span>
                <span class="material-icons">keyboard_arrow_down</span>
            </ng-container>
        </button>
    </div>

    <div ngbDropdownMenu class="p-2" aria-labelledby="btn-dropdown-menu">
        <div *ngIf="displayGroupsIsEmpty()" class="menu-show-groups">
            <div *ngFor="let item of displayColumns;let i=index; trackBy:trackByFunction">
                <ng-container *ngTemplateOutlet="itemColumnTemplate; context: {column: item, index: i}">
                </ng-container>
            </div>
        </div>
        <div ng-else class="menu-show-groups">
            <div *ngFor="let group of displayGroups;let gi=index; trackBy:trackByFunction" class="columns-group">
                <div class="d-flex align-items-center" [style.maxWidth.%]="95">
                    <mat-checkbox (change)="$event? toggleGroup(gi) : null" (click)="$event.stopPropagation()"
                        [checked]="selectedGroups.isSelected(gi)" [disabled]="group.disabled" class="mw-100"
                        color="primary">
                        <ng-template #defaultGroupLabelTemplate>
                            <span class="ml-1 text-dark font-weight-normal text-truncate" [ngbTooltip]="group.name">
                                <b [attr.data-group]="group.type">{{group.name}}</b>
                            </span>
                        </ng-template>
                        <ng-template [ngTemplateOutletContext]="{item: group}"
                            [ngTemplateOutlet]="columnLabelTemplate || defaultGroupLabelTemplate">
                        </ng-template>
                    </mat-checkbox>
                </div>
                <div class="ml-5" *ngFor="let column of (group | getDisplayColumnByGroup:columnsByGroups:displayColumns); trackBy:trackByFunction">
                    <ng-container
                        *ngTemplateOutlet="itemColumnTemplate; context: {column: column.value, index: column.key}">
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #itemColumnTemplate let-column="column" let-index="index">
    <div class="d-flex align-items-center" [style.maxWidth.%]="95">
        <mat-checkbox (change)="$event? columnsToHide.toggle(index): null" (click)="$event.stopPropagation()"
            [checked]="!columnsToHide.isSelected(index) || $any(column).disabled" [disabled]="$any(column).disabled"
            class="mw-100" color="primary">
            <ng-template #defaultLabelTemplate>
                <span class="ml-1 text-dark font-weight-normal text-truncate" [ngbTooltip]="column.description">
                    {{column.description}}
                </span>
            </ng-template>
            <ng-template [ngTemplateOutletContext]="{item: column}"
                [ngTemplateOutlet]="columnLabelTemplate || defaultLabelTemplate">
            </ng-template>
        </mat-checkbox>
    </div>
</ng-template>