<galvin-dialog-generic [data]="data" [save]="viewLoading">
    <div class="form-group-management">
        <form [formGroup]="form" novalidate>
            <div class="kt-form__section kt-form__section--first p-3">
                <div class="kt-form__group row">
                    <div class="col kt-margin-bottom-20-mobile mb-2">
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>
                                {{'BUILD_PLAN.PROTOTYPE_REQUEST.DIALOG.REQUEST_BY_APPROVAL.COMMENT_REASON' | translate}}
                            </mat-label>
                            <input formControlName="reason" matInput>
                        </mat-form-field>
                    </div>
                </div>
                <div class="kt-margin-bottom-20-mobile mb-2">
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-chip-list #chipList>
                            <mat-chip *ngFor="let cr of crs"
                                      [removable]="true"
                                      (removed)="removeCR(cr)">
                                {{cr}}
                                <mat-icon matChipRemove>cancel</mat-icon>
                            </mat-chip>
                            <input placeholder="{{'BUILD_PLAN.PROTOTYPE_REQUEST.DIALOG.REQUEST_BY_APPROVAL.PCM_CR_LIST' | translate}}"
                                   formControlName="cr"
                                   [matChipInputFor]="chipList"
                                   [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                   [matChipInputAddOnBlur]="true"
                                   (matChipInputTokenEnd)="addCR($event)">
                        </mat-chip-list>
                    </mat-form-field>
                </div>
            </div>
        </form>
    </div>
</galvin-dialog-generic>
