import { IDSRI18n } from '@galvin/core/build-plan/dsr-management/i18n/dsr-i18n.interface';

export const DSR: IDSRI18n = {
    DIALOG_CREATE_OR_EDIT: {
        TITLE: 'Add / Edit Shipment Details',
        MESSAGE_CREATED: 'Shipment created',
        MESSAGE_EDITED: 'Shipment edited',
        BTN_ADD_OR_EDIT: 'Add / Edit Shipment Details',
        BTN_BULK_ADD_OR_EDIT_DETAILS_FOR_SHIPMENT_DATE: 'Bulk Add / Edit Details For Shipment Date',
        ADD_TRACK: 'Concatenate tracking awb field values with filled awb(s)',
        REPLACE_TRACK: 'Replace tracking awb field values with filled awb(s)',
        DAILY_SHIPMENT_TOOLTIP:
            '* The range date is based on first daily shipment until the last by each dsr',
        UNITS: 'Units',
        NOTES: 'Notes',
        COLOR: 'Color',
        PO: 'PO',
        STATUS: 'Status',
        SHIPPED_DATE: 'Shipped Date (China Standard Time GMT+8)',
        LOCATION: 'Location',
        AWB_FIELD: 'AWB(s)',
        COMMENTS: 'Comment',
        TITLE_TRACKING: 'Tracking AWBs',
        TRACKING_AWB: 'Tracking AWB',
        UNIT_DIFF: 'The tracking total units differs from the units inputted in this date',
        HAS_SPREQ_TOOLTIP: 'Has Special Reqs',
        MESSAGE_DUPLICATE_LOCATION: 'Location already have selected. You can differ by Has Special Reqs check box',
        TITLE_WARNING: 'Warning',
        PROCCED_CHANGE:'PROCCED',
        MESSAGE_WARNING: 'You have more than one Tracking AWBs for this DSR Entry. This operation will overwrite all entries by the first one. If you want detailed customization, please, cancel it and then double click the cell and Edit directly in the Details screen.'
    },
    DIALOG_SUMMARY: {
        TOOLTIP_MORE_DETAILS:'Click for Details',
        TITLE: 'Shipment Details',
        DETAILS_TITLE:'details',
        CONFIGURATION_ALIAS: 'Conf. Alias - (#ID)',
        BUILD_SITE: 'Build Site',
        SHIP_TO: 'Ship to (CDC)',
        HW_REVISION: 'HW Revision',
        PNX_CVR_PCBA: 'PN/XCVR/PCBA',
        BR_NUMBER: 'BR#',
        FVC: 'FVC',
        AWB_TRACKING: 'Tracking AWBs',
        LOCATION: 'Location To',
        BUSINESS: 'Business',
        HAS_SPECIAL_REQUIREMENT: 'Has Special Reqs',
        TOTAL: 'Total',
        TOTAL_SHIPPED: 'Current Total Delivered:',
        NOT_FOUND:' No data matching the filter',
    },
    SELECT_EMAIL: 'Select email',
    SELECT_STATUS: 'Select Status',
    FVC_HW_REVISION: 'HW Revision (All)',
    FVC_XCVR: 'PN/XCVR (All)',
    FVC_BR_NUMBER: 'BR Number (All)',
    FVC_FVC: 'FVC (All)',
    FVC_CDC: 'Ship To (All)',
    FVC_STATUS : 'Status (All)',
    SEARCH_FVC_HW_REVISION: 'Search in HW Revision',
    SEARCH_FVC_XCVR: 'Search in PN/XCVR',
    SEARCH_FVC_BR_NUMBER: 'Search in BR Number',
    SEARCH_FVC_FVC: 'Search in FVC',
    SEARCH_FVC_CDC: 'Search in Ship To',
    SEARCH_FVC_STATUS : 'Search in Status',
    TIME_ZONE: 'China Standard Time (GMT+8)',
    XCVR_TOOLTIP: 'If the field Unit Type is different from  PCBA_ONLY or PCBA_ONLY_subBoard ,the value of "PN/XCVR/PCBA" field  will display XCVR Kit # value, but if the field Unit Type is equals PCBA_ONLY or PCBA_ONLY_subBoard , the value of "PN/XCVR/PCBA" field, will display PCBA Kit# value',
    NOT_AVAILABLE_MESSAGE: 'This DSR was marked for deletion but is still being shown because it has user inputs.',
    HW_TOTALS_WARNING: 'Remark: the Totals here are not equivalent to the ones from Prototype Request and Configuration screens since they\'re grouped exclusivelly by HW Revision, and not by Unit Types, i.e., Portable or PCBAs.',
    EXPORT_BUTTON: 'Export to XLSX',
    PROCESSING_DOWNLOAD: '<i class="ml-1 fas fa-spinner fa-spin"></i> The XLSX file is being created, please wait'
};
