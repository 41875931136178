<div class="kt-portlet__head-progress">
    <mat-progress-bar mode="indeterminate" *ngIf="showProgressBar()"></mat-progress-bar>
</div>
<div class="kt-portlet__head kt-portlet__head__custom justify-content-between dialog-header-generic {{ app }}">
    <div class="kt-portlet__head-label mw-100">
        <ng-template #titleToolTip>
            <div [innerHtml]="title"></div>
        </ng-template>
        <h3 [ngbTooltip]="titleToolTip" container="galvin-dialog-generic" placement="bottom"
            class="kt-portlet__head-title dialog-generic-header-text text-primary mw-100 text-truncate"
            [innerHtml]="title"></h3>
    </div>
    <div class="kt-portlet__head-toolbar">
        <div class="kt-portlet__head-group">
            <button ngbTooltip="{{'COMMON.CLOSE_DIALOG' | translate}}" placement="bottom" id="btn-header-close"
                container="galvin-dialog-generic" type="button" (click)="close()"
                class="btn btn-clean btn-sm btn-icon btn-icon-md">
                <i class="la la-times"></i>
            </button>
        </div>
    </div>
</div>
