<button class="galvin-btn-primary btn-open-sort-table" mat-raised-button color="primary" (click)="openDialog()">
    <span class="material-icons">sort</span>
    {{ 'COMMON.SORT_COLUMNS' | translate }}
</button>

<ng-template #tableSortDialog>

    <div mat-dialog-title class="d-flex justify-content-between">
        <div class="kt-portlet__head-label mw-100">
            <h3 [ngbTooltip]="title" container="galvin-dialog-generic" placement="bottom"
                class="kt-portlet__head-title dialog-generic-header-text text-primary mw-100 text-truncate"
                [innerHtml]="title"></h3>
        </div>
        <div class="kt-portlet__head-toolbar">
            <div class="kt-portlet__head-group">
                <button ngbTooltip="{{'COMMON.CLOSE_DIALOG' | translate}}" placement="bottom" id="btn-header-close"
                    container="galvin-dialog-generic" type="button" (click)="closeDialog()"
                    class="btn btn-clean btn-sm btn-icon btn-icon-md">
                    <i class="la la-times"></i>
                </button>
            </div>
        </div>
    </div>

    <ng-container>
        <form class="form-sort-columns" [formGroup]="formGroup" novalidate>
            <mat-dialog-content>
                <div class="row">
                    <div class="col-md-4 col-sm-12 column">
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label class="text-primary">
                                {{ 'COMMON.COLUMNS' | translate}}
                            </mat-label>
                            <ng-select appendTo="body" ngSelectMat [closeOnSelect]="false" [items]="columns"
                                [multiple]="true" [searchable]="false" [selectableGroupAsModel]="false"
                                [selectableGroup]="true" formControlName="column" bindValue="id" groupBy="group"
                                [required]="true">

                                <ng-template let-index="index" let-item="item" let-item$="item$" ng-optgroup-tmp>
                                    <mat-checkbox (click)="false" [checked]="item$.selected"
                                        [attr.data-group]="item.group">
                                        {{ item.group | uppercase}}
                                    </mat-checkbox>
                                </ng-template>
                                <ng-template let-index="index" let-item="item" let-item$="item$" ng-option-tmp>
                                    <mat-checkbox (click)="false" [checked]="item$.selected">
                                        <span [ngbTooltip]="item.name" class="text-truncate">
                                            {{item.name}}
                                        </span>
                                    </mat-checkbox>
                                </ng-template>
                                <ng-template let-clear="clear" let-items="items" ng-multi-label-tmp>

                                    <div *ngFor="let item of items | slice:0:2" class="ng-value text-truncate">
                                        <span (click)="clear(item)" aria-hidden="true"
                                            class="ng-value-icon right">×</span>
                                        <span class="ng-value-label">{{$any(item).name}}</span>
                                    </div>
                                    <div *ngIf="items.length > 2" class="ng-value">
                                        <span class="ng-value-label">{{items.length - 2}} more...</span>
                                    </div>
                                </ng-template>
                            </ng-select>
                            <mat-error *ngIf="column && column.hasError('required')">
                                {{ 'GENERAL_FORM.VALIDATION.REQUIRED_FIELD' | translate }}
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="col-md-4 col-sm-12 type-order">
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label class="text-primary">
                                {{ 'COMMON.TYPE_ORDER' | translate}}
                            </mat-label>
                            <ng-select appendTo="body" ngSelectMat formControlName="typeOrder" [items]="typeOrders"
                                bindValue="id" bindLabel="displayValue" [required]="true">
                            </ng-select>
                            <mat-error *ngIf="typeOrder && typeOrder.hasError('required')">
                                {{ 'GENERAL_FORM.VALIDATION.REQUIRED_FIELD' | translate }}
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="col-md-4 col-sm-12 type-null">
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label class="text-primary">
                                {{ 'COMMON.TYPE_NULL' | translate}}
                            </mat-label>
                            <ng-select appendTo="body" ngSelectMat formControlName="typeNull" [items]="typeNulls"
                                bindValue="id" bindLabel="displayValue" [required]="true">
                            </ng-select>
                            <mat-error *ngIf="typeNull && typeNull.hasError('required')">
                                {{ 'GENERAL_FORM.VALIDATION.REQUIRED_FIELD' | translate }}
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>

            </mat-dialog-content>
            <mat-dialog-actions class="justify-content-end">
                <button mat-button class="galvin-btn-danger"
                    (click)="closeDialog()">{{'COMMON.BUTTONS.CANCEL' | translate}}</button>
                <button mat-button class="galvin-btn-success btn-sort-table" (click)="closeDialog(true)"
                    [disabled]="!formGroup.valid">{{'COMMON.BUTTONS.SORT' | translate}}</button>
            </mat-dialog-actions>
        </form>
    </ng-container>
</ng-template>