import { Pipe, PipeTransform } from '@angular/core';
import { ITableEditInlineFreezeColumns } from '../table-edit-inline.component';

@Pipe({
    name: 'getLeftPosition'
})
export class GetLeftPositionPipe implements PipeTransform {
    transform(column: number, freezeColumnList: Set<ITableEditInlineFreezeColumns>) {
        let leftPosition = 0;

        freezeColumnList.forEach((fc) => {
            if (fc.index == column) {
                leftPosition = fc.leftPosition;
            }
        });

        return leftPosition;
    }
}