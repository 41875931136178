// Angular
import { Pipe, PipeTransform } from '@angular/core';
import { DateTimeUtils } from './../../../utils/moment-utils';

@Pipe({
    name: 'parseDate'
})
export class ParseDatePipe implements PipeTransform {
    /**
     * Transform
     *
     * @param value: string
     * @param hasTimezone (default false) If the input hasn't timezone info
     */
    transform(value: string | undefined | Date, hasTimeZone = false): string {
        if (value != '--' && value !== undefined && value) {
            if (value instanceof Date) {
                return DateTimeUtils.localDateFormatFromDate(value, hasTimeZone);
            }
            return DateTimeUtils.localDateFormat(value, hasTimeZone);
        }
        return '--';
    }
}
