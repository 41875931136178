import { Component, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDrawer } from '@angular/material/sidenav';
import { GoogleAnalyticsEvents } from '@galvin/core/analytics/google-analytics-events';
import { GoogleAnalyticsService } from '@galvin/core/analytics/google-analytics.service';
import { ERolesBuildPlan } from '@galvin/core/auth/_enums/roles-build-plan.enum';
import { IGalvinUser } from '@galvin/core/auth/_interfaces/galvin-user.interface';
import { IPermissions } from '@galvin/core/auth/_interfaces/permissions.interface';
import { EConfigurationStatusLabel } from '@galvin/core/build-plan/configuration-management/enums/configuration-status-label.enum';
import { BuildPlanRequestPreviewService } from '@galvin/core/build-plan/management/services/proto-request.service';
import { IPrototypeRequestConfiguration } from '@galvin/core/build-plan/prototype-request-management/interfaces/prototype-request-configuration.interface';
import {
    IPrototypeRequestTeamSpecialRequirements,
    IPrototypeRequestTeamSpecialRequirementsValidation
} from '@galvin/core/build-plan/prototype-request-management/interfaces/prototype-request-team.interface';
import { IPrototypeRequestWithConfigurationAndTeam } from '@galvin/core/build-plan/prototype-request-management/interfaces/prototype-request-with-configuration-and-team.interface';
import { IRequestActivity } from '@galvin/core/build-plan/prototype-request-management/interfaces/request-activity.interface';
import { PrototypeRequestService } from '@galvin/core/build-plan/prototype-request-management/services/prototype-request.service';
import { UtilsFlag } from '@galvin/core/utils/utils.flag';
import { LayoutUtilsService, ToastrType } from '@galvin/core/_base/crud';
import { IDialogData } from '@galvin/core/_base/general';
import { RequestApprovalCommentDialogComponent } from '@galvin/views/partials/layout/request-details-dialog/request-approval-comment-dialog/request-approval-comment-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { EMPTY, Observable, of, Subject } from 'rxjs';
import { catchError, finalize, switchMap } from 'rxjs/operators';
import { ConsentConfirmDialog } from '../../content/general/consent-dialog-generic/consent-confirm-dialog.component';
import { SecurityService } from './../../../../core/auth/services/security.service';
import { IPrototypeRequestTeam } from './../../../../core/build-plan/prototype-request-management/interfaces/prototype-request-team.interface';
import { IDataRequestDetailsDialog } from './data-request-details-dialog.interface';
import { LocalStorageHelper } from './../../../../core/storage/local-storage-helper.service';
import { ICommentWithMentions } from '@galvin/core/comments/models/icomment-with-mentions.model';
import { IPrototypeRequestI18n } from '@galvin/core/build-plan/prototype-request-management/i18n/prototype-request-i18n.interface';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { MatOptionSelectionChange } from '@angular/material/core';
import { ERequestOperationGroup, ERequestOperationType } from '@galvin/core/build-plan/prototype-request-management/enums/requests-operation-type-enum';

@Component({
    selector: 'request-details-dialog',
    templateUrl: './request-details-dialog.component.html',
    styleUrls: ['./request-details-dialog.component.scss']
})
export class RequestDetailsDialogComponent implements OnInit {
    public devicesNumber = 0;
    public requestForm!: FormGroup;
    public justificationForm!: FormGroup;
    public protoRequest: IPrototypeRequestWithConfigurationAndTeam = {
        buildPlanInternalProductName: '',
        configuration: {},
        teamRequest: {},
        fvcSources: []
    };

    public requestPreview = false;
    public readOnly = false;

    public loadRequest = true;
    public submitted = false;
    public disableButton = true;
    public comment = '';
    public usersToNotify: string[] = [];
    public loggedUser!: string;

    public requestActivity!: IRequestActivity[];
    public loadingRequestActivity!: boolean;
    public errorLoadingActivity!: boolean;
    public isValidSpecialRequiments!: boolean;
    public fvcSources: Record<string, number> = {};
    @ViewChild('specialRequimentDrawer') specialRequimentDrawer!: MatDrawer;
    readonly requestButtonLabel: string;

    validFields: Subject<void> = new Subject<void>();
    private readonly allowedStatusToRequest = [
        EConfigurationStatusLabel.OPEN,
        EConfigurationStatusLabel.LOCKED,
        EConfigurationStatusLabel.MANUFACTURED,
        EConfigurationStatusLabel.IN_TRANSIT,
        EConfigurationStatusLabel.COMPLETED
    ];
    private readonly JUSTIFICATIONS = 'justifications';

    operationGroupFilterOptions: ERequestOperationGroup[] = [
        ERequestOperationGroup.PROTOTYPE_REQUEST,
        ERequestOperationGroup.PROTOTYPE_EDIT,
        ERequestOperationGroup.CONFIGURATION_CHANGE_STATUS,
        ERequestOperationGroup.CONFIGURATION_DELETE,
        ERequestOperationGroup.BP_TEAM_DELETE,
        ERequestOperationGroup.CONFIGURATION_CHANGE,
        ERequestOperationGroup.BP_TEAM_CHANGE
    ];

    previousFilter: string[] = [];

    readonly filterLabels: IPrototypeRequestI18n;

    readonly OPERATION = 'Operation';
    readonly ALL: string;

    formFilter: FormGroup;

    constructor(
        private dialogRef: MatDialogRef<RequestDetailsDialogComponent>,
        private fb: FormBuilder,
        private layoutUtilsService: LayoutUtilsService,
        private translate: TranslateService,
        private utilsFlag: UtilsFlag,
        private dialog: MatDialog,
        private userService: SecurityService,
        private prototypeRequestService: PrototypeRequestService,
        private bpRequestPreviewService: BuildPlanRequestPreviewService,
        private googleAnalytics: GoogleAnalyticsService,
        private localStorageHelper: LocalStorageHelper,
        private i18n: TranslateService,
        @Optional()
        @Inject(MAT_DIALOG_DATA)
        public data: IDataRequestDetailsDialog
    ) {
        this.requestButtonLabel = this.getRequestButtonLabel();

        this.filterLabels = this.i18n.instant('BUILD_PLAN.PROTOTYPE_REQUEST');
        this.ALL = this.filterLabels.HEADER.FILTERS.ALL;
        this.formFilter = new FormGroup({});
    }

    ngOnInit(): void {
        this.loggedUser = `${this.localStorageHelper?.getLoggedUser()?.name} ${this.localStorageHelper?.getLoggedUser()?.lastName || ''}`;
        this.loadRequest = true;
        this.loadingRequestActivity = true;

        this.formFilter.addControl(this.OPERATION, this.fb.control([
            ERequestOperationGroup.PROTOTYPE_REQUEST,
            ERequestOperationGroup.PROTOTYPE_EDIT,
            ERequestOperationGroup.CONFIGURATION_CHANGE_STATUS,
            ERequestOperationGroup.CONFIGURATION_DELETE,
            ERequestOperationGroup.BP_TEAM_DELETE
        ]));

        if ('idConfigurationTeamProtoRequest' in this.data) {
            this.loadDataToProtoRequest();
            if('readOnly' in this.data){
                this.readOnly = true;
            }
        } else {
            this.loadDataToRequestPreview();
        }
    }

    get teamRequest(): IPrototypeRequestTeam {
        return this.protoRequest.teamRequest ? this.protoRequest.teamRequest : {};
    }

    get configuration(): IPrototypeRequestConfiguration {
        return this.protoRequest.configuration ? this.protoRequest.configuration : {};
    }

    subscribeForm(): void {
        this.requestForm.valueChanges.subscribe((rest) => {
            if (rest.quantity < 0) {
                this.requestForm.controls['quantity'].setValue(0);
            }
            if (rest.quantity > 10000) {
                this.requestForm.controls['quantity'].setValue(10000);
            }
            if (rest.activationPercentage && rest.activationPercentage < 0) {
                this.requestForm.controls['activationPercentage'].setValue(0);
            }
            if (rest.activationPercentage && rest.activationPercentage > 100) {
                this.requestForm.controls['activationPercentage'].setValue(100);
            }
            if (this.requestForm.value.allDevicesToBeActivated) {
                this.devicesNumber = this.requestForm.controls['quantity'].value;
            } else {
                this.devicesNumber = Math.round(
                    (this.requestForm.controls['quantity'].value *
                        this.requestForm.controls['activationPercentage'].value) /
                        100
                );
            }

            this.disableButton =
                !this.requestForm.valid ||
                !this.hasChanges(this.requestForm.getRawValue()) ||
                !this.configurationHasAllowedStatus();
        });
    }

    allDevicesCheckBox(): void {
        if (this.requestForm.value.allDevicesToBeActivated) {
            this.requestForm.controls['activationPercentage'].setValue(100);
        }
    }

    changeActiveDevices(): void {
        this.requestForm.value.allDevicesToBeActivated =
            !this.requestForm.value.allDevicesToBeActivated;
        this.allDevicesCheckBox();
    }

    close(): void {
        if (!this.submitted) {
            this.dialogRef.close();
        }
    }

    formBuilder(): void {
        this.comment = this.protoRequest.teamRequest?.additionalComments || '';
        this.usersToNotify = this.protoRequest.teamRequest?.usersMentionsNotification || [];
        const isSpecialRequirement =
            this.protoRequest.teamRequest?.specialRequirementsList?.length == undefined
                ? false
                : this.protoRequest.teamRequest?.specialRequirementsList?.length > 0;
        this.requestForm = this.fb.group({
            quantity: [{value:this.protoRequest.teamRequest?.quantity || 0, disabled: this.readOnly},Validators.required],
            activationPercentage: [
                {value:this.protoRequest.teamRequest?.activationPercentage || 0,disabled: this.readOnly},
                Validators.required
            ],
            justifications: new FormGroup({
                CARRIERS: new FormControl(
                    {value:this.protoRequest.teamRequest?.justifications?.Carriers || false, disabled: this.readOnly}
                ),
                MARKETING: new FormControl(
                    {value:this.protoRequest.teamRequest?.justifications?.Marketing || false, disabled: this.readOnly}
                ),
                SHOW_OFF: new FormControl(
                    {value:this.protoRequest.teamRequest?.justifications?.['Show Off'] || false, disabled: this.readOnly}
                ),
                MANUAL_TESTING: new FormControl(
                    {value:this.protoRequest.teamRequest?.justifications?.['Manual Testing'] || false, disabled: this.readOnly}
                ),
                AUTOMATED_TESTING: new FormControl(
                    {value:this.protoRequest.teamRequest?.justifications?.['Automated Testing'] || false, disabled: this.readOnly}
                ),
                OTHER: new FormControl(
                    {value:this.protoRequest.teamRequest?.justifications?.Other || false, disabled: this.readOnly}
                )
            }),
            isNewHWSpec: [{value:this.protoRequest.teamRequest?.isNewHWSpec || false, disabled: this.readOnly}],
            isSpecialRequirement: [{value:isSpecialRequirement, disabled: this.readOnly}],
            allDevicesToBeActivated: [{value:this.protoRequest.teamRequest?.allDevicesToBeActivated, disabled: this.readOnly}],
            status: [this.protoRequest.teamRequest?.status || 'Mock 1 st pass'],
            specialRequirementsList: [this.protoRequest.teamRequest?.specialRequirementsList || []]
        });

        this.justificationForm = this.requestForm.get(this.JUSTIFICATIONS) as FormGroup;
        this.devicesNumber = Math.round(
            (this.requestForm.controls['quantity'].value *
                this.requestForm.controls['activationPercentage'].value) /
                100
        );

        if (this.protoRequest.teamRequest) {
            this.protoRequest.teamRequest.isSpecialRequirement = <boolean>(
                this.requestForm.controls['isSpecialRequirement'].value
            );
        }

        this.requestForm.controls['isSpecialRequirement'].valueChanges.subscribe((value) => {
            if (this.submitted) {
                return;
            }

            if (value) {
                this.specialRequimentDrawer.open();
            } else {
                this.specialRequimentDrawer.close();
            }
        });
    }

    isValid(formControlName: string, firstColor?: string, secondColor?: string): string {
        if (firstColor && secondColor) {
            return this.requestForm.controls[formControlName].invalid ? firstColor : secondColor;
        }
        return this.requestForm.controls[formControlName].invalid ? 'red' : '#e2e5ec';
    }

    checkIfExist(formControlName: string): unknown {
        return this.requestForm.controls[formControlName].value ? '#f7f8fa' : '#FFF';
    }

    checkValidForm(): boolean {
        return !this.submitted && this.requestForm.valid;
    }

    submit(): void {
        const first = this.protoRequest.teamRequest?.requestOwner;
        const final = this.protoRequest.teamRequest?.finalRecipient;
        const user = this.userService.getLocalStorageUser();

        if (first && first.email != user?.email && final && final.email != user?.email) {
            const confirmTitle = this.translate.instant(
                'BUILD_PLAN.PROTOTYPE_REQUEST.DIALOG.LABELS.CONSENT_CONFIRM_REQUEST_TITLE'
            );
            const confirmContent = this.translate.instant(
                'BUILD_PLAN.PROTOTYPE_REQUEST.DIALOG.LABELS.CONSENT_CONFIRM_REQUEST_TEAM_MESSAGE'
            );
            const listData: string = this.protoRequest.teamRequest?.teamAlias
                ? this.protoRequest.teamRequest.teamAlias
                : '';

            const dialog: MatDialogRef<ConsentConfirmDialog> = <MatDialogRef<ConsentConfirmDialog>>(
                this.layoutUtilsService.consentDialogConfirmation(
                    confirmTitle,
                    confirmContent,
                    [listData],
                    ConsentConfirmDialog,
                    'galvin-btn-success'
                )
            );

            dialog.componentInstance.isConfirm$.subscribe(() => {
                dialog.close();
                this.makeRequest();
            });
        } else {
            this.makeRequest();
        }
    }

    makeRequest(): void {
        this.submitted = true;
        this.requestForm.disable();

        const specialRequirementsListAux: IPrototypeRequestTeamSpecialRequirements[] =
            this.requestForm.controls['specialRequirementsList'].value;

        if (this.isSpecialRequirementChecked()) {
            this.validFields.next();
            if (!this.isValidSpecialRequiments) {
                this.specialRequimentDrawer.open();
                this.submitted = false;
                this.requestForm.enable();
                return;
            }
        } else {
            this.requestForm.controls['specialRequirementsList'].setValue([]);
        }
        let request: Observable<IPrototypeRequestWithConfigurationAndTeam>;

        if (this.isOpen() || this.hasPermittedRole(this.data.userPermission)) {
            const bodyRequestPrototype =  this.requestForm.value as IPrototypeRequestTeam;
            bodyRequestPrototype.additionalComments = this.comment;
            bodyRequestPrototype.usersMentionsNotification = this.usersToNotify;
            request = this.prototypeRequestService.sendRequestPrototype(
                this.data.configurationId ? this.data.configurationId : 0,
                this.data.teamId ? this.data.teamId : 0,
                bodyRequestPrototype
            );

            this.googleAnalytics.sendEvent(
                GoogleAnalyticsEvents.BUILD_PLAN.PROTOTYPE_REQUEST.CATEGORY,
                GoogleAnalyticsEvents.BUILD_PLAN.PROTOTYPE_REQUEST.SUBMIT_REQUEST.ACTION,
                GoogleAnalyticsEvents.BUILD_PLAN.PROTOTYPE_REQUEST.SUBMIT_REQUEST.LABEL
            );
        } else {
            const title = this.translate.instant(
                'BUILD_PLAN.PROTOTYPE_REQUEST.DIALOG.REQUEST_BY_APPROVAL.TITLE'
            );
            request = this.dialog
                .open<RequestApprovalCommentDialogComponent, IDialogData>(
                    RequestApprovalCommentDialogComponent,
                    {
                        panelClass: 'custom-dialog',
                        width: '450px',
                        disableClose: true,
                        hasBackdrop: true,
                        data: {
                            title,
                            cancelButton: {
                                name: 'cancel',
                                value: this.translate.instant('COMMON.BUTTONS.CANCEL')
                            },
                            saveOrEditButton: {
                                name: 'save',
                                value: this.translate.instant(
                                    'BUILD_PLAN.PROTOTYPE_REQUEST.DIALOG.REQUEST_BY_APPROVAL.SAVE'
                                )
                            },
                            enabled: true
                        }
                    }
                )
                .afterClosed()
                .pipe(
                    switchMap((data) => {
                        if (data) {
                            this.googleAnalytics.sendEvent(
                                GoogleAnalyticsEvents.BUILD_PLAN.PROTOTYPE_REQUEST_BY_APPROVAL
                                    .CATEGORY,
                                GoogleAnalyticsEvents.BUILD_PLAN.PROTOTYPE_REQUEST_BY_APPROVAL
                                    .SUBMIT_REQUEST.ACTION,
                                GoogleAnalyticsEvents.BUILD_PLAN.PROTOTYPE_REQUEST_BY_APPROVAL
                                    .SUBMIT_REQUEST.LABEL
                            );
                            const approvalRequest = {
                                ...this.requestForm.value,
                                ...data
                            };
                            approvalRequest.buildPlanTeamAssociationId = this.data.teamId;
                            approvalRequest.configurationId = this.data.configurationId;
                            approvalRequest.specialRequirementsApprovalList =
                                specialRequirementsListAux.map((value) => ({
                                    specialRequirementId: value.specialRequirement.id,
                                    specialRequirementsValueId: value.specialRequirementsValue.id
                                }));
                            delete approvalRequest.specialRequirementsList;
                            delete approvalRequest.status;
                            delete approvalRequest.isSpecialRequirement;
                            return this.prototypeRequestService
                                .sendRequestPrototypeApproval(
                                    this.data.buildPlanId ? this.data.buildPlanId : 0,
                                    approvalRequest
                                )
                                .toPromise()
                                .then(() => approvalRequest);
                        }
                        this.submitted = false;
                        this.requestForm.enable();
                        return EMPTY;
                    })
                );
        }

        request.subscribe(
            (res) => {
                this.dialogRef.close(res);
            },
            () => {
                this.requestForm.controls['specialRequirementsList'].setValue(
                    specialRequirementsListAux
                );
                this.submitted = false;
                this.requestForm.enable();
                this.layoutUtilsService.showToastrNotification(
                    this.translate.instant('COMMON.ERROR_REQUEST'),
                    ToastrType.error
                );
            }
        );
    }

    /**
     * Checks if there's any activity information to show
     *
     * @return true if there is activity information, false otherwise
     */
    hasActivityInformation(): boolean {
        return this.requestActivity && this.requestActivity.length > 0 && !this.loadingRequestActivity;
    }

    getTooltipRequestButton(): string {
        if (!this.disableButton) {
            return this.requestButtonLabel;
        }

        if (this.isNotChangeForm()) {
            return this.translate.instant('BUILD_PLAN.PROTOTYPE_REQUEST.DIALOG.LABELS.NO_CHANGES');
        }

        return this.translate.instant(
            'BUILD_PLAN.PROTOTYPE_REQUEST.DIALOG.LABELS.TOOLTIP_REQUEST_BUTTON',
            { configStatus: this.data.configurationStatus }
        );
    }

    isNotChangeForm(): boolean {
        return !this.requestForm.valid || !this.hasChanges(this.requestForm.getRawValue());
    }

    isSpecialRequirementChecked(): boolean {
        return this.requestForm.controls['isSpecialRequirement'].value;
    }

    specialRequirementsChanges(
        specialRequirements: IPrototypeRequestTeamSpecialRequirementsValidation
    ): void {
        this.isValidSpecialRequiments = specialRequirements.valid;
        this.requestForm.controls['specialRequirementsList'].setValue(specialRequirements.specials);
    }

    getFlag(country: string): string {
        return `mr-2 fi fis ${this.utilsFlag.getFlag(country).class}`;
    }

    getSourceColor(col: any, item: any): number {
        if (col && col.id && item && item.id) {
            const valid = this.fvcSources[col.id + '_' + item.id];
            if (valid) {
                return valid;
            }
        }
        return 0;
    }

    getRecipientInfo(recipient: IGalvinUser): string {
        return String(recipient.name + ' ' + recipient.lastName);
    }

    isOpen(): boolean {
        return this.data.configurationStatus === EConfigurationStatusLabel.OPEN;
    }

    isRequestApproval(): boolean {
        return (
            this.configurationHasAllowedStatus() &&
            !this.isOpen() &&
            !this.hasPermittedRole(this.data.userPermission)
        );
    }

    applyComments(commentWithMentions: ICommentWithMentions): void {
        this.comment = commentWithMentions.commentText;
        this.usersToNotify = commentWithMentions.usersToNotify;
        this.disableButton = false;
    }

    private getRequestButtonLabel(): string {
        let requestLabel = 'BUILD_PLAN.PROTOTYPE_REQUEST.DIALOG.LABELS.REQUEST';
        if (this.isRequestApproval()) {
            requestLabel = 'BUILD_PLAN.PROTOTYPE_REQUEST.DIALOG.LABELS.REQUEST_APPROVAL';
        }
        return this.translate.instant(requestLabel);
    }

    /**
     * Load the information from request preview
     */
    private loadDataToRequestPreview(): void {
        this.requestPreview = true;
        const buildPlanId = this.data.buildPlanId ? this.data.buildPlanId : 0;
        const configurationId = this.data.configurationId ? this.data.configurationId : 0;
        this.bpRequestPreviewService
            .getConfigurationInformation(buildPlanId, configurationId)
            .pipe(
                catchError(() => {
                    this.layoutUtilsService.showToastrNotification(
                        this.translate.instant('COMMON.UNKNOWN_ERROR'),
                        ToastrType.error
                    );
                    this.close();
                    return of(null);
                }),
                finalize(() => {
                    this.loadRequest = false;
                })
            )
            .subscribe((data: IPrototypeRequestConfiguration) => {
                this.protoRequest.configuration = data;
                this.protoRequest.buildPlanInternalProductName = this.data.buildPlanName;
            });
    }

    /**
     * Checks if the user made changes to any of the original form values
     *
     * @param form current form
     * @return true if form has changes, false otherwise
     */
    private hasChanges(form: any): boolean {
        let hasChanges = false;
        Object.keys(form).some((item: any) => {
            const index: keyof IPrototypeRequestTeam = item;
            const data = this.protoRequest.teamRequest
                ? this.protoRequest.teamRequest[index]
                : null;
            if (!form[item] && !data) {
                return false;
            }
            if (item === this.JUSTIFICATIONS) {
                Object.keys(form[item]).some((justification) => {
                    if (form[item][justification] != [data]) {
                        hasChanges = true;
                    }
                    return hasChanges;
                });
            } else if (form[item] != data) {
                hasChanges = true;
            }
            return hasChanges;
        });
        return hasChanges;
    }

    /**
     * Load the information from prototype requests.
     */
    private loadDataToProtoRequest(): void {
        const configId = this.data.configurationId ? this.data.configurationId : 0;
        const teamId = this.data.teamId ? this.data.teamId : 0;
        this.prototypeRequestService
            .getConfigurationsAndProtoRequestForATeam(configId, teamId)
            .pipe(
                catchError(() => {
                    this.layoutUtilsService.showToastrNotification(
                        this.translate.instant('COMMON.UNKNOWN_ERROR'),
                        ToastrType.error
                    );
                    this.close();
                    return of(null);
                })
            )
            .subscribe(
                (res: IPrototypeRequestWithConfigurationAndTeam | null) => {
                    this.protoRequest = {
                        buildPlanInternalProductName: '',
                        configuration: {},
                        teamRequest: {},
                        fvcSources: []
                    };
                    if (res) {
                        this.protoRequest = res;
                    }
                    this.formBuilder();
                    this.allDevicesCheckBox();
                    this.subscribeForm();
                    this.loadRequest = false;
                },
                () => {
                    this.formBuilder();
                    this.subscribeForm();
                    this.loadRequest = false;
                }
            );

        this.loadActivity(configId, teamId, this.defaultFilter());
    }

    private loadActivity(configId: number, teamId: number, filter: string[]){
        this.loadingRequestActivity = true;
        this.prototypeRequestService
            .getActivityInformationForConfigurationAndTeam(configId, teamId, filter)
            .pipe(
                catchError(() => {
                    return of(null);
                })
            )
            .subscribe(
                (res: IRequestActivity[] | null) => {
                    this.requestActivity = res ? res : [];
                    this.loadingRequestActivity = false;
                },
                () => {
                    this.errorLoadingActivity = true;
                }
            );
        this.previousFilter = filter;
    }


    private defaultFilter(): string[]{
        let defaultFilter = new FormGroup({});
        defaultFilter.addControl('Operation', this.fb.control([
            ERequestOperationGroup.PROTOTYPE_REQUEST,
            ERequestOperationGroup.PROTOTYPE_EDIT,
            ERequestOperationGroup.CONFIGURATION_CHANGE_STATUS,
            ERequestOperationGroup.CONFIGURATION_DELETE,
            ERequestOperationGroup.BP_TEAM_DELETE
        ]));
        return this.formatFilter(defaultFilter);
    }

    filterHasChanged(filter: string[]): boolean {
        if (filter.length !== this.previousFilter.length) {
            return true;
        }
        return !filter.every(op => this.previousFilter.includes(op));
    }

    onFilterChange(){
        const configId = this.data.configurationId ? this.data.configurationId : 0;
        const teamId = this.data.teamId ? this.data.teamId : 0;
        let filter = this.formatFilter(this.formFilter);
        if(this.filterHasChanged(filter)){
            this.loadActivity(configId, teamId, filter);
        }
    }

    private formatFilter(filter: FormGroup): string[]{
        const groupOperation: Record<string, string[]> = {
            [ERequestOperationGroup.PROTOTYPE_REQUEST]: [
                ERequestOperationType.INSERT
            ],
            [ERequestOperationGroup.PROTOTYPE_EDIT]: [
                ERequestOperationType.EDIT
            ],
            [ERequestOperationGroup.CONFIGURATION_CHANGE_STATUS]: [
                ERequestOperationType.CONFIGURATION_EDIT,
                ERequestOperationType.SPECIAL_REQUIREMENTS_PROPAGATE,
                //This is particular case to apply as diferent filter. Reason: Not exists specific log to change status
                ERequestOperationGroup.CONFIGURATION_CHANGE_STATUS
            ],
            [ERequestOperationGroup.CONFIGURATION_CHANGE]: [
                ERequestOperationType.CONFIGURATION_EDIT,
                ERequestOperationType.CONFIGURATION_EDIT_MASSIVE,
                ERequestOperationType.CONFIGURATION_FVC_DELETE,
                ERequestOperationType.CONFIGURATION_PROPAGATE,
            ],
            [ERequestOperationGroup.CONFIGURATION_DELETE]: [
                ERequestOperationType.CONFIGURATION_DELETE
            ],
            [ERequestOperationGroup.BP_TEAM_CHANGE]: [
                ERequestOperationType.BP_TEAM_EDIT,
                ERequestOperationType.BP_TEAM_LABELS,
                ERequestOperationType.BP_TEAM_PROPAGATE,
            ],
            [ERequestOperationGroup.BP_TEAM_DELETE]: [
                ERequestOperationType.BP_TEAM_DELETE
            ],
        };

        let operations: string[] = [];

        this.operationGroupFilterOptions.forEach(operation => {
            if (filter.value['Operation'].includes(operation)) {
                operations.push(...groupOperation[operation]);
            }
        });
        return operations;
    }


    private configurationHasAllowedStatus() {
        const configurationStatus = this.data.configurationStatus;
        return this.allowedStatusToRequest.some((item) => configurationStatus === item);
    }

    private hasPermittedRole(userRoles: IPermissions | undefined) {
        if (!userRoles) {
            return false;
        }

        const allowedRoles = [
            ERolesBuildPlan.BP_GPM,
            ERolesBuildPlan.BP_HW_PM,
            ERolesBuildPlan.BP_ADMIN
        ];
        return (
            userRoles.superUser ||
            allowedRoles.some((item) => userRoles.rolesBuildPlan.includes(item))
        );
    }

    getOperationLabel(operation: ERequestOperationGroup): string {
        return this.filterLabels.HEADER.FILTERS[operation];
    }

    onOperationSelected(event: MatSelectChange): void {
        this.formFilter.get(this.OPERATION)?.setValue(event.value);
    }

    onSelectOptionAll($event: MatOptionSelectionChange, selector: MatSelect): void {
        if ($event.source.selected) {
            selector.options.forEach((opt) => {
                if (opt.getLabel() !== this.ALL) {
                    opt.deselect();
                }
            });
            $event.source.select();
        }
    }
    hasNoActivity(){
        return this.requestActivity && !this.requestActivity.length && !this.loadingRequestActivity;
    }
}
