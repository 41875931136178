import {Pipe, PipeTransform} from '@angular/core';
import {IMyPrototypeConfiguration} from '@galvin/core/build-plan/prototype-request-management';

@Pipe({
    name: 'filterMyPrototypeConfiguration'
})
export class FilterMyPrototypeConfigurationPipe implements PipeTransform {

    transform(
        value: IMyPrototypeConfiguration[],
        filterFn: (v: IMyPrototypeConfiguration[]) => IMyPrototypeConfiguration[],
        additionalCondition = true
    ): unknown[] {
        if (value && additionalCondition) {
            return filterFn.apply(this, [value]);
        }
        return value;
    }

}
