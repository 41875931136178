import { COMMA, ENTER, SPACE } from '@angular/cdk/keycodes';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GalvinDialogGenericService, IDialogData } from '@galvin/core/_base/general';
import { GenericConfig } from '@galvin/views/partials/content/general/galvin-dialog-generic';

@Component({
    selector: 'request-approval-comment-dialog',
    templateUrl: './request-approval-comment-dialog.component.html',
    styleUrls: ['./request-approval-comment-dialog.component.scss']
})
export class RequestApprovalCommentDialogComponent
    extends GenericConfig<RequestApprovalCommentDialogComponent>
    implements OnInit
{
    viewLoading = false;
    readonly crs: Set<string> = new Set<string>();
    readonly separatorKeysCodes = [ENTER, COMMA, SPACE];
    readonly form: FormGroup;
    readonly ISSUE_PATTERN = /[A-Z]{2,}-\d+/;

    constructor(
        private fb: FormBuilder,
        private dialogGenericService: GalvinDialogGenericService,
        public override dialogRef: MatDialogRef<RequestApprovalCommentDialogComponent>,
        dialogService: GalvinDialogGenericService,
        @Inject(MAT_DIALOG_DATA)
        public override data: IDialogData
    ) {
        super(dialogService, dialogRef, data);
        this.form = this.buildForm();
    }

    addCR(event: MatChipInputEvent): void {
        const input = event.input;
        const value = (event.value || '').trim();

        if (value && this.ISSUE_PATTERN.test(value)) {
            this.crs.add(value.trim());
            if (input) {
                input.value = '';
            }
        }
    }

    cancel(): any {
        this.dialogRef.close();
    }

    custom(): any {}

    edit(): any {}

    save(): any {
        if (this.form.valid) {
            const data = this.form.getRawValue();
            data.crs = Array.from(this.crs);
            delete data.cr;
            this.dialogRef.close(data);
        }
    }

    ngOnInit() {
        this.dialogGenericService.buttonEnabled = false;
        this.unsubClose.add(
            this.form.valueChanges.subscribe(
                () =>
                    (this.dialogGenericService.buttonEnabled = this.form.valid)
            )
        );
    }

    removeCR(cr: string) {
        this.crs.delete(cr);
    }

    private buildForm(): FormGroup {
        return this.fb.group({
            reason: ['', [Validators.required]],
            crs: this.fb.array([]),
            cr: ['', [Validators.pattern(this.ISSUE_PATTERN)]]
        });
    }
}
