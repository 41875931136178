import { ERolesBuildPlan } from './../../../auth/_enums/roles-build-plan.enum';
import { UserPermissionsService } from './../../../auth/services/user-permissions.service';
import { Directive, HostBinding, OnInit, Input } from '@angular/core';

@Directive({
    selector: '[ktVisibleTo]',
    exportAs: 'ktVisibleTo'
})
export class VisibleToDirective implements OnInit {
    constructor(private userPermissionsService: UserPermissionsService) {}

    @HostBinding('style.visibility')
        visibility!: 'initial' | 'hidden';

    @HostBinding('style.display')
        display!: 'initial' | 'none';

    @Input()
        ktVisibleTo: ERolesBuildPlan[] = [];

    @Input()
        ktTakeSpaceOff = false;

    ngOnInit() {
        if (!this.userPermissionsService.verifyPermissionsForBuildPlan(this.ktVisibleTo)) {
            if (this.ktTakeSpaceOff) {
                this.display = 'none';
            } else {
                this.visibility = 'hidden';
            }
        }
    }
}
