<title-expanded [title]="'BUILD_PLAN.CONFIGURATION.COMMON.DATES_RELATIVE'| translate" [expanded]="expanded"
    (expandedOut)="expanded = $event">
</title-expanded>

<div class="d-flex flex-column mr-4" id="dates-relative-container"
    [@detailExpand]="expanded  ? 'expanded' : 'collapsed'">
    <ul class="p-0 d-flex flex-column flex-wrap h-100 mt-4 justify-content-center">
        <li class="d-flex justify-content-between align-items-center mb-3 mr-3" [style.width.px]="210"
            *ngFor="let date of dates">
            <span container="galvin-dialog-generic" [ngbTooltip]="date.parameterName"
                class="text-default text-normal property-text">
                {{date.parameterName}} </span>
            <div container="galvin-dialog-generic" [ngbTooltip]="$any(date.value) | parseChineseDate"
                class="d-flex justify-content-center align-items-center tag-prototypes color-dark-gray width-min-width-36-perc">
                <span class="text-normal color-dark-gray">
                    {{ $any(date.value) | parseDate }}
                </span>
            </div>
        </li>
    </ul>
</div>