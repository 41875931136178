import {Directive, ElementRef} from '@angular/core';

@Directive({
    selector: '[groupRow]',
    exportAs: 'groupRow'
})
export class GroupRowDirective {

    constructor(private el: ElementRef) {
    }

    get height(): number {
        return this.el.nativeElement.offsetHeight || 0;
    }
}
