<div class="suggest-alias-container" >
    <div mat-dialog-title class="d-flex justify-content-between suggest-title">
        <div class="kt-portlet__head-label mw-100">
            <h3 container="galvin-dialog-generic" placement="bottom"
                class="kt-portlet__head-title dialog-generic-header-text text-primary mw-100 text-truncate"
                [innerHtml]="title"></h3>
        </div>
        <div class="kt-portlet__head-toolbar">
            <div class="kt-portlet__head-group">
                <button ngbTooltip="{{'COMMON.CLOSE_DIALOG' | translate}}" placement="bottom" id="btn-header-close"
                    container="galvin-dialog-generic" type="button" (click)="closeDialog()"
                    class="btn btn-clean btn-sm btn-icon btn-icon-md">
                    <i class="la la-times"></i>
                </button>
            </div>
        </div>
    </div>
    <ng-container>
        <mat-dialog-content>
            <mat-table [dataSource]="this.dataSource" class="mat-elevation-z0 mr-2 content-table" tabindex="0" placement="bottom">
                <ng-container matColumnDef="select">
                    <th mat-header-cell *matHeaderCellDef class="table-edit-inline-header-cell-check">
                        <mat-checkbox (change)="$event ? masterConfigToggleCheckBox($event.checked) : null"
                            [checked]="selectionConfigurationRow.hasValue() && isConfigurationsAllSelected()"
                            [indeterminate]="selectionConfigurationRow.hasValue() && !isConfigurationsAllSelected()">
                        </mat-checkbox>
                    </th>
                    <td mat-cell *matCellDef="let row" class="table-edit-inline-cell-check ng-star-inserted">
                        <mat-checkbox (click)="$event.stopPropagation()"
                            (change)="selectConfigurationId(row.rowID)"
                            [checked]="selectionConfigurationRow.isSelected(row.rowID)">
                        </mat-checkbox>
                    </td>
                </ng-container>

                <ng-container matColumnDef="rowId" >
                    <th mat-header-cell *matHeaderCellDef class="table-edit-inline-header-cell-code">
                        <strong>{{ labels.SUGGEST_ALIAS.CONFIGURATION_ID }}</strong>
                    </th>
                    <td mat-cell *matCellDef="let element" class="table-edit-inline-cell-code ng-star-inserted">
                        <div>
                            {{ element.rowID }}
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="currentDataFromAlias" >
                    <th mat-header-cell *matHeaderCellDef class="table-edit-inline-header-cell">
                        <strong>{{ labels.SUGGEST_ALIAS.CURRENT_CONFIGURATION }}</strong>
                    </th>
                    <td mat-cell *matCellDef="let element" class="table-edit-inline-cell-text ng-star-inserted">
                        <span class="alias-name">
                            {{ element.currentDataFromAlias }}
                        </span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="dataToSuggest" >
                    <th mat-header-cell *matHeaderCellDef class="table-edit-inline-header-cell">
                        <strong>{{ labels.SUGGEST_ALIAS.SUGGESTED_CONFIGURATION }}</strong>
                    </th>
                    <td mat-cell *matCellDef="let element" class="table-edit-inline-cell-text ng-star-inserted suggest-alias-column">
                        <input required type="text" maxlength="255" class="form-control suggest-field" [(ngModel)]="element.dataToSuggest"/>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </mat-table>
        </mat-dialog-content>
    </ng-container>

    <mat-dialog-actions class="justify-content-end">
        <button mat-dialog-close mat-raised-button color="warn" (click)="closeDialog()" class="close-button">
            {{'COMMON.BUTTONS.CANCEL' | translate }}</button>
        <button color="accent" accent mat-raised-button [disabled]="!hasItemSelected() || hasEmptyItem()" (click)="onButtonUpdateAll()" class="update-button">
            {{'COMMON.BUTTONS.CONTINUE' | translate}}</button>
    </mat-dialog-actions>
</div>