export enum EBuildPlanCopyOptions {
    TEAM = 'Teams',
    RELATIVE_DATE = 'Main Product Data - Relative Dates',
    CONFIGURATION = 'Configuration Data',
    CONFIGURATION_FVC_VALUE = 'Configuration Data - FVC Values',
    CONFIGURATION_DATE = 'Configuration Data - Dates',
    CONFIGURATION_RELATIVE_DATE = 'Configuration Data - Relative Dates',
    PROTOTYPE_REQUEST = 'Prototype Requests',
    SPECIAL_REQUIREMENTS = 'Special Requirements',
    SCOPE = 'Scopes Data',
    ACCESSORY = 'Accessory Data',
    ACCESSORY_PARAMETERS = 'Accessory Parameters - Values',
    ACCESSORY_REQUEST = 'Accessory Requests',
}
