/* eslint-disable @angular-eslint/component-class-suffix */
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { ConsentDialogData } from './../../../../../core/_base/crud/utils/layout-utils.service';

@Component({
    selector: 'consent-confirm-dialog',
    templateUrl: './consent-confirm-dialog.component.html',
    styleUrls: ['./consent-confirm-dialog.component.scss']
})
export class ConsentConfirmDialog {
    public isLoading = false;
    public isExecuting = false;
    public readonly isConfirm$: Subject<void>;

    constructor(
        private dialogRef: MatDialogRef<ConsentConfirmDialog>,
        @Inject(MAT_DIALOG_DATA) public data: ConsentDialogData
    ) {
        if (!data.buttonClass) {
            data.buttonClass = 'galvin-btn-danger';
        }
        this.isConfirm$ = new Subject();
    }
    closeDialog(): void {
        this.dialogRef.close();
    }
}
