// Angular
import { NgModule } from '@angular/core';
import { RouteReuseStrategy, RouterModule, Routes } from '@angular/router';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';

// Components
import { BaseComponent } from './views/theme/base/base.component';
import { BaseMinComponent } from './views/theme/base-min/base-min.component';

// Auth
import { AuthGuard } from './core/auth';
import { CustomReuseStrategy } from './core/build-plan/prototype-request-management/services/custom-reuse-strategy.service';

const routes: Routes = [
    {
        path: 'login',
        loadChildren: () => import('./views/pages/auth/auth.module').then((m) => m.AuthModule)
    },
    {
        path: '',
        canActivateChild: [AuthGuard],
        children: [
            {
                path: '',
                component: BaseMinComponent,
                canActivateChild: [AuthGuard],
                children: [
                    {
                        path: '',
                        loadChildren: () =>
                            import('./views/pages/home/home.module').then((m) => m.HomeModule)
                    }
                ]
            },
            {
                path: 'build-plan',
                canActivateChild: [AuthGuard],
                component: BaseComponent,
                loadChildren: () =>
                    import('./views/pages/build-plan/build-plan.module').then(
                        (m) => m.BuildPlanModule
                    )
            },
            {
                path: 'cdc',
                canActivateChild: [AuthGuard],
                component: BaseComponent,
                loadChildren: () => import('./views/pages/cdc/cdc.module').then((m) => m.CDCModule)
            },
            {
                path: 'finance',
                canActivateChild: [AuthGuard],
                component: BaseComponent,
                loadChildren: () => import('./views/pages/finance/finance.module').then((m) => m.FinanceModule)
            },
            { path: '**', redirectTo: '', pathMatch: 'full' }
        ]
    },
    { path: '**', redirectTo: '', pathMatch: 'full' }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            onSameUrlNavigation: 'reload',
            relativeLinkResolution: 'legacy'
        })
    ],
    exports: [RouterModule],
    providers: [
        { provide: LocationStrategy, useClass: HashLocationStrategy },
        { provide: RouteReuseStrategy, useClass: CustomReuseStrategy }
    ]
})
export class AppRoutingModule {}
