/* eslint-disable @angular-eslint/no-input-rename */
import { SelectionModel } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import {
    Component,
    ContentChildren,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    QueryList,
    SimpleChanges,
    TemplateRef
} from '@angular/core';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { GalvinTreeNodeTmpDirective } from '@galvin/views/partials/content/general/galvin-tree/galvin-tree-node-tmp.directive';
import { TodoItemFlatNode } from '@galvin/views/partials/content/general/galvin-tree/todo-item-flat-node.interface';
import { GalvinTreeNodeTooltipTmpDirective } from './galvin-tree-node-tooltip-tmp.directive';
@Component({
    selector: 'galvin-tree',
    templateUrl: './galvin-tree.component.html',
    styleUrls: ['./galvin-tree.component.scss']
})
export class GalvinTreeComponent<T, V> implements OnChanges {
    treeControl = new FlatTreeControl<TodoItemFlatNode<V>>(
        (node) => node.level,
        (node) => node.expandable
    );
    /** The selection for checklist */
    checklistSelection = new SelectionModel<TodoItemFlatNode<V>>(true);
    dataSource!: MatTreeFlatDataSource<T, TodoItemFlatNode<V>>;
    treeFlattener!: MatTreeFlattener<T, TodoItemFlatNode<V>>;
    /**
     * @description Function to build a TodoItemFlatNode<V> with node and level
     * @Param node to transformer a node in TodoItemFlatNode<V>
     * @Param level deep level node
     */
    @Input('transformer') transformerFn!: (node: T, level: number) => TodoItemFlatNode<V>;
    @Input() style!: string;
    /**
     * @description Return a sub level nodes if exist
     * @Param node
     */
    @Input('getChildren') getChildrenFn!: (node: T) => T[];
    @Input() data!: T[];
    @Output() nodesSelected = new EventEmitter();
    @ContentChildren(GalvinTreeNodeTmpDirective)
        contentNodes!: QueryList<GalvinTreeNodeTmpDirective>;
    @ContentChildren(GalvinTreeNodeTooltipTmpDirective)
        contentTooltipNodes!: QueryList<GalvinTreeNodeTooltipTmpDirective>;
    constructor() {
        this.buildTree();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['data'].currentValue != changes['data'].previousValue) {
            const result = this.builderTreeObject(changes['data'].currentValue);
            this.nodesSelected.emit(result);
            this.dataSource.data = changes['data'].currentValue;
            this.treeControl.dataNodes.forEach((dataNode) => {
                if (!this.checklistSelection.isSelected(dataNode)) {
                    this.checklistSelection.toggle(dataNode);
                }
            });
        }
    }
    getLevel = (node: TodoItemFlatNode<V>): number => node.level;
    isExpandable = (node: TodoItemFlatNode<V>): boolean => node.expandable;
    hasChild = (_: number, node: TodoItemFlatNode<V>): boolean => node.expandable;
    getNodeTemplate(template: TemplateRef<any>, level: number): TemplateRef<any> {
        const find = this.getTemplateByLevel(level);
        return find ? find.templateRef : template;
    }
    getNodeTooltip(level: number): TemplateRef<any> | null {
        const find = this.getTooltipByLevel(level);
        return find ? find.templateRef : null;
    }
    builderTreeObject(value: T[]): { [k: string]: string } {
        const result: any = {};
        Object.entries(value).forEach(([key, checked]) => {
            if (checked) {
                const [k, l, g] = key.split('-');
                const keyConst = `${g}_${l}`;
                if (!(keyConst in result)) {
                    result[keyConst] = [];
                }
                result[keyConst].push(k);
            }
        });
        return result;
    }
    /**
     * Whether all the descendants of the node are selected
     * @param node
     */
    descendantsAllSelected(node: TodoItemFlatNode<V>): boolean {
        if (this.treeControl.dataNodes) {
            const descendants = this.treeControl.getDescendants(node);
            const response = descendants.every((child) =>
                this.checklistSelection.isSelected(child)
            );
            this.nodesSelected.emit(this.checklistSelection.selected);
            return response;
        }
        return false;
    }
    /**
     * Whether part of the descendants are selected
     * @param node
     */
    descendantsPartiallySelected(node: TodoItemFlatNode<V>): boolean {
        if (this.treeControl.dataNodes) {
            const descendants = this.treeControl.getDescendants(node);
            const result = descendants.some((child) => this.checklistSelection.isSelected(child));
            this.nodesSelected.emit(this.checklistSelection.selected);
            return result && !this.descendantsAllSelected(node);
        }
        return false;
    }
    /**
     * Toggle the to-do item selection. Select/deselect all the descendants node
     * @param node
     */
    todoItemSelectionToggle(node: TodoItemFlatNode<V>): void {
        this.checklistSelection.toggle(node);
        const descendants = this.treeControl.getDescendants(node);
        this.checklistSelection.isSelected(node)
            ? this.checklistSelection.select(...descendants)
            : this.checklistSelection.deselect(...descendants);
        this.nodesSelected.emit(this.checklistSelection.selected);
        // Force update for the parent
        descendants.forEach((child) => this.checklistSelection.isSelected(child));
        this.checkAllParentsSelection(node);
    }
    /** Toggle a leaf to-do item selection. Check all the parents to see if they changed
     * @param node
     */
    todoLeafItemSelectionToggle(node: TodoItemFlatNode<V>): void {
        this.checklistSelection.toggle(node);
        this.nodesSelected.emit(this.checklistSelection.selected);
        this.checkAllParentsSelection(node);
    }
    /** Checks all the parents when a leaf node is selected/unselected
     * @param node
     */
    checkAllParentsSelection(node: TodoItemFlatNode<V>): void {
        let parent: TodoItemFlatNode<V> | null = this.getParentNode(node);
        while (parent) {
            this.checkRootNodeSelection(parent);
            parent = this.getParentNode(parent);
        }
    }
    /** Check root node checked state and change it accordingly
     * @param node
     */
    checkRootNodeSelection(node: TodoItemFlatNode<V>): void {
        const nodeSelected = this.checklistSelection.isSelected(node);
        const descendants = this.treeControl.getDescendants(node);
        const descAllSelected =
            descendants.length > 0 &&
            descendants.every((child) => {
                return this.checklistSelection.isSelected(child);
            });
        if (nodeSelected && !descAllSelected) {
            this.checklistSelection.deselect(node);
        } else if (!nodeSelected && descAllSelected) {
            this.checklistSelection.select(node);
        }
        this.nodesSelected.emit(this.checklistSelection.selected);
    }
    /** Get the parent node of a node
     * @param node
     */
    getParentNode(node: TodoItemFlatNode<V>): TodoItemFlatNode<V> | null {
        const currentLevel = this.getLevel(node);
        if (currentLevel < 1) {
            return null;
        }
        const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;
        for (let i = startIndex; i >= 0; i--) {
            const currentNode = this.treeControl.dataNodes[i];
            if (this.getLevel(currentNode) < currentLevel) {
                return currentNode;
            }
        }
        return null;
    }
    private buildTree(): void {
        this.treeFlattener = new MatTreeFlattener<T, TodoItemFlatNode<V>>(
            (node, level) => this.transformer(node, level) as TodoItemFlatNode<V>,
            (node) => node.level,
            (node) => node.expandable,
            (node) => this.getChildren(node) as T[]
        );
        this.treeControl = new FlatTreeControl<TodoItemFlatNode<V>>(
            this.getLevel,
            this.isExpandable
        );
        this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
    }
    private getTemplateByLevel(level: number): GalvinTreeNodeTmpDirective {
        return this.contentNodes.find(
            (v) => v.galvinTreeNodeTmpLevel === level
        ) as GalvinTreeNodeTmpDirective;
    }
    private getTooltipByLevel(level: number): GalvinTreeNodeTooltipTmpDirective {
        return this.contentTooltipNodes.find(
            (v) => v.galvinTreeNodeTooltipTmpLevel === level
        ) as GalvinTreeNodeTooltipTmpDirective;
    }
    private transformer(node: T, level: number): TodoItemFlatNode<V> | undefined {
        if (this.transformerFn) {
            return this.transformerFn(node, level);
        }
        return;
    }
    private getChildren(node: T): T[] | undefined {
        if (this.getChildrenFn) {
            return this.getChildrenFn(node);
        }
        return;
    }
}
