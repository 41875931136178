import { Component, Input } from '@angular/core';

@Component({
    selector: 'skeleton-load-dialog',
    templateUrl: './skeleton-load-dialog.component.html',
    styleUrls: ['../request-details-dialog.component.scss']
})
export class SkeletonLoadDialogComponent {
    @Input() requestPreview!: boolean;
    fvcs = Array(50);
    sections = Array(3);
}
