import { IConfigurationI18n } from './configuration-i18n.interface';

export const CONFIGURATION: IConfigurationI18n = {
    DIALOG_CREATE: {
        CREATE_TITLE: 'Creating a new Configuration',
        EDIT_TITLE: 'Editing the Configuration',
        MESSAGE_CREATED: 'Configuration created',
        MESSAGE_EDITED: 'Configuration edited'
    },
    DIALOG_EDIT: {
        CREATE_TITLE: 'Editing Configuration(s)',
        EDIT_TITLE: 'Editing Configuration',
        MESSAGE_CREATED: 'Multiple Configurations Edited',
        BUTTON_CREATE: 'Edit Configuration(s)',
        MESSAGE_EDIT_CONFIG_STATUS:
            'You cannot change the status because the previously selected configurations do not have the same status',
        MESSAGE_EDIT_ALREADY_OPEN:
            'You need to update the configuration status to <span class="d-inline-flex align-items-center build-plan-open">' +
            '<span class="material-icons">brightness_1</span><b>Open</b></span>',
        MESSAGE_EDIT_ALREADY_OPEN_FILLED:
            'This field cannot be changed because it has already been previously filled',
        MESSAGE_OPENED_DATE: 'This field is filled automatically',
        MESSAGE_TEMPORARY_DISABLED_DATE: 'This field is enabled when field Temporary is checked',
        ORDER_CHANGE_SUCCESS: 'Configuration order successfully updated',
        ORDER_CHANGE_ERROR:
            'Failed to update configuration order, the data shown on the screen is outdated. ' +
            'Please refresh before performing the operation.',
        ORDER_CHANGING: 'Updating Configuration order <i class="ml-1 fas fa-spinner fa-spin"></i>'
    },
    SORT_TABLE_TITLE: 'Sorting configurations for this Build Plan',
    DIALOG_CLONED: {
        CREATE_TITLE: 'Clone Configuration(s)',
        MESSAGE_CREATED: 'Configuration created',
        MESSAGE_EDITED: 'Configuration edited',
        MESSAGE_CLONED: 'Configuration(s) cloned',
        CONFIGURATION_ORDER: 'Configuration order',
        CONFIGURATION_ORDER_HINT:
            'The cloned configurations will be placed directly <strong>after</strong> the one selected.'
    },
    DIALOG_CONFIG_ALIAS: {
        MESSAGE_UPDATE: 'Configuration(s) Alias changed'
    },
    FVC_PARAMETER: {
        NEW_INDICATOR: 'New!',
        BTN_ADD_FVC_PARAMETER: 'Add FVC Parameter',
        SEARCH_FVC_PARAMETER: 'Search FVC Parameters',
        ADD_TO_BUILD_PLAN: 'Add to Build Plan',
        FVC_PARAMETER_ADDED: 'Fvc Parameters(s) added in Build Plan',
        DELETE_CONFIRMATION:
            'Are you sure you want to delete fvc parameter from this build plan: {{name}}?',
        DELETE_CONFIRMATION_DIALOG_TITLE: 'Confirm',
        REMOVE_FVC_PARAMETER: 'Remove {{name}} FVC Parameter',
        FVC_PARAMETER_DELETED: 'FVC Parameter removed'
    },
    SWIMLANES: {
        FACTORY: {
            FVC_FINISHED_QUANTITY: 'FVC Finished Quantity',
            FVC: 'FVC',
            BR_NUMBER: 'BR Number',
            LDA_BR: 'LDA BR',
            LDA_FVC: 'LDA FVC',
            PCB_VERSION: 'PCB Version',
            SUB_PCB: 'Sub PCB'
        },
        CONFIGURATION_STATUS_DETAIL: {
            HIDDEN: 'Hidden',
            ARCHIVED: 'Archived',
            TEMPORARY: 'Temporary',
            ALREADY_OPENED: 'Already Opened',
            TEMPORARY_DISABLE_DATE: 'Temporary Disable Date',
            OPENED_DATE: 'Opened Date',
            DUE_DATE: 'Due Date'
        },
        STATUS: {
            NEW: 'New',
            OPEN: 'Open',
            LOCKED: 'Locked',
            MANUFACTURED: 'Manufactured',
            IN_TRANSIT: 'In Transit',
            COMPLETED: 'Completed',
            CANCELED: 'Canceled'
        }
    },
    COMMON: {
        BUTTONS: {
            CREATE_CONFIGURATION: 'New Configuration',
            DELETE_CONFIGURATION: 'Delete {{amount}} Configuration(s)',
            EDIT_CONFIGURATION: 'Edit {{amount}} Configuration(s)',
            HIDE_SHOW_COLUMNS: 'Hide / Show the columns',
            CLONE_CONFIGURATIONS: 'Clone {{amount}} Configuration(s)',
            UPDATE_CONFIG_ALIAS: 'Apply Config Alias {{amount}} Configuration(s)'
        },
        AUTOMATED_SORTING: 'Automated/Default Sorting',
        AUTOMATED_SORTING_TOOLTIP: `Default Order Ex.: <br/>
                                    <strong>HW Revision</strong>= EVB > EVT BOARD > EVT > DVT1 BOARD > DVT1 ... <br/>
                                    <strong>Sourcing</strong>= 1st > 2nd > 3rd ... <br/>
                                    <strong>Unit Type</strong>= Alphabetical Order. <br/>
                                    <strong>Build Recipe</strong>= PCBA > Normal > RF Hole > RF Hole+ BE > BE Hole Only > Secure SW CID 0 > Secure SW CID 5 ... <br/>
                                    <strong>Market Region</strong>= Alphabetical Order.`,
        CONFIGURATION_STATUS: 'Configuration Status',
        ACCESSORY_STATUS: 'Accessory Status',
        ACCESSORY_TYPE: 'Accessory Type',
        RESET: 'RESET',
        MANDATORY_FIELD: 'Mandatory Field',
        CLONE: 'Clone',
        CONFIGURATION: 'CONFIGURATION',
        FVC_PARAMETERS: 'FVC PARAMETERS',
        ACCESSORY_PARAMETERS: 'ACCESSORY PARAMETERS',
        CONFIG_STATUS_DETAIL: 'CONFIG STATUS DETAIL',
        FACTORY: 'FACTORY',
        DATES: 'DATES',
        DATES_RELATIVE: 'RELATIVE DATES',
        CHOOSE_FIELDS: 'Choose fields to clone the configurations',
        CONFIGURATION_NAME: 'Configuration Name',
        CONFIGURATION_ORDER: 'Configuration Order',
        DELETE_WARN:
            'This action will <strong>delete <span class="text-danger">{{amount}}</span> configuration(s) ' +
            'and the <span class="text-danger"> {{totalRequests}}</span> proto requests of\n' +
            '<span class="text-primary">{{internalProductName}}</span></strong> Build Plan and could not be undone.',
        CANT_DELETE_TITLE:
            '<strong><span class="text-danger">{{amount}}</span> configuration(s) can\'t be delete ' +
            'as it was already opened to the Requestors. Please, Archive or Cancel it/them.</strong>',
        CANT_DELETE_CONFIGURATION:
            'This configuration can\'t be delete ' +
            'as it has already been opened to the Requestors. Please, Archive or Cancel it.',
        CONFIRMATION_DELETE_DIALOG_TITLE: 'DELETE {{amount}} Configuration(s)',
        CONFIRMATION_EDIT_DIALOG_TITLE: 'EDIT {{amount}} Configuration(s)',
        SUCCESSFUL_DELETED: 'Configuration(s) successfully deleted'
    },
    CONFIGURATION_FILTER: {
        TITLE: 'Filter by Device Data',
        CALENDAR: {
            THESE_DATES_WILL_FILTER_CONFIGURATION_CREATEDDATE:
                'These dates will filter the CreatedDate of Configuration History.',
        },
    },
    SUGGEST_ALIAS: {
        TITLE: 'Would you like to rename the {{columnToUpdate}}?',
        SUGGESTION: 'We suggest you rename the Config Alias <br/> from <span class="font-weight-bold">{{current}}</span> <br/> to <span class="font-weight-bold">{{suggest}}</span>',
        SUGGESTION_LIST: 'We suggest you rename the field from',
        SUGGESTION_TO: ' to',
        BUTTONS: {
            UPDATE_COLUMN: 'Update',
            NOT_UPDATE_COLUMN: 'No, it\'s fine',
        },
        CONFIGURATION_ID: 'Conf. Id #',
        CURRENT_CONFIGURATION: 'Current Conf. Alias Name',
        SUGGESTED_CONFIGURATION: 'Suggested Conf. Alias Name'
    },
    SUGGEST_UPDATE_TEMPORARY_DATE: {
        DIALOG_TITLE: 'Update temporary date field',
        DIALOG_CONTENT: 'Please, provide a valid Temporary Date to this configuration',
        LABEL: 'Temporary Date'
    },
    DIALOG_SHOW_CONFIGURATION_WORKFLOW: {
        INFO: 'Check Configuration Workflow',
        TITLE: 'Configuration Flow'
    },
    SUGGEST_UPDATE_RELATIVE_DATES: {
        TITLE: 'Would you like  to overwrite {{amount}} relative date(s)?',
        SUGGESTION:
            'This action will change {{amount}} relative date(s) that currently have manual values inputted, which don\'t reflect the' +
            ' {{datesDefault}}. These relative dates will be calculated based on the new date default.',
        CLONE_TITLE:
            'Would you like to reset all relative dates for the new cloned configurations?',
        WARN:
            'This action will ' +
            '<strong>change <span class="text-danger">{{amount}} relative date(s)</span> </strong>' +
            ' that currently have manual values inputted, which don\'t reflect the' +
            '<span class="text-danger"> <strong>{{datesDefault}}</strong>. </span>' +
            '<strong>These relative dates will be calculated based on the new date default</strong>',

        CLONE_WARN:
            'This action will ' +
            '<strong> calculate the <span class="text-danger">all forced relative date(s)</span></strong>' +
            ' from the date default associated with it, which means that the manually inputted values from the relative dates that are ' +
            'present in the configurations you selected will be updated to be based on its current date default.' +
            '<strong>This action will apply to the new cloned configurations.</strong>'
    },
    FVC_SOURCES: {
        SET_FVC_SOURCES: 'Set FVC Sources',
        DIALOG_TITLE: 'Set FVC Sources for this Build Plan',
        REMOVE_FVC_SOURCE: 'Remove FVC Source',
        FVC_PARAMETER: 'FVC Parameter',
        FVC_VALUE: 'FVC Value',
        SOURCE: 'Source',
        OPTION_DISABLED_TOOLTIP: 'This option is already selected in another line',
        SAVE_ERROR_NO_CHANGES:
            'There are no FVC Source changes to be saved. Please set at least one FVC Source or cancel operation.',
        SAVED_SUCCESSFULLY: 'FVC Sources updated successfully'
    },
    BP_ONLY_FVC: {
        TITLE: 'Set FVC Values Only for this Build Plan',
        BUTTON: 'Set BP Only FVCs',
        BUTTON_SAVE: 'Set FVC Values',
        BUTTON_REMOVE: 'Remove BP Only FVCs',
        SUCCESS_MESSAGE: 'BP Only FVCs was added successfuly',
        TOOLTIP_INFO: 'Filtered FVC'
    },
    TIME_ZONE_CHINESE: {
        INFO: 'From a business perspective we will always use the China Standard Time Zone. (GMT+08)'
    },
    WARNING_FOR_CONFIGURATIONS: {
        TEMPLATE_MSG: 'You have {{msg}} Configuration(s).',
        ADD: ' and ',
        IS_HIDDEN: 'Hidden',
        IS_TEMPORARY: 'Temporary'
    },
    PLURAL_NAME: 'Configurations',
    CONFIGURATION_HISTORY : {
        NO_DATA : 'There are no configuration history for the filter.'
    }
};
