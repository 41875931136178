import { environment } from 'environments/environment';

export class E2eRunnerHandler{

    public getRunnerTest(): string {
        return localStorage.getItem('runnerTest') as string;
    }

    public getForceCloudTaskExecution(): string {
        return localStorage.getItem('forceCloudTaskExecution') as string;
    }

    public isRunnerTest(){
        return !['mduat','prod'].includes(environment.name) && this.getRunnerTest()==='true';
    }

    public canForceCloudTaskExecution(){
        return !['mduat','prod'].includes(environment.name) && this.getForceCloudTaskExecution()==='true';
    }

}
