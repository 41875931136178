import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export interface AddRowEvent<T> {
    row: T;
    scrollToElement: boolean;
}

@Injectable()
export class TableEditInlineService {
    readonly scrollToBottom$: Subject<any>;

    constructor() {
        this.scrollToBottom$ = new Subject();
    }

    scrollToBottom(): void {
        this.scrollToBottom$.next();
    }
}
