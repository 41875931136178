// Angular
import { Injectable } from '@angular/core';
// RxJS
import { BehaviorSubject, Subject } from 'rxjs';
// Object path
import * as objectPath from 'object-path';
// Services
import { MenuConfigService } from './menu-config.service';

@Injectable()
export class MenuAsideService {
    // Public properties
    menuList$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

    isSidebarOpen!: boolean;
    sidebarOpenCloseListener$: Subject<boolean> = new Subject<boolean>();

    readonly SIDEBAR_SHOW_SIZE = 1025;

    /**
     * Service constructor
     *
     * @param menuConfigService: MenuConfigService
     */
    constructor(private menuConfigService: MenuConfigService) {
        this.loadMenu();
    }

    /**
     * Called whenever the sidebar status changes from open to closed or vice-versa
     * @param isOpen
     */
    sidebarOpenCloseChanged(isOpen: boolean): void {
        this.isSidebarOpen = isOpen;
        this.sidebarOpenCloseListener$.next(isOpen);
    }

    /**
     * Load menu list
     */
    loadMenu() {
        // get menu list
        const menuItems: any[] = objectPath.get(
            this.menuConfigService.getCurrentMenuConfig(),
            'aside.items'
        );
        this.menuList$.next(menuItems);
    }
}
