import { Pipe, PipeTransform } from '@angular/core';
import { HwRevisionUtils } from '@galvin/core/build-plan/prototype-request-management';

@Pipe({
    name: 'getBodySourcingColor'
})
export class GetBodySourcingColor implements PipeTransform {

    transform(sourcing: string, hwRevision?: string): any {
        const total = this.getSourcingColorForBody(sourcing, hwRevision);
        return total;
    }

    /**
     * Return a css class for body
     * @param sourcing
     * @param hwRevision optional
     */
    getSourcingColorForBody(sourcing: string, hwRevision?: string) {
        if(!hwRevision?.includes(HwRevisionUtils.EVB || HwRevisionUtils.BOARD)) {
            return sourcing === '1st' ? 'green-light' : 'yellow-light';
        }

        return '';
    }
}