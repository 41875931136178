<div id="multi-selectors" class="selector-with-filter">
  <div
    class="dropdown-inline-block"
    ngbDropdown
    [autoClose]="'outside'"
    (openChange)="dropdownChange($event)"
  >
    <button
      id="item-seletor-dropdown"
      ngbDropdownToggle
      [disabled]="isDisabled"
      [ngClass]="{ disabled: isDisabled }"
      ngbTooltip="{{ infoItemSelected() }}"
    >
      <span class="title-button">{{ titleInfoShort(infoItemSelected()) }}</span>
    </button>

    <div aria-labelledby="item-selector-dropdown" ngbDropdownMenu>
      <div class="all-items flex-wrap">
        <ng-container *ngFor="let label of labelSelector">
          <span
            [id]="'multi-selector-label-' + label.key"
            class="sub-selector-label"
          >
            {{ label.name }}
          </span>
          <ng-select
            [id]="'multi-selector-' + label.key"
            (ngModelChange)="onItemSelected($event, label.key)"
            [closeOnSelect]="false"
            [items]="itemsSelector[label.key]"
            [multiple]="true"
            [ngModel]="itemsSelected[label.key]"
            [searchable]="true"
            [selectableGroupAsModel]="false"
            [selectableGroup]="true"
            [virtualScroll]="true"
            bindValue="value"
            groupBy="group"
            [searchFn]="searchCustomerReg"
            class="border border-secondary rounded"
          >
            <ng-template
              ng-optgroup-tmp
              let-index="index"
              let-item="item"
              let-item$="item$"
            >
              <mat-checkbox (click)="(false)" [ngModel]="item$.selected">
                {{ item.group }}
              </mat-checkbox>
            </ng-template>

            <ng-template
              ng-option-tmp
              let-index="index"
              let-item="item"
              let-item$="item$"
            >
              <mat-checkbox (click)="(false)" [ngModel]="item$.selected">
                <span class="text-truncate" [ngbTooltip]="item.value">{{
                  item.value
                }}</span>
              </mat-checkbox>
            </ng-template>

            <ng-template let-clear="clear" let-items="items" ng-multi-label-tmp>
              <div
                *ngFor="let item of items | slice : 0 : 3"
                class="ng-value bg-main-color text-truncate"
              >
                <span
                  (click)="clear(item)"
                  aria-hidden="true"
                  class="ng-value-icon right"
                  >x</span
                >
                <span [ngbTooltip]="$any(item).value" class="ng-value-label">{{
                  $any(item).value
                }}</span>
              </div>
              <div *ngIf="items.length > 3" class="ng-value bg-main-color">
                <span
                  [ngbTooltip]="infoTooltipMore(items)"
                  class="ng-value-label"
                  >{{ infoTooltipMore(items) }}</span
                >
              </div>
            </ng-template>
          </ng-select>
        </ng-container>
      </div>
    </div>
  </div>
</div>
