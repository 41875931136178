// Angular Forms
import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';
// Constants
import { AppConstants } from '../../views/share/app-constants';
import { LocationConstants } from '../build-plan/field-value-management';
import { Observable, of, timer } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

export interface AbstractControlWarn extends AbstractControl {
    warn: any;
}

export class UtilsForm {
    public static validateDomain(control: AbstractControl): { [key: string]: boolean } | null {
        const email = control.value;
        const regexEmail = /^[A-z0-9._+-]+@[A-z0-9_+-]+(\.[A-z0-9]+)+$/gi;
        if (regexEmail.test(email)) {
            const domain = `@${email.split('@')[1]}`;
            if (domain !== AppConstants.MOTOROLA_EMAIL && domain !== AppConstants.LENOVO_EMAIL) {
                return { domain: true };
            }
        }

        if (email === null || email.length === 0 ) {
            return null;
        }

        return null;
    }

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    public static validateNameWithSpaceOrDash() {
        return /^[a-zA-Z0-9-]+( [a-zA-Z0-9-]+)*$/;
    }

    public static validateNameWithSpaceOrHyphen(
        control: AbstractControl
    ): { [key: string]: boolean } | null {
        const { value } = control;
        const validate = /^[A-z]+([- ][A-z]+)*$/;
        if (value.length === 0) {
            return null;
        }
        return !validate.test(value) ? { name: true } : null;
    }

    public static validateCoreId(control: AbstractControl): { [key: string]: boolean } | null {
        const { value } = control;
        const validate = /^[a-z0-9]*$/;
        return !validate.test(value) ? {coreId: true} : null;
    }

    public static validatePhoneNumber(control: AbstractControl): { [key: string]: boolean } | null {
        const value = control.value;
        const phoneNumberUtil = PhoneNumberUtil.getInstance();
        if (value) {
            try {
                const phoneNumber = phoneNumberUtil.parse(value);
                if (!phoneNumberUtil.isValidNumber(phoneNumber)) {
                    return { invalid: true };
                } else if (
                    value !=
                    phoneNumberUtil.format(phoneNumber, PhoneNumberFormat.INTERNATIONAL).toString()
                ) {
                    control.setValue(
                        phoneNumberUtil.format(phoneNumber, PhoneNumberFormat.INTERNATIONAL)
                    );
                }
                return null;
            } catch (error) {
                return { invalid: true };
            }
        }
        return null;
    }

    public static validateGTMList(control: AbstractControl): { [key: string]: boolean } | null {
        const value = control.value;
        if (LocationConstants.GMTOFFSET.filter((gmt) => gmt === value).length === 0) {
            return { required: true };
        }
        return null;
    }

    public static matDateFormat(): any {
        return {
            parse: {
                dateInput: 'LL'
            },
            display: {
                dateInput: 'LL',
                monthYearLabel: 'MMM YYYY',
                dateA11yLabel: 'LL',
                monthYearA11yLabel: 'MMMM YYYY'
            }
        };
    }

    public static validateLabel(term: string): string | null {
        if (term.match(/[;,/'"`´.\\]/) || term === '') {
            return term;
        }
        return null;
    }

    /**
     * Validator to check if name already exists.
     * @param f arrow function where will pas service with method. Ex: (name)=>getScopeByName(name)
     */
    public static  alreadyExistValidatorAsync(f: any): AsyncValidatorFn {
        return (control: AbstractControl): Observable<ValidationErrors | null> => {
            if (control.pristine) {
                return of(null);
            }
            return timer(1000).pipe(
                switchMap(() => f(control.value)),
                map((result) => (result ? { alreadyExist: true } : null))
            );
        };
    }

    public static validateDomainWarnExternal(control: AbstractControlWarn): { [key: string]: boolean } | null {
        const validate = UtilsForm.validateDomain(control);
        if (validate && validate['domain']) {
            control.warn = validate;
            return null;
        }
        control.warn = null;
        return validate;
    }

    /**
     * Validate an order selector given a series of parameters
     *
     * @param order the order selected
     * @param item the item being edited/created
     * @param options list of all items
     * @param orderParam the order parameter within the options
     * @param isEditOperation boolean indicating if it's an edit operation
     * @param requiresRefresh boolean indicating if the data needs a refresh
     */
    public static validateOrder(order: number, item: any, options: any[], orderParam: string, isEditOperation: boolean,
                                requiresRefresh: boolean): ValidationErrors | null {
        const last = options[options.length - 1][orderParam];
        if (!requiresRefresh || (requiresRefresh
        && (isEditOperation && order === item[orderParam] || !isEditOperation && order === last))) {
            return null;
        }
        return { outdated: true };
    }

}
