<title-expanded [title]="'BUILD_PLAN.CONFIGURATION.COMMON.FVC_PARAMETERS'| translate" [expanded]="expanded"
    (expandedOut)="expanded = $event">
</title-expanded>
<div class="d-flex flex-row" id="fvc-container" [@detailExpand]="expanded  ? 'expanded' : 'collapsed'">
    <ul class="p-0 d-flex flex-row flex-wrap h-100 mt-4 justify-content-start">
        <li class="d-flex justify-content-between align-items-center mb-3 mr-3" [style.width.px]="180"
            *ngFor="let fvc of fvcs">
            <span container="galvin-dialog-generic" placement="right" [ngbTooltip]="fvc.parameterName"
                class="text-default text-truncate text-normal property-text">
                {{fvc.parameterName}} </span>
            <div class="d-flex justify-content-center align-items-center tag-prototypes color-dark-gray"
                [style.width.px]="75" [style.min-width.%]="36" [attr.data-source]="fvc.source">
                <span container="galvin-dialog-generic" [ngbTooltip]="getValue(fvc.value) | checkNull" placement="left"
                    class="text-normal d-inline-block text-truncate p-2 color-dark-gray">
                    {{ getValue(fvc.value) | checkNull}}
                </span>
            </div>
        </li>
    </ul>
</div>
