<!-- begin: Header -->
<mat-toolbar
    ktHeader
    #ktHeader
    class="kt-header kt-grid__item toolbar-header"
    [ngClass]="htmlClassService.getClasses('header', true)"
    id="kt_header"
>
    <g-brand> </g-brand>

    <ngb-progressbar
        class="kt-loading-bar"
        *ngIf="(loader.progress$ | async) || 0 > 0"
        [value]="(loader.progress$ | async) || 0"
        height="3px"
    >
    </ngb-progressbar>

    <!-- begin: Header Menu -->
    <kt-menu-horizontal *ngIf="menuHeaderDisplay"></kt-menu-horizontal>
    <!-- end: Header Menu -->

    <!-- begin:: Header Topbar -->
    <!-- empty div to fix topbar to stay on the right when menu-horizontal is hidden -->
    <div class="topbar-content">
        <div *ngIf="!menuHeaderDisplay"></div>
        <kt-topbar></kt-topbar>
        <!-- end:: Header Topbar -->
    </div>
</mat-toolbar>

<!-- end: Header -->
