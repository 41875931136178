import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IAccessoryTeamRequestPreview } from '@galvin/core/build-plan/accessory-management/interfaces/accessory-team-request-preview.interface';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class BuildPlanRequestPreviewService {
    private readonly BUILD_PLAN_BASE_URL = `${environment.baseUrlApi}/build-plan/build-plan-management`;
    private readonly URL_BUILD_PLAN =
        'build-plan/build-plan-management/{id-build-plan}/request-preview';
    private readonly API_BUILD_PLAN_URL = `${environment.baseUrlApi}/${this.URL_BUILD_PLAN}`;

    constructor(private http: HttpClient) {}

    getBpAccessoriesByBuildPlan(
        buildPlanId: number,
        isRequestPreviewTab: boolean
    ): Observable<IAccessoryTeamRequestPreview> {
        return this.http
            .get(
                `${this.BUILD_PLAN_BASE_URL}/${buildPlanId}/accessory-team-request-preview?is-accessory-request-preview-tab=${isRequestPreviewTab}`
            )
            .pipe(
                map((response: any) => {
                    return response.content;
                })
            );
    }

    getByBuildPlanRequest(buildPlanId: number, isRequestPreviewTab: boolean): Observable<any> {
        return this.http
            .get<any>(
                `${this.getBasePath(buildPlanId)}?is-request-preview-tab=${isRequestPreviewTab}`
            )
            .pipe(
                map((response: any) => {
                    return response.content;
                })
            );
    }

    getConfigurationInformation(buildPlanId: number, configurationId: number): Observable<any> {
        return this.http
            .get<any>(`${this.getBasePath(buildPlanId)}/configurations/${configurationId}`)
            .pipe(
                map((response: any) => {
                    return response.content;
                })
            );
    }

    private getBasePath(id: number): string {
        return this.API_BUILD_PLAN_URL.replace('{id-build-plan}', `${id}`);
    }
}
