import { IAccessoryTeamRequestPreviewAccessory } from './../../accessory-management/interfaces/accessory-team-request-preview-accessory';
// Angular
import { Injectable } from '@angular/core';
import { EAccessoryRequestFilters } from '@galvin/core/build-plan/accessory-request-management/enums/accessory-request-filters.enum';
import {
    IAccessoryFilters,
    IAccessoryRequestTeam,
    IAccessoryTypeRequestsTotals
} from '@galvin/core/build-plan/accessory-request-management/interface/accessory-request-team.interface';
import { IBuildPlanSelectorItem } from '@galvin/core/build-plan/prototype-request-management';
import { ITeamSelectorItem } from '@galvin/core/build-plan/prototype-request-management/interfaces/team-selector-item.interface';
import { LocalStorageHelper } from '@galvin/core/storage/local-storage-helper.service';
import { MonoTypeOperatorFunction, Observable, Subject } from 'rxjs';
// Environments
import { FormGroup } from '@angular/forms';
import { AccessoryConstants } from '@galvin/core/build-plan/accessory-management/interfaces/accessory-constants';
import { WithRequiredProperty } from '@galvin/core/utils/interfaces';
import { IProtoRequestCell } from '@galvin/views/partials/layout/proto-request-table';
import { cloneDeep } from 'lodash';
import { filter } from 'rxjs/operators';
import { EAccessoryStatusLabel } from '../../accessory-management/enums/accessory-type.enum';
import { IBuildPlanTotalAccessoryRequest } from '../../management/interfaces/build-plan-total-requests.interface';
import { IProtoRequestTableStorage } from '../../prototype-request-management/interfaces/proto-request-table-storage.interface';
import { IPrototypeRequestTeamSpecialRequirements } from '../../prototype-request-management/interfaces/prototype-request-team.interface';
import { IAccessoryValueRequestTableStorage } from '../interface/accessory-request-table-storage.interface';

function filterEmpty<T>(): MonoTypeOperatorFunction<T | null | undefined> {
    // @ts-ignore
    return filter<T>((value) => value !== undefined && value !== null);
}

function isIAccessoryRequestTeam(accessory: any): accessory is IAccessoryRequestTeam[] {
    return typeof accessory[0] != 'string' && 'accessoryTeamRequestId' in accessory[0];
}

@Injectable({
    providedIn: 'root'
})
export class AccessoryRequestService {
    readonly accessoryStatusNowAllowed = [EAccessoryStatusLabel.NEW];

    team!: ITeamSelectorItem;
    teamId!: number;
    teams!: ITeamSelectorItem[];
    teamsIds!: number[];
    buildPlan!: IBuildPlanSelectorItem | null;
    buildPlanId!: number | undefined;
    accessoriesRequest!: IAccessoryRequestTeam[] | IAccessoryTeamRequestPreviewAccessory[];
    isTeamLoaded = false;
    isBuildPlanSelectorLoaded = false;
    noTeamFound = true;

    readonly accessoryStatusNotShow = [EAccessoryStatusLabel.NEW];

    private _loading!: boolean;
    private prefixCache!: string;
    private requestedCountByAcessory!: IAccessoryTypeRequestsTotals;
    private requestCountUpdate!: { accessory: string; difference: number };
    requestReviewAccessoryTypeChange: Subject<IBuildPlanTotalAccessoryRequest[]> = new Subject<
        IBuildPlanTotalAccessoryRequest[]
    >();

    private readonly TEAM_SELECTOR: string = 'teamSelector';
    private readonly TEAMS_SELECTOR: string = 'teamListSelector';
    private readonly _requestedCountByAccessoryChangeStream: Subject<IAccessoryTypeRequestsTotals>;
    private readonly _requestedCountByAccessoryChange: Observable<IAccessoryTypeRequestsTotals>;
    private readonly _requestedCountUpdateStream: Subject<{
        accessory: string;
        difference: number;
    }>;
    private readonly _requestedCountUpdate: Observable<{ accessory: string; difference: number }>;

    private readonly _accessoryRequestListStream: Subject<Partial<IAccessoryRequestTeam[]>>;
    private readonly _accessoryRequestList: Observable<Partial<IAccessoryRequestTeam[]>>;

    private readonly _filtersStream: Subject<IAccessoryFilters>;
    private readonly _filtersLoaded: Observable<IAccessoryFilters>;

    private readonly _filtersOptionStream: Subject<IAccessoryFilters>;
    private readonly _filtersOption: Observable<IAccessoryFilters>;

    private readonly _signalResetFilterStream: Subject<IAccessoryFilters>;
    private readonly _signalResetFilter: Observable<IAccessoryFilters>;

    private readonly _isLoadingStream: Subject<boolean>;
    private readonly _isLoading: Observable<boolean>;

    private readonly _teamSelectedStream: Subject<ITeamSelectorItem>;
    private readonly _teamSelected: Observable<ITeamSelectorItem>;

    private readonly _teamsSelectedStream: Subject<ITeamSelectorItem[]>;
    private readonly _teamsSelected: Observable<ITeamSelectorItem[]>;

    private readonly _buildPlanSelectedStream: Subject<IBuildPlanSelectorItem>;
    private readonly _buildPlanSelected: Observable<IBuildPlanSelectorItem>;

    private readonly _accessoriesStream: Subject<
        IAccessoryRequestTeam[] | IAccessoryTeamRequestPreviewAccessory[]
    >;
    private readonly _accessoriesLoaded: Observable<
        IAccessoryRequestTeam[] | IAccessoryTeamRequestPreviewAccessory[]
    >;

    private readonly KEY_FILTER_STORAGE: string = 'accessory-request-filters';
    private readonly BUILD_PLAN_SELECTOR: string = 'buildPlanSelector';

    constructor(private localStorageHelper: LocalStorageHelper) {
        this._teamSelectedStream = new Subject<ITeamSelectorItem>();
        this._teamSelected = this._teamSelectedStream
            .asObservable()
            .pipe(filterEmpty()) as Observable<ITeamSelectorItem>;

        this._teamsSelectedStream = new Subject<ITeamSelectorItem[]>();
        this._teamsSelected = this._teamsSelectedStream
            .asObservable()
            .pipe(filterEmpty()) as Observable<ITeamSelectorItem[]>;

        this._buildPlanSelectedStream = new Subject<IBuildPlanSelectorItem>();
        this._buildPlanSelected = this._buildPlanSelectedStream
            .asObservable()
            .pipe(filterEmpty()) as Observable<IBuildPlanSelectorItem>;

        this._requestedCountByAccessoryChangeStream = new Subject<IAccessoryTypeRequestsTotals>();
        this._requestedCountByAccessoryChange = this._requestedCountByAccessoryChangeStream
            .asObservable()
            .pipe(filterEmpty()) as Observable<IAccessoryTypeRequestsTotals>;

        this._requestedCountUpdateStream = new Subject<{ accessory: string; difference: number }>();
        this._requestedCountUpdate = this._requestedCountUpdateStream
            .asObservable()
            .pipe(filterEmpty()) as Observable<{ accessory: string; difference: number }>;

        this._isLoadingStream = new Subject<boolean>();
        this._isLoading = this._isLoadingStream
            .asObservable()
            .pipe(filterEmpty()) as Observable<boolean>;

        this._accessoriesStream = new Subject<
            Array<IAccessoryRequestTeam | IAccessoryTeamRequestPreviewAccessory>
        >();
        this._accessoriesLoaded = this._accessoriesStream
            .asObservable()
            .pipe(filterEmpty()) as Observable<
            Array<IAccessoryRequestTeam | IAccessoryTeamRequestPreviewAccessory>
        >;

        this._filtersStream = new Subject<IAccessoryFilters>();
        this._filtersStream.next(JSON.parse(localStorageHelper.getItem(this.KEY_FILTER_STORAGE)));
        this._filtersLoaded = this._filtersStream
            .asObservable()
            .pipe(filterEmpty()) as Observable<IAccessoryFilters>;

        this._filtersOptionStream = new Subject<IAccessoryFilters>();
        this._filtersOption = this._filtersOptionStream
            .asObservable()
            .pipe(filterEmpty()) as Observable<IAccessoryFilters>;

        this._signalResetFilterStream = new Subject<IAccessoryFilters>();
        this._signalResetFilter = this._signalResetFilterStream
            .asObservable()
            .pipe(filterEmpty()) as Observable<IAccessoryFilters>;

        this._accessoryRequestListStream = new Subject<Partial<IAccessoryRequestTeam[]>>();
        this._accessoryRequestList = this._accessoryRequestListStream
            .asObservable()
            .pipe(filterEmpty()) as Observable<(IAccessoryRequestTeam | undefined)[]>;
    }

    private _filters!: IAccessoryFilters;

    set accessories(value: IAccessoryRequestTeam[] | IAccessoryTeamRequestPreviewAccessory[]) {
        this.accessoriesRequest = value;
        this._accessoriesStream.next(value);
    }

    get accessories$(): Observable<
        IAccessoryRequestTeam[] | IAccessoryTeamRequestPreviewAccessory[]
        > {
        return this._accessoriesLoaded;
    }

    set filters(value: IAccessoryFilters) {
        this._filters = cloneDeep(value);
        this._filtersStream.next(this._filters);
        this.localStorageHelper.setItem(this.KEY_FILTER_STORAGE, JSON.stringify(value));
    }

    get filters$(): Observable<IAccessoryFilters> {
        return this._filtersLoaded;
    }

    set filtersOption(filters: IAccessoryFilters) {
        this._filtersOptionStream.next(filters);
    }

    get filtersOption$(): Observable<IAccessoryFilters> {
        return this._filtersOption;
    }

    set signalResetFilterSelected(filters: IAccessoryFilters) {
        this._signalResetFilterStream.next(filters);
    }

    get signalResetFilterSelected$(): Observable<IAccessoryFilters> {
        return this._signalResetFilter;
    }

    get teamSelected$(): Observable<ITeamSelectorItem> {
        return this._teamSelected;
    }

    set teamSelected(team: ITeamSelectorItem) {
        if (team) {
            this.localStorageHelper.setItem(
                this.getCachePrefix(this.TEAM_SELECTOR),
                team.id.toString()
            );
            this.team = team;
            this.teamId = team.id;
            this._teamSelectedStream.next(team);
        }
    }

    set teamSelectedNull(teamNull: ITeamSelectorItem | null) {
        this._teamSelectedStream.error(teamNull);
    }

    get teamsSelected$(): Observable<ITeamSelectorItem[]> {
        return this._teamsSelected;
    }

    set teamsSelected(teams: ITeamSelectorItem[]) {
        if (teams) {
            const teamIds = teams.map((team) => team.id);
            this.localStorageHelper.setItem(
                this.getCachePrefix(this.TEAMS_SELECTOR),
                JSON.stringify(teamIds)
            );
            this.teams = teams;
            this.teamsIds = teamIds;
            this._teamsSelectedStream.next(teams);
        }
    }

    get buildPlanSelected$(): Observable<IBuildPlanSelectorItem> {
        return this._buildPlanSelected;
    }

    set buildPlanSelected(buildPlan: IBuildPlanSelectorItem | null) {
        if (buildPlan) {
            this.localStorageHelper.setItem(this.BUILD_PLAN_SELECTOR, JSON.stringify(buildPlan));
            this.buildPlan = buildPlan;
            this.buildPlanId = buildPlan.id;
            this._buildPlanSelectedStream.next(buildPlan);
        } else {
            this.buildPlan = null;
            this.buildPlanId = -1;
        }
    }

    get isLoading$(): Observable<boolean> {
        return this._isLoading;
    }

    get isLoading(): boolean {
        return this._loading;
    }

    set isLoading(value: boolean) {
        this._loading = value;
        this._isLoadingStream.next(value);
    }

    public triggerBpAndTeam(): void {
        this._buildPlanSelectedStream.next(this.buildPlan as IBuildPlanSelectorItem);
        this._teamsSelectedStream.next(this.teams);
    }

    public get filterFromLocalStorage(): IAccessoryFilters {
        let filter: IAccessoryFilters = {
            types: [],
            selectedTypes: [],
            requested: EAccessoryRequestFilters.ALL,
            inputSearch: '',
            parameters: {},
            teamParametersSelected: {}
        };

        if (this.localStorageHelper) {
            let storageFilter = JSON.parse(
                this.localStorageHelper.getItem(this.KEY_FILTER_STORAGE)
            );
            if (storageFilter) {
                filter = storageFilter;
            }
        }
        return filter;
    }

    public set filtersFromLocalStorage(filters: IAccessoryFilters) {
        const saveSomeFilters = { ...filters };
        this.localStorageHelper.setItem(this.KEY_FILTER_STORAGE, JSON.stringify(saveSomeFilters));
    }

    get requestedCountByAccessoryChange$(): Observable<IAccessoryTypeRequestsTotals> {
        return this._requestedCountByAccessoryChange;
    }

    set requestedCountByAccessoryChange(value: IAccessoryTypeRequestsTotals) {
        this.requestedCountByAcessory = value;
        this._requestedCountByAccessoryChangeStream.next(value);
    }

    get requestedCountUpdate$(): Observable<{ accessory: string; difference: number }> {
        return this._requestedCountUpdate;
    }

    set requestedCountUpdate(value: { accessory: string; difference: number }) {
        this.requestCountUpdate = value;
        this._requestedCountUpdateStream.next(value);
    }

    get accessoryRequestList$(): Observable<Partial<IAccessoryRequestTeam[]>> {
        return this._accessoryRequestList;
    }

    set accessoryRequestList(value: Partial<IAccessoryRequestTeam[]>) {
        this._accessoryRequestListStream.next(value);
    }

    applyFilters(
        accessories: Array<IAccessoryRequestTeam | string>,
        filters:
            | IAccessoryFilters
            | WithRequiredProperty<IAccessoryFilters, 'inputSearch' | 'parameters'>
    ): Array<IAccessoryRequestTeam | string> {
        if (!accessories || accessories.length <= 0) return [];
        if (isIAccessoryRequestTeam(accessories)) {
            return this.getFilter(
                accessories,
                filters as WithRequiredProperty<IAccessoryFilters, 'inputSearch' | 'parameters'>
            );
        }
        return accessories.filter((acc: any) => filters.selectedTypes?.includes(acc)) as Array<
            IAccessoryRequestTeam | string
        >;
    }

    reduceAccessoryTypesTeamView(accessories: IAccessoryRequestTeam[]): string[] {
        return accessories
            .map((a) => a.accessoryType)
            .filter((type, index, self) => self.indexOf(type) === index)
            .filter((type) => type)
            .sort((a, b) => a.localeCompare(b, 'en', { sensitivity: 'base' }));
    }

    reduceAccessoryTypesFullView(accessories: IAccessoryTeamRequestPreviewAccessory[]): string[] {
        return accessories
            .map<string>((a) => a.accessoryType as string)
            .filter((type, index, self) => self.indexOf(type) === index)
            .filter((type) => type)
            .sort((a, b) => a.localeCompare(b, 'en', { sensitivity: 'base' }));
    }

    /**
     * Gets the currently selected build plan id from the local storage
     */
    getBuildPlanSelection(): number | undefined {
        const storageBuildPlan = this.localStorageHelper.getItem(this.BUILD_PLAN_SELECTOR);
        if (storageBuildPlan) {
            this.buildPlan = JSON.parse(storageBuildPlan);
            this.buildPlanId = this.buildPlan?.id;
        }
        return this.buildPlanId;
    }

    /**
     * Gets the currently selected team from the local storage
     */
    getTeamSelection(): number {
        const storageTeam = this.localStorageHelper.getItem(
            this.getCachePrefix(this.TEAM_SELECTOR)
        );
        if (storageTeam && !isNaN(Number(storageTeam))) {
            this.teamId = Number(storageTeam);
        }

        return this.teamId;
    }

    /**
     * Gets the currently selecteds team from the local storage
     */
    getTeamsSelection(): number[] {
        const storageTeam: number[] = JSON.parse(
            this.localStorageHelper.getItem(this.getCachePrefix(this.TEAMS_SELECTOR))
        );

        return storageTeam ? (this.teamsIds = storageTeam) : (this.teamsIds = []);
    }

    setCachePrefix(key: string): void {
        this.prefixCache = key;
    }

    getCachePrefix(key: string): string {
        if (this.prefixCache) {
            return this.prefixCache + '_' + key;
        }

        return key;
    }

    canShowAccessoryRequest(
        accessory: WithRequiredProperty<IAccessoryTeamRequestPreviewAccessory, 'accessoryStatus'>
    ): boolean {
        return (
            !accessory.isHidden && !this.accessoryStatusNotShow.includes(accessory.accessoryStatus)
        );
    }

    private getFilter(
        accessories: Array<IAccessoryRequestTeam>,
        filters: WithRequiredProperty<IAccessoryFilters, 'inputSearch' | 'parameters'>
    ) {
        // Filter Accessory Type
        let resultAccessories = accessories
            .filter(
                (acc) =>
                    filters.selectedTypes &&
                    filters.selectedTypes.length > 0 &&
                    filters.selectedTypes.includes(acc.accessoryType)
            )
            .map((a) => a as WithRequiredProperty<IAccessoryRequestTeam, 'requested'>);

        // Filter Already Request or To Request or All
        resultAccessories = resultAccessories.filter((acc) => {
            switch (filters.requested) {
            case EAccessoryRequestFilters.TO_REQUEST:
                return ('requested' in acc && acc.requested == 0) || !('requested' in acc);

            case EAccessoryRequestFilters.ALREADY_REQUESTED:
                return 'requested' in acc && acc.requested > 0;

            default:
                return true;
            }
        });

        // Filter by input search
        resultAccessories = resultAccessories.filter(
            (acc) =>
                acc.accessoryId.toString().includes(filters.inputSearch) ||
                acc.name.toUpperCase().indexOf(filters.inputSearch.toUpperCase()) > -1
        );

        // Filter by "Filter By" [Accessory Parameters]
        const parameters: {
            key: string;
            values: string[];
        }[] = Object.keys(filters.parameters).map((parameter) => {
            let key = '';
            switch (parameter) {
            case AccessoryConstants.CATEGORY_VALUE:
                key = AccessoryConstants.CATEGORY;
                break;
            case AccessoryConstants.VENDOR_VALUE:
                key = AccessoryConstants.VENDOR;
                break;
            case AccessoryConstants.VENDOR_PART_VALUE:
                key = AccessoryConstants.VENDOR_PART;
                break;
            case AccessoryConstants.MOTO_PART_VALUE:
                key = AccessoryConstants.MOTO_PART;
                break;
            }

            return {
                key: key,
                values: filters.parameters[parameter]!.values
            };
        });

        if (parameters.length > 0) {
            parameters.forEach((p) => {
                resultAccessories = resultAccessories.filter((acc: any) => {
                    return p.values.includes(acc[p.key]);
                });
            });
        }

        return resultAccessories;
    }

    updateRequestReviewAccessoryTypes(requestReviewHW: IBuildPlanTotalAccessoryRequest[]): void {
        this.requestReviewAccessoryTypeChange.next(requestReviewHW);
    }

    /**
     * @description Create a accessoryRequest or if exist accessory with the same buildPlanId, update.
     *
     * @param value accessory relative to the request of each cell in the table
     * @param key storage key
     * @param idBuildPlan is used to find requests
     */
    saveStorage(
        value: IProtoRequestTableStorage<IAccessoryValueRequestTableStorage>,
        idBuildPlan: number,
        key: string
    ): IProtoRequestTableStorage<IAccessoryValueRequestTableStorage> {
        const localStorage = this.localStorageHelper.getAccessoryRequestsChanges(idBuildPlan);

        const newComment  = value.comment;

        if (localStorage) {
            value.requests.forEach((request) => {
                const changedRequestIndex = localStorage.requests.findIndex(
                    (v) => v.idTeam === request.idTeam && v.accessoryId === request.accessoryId
                );
                if (changedRequestIndex>=0) {
                    localStorage.requests[changedRequestIndex].quantity = request.quantity;
                    localStorage.requests[changedRequestIndex].specialRequirements = request.specialRequirements;
                    localStorage.requests[changedRequestIndex].comment = request.comment;
                    localStorage.requests[changedRequestIndex].usersToNotify = request.usersToNotify;
                }else{
                    localStorage.requests.push({
                        ...request,
                        comment: newComment
                    });
                }
            });
            localStorage.comment = newComment;
            localStorage.usersToNotify = value.usersToNotify;
            localStorage.justification = value.justification;
            localStorage.overwriteAll = value.overwriteAll;
            this.localStorageHelper.setAccessoryRequestsChanges(key, localStorage);
        } else {
            value.idBuildPlan = idBuildPlan;
            this.localStorageHelper.setAccessoryRequestsChanges(key, value);
        }
        return localStorage || value;
    }

    undoAll(key: string, idBuildPlan: number): void {
        this.localStorageHelper.undoAllRequestByBuildPlan<IAccessoryValueRequestTableStorage>(
            key,
            idBuildPlan
        );
    }

    getEmptyStorageObject(): IProtoRequestTableStorage<IAccessoryValueRequestTableStorage> {
        return {
            idBuildPlan: null,
            requests: [],
            comment: null,
            overwriteAll: null,
            justification: {
                AUTOMATED_TESTING: false,
                MANUAL_TESTING: false,
                CARRIERS: false,
                SHOW_OFF: false,
                MARKETING: false,
                OTHER: false
            },
            specialRequirements: [],
            canChangeJustificationAndComment: true,
            hasMixedUp: false
        } as any;
    }

    /**
     * @description Get PrototypeRequests by buildPlan
     *
     * @param key is used to handler requests
     * @param idBuildPlan is used to find requests
     * @return IProtoRequestTableStorage
     */
    loadStorage(
        key: string,
        idBuildPlan: number
    ): IProtoRequestTableStorage<IAccessoryValueRequestTableStorage> {
        let localStorage: IProtoRequestTableStorage<IAccessoryValueRequestTableStorage> = {
            idBuildPlan,
            requests: [],
            comment: undefined,
            overwriteAll: undefined,
            justification: {
                AUTOMATED_TESTING: false,
                MANUAL_TESTING: false,
                CARRIERS: false,
                SHOW_OFF: false,
                MARKETING: false,
                OTHER: false
            },
            specialRequirements: [],
            hasMixedUp: false
        };

        if (key) {
            const localStorageFound =
                this.localStorageHelper.getAccessoryRequestsChanges(idBuildPlan);
            if (localStorageFound) {
                localStorage = localStorageFound;
            }
        }
        return localStorage;
    }

    buildObjRequestToSave(
        objSave: IProtoRequestTableStorage<IAccessoryValueRequestTableStorage>,
        cell: IProtoRequestCell<IAccessoryTeamRequestPreviewAccessory>,
        requestForm: FormGroup,
        specialRequirements: IPrototypeRequestTeamSpecialRequirements[]
    ): IProtoRequestTableStorage<IAccessoryValueRequestTableStorage> {
        objSave.requests.push({
            accessoryId: cell.item['accessoryId'],
            accessoryType: cell.$column.accessoryType as string,
            quantity: cell.item['quantity'],
            idTeam: cell.$row.idTeam,
            idTeamAssociation: cell.$row.idTeamAssociation as number,
            firstQuantity: cell.item['firstQuantity'],
            idTeamProtoRequest: cell.item['idTeamProtoRequest'],
            operation: cell.operation,
            specialRequirements: [],
            comment: cell.item['additionalComments'],
            accessoryStatus: <any>cell.$column.accessoryStatus
        });
        objSave.comment = requestForm.value.comment;
        objSave.justification = requestForm.value.justification;
        objSave.specialRequirements = specialRequirements;
        objSave.overwriteAll = requestForm.value.overwriteAll;
        return objSave;
    }
}
