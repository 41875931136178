import {Directive, TemplateRef} from '@angular/core';

@Directive({
    selector: '[cellRef]',
})
export class CellRefDirective {
    constructor(public template: TemplateRef<any>) {
    }
}

