<div class="build-plan-information" *ngIf="buildPlan">
    <div class="build-plan-status status-color">
        <div class="item">
            <div>{{ 'BUILD_PLAN.BUILD_PLAN_MANAGEMENT.DETAILS_STATUS' | translate}}</div>
            <span class="status" [ngClass]="getStatusCSSClass()" [ngbTooltip]="buildPlan.status">
                {{ checkNull.transform(buildPlan.status) }}
            </span>
        </div>
    </div>

    <div *ngIf="displayDates" class="build-plan-status details-creation">
        <div class="item">
            <div class="d-inline-flex">
                <i class="material-icons material-icons-center">today</i>
                <div>{{ 'BUILD_PLAN.BUILD_PLAN_MANAGEMENT.DETAILS_CREATION' | translate}}</div>
            </div>
            <span class="text-primary creation-date"
                [ngbTooltip]='parseDateTime.transform(buildPlan.createdDate, true)'>
                {{ parseDate.transform(buildPlan.createdDate) }}
            </span>
        </div>
    </div>

    <div *ngIf="displayDates" class="build-plan-status details-release">
        <div class="item">
            <div class="d-inline-flex">
                <i class="material-icons material-icons-center">next_week</i>
                <div>{{ 'BUILD_PLAN.BUILD_PLAN_MANAGEMENT.DETAILS_RELEASE' | translate}}</div>
            </div>
            <span class="text-primary release-date"
                [ngbTooltip]='parseDateTime.transform(buildPlan.plannedRelease, true)'>
                {{ parseDate.transform(buildPlan.plannedRelease) }}
            </span>
        </div>
    </div>

    <div *ngIf="displayLockdown" class="build-plan-status date-lockdown">
        <div class="item">
            <div class="d-inline-flex">
                <i class="material-icons material-icons-center">lock</i>
                {{ 'BUILD_PLAN.BUILD_PLAN_MANAGEMENT.DETAILS_LOCKDOWN' | translate}}
            </div>
            <span class="text-primary lock-date" [ngbTooltip]='parseDateTime.transform(buildPlan.lockDownDate, true)'>
                {{ parseDate.transform(buildPlan.lockDownDate, true) }}
            </span>
        </div>
    </div>
</div>