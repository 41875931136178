<div #elementRefTemp (scroll)="onScroll($any($event))" class="config-table list"
[ngStyle]="isShowScrollInCollpaseView() ?{'height':getTableContentSize()+20 +'px'}:{}">

    <div *ngIf="!loadingTable" [style.height.px]="getTableContentSize()" [style.width.px]="content.width"
        class="table-content">
    </div>

    <table *ngIf="!loadingTable" [style.minWidth.px]="viewPortSize.width" class="content-table">
        <thead #tableHeaderElRef>
            <tr *ngIf="canShowGroups()" class="table-header-groups">
                <th #empty *ngIf="!cdkScrollDisabled || selection" [colSpan]="getColSpanGroup()"
                    class="group selection"></th>
                <th #freezeColRef *ngIf="hasColumnFreeze" [colSpan]="freezeColumnIndex.size"
                    [style.left.px]="emptyHeaderOffsetWidth"
                    class="group sticky-column freeze-columns-group">
                    <div class="table-header-wrapper">
                        <div class="text-uppercase">
                            {{'COMMON.BUTTONS.FREEZE'|translate}}
                        </div>
                    </div>
                </th>
                <th class="group p-0"></th>

                <th *ngFor="let group of groups; trackBy:trackByFunction"
                    [attr.data-group]="group?.item?.type?.toUpperCase()" [colSpan]="group?.colSpan"
                    [style.left.px]="normalGroupLeftPosition" [style.width.px]="cellSize.width * (group?.colSpan || 1)"
                    class="group">
                    <div class="table-header-wrapper">
                        <div [style.left.px]="normalGroupLeftPosition" class="text-truncate">
                            <ng-container *ngIf="groupRef !== undefined"
                                [ngTemplateOutletContext]="{item: group?.item?.type, group: group}"
                                [ngTemplateOutlet]="groupRef.template">
                            </ng-container>
                        </div>
                    </div>
                </th>
                <th *ngIf="hasColumnFreeze" class="group p-0"></th>

            </tr>
            <tr class="table-edit-inline-header">
                <!-- EMPTY COLUMN TO DRAG COLUMN -->
                <th *ngIf="!cdkScrollDisabled" [style.left.px]="0" [style.minWidth.px]="24" class="sticky-column"></th>
                <!-- SELECTION ALL TOGGLE -->
                <ng-container *ngIf="canShowSelectionToggle">
                    <th [style.left.px]="getCdkScrollCellWidth()" class="selection">
                        <mat-checkbox (change)="$event ? masterToggle() : null" *ngIf="selection.isMultipleSelection()"
                            [checked]="hasSelection && (selection | isAllSelected : rows : canSelectRowFn)"
                            [indeterminate]="hasSelection && !(selection | isAllSelected : rows : canSelectRowFn)" color="primary">
                        </mat-checkbox>
                    </th>
                </ng-container>
                <!-- FREEZE COLUMNS -->
                <ng-container *ngFor="let col of freezeColumnList">
                    <th #headerRef [attr.data-column]="col.index"
                        [attr.data-group]="$any(headers[col.index])?.name?.toUpperCase()"
                        [attr.data-type]="headers[col.index]?.type"
                        [attr.table-edit-inline-header-cell]="headers[col.index]?.description?.toLowerCase()"
                        [galvinTableHeader]="headerRef" [style.height.px]="cellSize.height"
                        [style.left.px]="col.leftPosition" [style.max-width.px]="getCellWidth(col.index)"
                        [style.top.px]="$any(tableHeaderRef)?.height" [style.width.px]="getCellWidth(col.index)"
                        class="table-edit-inline-header-cell sticky-column">
                        <div class="header">
                            <ng-container [ngTemplateOutletContext]="{item: headers[col.index]}"
                                [ngTemplateOutlet]="tableHeaderRef?.template || thTemplate">
                            </ng-container>
                        </div>
                    </th>
                </ng-container>
                <th class="p-0 m-0"></th>
                <!-- NORMAL COLUMNS HEADER -->
                <ng-container *ngFor="let col of columns; trackBy:trackByFunction">
                    <th #headerRef *ngIf="!isColumnSticky(col)" [attr.data-column]="col"
                        [attr.data-group]="$any(headers[col])?.name?.toUpperCase()"
                        [attr.data-type]="headers[col]?.type"
                        [attr.table-edit-inline-header-cell]="headers[col]?.description?.toLowerCase()"
                        [galvinTableHeader]="headerRef" [style.height.px]="cellSize.height"
                        [style.max-width.px]="getCellWidth(col)" [style.min-width.px]="getCellWidth(col)"
                        [style.top.px]="$any(tableHeaderRef)?.height" class="table-edit-inline-header-cell">
                        <div class="header">
                            <ng-container [ngTemplateOutletContext]="{item: headers[col]}"
                                [ngTemplateOutlet]="tableHeaderRef?.template || thTemplate">
                            </ng-container>
                        </div>
                    </th>
                </ng-container>
                <th class="p-0 m-0"></th>
            </tr>
        </thead>
        <tbody>
            <tr [style.height.px]="cellSize.height*yRange.first" class="space-top"></tr>
        </tbody>
        <tbody #mainTbody (cdkDropListDropped)="onDrop($any($event))" [attr.totalRows]="yRange | json"
            [cdkDropListData]="rows | slice:yRange.first:yRange.last" [cdkDropListDisabled]="cdkScrollDisabled"
            cdkDropList>
            <ng-container *ngFor="let row of rows | slice:yRange.first:yRange.last; trackBy:trackByFunction">
                <tr (cdkDragEnded)="onDragEnded()" (cdkDragStarted)="onDragStarted($event,row[0])"
                    (pointerdown)="onPointerDown($event,row[0])" [class.even]="row[0] % 2 !== 0"
                    [class.highlight]="highlightedRows.isSelected(row[0])"
                    [class.selected-drag]="isSelectedDrag(row[0])" [style.min-height.px]="cellSize.height" cdkDrag
                    class="table-edit-inline-row">
                    <!-- DRAG COLUMN -->
                    <ng-container *ngIf="!cdkScrollDisabled">
                        <td [class.cursor-copy]="multiSelect.multiSelect" [disableTooltip]="!isMultiDrag"
                            [ngbTooltip]="'COMMON.TOOLTIP_MULTI_DRAG_DROP' | translate" [style.left.px]="0"
                            cdkDragHandle class="cursor-move drag-icon sticky-column">
                            <i class="material-icons material-icons-center p-0">
                                drag_indicator
                            </i>
                        </td>
                    </ng-container>
                    <!-- SELECTION COLUMN -->
                    <ng-container *ngIf="canShowSelectionToggle">
                        <td [style.left.px]="getCdkScrollCellWidth()" class="selection">
                            <mat-checkbox (change)="$event ? selection.toggle(row) : null"
                                (click)="$event.stopPropagation()" *ngIf="getRowObject(row[1])"
                                [checked]="selection.isSelected(row)" [disabled]="canSelectRow(getRowObject(row[1]))"
                                color="primary">
                            </mat-checkbox>
                        </td>
                    </ng-container>
                    <!-- FREEZE COLUMNS -->
                    <ng-container *ngFor="let column of freezeColumnList">
                        <td [attr.data-column]="column.index" [attr.data-type]="headers[column.index]?.type"
                            [attr.table-edit-inline-row-cell]="headers[column.index]?.description?.toLowerCase()"
                            [style.height.px]="cellSize.height" [style.left.px]="column.leftPosition"
                            [style.max-height.px]="cellSize.height" [style.max-width.px]="getCellWidth(column.index)"
                            [style.min-height.px]="cellSize.height" [style.min-width.px]="getCellWidth(column.index)"
                            class="table-edit-inline-row-cell sticky-column">
                            <div class="row-table w-100">
                                <ng-container
                                    [ngTemplateOutletContext]="{row: getRowObject(row[1]), item: column.index | getColumnObject : row[1] : headers : _emptyObject, coll:headers[column.index], indexRow: row[0]}"
                                    [ngTemplateOutlet]="cellRef.template || cellTemplate">
                                </ng-container>
                            </div>
                        </td>
                    </ng-container>
                    <td [attr.colspan]="colSpanX" class="space-left p-0 m-0"></td>

                    <!-- NORMAL COLUMNS -->
                    <ng-container
                        *ngFor="let column of columns | slice:xRange.first:xRange.last; trackBy:trackByFunction">
                        <td *ngIf="!isColumnSticky(column)" [attr.data-column]="column"
                            [attr.data-type]="headers[column]?.type"
                            [attr.table-edit-inline-row-cell]="headers[column]?.description?.toLowerCase()"
                            [class.sticky-column]="isColumnSticky(column)" [style.height.px]="cellSize.height"
                            [style.left.px]="column | getLeftPosition:freezeColumnList" [style.max-height.px]="cellSize.height"
                            [style.max-width.px]="getCellWidth(column)" [style.min-height.px]="cellSize.height"
                            [style.min-width.px]="getCellWidth(column)" class="table-edit-inline-row-cell">

                            <div class="row-table w-100">
                                <ng-container
                                    [ngTemplateOutletContext]="{row: getRowObject(row[1]), item: column | getColumnObject : row[1] : headers : _emptyObject, coll: headers[column], indexRow: row[0]}"
                                    [ngTemplateOutlet]="cellRef.template || cellTemplate">
                                </ng-container>

                            </div>
                        </td>
                    </ng-container>
                    <td [attr.colspan]="headers.length - xRangeHeader.last" class="space-right p-0 m-0"></td>
                </tr>
                <ng-container *ngIf="collapse">
                    <tr class="row-collapse">
                        <td #collapse="ngbCollapse" [attr.colspan]="columns.length"
                            [ngbCollapse]="isNotCollapsed(row[0])">
                            <ng-container *ngIf="!isNotCollapsed(row[0])"
                                [ngTemplateOutletContext]="{row: getRowObject(row[1])}"
                                [ngTemplateOutlet]="rowCollapseRef.template || rowCollapseTemplate">
                            </ng-container>
                        </td>
                    </tr>
                </ng-container>
            </ng-container>
        </tbody>
        <tbody>
            <tr [style.height.px]="spaceBottom" class="space-bottom"></tr>
        </tbody>
    </table>
    <div *ngIf="loadingTable" id="loading-table-dialog">
        <galvin-progress-spinner [diameter]="70"></galvin-progress-spinner>
    </div>
    <div *ngIf="rows.length === 0 && !loadingTable" class="text-center p-5">
        {{ "MAT_TABLE.NO_RECORDS" | translate }}
    </div>

</div>

<ng-template #ghTemplate let-item="item">
    {{item}}
</ng-template>

<ng-template #thTemplate let-item="item">
    {{item.description}}
</ng-template>

<ng-template #rhTemplate let-item="item">
    {{item}}
</ng-template>

<ng-template #cellTemplate let-item="item">
    {{item}}
</ng-template>

<ng-template #rowCollapseTemplate let-item="item">
    {{item}}
</ng-template>