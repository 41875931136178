// Angular
import { Injectable } from '@angular/core';
// RxJS
import { tap } from 'rxjs/operators';
// NGRX
import { Actions, Effect, ofType } from '@ngrx/effects';
// Auth actions
import { SecurityService } from '../services/security.service';
import { AuthActionTypes, LoginGoogle, LoginLenovo, Logout } from '../_actions/auth.actions';

@Injectable()
export class AuthEffects {
    constructor(private actions$: Actions, private securityService: SecurityService) {}

    /**
     * Google Login. Starts the login process on SecurityService
     */
    @Effect({ dispatch: false })
        loginGoogle$ = this.actions$.pipe(
            ofType<LoginGoogle>(AuthActionTypes.LoginGoogle),
            tap(() => {
                this.securityService.waitValidate().subscribe();
            })
        );

    /**
     * Lenovo Login. Starts the login process on SecurityService
     */
    @Effect({ dispatch: false })
        loginLenovo$ = this.actions$.pipe(
            ofType<LoginLenovo>(AuthActionTypes.LoginLenovo),
            tap(() => {
                this.securityService.signInLenovo();
            })
        );

    /**
     * Logout. Calls the sign out function on SecurityService
     */
    @Effect({ dispatch: false })
        logout$ = this.actions$.pipe(
            ofType<Logout>(AuthActionTypes.Logout),
            tap(() => {
                this.securityService.signOut();
            })
        );
}
