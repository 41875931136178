// Angular
import {Pipe, PipeTransform} from '@angular/core';

/**
 * Returns string from Array
 */
@Pipe({
    name: 'join'
})
export class JoinPipe implements PipeTransform {
    /**
     * Transform
     *
     * @param value: any[]
     * @param char: string
     */
    transform(value: unknown[], char = ' '): string {
        if (Array.isArray(value)) {
            return value.join(char);
        }
        return value;
    }
}
