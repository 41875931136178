import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'g-brand',
    templateUrl: './g-brand.component.html',
    styleUrls: ['./g-brand.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class GBrandComponent {}
