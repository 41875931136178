<!-- begin:: Header FullScreen -->

<mat-toolbar class="toolbar-subheader">
    <span class="fullscreen-spacer"></span>
    <button
        [ngbTooltip]="clickforToggleChatbot"
        (click)="onChatbotSidePanelToggle()"
        mat-icon-button
    >
        <span class="material-icons"> forum </span>
    </button>
    <button
        [ngbTooltip]="clickforCollpaseSideBar"
        mat-icon-button ktToggle [options]="toggleOptionsMenu" (click)="onSidebarToggle()"
        class="fullscreen-icon favorite-icon"
        aria-label="Fullscream icon-button with heart icon" >
        <span class="material-icons"> menu </span>
    </button>
    <button
        [ngbTooltip]="clickforfullscreen"
        (click)="closeFullScreen()"
        mat-icon-button
        class="fullscreen-icon"
        aria-label="Fullscream icon-button with share icon"
    >
        <span class="material-icons"> expand_more </span>
    </button>
</mat-toolbar>

<!-- end:: Header FullScreen -->
