import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GalvinProgressSpinnerComponent } from './galvin-progress-spinner.component';

@NgModule({
    imports: [
        CommonModule,
        MatProgressSpinnerModule,
        MatIconModule
    ],
    declarations: [GalvinProgressSpinnerComponent],
    exports: [GalvinProgressSpinnerComponent]
})
export class GalvinProgressSpinnerModule { }
