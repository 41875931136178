import { AfterViewInit, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LayoutUtilsService, ToastrType } from '@galvin/core/_base/crud';
import { GalvinDialogGenericService, IButtonConfig } from '@galvin/core/_base/general';
import {
    IUserManagementI18n,
    UserManagementService
} from '@galvin/core/build-plan/user-management';
import { EApplication } from '@galvin/core/notification/notification.interface';
import { UtilsFlag } from '@galvin/core/utils/utils.flag';
import { AbstractControlWarn, UtilsForm } from '@galvin/core/utils/utils.form';
import { TranslateService } from '@ngx-translate/core';
import { EMPTY, Subscription } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { IUserManagement } from '../../../../core/build-plan/user-management/interfaces/user-management.interface';

interface ICountrySelect {
    value: string;
    label: string;
}
@Component({
    selector: 'user-management-dialog',
    templateUrl: './user-management-dialog.component.html',
    styleUrls: ['./user-management-dialog.component.scss']
})
export class UserManagementDialogComponent implements OnInit, AfterViewInit,  OnDestroy
{
    public app!:EApplication;
    public title!: string;
    public cancelButton!: IButtonConfig;
    public saveOrEditButton!: IButtonConfig;
    public customButton!: IButtonConfig;

    loading!: boolean;
    saving!: boolean;
    labels!: IUserManagementI18n;
    formUser!: FormGroup;
    countries: ICountrySelect[] = [];
    email?: string;
    hasFormErrors = false;
    private s10n = new Subscription();

    private readonly CORE_ID = 'coreId';
    private readonly EMAIL = 'email';
    constructor(
        public i18n: TranslateService,
        public dialogRef: MatDialogRef<UserManagementDialogComponent>,
        dialogService: GalvinDialogGenericService,
        private layoutUtilsService: LayoutUtilsService,
        private dialogGenericService: GalvinDialogGenericService,
        private dialog: MatDialog,
        private fb: FormBuilder,
        private cdr: ChangeDetectorRef,
        private userManagementService: UserManagementService,
        private utilsFlag: UtilsFlag,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
    }
    ngAfterViewInit(): void {
        if(this.data?.data?.email){
            this.formUser.controls[this.EMAIL].setValue(this.data.data.email);
            this.formUser.controls[this.EMAIL].markAsTouched();
            const validateDomain=UtilsForm.validateDomain(this.formUser.controls[this.EMAIL]);
            if (!this.data?.data?.coreId && validateDomain==null){
                const coreId = this.data?.data?.email.split('@')[0];
                this.formUser.controls[this.CORE_ID].setValue(coreId);
            }
        }
        this.cdr.detectChanges();
    }

    ngOnInit(): void {

        this.loading = false;
        this.saving = false;
        this.dialogGenericService.buttonEnabled = false;
        this.labels = this.i18n.instant('BUILD_PLAN.USER_MANAGEMENT');

        const { cancelButton, saveOrEditButton, customButton } = this.data;
        this.title = `${this.labels.DIALOG.CREATE_TITLE}`;
        this.cancelButton = cancelButton as IButtonConfig;
        this.saveOrEditButton = saveOrEditButton as IButtonConfig;
        this.customButton = customButton as IButtonConfig;
        this.setForm();
        this.formUser.statusChanges.subscribe((value) => {
            this.dialogGenericService.buttonEnabled = value === 'VALID';
        });
    }

    ngOnDestroy(): void {
        this.s10n.unsubscribe();
    }

    getCountriesAsArray(): ICountrySelect[] {
        return this.utilsFlag.getFlags().map((c) => ({
            value: c.initial.toUpperCase(),
            label: c.country
        }));
    }

    getCountryLabel(abbr: string): string {
        const label = this.countries.find((country) => country.value === abbr)?.label;
        return label ? label : '';
    }

    getCountryFlagClass(value: string): string {
        return this.utilsFlag.getFlagClassByInitial(value || ' ');
    }

    cancel(): void {
        this.close();
    }

    close(param: any = false) {
        this.dialogRef.close(param);
    }

    save(): void {
        this.saving = true;
        this.dialogGenericService.buttonEnabled = false;
        this.cdr.detectChanges();
        const validateDomain=UtilsForm.validateDomain(this.formUser.controls[this.EMAIL]);


        const user: IUserManagement = this.formUser.value;

        if (validateDomain!=null){
            user.coreId='';
        }


        if(this.data.sendApi){
            this.userManagementService
                .createUser(user)
                .pipe(
                    catchError((error: any): any => {
                        if (error.status === 400) {
                            if (
                                error.error.msg ===
                            `Already exists a Galvin User with email: (${user.email})`
                            ) {
                                this.formUser.controls['email'].setErrors({
                                    alreadyExists: true
                                });

                                this.layoutUtilsService.showToastrNotification(
                                    error.error.msg,
                                    ToastrType.error
                                );
                            } else if(error.error.msg) {
                                this.layoutUtilsService.showToastrNotification(
                                    error.error.msg,
                                    ToastrType.error
                                );
                            }
                            this.saving = false;
                            this.dialogGenericService.buttonEnabled = false;
                            return EMPTY;
                        }
                        this.cdr.detectChanges();
                        this.saving = false;
                        this.dialogGenericService.buttonEnabled = true;
                    })
                )
                .subscribe((response) => {
                    response = response as IUserManagement;
                    this.saving = false;
                    this.dialogGenericService.buttonEnabled = true;

                    this.layoutUtilsService.showToastrNotification(
                        this.labels.DIALOG.USER_CREATED,
                        ToastrType.success
                    );

                    if (response) {
                        this.close(response);
                    }
                });
        }else{
            this.dialogRef.close(user);
        }
    }
    edit(): void {
        return;
    }
    custom(): void {
        return;
    }

    hasDomainWarning(emailField: AbstractControl): boolean {
        const controlWarn = emailField as AbstractControlWarn;
        const hasDomainWarn = controlWarn.warn && controlWarn.warn.domain;
        if (hasDomainWarn) {
            this.formUser.controls[this.CORE_ID].disable();
        } else {
            this.formUser.controls[this.CORE_ID].enable();
        }
        return hasDomainWarn;
    }

    private setForm(): void {
        this.countries = this.getCountriesAsArray();
        this.formUser = this.fb.group({
            email: new FormControl('',[
                Validators.required,
                Validators.email,
                UtilsForm.validateDomainWarnExternal,
                Validators.maxLength(255),
            ]),
            coreId: new FormControl('', [UtilsForm.validateCoreId, Validators.maxLength(255), Validators.required]),
            name: new FormControl('', [
                Validators.required,
                UtilsForm.validateNameWithSpaceOrHyphen,
                Validators.maxLength(255)
            ]),
            lastName: new FormControl('', [
                Validators.required,
                UtilsForm.validateNameWithSpaceOrHyphen,
                Validators.maxLength(255)
            ]),
            country: new FormControl(null)
        });

    }

}
