import { GoogleAuth } from './core/auth/services/google-auth';
// Angular
import { OverlayModule } from '@angular/cdk/overlay';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {
    BrowserModule,
    HammerGestureConfig,
    HAMMER_GESTURE_CONFIG
} from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
// NGRX
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
// Hammer JS
import 'hammerjs';
// SVG inline
import { InlineSVGModule } from 'ng-inline-svg';
// Cookies
import { CookieService } from 'ngx-cookie-service';
// Highlight JS
import { HIGHLIGHT_OPTIONS } from 'ngx-highlightjs';
// Perfect Scroll bar
import { PerfectScrollbarConfigInterface, PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
// NGX Permissions
import { NgxPermissionsModule } from 'ngx-permissions';
//Toastr
import { ToastrModule } from 'ngx-toastr';
// Modules
import { AppRoutingModule } from './app-routing.module';
// Components
import { AppComponent } from './app.component';
import { SecurityService } from './core/auth';
import { CoreModule } from './core/core.module';
// HTTP Interceptor
import { GalvinHttpInterceptor } from './core/interceptor/galvin-http.interceptor';
// State
import { metaReducers, reducers } from './core/reducers';
// CRUD
import { LayoutUtilsService, TypesUtilsService } from './core/_base/crud';
// Layout Services
import {
    KtDialogService,
    LayoutConfigService,
    LayoutRefService,
    MenuAsideService,
    MenuConfigService,
    MenuHorizontalService,
    PageConfigService,
    SplashScreenService,
    SubheaderService
} from './core/_base/layout';
import { KTToggleService } from './core/_base/layout/directives/toggle.directive';
// Config
import { LayoutConfig } from './core/_config/layout.config';
// Fake API BuildPlan
import { PaginatorI18n } from './core/_config/paginator.config';
// Auth
import { AuthModule } from './views/pages/auth/auth.module';
// Partials
import { GoogleAnalyticsService } from '@galvin/core/analytics/google-analytics.service';
import { PartialsModule } from './views/partials/partials.module';
import { ShareModule } from './views/share/share.module';
import { ThemeModule } from './views/theme/theme.module';

// tslint:disable-next-line:class-name
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    wheelSpeed: 0.5,
    swipeEasing: true,
    minScrollbarLength: 40,
    maxScrollbarLength: 300
};

export function initializeLayoutConfig(appConfig: LayoutConfigService) {
    // initialize app by loading default demo layout config
    return function () {
        if (appConfig.getConfig() === null) {
            appConfig.loadConfigs(new LayoutConfig().configs);
        }
    };
}

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserAnimationsModule,
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        NgxPermissionsModule.forRoot(),
        PartialsModule,
        CoreModule,
        OverlayModule,
        StoreModule.forRoot(reducers, { metaReducers }),
        EffectsModule.forRoot([]),
        StoreRouterConnectingModule.forRoot({ stateKey: 'router' }),
        StoreDevtoolsModule.instrument(),
        AuthModule.forRoot(),
        TranslateModule.forRoot(),
        MatProgressSpinnerModule,
        InlineSVGModule.forRoot(),
        MatIconModule,
        ThemeModule,
        ShareModule,
        ToastrModule.forRoot({
            closeButton: true,
            newestOnTop: true,
            progressBar: true,
            positionClass: 'toast-top-right',
            preventDuplicates: true,
            tapToDismiss: true,
            timeOut: 5000,
            extendedTimeOut: 1000,
            easeTime: 300,
            easing: 'swing'
        })
    ],
    exports: [MatIconModule, CoreModule],
    providers: [
        CookieService,
        LayoutConfigService,
        LayoutRefService,
        MenuConfigService,
        PageConfigService,
        KtDialogService,
        SecurityService,
        GoogleAuth,
        SplashScreenService,
        GoogleAnalyticsService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: GalvinHttpInterceptor,
            multi: true
        },
        {
            provide: PERFECT_SCROLLBAR_CONFIG,
            useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
        },
        {
            provide: HAMMER_GESTURE_CONFIG,
            useClass: HammerGestureConfig
        },
        {
            // layout config initializer
            provide: APP_INITIALIZER,
            useFactory: initializeLayoutConfig,
            deps: [LayoutConfigService],
            multi: true
        },
        {
            provide: HIGHLIGHT_OPTIONS,
            useValue: {
                coreLibraryLoader: () => import('highlight.js/lib/core'),
                languages: {
                    typescript: () => import('highlight.js/lib/languages/typescript'),
                    css: () => import('highlight.js/lib/languages/css'),
                    xml: () => import('highlight.js/lib/languages/xml')
                },
                themePath: 'path-to-theme.css' // Optional, and useful if you want to change the theme dynamically
            }
        },
        // Paginator Translate Config.
        {
            provide: MatPaginatorIntl,
            deps: [TranslateService],
            useFactory: (translateService: TranslateService) =>
                new PaginatorI18n(translateService).getPaginatorIntl()
        },
        // template services
        SubheaderService,
        MenuHorizontalService,
        MenuAsideService,
        TypesUtilsService,
        LayoutUtilsService,
        KTToggleService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
