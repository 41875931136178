<div #genericFieldContainerRef [class.mat-elevation-z2]="isEditing" class="generic-field-container rounded">
    <ng-container *ngTemplateOutlet="stateTmpl || defaultLabelTmpl"></ng-container>
    <div *ngIf="isSaving()" class="loading">
        <mat-spinner diameter="15"></mat-spinner>
    </div>
</div>

<ng-template #defaultLabelTmpl>
    <div class="text-truncate">
        <ng-content></ng-content>
    </div>
</ng-template>

<ng-template #savingTmpl>
    <div class="generic-field-wrapper d-flex align-items-center pl-1">
        <ng-container *ngTemplateOutlet="defaultLabelTmpl"></ng-container>
    </div>
</ng-template>

<ng-template #defaultEditionTmpl>
    <div class="generic-field-wrapper d-flex align-items-center pl-1">
        <ng-container *ngTemplateOutlet="fieldTmpl; context: {field: self}"></ng-container>
    </div>
</ng-template>

<ng-template #defaultFieldTmpl [genericFieldTemplate]='$any(commonTypes)' [markAsDefault]="true">
    <input [(ngModel)]="fieldValue" [disabled]="isDisabled" [genericFieldTemplateConfig]="$any(fieldConfig)"
        (ngModelChange)="markAsDirty()" (keydown)="onKeyDownCell($event)" (blur)="onBlurCell($event)"
        [type]="fieldType" class="border-0">
</ng-template>

<ng-template [markAsDefault]="true" genericFieldTemplate="select">
    <ng-select #field [(ngModel)]="fieldValue" [clearable]="false" [closeOnSelect]="$any(fieldConfig)?.closeOnSelect"
        (ngModelChange)="markAsDirty()" (keydown)="onKeyDownCell($event)" (blur)="onBlurCell($event)"
        [disabled]="isDisabled" [items]="$any(fieldConfig).items" [multiple]="$any(fieldConfig)?.multiple || false">
        <ng-template let-clear="clear" let-items="items" ng-multi-label-tmp>
            <div *ngFor="let item of items | slice:0:2" class="ng-value">
                <span class="ng-value-label">{{item}}</span>
                <span (click)="clear(item)" aria-hidden="true" class="ng-value-icon right">×</span>
            </div>
            <div *ngIf="items.length > 2" class="ng-value">
                <span class="ng-value-label">{{items.length - 2}} more...</span>
            </div>
        </ng-template>
    </ng-select>
</ng-template>

<ng-template [markAsDefault]="true" genericFieldTemplate="percent">
    <label>
        {{fieldValue | number:'3.2-2'}}%
        <input [(ngModel)]="fieldValue" [disabled]="isDisabled" [genericFieldTemplateConfig]="$any(fieldConfig)"
            class="border-0" max="100" min="0" type="range">
    </label>
</ng-template>

<ng-template [markAsDefault]="true" genericFieldTemplate="date">
    <mat-form-field>
        <input (ngModelChange)="setValueFromDatePicker($event)" [matDatepicker]="datePickerTmpl" [ngModel]="fieldValue"
            (ngModelChange)="markAsDirty()" (keydown)="onKeyDownCell($event)" (blur)="onBlurCell($event, datePickerTmpl)" (focus)="!dirty && datePickerTmpl.open()"
            matInput readonly />
        <mat-datepicker-toggle [for]="datePickerTmpl" matSuffix></mat-datepicker-toggle>
        <mat-datepicker #datePickerTmpl></mat-datepicker>
    </mat-form-field>
</ng-template>

<ng-template [markAsDefault]="true" genericFieldTemplate="checkbox">
    <mat-checkbox (ngModelChange)="propagateChange($event)" [disabled]="isDisabled" [ngModel]="fieldValue"
        class="text-truncate" disableRipple="true">
        {{$any(fieldConfig)?.label}}
    </mat-checkbox>
</ng-template>