<div ngbDropdown placement="bottom-right" *ngIf="user$ | async as user"
    class="kt-header__topbar-item kt-header__topbar-item--user">
    <div ngbDropdownToggle class="kt-header__topbar-wrapper">
        <div class="kt-header__topbar-user" [ngClass]="{'kt-header__topbar-icon': icon}">
            <span class="kt-header__topbar-welcome kt-hidden-mobile" *ngIf="greeting">

                <ng-container *ngIf="user.domain === 'motorola';else loginLenovo">
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="18px" height="18px" viewBox="0 0 48 48"
                        class="abcRioButtonSvg">
                        <g>
                            <path fill="#EA4335"
                                d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z">
                            </path>
                            <path fill="#4285F4"
                                d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z">
                            </path>
                            <path fill="#FBBC05"
                                d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z">
                            </path>
                            <path fill="#34A853"
                                d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z">
                            </path>
                            <path fill="none" d="M0 0h48v48H0z"></path>
                        </g>
                    </svg>
                </ng-container>

                <ng-template #loginLenovo>
                    <img height="38px" src="assets/galvin/icons/lenovo.svg" style="margin-top:5px;" viewbox="0 0 48 48"
                        width="38px">
                </ng-template>
            </span>
            <span class="kt-header__topbar-welcome kt-hidden-mobile" *ngIf="greeting">Hi,</span>
            <span class="kt-header__topbar-username kt-hidden-mobile" *ngIf="greeting">{{user.name}}</span>
            <i *ngIf="icon" class="flaticon2-user-outline-symbol"></i>
            <img *ngIf="avatar" alt="Pic" [attr.src]="user.picture">
            <span *ngIf="badge"
                class="kt-badge kt-badge--username kt-badge--unified-success kt-badge--lg kt-badge--rounded kt-badge--bold">
                {{ (user.name + ' ' + user.lastName) |firstLetter }}
            </span>
        </div>
    </div>
    <div id="user-profile" ngbDropdownMenu
        class="dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl">
        <!--begin: Head -->
        <div class="kt-user-card kt-user-card--skin-dark kt-notification-item-padding-x"
            style="background-image: url(./assets/media/misc/bg-1.jpg)">
            <div class="kt-user-card__avatar">
                <img alt="Pic" [attr.src]="user.picture" />
                <span [hidden]="true" class="kt-badge kt-badge--lg kt-badge--rounded kt-badge--bold kt-font-success">
                    {{ (user.name + ' ' + user.lastName)|firstLetter }}
                </span>
            </div>
            <div id="user-profile-name" class="kt-user-card__name">
                <div>{{ (user.name + ' ' + user.lastName) }}</div>
                <small>{{user.email}}</small>
            </div>
        </div>
        <!--end: Head -->

        <!--begin: Navigation -->
        <div class="kt-notification">
            <a ngbDropdownItem [attr.href]="'https://mm-employees.appspot.com/employee/' + user.coreId" target="_blank"
                class="kt-notification__item">
                <div class="kt-notification__item-icon">
                    <i class="flaticon2-calendar-3 kt-font-success"></i>
                </div>
                <div class="kt-notification__item-details">
                    <div class="kt-notification__item-title kt-font-bold">
                        My Profile
                    </div>
                </div>
            </a>
            <div class="kt-notification__custom">
                <button id="logout-button" (click)="logout()"
                    class="btn btn-outline-brand btn-upper btn-sm btn-bold">Sign Out</button>
            </div>
        </div>
        <!--end: Navigation -->
    </div>
</div>