import {
    IGroupBuildPlanManagementI18n
} from '@galvin/core/build-plan/group-management/i18n/group-build-plan-management-i18n.interface';

export const GROUP_BUILD_PLAN_MANAGEMENT: IGroupBuildPlanManagementI18n = {
    TITLE: 'Group Management',
    SEARCH: 'Search by external group',
    CONFIRMATION_DELETE_DIALOG_TITLE: 'Delete {{amount}} External Group(s)',
    CONFIRMATION_DELETE_DIALOG_MESSAGE: 'The users of this external group will not be able to see this build plan!',
    TABLE: {
        NAME: 'External Group'
    },
    DIALOG: {
        ADD_TITLE: 'Add a External group',
        MESSAGE: {
            SAVED: 'External Group successfully saved',
            ADD: 'Add',
        },
        SEARCH: 'Search external group by name'
    },
    COMMON: {
        BUTTONS: {
            ADD_BUTTON: 'Add External Group',
        },
        DELETE_TITLE: 'Do you want to delete this External Group?',
        DELETE_CONTENT: 'This action will delete: {{name}}',
        DELETE_TOOLTIP: 'Delete External Group',
    }
};
