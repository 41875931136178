// Angular
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LocalStorageHelper } from '@galvin/core/storage/local-storage-helper.service';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { IBuildPlanAtomicUpdate, IBuildPlanDetails, IBuildPlanStatusUpdate } from '..';
// Environments
import { environment } from '../../../../../environments/environment';
import { EBuildPlanStatusLabel } from '../enums/build-type-status-label.enum';
import { IBuildPlanCreate } from '../interfaces/build-plan-create.interface';
import { IBuildPlanStatusConfigAccessory } from '../interfaces/build-plan-status-config-accessory.interface';
import { IBuildPlanStatusConfiguration } from '../interfaces/build-plan-status-configuration.interface';
import { IBuildPlanSwimLaneStatus } from '../interfaces/build-plan-swimlane-status.interface';
import { IBuildPlan } from '../interfaces/build-plan.interface';
import { IBuildPlanPmdProject } from './../interfaces/build-plan-pmd-project.interface';
import { IBuildPlanSelectPlusBuildPlanList, IFindBPByURLOrLocalStorageName } from '@galvin/views/pages/finance/po-quotation/finance-build-plan-selector/finance-build-plan-selector.component';
import { BuildPlanDetailsService } from './build-plan-details.service';

@Injectable({
    providedIn: 'root'
})
export class BuildPlanService {
    private readonly URL_BUILD_PLAN = 'build-plan/build-plan-management';
    private readonly API_BUILD_PLAN_URL = `${environment.baseUrlApi}/` + this.URL_BUILD_PLAN;
    private readonly LOCAL_STORAGE_BUILD_PLAN_DETAILS = 'buildPlanService@BuildPlanDetails';

    constructor(private http: HttpClient, private localStorageHelper: LocalStorageHelper, private buildPlanDetailsService: BuildPlanDetailsService) {}

    /**
     * Get All Build Plans
     */
    getBuildPlans(): Observable<IBuildPlan[]> {
        return this.http.get<IBuildPlan[]>(`${this.API_BUILD_PLAN_URL}`).pipe(
            map((response: any) => {
                return response.content;
            })
        );
    }

    /**
     * Get All Build Plans With Details
     */
    getBuildPlansWithDetails(): Observable<IBuildPlanDetails[]> {
        return this.http.get<IBuildPlanDetails[]>(`${this.API_BUILD_PLAN_URL}/details`).pipe(
            map((response: any) => {
                return response.content;
            })
        );
    }

    /**
     * Get By Status In Group of Statuses and Device Names
     */
    getBuildPlansByStatusGroup(value: string, group: string): Observable<IBuildPlan[]> {
        return this.http
            .get<IBuildPlan[]>(`${this.API_BUILD_PLAN_URL}/get-by-status-group`, {
                params: { value, group }
            })
            .pipe(
                map((response: any) => {
                    return response.content;
                })
            );
    }

    updateCacheDetailsByInternalProductName(name: string) {
        this.http
            .get<IBuildPlan[]>(`${this.API_BUILD_PLAN_URL}/find-by-internal-product-name`, {
                params: { internalProductName: name }
            })
            .pipe(
                map((response: any) => {
                    return response.content;
                }),
                catchError(() => {
                    this.localStorageHelper.removeItem(this.LOCAL_STORAGE_BUILD_PLAN_DETAILS);
                    return of({});
                })
            ).subscribe((response) => {

                const newValue = JSON.stringify(response);
                const oldValue = this.localStorageHelper.getItem(this.LOCAL_STORAGE_BUILD_PLAN_DETAILS);

                if(newValue !== oldValue) {
                    this.buildPlanDetailsService.emit(response);
                }

                this.localStorageHelper.setItem(this.LOCAL_STORAGE_BUILD_PLAN_DETAILS, JSON.stringify(response));
            });
    }

    getDetailsByInternalProductNameCached(name: string): Observable<IBuildPlanDetails> {
        const cachedBpStr = this.localStorageHelper.getItem(this.LOCAL_STORAGE_BUILD_PLAN_DETAILS);
        const cachedBpObj = JSON.parse(cachedBpStr || '{}') as IBuildPlanDetails;

        if(cachedBpStr && cachedBpObj.internalProductName === name) {
            this.updateCacheDetailsByInternalProductName(name);
            return of(cachedBpObj);
        }else {
            return this.http
                .get<IBuildPlan[]>(`${this.API_BUILD_PLAN_URL}/find-by-internal-product-name`, {
                    params: { internalProductName: name }
                })
                .pipe(
                    map((response: any) => {
                        return response.content;
                    }),
                    tap((response: any) => {
                        this.localStorageHelper.setItem(this.LOCAL_STORAGE_BUILD_PLAN_DETAILS, JSON.stringify(response));
                    }),
                    catchError(() => {
                        this.localStorageHelper.removeItem(this.LOCAL_STORAGE_BUILD_PLAN_DETAILS);
                        return of({});
                    })
                );
        }
    }

    /**
     * Get Build Plan Details by ID
     */
    getDetailsByInternalProductName(name: string): Observable<IBuildPlanDetails> {
        return this.http
            .get<IBuildPlan[]>(`${this.API_BUILD_PLAN_URL}/find-by-internal-product-name`, {
                params: { internalProductName: name }
            })
            .pipe(
                map((response: any) => {
                    return response.content;
                })
            );
    }

    /**
     * Update Build Plan
     */
    atomicUpdate(data: IBuildPlanAtomicUpdate): Observable<void> {
        return this.http.put<void>(`${this.API_BUILD_PLAN_URL}`, data).pipe(
            map((response: any) => {
                return response.content;
            })
        );
    }

    /**
     * Get Swimlanes to the steps in Status & Approvals
     */
    getSwimlanes(idBuildPlan: number): Observable<IBuildPlanSwimLaneStatus> {
        return this.http
            .get<IBuildPlanSwimLaneStatus>(`${this.API_BUILD_PLAN_URL}/${idBuildPlan}/swimlanes`)
            .pipe(
                map((response: any) => {
                    return response.content;
                })
            );
    }

    /**
     * Update Status Build Plan
     * @param idBuildPlan
     * @param status
     */
    updateStatusBuildPlan(
        idBuildPlan: number,
        status: EBuildPlanStatusLabel,
        body: IBuildPlanStatusUpdate
    ): Observable<IBuildPlan> {
        return this.http
            .put<IBuildPlan>(`${this.API_BUILD_PLAN_URL}/${idBuildPlan}/status/${status}`, body)
            .pipe(
                map((response: any) => {
                    return response.content;
                })
            );
    }

    /*
     * Return All Build Plans only with id and internal product name.
     */
    getBuildPlansWithIdAndInternalProductName(): Observable<IBuildPlan[]> {
        return this.http.get<IBuildPlan[]>(`${this.API_BUILD_PLAN_URL}/all`).pipe(
            map((response: any) => {
                return response.content;
            })
        );
    }

    createdBuildPlan(buildPlan: IBuildPlanCreate): Observable<IBuildPlan> {
        return this.http
            .post<any>(`${this.API_BUILD_PLAN_URL}`, buildPlan)
            .pipe(map((res) => res.content));
    }

    getBuildPlanByMultiplesNames(names: IFindBPByURLOrLocalStorageName): Observable<IBuildPlanSelectPlusBuildPlanList> {
        return this.http
            .post<any>(`${this.API_BUILD_PLAN_URL}/find-by-multiple-names`, names)
            .pipe(map((res) => res.content));
    }

    /**
     * Get configurations affected by build plan status transition
     */
    getConfigurationsAffectedByStatusTransition(
        idBuildPlan: number,
        status: EBuildPlanStatusLabel
    ): Observable<IBuildPlanStatusConfiguration[]> {
        return this.http
            .get<IBuildPlanStatusConfiguration[]>(
                `${this.API_BUILD_PLAN_URL}/${idBuildPlan}/status/${status}/configurations`
            )
            .pipe(
                map((response: any) => {
                    return response.content;
                })
            );
    }

    getConfigsAndAccessoriesAffectedByStatusTransition(
        idBuildPlan: number,
        status: EBuildPlanStatusLabel
    ): Observable<IBuildPlanStatusConfigAccessory> {
        return this.http
            .get<IBuildPlanStatusConfigAccessory>(
                `${this.API_BUILD_PLAN_URL}/${idBuildPlan}/status/${status}/configurations-accessories`
            )
            .pipe(
                map((response: any) => {
                    return response.content;
                })
            );
    }

    /**
     * Get Build Plan Details by ID
     */
    findAllPmdProjectsBySaDate(): Observable<IBuildPlanPmdProject[]> {
        return this.http
            .get<IBuildPlanPmdProject[]>(`${this.API_BUILD_PLAN_URL}/get-pmd-projects`)
            .pipe(
                map((response: any) => {
                    return response.content;
                })
            );
    }

    /**
     * Delete a Build Plan
     * @params buildPlanId ID of build plan being deleted
     */
    deleteBuildPlan(buildPlanId: number): Observable<any> {
        return this.http.delete<any>(`${this.API_BUILD_PLAN_URL}/${buildPlanId}`);
    }

    findAllGroups(buildPlanId: number): Observable<any> {
        return this.http.get(`${this.API_BUILD_PLAN_URL}/${buildPlanId}/groups`);
    }

    setGroups(buildPlanId: number, groups: any[]): Observable<any> {
        return this.http.put(`${this.API_BUILD_PLAN_URL}/${buildPlanId}/groups`, groups);
    }
}
