import { HttpClient } from '@angular/common/http';
import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthNoticeService, User, UserLoaded } from '@galvin/core/auth';
import { E2eRunnerHandler } from '@galvin/core/utils/e2e/e2e-runner-handler';
import { AppConstants } from '@galvin/views/share/app-constants';
import { Store } from '@ngrx/store';
import jwt_decode from 'jwt-decode';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../../../../environments/environment';
import { AppState } from '../../reducers';
import { LocalStorageHelper } from '../../storage/local-storage-helper.service';
import { userToLocalStorageUser } from '../_models/local-storage-user.model';
import { SecurityBaseService } from './security-base.service';
import { UserPermissionsService } from './user-permissions.service';
import { NotificationComponentService } from '@galvin/views/partials/layout/topbar/notification/notification.component.service';

declare var google: any;

export const tokenSymbol = Symbol.for('accessToken');

// eslint-disable-next-line no-undef
export const internalToken = new Proxy({ [tokenSymbol]: '' }, {});

@Injectable({
    providedIn: 'root'
})
export class SecurityMotorolaService extends SecurityBaseService implements OnDestroy {

    private intervalRefreshToken: any = null;

    public constructor(
        protected override localStorageHelper: LocalStorageHelper,
        protected override cookieService: CookieService,
        protected override ngZone: NgZone,
        protected override router: Router,
        protected override httpClient: HttpClient,
        protected override store: Store<AppState>,
        protected override authNoticeService: AuthNoticeService,
        protected override userPermissionsService: UserPermissionsService,
        protected route: ActivatedRoute,
        private notificationComponentService:NotificationComponentService

    ) {
        super(localStorageHelper,cookieService,ngZone,router,httpClient,store,authNoticeService,userPermissionsService);
    }

    override ngOnDestroy() {
        super.ngOnDestroy();
        this.destroyCheckToken();
    }


    /**
     * Sets new user information as the logged in user, removing sensible information from local storage
     *
     * @param user
     */
    public override setUser(user: User): void {
        //set user picture
        if (this.user) {
            user.sub = this.user.sub;
            user.domain = this.user.domain;
            if (this.user.picture) {
                user.picture = this.user.picture;
            } else {
                user.picture = 'assets/images/user.png';
            }
        }
        if (user.roles) {
            this.userPermissionsService.setUserRoles(user.roles);
        }
        this.userPermissionsService.setInformationUser(user);
        if (user.qtRefreshToken) {
            this.setQtyRefreshTokenExp(user.qtRefreshToken);
        }
        this.user = user ? { ...user } : null;
        this.localStorageHelper.setLoggedUser(userToLocalStorageUser(user));
        this.store.dispatch(new UserLoaded({ user }));
    }

    /**
     * Refresh Token when there are LEFT_MINUTES_TO_REFRESH_TOKEN minutes left to expire the access token
     *
     */
    public renewAsExpiredToken = () => {
        let user: User | null = this.getInMemoryUser();
        if (!user || !user.expirationDate) {
            return;
        }

        let qtRefreshTokenExp = this.getQtyRefreshTokenExp();
        const left_minutes_to_refresh_token: number = 1;
        const current_time = new Date().getTime();
        const expire_time = (new Date(user.expirationDate)).getTime();
        const mili_seconds_renew = left_minutes_to_refresh_token * (60 * 1000);

        if (qtRefreshTokenExp <= 0) {
            if (current_time > expire_time) {
                this.logout();
            }
            return;
        }
        if (current_time > (expire_time - mili_seconds_renew)) {
            qtRefreshTokenExp--;
            this.refreshToken();
            this.setQtyRefreshTokenExp(qtRefreshTokenExp);
        }
    };

    /**
     * Removes logged in user information after logout
     */
    public override deleteUser(): void {
        this.user = null;
        this.localStorageHelper.removeLoggedUser();
        this.cookieService.delete('access_token_hd');
        this.cookieService.delete('refresh_token_hd');
        this.cookieService.delete('session_id_hd');
    }

    public destroyCheckToken(){
        if (this.intervalRefreshToken) {
            clearInterval(this.intervalRefreshToken);
        }
    }

    public initRefreshToken = () => {
        this.destroyCheckToken();
        var user = this.getInMemoryUser();
        if (user && user.domain === AppConstants.MOTOROLA ){
            this.renewAsExpiredToken();
            this.intervalRefreshToken = setInterval(this.renewAsExpiredToken, 1000 * 60);
        }
    };

    public refreshToken = () => {
        this.httpClient
            .post<User>(environment.baseUrl + '/auth/validate', {})
            .subscribe(
                (response) => {
                    this.setUser(response);

                },
                () => {
                    this.redirectToLogin();
                    this.authNoticeService.setNotice(
                        'Failed to refresh Token Motorola user',
                        'danger'
                    );
                    this.logging.next(false);
                }
            );
    };

    public getRedirectUrl(){
        let redirect_to= this.route.snapshot.queryParamMap.get('redirect_to');
        if (redirect_to && redirect_to.includes('#')){
            redirect_to=redirect_to.split('#')[1];
        }
        return redirect_to;
    }

    /**
     * Save logged user, initRefreshToken and redirect to home
     *
     * @param user: user token
     */
    public handlePostLogin(user: User) {
        this.setUser(user);
        this.initRefreshToken();
        this.notificationComponentService.loadNotification();

        if (this.router.url.includes('/login')) {
            let redirectTo= this.getRedirectUrl();
            this.ngZone.run(() => this.router.navigateByUrl(redirectTo ? redirectTo : '/')).then();
        }
    }



    /**
     * Validates Motorola user with the server side and sets the http only cookie
     *
     * @param token: user token
     */
    public login(token: string): void {
        const userDecode: any = jwt_decode(token);
        this.saveUser(userDecode);
        if (new E2eRunnerHandler().isRunnerTest()){
            this.setTokenByTest(token);
        }

        this.httpClient
            .post<User>(environment.baseUrl + '/auth/login', { 'token': token })
            .subscribe(
                (response) => {
                    this.handlePostLogin(response);
                }
            );
    }


    public logout(): void {
        this.destroyCheckToken();
        this.removeUserByLogout();
        this.httpClient
            .post<any>(environment.baseUrl + '/auth/logout', {})
            .subscribe();
    }

    public logoutGoogle():void{
        google.accounts.id.initialize({
            client_id: environment.googleClientID,
            auto_select: false
        });
        google.accounts.id.disableAutoSelect();
    }

    private removeUserByLogout(){
        var user = this.getInMemoryUser();
        if (user && user.email){
            this.logoutGoogle();
            this.deleteUser();
        }
    }

    /**
     * Clears the user information and redirects the user to login page
     */
    public override redirectToLogin(): void {
        this.destroyCheckToken();
        this.deleteUser();
        this.router.navigate(['login']);
    }
}
