import { SelectionModel } from '@angular/cdk/collections';
import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { ToolbarComponent } from '@galvin/views/partials/layout/toolbar/toolbar.component';
import { Subscription } from 'rxjs';

export enum EStateExpected {
    NONE = 'none',
    ONE = 'one',
    MANY = 'many',
    PARTIAL = 'partial',
    ALL = 'all'
}

export type StateExpected = 'none' | 'one' | 'many' | 'partial' | 'all';

export type StateExpectedContext = StateExpected | EStateExpected;

@Directive({
    selector: '[stateExpected]',
    exportAs: 'stateExpected'
})
export class StateExpectedDirective implements OnDestroy, OnInit {
    private sub8n = new Subscription();
    private hasView = false;

    constructor(
        private template: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private hostToolbar: ToolbarComponent
    ) {}

    private _state!: StateExpectedContext;

    @Input('stateExpected')
    set state(mode: StateExpectedContext) {
        this._state = mode;
        this.updateView();
    }

    get selectionModel(): SelectionModel<unknown> {
        return this.hostToolbar.selection;
    }

    get hasSelection(): boolean {
        return this.hostToolbar.selection.hasValue();
    }

    get allSelected(): boolean {
        return this.hostToolbar.selection.selected.length === this.hostToolbar.selectionMax;
    }

    static ngTemplateContextGuard(
        dir: StateExpectedDirective,
        ctx: unknown
    ): ctx is StateExpectedContext {
        `${dir}${ctx}`;
        return true;
    }

    ngOnDestroy(): void {
        this.sub8n.unsubscribe();
    }

    ngOnInit(): void {
        this.sub8n.add(
            this.hostToolbar.selection.changed.subscribe(() => {
                this.updateView();
            })
        );
    }

    buildTemplate(): void {
        this.viewContainer.createEmbeddedView(this.template);
    }

    private updateView(): void {
        switch (true) {
        case this._state === EStateExpected.PARTIAL && this.hasSelection: {
            if (!this.hasView) {
                this.viewContainer.createEmbeddedView(this.template);
                this.hasView = true;
            }
            break;
        }
        case this._state === EStateExpected.ONE &&
                this.hasSelection &&
                this.selectionModel.selected.length === 1: {
            if (!this.hasView) {
                this.viewContainer.createEmbeddedView(this.template);
                this.hasView = true;
            }
            break;
        }
        case this._state === EStateExpected.MANY &&
                this.hasSelection &&
                this.selectionModel.selected.length > 1: {
            if (!this.hasView) {
                this.viewContainer.createEmbeddedView(this.template);
                this.hasView = true;
            }
            break;
        }
        case this._state === EStateExpected.NONE && !this.hasSelection: {
            if (!this.hasView) {
                this.viewContainer.createEmbeddedView(this.template);
                this.hasView = true;
            }
            break;
        }
        case this._state === EStateExpected.ALL && this.hasSelection && this.allSelected: {
            if (!this.hasView) {
                this.viewContainer.createEmbeddedView(this.template);
                this.hasView = true;
            }
            break;
        }
        default: {
            if (this.hasView) {
                this.viewContainer.clear();
                this.hasView = false;
            }
        }
        }
    }
}
