export interface IRequestPreviewI18n {
    MASS_EDIT_LABEL: string;
    TOTAL_REQUEST_FROM_TEAM: string;
    DETAILS: string;
    CLONE: string;
    EDIT: string;
    DETAILS_ACCESSORY: string;
    FILTER_BY_ONLY_REQUESTS: string;
    RESET_FILTERS: string;
    TOTAL_REQUEST_FULL_VIEW: string;
    TOTAL_PORTABLE_REQUEST_FULL_VIEW: string,
    TOTAL_BOARDS_REQUEST_FULL_VIEW: string,
    SHOW_TOTAL_BOARDS:string,
    HIDE_TOTAL_BOARDS:string,
    SHOW_DETAILS_PORTABLE: string,
    HIDE_DETAILS_PORTABLE: string,
    SHOW_DETAILS_BOARDS: string,
    HIDE_DETAILS_BOARDS: string,
    SPECIAL_REQUIREMENTS_TITLE: string;
    COLLAPSE_HEADER_INFO: string;
    UNCOLLAPSE_HEADER_INFO: string;
    CANCELED_CONFIGURATION: string;
    NO_DATA: string;
    NO_DATA_FILTER: string;
    COPY: string;
    COPY_SHORTCUT: string;
    CUT: string;
    CUT_SHORTCUT: string;
    PASTE: string;
    PASTE_SHORTCUT: string;
    ERASE: string;
    ERASE_SHORTCUT: string;
    COPY_SUCCESS: string;
    PASTE_OPERATION_EXCEED_LIMIT: string;
    SELECTOR_TEAMS_PARAMETERS: string;
    FILTER: string;
    QUICK_ACTION: string;
}
