import { GenericResource } from '@galvin/core/_base/resource';
import {
    ISkuMatrixManagementVO,
    ISkuMatrixManagement,
    ISkuMatrixManagementMassive,
    ISkuMatrixCloneVO
} from '../interfaces/sku-matrix-management.interface';

export type SkuMatrixManagementContentType =
    | ISkuMatrixManagementVO
    | ISkuMatrixManagementVO[]
    | ISkuMatrixManagement
    | ISkuMatrixManagement[]
    | ISkuMatrixManagementMassive
    | ISkuMatrixCloneVO
    | { skuIds: number[] };

export class SkuMatrixManagementResource extends GenericResource<SkuMatrixManagementContentType> {
    constructor(public override content?: SkuMatrixManagementContentType) {
        super('/build-plan/build-plan-management/sku-matrix', content);
    }
}
