export class StyleUtils {
    static readonly CMF_COLORS = [
        'Black',
        'Gold',
        'Gondwana',
        'Jasper',
        'Jazz Blue',
        'White',
        'Stardust',
        'Cosmos'
    ];
    public static isColor(colorName: string): boolean {
        const colorNameExists = StyleUtils.CMF_COLORS.some(
            (color) => color.toLowerCase() === colorName.toLowerCase()
        );
        if (colorNameExists) {
            return true;
        }
        const s = new Option().style;
        s.color = colorName;
        return s.color == colorName;
    }
}
