<ng-container *ngIf="isLoading">
    <mat-dialog-content class="justify-content-center">
        <galvin-progress-spinner></galvin-progress-spinner>
    </mat-dialog-content>
</ng-container>
<ng-container *ngIf="!isLoading">
    <div class="content d-flex flex-row">
        <div class="overflow-auto ml-3 mx-height-300" id="propagate-first-section"
            [ngClass]="dataSecondSection && dataSecondSection.length > 0 ? 'w-50' : 'w-100'">
            <p class="d-flex flex-column bd-highlight mb-3 justify-content-center title">
                <strong innerHTML="{{getFirstSectionTitle()}}">
                </strong>
            </p>

            <galvin-tree [data]="dataFirstSection" [getChildren]="childrenFirstSection"
                [transformer]="transformerFirstSection" (nodesSelected)="onNodesSelectedFirstSection($event)">

                <ng-template #templateBpStatus let-node="node">
                    <build-plan-status-icon [bpStatus]="node.name"></build-plan-status-icon>
                </ng-template>

                <ng-container *galvinTreeNodeTmp="let node=node;level:0">
                    <ng-container [ngTemplateOutlet]="templateBpStatus" [ngTemplateOutletContext]="{node:node}">
                    </ng-container>
                </ng-container>
                <ng-container *galvinTreeNodeTooltipTmp="let node=node;level:0">
                    <build-plan-status-icon [bpStatus]="node.name" [toolTip]="true"></build-plan-status-icon>
                </ng-container>

                <ng-container *galvinTreeNodeTmp="let node=node;level:1">
                    <span class="text-primary font-weight-bold w-100 text-truncate">
                        {{node.name}}
                    </span>
                </ng-container>
                <ng-container *galvinTreeNodeTooltipTmp="let node=node;level:1">
                    {{node.name}}
                </ng-container>

                <ng-container *galvinTreeNodeTmp="let node=node;level:2">
                    <span class="text-dark font-weight-bold w-100 text-truncate">
                        {{node.name}}
                    </span>
                </ng-container>
                <ng-container *galvinTreeNodeTooltipTmp="let node=node;level:2">
                    {{node.name}}
                </ng-container>

            </galvin-tree>

        </div>
        <ng-container *ngIf="dataSecondSection && dataSecondSection.length > 0">
            <div class="overflow-auto ml-3 w-50 mx-height-300" id="propagate-second-section">
                <p class="d-flex flex-column bd-highlight mb-3 justify-content-center showBpAssociationsTitle">
                    <strong innerHTML="{{titleSecondSection}}">
                    </strong>
                </p>

                <galvin-tree [data]="dataSecondSection" [getChildren]="childrenSecondSection"
                    [transformer]="transformerSecondSection" (nodesSelected)="onNodesSelectedSecondSection($event)">

                    <ng-template #tempalteBpStatus let-node="node">
                        <build-plan-status-icon [bpStatus]="node.name"></build-plan-status-icon>
                    </ng-template>

                    <ng-container *galvinTreeNodeTmp="let node=node;level:0" ngSwitch="{{typeLevelZero}}">
                        <ng-container *ngSwitchCase="'BpStatus'">
                            <ng-container [ngTemplateOutlet]="tempalteBpStatus" [ngTemplateOutletContext]="{node:node}">
                            </ng-container>
                        </ng-container>
                        <ng-container *ngSwitchCase="'DoubleRow'">
                            <div class="checkbox-label">
                                <span class="text-dark font-weight-bold text-truncate">{{node.name}}</span>
                                <span
                                    class="badge badge-secondary text-truncate right-gray-size">{{node.suggestName}}</span>
                            </div>
                        </ng-container>
                        <ng-container *ngSwitchDefault>
                            <ng-container>
                                <span class="text-dark font-weight-bold text-truncate">
                                    {{node.name}}
                                </span>
                            </ng-container>
                        </ng-container>
                    </ng-container>

                    <ng-container *galvinTreeNodeTooltipTmp="let node=node;level:0" ngSwitch="{{typeLevelZero}}">
                        <ng-container *ngSwitchCase="'BpStatus'">
                            <build-plan-status-icon [bpStatus]="node.name" [toolTip]="true"></build-plan-status-icon>
                        </ng-container>
                        <ng-container *ngSwitchCase="'DoubleRow'">
                            <span>
                                {{getName(node.name, node.suggestName)}}
                            </span>
                        </ng-container>
                        <ng-container *ngSwitchDefault>
                            <span>
                                {{node.name}}
                            </span>
                        </ng-container>
                    </ng-container>

                    <ng-container *galvinTreeNodeTmp="let node=node;level:1">
                        <span class="text-primary font-weight-bold w-100 text-truncate">
                            {{node.name}}
                        </span>
                    </ng-container>
                    <ng-container *galvinTreeNodeTooltipTmp="let node=node;level:1">
                        {{node.name}}
                    </ng-container>

                    <ng-container *galvinTreeNodeTmp="let node=node;level:2">
                        <ng-container *ngIf="showSuggestName">
                            <div class="checkbox-label-second">
                                <span class="text-dark font-weight-bold text-truncate">{{ node.name }}</span>
                                <span
                                    class="badge badge-secondary text-truncate right-gray-size">{{  node.suggestName }}</span>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="!showSuggestName">
                            <span class="text-dark font-weight-bold text-truncate">
                                {{getName(node.name, node.suggestName)}}
                            </span>
                        </ng-container>
                    </ng-container>
                    <ng-container *galvinTreeNodeTooltipTmp="let node=node;level:2">
                        <ng-container *ngIf="showSuggestName">
                            {{getName(node.name, node.suggestName)}}
                        </ng-container>
                        <span *ngIf="!showSuggestName">
                            {{node.name}}
                        </span>
                    </ng-container>

                </galvin-tree>

            </div>
        </ng-container>
    </div>

</ng-container>