import { ACCESSORY_REQUEST } from '@galvin/core/build-plan/accessory-request-management/i18n/accessory-request.en';
import { DSR } from '@galvin/core/build-plan/dsr-management/i18n/dsr.en';
import { NOTIFICATIONS } from '@galvin/core/build-plan/notifications/en';
import { REPORTS } from '@galvin/core/build-plan/reports/i18n/reports.en';
import { REQUEST_PREVIEW } from '../../../views/pages/build-plan/build-plan-management/request-preview';
import { ACCESSORY_PARAMETERS } from '../../build-plan/field-value-management/accessories-parameters/i18n/accessories-parameters.en';
import { LOCATION } from '../../build-plan/field-value-management/location/i18n/location.en';
// USA
import { FAQ } from '@galvin/core/build-plan/faq/i18n/faq.en';
import { GROUP_BUILD_PLAN_MANAGEMENT } from '@galvin/core/build-plan/group-management/i18n/group-build-plan-management.en';
import { CDC_SHIPMENT } from '@galvin/core/cdc/shipment/i18n/shipment.en';
import { CDC_SORTING } from '@galvin/core/cdc/sorting/i18n/sorting.en';
import { TEAM_PARAMETERS } from '../../build-plan/field-value-management/team-parameters/i18n/team-parameters.en';
import { BUILD_PLAN_IMPORT_APPROVAL } from '../../build-plan/import-approval/i18n/build-plan-import-approval.en';
import { EXPORTS } from '../../build-plan/management/exports/i18n/exports.en';
import { BUILD_PLAN_MANAGEMENT } from '../../build-plan/management/i18n/build-plan-management.en';
import { BUILD_PLANS } from '../../build-plan/management/i18n/build-plan.en';
import { PROTOTYPE_REQUEST } from '../../build-plan/prototype-request-management/i18n/prototype-request.en';
import { SCOPE_MANAGEMENT } from '../../build-plan/scope-management/i18n/scope.en';
import { USER_MANAGEMENT } from '../../build-plan/user-management/i18n/user-management.en';
import { CONFIGURATION } from './../../build-plan/configuration-management/i18n/configuration.en';
import { FVC_PARAMETERS } from './../../build-plan/field-value-management/fvc-parameters/i18n/fvc-parameters.en';
import { SPECIAL_REQUIREMENTS } from './../../build-plan/field-value-management/special-requirements/i18n/special-requirements.en';
import { TEAM_MANAGEMENT } from './../../build-plan/team-management/i18n/team-management.en';
import { PO_QUOTATION } from '@galvin/core/finance/i18n/po-quotation.en';
import { SKU_MATRIX_MANAGEMENT } from '@galvin/core/build-plan/management/sku-matrix';

export const locale = {
    lang: 'en',
    data: {
        HOME: {
            TITLE: 'Galvin',
            BODY: 'Galvin is a PDT platform development initiative to centralize and manage all Prototypes lifecycle at Motorola, since the conception, Build Plan request, CDC, control, and arrival to the end users. Today, different Motorola processes manage and control these devices, and Galvin is a initiative to centralize and patternize all of these processes gettings of spreadsheets and email processes, making devices traceable and leaders getting easily and confident metrics feedback dynamically.',
            USER_GUIDE: {
                REQUEST_SOP: 'Galvin Request SOP.E',
                TPM_SOP: 'Galvin TPM SOP (in chinese)',
                PROTOTYPE_REQUEST: 'Prototype Request',
                REQUEST_BP_LOCKED: 'Request after BP is Locked',
                GROUPS: 'Manage your Groups',
                BP_STATUS: 'Build Plan Status',
                FVC_TUNING: 'Configuration/FVCs Tuning',
                ADD_ON: 'Galvin Add-on User Guide',
                EXPORT: 'Export BP Data',
                CDC_SORTING: 'CDC Sorting Tracker',
                BIGQUERY: 'Bigquery User Guide',
                NEW_TEAM: 'Request New Team(s)'
            }
        },
        TOPBAR: {
            BUILD_PLAN: 'Build Plan',
            CDC: 'CDC',
            FINANCE: 'Finance',
            PROTOTYPE_REQUEST: 'Prototype Request',
            MY_PROTOTYPES: 'My Prototypes',
            CONFIGURATIONS: 'Configuration (FVCs)',
            DSR: 'DSR',
            ACCESSORY_REQUEST: 'Accessory Request',
            MY_ACCESSORIES: 'My Accessories',
            PROTOTYPE_SORTING: 'Prototype Sorting',
            TRACKER: 'Accessory Tracker',
            CREATE_SHIPMENT: 'Create Shipment',
            LIST_SHIPMENT: 'List Shipment',
            PO_QUOTATION: 'PO/Quotation'
        },
        BANNER_INFORMATION: {
            TITLE: 'Information'
        },
        CDC: {
            TITLE: 'What is CDC?',
            BODY: 'CDC (Central Distribution Center) is a module developed in Galvin with the objective of helping Motorola/Lenovo CDC Teams to manage and track shipment process of samples requested through Galvin Build Plan, in a centralized fashion. <br/>Main features comprehend:<ul><li> <b>Sorting:</b> GPMs will be able to assign manufactured uParts samples, from different Factories in the world, to a sample requestor from Build Plan;</li> <li><b>Shipment/Receival:</b> Tracking and Management of Packages to the right Destinations, according to dynamic CDC rules;</li><li><b>Tracking:</b> Identify current location of samples and packages, based on Shipment process and samples scans</li></ul>',
            DOCUMENTATIONS: 'Documentation links',
            WORKS: 'How does it work?',
            USER_GUIDE: 'User Guide',
            DEVELOPMENT_GUIDE: 'Development Guide',
            INFORMATION_ERROR: 'Ship to, Ship from and Carrier must be inputed',
            CDC_SORTING,
            CDC_SHIPMENT
        },
        FINANCE: {
            TITLE: 'What is Finance module in Galvin?',
            BODY: 'This module handles the tracking and correlation of finance data with build plan information. Its primary functions include managing purchase orders (POs) and purchase requisitions (PRs) for factory build requests according to hardware revision and business unit. Additionally, it facilitates real-time tracking of shipping expenses for each country/city in the system, aligning with the requirements of build plan requestors.',
            PO_QUOTATION
        },
        BUILD_PLAN: {
            TITLE: 'What is Build Plan?',
            BODY: 'Build Plan is the initiative to formalize in a patternized way all requests of Prototypes cross Motorola Company, since the idealization of a product, till the manufacturing, in order to be consumed by Internal Motorola teams for different purposes, like Testing, Automation, Marketing, etc.',
            BUILD_PLANS,
            BUILD_PLAN_MANAGEMENT,
            BUILD_PLAN_IMPORT_APPROVAL,
            DIALOG_CREATE: {
                TITLE: 'Create a new Build Plan',
                NAME: 'Build Plan Name',
                NAME_ERROR:
                    'The internal product name must have only numbers, letters, parenthesis and hifen.',
                DATA_IMPORT: 'Data Import',
                FROM_BUILD_PLAN: 'Import data from another Build Plan',
                SUCCESSFUL: 'Build Plan successfully created!',
                NAME_ALREADY_EXISTS: 'There is already a Build Plan with that name.',
                MESSAGE_CREATING:
                    'Creating the Build Plan <i class="ml-1 fas fa-spinner fa-spin"></i>',
                COPY_OPTION_TEAM_TOOLTIP:
                    'If checked: Teams Associations will be copied to the new Build Plan. If unchecked: Teams will be copied from the template at Team Management.',
                COPY_OPTION_SCOPE_TOOLTIP:
                    'If checked: Scope Associations will be copied to the new Build Plan. If unchecked: Scopes will be copied from the template at Scope Management.',
                CONFIGURATION_STEP: 'Configuration',
                ACCESSORY_STEP: 'Accessory',
                COMPLETED_STEP: 'Completed',
                COMPLETED_STEP_DETAILS: {
                    BUILD_PLAN_NAME: 'Build Plan Name: ',
                    DATA_IMPORT: 'Data Import: ',
                    CONFIGURATION_COPIES: 'Configuration Copies: ',
                    ACCESSORY_COPIES: 'Accessory Copies: ',
                    COPY_FROM: 'Copy From: ',
                    COPY_OPTIONS: 'Copy Options: '
                },
                BUILD_PLAN_STEP: 'Build Plan Info'
            },
            PERMISSION_MANAGEMENT: {
                NAME: 'Permission',
                TITLE: 'Permission Management',
                CREATE_BUTTON: 'Add User',
                TOOLTIP_DELETE: 'Remove Permission to User',
                TOOLTIP_REFRESH_USERS_LIST: 'Refresh Users List',
                TABLE_USER_ROLE: {
                    COLUMNS: {
                        PERMISSION: 'Permission',
                        NAME: 'Name',
                        LAST_NAME: 'Last name',
                        EMAIL: 'E-mail',
                        ACTIONS: 'Actions'
                    }
                },
                DIALOG_ADD_PERMISSION: {
                    TITLE: 'Add {{userName}} Permission to User',
                    BUTTON_ADD: 'Add Permission',
                    VALIDATION: {
                        EMAIL: {
                            INVALID: 'Invalid e-mail',
                            INVALID_DOMAIN: 'Only @motorola.com or @lenovo.com email domains.',
                            USER_NOT_FOUND: 'User not found'
                        }
                    },
                    MESSAGE_SAVED: 'Permission has been successfully saved to User.',
                    MESSAGE_ERROR: 'Failed in associate permission to User',
                    MESSAGE_DUPLICATE_USER: 'User already have this role'
                },
                DIALOG_DELETE_PERMISSION: {
                    TITLE: 'Remove {{permissionName}} Permission to User',
                    DESCRIPTION:
                        'Do you want to permanently remove {{permissionName}} permission to {{userEmail}}?',
                    WAIT_DESCRIPTION: '{{permissionName}} Removing permission...',
                    DELETE_MESSAGE:
                        '{{permissionName}} Permisson has been removed from {{userEmail}}',
                    DELETE_MESSAGE_ERROR: '{{userEmail}} don\'t have permission {{permissionName}}'
                },
                DIALOG_GRANT_PERMISSION: {
                    TITLE: 'Concede Permission <div class="small">Contact <a href="mailto:{{email}}">{{email}}</a></div>',
                    CONCEDE_BUTTON: 'CONCEDE',
                    DENY_BUTTON: 'DENY',
                    PERMISSION_GRANTED: 'Permission successful granted!',
                    USER_IS_REQUIRING_PERMISSION: '{{user}} is requiring permission to be {{role}}'
                }
            },
            FIELD_VALUE_MANAGEMENT: {
                FVC_PARAMETERS,
                SPECIAL_REQUIREMENTS,
                TEAM_PARAMETERS,
                ACCESSORY_PARAMETERS,
                DATES: {
                    DATES_DEFAULT: {
                        CREATE_BUTTON: 'Add Date',
                        FIXED: 'Fixed',
                        EDIT_TOOLTIP: 'Edit Date',
                        DATES_DEFAULT: 'dates default',
                        ADD_OR_EDIT_DIALOG: {
                            CREATE_TITLE: 'Create a new Date',
                            EDIT_TITLE: 'Editing the Date: ',
                            ORDER_VALUE: 'Dates Default Order',
                            ERROR_ALREADY_EXIST: 'Dates default already exists',
                            ERROR_FIELD_NAME: 'Please provide a valid name',
                            MESSAGE_SAVED: 'Date saved',
                            MESSAGE_UPDATE: 'Date edited'
                        },
                        FIXED_TOGGLE: 'This date is fixed',
                        NOT_FIXED_TOGGLE: 'This date is not fixed',
                        DELETE_TITLE: 'Do you want to delete this date?',
                        DELETE_CONTENT:
                            'This action will delete: {{name}} and could not be undone.',
                        DELETE_TOOLTIP: 'Delete Date',
                        WARNING_TOOLTIP: 'The Date is already being used and cannot be deleted.'
                    },
                    RELATIVE_DATES: {
                        CREATE_BUTTON: 'Add Relative Date',
                        DAYS: 'Days',
                        LOCATION: 'Location',
                        DATES_DEFAULT: 'Dates Default',
                        EDIT_TOOLTIP: 'Edit Relative Date',
                        DELETE_TITLE: 'Do you want to delete this relative-date?',
                        DELETE_CONTENT:
                            'This action will delete: {{name}} and could not be undone.',
                        DELETE_TOOLTIP: 'Delete Relative date',
                        WARNING_TOOLTIP:
                            'The Relative date is already being used and cannot be deleted.',
                        ADD_OR_EDIT_DIALOG: {
                            CREATE_TITLE: 'Create a new Relative Date',
                            EDIT_TITLE: 'Editing the Relative Date: ',
                            ORDER_VALUE: 'Relative Date Order',
                            ERROR_FIELD_NAME: 'Please provide a valid name',
                            ERROR_ALREADY_EXIST: 'Relative date already exists',
                            ERROR_DAYS: 'Please provide day number',
                            ERROR_LOCATION: 'Please select a location',
                            ERROR_DATES_DEFAULT: 'Please select a date',
                            MESSAGE_SAVED: 'Relative date saved',
                            MESSAGE_UPDATE: 'Relative date edited'
                        }
                    }
                },
                LOCATION,
                SCOPES: {
                    CREATE_BUTTON: 'ADD Scope Parameter',
                    SCOPE_PARAMETER: 'Scope Parameter',
                    EDIT_TOOLTIP: 'Edit Scope Parameter',
                    DELETE_TITLE: 'Do you want to delete this Scope Parameter?',
                    DELETE_CONTENT: 'This action will delete: {{name}} and could not be undone.',
                    WARNING_TOOLTIP: 'The parameter is already being used and cannot be deleted.',
                    DELETE_TOOLTIP: 'Delete Scope Parameter',
                    EDIT_VALUE_TITLE: 'Do you want to edit this Scope value?',
                    BP_SCOPE_VALUE_ASSOCIATIONS:
                        'The following Build Plans associated values can be updated to {{scopeValue}}. ',
                    SCOPE_MANAGEMENT_VALUE_ASSOCIATIONS:
                        'The following Scope Management will have their aliases updated to {{scopeValue}} ',
                    BTN_SCOPE_VALUE: 'Update scope value',
                    BTN_SCOPE_VALUE_ASSOCIATIONS: 'Update associations',
                    TOOLTIP_TEAM_BTN_UPDATE_SCOPE_ONLY:
                        'Updates the value from this parameter, without updating the Build Plan or the Scope Management values that may use this value',
                    TOOLTIP_TEAM_BTN_UPDATE_SCOPE_ASSOCIATIONS:
                        'Updates the value from this parameter, and updates the Build Plan Scopes and the Scope Management values you\'ve checked above',
                    ADD_OR_EDIT_DIALOG: {
                        CREATE_TITLE: 'Create a new Scope Parameter',
                        EDIT_TITLE: 'Editing the Scope Parameter: ',
                        SCOPE_VALUE: 'Enter with a Value',
                        ADD_SCOPE_VALUE: 'Write a Scope Value',
                        FIELD: '1.Field',
                        FIELD_VALUES: '2.Values',
                        ERROR_FIELD_NAME: 'Please provide a valid name',
                        ERROR_DATA_TYPE: 'Please select a data type',
                        ERROR_FIELD_TYPE: 'Please select a field type',
                        FIELD_ORDER: 'Scope Parameter Order',
                        ERROR_SCOPE_NAME_ALREADY_EXIST: 'Scope parameter name already exists',
                        ERROR_SCOPE_VALUE_REQUIRED: 'Please provide a valid value',
                        ERROR_SCOPE_VALUE: 'This Scope value already exists',
                        SELECT_DATA_TYPE: 'Select a data type',
                        SELECT_FIELD_TYPE: 'Select a field type',
                        MESSAGE_SAVED: 'Scope Parameter saved',
                        MESSAGE_UPDATE: 'Scope parameter updated',
                        DELETE_VALUE_TITLE: 'Do you want to delete this Scope value?',
                        DELETE_VALUE_CONTENT:
                            'This action will delete: {{name}} and could not be undone.',
                        CREATE_VALUE_TITLE: 'Do you want to create this Scope value?',
                        CREATE_VALUE_CONTENT: 'This action will create: {{name}}',
                        EDIT_VALUE_TITLE: 'Do you want to edit this Scope value?',
                        EDIT_VALUE_CONTENT: 'This action will edit to: {{name}}',
                        DELETE_VALUE_TOOLTIP: 'Delete scope value',
                        WARNING_VALUE_TOOLTIP:
                            'The value is already being used and cannot be deleted.'
                    }
                },
                COMMON: {
                    DATA_TYPE: 'Data Type',
                    FIELD_TYPE: 'Field Type',
                    VALUES: 'Values',
                    ALWAYS_ENABLED_CREATE: 'The {{param}} must be enabled when created',
                    EDIT_TOOLTIP: 'Edit item',
                    DELETE_TOOLTIP: 'Delete item',
                    USER_UPDATED: 'User updated'
                }
            },
            GROUP_MANAGEMENT: {
                TITLE: 'Groups',
                NEW_GROUP_BUTTON: 'New Group',
                EDIT_TOOLTIP: 'Edit group',
                USER_GROUP_TOOLTIP: 'Show group users',
                TYPE_DESCRIPTION: {
                    COMMON: 'Common',
                    EXTERNAL_USER: 'External User'
                },
                TABLE_GROUP_MANAGEMENT: {
                    COLUMNS: {
                        NAME: 'Name',
                        TYPE: 'Type',
                        DEPARTMENT: 'Department'
                    }
                },
                DIALOG_GROUP: {
                    CREATE_TITLE: 'Create a new Group',
                    EDIT_TITLE: 'Editing the Group: <b>{{group}}</b>',
                    MESSAGE_CREATED: 'Group {{group}} created',
                    MESSAGE_EDITED: 'Group {{group}} edited',
                    MESSAGE_GROUP_ALREADY_EXIST: '{{group}} already exist',
                    MESSAGE_ERROR_CREATE: 'Error to create group',
                    MESSAGE_ERROR_EDIT: 'Error to edit group'
                },
                DIALOG_USER_GROUP: {
                    TABLE_PANEL: {
                        ACTIVE: {
                            MESSAGE_SAVED: 'Status user {{email}} updated',
                            MESSAGE_ERROR: 'Failed to change status user'
                        },
                        MANAGER: {
                            MESSAGE_SAVED: 'Manager status user {{email}} updated',
                            MESSAGE_ERROR: 'Failed to change manager status user'
                        }
                    },
                    ADD_PANEL: {
                        TITLE: 'Add User in Group',
                        BUTTON_ADD: 'Add User',
                        MESSAGE_SAVED: 'User has been added in the group successfully',
                        MESSAGE_ERROR: 'Failed to add user in group',
                        MESSAGE_DUPLICATE_USER: 'User already have this group'
                    },
                    DELETE_USER_GROUP: {
                        TITLE: 'Do you want to delete the user from the group?\n',
                        MESSAGE_SAVED:
                            'This action will delete user: {{user}} from the group: {{group}}'
                    }
                },
                COMMON: {
                    ACTIONS: {
                        CHANGE_ACTIVE: 'Change group status',
                        CHANGE_MANAGER: 'Change manager status group'
                    },
                    ERRORS: {
                        GROUP_DISABLED: 'Group is disabled'
                    }
                }
            },
            SCOPE_MANAGEMENT,
            CONFIGURATION,
            TEAM_MANAGEMENT,
            GROUP_BUILD_PLAN_MANAGEMENT,
            USER_MANAGEMENT,
            PROTOTYPE_REQUEST,
            ACCESSORY_REQUEST,
            REQUEST_PREVIEW,
            EXPORTS,
            DSR,
            REPORTS,
            NOTIFICATIONS,
            FAQ,
            IMPORT_APPROVAL: {
                TAB_CONFIGURATIONS: 'Configurations',
                TAB_REQUESTS: 'Prototype Requests',
                TAB_ACCESSORY: 'Accessory',
                TAB_ACCESSORY_REQUESTS: 'Accessory Requests'
            },
            SKU_MATRIX_MANAGEMENT
        },
        NOT_AUTHORIZED: {
            TITLE: 'Not Authorized',
            BODY: 'You don\'t have permission to access this page. Please contact your leader to grant you the access or access the link below for request access permission',
            BTN_REQUEST_ACCESS: 'Request BP Admin for MBG User',
            BTN_REQUEST_ODM_ACCESS: 'Request BP Admin for ODM (External User)',
            REQUEST_SENT: 'Your request was success sent!'
        },
        FOOTER: {
            SUPPORT_REQUEST_EMAIL: 'Support Request Email:',
            SUPPORT_REQUEST_JIRA: 'Raise a ticket in JIRA:'
        },
        UNDER_CONSTRUCTION: {
            TITLE: 'Under Construction',
            BODY: 'This page is still in construction. It\'s going to be available soon'
        },
        AUTH: {
            GENERAL: {
                OR: 'Or',
                SUBMIT_BUTTON: 'Submit',
                NO_ACCOUNT: 'Don\'t have an account?',
                SIGNUP_BUTTON: 'Sign Up',
                FORGOT_BUTTON: 'Forgot Password',
                BACK_BUTTON: 'Back',
                PRIVACY: 'Privacy',
                LEGAL: 'Legal',
                CONTACT: 'Contact'
            },
            LOGIN: {
                WELCOME: 'Welcome to',
                WELCOME_SUBTITLE: 'A Motorola PDT platform development initiative',
                SIGN_IN: 'Sign in',
                LOGIN_GALVIN: 'Log into Galvin with your MMID',
                GOOGLE_SIGNIN_BUTTON: 'Sign in with Google',
                LENOVO_SIGNIN_BUTTON: 'Sign in with Lenovo',
                INFORMATIONS: {
                    COPYRIGHT: '© Motorola Mobility Technologies, Ltd.',
                    FOOTER_LINKS: {
                        PRIVACY_CONTENT: 'Privacy Content',
                        LEGAL: 'Legal',
                        GALVIN_SUPPORT: 'Project Team'
                    }
                }
            },
            TOKEN_EXPIRED:
                '<b style="cursor:pointer;">Your token has expired. To refresh the page click here</b>'
        },
        GENERAL_FORM: {
            SEARCH: 'Search',
            FILTER: 'Filter',
            FILTER_ANY_FIELD: 'Filter By Any Field',
            BY: 'by',
            FILTER_BY: 'Filter By',
            FIELDS: {
                NAME: 'Name',
                LAST_NAME: 'Last name',
                EMAIL: 'E-mail',
                COREID: 'CoreID'
            },
            CLEAR_ALL_FITLERS: 'Clear all filters',
            VALIDATION: {
                INVALID: '{{name}} is not valid',
                REQUIRED: '{{name}} is required',
                MIN_LENGTH: '{{name}} minimum length is {{min}}',
                AGREEMENT_REQUIRED: 'Accepting terms & conditions are required',
                NOT_FOUND: 'The requested {{name}} is not found',
                INVALID_LOGIN: 'The login detail is incorrect',
                REQUIRED_FIELD: 'Required field',
                MIN_LENGTH_FIELD: 'Minimum field length:',
                MAX_LENGTH_FIELD: 'Maximum field length:',
                INVALID_FIELD: 'Field is not valid',
                INTERNATIONAL_PHONE_NUMBER_INVALID:
                    'International Phone Number is invalid, +55-011-91234-1234',
                SELECT_OPTION_REQUIRED: 'Please select an options',
                CHECK_THE_FORM: 'Please, check if the form has been correctly filled.',
                EXTERNAL_DOMAIN: 'External user selected (not Motorola or Lenovo)'
            }
        },
        COMMON: {
            BUTTONS: {
                CANCEL: 'Cancel',
                OK: 'Ok',
                YES: 'Yes',
                NO: 'No',
                DELETE: 'Delete',
                CREATE: 'Create',
                CLONE: 'Clone',
                EDIT: 'Edit',
                SAVE: 'Save',
                ADD: 'Add',
                CONTINUE: 'Continue',
                NEXT: 'Next',
                BACK: 'Back',
                CLOSE: 'Close',
                FREEZE: 'Freeze',
                LOADING: 'Loading...',
                SORT: 'Sort',
                SEND: 'Send',
                PREVIEW: 'Preview',
                PREVIOUS: 'Previous'
            },
            SORT_COLUMNS: 'Sort Columns',
            COLUMNS: 'Columns',
            TYPE_ORDER: 'Type Order',
            TYPE_NULL: 'Type Null',
            ASC: 'Ascending',
            DESC: 'Descending',
            FIRST_NULL: 'First Null',
            LAST_NULL: 'Last Null',
            IGNORE_NULLS: 'Ignore Nulls',
            CLOSE_DIALOG: 'Close Dialog',
            GALVIN: 'Galvin',
            NO_CONNECTION: 'Oh, Your Connection has dropped!',
            ON_MAINTENANCE: 'We are under maintenance!',
            SHOW_ALL: 'Show All',
            USERS: 'Users',
            ERROR_REQUEST: 'An error has occurred',
            USER_WITHOUT_PERMISSION: 'You don\'t have permission to click on this item',
            MANDATORY_TOGGLE_ENABLED: 'You can\'t disable a mandatory item',
            CHANGE_STATUS: 'Change Status',
            CHANGE_MANDATORY: 'Change Mandatory status',
            MESSAGE_STATUS_UPDATE: 'Status updated',
            MANDATORY_MESSAGE_UPDATE: 'Mandatory status updated',
            SUCCESSFULLY_SAVED: 'Successfully saved!',
            SUCCESSFULLY_UPDATED: 'Successfully updated!',
            SUCCESSFULLY_DELETED: 'Successfully deleted!',
            UNKNOWN_ERROR: 'Unknown error, try again!',
            FIRST: 'First',
            RIGHT: 'Right',
            LEFT: 'Left',
            LAST: 'Last',
            BELOW: 'Below',
            ABOVE: 'Above',
            MORE_ELEMENTS_ON_ARRAY: 'and {{length}} more',
            ITEM_SELECTED: '{{text}} selected',
            NUMBER_MORE: '{{quantity}} more...',
            ALREADY_ADDED: 'Already added to the selection.',
            SAVING: 'Saving',
            SEARCH: 'Search',
            SIZE_OF_DATA: '<b>Show {{found}} items </b> of {{total}}',
            SHOWING_TOTAL_REQUESTS: '<b>Showing total requested: {{showing}}</b> <span>out of {{total}}</span>',
            SHOWING_TOTAL_REQUESTS_PLUS_BUFFER: '<b>Showing total requested + buffer: {{showing}}</b> <span>out of {{total}}</span>',
            SHOWING_TOTAL_CANCELLED_REQUESTS:
                'This Build Plan has Cancelled {{typeTotals}} that are not considered in Showing total requested' +
                '<br> <b>Total filtered cancelled requested: {{showing}}</b> <span>out of {{total}}</span>' +
                '<br> <b>Total filtered cancelled requested + buffer: {{showing_buffer}}</b> <span>out of {{total_buffer}}</span>',
            CHECK_ALL: 'Check all',
            UNCHECK_ALL: 'Uncheck all',
            PROPAGATE_TO: ' will be renamed to ',
            PROPAGATE_NO_ASSOCIATIONS: 'No Associations Found for this value',
            DOWNLOAD: 'Download',
            READ_MORE: 'Read more',
            SHOW_LESS: 'Show less',
            TOOLTIP_MULTI_DRAG_DROP: 'Press CTRL to select one or more items',
            OPEN_FILE_NEW_TAB: 'Open the file in new tab',
            ALL: 'All',
            IGNORE_CHANGES: 'Ignore Changes',
            NEW: 'New',
            ALIAS: 'Alias',
            ID: 'Id',
            ORDER: 'Order',
            ADD_VALUE: 'Add new Value',
            ADD_VALUE_DIALOG: 'The value that will be added: ',
            ADD_ACCESSORY_VALUE: 'Add new Accessory Value',
            ADD_ACCESSORY_CONFIGURATION_DIALOG: 'The value that will be added in the Accessory values: ',
            MENTIONS: {
                NOTIFY: 'Notify',
                APPLY: 'Apply',
                CLOSE: 'Close',
                CANCEL: 'Cancel',
                LOADING: 'Loading Users...',
                PLACEHODER: 'Comment or add others with @'
            },
            INVALID_FORMAT_DATE: 'Invalid date format. {{value}}',
            INVALID_FORMAT_NUMBER: 'Invalid number format. {{value}}',
            INVALID_FORMAT_NUMBER_INTEGER: 'Invalid integer number format. {{value}}',
            INVALID_FORMAT_NUMBER_FLOAT: 'Invalid decimal number format. {{value}}',
            IMEI2_CANNOT_EDIT: 'IMEI 2 can\'t be edited. Error to save value {{value}}',
            LENS_CODE_CANNOT_EDIT: 'Lens Code can\'t be edited. Error to save value {{value}}',
            USER_CANNOT_EDIT: 'User can\'t be edited. Error to save value {{value}}',
            BU_CANNOT_EDIT: 'BU can\'t be edited. Error to save value {{value}}',
            HW_REVISION_CANNOT_EDIT: 'Invalid HW Revision. {{value}}',
            LOCATION_CANNOT_EDIT: 'Location can\'t be edited. Error to save value {{value}}'
        },
        CHATBOT: {
            BUTTON_TOOLTIP: 'Toggle AI assistant panel'
        },
        NOTIFICATION: {
            NEXT: 'Next notification',
            PREVIOUS: 'Previous notification',
            DISMISS: 'Dismiss',
            NO_CONTENT: 'No notifications found'
        },
        MAT_TABLE: {
            NO_RECORDS: 'No records found',
            PLEASE_WAIT: 'Please wait...',
            ACTIVE: 'Active',
            MANDATORY: 'Mandatory',
            ACTIONS: 'Actions',
            MANAGER: 'Manager',
            PAGINATOR: {
                ITEMS_PER_PAGE_LABEL: 'Items per Page',
                NEXT_PAGE_LABEL: 'Next page',
                PREVIOUS_PAGE_LABEL: 'Previous Page',
                FIRST_PAGE_LABEL: 'First page',
                LAST_PAGE_LABEL: 'Last Page',
                RANGE_PAGE_LABEL_1: '0 of {{length}}',
                RANGE_PAGE_LABEL_2: '{{startIndex}} - {{endIndex}} of {{length}}'
            }
        }
    }
};
