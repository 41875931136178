export class PageConfig {
    public defaults: any = {
        cdc: {
            home: {
                page: {
                    title: 'CDC - Home'
                }
            },
            'prototype-sorting': {
                page: {
                    title: 'CDC - Prototype Sorting'
                }
            },
            'accessory-tracker': {
                page: {
                    title: 'CDC - Accessory Tracker'
                }
            }
        },
        'build-plan': {
            home: {
                page: {
                    title: 'Build Plan - Home'
                }
            },
            'import-approval': {
                page: {
                    title: 'Build Plan - Import Approval'
                },
                review: {
                    page: {
                        title: 'Build Plan - Import Approval - Review'
                    }
                }
            },
            management: {
                page: {
                    title: 'Build Plan - Management'
                },
                details: {
                    product: {
                        page: {
                            title: 'Build Plan - Details - Main Product Data'
                        }
                    },
                    configurations: {
                        page: {
                            title: 'Build Plan - Details - Configurations'
                        }
                    },
                    scopes: {
                        page: {
                            title: 'Build Plan - Details - Scopes'
                        }
                    },
                    'external-groups': {
                        page: {
                            title: 'Build Plan - Details - External Groups'
                        }
                    },
                    teams: {
                        page: {
                            title: 'Build Plan - Details - Teams'
                        }
                    },
                    'request-preview': {
                        page: {
                            title: 'Build Plan - Details - Request Preview'
                        }
                    },
                    accessories: {
                        page: {
                            title: 'Build Plan - Details - Accessories'
                        }
                    },
                    'accessories-request-preview': {
                        page: {
                            title: 'Build Plan - Details - Accessories Request Preview'
                        }
                    },
                    'status-approvals': {
                        page: {
                            title: 'Build Plan - Details - Status & Approvals'
                        }
                    },
                    exports: {
                        page: {
                            title: 'Build Plan - Details - Exports'
                        }
                    },
                    'sku-matrix': {
                        page: {
                            title: 'Build Plan - Details - Sku Matrix'
                        }
                    }
                }
            },
            'user-management': {
                page: {
                    title: 'Build Plan - User Management'
                }
            },
            permissions: {
                page: {
                    title: 'Build Plan - Permissions'
                }
            },
            'field-value-management': {
                'fvc-parameters': {
                    page: {
                        title: 'Fields & Values - FVC Parameters'
                    }
                },
                'accessory-parameters': {
                    page: {
                        title: 'Fields & Values - Accessory Parameters'
                    }
                },
                'special-requirements': {
                    page: {
                        title: 'Fields & Values - Special Requirements'
                    }
                },
                dates: {
                    page: {
                        title: 'Fields & Values - Dates'
                    }
                },
                'relative-dates': {
                    page: {
                        title: 'Fields & Values - Relative Dates'
                    }
                },
                'scope-parameters': {
                    page: {
                        title: 'Fields & Values - Scope Parameters'
                    }
                },
                'team-parameters': {
                    page: {
                        title: 'Fields & Values - Team Parameters'
                    }
                },
                locations: {
                    page: {
                        title: 'Fields & Values - Locations'
                    }
                }
            },
            groups: {
                page: {
                    title: 'Build Plan - Groups'
                }
            },
            scopes: {
                page: {
                    title: 'Build Plan - Scopes'
                }
            },
            teams: {
                page: {
                    title: 'Build Plan - Teams'
                }
            },
            'prototype-request': {
                'requests-approval': {
                    page: {
                        title: 'Build Plan - Prototype Request - Requests Approval'
                    }
                },
                'team-view': {
                    page: {
                        title: 'Build Plan - Prototype Request - Team view'
                    }
                },
                'full-view': {
                    page: {
                        title: 'Build Plan - Prototype Request - Full view'
                    }
                },
                'my-prototypes': {
                    page: {
                        title: 'Build Plan - Prototype Request - My Prototypes'
                    }
                },
                'request-review': {
                    page: {
                        title: 'Build Plan - Prototype Request - Review'
                    }
                },
                'request-history': {
                    page: {
                        title: 'Build Plan - Prototype Request - Request History'
                    }
                }
            },
            'accessory-request': {
                'requests-approval': {
                    page: {
                        title: 'Build Plan - Accessory Request - Requests Approval'
                    }
                },
                'team-view': {
                    page: {
                        title: 'Build Plan - Accessory Request - Team view'
                    }
                },
                'full-view': {
                    page: {
                        title: 'Build Plan - Accessory Request - Full view'
                    }
                },
                'request-review': {
                    page: {
                        title: 'Build Plan - Accessory Request - Review'
                    }
                },
                'my-accessories': {
                    page: {
                        title: 'Build Plan - Accessory Request - My Accessories'
                    }
                }
            },
            dsr: {
                page: {
                    title: 'Build Plan - DSR'
                }
            },
            reports: {
                page: {
                    title: 'Build Plan - Reports'
                }
            },
            notifications: {
                page: {
                    title: 'Build Plan - Notifications'
                }
            },
            authorization: {
                page: {
                    title: 'Not Authorized'
                }
            },
            'under-construction': {
                page: {
                    title: 'Under Construction'
                }
            },
            faq: {
                page: {
                    title: 'Build Plan - FAQ'
                }
            }
        },
        finance: {
            home: {
                page: {
                    title: 'Finance - Home'
                }
            },
            'po-quotation': {
                page: {
                    title: 'Finance - PO/Quotation'
                }
            },
        }
    };

    public get configs(): any {
        return this.defaults;
    }
}
