import {SelectionModel} from '@angular/cdk/collections';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ContentChild,
    Directive,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    TemplateRef
} from '@angular/core';
import {
    IMenuShowColumnsGroup
} from '@galvin/core/build-plan/configuration-management/interfaces/menu-show-columns-group.interface';
import {LocalStorageHelper} from '@galvin/core/storage/local-storage-helper.service';
import {Subscription} from 'rxjs';

export interface IMenuShowColumnsColumn {
    type?: string;

    [key: string]: unknown;
}

@Directive({selector: '[menu-show-columns-tmp]'})
export class MenuShowColumnsTmpDirective {
    constructor(public template: TemplateRef<any>) {
    }
}

@Component({
    selector: 'menu-show-columns',
    templateUrl: './menu-show-columns.component.html',
    styleUrls: ['./menu-show-columns.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuShowColumnsComponent implements OnInit, OnDestroy {
    @ContentChild(MenuShowColumnsTmpDirective, {
        read: TemplateRef
    })
        columnLabelTemplate!: TemplateRef<any>;
    readonly columnsToHide: SelectionModel<number>;
    readonly selectedGroups: SelectionModel<number>;

    constructor(private storageService: LocalStorageHelper, private cdr: ChangeDetectorRef) {
        const toHide = JSON.parse(this.storageService.getItem(this.keyStorage)) || [];
        this.columnsToHide = new SelectionModel<number>(true, toHide);
        this.selectedGroups = new SelectionModel<number>(true, []);
    }

    _keyStorage!: string;

    @Input()
        hideColumns!: number[];
    @Output()
        resultColumns: EventEmitter<unknown[]> = new EventEmitter<unknown[]>();
    columnsByGroups: Record<string, number[]> = {};
    private s8n = new Subscription();

    @Input()
    set keyStorage(value) {
        this._keyStorage = value;
        const toHide = JSON.parse(this.storageService.getItem(this.keyStorage)) || [];
        this.columnsToHide.select(...toHide);
    }
    @Input()
        smallMode: boolean = false;

    @Input()
        showText: boolean = true;

    @Input()
        showViewList: boolean = true;

    @Input()
        titleDropdown: string = '';

    get keyStorage() {
        return this._keyStorage;
    }

    private _displayColumns: IMenuShowColumnsColumn[] = [];

    get displayColumns(): IMenuShowColumnsColumn[] {
        return this._displayColumns;
    }

    @Input()
    set displayColumns(data: IMenuShowColumnsColumn[]) {
        this._displayColumns = data || [];
        this.emit();
    }

    private _displayGroups: IMenuShowColumnsGroup[] = [];

    get displayGroups(): IMenuShowColumnsGroup[] {
        return this._displayGroups || [];
    }

    @Input()
    set displayGroups(v: IMenuShowColumnsGroup[]) {
        if (v) {
            this._displayGroups = v;
            this.updateGroups();
        }
    }

    private _columnsToShow: IMenuShowColumnsColumn[] = [];

    get columnsToShow(): IMenuShowColumnsColumn[] {
        return this._columnsToShow;
    }

    ngOnInit(): void {
        const toHide = JSON.parse(this.storageService.getItem(this.keyStorage));
        if (Array.isArray(toHide)) {
            this.columnsToHide.select(...toHide);
        }
        this.s8n.add(this.columnsToHide.changed.subscribe(() => this.onSelectionChange()));
    }

    ngOnDestroy(): void {
        this.s8n.unsubscribe();
    }

    getDisplayColumnByGroup(group: IMenuShowColumnsGroup): { value: any; key: number }[] {
        const columns: { value: any; key: number }[] = [];
        if (group.type in this.columnsByGroups) {
            this.columnsByGroups[group.type].forEach((i) => {
                columns.push({value: this.displayColumns[i], key: i});
            });
        }
        return columns;
    }

    emit(): void {
        this._columnsToShow = this.getObjectSelected();
        this.resultColumns.next(this._columnsToShow);
        if (this.displayGroups) {
            this.updateGroups();
        }
    }

    saveStorage(key: string, val: unknown): void {
        this.storageService.setItem(key, JSON.stringify(val));
    }

    public trackByFunction(index: number, item: unknown): number | null {
        if (!item) {
            return null;
        }
        return index;
    }

    toggleGroup(gi: number) {
        this.selectedGroups.toggle(gi);
        const group = this.displayGroups[gi];
        const groupColumns = this.getDisplayColumnByGroup(group)
            .filter((c) => !c.value.disabled)
            .map((c) => c.key);
        if (this.selectedGroups.isSelected(gi)) {
            this.columnsToHide.deselect(...groupColumns);
        } else {
            this.columnsToHide.select(...groupColumns);
        }
        return null;
    }

    displayGroupsIsEmpty(): boolean {
        return this.displayGroups.length === 0;
    }

    private getObjectSelected(): IMenuShowColumnsColumn[] {
        return this._displayColumns.filter((_, i) => !this.columnsToHide.isSelected(i));
    }

    private onSelectionChange() {
        const columnsToHide = this.columnsToHide.selected;
        if (this.keyStorage) {
            this.saveStorage(this.keyStorage, columnsToHide);
        }

        this.emit();
    }

    private updateGroups() {
        this.columnsByGroups = this._displayColumns
            .reduce(
                (record, item: any, ci) => {
                    if (item && !(item.type in record)) {
                        record[item.type] = [];
                    }
                    const group = record[item.type];

                    if (!group.includes(ci)) {
                        group.push(ci);
                    }
                    return record;
                },
                <Record<string, number[]>>{}
            );

        this.displayGroups.forEach((group, gi) => {
            const selecteds = this.columnsByGroups[group.type].filter((c) => !this.columnsToHide.isSelected(c));
            if (this.columnsByGroups[group.type].length === selecteds.length) {
                this.selectedGroups.select(gi);
            } else {
                this.selectedGroups.deselect(gi);
            }
        });

        this.cdr.markForCheck();
    }
}
