export enum ERolesBuildPlan {
    BP_ADMIN = 'Build Plan Admin',
    BP_FACTORY_PM = 'Factory PM',
    BP_GPM = 'GPM',
    BP_GROUP_MANAGER = 'Group Manager',
    BP_GTM = 'GTM',
    BP_HW_PM = 'HW PM',
    BP_PM = 'PM',
    BP_REQUESTOR = 'Requestor',
    BP_EXTERNAL_USER = 'External User',
    CDC_OPERATOR = 'CDC Operator',
    CDC_APPROVER = 'CDC Approver',
    FINANCE_OPERATOR = 'Finance Operator'
}
export type ERolesBuildPlanKeys = keyof typeof ERolesBuildPlan;
