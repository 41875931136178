import { ISpecialRequirementsI18n } from './special-requirements-i18n.interface';

export const SPECIAL_REQUIREMENTS: ISpecialRequirementsI18n = {
    CREATE_BUTTON: 'Add Special Requirement',
    SPECIAL_REQUIREMENT: 'Special Requirement',
    EDIT_TOOLTIP: 'Edit Special Requirement',
    DELETE_TOOLTIP: 'Delete Special Requirement',
    WARNING_TOOLTIP: 'The special requirement is already being used and cannot be deleted or you don\'t have permission to do this action.',
    TOOLTIP_BTN_UPDATE_VALUE_ONLY:
        'Updates the value from this special requirement, without updating the Build Plans that may use this value',
    TOOLTIP_BTN_UPDATE_ASSOCIATIONS:
        'Updates the value from this special requirement and it updates the Build Plans that contains this special requriements value',
    DELETE_TITLE: 'Do you want to delete this Special Requirement?',
    DELETE_CONTENT: 'This action will delete: {{name}} and could not be undone.',
    ADD_OR_EDIT_DIALOG: {
        CREATE_TITLE: 'Create a new Special Requirement',
        EDIT_TITLE: 'Editing the Special Requirement: ',
        SPECIAL_REQUIREMENT_VALUE: 'Enter with a Value',
        ADD_SPECIAL_REQUIREMENT_VALUE: 'Write a Special Requirement Value',
        ORDER_VALUE: 'Special Requirement Order',
        FIELD: '1.Field',
        FIELD_VALUES: '2.Values',
        ERROR_FIELD_NAME: 'Please provide a valid name',
        ERROR_DATA_TYPE: 'Please select a data type',
        ERROR_FIELD_TYPE: 'Please select a field type',
        ERROR_SPECIAL_REQUIREMENT_REQUIRED: 'Please provide a valid value',
        ERROR_SPECIAL_REQUIREMENT_VALUE_REQUIRED: 'This special requirement value already exist',
        ERROR_SPECIAL_REQUIREMENT_VALUE: 'This Special Requirement value already exist',
        SELECT_DATA_TYPE: 'Select a data type',
        SELECT_FIELD_TYPE: 'Select a field type',
        MESSAGE_SAVED: 'Special Requirement saved',
        MESSAGE_UPDATED: 'Special Requirement updated',
        SPECIAL_REQUIREMENT_DUPLICATE: 'Special Requirement Already Exists',
        DELETE_VALUE_TITLE: 'Do you want to delete this Special Requirement value?',
        DELETE_VALUE_CONTENT: 'This action will delete: {{name}} and could not be undone.',
        CREATE_VALUE_TITLE: 'Do you want to create this special requirement value ?',
        CREATE_VALUE_CONTENT: 'This action will create: {{name}}',
        EDIT_VALUE_TITLE: 'Do you want to edit this special requirement value?',
        EDIT_VALUE_CONTENT: 'This action will edit to: {{name}}',
        DELETE_VALUE_TOOLTIP: 'Delete Special Requirement value',
        WARNING_VALUE_TOOLTIP: 'The value is already being used and cannot be deleted.',
        BUTTON_UPDATE_ONLY_VALUE: 'Update Special Requirement Value',
        BUTTON_UPDATE_ASSOCIATIONS: 'Update Associations'
    }
};
