// Angular
import { Component, OnInit } from '@angular/core';
// Layout
import { environment } from '../../../../environments/environment';
import { LayoutConfigService } from '../../../core/_base/layout';
// Object-Path
import * as objectPath from 'object-path';

@Component({
    selector: 'kt-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
    // Public properties
    today: number = Date.now();
    fluid!: boolean;
    sha1!: string;

    readonly galvinSupportEmail = 'galvinsupport@motorola.com';
    readonly galvinSupportRequestUrl =
        'https://idart.mot.com/secure/CreateIssueDetails!init.jspa?pid=38190&issuetype=10&priority=3&summary=[GALVIN%20Issue]%20%3CPlease,%20add%20your%20request%20summary%20here%3E&description=%3CPlease%20fill%20the%20information%20below%20and%20provide%20a%20brief%20description%20of%20your%20request%3E&components=106724&customfield_10005=10002&customfield_16116=28228';

    /**
     * Component constructor
     *
     * @param layoutConfigService: LayouConfigService
     */
    constructor(private layoutConfigService: LayoutConfigService) {}

    /**
     * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
     */

    /**
     * On init
     */
    ngOnInit(): void {
        const config = this.layoutConfigService.getConfig();

        // footer width fluid
        this.fluid = objectPath.get(config, 'footer.self.width') === 'fluid';
        this.sha1 = environment.sha1;
    }
}
