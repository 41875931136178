import { Directive,  ElementRef,  Input, OnChanges, Renderer2 } from '@angular/core';
import { PrototypeRequestService } from '@galvin/core/build-plan/prototype-request-management';
import { IProtoRequestTableStorage } from '@galvin/core/build-plan/prototype-request-management/interfaces/proto-request-table-storage.interface';
import { REGEX_MENTIONS_EMAIL } from '@galvin/core/comments/mention-comment/mention-comment.component';
import { LocalStorageHelper } from '@galvin/core/storage/local-storage-helper.service';



@Directive({
    selector: '[fullviewMentionsCommentIndicator]'
})
export class FullviewMentionsCommentDirective implements  OnChanges{
    public localStorage: IProtoRequestTableStorage<any>;
    public loggedUser!: string;

    @Input() column: any;
    @Input() row: any;

    constructor (
        private _elRef: ElementRef,
        private _renderer: Renderer2,
        private prototypeRequestService: PrototypeRequestService,
        private localStorageHelper: LocalStorageHelper,
    ) {
        this.loggedUser = this.localStorageHelper.getLoggedUser()?.email || '';
        this.localStorage = this.prototypeRequestService.getEmptyStorageObject();
    }

    ngOnChanges(changes: any): void {
        if(changes.column || changes.row) {
            this.setAttr();
        }
    }

    getClassByAdditionalCommentRequest(column: any, row: any): string {
        const colaux = <any>column;
        const rowaux = <any>row;

        if(colaux && rowaux) {
            if (colaux['idConfiguration']) {
                //For Configurations
                // is at localStorage
                const findInLocalStorage = this.localStorage ? this.localStorage?.requests.find(req => {
                    return !!req.comment &&
                            req.configurationId === (column as any).idConfiguration &&
                            req.idTeamAssociation === (row as any).idTeamAssociation;
                }) : null;

                // is at data
                const findInData = rowaux.requests?.find((c: any) =>
                    !!c.additionalComments &&
                    c.configurationId === colaux.idConfiguration &&
                    c.revision === colaux.revision?.fvcValueId
                );

                if(findInData?.additionalComments?.includes(this.loggedUser) || findInLocalStorage?.comment?.includes(this.loggedUser)){
                    return 'border-comments yellow';
                }else if(findInData?.additionalComments?.match(REGEX_MENTIONS_EMAIL) || findInLocalStorage?.comment?.match(REGEX_MENTIONS_EMAIL)){
                    return 'border-comments blue';
                }

                return !!findInLocalStorage || !!findInData ? 'border-comments black' : '';
            }
            else {
                //For Accessories
                // is at localStorage
                const findInLocalStorage = this.localStorage ? this.localStorage?.requests.find(req => {
                    return !!req.comment &&
                            req.accessoryId === (column as any).accessoryId &&
                            req.idTeam === (row as any).idTeam;
                }) : null;
                // is at data
                const findInData = rowaux.requests?.find((c: any) =>
                    !!c.additionalComments &&
                    c.accessoryId === colaux.accessoryId
                );

                if(findInData?.additionalComments?.includes(this.loggedUser) || findInLocalStorage?.comment?.includes(this.loggedUser)){
                    return 'border-comments yellow';
                }else if(findInData?.additionalComments?.match(REGEX_MENTIONS_EMAIL) || findInLocalStorage?.comment?.match(REGEX_MENTIONS_EMAIL)){
                    return 'border-comments blue';
                }

                return !!findInLocalStorage || !!findInData ? 'border-comments black' : '';
            }
        }
        return '';
    }

    private setAttr() {
        this._renderer.setAttribute(this._elRef.nativeElement, 'class', this.getClassByAdditionalCommentRequest(this.column, this.row));
    }
}
