<galvin-progress-spinner [diameter]="70" [hidden]="!logging"></galvin-progress-spinner>
<div class="kt-login__body login-box" [hidden]="logging">
    <div class="kt-login__form">
        <div class="kt-login__title">
            <h3>{{ 'AUTH.LOGIN.SIGN_IN' | translate }}</h3>
        </div>

        <div class="login-form">
            <div class="login-message mt-3">{{ ['AUTH.LOGIN.LOGIN_GALVIN' | translate] }}</div>
            <!-- Lenovo sign in -->
            <div class="signin-div mt-2">
                <div id="google-signin" class="gid_signin" data-type="standard" data-shape="rectangular"
                    data-theme="filled_blue" data-text="signin_with" data-size="large" data-width="240"
                    data-auto_select="true" data-logo_alignment="left" data-cancel_on_tap_outside="false">
                </div>
                <div class="lenovo-signin-btn" *ngIf="showLenovoLoginButton" (click)="signInWithLenovo()">
                    <span class="lenovo-signin-btn-text">
                        <span>Sign in with Lenovo</span>
                    </span>
                    <div class="lenovo-signin-btn-icon-wrapper">
                        <div class="lenovo-signin-btn-icon">
                            <img src="assets/galvin/icons/lenovo.svg" width="32px" height="32px" viewBox="0 0 32 32" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
