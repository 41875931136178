// Angular
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {LocalStorageHelper} from '@galvin/core/storage/local-storage-helper.service';
import {GenericResource, ResourceService} from '@galvin/core/_base/resource';
import {IProtoRequest, IProtoRequestCell, IProtoRequestHeader} from '@galvin/views/partials/layout/proto-request-table';
import {TranslateService} from '@ngx-translate/core';
import {cloneDeep} from 'lodash';
import moment from 'moment';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {map} from 'rxjs/operators';
// Environment
import {UserPermissionsService} from '@galvin/core/auth/services/user-permissions.service';
import {ERolesBuildPlan} from '@galvin/core/auth/_enums/roles-build-plan.enum';
import {EConfigurationStatus} from '@galvin/core/build-plan/management';
import {environment} from '../../../../../environments/environment';
import {EConfigurationStatusLabel} from '../../configuration-management/enums/configuration-status-label.enum';
import {EDataType, IFieldValue} from '../../field-value-management';
import {IBuildPlanTotalRequest} from '../../management/interfaces/build-plan-total-requests.interface';
import {IPrototypeRequestI18n} from '../i18n/prototype-request-i18n.interface';
import {IBuildPlanSelectorItem} from '../interfaces/build-plan-selector-item.interface';
import {IBuildPlanRequestsTotals} from '../interfaces/hw-revision-requests-totals.interface';
import {IProtoRequestTableStorage} from '../interfaces/proto-request-table-storage.interface';
import {IPrototypeFilters} from '../interfaces/prototype-filters.interface';
import {IPrototypeRequestTeam, IPrototypeRequestTeamSpecialRequirements} from '../interfaces/prototype-request-team.interface';
import {
    IPrototypeRequestWithConfigurationAndTeam
} from '../interfaces/prototype-request-with-configuration-and-team.interface';
import {IPrototype} from '../interfaces/prototype.interface';
import {IRequestActivity} from '../interfaces/request-activity.interface';
import {IRequestTableStorage} from '../interfaces/request-table-storage.interface';
import {ITeamSelectorItem} from '../interfaces/team-selector-item.interface';
import {PrototypeRequestResource, PrototypeRequestTeamResource} from './prototype-request-resource';
import { BuildPlanRequestPreviewService } from '../../management/services/proto-request.service';
import { IHwRevision } from '../interfaces/hw-revision.interface';
import { PrototypeRequestActivityResource } from './prototype-request-activity-resource';

@Injectable({
    providedIn: 'root'
})
export class PrototypeRequestService extends ResourceService {
    loadingPrototypeRequests: Subject<boolean> = new Subject<boolean>();
    selectedBuildPlanIdChange: Subject<IBuildPlanSelectorItem> = new Subject<IBuildPlanSelectorItem>();
    bpInternalProductNameSelected = new Subject<string | null>();
    isBuildPlanSelectorLoaded = false;
    selectedTeamIdChange: Subject<number> = new Subject<number>();
    selectedTeamIdsChange: BehaviorSubject<ITeamSelectorItem[]> = new BehaviorSubject<ITeamSelectorItem[]>([]);
    isTeamLoaded = false;
    noTeamFound = true;
    requested: Subject<number> = new Subject<number>();
    requestedCountByHWRevisionChange: Subject<IBuildPlanRequestsTotals> =
        new Subject<IBuildPlanRequestsTotals>();
    protoRequestList: Subject<Partial<IPrototype>[]> = new Subject<Partial<IPrototype>[]>();
    requestedCountUpdate: Subject<{ hwRevision: string; difference: number }> = new Subject<{
        hwRevision: string;
        difference: number;
    }>();
    filterOptionsChange: Subject<IPrototypeFilters> = new Subject<IPrototypeFilters>();
    filterSelectionsChange: Subject<IPrototypeFilters> = new Subject<IPrototypeFilters>();
    requestPeviewFilterChange: Subject<IPrototypeFilters> = new Subject<IPrototypeFilters>();
    requestReviewHWChange: Subject<IBuildPlanTotalRequest[]> = new Subject<IBuildPlanTotalRequest[]>();
    signalResetFiltersSelected: Subject<IPrototypeFilters> = new Subject<IPrototypeFilters>();

    selectedTeams: ITeamSelectorItem[] = [];
    selectedFilter!: IPrototypeFilters;
    readonly configurationStatusNotShow = [EConfigurationStatusLabel.NEW];
    readonly configurationStatusToRequestApproval: EConfigurationStatus[] = [
        EConfigurationStatus.LOCKED,
        EConfigurationStatus.MANUFACTURED,
        EConfigurationStatus.IN_TRANSIT,
        EConfigurationStatus.CANCELED,
        EConfigurationStatus.COMPLETED
    ];
    public readonly ALL: string;
    public readonly SEPARATOR: string;
    private readonly labels: IPrototypeRequestI18n;
    private readonly URL_PROTOTYPE_REQUEST = 'build-plan/prototype-request';
    private readonly API_PROTOTYPE_REQUEST_URL =
        `${environment.baseUrlApi}/` + this.URL_PROTOTYPE_REQUEST;
    private readonly BUILD_PLAN_SELECTOR: string = 'buildPlanSelector';
    private readonly KEY_FILTER_STORAGE: string = 'prototype-request-filters';
    private readonly KEY_FILTER_STORAGE_REQUEST: string = 'requestPreview-filters';
    private readonly TEAM_SELECTOR: string = 'teamSelector';
    private readonly TEAMS_SELECTOR: string = 'teamListSelector';

    private selectedBuildPlan!: IBuildPlanSelectorItem | null;
    private selectedBuildPlanId!: number;
    private selectedTeamId!: number;
    private selectedTeamListIds!: number[];

    private prefixCache!: string;

    constructor(
        private httpClient: HttpClient,
        private localStorageHelper: LocalStorageHelper,
        private translate: TranslateService,
        private userPermissionsService: UserPermissionsService,
        private bpRequestPreviewService: BuildPlanRequestPreviewService
    ) {
        super(httpClient);
        this.labels = this.translate.instant('BUILD_PLAN.PROTOTYPE_REQUEST');
        this.ALL = this.translate.instant('BUILD_PLAN.PROTOTYPE_REQUEST.HEADER.FILTERS.ALL');
        this.SEPARATOR = ',';
    }

    /**
     * Retrieves the build plans selector data from the server
     */
    getBuildPlanSelector(
        filterByRole = false,
        excludedUnderApproval = true,
        teamId?: number
    ): Observable<IBuildPlanSelectorItem[]> {
        let url = `${this.API_PROTOTYPE_REQUEST_URL}/build-plans/selector?by-role=${filterByRole}&excluded-under-approval=${excludedUnderApproval}`;
        if (teamId) {
            url = `${this.API_PROTOTYPE_REQUEST_URL}/teams/${teamId}/build-plans/selector`;
        }

        return this.httpClient.get<IBuildPlanSelectorItem[]>(url).pipe(
            map((response: any) => {
                this.isBuildPlanSelectorLoaded = true;
                return response.content;
            })
        );
    }

    /**
     * Retrieves the teams selector data from the server
     */
    getTeamSelectorByBuildPlan(buildPlanId: number): Observable<ITeamSelectorItem[]> {
        return this.httpClient
            .get<ITeamSelectorItem[]>(
                `${this.API_PROTOTYPE_REQUEST_URL}/build-plans/${buildPlanId}/teams/selector`
            )
            .pipe(
                map((response: any) => {
                    this.noTeamFound = !response.content || response.content.length === 0;
                    this.isTeamLoaded = true;
                    return response.content;
                })
            );
    }

    /**
     * Retrieves all FVC Value HWRevions registered from the server
     */
    getAllHwRevisions(): Observable<IHwRevision[]> {
        return this.httpClient
            .get<IHwRevision[]>(
                `${this.API_PROTOTYPE_REQUEST_URL}/all-hw-revisions/`
            )
            .pipe(
                map((response: any) => {
                    const revisions = response.content.map((r:any) => { return { fvcValueId: r.id, name: r.value.toUpperCase()}; });
                    return revisions;
                })
            );
    }

    /**
     * Retrieves the teams selector data for my prototypes from the server
     */
    getMyPrototypesTeamSelector(): Observable<ITeamSelectorItem[]> {
        return this.httpClient
            .get<ITeamSelectorItem[]>(
                `${this.API_PROTOTYPE_REQUEST_URL}/my-prototypes/teams/selector`
            )
            .pipe(
                map((response: any) => {
                    this.noTeamFound = !response.content || response.content.length === 0;
                    this.isTeamLoaded = true;
                    return response.content;
                })
            );
    }

    /**
     * Retrieves the list of prototype requested for a given build plan ID from the server
     * @param buildPlanId: ID of the build plan to get the prototype requested from
     */
    getPrototypeRequestsList(
        buildPlanId: number,
        teamAssociationId: number
    ): Observable<IPrototype[]> {
        this.loadingPrototypeRequests.next(true);

        return this.httpClient
            .get<IPrototype[]>(
                `${this.API_PROTOTYPE_REQUEST_URL}/build-plans/${buildPlanId}/team-association/${teamAssociationId}/requests`
            )
            .pipe(
                map((response: any) => {
                    this.loadingPrototypeRequests.next(false);
                    return response.content;
                })
            );
    }

    /**
     * Returns all configurations and prototype request for a given team.
     *
     */
    getConfigurationsAndProtoRequestForATeam(
        idConfiguration: number,
        idPrototype: number
    ): Observable<IPrototypeRequestWithConfigurationAndTeam> {
        const protoRequestResource = new PrototypeRequestResource();
        protoRequestResource._links.self = {
            href: `/build-plan/prototype-request/configurations/${idConfiguration}/teams/${idPrototype}`
        };

        return this.get<IPrototypeRequestWithConfigurationAndTeam, PrototypeRequestResource>(
            protoRequestResource
        ).pipe(
            map((response) => {
                return response.content;
            })
        );
    }

    /**
     * Returns all activity information for a given and configuration and team
     */
    getActivityInformationForConfigurationAndTeam(
        idConfiguration: number,
        idTeam: number,
        filter: string[]
    ): Observable<IRequestActivity[]> {
        const url =  `${this.API_PROTOTYPE_REQUEST_URL}/configurations/${idConfiguration}/teams/${idTeam}/history`;
        return this.http.post<PrototypeRequestActivityResource>(url, filter).pipe(map((res) => res.content));
    }


    /**
     * Should send a prototype request.
     *
     */
    sendRequestPrototype(
        idConfiguration: number,
        idTeam: number,
        body: IPrototypeRequestTeam
    ): Observable<IPrototypeRequestWithConfigurationAndTeam> {
        const protoRequestResource = new PrototypeRequestTeamResource();
        protoRequestResource._links.self = {
            href: `/build-plan/prototype-request/configurations/${idConfiguration}/teams/${idTeam}`
        };
        protoRequestResource.content = body;

        return this.post<IPrototypeRequestWithConfigurationAndTeam, PrototypeRequestTeamResource>(
            protoRequestResource
        ).pipe(
            map((response) => {
                return response.content;
            })
        );
    }

    /**
     * Should send a prototype request approval.
     *
     */
    sendRequestPrototypeApproval(
        buildPlanId: number,
        approvalRequest: any
    ): Observable<IPrototypeRequestWithConfigurationAndTeam> {
        const protoRequestResource = new PrototypeRequestResource();
        protoRequestResource._links.self = {
            href: '/build-plan/prototype-request-approval'
        };
        protoRequestResource.content = approvalRequest;

        return this.post<IPrototypeRequestWithConfigurationAndTeam, PrototypeRequestResource>(
            protoRequestResource
        ).pipe(
            map((response) => {
                return response.content;
            })
        );
    }

    /**
     * Should send a prototype request approval.
     *
     */
    sendRequestPrototypeApprovalMassive(
        buildPlanId: number,
        approvalRequests: Array<any>
    ): Observable<IPrototypeRequestWithConfigurationAndTeam> {
        const protoRequestResource = new GenericResource();
        protoRequestResource._links.self = {
            href: '/build-plan/prototype-request-approval/massive'
        };
        protoRequestResource.content = approvalRequests;

        return this.post<IPrototypeRequestWithConfigurationAndTeam, any>(protoRequestResource).pipe(
            map((response) => {
                return response.content;
            })
        );
    }

    setProtoRequestList(prototypes: Partial<IPrototype>[]): void {
        this.protoRequestList.next(prototypes);
    }

    canUserRequestPrototypeAfterLocker(): boolean {
        const userRoles = this.userPermissionsService.getUserRoles() as any;
        const allowedRoles = [
            ERolesBuildPlan.BP_GPM,
            ERolesBuildPlan.BP_HW_PM,
            ERolesBuildPlan.BP_ADMIN
        ];
        return (
            userRoles.superUser ||
            allowedRoles.some((item) => userRoles.rolesBuildPlan.includes(item))
        );
    }

    canUserSeeArchivedHidden(): boolean {
        const userRoles = this.userPermissionsService.getUserRoles() as any;
        const allowedRoles = [
            ERolesBuildPlan.BP_GPM,
            ERolesBuildPlan.BP_HW_PM,
            ERolesBuildPlan.BP_ADMIN
        ];
        return (
            userRoles.superUser ||
            allowedRoles.some((item) => userRoles.rolesBuildPlan.includes(item))
        );
    }

    /**
     * Set a new selected build plan and saves it to the local storage
     * @param buildPlan: the build plan selected
     */
    setBuildPlanSelection(buildPlan: IBuildPlanSelectorItem | null): void {
        if (buildPlan) {
            let localBuildPlan: IBuildPlanSelectorItem = { ...buildPlan};
            if (localBuildPlan.teams != undefined && localBuildPlan.teams.length > 0) {
                // Put the teams on the localstorage propriatelly
                this.setTeamsSelection(localBuildPlan.teams);
                localBuildPlan.teams = undefined;
            }
            this.localStorageHelper.setItem(this.BUILD_PLAN_SELECTOR, JSON.stringify(localBuildPlan));
            this.selectedBuildPlan = buildPlan;
            this.selectedBuildPlanId = buildPlan.id;
            this.selectedBuildPlanIdChange.next(buildPlan);
        } else {
            this.selectedBuildPlan = null;
            this.selectedBuildPlanId = -1;
        }
    }

    /**
     * Gets the currently selected build plan id from the local storage
     */
    getBuildPlanSelection(): number {
        const storageBuildPlan = this.localStorageHelper.getItem(this.BUILD_PLAN_SELECTOR);
        if (storageBuildPlan) {
            this.selectedBuildPlan = JSON.parse(storageBuildPlan);
            this.selectedBuildPlanId = this.selectedBuildPlan?.id as number;
        }
        return this.selectedBuildPlanId;
    }

    /**
     * Gets the currently selected build plan from the local storage
     */
    getBuildPlanObjectSelection(): IBuildPlanSelectorItem | null {
        const storageBuildPlan = this.localStorageHelper.getItem(this.BUILD_PLAN_SELECTOR);
        if (storageBuildPlan) {
            this.selectedBuildPlan = JSON.parse(storageBuildPlan);
            this.selectedBuildPlanId = this.selectedBuildPlan?.id as number;
        }
        return this.selectedBuildPlan;
    }

    /**
     * Set a new selected team and saves it to the local storage
     * @param teamId: ID of the team selected
     */
    setTeamSelection(teamId: number): void {
        if (teamId) {
            this.localStorageHelper.setItem(
                this.getCacheKey(this.TEAM_SELECTOR),
                teamId.toString()
            );
            this.selectedTeamId = teamId;
            this.selectedTeamIdChange.next(this.selectedTeamId);
        }
    }

    /**
     * Set a new selected teams and saves it to the local storage
     * @param teamId: IDs of the teams selecteds
     */
    setTeamsSelection(teams: ITeamSelectorItem[]): void {
        if (teams) {
            const teamIds = teams.map((team) => team.id);
            this.localStorageHelper.setItem(
                this.getCacheKey(this.TEAMS_SELECTOR),
                JSON.stringify(teamIds)
            );
            this.selectedTeams = teams;
            this.selectedTeamListIds = teamIds;
            this.selectedTeamIdsChange.next(teams);
        }
    }

    /**
     * Gets the currently selected team from the local storage
     */
    getTeamSelection(): number {
        const storageTeam = this.localStorageHelper.getItem(this.getCacheKey(this.TEAM_SELECTOR));
        if (storageTeam && !isNaN(Number(storageTeam))) {
            this.selectedTeamId = Number(storageTeam);
        }
        return this.selectedTeamId;
    }

    /**
     * Gets the currently selecteds team from the local storage
     */
    getTeamsSelection(): number[] {
        const storageTeam: number[] = JSON.parse(
            this.localStorageHelper.getItem(this.getCacheKey(this.TEAMS_SELECTOR))
        );

        return storageTeam
            ? (this.selectedTeamListIds = storageTeam)
            : (this.selectedTeamListIds = []);
    }

    setCachePrefix(key: string): void {
        this.prefixCache = key;
    }

    getCacheKey(key: string): string {
        if (this.prefixCache) {
            return this.prefixCache + '_' + key;
        }
        return key;
    }

    getFilters(): IPrototypeFilters {
        let filters = JSON.parse(
            this.localStorageHelper.getItem(this.getCacheKey(this.KEY_FILTER_STORAGE))
        );

        if (filters?.hwRevision) {
            filters.hwRevision = filters.hwRevision.split(',');
        }

        if (filters?.inputSearch) {
            filters.inputSearch = '';
        }

        if (!filters) {
            filters = {
                hwRevision: [],
                requested: this.ALL,
                requesteds: {},
                parameters: {},
                teamParametersSelected: {}
            };
        }

        return filters;
    }

    getRequestPreviewFilters(): IPrototypeFilters {
        let filters = JSON.parse(
            this.localStorageHelper.getItem(this.KEY_FILTER_STORAGE_REQUEST)
        );

        if (filters?.hwRevision) {
            filters.hwRevision = filters.hwRevision.split(',');
        }

        if (!filters) {
            filters = {
                hwRevision: [],
                requested: this.ALL,
                requesteds: {},
                parameters: {},
                teamParametersSelected: {}
            };
        }

        return filters;
    }

    resetFiltersSelected(): void {
        const filters = {
            hwRevision: [],
            requested: this.ALL,
            parameters: {},
            teamParametersSelected: {}
        };

        this.signalResetFiltersSelected.next(filters);
    }

    /**
     * Given a new IHwRevisionRequestsTotals entry, call requestedCountByHWRevisionChange with the new value
     *
     * @param requestsCountByHWRevision new value
     */
    updateRequestCountByHWRevision(requestsCountByHWRevision: IBuildPlanRequestsTotals): void {
        this.requestedCountByHWRevisionChange.next(requestsCountByHWRevision);
    }

    /**
     * Given a new prototype request value, send update to HW Revision headers
     *
     * @param prototype prototype being updated
     * @param newValue
     */
    updateRequestCountWithNewValue(hwRevision: string, difference: number): void {
        this.requestedCountUpdate.next({
            hwRevision,
            difference
        });
    }

    /**
     * Given IPrototypeFilters filter options, trigger filterOptionsChange with the new options
     */
    updateFilterOptions(filters: IPrototypeFilters): void {
        this.filterOptionsChange.next(filters);
    }

    /**
     * Given IPrototypeFilters filter selections, trigger filterSelectionsChange with the new selections
     */
    updateFilterSelections(filters: IPrototypeFilters): void {
        this.setFiltersOnStorage(filters);
        this.filterSelectionsChange.next(cloneDeep(filters));
    }

    setFiltersOnStorage(filters: IPrototypeFilters): void {
        const saveSomeFilters = {...filters};

        if (saveSomeFilters?.hwRevision) {
            saveSomeFilters.hwRevision = (saveSomeFilters.hwRevision as any).join(',');
        }

        this.localStorageHelper.setItem(
            this.getCacheKey(this.KEY_FILTER_STORAGE),
            JSON.stringify(saveSomeFilters)
        );
    }

    updateRequestPreviewFilterSelections(filters: IPrototypeFilters): void {
        this.setRequestPreviewFiltersOnStorage(filters);
        this.requestPeviewFilterChange.next(cloneDeep(filters));
    }

    setRequestPreviewFiltersOnStorage(filters: IPrototypeFilters): void {
        const saveSomeFilters = {...filters};

        if (saveSomeFilters?.hwRevision) {
            saveSomeFilters.hwRevision = (saveSomeFilters.hwRevision as any).join(',');
        }

        this.localStorageHelper.setItem(
            this.KEY_FILTER_STORAGE_REQUEST,
            JSON.stringify(saveSomeFilters)
        );
    }
    /**
     * Given a new IHwRevisionDiffRequest entry, call requestReviewHWChange with the new value
     *
     * @param requestReviewHW new value
     */
    updateRequestReviewHW(requestReviewHW: IBuildPlanTotalRequest[]): void {
        this.requestReviewHWChange.next(requestReviewHW);
    }

    /**
     * Given a value and an array, check if the value is contained within the array
     *
     * @param value string
     * @param array string array
     * @return boolean indicating if array contains the value
     */
    public isValueInArray(value: string, array: string[]): boolean {
        return array.indexOf(value) !== -1;
    }

    /**
     * Given an array of strings, return an equivalent array of IFieldValue
     * @param values
     */
    public getFieldValuesFromArray(values: string[]): IFieldValue[] {
        const fieldValues: IFieldValue[] = [];

        if (values && values.length) {
            values.forEach((value) => fieldValues.push({value}));
        }

        return fieldValues;
    }

    /**
     * Check if the configuration status isn't New / Requirements in Progress &&
     * @param prototype a prototype request
     */
    canShowPrototypeRequest(prototype: IProtoRequestHeader): boolean {
        return (
            !prototype.filtered &&
            !this.configurationStatusNotShow.includes(
                prototype.configStatus as EConfigurationStatusLabel
            )
        );
    }

    /**
     * Updates the filters options with the data from a given prototype
     *
     * @param filters being updated
     * @param prototype to get the information from
     */
    public updateFiltersObject(filters: IPrototypeFilters, prototype: IProtoRequestHeader): void {
        this.addToFiltersIfNotYetPresent(
            prototype.ram as string,
            EDataType.SYSTEM_RAM_SIZE,
            filters,
            this.labels.HEADER.FILTERS.RAM_SIZE
        );
        this.addToFiltersIfNotYetPresent(
            prototype.storage as string,
            EDataType.SYSTEM_STORAGE_SIZE,
            filters,
            this.labels.HEADER.FILTERS.STORAGE_SIZE
        );
        this.addToFiltersIfNotYetPresent(
            prototype.recipeConfig,
            EDataType.SYSTEM_BUILD_RECIPE,
            filters,
            this.labels.HEADER.FILTERS.BUILD_RECIPE
        );
        this.addToFiltersIfNotYetPresent(
            this.getSecureMarkingStringFromBoolean(prototype.secureMarking as string),
            EDataType.SYSTEM_SECURE_MARKING,
            filters,
            this.labels.HEADER.FILTERS.SECURE_MARKING
        );
        this.addToFiltersIfNotYetPresent(
            this.getFvcSecureStringFromBoolean(prototype.fvcSecure as boolean),
            EDataType.SYSTEM_SECURE,
            filters,
            this.labels.HEADER.FILTERS.SECURE
        );
        this.addToFiltersIfNotYetPresent(
            prototype.sourcing as string,
            EDataType.SYSTEM_SOURCING,
            filters,
            this.labels.HEADER.FILTERS.SOURCING
        );
        this.addToFiltersIfNotYetPresent(
            prototype.marketRegion as string,
            EDataType.SYSTEM_MARKET_REGION,
            filters,
            this.labels.HEADER.FILTERS.MARKET_REGION
        );
        this.addToFiltersIfNotYetPresent(
            prototype.productSku as string,
            EDataType.SYSTEM_PRODUCT_SKU,
            filters,
            this.labels.HEADER.FILTERS.PRODUCT_SKU
        );
        this.addToFiltersIfNotYetPresent(
            prototype.cmfColor as string,
            EDataType.SYSTEM_CMF_COLOR,
            filters,
            this.labels.HEADER.FILTERS.CMF_COLOR
        );
        this.addToFiltersIfNotYetPresent(
            prototype.simCard as string,
            EDataType.SYSTEM_SIM,
            filters,
            this.labels.HEADER.FILTERS.SIM
        );
        this.addToFiltersIfNotYetPresent(
            prototype.unitType as string,
            EDataType.SYSTEM_UNIT_TYPE,
            filters,
            this.labels.HEADER.FILTERS.UNIT_TYPE
        );
        this.addToFiltersIfNotYetPresent(
            prototype.xcvrKit as string,
            EDataType.SYSTEM_XCVR_KIT,
            filters,
            this.labels.HEADER.FILTERS.XCVR_KIT
        );
        this.addToFiltersIfNotYetPresent(
            moment(prototype.buildDate).format('MMM DD, YYYY'),
            this.labels.HEADER.FILTERS.BUILD_DATE,
            filters,
            this.labels.HEADER.FILTERS.BUILD_DATE
        );
        this.addToFiltersIfNotYetPresent(
            prototype.configFvc as string,
            this.labels.HEADER.FILTERS.FVC,
            filters,
            this.labels.HEADER.FILTERS.FVC
        );
        this.addToFiltersIfNotYetPresent(
            prototype.configStatus as string,
            this.labels.HEADER.FILTERS.CONFIG_STATUS,
            filters,
            this.labels.HEADER.FILTERS.CONFIG_STATUS
        );
        this.addToFiltersIfNotYetPresent(
            prototype.requestType as string,
            EDataType.SYSTEM_REQUEST_TYPE,
            filters,
            this.labels.HEADER.FILTERS.REQUEST_TYPE
        );
        if (this.canUserSeeArchivedHidden()) {
            this.addToFiltersIfNotYetPresent(
                prototype.isHidden ? this.labels.REQUEST.CARDS.HIDDEN : this.labels.REQUEST.CARDS.NOT_HIDDEN,
                this.labels.HEADER.FILTERS.HIDDEN,
                filters,
                this.labels.HEADER.FILTERS.HIDDEN
            );
            this.addToFiltersIfNotYetPresent(
                prototype.isArchived ? this.labels.REQUEST.CARDS.ARCHIVED : this.labels.REQUEST.CARDS.NOT_ARCHIVED,
                this.labels.HEADER.FILTERS.ARCHIVED,
                filters,
                this.labels.HEADER.FILTERS.ARCHIVED
            );
        }
    }

    /**
     * Used to check new item
     * @param item
     * @returns
     */
    isDeletedNewItem(item: IProtoRequest|any): boolean {
        return(
            (item.quantity==undefined || item.quantity==null || item.quantity==0) &&
            !item.firstQuantity
        );
    }

    /**
     * @description Create a prototypeRequest or if exist prototype with the same buildPlanId, update.
     *
     * @param value prototype relative to the request of each cell in the table
     * @param key storage key
     * @param idBuildPlan is used to find requests
     */
    saveStorage(
        value: IProtoRequestTableStorage<IRequestTableStorage>,
        idBuildPlan: number,
        key: string
    ): IProtoRequestTableStorage<IRequestTableStorage> {
        const localStorage = this.localStorageHelper.getPrototypeRequestsChanges(idBuildPlan);

        if (localStorage) {
            value.requests.forEach((request) => {
                const find = localStorage.requests.find(
                    (v) =>
                        v.idTeam === request.idTeam && v.configurationId === request.configurationId
                );
                if (find) {
                    find.quantity = request.quantity;
                    find.specialRequirements = request.specialRequirements;
                    find.comment = request.comment;
                    find.usersToNotify = request.usersToNotify;
                } else {
                    localStorage.requests.push(request);
                }
            });
            localStorage.comment = value.comment;
            localStorage.usersToNotify = value.usersToNotify;
            localStorage.justification = value.justification;
            localStorage.specialRequirements = value.specialRequirements;
            localStorage.overwriteAll = value.overwriteAll;
            localStorage.requests = localStorage.requests.filter(e=> !this.isDeletedNewItem(e));
            localStorage.requests.forEach(e=>{
                if(value.overwriteAll && e.quantity==0){
                    e.specialRequirements = [];
                    e.comment = '';
                    e.usersToNotify = [];
                }
            });

            this.localStorageHelper.setPrototypeRequestsChanges(key, localStorage);
        } else {
            value.idBuildPlan = idBuildPlan;
            this.localStorageHelper.setPrototypeRequestsChanges(key, value);
        }
        return localStorage || value;
    }

    /**
     * @description Get PrototypeRequests by buildPlan
     *
     * @param key is used to handler requests
     * @param idBuildPlan is used to find requests
     * @return IProtoRequestTableStorage
     */
    loadStorage(key: string, idBuildPlan: number): IProtoRequestTableStorage<IRequestTableStorage> {
        let localStorage: IProtoRequestTableStorage<IRequestTableStorage> = {
            idBuildPlan,
            requests: [],
            comment: null,
            overwriteAll: null,
            justification: {
                AUTOMATED_TESTING: false,
                MANUAL_TESTING: false,
                CARRIERS: false,
                SHOW_OFF: false,
                MARKETING: false,
                OTHER: false
            },
            specialRequirements: [],
            hasMixedUp: false
        } as any;
        if (key) {
            const localStorageFound =
                this.localStorageHelper.getPrototypeRequestsChanges(idBuildPlan);
            if (localStorageFound) {
                localStorage = localStorageFound;
            }
        }
        return localStorage;
    }

    /**
     * @description remove all changes in storage by buildPlan
     *
     * @param idBuildPlan is used to find requests
     * @param key is used to handle storage
     */
    undoAll(key: string, idBuildPlan: number): void {
        this.localStorageHelper.undoAllRequestByBuildPlan<IRequestTableStorage>(key, idBuildPlan);
    }

    /**
     * Given the secure marking boolean value, return the display string from the filters
     *
     * @param secureMarking value to convert
     * @return converted value
     */
    getSecureMarkingStringFromBoolean(secureMarking: string | boolean): string {
        return secureMarking || secureMarking === 'true'
            ? this.labels.REQUEST.CARDS.SECURE_MARKING
            : this.labels.REQUEST.CARDS.NO_MARKING;
    }

    /**
     * Given the secure marking boolean value, return the display string from the filters
     *
     * @param fvcSecure value to convert
     * @return converted value
     */
    getFvcSecureStringFromBoolean(fvcSecure: string | boolean): string {
        return fvcSecure || fvcSecure === 'true'
            ? this.labels.REQUEST.CARDS.FVC_SECURE
            : this.labels.REQUEST.CARDS.FVC_NO_SECURE;
    }

    getEmptyStorageObject(): IProtoRequestTableStorage<IRequestTableStorage> {
        return {
            idBuildPlan: null,
            requests: [],
            comment: null,
            overwriteAll: null,
            justification: {
                AUTOMATED_TESTING: false,
                MANUAL_TESTING: false,
                CARRIERS: false,
                SHOW_OFF: false,
                MARKETING: false,
                OTHER: false
            },
            specialRequirements: [],
            canChangeJustificationAndComment: true,
            hasMixedUp: false
        } as any;
    }

    /**
     * Check if the prototype is temporary,
     * case yes, return if the temporary disable date is after today
     * @param prototype a prototype request
     */
    private isTemporaryDisableDateAfter(prototype: IProtoRequestHeader): boolean {
        if (prototype.isTemporary) {
            if (!prototype.temporaryDisableDate) {
                return true;
            }
            return (
                moment(prototype.temporaryDisableDate).isAfter(moment()) ||
                moment().format('YYYY-MM-DD') === prototype.temporaryDisableDate
            );
        }
        return true;
    }

    /**
     * Adds a value to a given configuration filter if not yet included
     *
     * @param value to include
     * @param property DataType of that value
     * @param filters filters to include the value into
     * @param name of the configuration to display on filters
     */
    private addToFiltersIfNotYetPresent(
        value: string,
        property: string,
        filters: IPrototypeFilters,
        name: string
    ): void {
        if ((filters.parameters as any)[property] === undefined) {
            (filters.parameters as any)[property] = {name, values: []};
        }

        if (
            value &&
            !this.isValueInArray(String(value), (filters.parameters as any)[property].values)
        ) {
            (filters.parameters as any)[property].values.push(String(value));
        }
    }

    buildObjRequestToSave(
        objSave: IProtoRequestTableStorage<any>,
        cell: IProtoRequestCell,
        requestForm: FormGroup,
        specialRequirements: IPrototypeRequestTeamSpecialRequirements[]
    ): IProtoRequestTableStorage<any> {
        const special = (cell.item['specialRequirementList'] || []).map((special: any) => {
            return {
                id: special.id,
                specialRequirement: {
                    id: special.idSpecialRequirement,
                    fieldName: special.fieldName
                },
                specialRequirementsValue: {
                    id: special.idSpecialRequirementValue,
                    value: special.value
                },
                value: special.value
            };
        });
        objSave.requests.push({
            configurationId: cell.item['configurationId'],
            configurationStatus: cell.$column.configStatus,
            hwRevision: cell.$column.hwRevision,
            quantity: cell.item['quantity'],
            idTeam: cell.$row.idTeam,
            idTeamAssociation: cell.$row.idTeamAssociation,
            firstQuantity: cell.item['firstQuantity'],
            idTeamProtoRequest: cell.item['idTeamProtoRequest'],
            operation: cell.operation,
            specialRequirements: special,
            comment: cell.item['additionalComments']
        });
        objSave.comment = requestForm.value.comment;
        objSave.justification = requestForm.value.justification;
        objSave.specialRequirements = specialRequirements;
        objSave.overwriteAll = requestForm.value.overwriteAll;
        return objSave;
    }

    public updateRequestStatusLocalStorage(data:any, idBuildPlan:number){
        const localStorageRequests: IProtoRequestTableStorage<IRequestTableStorage> = this.localStorageHelper.getPrototypeRequestsChanges(
            idBuildPlan
        )!;
        const configurations: any = {};
        data.configurations.forEach((config: any) => {
            configurations[config.idConfiguration] = config;
        });
        localStorageRequests?.requests.forEach(r=>{
            const conf = configurations[r.configurationId];
            if (conf){
                r.configurationStatus=conf.configStatus;
            }
        });
        if(localStorageRequests){
            this.localStorageHelper.setPrototypeRequestsChanges('requests', localStorageRequests);
        }
    }
}
