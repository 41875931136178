import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {AuthNotice} from '@galvin/core/auth';

@Injectable({
    providedIn: 'root'
})
export class AuthNoticeService {
    onNoticeChanged$: BehaviorSubject<AuthNotice | null>;

    constructor() {
        this.onNoticeChanged$ = new BehaviorSubject<AuthNotice | null>(null);
    }

    setNotice(message?: string, type?: string) {
        let notice: AuthNotice | null = null;
        if (message) {
            notice = {
                message,
                type
            };
        }
        this.onNoticeChanged$.next(notice);
    }
}
