import { IGroup } from '../../core/build-plan/group-management';
import { IUserManagement } from '../build-plan/user-management';

export enum EApplication {
    GALVIN = 'GALVIN',
    BUILD_PLAN = 'BUILD_PLAN',
    CDC = 'CDC',
    FINANCE = 'FINANCE'
}

export enum ENotificationEvent {
    BP_REQUESTS_CHANGE = 'BP_REQUESTS_CHANGE',
    BP_CREATION_VALUE = 'BP_CREATION_VALUE',
    BP_OPEN_VALUE = 'BP_OPEN_VALUE',
    BP_REQUESTS_CHANGE_VALUE = 'BP_REQUESTS_CHANGE_VALUE',
    BP_ACCESSORY_REQUESTS_CHANGE_VALUE = 'BP_ACCESSORY_REQUESTS_CHANGE_VALUE',
    BP_CONFIGURATION_CHANGE_VALUE = 'BP_CONFIGURATION_CHANGE_VALUE',
    BP_TEAM_REMOVED_VALUE = 'BP_TEAM_REMOVED_VALUE',
    BP_CONFIGURATION_REMOVED_VALUE = 'BP_CONFIGURATION_REMOVED_VALUE'
}

export enum ENotificationOriginAssignment {
    AUTOMATED = 'AUTOMATED'
}

export enum ENotificationDeliveryType {
    APPLICATION = 'APPLICATION',
    EMAIL = 'EMAIL',
    ALL = 'ALL'
}

export enum ENotificationSeverity {
    LOW = 'LOW',
    BLOCKER = 'BLOCKER',
    CRITICAL = 'CRITICAL',
    MAJOR = 'MAJOR',
    MINOR = 'MINOR'
}

export enum ENotificationStatus {
    NEW = 'NEW',
    SEEN = 'SEEN',
    DISMISSED = 'DISMISSED',
    EXPIRED = 'EXPIRED'
}

export enum ENotificationTriggerType {
    AUTOMATED_TRANSITION = 'AUTOMATED_TRANSITION'
}

export enum ENotificationType {
    INFO = 'INFO',
    REQUIRED = 'REQUIRED',
    APPROVAL = 'APPROVAL'
}

export enum EReceiverType {
    GROUP = 'group',
    USER = 'user',
    BP_HWPM = 'BP_HWPM',
    ALL_HWPM = 'ALL_HWPM',
    ALL_USERS = 'ALL',
    ALL_USERS_WITH_REQUEST = 'USERS_WITH_REQUEST'
}

export interface INotification {
    id: number;
    actionId: string;
    application: EApplication;
    templateName: string;
    template: { id: number };
    buildPlanId: number;
    buildPlanName: string;
    createdByUser: string;
    createdDate: Date;
    event: ENotificationEvent;
    deliveryTypes: Array<ENotificationDeliveryType>;
    expirationDate: Date;
    originAssignment: ENotificationOriginAssignment;
    assigneeUserEmail: string;
    severity: ENotificationSeverity;
    status: ENotificationStatus;
    summary: string;
    content?: string;
    triggerType: ENotificationTriggerType;
    type: ENotificationType;
}

export interface INotificationCreate {
    buildPlanId: number;
    content: string;
    coreIds: string[];
    groupsIds: string[];
    specialRecipients: SpecialReceiver[];
    notificationDeliveryTypeEnum: ENotificationDeliveryType[];
    subject: string;
    templateId: number;
}

export type SpecialReceiver =
    | EReceiverType.ALL_HWPM
    | EReceiverType.BP_HWPM
    | EReceiverType.ALL_USERS
    | EReceiverType.ALL_USERS_WITH_REQUEST;

export interface IReceiver {
    type: EReceiverType;
    data: IUserManagement | IGroup | string;
}
