import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    Router,
    CanActivateChild
} from '@angular/router';
import { Observable } from 'rxjs';
import { SecurityService } from '@galvin/core/auth';

@Injectable()
export class AuthGuard implements CanActivateChild {
    /**
     * Creates an instance of AuthGuard.
     *
     * @param {Router} router
     * @param securityService
     * @memberof AuthGuard
     */
    public constructor(private router: Router, private securityService: SecurityService) {}

    /**
     * blocks or permits the desired route to be accessed depending on whether the user is logged in or not
     *
     * @param {ActivatedRouteSnapshot} next
     * @param {RouterStateSnapshot} state
     * @returns {(Observable<boolean> | boolean)}
     * @memberof AuthGuard
     */

    public canActivateChild(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        next: ActivatedRouteSnapshot,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        state: RouterStateSnapshot
    ): Observable<boolean> {
        this.securityService.urlToAccess=state.url;
        return this.securityService.waitAuthenticator();
    }
}
