import { OnDestroy } from '@angular/core';
// Angular
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
// RxJS
import { Subscription } from 'rxjs';
// Object-Path
import * as objectPath from 'object-path';
// Layout
import { LayoutConfigService } from '../../../core/_base/layout';
import { HtmlClassService } from '../html-class.service';

import { LayoutConfig } from '../../../core/_config/layout.config';

// This component is like the Base Component but without an aside left && subheader component

declare var document: Document;
@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'base-min',
    templateUrl: './base-min.component.html',
    styleUrls: ['./base-min.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class BaseMinComponent implements OnInit, OnDestroy {
    // Public variables
    desktopHeaderDisplay!: boolean;

    // Private properties
    private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

    /**
     * Component constructor
     *
     * @param layoutConfigService: LayoutConfigService
     * @param htmlClassService: HtmlClassService
     * @param store
     * @param permissionsService
     */
    constructor(
        private layoutConfigService: LayoutConfigService,
        private htmlClassService: HtmlClassService
    ) {
        // register configs by demos
        this.layoutConfigService.loadConfigs(new LayoutConfig().configs);

        // setup element classes
        this.htmlClassService.setConfig(this.layoutConfigService.getConfig());

        const subscr = this.layoutConfigService.onConfigUpdated$.subscribe((layoutConfig) => {
            // reset body class based on global and page level layout config, refer to html-class.service.ts
            document.body.className = '';
            this.htmlClassService.setConfig(layoutConfig);
        });
        this.unsubscribe.push(subscr);
    }

    /**
     * On init
     */
    ngOnInit(): void {
        const config = this.layoutConfigService.getConfig();
        this.layoutConfigService.setConfig('subheader.display', false);
        this.desktopHeaderDisplay = objectPath.get(config, 'header.self.fixed.desktop');
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        this.unsubscribe.forEach((sb) => sb.unsubscribe());
    }
}
