import { IBuildPlanImportApprovalI18n } from '@galvin/core/build-plan/import-approval/i18n/build-plan-import-approval-i18n.interface';

export const BUILD_PLAN_IMPORT_APPROVAL: IBuildPlanImportApprovalI18n = {
    LABEL: {
        REVIEW: 'Review'
    },
    MESSAGE: {
        NO_IMPORT_APPROVAL_DATA: 'There are no Imports to Review here.',
        SUCCESSFUL_REJECTED: 'Import Approval successful rejected.',
        SUCCESSFUL_APPROVED: 'Import Approval successful approved.'
    },
    STATUS: {
        SEARCH: {
            PLACEHOLDER: 'Search Build Plan Import',
            ALL_STATUS: 'All',
            PENDING_STATUS: 'Pending',
            APPROVED_STATUS: 'Approved',
            REJECTED_STATUS: 'Rejected'
        }
    },
    TABLE_COL_LABEL: {
        TASK_STATUS: 'Task Status',
        IMPORTED_BY: 'Importer',
        IMPORTED_DATE: 'Import Date',
        TYPE: 'Import type',
        STATUS: 'Import Status',
        APPROVAL_BY: 'Approver',
        APPROVAL_DATE: 'Approval Date',
        REASON: 'Reason',
        GOOGLE_SHEET_URL: 'Sheet URL'
    },
    DIALOG: {
        REASON: 'Reason',
        STATUS: 'Status'
    },
    BUTTONS: {
        BACK: 'Back',
        REJECT: 'Reject',
        APPROVE: 'Approve'
    }
};
