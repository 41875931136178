// Angular
import { Pipe, PipeTransform } from '@angular/core';
import { DateTimeUtils } from './../../../utils/moment-utils';

@Pipe({
    name: 'parseDateTimeSimple'
})
export class ParseDatetimeSimplePipe implements PipeTransform {
    /**
     * Transform to parse the input to Datetime as MMM DD, YYYY HH:mm Z
     *
     * @param input That represents a Date to format
     * @param hasTimezone (default true) If the input has timezone info
     */
    transform(input: string, hasTimezone = true): string {
        if (input) {
            return DateTimeUtils.galvinLocalDateTimeFormat(input, hasTimezone);
        }
        return '';
    }
}
