export class AccessoryConstants {
    public static UNDERSCORE = '_';
    public static DASH = '--';
    public static ACCESSORY = 'ACCESSORY';
    public static ACCESSORY_STATUS = 'ACCESSORY_STATUS';
    public static BUFFER_PERCENT = 'BUFFER_PERCENT';
    public static OVERWRITTEN = 'OVERWRITTEN';
    public static ORDER = 'ORDER';
    public static NAME = 'NAME';
    public static ID = 'ID';
    public static IS_OPENED = 'IS_OPENED';
    public static IS_TEMPORARY = 'IS_TEMPORARY';
    public static ACCESSORY_STATUS_DUE_DATE = 'ACCESSORY_STATUS_DUE_DATE';
    public static ACCESSORY_STATUS_TEMPORARY_DISABLE_DATE =
        'ACCESSORY_STATUS_TEMPORARY_DISABLE_DATE';

    public static CATEGORY = 'category';
    public static VENDOR = 'vendor';
    public static VENDOR_PART = 'vendorPart#';
    public static MOTO_PART = 'motoPart#';

    public static CATEGORY_VALUE= 'Category';
    public static VENDOR_VALUE= 'Vendor';
    public static VENDOR_PART_VALUE= 'Vendor Part#';
    public static MOTO_PART_VALUE= 'Moto Part#';

    public static ALL = 'All';
    public static ALREADY_REQUESTED = 'Already Requested';
    public static TO_REQUEST = 'To Request';

}
