import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { CoreModule } from '@galvin/core/core.module';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { DiffDirective } from './diff.directive';
import { GenericFieldReadingTmplDirective } from './generic-field/generic-field-reading-tmpl.directive';
import { GenericFieldTemplateConfigDirective } from './generic-field/generic-field-template-config.directive';
import { GenericFieldTemplateDirective } from './generic-field/generic-field-template.directive';
import { GenericFieldComponent } from './generic-field/generic-field.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';

@NgModule({
    declarations: [
        GenericFieldComponent,
        GenericFieldReadingTmplDirective,
        GenericFieldTemplateConfigDirective,
        GenericFieldTemplateConfigDirective,
        GenericFieldTemplateDirective,
        DiffDirective
    ],
    exports: [GenericFieldComponent, DiffDirective, GenericFieldTemplateDirective],
    imports: [
        CommonModule,
        NgbTooltipModule,
        NgSelectModule,
        FormsModule,
        MatCheckboxModule,
        CoreModule,
        MatProgressSpinnerModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatInputModule
    ]
})
export class GenericFieldModule {}
