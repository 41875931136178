import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

// Interfaces
import { ILocation } from '../../location/interfaces/location.interface';
import { LocationResource } from './location-resource';
import { ResourceService } from '@galvin/core/_base/resource';
import { map } from 'rxjs/operators';
import {IDeletableValue} from '@galvin/core/build-plan/field-value-management/fvc-parameters/interfaces/deletable-parameter.interface';

@Injectable({
    providedIn: 'root'
})
export class LocationService extends ResourceService {
    private locationResource = new LocationResource();
    constructor(http: HttpClient) {
        super(http);
    }

    /**
     * Get All locations
     */
    getAllLocations(): Observable<ILocation[]> {
        this.locationResource._links.self = {
            href: '/build-plan/field-values-management/location'
        };
        return this.get<ILocation[], LocationResource>(this.locationResource).pipe(
            map((response) => {
                return response.content;
            })
        );
    }

    /**
     * Get all locations enabled
     */
    getAllLocationsEnabled(): Observable<ILocation[]> {
        this.locationResource._links.self = {
            href: '/build-plan/field-values-management/location/enabled'
        };
        return this.get<ILocation[], LocationResource>(this.locationResource).pipe(
            map((response) => {
                return response.content;
            })
        );
    }

    /**
     * Create a new Location
     */
    createLocation(location: ILocation): Observable<ILocation> {
        this.locationResource._links.self = {
            href: '/build-plan/field-values-management/location'
        };
        this.locationResource.content = location;
        return this.post<ILocation, LocationResource>(this.locationResource).pipe(
            map((response) => {
                return response.content;
            })
        );
    }

    /**
     * Update a Location
     */
    updateLocation(location: ILocation): Observable<ILocation> {
        this.locationResource._links.self = {
            href: '/build-plan/field-values-management/location'
        };
        this.locationResource.content = location;
        return this.put<ILocation, LocationResource>(this.locationResource).pipe(
            map((response) => {
                return response.content;
            })
        );
    }

    /**
     * Update Status a Location
     */
    updateStatusLocation(idLocation: number): Observable<ILocation> {
        this.locationResource._links.self = {
            href: `/build-plan/field-values-management/location/${idLocation}/update-enabled`
        };
        return this.put<ILocation, LocationResource>(this.locationResource).pipe(
            map((response) => {
                return response.content;
            })
        );
    }

    /**
     * Get a list of deletable Scope Parameters.
     */
    getDeletableParameters(): Observable<IDeletableValue[]> {
        this.locationResource._links.self = {
            href: '/build-plan/field-values-management/location/can-delete'
        };
        return this.get<IDeletableValue, LocationResource>(
            this.locationResource
        ).pipe(map(res => res.content));
    }

    /**
     * Deletes a location, if it's not used.
     * @params idLocation id of location
     */
    deleteLocation(idLocation: number): Observable<any> {
        this.locationResource._links.self = {
            href: `/build-plan/field-values-management/location/${idLocation}`
        };
        return this.delete(
            this.locationResource
        );
    }
}
