// Angular
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    HostListener,
    Input,
    OnDestroy,
    ViewChild
} from '@angular/core';
// RxJS
import { Subscription } from 'rxjs';

// Layout
import { NavigationEnd, Router } from '@angular/router';
import { ToolbarService } from '@galvin/views/theme/services/toolbar.service';
import {
    LayoutConfigService,
    MenuAsideService,
    SubheaderService,
    ToggleDirective,
    ToggleOptions
} from '../../../../../core/_base/layout';
import { Breadcrumb } from '../../../../../core/_base/layout/services/subheader.service';

declare var KTUtil: any;

@Component({
    selector: 'kt-subheader-galvin',
    templateUrl: './subheader-galvin.component.html',
    styleUrls: ['./subheader-galvin.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubheaderGalvinComponent implements OnDestroy, AfterViewInit {
    // Public properties
    @Input() fluid!: boolean;
    @Input() clear!: boolean;
    headerLogo!: string;
    headerStickyLogo!: string;
    clickforfullscreen: string = 'Hide the Toolbars (CTRL+SHIFT+F)';
    toggleOptions: ToggleOptions = {
        target: 'body',
        targetState: 'kt-aside--minimize',
        togglerState: 'kt-aside__brand-aside-toggler--active'
    };

    today: number = Date.now();
    title = '';
    desc = '';
    breadcrumbs: Breadcrumb[] = [];
    toggleDirective!: ToggleDirective;
    receivedData: any = '';
    // Private properties
    private subscriptions: Subscription[] = [];

    private readonly CDC_PROTOTYPE_SORTING_URL = '/cdc/prototype-sorting';
    private readonly CDC_ACCESSORY_TRACKER_URL = '/cdc/accessory-tracker';
    private readonly PO_QUOTATION_URL = '/finance/po-quotation/';

    /**
     * Component constructor
     *
     * @param subheaderService: SubheaderService
     */
    constructor(
        public subheaderService: SubheaderService,
        private changeDetector: ChangeDetectorRef,
        private sidebarService: MenuAsideService,
        public layoutConfigService: LayoutConfigService,
        private router: Router,
        private toolbarService: ToolbarService
    ) { }



    /**
     * Checks if fullscream is enabled, clicked on the head component and layout service
     */
    private onVerifyFullScreen() {
        this.toolbarService.getFullScreen().subscribe(data => {
            this.receivedData = data;
            if (this.receivedData) {
                this.onSidebarToggleClose(100);
            } else {
                setTimeout(() => {
                    this.onSidebarToggle();
                }, 500);
            }

        });
    }

    /**
     * After view init
     */
    ngAfterViewInit(): void {
        this.subscriptions.push(
            this.subheaderService.title$.subscribe((bt) => {
                // breadcrumbs title sometimes can be undefined
                if (bt) {
                    Promise.resolve(null).then(() => {
                        this.title = bt.title;
                        this.desc = bt.desc ? bt.desc : '';

                        this.changeDetector.detectChanges();
                    });
                }
            })
        );

        this.subscriptions.push(
            this.subheaderService.breadcrumbs$.subscribe((bc) => {
                Promise.resolve(null).then(() => {
                    this.breadcrumbs = bc;

                    this.changeDetector.detectChanges();
                });
            })
        );

        const isSidebarOpen = this.isSidebarOpen();
        this.sidebarService.sidebarOpenCloseChanged(isSidebarOpen);
        if(this.router.url.includes(this.CDC_PROTOTYPE_SORTING_URL) || this.router.url.includes(this.CDC_ACCESSORY_TRACKER_URL) || this.router.url.includes(this.PO_QUOTATION_URL)){
            this.onSidebarToggleClose(1500);
        }

        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                if(event.url.includes(this.CDC_PROTOTYPE_SORTING_URL) || this.router.url.includes(this.CDC_ACCESSORY_TRACKER_URL || event.url.includes(this.PO_QUOTATION_URL))){

                    this.onSidebarToggleClose();
                }
                if(event.url.includes(this.PO_QUOTATION_URL)){
                    this.onSidebarToggleClose(1500);
                }
            }
        });
    }

    @ViewChild(ToggleDirective)
    set toggleDirectiveSet(directive: ToggleDirective) {
        this.toggleDirective = directive;
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        this.subscriptions.forEach((sb) => sb.unsubscribe());
    }

    /**
     * On window resize
     */
    @HostListener('window:resize')
    onResize(): void {
        // Sidebar is hidden if width is less than 1025px
        if (
            (window.innerWidth < this.sidebarService.SIDEBAR_SHOW_SIZE &&
                this.sidebarService.isSidebarOpen) ||
            (window.innerWidth >= this.sidebarService.SIDEBAR_SHOW_SIZE &&
                !this.sidebarService.isSidebarOpen)
        ) {
            this.onSidebarToggle();
        }
    }

    /**
     * Called whenever the sidebar is shown/hidden
     */
    onSidebarToggle() {
        // The timeout accounts for the sidebar open/close animation
        this.changeDetector.detectChanges();
        setTimeout(() => {
            this.sidebarService.sidebarOpenCloseChanged(!this.sidebarService.isSidebarOpen);
        }, 500);
    }

    /**
     * Called whenever the sidebar is hidden
     */
    onSidebarToggleClose(timeDelay=500) {
        setTimeout(() => {
            this.sidebarService.sidebarOpenCloseChanged(false);
            this.toggleDirective.toggle.toggleOn();
            this.changeDetector.detectChanges();
        }, timeDelay);
    }

    /**
     * Checks whether the sidebar is open or not
     *
     * @return boolean indicating if the sidebar is open
     */
    public isSidebarOpen(): boolean {
        return (
            !document.body.classList.contains('kt-aside--minimize') &&
            KTUtil.isInResponsiveRange('desktop') &&
            window.innerWidth >= this.sidebarService.SIDEBAR_SHOW_SIZE
        );
    }

    isGalvinHome(): boolean {
        return this.router.url !== '/';
    }

    fullScreen() {
        this.toolbarService.setFullScreen(true);
        document.body.classList.add('page-fullscreen');
    }

}