import {Observable, of} from 'rxjs';
import {GenericResource, ResourceService} from '@galvin/core/_base/resource';
import {HttpClient} from '@angular/common/http';
import {IUserManagement} from '../interfaces/user-management.interface';
import {UserManagementResource} from './user-management-resource';
import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {ERolesBuildPlan} from '@galvin/core/auth/_enums/roles-build-plan.enum';

@Injectable({
    providedIn: 'root'
})
export class UserManagementService extends ResourceService {
    public cachedUsers: IUserManagement[] = [];
    private userManagementResource = new UserManagementResource();

    constructor(http: HttpClient) {
        super(http);
    }

    getAllUsersToComment(): Observable<IUserManagement[]> {
        if(this.cachedUsers.length > 0){
            return of(this.cachedUsers);
        }else{
            this.userManagementResource._links.self = {
                href: '/galvin/users?includeExternal=true'
            };
            return this.get<IUserManagement[], UserManagementResource>(
                this.userManagementResource
            ).pipe(
                map((response) => {
                    this.cachedUsers = response.content;
                    return response.content;
                })
            );
        }
    }

    getAllUsers(): Observable<IUserManagement[]> {
        this.userManagementResource._links.self = {
            href: '/galvin/users/groups?includeExternal=true'
        };
        return this.get<IUserManagement[], UserManagementResource>(
            this.userManagementResource
        ).pipe(
            map((response) => {
                return response.content;
            })
        );
    }

    createUser(user: IUserManagement): Observable<IUserManagement> {
        this.userManagementResource._links.self = {
            href: '/galvin/users/propagate/build-plan-management'
        };
        this.userManagementResource.content = user;
        return this.post<IUserManagement, UserManagementResource>(this.userManagementResource).pipe(
            map((response) => {
                return response.content;
            })
        );
    }

    updateUserStatus(email: string): Observable<IUserManagement> {
        this.userManagementResource._links.self = {
            href: `/galvin/users/${email}/status`
        };
        return this.put<IUserManagement, UserManagementResource>(this.userManagementResource).pipe(
            map((response) => {
                return response.content;
            })
        );
    }

    updateUser(user: IUserManagement, email: string): Observable<IUserManagement> {
        this.userManagementResource._links.self = {
            href: `/galvin/users/${email}/propagate/build-plan-management`
        };
        this.userManagementResource.content = user;
        return this.put<IUserManagement, UserManagementResource>(this.userManagementResource).pipe(
            map((response) => {
                return response.content;
            })
        );
    }

    askForPermission(email: string, role: ERolesBuildPlan): Observable<void> {
        return this.post(new GenericResource(`/build-plan/permission-management/request-permission/user/${email}/role/${role}`));
    }
}
