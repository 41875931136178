import { IFieldValue } from '@galvin/core/build-plan/field-value-management';
/* eslint-disable @angular-eslint/no-output-on-prefix */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { IAccessoryRequestI18n } from '@galvin/core/build-plan/accessory-request-management/i18n/accessory-request-i18n.interface';
import { IAccessoryFieldValue } from '@galvin/core/build-plan/field-value-management/interfaces/field-value.interface';
import { TranslateService } from '@ngx-translate/core';
import { IAccessoryParameter } from './../../../pages/build-plan/build-plan-management/accessories/accessory-parameter.interface';
import { IOptionsModel } from './../configuration-filters/configuration-parameters-filters.component';

@Component({
    selector: 'accessory-parameters-filter',
    templateUrl: './accessory-parameters-filter.component.html',
    styleUrls: ['./accessory-parameters-filter.component.scss']
})
export class AccessoryParametersFilterComponent implements OnInit {
    @Input() loading = false;
    accessoryParameters: IAccessoryParameter[] = [];
    optionsModel: IOptionsModel = {};
    labels!: IAccessoryRequestI18n;

    @Input() searchForm: FormGroup = new FormGroup({});

    @Output() onFilterSelection = new EventEmitter<{
        parameter: IAccessoryParameter;
        value: IAccessoryFieldValue;
        selected: boolean;
        optionsSelected?: IOptionsModel;
    }>();

    @Input() set optionsSelected(optionsModel: IOptionsModel) {
        this.optionsModel = optionsModel;
    }

    @Input() set parameters(parameters: IAccessoryParameter[]) {
        if (!parameters) {
            return;
        }

        this.accessoryParameters = parameters.filter(
            (accessory) => accessory.fieldValues && accessory.fieldValues.length > 0
        );
        this.accessoryParameters.sort((acc1, acc2) => {
            const acc1Values = acc1.fieldValues as IFieldValue[];
            const acc2Values = acc2.fieldValues as IFieldValue[];

            return acc1Values > acc2Values ? 1 : 0;
        });
    }

    constructor(private translate: TranslateService) {}

    ngOnInit(): void {
        this.labels = this.translate.instant('BUILD_PLAN.ACCESSORY_REQUEST');
    }

    /**
     * Function called when a filter option is selected, it updates the accessory filters
     *
     * @param fieldValue the value selected
     * @param parameter the parameter selected
     */
    onOptionSelected(fieldValue: IAccessoryFieldValue, parameter: IAccessoryParameter): void {
        const field = parameter.fieldName as string;
        const value = fieldValue.value as string;

        if (!this.optionsModel[field]) {
            this.optionsModel[field] = {};
        }

        if (this.optionsModel[field][value] === undefined) {
            this.optionsModel[field][value] = false;
        }

        this.optionsModel[field][value] = !this.optionsModel[field][value];
        this.onFilterSelection.emit({
            parameter: parameter,
            value: fieldValue,
            selected: this.optionsModel[field][value],
            optionsSelected: this.optionsModel
        });
    }

    /**
     * Checks if a given accessory is selected
     *
     * @param value field value
     * @param parameter FVC parameter
     */
    isSelected(value: string, parameter: string): boolean {
        return (
            this.optionsModel && this.optionsModel[parameter] && this.optionsModel[parameter][value]
        );
    }

    /**
     * Given a value, return the string that should be displayed on the filter option
     *
     * @param value the display string
     */
    getValue(value: string): string {
        return value ? value : '—';
    }
}
