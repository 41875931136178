import { AfterViewInit, ChangeDetectorRef, Component, OnInit, Renderer2, TemplateRef, ViewChild, ViewContainerRef, AfterViewChecked } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from 'environments/environment';
import { ChatbotSidePanelService } from '../../../../core/_base/layout/services/chatbot-side-panel.service';

@Component({
    selector: 'kt-chatbot-side-panel',
    templateUrl: './chatbot-side-panel.component.html',
    styleUrls: ['./chatbot-side-panel.component.scss'],
})
export class ChatbotSidePanelComponent implements AfterViewChecked, OnInit, AfterViewInit {
    headerColor!: string;
    isOpen!: boolean;

    projectId!: string;
    dialogAgentId!: string;

    @ViewChild('templateContainer', { read: ViewContainerRef }) templateContainer!: ViewContainerRef;
    @ViewChild('dfMessengerChat') dfMessengerChat!: TemplateRef<any>;

    constructor(
        private changeDetector: ChangeDetectorRef,
        private sidePanelService: ChatbotSidePanelService,
        private render: Renderer2,
        private router: Router
    ) {
        this.headerColor = sidePanelService.getHeaderColor();
        this.isOpen = sidePanelService.isSidePanelOpen;

        this.projectId = 'galvin-' + environment.name;
        this.dialogAgentId = sidePanelService.dialogAgentID;

    }

    ngOnInit(): void {
        this.changeDetector.detectChanges();
        this.sidePanelService.sidePanelOpenCloseListener$.subscribe((isOpen: boolean) => { this.updateChatbotMinimizeClass(isOpen); });
    }

    ngAfterViewInit(): void {
        this.router?.events?.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.headerColor = this.sidePanelService.getHeaderColor();
                this.isOpen = this.sidePanelService.isSidePanelOpen;
            }
        });

        this.updateChatbotMinimizeClass(this.isOpen);

        const dfMessenger = this.render.createElement('df-messenger');
        this.render.setAttribute(dfMessenger, 'project-id', this.projectId);
        this.render.setAttribute(dfMessenger, 'agent-id', this.dialogAgentId);
        this.render.setAttribute(dfMessenger, 'language-code', 'en');
        this.render.setAttribute(dfMessenger, 'intent', 'automatic-welcome');

        if(this.templateContainer && this.templateContainer.element){
            this.render.appendChild(dfMessenger, this.dfMessengerChat.createEmbeddedView(null).rootNodes[0]);
            this.render.appendChild(this.templateContainer.element.nativeElement.parentElement, dfMessenger);
        }
    }

    ngAfterViewChecked(): void {
        this.updateTitlebarBackgroundColor();
    }

    private updateChatbotMinimizeClass(isOpen: boolean): void {
        if (document.body.classList.contains('kt-chatbot--fixed')) {
            this.isOpen = isOpen;
            // if the left side panel menu was minimized
            if (isOpen) {
                this.render.removeClass(document.body, 'kt-chatbot--minimize');
            } else {
                this.render.addClass(document.body, 'kt-chatbot--minimize');
            }
        }
    }

    /**
     * Fetches titlebar and updates background color depending on current module.
     */
    private updateTitlebarBackgroundColor() : void {
        const dfMessengerTitlebar = document.querySelector('.df-messenger-titlebar');
        if (dfMessengerTitlebar) {
            this.render.setStyle(dfMessengerTitlebar, 'background-color', this.headerColor);
        }
    }


    /**
     * Called whenever the side panel is hidden
     */
    public onSidePanelClose() {
        this.isOpen = false;
        this.sidePanelService.sidePanelOpenCloseChanged(false);
        this.changeDetector.detectChanges();
    }

}
