import {Injectable, TemplateRef} from '@angular/core';
import {
    IProtoRequestTableStorage
} from '@galvin/core/build-plan/prototype-request-management/interfaces/proto-request-table-storage.interface';
import {
    IRequestTableStorage
} from '@galvin/core/build-plan/prototype-request-management/interfaces/request-table-storage.interface';
import {LocalStorageHelper} from '@galvin/core/storage/local-storage-helper.service';
import {
    IProtoRequest,
    IProtoRequestCell,
    IProtoRequestHeader,
    IProtoRequestTeam
} from '@galvin/views/partials/layout/proto-request-table';
import {
    EProtoRequestTableCellState
} from '@galvin/views/partials/layout/proto-request-table/proto-request-table-cell-state.enum';
import {ProtoRequestTableService} from '@galvin/views/partials/layout/proto-request-table/proto-request-table.service';
import { isEmpty } from 'lodash';
import { IHwRevision } from '../../prototype-request-management/interfaces/hw-revision.interface';

@Injectable({
    providedIn: 'root'
})
export class ConfigurationRequestTableService
implements ProtoRequestTableService<IProtoRequestHeader, IProtoRequestTeam> {

    sumRequests(
        value: IProtoRequest[]
    ): number {
        return value.reduce((acc, req) => Number(req.quantity) + acc, 0);
    }

    sumPortable(
        value: IProtoRequest[],
        filteredRevions: string[],
        allrevisions: IHwRevision[]
    ): number {
        const foundData = this.getSumHwRevisionRows(value, filteredRevions, allrevisions);
        return (foundData && foundData.length > 0) ? foundData.reduce((acc, req) => Number(req.quantity) + acc, 0) : 0;
    }

    sumBoards(
        value: IProtoRequest[],
        filteredRevions: string[],
        allrevisions: IHwRevision[]
    ): number {
        const foundData = this.getSumGroupHwRevisionRows(value, filteredRevions, allrevisions);
        return (foundData && foundData.length > 0) ? foundData.reduce((acc, req) => Number(req.quantity) + acc, 0) : 0;
    }

    getSumGroupHwRevisionRows(value: IProtoRequest[], filteredRevions: string[], allBoardRevisions: IHwRevision[]): IProtoRequest[] {
        const foundData = (filteredRevions.length) ?
            value?.filter((c: any) => allBoardRevisions.filter(r => filteredRevions.includes(r.name!))
                .some((r:IHwRevision) => r.fvcValueId === c.revision)) :
            value?.filter((c: any) => allBoardRevisions.some((r:IHwRevision) => r.fvcValueId === c.revision));
        return foundData;
    }

    getSumHwRevisionRows(value: IProtoRequest[], filteredRevions : string[], portableRevisions: IHwRevision[]): IProtoRequest[] {
        const foundData = (filteredRevions.length) ?
            value?.filter((c: any) => portableRevisions.filter(r => filteredRevions.includes(r.name!))
                .some((r:IHwRevision) => r.fvcValueId === c.revision)) :
            value?.filter((c: any) => portableRevisions.some((r:IHwRevision) => r.fvcValueId === c.revision));
        return foundData;
    }

    undoStorage(
        prototypeRequestsChanges: IProtoRequestTableStorage<IRequestTableStorage>,
        column: IProtoRequestHeader,
        row: IProtoRequestTeam,
        localStorageHelper: LocalStorageHelper,
        saveStorageItem: string
    ): void {
        if (prototypeRequestsChanges) {
            const value = {
                configurationId: column.idConfiguration,
                idTeam: row.idTeam,
                quantity: null
            };
            const index = prototypeRequestsChanges.requests.findIndex(
                (v) => v.idTeam === value.idTeam && value.configurationId === v.configurationId
            );
            if (index !== -1) {
                prototypeRequestsChanges.requests.splice(index, 1);

                localStorageHelper.setPrototypeRequestsChanges(
                    saveStorageItem,
                    prototypeRequestsChanges
                );
            }
        }
    }

    applyLoadStorage(
        localStorage: IProtoRequestTableStorage<IRequestTableStorage>,
        edited: Record<string, IProtoRequestCell>
    ): void {
        localStorage.requests.forEach((v) => {
            const col: IProtoRequestHeader = <any>{
                name: null,
                idConfiguration: v.configurationId,
                recipeConfig: null,
                totalRequests: null
            };
            const row: IProtoRequestTeam = {
                idTeam: v.idTeam
            };

            const item: any = {...v, additionalComments: v.comment};

            item.specialRequirementList = (v.specialRequirements || []).map((special) => {
                return {
                    fieldName: (special.specialRequirement != undefined ? special.specialRequirement.fieldName : (special as any)?.fieldName),
                    value: special.value,
                };
            });

            edited[this.getCellIndex(col, row)] = {
                $column: col,
                $row: row,
                item,
                operation: v.operation
            };
        });
    }

    findRequest(
        prototypeRequestsChanges: IProtoRequestTableStorage<IRequestTableStorage>,
        value: IRequestTableStorage
    ): number {
        return prototypeRequestsChanges.requests.findIndex(
            (v) => v.idTeam === value.idTeam && value.configurationId === v.configurationId
        );
    }

    getCellIndex(column: IProtoRequestHeader | 'a', row: IProtoRequestTeam | 'a'): string {
        const columnIndex = column === 'a' ? 'a' : column.idConfiguration;
        const rowIndex = row === 'a' ? 'a' : row.idTeam;
        return `${columnIndex}-${rowIndex}`;
    }

    updateComments(localStorage: IProtoRequestTableStorage<IRequestTableStorage>): void{
        if(localStorage.comment){
            localStorage.requests.forEach(req => {
                req.comment = localStorage.comment;
            });
        }
    }

    updateSpecialRequirements(localStorage: IProtoRequestTableStorage<IRequestTableStorage>): void {
        if(localStorage.overwriteAll && localStorage.specialRequirements){
            localStorage.requests.forEach(req => {
                req.specialRequirements = localStorage.specialRequirements;
            });
        }
    }

    onSaveCellValue(
        cell: IProtoRequestCell,
        row: IProtoRequestTeam,
        column: IProtoRequestHeader,
        localStorage: IProtoRequestTableStorage<IRequestTableStorage>
    ): void {
        const find = localStorage.requests.find(
            (req) => req.configurationId === cell.item['configurationId'] && req.idTeam === row.idTeam
        );

        if(localStorage.canChangeJustificationAndComment){
            this.updateComments(localStorage);
            this.updateSpecialRequirements(localStorage);
        }

        if (find) {
            find.quantity = cell.item['quantity'];
        } else {
            // get first quantity
            const item = row.requests?.find(
                (c) =>
                    c.configurationId === column.idConfiguration &&
                    c.revision === column.revision?.fvcValueId
            );

            const specialRequirements = !isEmpty(localStorage.specialRequirements) ? localStorage.specialRequirements : cell.item['specialRequirementList'];

            const request = <any>{
                configurationId: cell.item['configurationId'],
                configurationStatus: <any>cell.$column.configStatus,
                idTeam: row.idTeam,
                idTeamAssociation: row.idTeamAssociation,
                quantity: cell.item['quantity'],
                hwRevision: column.hwRevision,
                operation: cell.operation,
                justifications: cell.item['justifications'],
                specialRequirements: specialRequirements,
                comment: cell.item['additionalComments'] || (localStorage.comment || ''),
                usersToNotify: cell.item['usersToNotify']
            };
            if (item) {
                request['firstQuantity'] = item.firstQuantity;
            }

            localStorage.requests.push(request);
        }
    }

    toggleCellState(
        cell: IProtoRequestCell,
        editable: IProtoRequestCell,
        columnRecord: any,
        rowRecord: any
    ): void {
        columnRecord[cell.$column.idConfiguration] = true;
        rowRecord[cell.$row.idTeam] = true;
    }

    selectedColumn(column: IProtoRequestHeader, columnRecord: Record<number, boolean>): boolean {
        return columnRecord[column.idConfiguration];
    }

    selectedRow(row: IProtoRequestTeam, rowRecord: Record<number, boolean>): boolean {
        return rowRecord[row.idTeam];
    }

    updateSelected(
        columnRecord: Record<number, boolean>,
        rowRecord: Record<number, boolean>,
        selectionModel: any,
        headers: IProtoRequestHeader[],
        data: IProtoRequestTeam[],
        edited: Record<string, IProtoRequestCell>
    ): IProtoRequestCell[] {
        return selectionModel.selected.map((index: string) => {
            const [column, row] = index.split('-').map((i) => parseInt(i));
            columnRecord[column] = true;
            rowRecord[row] = true;
            const header = headers.find((head) => head.idConfiguration === column) as IProtoRequestHeader;
            const rowObject = data.find((team) => team.idTeam === row) as IProtoRequestTeam;
            return this.getColumnObject(rowObject, header, edited, data);
        });
    }

    getColumnObject(
        row: IProtoRequestTeam,
        column: IProtoRequestHeader,
        edited: Record<string, IProtoRequestCell>,
        data: IProtoRequestTeam[]
    ): IProtoRequestCell {

        const cellIndex = this.getCellIndex(column, row);

        const obj: IProtoRequestCell = <any>{
            item: {
                quantity: null,
                configurationId: column.idConfiguration
            },
            $row: row,
            $column: column,
            $state: EProtoRequestTableCellState.VIEW
        };

        if (cellIndex in edited) {
            obj.item = edited[cellIndex].item;
            obj.operation = edited[cellIndex].operation;
        } else if (data) {
            obj.item = <any>row.requests?.find(
                (c) =>
                    c.configurationId === column.idConfiguration &&
                    c.revision === column.revision?.fvcValueId
            );
            if (obj.item === undefined) {
                obj.item = {
                    quantity: null,
                    configurationId: column.idConfiguration
                };
            }
        }

        return obj;

    }

    getTemplate(
        editable: IProtoRequestCell,
        original: TemplateRef<any>,
        incoming?: { template: TemplateRef<any> },
        cell?: IProtoRequestCell,
        defaultEdition?: TemplateRef<any>
    ): TemplateRef<any> {
        if (
            defaultEdition &&
            editable &&
            editable.$column.idConfiguration === cell?.$column.idConfiguration &&
            editable.$row.idTeamAssociation === cell?.$row.idTeamAssociation
        ) {
            return defaultEdition;
        }
        return incoming ? incoming.template : original;
    }

    reduceGroups(
        {first, last}: { first: any; last: any },
        headers: IProtoRequestHeader[]
    ): { [key: number]: { [key: string]: any; colSpan: number } } {
        const groups = {} as any;
        var groupsCount = 0;
        for (let i = first; i <= last; i++) {
            const {revision} = headers[i] || {revision: undefined};
            if (revision) {
                if (groups[groupsCount]?.item.name === revision.name) {
                    groups[groupsCount].colSpan++;
                } else {
                    groupsCount += 1;
                    groups[groupsCount] = {
                        item: revision,
                        colSpan: 1
                    };
                    if (revision.name) {
                        groups[groupsCount].item.groupName = revision.name.length > 20 ? revision.name.substring(0, 20) + '...' : revision.name;
                    } else {
                        groups[groupsCount].item.groupName = '';
                    }
                }
            }
        }
        return groups;
    }

    isEditingCell(
        column: IProtoRequestHeader,
        row: IProtoRequestTeam,
        editable: IProtoRequestCell,
        cellObj: IProtoRequestCell
    ): boolean {

        if (cellObj == undefined) {
            return (
                editable &&
                row.idTeam === editable.$row.idTeam &&
                column.idConfiguration === editable.$column.idConfiguration
            );
        }
        return (
            editable &&
            cellObj.$row.idTeam === editable.$row.idTeam &&
            cellObj.$column.idConfiguration === editable.$column.idConfiguration
        );
    }

    isCanceled(item: IProtoRequestHeader): boolean {
        return item.configStatus?.toString().toLocaleUpperCase() === 'CANCELED';
    }

    private static deepCopy<T>(el: T): T {
        return JSON.parse(JSON.stringify(el));
    }
}
