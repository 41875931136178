<div class="kt-form">
    <div class="kt-portlet__foot kt-portlet__no-border kt-portlet__foot--fit text-right">
        <div class="kt-form__actions kt-form__actions--sm">
            <button id="cancel-button" [disabled]="canDisabledCancelButton()" *ngIf="canShowButton(cancelButton)"
                [ngClass]="{'galvin-btn-danger' : !canDisabledCancelButton()}" class="btn-warn"
                container="galvin-dialog-generic" ngbTooltip="{{cancelButton?.value}}" (click)="cancel()" mat-button
                mat-raised-button color="warn">
                {{cancelButton?.value}}
            </button>
            &nbsp;
            <button id="create-button" [disabled]="canDisabledCreateButton()"
                [ngClass]="{'galvin-btn-success' : !canDisabledCreateButton()}" [ngbTooltip]="getTooltipSaveOrEdit()"
                container="galvin-dialog-generic" *ngIf="canShowButton(saveOrEditButton)" (click)="saveOrEdit()"
                mat-button mat-raised-button color="accent">
                {{saveOrEditButton?.value}}
            </button>
            &nbsp;
            <button *ngIf="canShowButton(customButton)" (click)="customSave()" [disabled]="canDisabledCreateButton()"
                [ngClass]="{'galvin-btn-primary' : !canDisabledCreateButton()}" mat-button mat-raised-button
                container="galvin-dialog-generic" ngbTooltip="{{tooltips.custom}}">
                {{customButton?.value}}
            </button>
        </div>
    </div>
</div>