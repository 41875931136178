import { OnDestroy, OnInit } from '@angular/core';
// Angular
import { animate, style, transition, trigger } from '@angular/animations';
import { SelectionModel } from '@angular/cdk/collections';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    HostBinding,
    TemplateRef
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import {
    ENotificationStatus,
    INotification
} from '@galvin/core/notification/notification.interface';
import { NotificationService } from '@galvin/core/notification/notification.service';
import { LayoutUtilsService, ToastrType } from '@galvin/core/_base/crud';
import { TranslateService } from '@ngx-translate/core';
import { EMPTY, of, Subscription } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { LocalStorageHelper } from '@galvin/core/storage/local-storage-helper.service';
import { UserPermissionsService } from '@galvin/core/auth/services/user-permissions.service';
import { NotificationStorageHelper } from '@galvin/core/storage/notification-storage-helper.service';
import { NotificationComponentService } from './notification.component.service';

@Component({
    selector: 'kt-notification',
    templateUrl: './notification.component.html',
    styleUrls: ['notification.component.scss'],
    animations: [
        trigger('insertRemoveItem', [
            transition(':enter', [style({ opacity: 0 }), animate('200ms', style({ opacity: 1 }))]),
            transition(':leave', [
                animate(
                    '200ms',
                    style({
                        opacity: 0,
                        transform: 'translateX(-100%)'
                    })
                )
            ])
        ])
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationComponent implements OnInit, OnDestroy {
    // Show pulse on icon
    pulse!: boolean;
    pulseLight!: boolean;
    // Set icon class name
    icon = 'flaticon2-bell-alarm-symbol';
    iconType!: '' | 'success';
    // Set true to icon as SVG or false as icon class
    useSVG!: boolean;
    // Set bg image path
    bgImage!: string;
    // Set skin color, default to light
    skin: 'light' | 'dark' = 'light';
    type: 'brand' | 'success' = 'success';
    s8n = new Subscription();
    _notifications!: INotification[];
    readonly dismissing: SelectionModel<number>;
    readonly seen: SelectionModel<number>;
    readonly labels: any;
    opened!: INotification;
    loadingContent!: boolean;
    lengthDescription!: string | undefined;
    loading!: boolean;
    private readonly LIMIT_TO_SHOW = 9;
    private intervalNotification: any = null;
    // Time in milliseconds to check the next notification call. = 20 seconds
    private readonly CHECK_TO_NOTIFICATION =  (20) *1000;
    // Time interval for calling the notification endpoint: 60 seconds
    private readonly INTERVAL_TO_NOTIFICATION = (60) *1000;
    // After the component is opened, the delay for the notification call when without  data in the storage: 5 seconds
    private readonly DELAY_CALL_WITHOUT =  5000;
    // The delay for the notification call when with  data in the storage: 10 seconds
    private readonly DELAY_CALL_RELOAD =  10000;
    /**
     * Component constructor
     *
     * @param sanitizer: DomSanitizer
     * @param notificationService: NotificationService user to load all user notifications
     * @param i18n
     * @param layoutService
     * @param dialog
     * @param cdr: ChangeDetectorRef to dispatch the updated value and update view
     */
    constructor(
        readonly sanitizer: DomSanitizer,
        private readonly notificationService: NotificationService,
        private readonly i18n: TranslateService,
        private route: ActivatedRoute,
        private readonly layoutService: LayoutUtilsService,
        private readonly dialog: MatDialog,
        private readonly cdr: ChangeDetectorRef,
        private localStorageHelper: LocalStorageHelper,
        private notificationStorage:NotificationStorageHelper,
        private userPermissionsService: UserPermissionsService,
        private notificationComponentService:NotificationComponentService

    ) {
        this.dismissing = new SelectionModel(true);
        this.seen = new SelectionModel(true);
        this.labels = this.i18n.instant('COMMON');
    }

    ngOnInit(): void {
        this._notifications = this.notificationStorage.getNotificationCache();
        this.updateNotificationStatus();
        setTimeout(this.callLastNotification, this.DELAY_CALL_RELOAD);
        this.notificationComponentService.loadNotifications$.subscribe((run:boolean) => {
            if (run){
                setTimeout(this.callLastNotification, this.DELAY_CALL_WITHOUT);
            }
        });

        this.destroyInterval();
        this.intervalNotification =setInterval(this.callLastNotification, this.CHECK_TO_NOTIFICATION);
    }

    get notifications(){
        return this._notifications;
    }

    set notifications(notifications:INotification[]){
        this._notifications = notifications;
        this.notificationStorage.setNotificationCache(notifications);
        this.updateNotificationStatus();

    }


    get hasNotification(): boolean {
        return this.notifications && this.notifications.length > 0;
    }

    get newNotifications(): INotification[] {
        return this.notifications
            ? this.notifications.filter((n) => n.status === ENotificationStatus.NEW)
            : [];
    }

    @HostBinding('attr.notification-total')
    get notificationsLength(): number {
        if (!this.hasNotification) {
            return 0;
        }
        return this.notifications.length;
    }



    public loadNotification = () =>{
        this.loading = true;
        this.notificationStorage.setLastDateNotification(new Date().getTime().toString());
        let hasRolesOrAdmin =this.userPermissionsService.hasRoleOrAdmin();
        const localStorageUser = this.localStorageHelper.getLoggedUser();
        if (localStorageUser!=null  && hasRolesOrAdmin){
            this.s8n.add(
                this.notificationService.getLatest().subscribe((n) => {
                    this.handleNotifications(n);
                    this.loading = false;
                },
                () => {
                    this.loading = false;
                })
            );
        }

    };


    public callLastNotification = () => {
        const lastNotificationTime = this.notificationStorage.getLastDateNotification();
        const isStorage=this.notifications.length>0;
        if(this.loading){
            return;
        }
        const miliSecondsRenew = this.INTERVAL_TO_NOTIFICATION;
        const currentTime = new Date().getTime();
        if (lastNotificationTime && isStorage) {
            const dtLast =new Date(lastNotificationTime);
            let exp = dtLast.getTime();
            const timeRenew = exp + miliSecondsRenew;
            if (currentTime > timeRenew) {
                this.loadNotification();
            }
        }else{
            this.loadNotification();
        }
    };

    public destroyInterval(){
        if (this.intervalNotification) {
            clearInterval(this.intervalNotification);
        }
    }

    backGroundStyle(): string {
        if (!this.bgImage) {
            return 'none';
        }

        return 'url(' + this.bgImage + ')';
    }

    onDismissNotification(notification: INotification): void {
        if (!this.dismissing.isSelected(notification.id)) {
            this.dismissing.select(notification.id);
            this.notificationService
                .dismiss(notification)
                .pipe(
                    map(() => true),
                    catchError(() => {
                        this.layoutService.showToastrNotification(
                            this.labels.ERROR_REQUEST,
                            ToastrType.error
                        );
                        return of(false);
                    })
                )
                .subscribe((remove) => this.handleDismissedNotification(notification, remove));
        }
    }

    onOpen(notification: INotification, contentTmpl?: TemplateRef<HTMLElement>): void {
        if (!this.dismissing.isSelected(notification.id)) {
            this.loadingContent = true;
            this.opened = notification;
            if (contentTmpl) {
                this.dialog
                    .open<HTMLElement, void, void | 'dismiss'>(contentTmpl, {
                        panelClass: 'custom-notification-dialog',
                        maxHeight: '95vh'
                    })
                    .afterClosed()
                    .subscribe((action) => {
                        if (action === 'dismiss') {
                            this.onDismissNotification(notification);
                        }
                    });
            }
            this.s8n.add(
                this.notificationService
                    .getById(notification.id)
                    .pipe(catchError(() => EMPTY))
                    .subscribe((n) => {
                        this.loadingContent = false;
                        notification.content = n.content;
                        if (notification.status === ENotificationStatus.NEW) {
                            this.s8n.add(
                                this.notificationService.maskAsSeen(notification).subscribe(() => {
                                    this.seen.select(notification.id);
                                    this.cdr.markForCheck();
                                })
                            );
                        }
                    })
            );
        }
    }

    loadNextNotification(): void {
        const currentIndex = this.notifications.findIndex((n) => n.id === this.opened.id);
        const found: INotification = this.notifications[currentIndex + 1];
        if (found) {
            this.onOpen(found);
        }
    }

    loadPreviousNotification(): void {
        const currentIndex = this.notifications.findIndex((n) => n.id === this.opened.id);
        const found: INotification = this.notifications[currentIndex - 1];
        if (found) {
            this.onOpen(found);
        }
    }

    hasPrevious(): boolean {
        const currentIndex = this.notifications.findIndex((n) => n.id === this.opened.id);
        return currentIndex > 0;
    }

    hasNext(): boolean {
        const currentIndex = this.notifications.findIndex((n) => n.id === this.opened.id);
        return currentIndex < this.notifications.length - 1;
    }

    private handleDismissedNotification(notification: INotification, remove?: boolean | void) {
        if (remove !== false) {
            this.notifications = this.notifications.filter((n) => n.id !== notification.id);
        }
        this.dismissing.deselect(notification.id);
        this.updateNotificationStatus();
    }

    /**
     * Get notification requests result and setup the notification status.
     *
     * @param notifications {@link INotification}
     * @private
     */
    private handleNotifications(notifications: INotification[]) {
        this.notifications = notifications;
        this.updateNotificationStatus();
    }

    /**
     * Update the component visual status: notification length description and pulse effect.
     *
     * @private
     */
    private updateNotificationStatus() {
        this.lengthDescription = undefined;
        let seen: any[] = [];
        this.pulse = this.notifications && this.notifications.length > 0;
        if (this.notifications && this.notifications.length > 0) {
            seen = this.notifications
                .filter((n) => n.status === ENotificationStatus.SEEN)
                .map((n) => n.id);
            if (this.notifications.length > this.LIMIT_TO_SHOW) {
                this.lengthDescription = `${this.LIMIT_TO_SHOW}+`;
            } else {
                this.lengthDescription = `${this.notifications.length}`;
            }
        }
        this.seen.clear();
        this.seen.select(...seen);
        this.cdr.markForCheck();
    }

    ngOnDestroy(): void {
        this.destroyInterval();
        this.s8n.unsubscribe();
    }
}
