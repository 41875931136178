
<kt-portlet>
    <header-dialog-generic [title]="title" [loadSave]="saving" [app]="app" [closeByService]="false" (closeAction)="close($event)"></header-dialog-generic>
    <kt-portlet-body class="{{ app }}">
        <form [formGroup]=formUser id="form-new-user" *ngIf="!loading" novalidate >
            <div class="kt-form__group row p-2 mb-2">
                <mat-form-field id="mat-form-field-email" appearance="outline" class="col-12">
                    <mat-label class="text-primary">{{labels.DIALOG.EMAIL_FIELD}}</mat-label>
                    <input id="user-new-email" matInput formControlName="email" required maxlength="255">
                    <mat-error *ngIf="formUser.controls['email'].hasError('required')">
                        {{ 'GENERAL_FORM.VALIDATION.REQUIRED_FIELD' | translate }}
                    </mat-error>
                    <mat-error *ngIf="formUser.controls['email'].hasError('email')">
                        {{labels.DIALOG.VALIDATIONS.INVALID}}
                    </mat-error>
                    <mat-error *ngIf="formUser.controls['email'].hasError('alreadyExists')">
                        {{labels.DIALOG.VALIDATIONS.EMAIL_ALREADY_EXISTS}}
                    </mat-error>
                    <mat-hint *ngIf="hasDomainWarning(formUser.controls['email'])">
                        {{labels.DIALOG.VALIDATIONS.EXTERNAL_DOMAIN}}
                    </mat-hint>
                </mat-form-field>
            </div>
            <div class="kt-form__group row p-2 mb-2">
                <mat-form-field id="mat-form-field-core-id" appearance="outline" class="col-6"
                    [ngbTooltip]="labels.DIALOG.TOOLTIPS.CODE_ID"
                    [disableTooltip]="!hasDomainWarning(formUser.controls['email'])">
                    <mat-label class="text-primary">{{labels.DIALOG.COREID_FIELD}}</mat-label>
                    <input id="user-new-core-id" matInput formControlName="coreId" maxlength="255">
                    <mat-error *ngIf="formUser.controls['coreId'].hasError('coreId')">
                        {{labels.DIALOG.VALIDATIONS.INVALID_CORE_ID}}
                    </mat-error>
                </mat-form-field>
                <mat-form-field id="mat-form-field-country" appearance="outline" class="col-6 ng-select">
                    <mat-label class="text-primary">{{labels.DIALOG.COUNTRY_FIELD}}</mat-label>
                    <ng-select id="mat-select-country" ngSelectMat appendTo="body" formControlName="country"
                        [items]="countries" bindValue="value">
                        <ng-template let-item="item" ng-option-tmp ng-label-tmp>
                            <span [ngClass]="getCountryFlagClass(item.value)"></span>
                            <span class="text-primary ml-3">{{item.label}}</span>
                        </ng-template>
                    </ng-select>
                </mat-form-field>
                <mat-form-field id="mat-form-field-name" appearance="outline" class="col-6">
                    <mat-label class="text-primary">{{labels.DIALOG.NAME_FIELD}}</mat-label>
                    <input id="user-new-name" matInput formControlName="name" maxlength="255">
                    <mat-error *ngIf="formUser.controls['name'].hasError('name')">
                        {{labels.DIALOG.VALIDATIONS.INVALID_NAME}}
                    </mat-error>
                </mat-form-field>
                <mat-form-field id="mat-form-field-last-name" appearance="outline" class="col-6">
                    <mat-label class="text-primary">{{labels.DIALOG.LAST_NAME_FIELD}}</mat-label>
                    <input id="user-new-last-name" matInput formControlName="lastName" maxlength="255">
                    <mat-error *ngIf="formUser.controls['lastName'].hasError('name')">
                        {{labels.DIALOG.VALIDATIONS.INVALID_NAME}}
                    </mat-error>
                </mat-form-field>
            </div>
        </form>
    </kt-portlet-body>
    <kt-portlet-footer>
       <div class="kt-form">
            <div class="kt-portlet__foot kt-portlet__no-border kt-portlet__foot--fit text-right">
                <div  class="kt-form__actions kt-form__actions--sm">
                    <button  id="cancel-button"
                   class="btn-warn"
                        container="galvin-dialog-generic" (click)="cancel()" mat-button
                        mat-raised-button color="warn">
                        {{cancelButton?.value}}
                    </button>
                    &nbsp;
                   <button id="create-button"
                            (click)="save()"
                            container="galvin-dialog-generic"
                            [disabled]="!formUser?.valid"
                            mat-button mat-raised-button color="accent">
                        {{ hasFormErrors ? saveOrEditButton?.value : saveOrEditButton?.value }}
                    </button>

                </div>
            </div>
        </div>
    </kt-portlet-footer>
</kt-portlet>