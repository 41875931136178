export class ReportUtils {
    static EMPTY = '—';

    /**
    * Given a value, return it or '—' if empty
    * @param value value to format
    */
    public static getValue(value: number | undefined) {
        return value ? value : this.EMPTY;
    }
}