import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IConfigurationI18n } from '@galvin/core/build-plan/configuration-management/i18n/configuration-i18n.interface';
import { IPrototypeRequestI18n } from '@galvin/core/build-plan/prototype-request-management/i18n/prototype-request-i18n.interface';
import { EnumUtils } from '@galvin/core/utils/utils.enum';
import { UtilsFlag } from '@galvin/core/utils/utils.flag';
import { TranslateService } from '@ngx-translate/core';

import { EConfigurationStatusLabel } from '@galvin/core/build-plan/configuration-management/enums/configuration-status-label.enum';
import {
    EConfigurationStatusColor,
    EConfigurationStatusColorKeys
} from '@galvin/core/build-plan/management/enums/type-status-color';
import {
    EConfigurationStatusIcon,
    EConfigurationStatusIconKeys
} from '@galvin/core/build-plan/management/enums/type-status-icon.enum';
import { IPrototypeRequestConfiguration } from '@galvin/core/build-plan/prototype-request-management/interfaces/prototype-request-configuration.interface';
import { IPrototypeRequestWithConfigurationAndTeam } from '@galvin/core/build-plan/prototype-request-management/interfaces/prototype-request-with-configuration-and-team.interface';

@Component({
    selector: 'header-request-details',
    templateUrl: './header-request-details.component.html',
    styleUrls: ['./header-request-details.component.scss']
})
export class HeaderRequestDetailsComponent implements OnInit {
    @Input() protoRequest!: IPrototypeRequestWithConfigurationAndTeam;
    @Input() requestPreview!: boolean;
    @Output() closeDialog = new EventEmitter(true);

    labelsPrototype!: IPrototypeRequestI18n;
    labelsConfiguration!: IConfigurationI18n;

    constructor(
        private i18n: TranslateService,
        private enumUtils: EnumUtils,
        private utilsFlag: UtilsFlag
    ) {}

    ngOnInit(): void {
        this.labelsPrototype = this.i18n.instant('BUILD_PLAN.PROTOTYPE_REQUEST');
        this.labelsConfiguration = this.i18n.instant('BUILD_PLAN.CONFIGURATION');
    }

    get configuration(): IPrototypeRequestConfiguration {
        return this.protoRequest.configuration ? this.protoRequest.configuration : {};
    }

    /**
     * Return the class of the configuration status
     * @param status of the configuration
     */
    getStatusCssClass(status: string | undefined): string {
        if (status == undefined) {
            return '';
        }

        const key = this.enumUtils.getKeyEnumByValue(
            EConfigurationStatusLabel,
            status
        ) as EConfigurationStatusColorKeys;

        return EConfigurationStatusColor[key];
    }

    getStatusIcon(status: string | undefined): string {
        if (status == undefined) {
            return '';
        }

        const key = this.enumUtils.getKeyEnumByValue(
            EConfigurationStatusLabel,
            status
        ) as EConfigurationStatusIconKeys;

        return EConfigurationStatusIcon[key];
    }

    getFlag(country: string): string {
        return `mr-2 fi fis ${this.utilsFlag.getFlag(country).class}`;
    }
}
