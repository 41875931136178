<div *ngIf="!loadRequest" class="d-flex flex-column h-100">
  <div
    [ngClass]="{ 'clean-grid-template': requestPreview }"
    class="container-dialog flex-grow-1"
  >
    <accessory-header-request-details
      (closeDialog)="close()"
      [accessoryData]="
        requestPreview ? accessoryData : $any(accessoryRequest.accessory)
      "
      [buildPlan]="buildPlan"
      [requestPreview]="requestPreview"
      class="header"
    >
    </accessory-header-request-details>
    <div *ngIf="!requestPreview" class="side-bar">
      <form
        (ngSubmit)="checkValidForm() && submit()"
        [formGroup]="requestForm"
        class="h-100 d-flex flex-column"
      >
        <!-- right side request -->
        <section
          class="header-side-bar d-flex align-items-center justify-content-between pl-3 pr-3"
        >
          <div [style.width.%]="95" class="d-flex flex-column">
            <span class="text-default title-header">
              {{
                "BUILD_PLAN.PROTOTYPE_REQUEST.DIALOG.LABELS.REQUEST" | translate
              }}
            </span>
            <span
              [ngbTooltip]="accessoryRequest?.teamRequest?.teamAlias"
              container="galvin-dialog-generic"
              class="d-inline-block text-default text-truncate sub-title-header"
            >
              {{ accessoryRequest?.teamRequest?.teamAlias }}
            </span>
            <span
              [ngbTooltip]="accessoryRequest?.teamRequest?.teamValues"
              container="galvin-dialog-generic"
              class="d-inline-block text-truncate text-default sub-title-header"
            >
              <small>
                {{ accessoryRequest?.teamRequest?.teamValues }}
              </small>
            </span>
          </div>
          <button
            (click)="close()"
            class="close-side-bar btn btn-clean btn-sm btn-icon btn-icon-md dialog-close-button"
            container="galvin-dialog-generic"
            ngbTooltip="{{ 'COMMON.CLOSE_DIALOG' | translate }}"
            type="button"
          >
            <i class="la la-times"></i>
          </button>
        </section>
        <section
          class="form-group side-bar-content p-3 m-0"
          id="quantityContainer"
        >
          <label class="text-default title-section">
            {{
              "BUILD_PLAN.PROTOTYPE_REQUEST.DIALOG.LABELS.QUANTITY" | translate
            }}
          </label>
          <div class="d-flex flex-column align-items-end position-relative">
            <i class="material-icons position-absolute p-4 color-quantity"
              >edit</i
            >
            <input
              [ngStyle]="{ 'border-color': isValid('quantity') }"
              [style.height.px]="52"
              class="form-control text-default text-input color-main-blue"
              formControlName="quantity"
              min="0"
              required
              type="number"
            />
          </div>
          <div
            *ngIf="requestForm.get('quantity')?.invalid"
            class="invalid-fields"
          >
            {{
              "BUILD_PLAN.PROTOTYPE_REQUEST.DIALOG.LABELS.REQUIRED_FIELD"
                | translate
            }}
          </div>
        </section>
        <section class="form-group pl-3 pr-4 m-0" id="justification">
          <form [formGroup]="justificationForm">
            <label class="text-default title-section">
              {{
                "BUILD_PLAN.PROTOTYPE_REQUEST.DIALOG.LABELS.JUSTIFICATION.JUSTIFICATION"
                  | translate
              }}
            </label>
            <div class="d-flex flex-wrap">
              <label class="checkbox-container flex-grow-side-bar mb-0">
                <input formControlName="AUTOMATED_TESTING" type="checkbox" />
                <span class="checkmark"></span>
                <label class="text-default checkbox-label m-0 mr-1">
                  {{
                    "BUILD_PLAN.PROTOTYPE_REQUEST.DIALOG.LABELS.JUSTIFICATION.AUTOMATED_TESTING"
                      | translate
                  }}
                </label>
              </label>
              <label class="checkbox-container flex-grow-side-bar mb-0">
                <input formControlName="MANUAL_TESTING" type="checkbox" />
                <span class="checkmark"></span>
                <label class="text-default checkbox-label m-0 mr-1">
                  {{
                    "BUILD_PLAN.PROTOTYPE_REQUEST.DIALOG.LABELS.JUSTIFICATION.MANUAL_TESTING"
                      | translate
                  }}
                </label>
              </label>
              <label class="checkbox-container flex-grow-side-bar mb-0">
                <input formControlName="CARRIERS" type="checkbox" />
                <span class="checkmark"></span>
                <label class="text-default checkbox-label m-0 mr-1">
                  {{
                    "BUILD_PLAN.PROTOTYPE_REQUEST.DIALOG.LABELS.JUSTIFICATION.CARRIERS"
                      | translate
                  }}
                </label>
              </label>
              <label class="checkbox-container flex-grow-side-bar mb-0">
                <input formControlName="SHOW_OFF" type="checkbox" />
                <span class="checkmark"></span>
                <label class="text-default checkbox-label m-0 mr-1">
                  {{
                    "BUILD_PLAN.PROTOTYPE_REQUEST.DIALOG.LABELS.JUSTIFICATION.SHOW_OFF"
                      | translate
                  }}
                </label>
              </label>
              <label class="checkbox-container flex-grow-side-bar mb-0">
                <input formControlName="MARKETING" type="checkbox" />
                <span class="checkmark"></span>
                <label class="text-default checkbox-label m-0 mr-1">
                  {{
                    "BUILD_PLAN.PROTOTYPE_REQUEST.DIALOG.LABELS.JUSTIFICATION.MARKETING"
                      | translate
                  }}
                </label>
              </label>
              <label class="checkbox-container flex-grow-side-bar mb-0">
                <input formControlName="OTHER" type="checkbox" />
                <span class="checkmark"></span>
                <label class="text-default checkbox-label m-0 mr-1">
                  {{
                    "BUILD_PLAN.PROTOTYPE_REQUEST.DIALOG.LABELS.JUSTIFICATION.OTHER"
                      | translate
                  }}
                </label>
              </label>
            </div>
          </form>
        </section>
        <section
          class="form-group side-bar-content p-3 m-0"
          id="additionalCommentsContainer"
        >
          <label class="text-default title-section">{{
            "BUILD_PLAN.PROTOTYPE_REQUEST.DIALOG.LABELS.JUSTIFICATION.ADDITIONAL_COMMENTS"
              | translate
          }}</label>
          <mention-comment
            [defaultText]="comment"
            [editable]="!disableForm"
            [usersToNotify]="usersToNotify"
            [nameCreateBy]="loggedUser"
            (applyActions)="applyComments($event)"
            [container]="'additionalCommentsContainer'"
          >
            <textarea
              class="form-control fake-disable"
              rows="3"
              placeholder="Write your comments here"
              [value]="comment"
              readonly
              maxlength="1000"
            ></textarea>
          </mention-comment>
        </section>

        <!-- Request button -->
        <div
          class="footer-side-bar d-flex align-items-end w-100 pl-3 pr-3 flex-grow-1"
          *ngIf="!disableForm"
        >
          <div
            [ngbTooltip]="getTooltipRequestButton()"
            class="side-bar-button-container"
            container="galvin-dialog-generic"
          >
            <button
              [disabled]="isNotChangeForm() || submitted"
              class="w-100 side-bar-button mb-3 d-flex align-items-center justify-content-center"
              [class.galvin-btn-primary]="isRequestApproval()"
              mat-flat-button
            >
              <span class="d-flex align-items-center justify-content-center">
                {{ requestButtonLabel }}
              </span>
            </button>
          </div>
        </div>
      </form>
    </div>

    <!-- Details for no request dialog -->
    <ng-container [ngTemplateOutlet]="details"> </ng-container>
  </div>
</div>
<skeleton-load-dialog
  *ngIf="loadRequest"
  [requestPreview]="requestPreview"
></skeleton-load-dialog>

<ng-template #details>
  <div
    [ngClass]="{ 'clean-size-grid-template-areas': requestPreview }"
    class="content mt-4 ml-5 left-request-side"
  >
    <galvin-progress-spinner
      *ngIf="submitted"
      class="galvin-load"
      [diameter]="100"
    >
    </galvin-progress-spinner>

    <div class="overflow-auto">
      <div class="d-flex flex-column">
        <div class="row w-100 justify-content-start">
          <accessory-parameters-details
            *ngIf="
              accessoryRequest?.accessory?.sections?.accessoryValueList ||
              accessoryRequest?.sections?.accessoryValueList
            "
            [accessories]="getAccessories()"
            [ngClass]="requestPreview ? 'three-columns' : 'two-columns'"
            class="col-12"
          >
          </accessory-parameters-details>
        </div>
      </div>
    </div>
    <div
      *ngIf="!requestPreview"
      class="d-flex flex-column overflow-auto position-relative mr-4 ml-4 activity-container"
      id="activityContainer"
    >
      <span class="text-default title-container" id="activitySpan">
        {{ "BUILD_PLAN.PROTOTYPE_REQUEST.DIALOG.LABELS.ACTIVITY" | translate }}
        <i
          class="galvin-icon-information"
          container="galvin-dialog-generic"
          ngbTooltip="{{
            'BUILD_PLAN.PROTOTYPE_REQUEST.DIALOG.LABELS.ACTIVITY_INFO_TOOLTIP'
              | translate
          }}"
        >
        </i>
      </span>

      <request-activity
        *ngIf="hasActivityInformation()"
        [activity]="requestActivity"
      ></request-activity>
      <div *ngIf="!hasActivityInformation()" class="activity-message">
        <span *ngIf="loadingRequestActivity" id="loadingRequestActivity">
          <galvin-progress-spinner></galvin-progress-spinner>
          {{
            "BUILD_PLAN.PROTOTYPE_REQUEST.DIALOG.LABELS.LOADING_ACTIVITY"
              | translate
          }}
        </span>
        <span
          *ngIf="requestActivity && !requestActivity.length"
          id="noRequestActivityData"
        >
          {{
            "BUILD_PLAN.PROTOTYPE_REQUEST.DIALOG.LABELS.NO_ACTIVITY_DATA"
              | translate
          }}
        </span>
        <span *ngIf="errorLoadingActivity" id="requestActivityError">
          {{
            "BUILD_PLAN.PROTOTYPE_REQUEST.DIALOG.LABELS.ERROR_ACTIVITY"
              | translate
          }}
        </span>
      </div>
    </div>
  </div>
</ng-template>
