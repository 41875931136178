import { EConfigurationStatusLabel } from '@galvin/core/build-plan/configuration-management/enums/configuration-status-label.enum';
import { ILocation } from '@galvin/core/build-plan/field-value-management';
import { IProtoRequestGroup } from '@galvin/views/partials/layout/proto-request-table/interfaces/proto-request-group.interface';

export interface IProtoRequestHeader {
    idConfiguration: number;
    name: string;
    configurationOrder?: number;
    recipeConfig: string;
    revision?: IProtoRequestGroup;
    totalRequests: number;
    filtered?: boolean;
    isHidden?: boolean;
    isTemporary?: boolean;
    temporaryDisableDate?: string;
    isOpened?: boolean;
    configStatus?: EConfigurationStatusLabel;
    configFvc?: string;
    hwRevision?: string;
    frontColor?: string;
    rearColor?: string;
    ram?: string;
    storage?: string;
    buildRecipe?: string;
    secureMarking?: string;
    fvcSecure?: boolean;
    sourcing?: string;
    marketRegion?: string;
    productSku?: string;
    cmfColor?: string;
    simCard?: string;
    unitType?: string;
    brNumber?: number;
    xcvrKit?: string;
    pcbaKit?: string;
    buildDate?: Date;
    bufferPercent?: number;
    requestType?: string;
    isArchived?: boolean;
    buildSite?:ILocation;
}
