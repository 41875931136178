import {Directive, ElementRef} from '@angular/core';

@Directive({
    selector: '[groupRow]',
    exportAs: 'GroupRow'
})
export class GroupRowDirective {

    constructor(private el: ElementRef) {
    }

}
