<div [ngClass]="{ 'clean-grid-template-areas': requestPreview }" class="grid-header">
    <section class="d-flex justify-content-center align-items-center" id="sectionIconHeader">
        <div class="build-plan-selector-side galvin-icon-phone-large pt-4 pr-3 pb-4 pl-4">
        </div>
        <div [style.min-width.px]="200" class="d-flex flex-column text-default pt-3">
            <label [ngbTooltip]="protoRequest.buildPlanInternalProductName"
                class="d-inline-block text-truncate width-150 m-0 title-header m-0" container="galvin-dialog-generic"
                id="product-name">
                {{protoRequest.buildPlanInternalProductName}}
            </label>
            <label class="d-inline-block text-truncate width-150 m-0 text-default sub-title-bold-header"
                id="configuration-name">
                <b [ngbTooltip]="configuration.nameConfiguration" container="galvin-dialog-generic">
                    {{configuration.nameConfiguration}}
                </b>
            </label>
            <label class="d-inline-block text-truncate width-150 m-0 text-default" id="configuration-id">
                <b [ngbTooltip]="$any(configuration.idConfiguration)" container="galvin-dialog-generic">
                    #{{configuration.idConfiguration}}
                </b>
            </label>
            <label class="d-inline-block text-truncate width-150 m-0 text-default sub-title-normal-header"
                id="recipe-config">
                {{configuration.recipeConfig | checkNull }}
            </label>

        </div>
    </section>

    <section class="d-flex flex-row justify-content-between align-items-center">

        <label [ngbTooltip]="labelsPrototype.DIALOG.LABELS.SPECIAL_REQUIREMENT" id="special-requirement">
            <span [hidden]="protoRequest.teamRequest && !protoRequest.teamRequest.isSpecialRequirement"
                class="special-requirement-icon">
                <mat-icon>flash_on</mat-icon>
            </span>
        </label>

        <div class="d-flex flex-column p-2">
            <label class="text-top color-main-blue m-0" id="configuration-status-header">
                {{labelsConfiguration.COMMON.CONFIGURATION_STATUS | translate }}
            </label>
            <div [ngClass]="getStatusCssClass(configuration.configStatus)"
                class="d-flex flex-row align-items-center text-down m-0" id="configuration-status">
                <span class="material-icons"> {{getStatusIcon(configuration.configStatus)}} </span>
                <span class="configuration-status-name">{{ configuration.configStatus }}</span>
            </div>
        </div>
        <div class="d-flex flex-column p-2">
            <label class="text-top color-main-blue m-0" id="hw-revision-header">
                {{labelsPrototype.DIALOG.LABELS.HW_REVISION | translate}}
            </label>
            <label [attr.data-source]="configuration.hwRevisionSource" [style.min-width.%]="36" [style.width.px]="75"
                class="text-down justify-content-center align-items-center tag-prototypes m-0" id="hw-revision">
                <span class="hw-revision-name"> {{configuration.hwRevision | checkNull}}</span>
            </label>
        </div>
        <div class="d-flex flex-column p-2">
            <label class="text-top color-main-blue m-0" id="due-date-header">
                {{labelsPrototype.DIALOG.LABELS.DUE_DATE | translate}}
            </label>
            <label [ngbTooltip]="configuration.dueDate | parseChineseDate" class="text-down m-0"
                container="galvin-dialog-generic" id="due-date">
                {{ configuration.dueDate | parseDate}}
            </label>
        </div>
        <div class="d-flex flex-column p-2">
            <label class="text-top color-main-blue m-0" id="build-site-header">
                {{labelsPrototype.DIALOG.LABELS.BUILD_SITE | translate}}
            </label>
            <div class="d-flex flex-row">
                <span [ngClass]="getFlag($any(configuration).location.country)"></span>
                <label class="text-down m-0" id="build-site">
                    {{ configuration.location?.city }}
                </label>
            </div>
        </div>
        <div class="d-flex flex-column p-2">
            <label class="text-top color-main-blue m-0" id="product-sku-header">
                {{labelsPrototype.DIALOG.LABELS.PRODUCT_SKU | translate}}
            </label>
            <label [attr.data-source]="configuration.productSKUSource" [style.min-width.%]="36" [style.width.px]="75"
                class="text-down justify-content-center align-items-center tag-prototypes m-0" id="product-sku">
                {{configuration.productSKU | checkNull}}
            </label>
        </div>
        <div class="d-flex flex-column p-2">
            <label class="text-top color-main-blue m-0" id="market-region-header">
                {{labelsPrototype.DIALOG.LABELS.MARKET_REGION| translate}}
            </label>
            <div [attr.data-source]="configuration.marketRegionSource" [style.min-width.%]="36" [style.width.px]="75"
                class="d-flex justify-content-center align-items-center tag-prototypes m-0 p-2 " id="market-region">
                <span [ngbTooltip]="configuration.marketRegion" class="d-inline-block text-truncate text-down"
                    container="galvin-dialog-generic" placement="left">{{configuration.marketRegion | checkNull}}</span>
            </div>
        </div>
        <div *ngIf="requestPreview" class="d-flex align-items-center mr-2">
            <button (click)="closeDialog.emit(true)" [ngbTooltip]="'COMMON.CLOSE_DIALOG' | translate"
                class="btn btn-clean btn-sm btn-icon btn-icon-md dialog-close-button" container="galvin-dialog-generic"
                type="button">
                <i class="la la-times"></i>
            </button>
        </div>
    </section>
</div>