import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnInit,
    Output,
    TemplateRef,
    ViewChild
} from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor,
    FormBuilder,
    FormControl,
    FormGroup
} from '@angular/forms';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { ActivatedRoute, Router } from '@angular/router';
import { PrototypeRequestService } from '@galvin/core/build-plan/prototype-request-management';
import {
    ETeamProtoRequestJustification,
    ETeamProtoRequestJustificationKeys
} from '@galvin/core/build-plan/prototype-request-management/enums/team-proto-request-justification-enum';
import { IProtoRequestTableStorage } from '@galvin/core/build-plan/prototype-request-management/interfaces/proto-request-table-storage.interface';
import { IPrototypeRequestTeamSpecialRequirements } from '@galvin/core/build-plan/prototype-request-management/interfaces/prototype-request-team.interface';
import { REGEX_MENTIONS_EMAIL } from '@galvin/core/comments/mention-comment/mention-comment.component';
import { ICommentWithMentions } from '@galvin/core/comments/models/icomment-with-mentions.model';
import { LocalStorageHelper } from '@galvin/core/storage/local-storage-helper.service';
import { IProtoRequestCell } from '@galvin/views/partials/layout/proto-request-table';
import { SpecialRequirementFormComponent } from '@galvin/views/partials/layout/request-details-dialog/special-requirement-form/special-requirement-form.component';
import { TranslateService } from '@ngx-translate/core';

export interface IJustificationOption {
    description: string;
    key?: string;
    value?: string;
}

export interface IPrototypeRequestFormValue {
    quantity: number;
    justification: IJustificationOption[];
    comment: string;
}

interface ITooltipAndIcon {
    tooltip: string;
    icon: string;
}

export type FnPrototypeRequestFormChange = ({ value: IPrototypeRequestFormValue }: any) => void;
export type FnPrototypeRequestFormTouched = (_: unknown) => void;

@Component({
    selector: 'prototype-request-form',
    templateUrl: './prototype-request-form.component.html',
    styleUrls: ['./prototype-request-form.component.scss']
})
export class PrototypeRequestFormComponent implements OnInit, ControlValueAccessor {
    readonly justificationOptions: IJustificationOption[];
    requestForm!: FormGroup;
    requestFormChangeFn!: FnPrototypeRequestFormChange;
    requestFormTouchedFn!: FnPrototypeRequestFormTouched;
    @ViewChild(SpecialRequirementFormComponent)
        specialRequirementsComponent!: SpecialRequirementFormComponent;
    @Output()
        setQuantity = new EventEmitter<number>();
    @Output()
        selectionChange = new EventEmitter<IProtoRequestCell[]>();
    @Output() saveStorage = new EventEmitter<IProtoRequestTableStorage<any>>();
    @Output() undoAll = new EventEmitter();
    @Output() undoSomeCells = new EventEmitter();
    @Output() undoOnlyEdition = new EventEmitter();
    @Output() remove = new EventEmitter();
    @Output() forceUpdateData = new EventEmitter();
    @Input()
        collectionSize!: number;
    @Input()
        hideSpecialRequiments = false;
    @Input()
        hasChanges = false;
    @Input()
        requests: IProtoRequestCell[] = [];
    objStorage: IProtoRequestTableStorage<any> = {
        idBuildPlan: 0,
        requests: [],
        comment: '',
        justification: {},
        overwriteAll: false,
        specialRequirements: [],
        canChangeJustificationAndComment: true,
        hasMixedUp: false
    };
    quantity!: FormControl;
    specialRequirements: IPrototypeRequestTeamSpecialRequirements[] = [];
    justificationsChecked!: string[];
    usersToNotify: string[] = [];
    loggedUser: string;
    loggedUserEmail?: string;
    private bottomSheetRef!: MatBottomSheetRef<any, any>;

    constructor(
        private fb: FormBuilder,
        private i18n: TranslateService,
        private router: Router,
        private route: ActivatedRoute,
        readonly bottomSheet: MatBottomSheet,
        private ref: ChangeDetectorRef,
        private requestService: PrototypeRequestService,
        private elementRef: ElementRef,
        private localStorageHelper: LocalStorageHelper
    ) {
        this.loggedUser = `${this.localStorageHelper?.getLoggedUser()?.name} ${this.localStorageHelper?.getLoggedUser()?.lastName || ''}`;
        this.loggedUserEmail = this.localStorageHelper?.getLoggedUser()?.email;
        this.justificationOptions = Object.entries(ETeamProtoRequestJustification).map((v) => ({
            description: v[1],
            key: v[0]
        }));
    }

    get i18nMassEditRequestParam(): { collectionSize: number } {
        return { collectionSize: this.collectionSize };
    }

    private _loadStorage!: IProtoRequestTableStorage<any>;

    get loadStorage(): IProtoRequestTableStorage<any> {
        return this._loadStorage;
    }

    @Input()
    set loadStorage(value: IProtoRequestTableStorage<any>) {
        this._loadStorage = value;
        if (value) {
            this.specialRequirements =
                value.specialRequirements as IPrototypeRequestTeamSpecialRequirements[];
            this.justificationsChecked = value.justification ? Object.keys(value.justification).filter(j => (
                value.justification[j]
            )).map(j => this.i18n.instant(`BUILD_PLAN.PROTOTYPE_REQUEST.DIALOG.LABELS.JUSTIFICATION.${j}`)) : [];
            this.usersToNotify = value.usersToNotify || [];
        }
        if (this.requestForm) {
            this.buildForm();
        }
    }

    private _disabled!: boolean;

    get disabled(): boolean {
        return this._disabled;
    }

    @Input()
    set disabled(value: boolean) {
        this._disabled = value;
        this.syncFormStatus();
    }

    private _canOverwriteJustifications = false;

    get canOverwriteJustifications(): boolean {
        return this._canOverwriteJustifications;
    }

    @Input()
    set canOverwriteJustifications(value: boolean) {
        if (value != undefined) {
            this._canOverwriteJustifications = value;
            this.syncCommentStats(value);
            this.syncJustificationStats(value);
        }
    }

    get justifications(): string[] {
        return this.justificationOptions.map((j) => j.description);
    }

    get isDoneEnabled(): boolean {
        return this.quantity && (this.quantityField.touched || this.quantityField.dirty);
    }

    get justificationFormGroup(): AbstractControl {
        return this.requestForm.controls['justification'];
    }

    get commentControl(): AbstractControl {
        return this.requestForm.controls['comment'];
    }

    get overwriteControl(): AbstractControl {
        return this.requestForm.controls['overwriteAll'];
    }

    getRequestsQuantities(): Array<number> {
        return this.requests.map((r) => r.item['quantity'] || 0);
    }

    private get quantityField(): AbstractControl {
        return this.quantity;
    }

    get isEnabledMention(){
        return this.requestForm.controls['comment'].enabled;
    }

    get commentMention(){
        return this.requestForm.controls['comment'].value;
    }

    set commentMention(value:string){
        this.requestForm.controls['comment'].setValue(value);
    }

    ngOnInit(): void {
        this.buildForm();
    }

    registerOnChange(fn: FnPrototypeRequestFormChange): void {
        this.requestFormChangeFn = fn;
    }

    registerOnTouched(fn: FnPrototypeRequestFormTouched): void {
        this.requestFormTouchedFn = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        isDisabled ? this.requestForm.disable() : this.requestForm.enable();
    }

    writeValue(value: Partial<IPrototypeRequestFormValue>): void {
        this.requestForm.patchValue(value);
    }

    @HostListener('click', ['$event'])
    onClick($event: MouseEvent): void {
        $event.stopImmediatePropagation();
    }

    hasSpecialRequirements(): boolean {
        return this.specialRequirements?.length > 0;
    }

    hasJustification(): boolean {
        return this.justificationsChecked?.length > 0;
    }



    onEdit(): void {
        if (!this.requests || (this.requests && this.requests.length <= 0)) {
            return;
        }
        const objSave=this.newRequestObj();
        this.requests.forEach((cell: IProtoRequestCell) => {
            if (cell.item['quantity'] != null) {
                if (!cell.operation) {
                    cell.operation = 'edit';
                }
                this.buildObjRequestToSave(objSave, cell);
                this.addValueToSaveStorage(objSave);
            }
        });
        this.objStorage.requests = objSave.requests;
        this.selectionChange.next(
            this.requests.filter((cell: IProtoRequestCell) => cell.item['quantity'] != null)
        );

        this.requests = [];
    }

    addValueToSaveStorage(newRequestObj: IProtoRequestTableStorage<any>): void {
        newRequestObj.requests.forEach((v) => {
            v.usersToNotify = this.usersToNotify;
            v.comment = newRequestObj.comment;
            const find = this.objStorage.requests.find(
                (req) => {
                    if(req.configurationId){
                        return req.configurationId === v.configurationId && req.idTeam === v.idTeam;
                    }
                    return req.accessoryId === v.accessoryId && req.idTeam === v.idTeam;
                }
            );
            if (find) {
                find.quantity = v.quantity;
            } else {
                this.objStorage.requests.push(v);
            }
        });
        this.objStorage.usersToNotify = this.usersToNotify;
        this.objStorage.comment = newRequestObj.comment;
        this.objStorage.justification = { ...newRequestObj.justification };
        this.objStorage.overwriteAll = newRequestObj.overwriteAll;
    }

    addFormToStorage(): void {
        if (this.loadStorage) {
            const hasChangesFromReview = !this.loadStorage.canChangeJustificationAndComment;
            this.objStorage.requests = this.objStorage.requests.map(req => ({
                ...req,
                specialRequirements: this.specialRequirements,
                usersToNotify: this.usersToNotify
            }));
            this.objStorage.requests.push(...this.loadStorage.requests.map(req => ({
                ...req,
                comment: !hasChangesFromReview ?
                    (this.requestForm.value.comment  !== undefined ? this.requestForm.value.comment
                        : (req.comment || undefined))
                    : (req.comment || undefined),
                specialRequirements: !hasChangesFromReview ?
                    this.specialRequirements !== undefined ? this.specialRequirements
                        : (req.specialRequirements || [])
                    : (req.specialRequirements || []),
                usersToNotify: !hasChangesFromReview ?
                    this.usersToNotify !== undefined ? this.usersToNotify
                        : (req.usersToNotify || [])
                    : (req.usersToNotify || []),
            })));
        }
        this.objStorage.usersToNotify = this.usersToNotify;
        this.objStorage.comment = this.requestForm.value.comment;
        this.objStorage.justification = this.requestForm.value.justification;
        this.objStorage.overwriteAll = this.requestForm.value.overwriteAll;
        this.objStorage.specialRequirements = this.specialRequirements;

        this.saveStorage.next(this.objStorage);
    }

    updateJustifications(): void {
        if(this.loadStorage){
            this.objStorage.requests.forEach(req => {
                req.justification = this.requestForm.value.justification;
            });
        }
        this.addFormToStorage();
    }

    saveToStorageBeforeUndoAll(): void {
        this.objStorage.requests = [];
        this.loadStorage.requests = [];

        this.objStorage.comment = this.requestForm.value.comment;
        this.objStorage.justification = this.requestForm.value.justification;
        this.objStorage.overwriteAll = this.requestForm.value.overwriteAll;
        this.objStorage.specialRequirements = this.specialRequirements;
        this.objStorage.usersToNotify = this.usersToNotify;
        this.saveStorage.next(this.objStorage);
    }

    buildObjRequestToSave(
        objSave: IProtoRequestTableStorage<any>,
        cell: IProtoRequestCell
    ): IProtoRequestTableStorage<any> {
        return this.requestService.buildObjRequestToSave(
            objSave,
            cell,
            this.requestForm,
            this.specialRequirements
        );
    }

    updateObjStorageWithSpecialRequirements(): void {
        this.objStorage.requests.forEach((req, index) => {
            this.objStorage.requests[index] = {
                ...req,
                specialRequirements: this.loadStorage.specialRequirements,
                comment: this.loadStorage.comment,
            };
        });
    }

    newRequestObj(): IProtoRequestTableStorage<any>{
        const objSave: IProtoRequestTableStorage<any> = {
            idBuildPlan: 0,
            requests: [],
            comment: '',
            justification: {},
            overwriteAll: false,
            specialRequirements: [],
            canChangeJustificationAndComment: true,
            hasMixedUp: false,
            usersToNotify: []
        };
        return objSave;
    }

    onReduce(): void {
        const objSave = this.newRequestObj();
        this.requests.forEach((cell) => {
            let result = cell.item['quantity'] - 1;
            if (result < 0) {
                result = 0;
            }
            cell.operation = 'decrease';
            cell.item['quantity'] = result;
            this.buildObjRequestToSave(objSave, cell);
            this.addValueToSaveStorage(objSave);
        });
        this.updateObjStorageWithSpecialRequirements();
        this.saveStorage.next(this.objStorage);
        this.selectionChange.next(this.requests);
    }

    onIncrease(): void {
        const objSave = this.newRequestObj();
        this.requests.forEach((cell: IProtoRequestCell) => {
            if (!cell.item) {
                cell.item = { quantity: 0 };
            }
            cell.operation = 'increase';
            cell.item['quantity'] += 1;
            this.buildObjRequestToSave(objSave, cell);
            this.addValueToSaveStorage(objSave);
        });
        this.objStorage.requests = objSave.requests;
        this.saveStorage.next(this.objStorage);
        this.selectionChange.next(this.requests);
    }

    onDone(): void {
        const objSave = this.newRequestObj();
        this.requests.forEach((cell) => {
            cell.item['quantity'] = this.quantity.value;
            cell.operation = 'set_quantity';
            cell.item['comment'] = this.requestForm.value.comment;
            cell.item['usersToNotify'] = this.usersToNotify;
            cell.item['specialRequirements'] = this.loadStorage.specialRequirements;
            this.buildObjRequestToSave(objSave, cell);
            this.addValueToSaveStorage(objSave);
        });

        this.updateObjStorageWithSpecialRequirements();

        this.saveStorage.next(this.objStorage);
        this.selectionChange.next(this.requests);
        this.resetQuantityValue();
        this.forceUpdateData.emit();
    }

    undoAllChanges(): void {
        this.undoAll.next();
    }

    cellsSelected(): boolean {
        return this.requests?.some((req) => {
            return this.loadStorage?.requests.some((reqStorage) => {
                return ((!!req.item['idTeamProtoRequest'] && !!reqStorage['idTeamProtoRequest']) &&
                        (req.item['idTeamProtoRequest'] === reqStorage['idTeamProtoRequest'])) ||
                        ((!!req.item['accessoryId'] && !!reqStorage['accessoryId']) &&
                        (req.item['accessoryId'] === reqStorage['accessoryId'])
                        && (req.item['idTeamAssociation'] === reqStorage['idTeamAssociation']) );
            });
        });
    }

    hasOriginalFilledCellsSelected(): boolean {
        return this.requests.some((req) => {
            return req.item?.['firstQuantity'] > 0;
        });
    }

    getOverwriteTooltipAndIcon(): ITooltipAndIcon {
        const translationPrefix = 'BUILD_PLAN.PROTOTYPE_REQUEST.PROTO_REQUEST_TABLE';
        if(this.hasOriginalFilledCellsSelected() && this.isOverwriteAllEnabled()){
            if(this.cellsSelected()){
                return {
                    icon: 'indeterminate_check_box',
                    tooltip: `${translationPrefix}.REMOVE_REQUEST`
                };
            }
            return {
                icon: 'add_box',
                tooltip: `${translationPrefix}.ADD_REQUEST`
            };
        }
        return {
            icon: 'block',
            tooltip: `${translationPrefix}.BLOCKED_REQUEST`
        };
    }

    addRequests() {
        this.onEdit();

        this.addFormToStorage();

        // defocus cell
        document.querySelector('body')?.click();

        this.requests = [];
    }

    removeRequests() {
        this.undoSomeCells.emit(this.requests.filter(req => req.item?.['firstQuantity'] > 0).map(req => ({
            idTeamProtoRequest: req.item['idTeamProtoRequest'],
            accessoryId: req.item['accessoryId'],
            idTeamAssociation: req.item['idTeamAssociation']
        })));
        // defocus cell
        document.querySelector('body')?.click();

        this.requests = [];
    }

    removeEditedCells() {
        this.requests = [];
        this.undoOnlyEdition.next();
        this.saveToStorageBeforeUndoAll();
    }

    removeEditedCellsAfterCheckboxChanged(){
        this.requests = [];
        this.undoOnlyEdition.next();
        this.saveToStorageBeforeUndoAll();

    }

    isOverwriteAllEnabled(): boolean {
        return this.requestForm.controls['overwriteAll'].value;
    }

    onJustificationChange(item: boolean, tmpl: TemplateRef<any>): void {
        if (!item) {
            this.requestForm.controls['justification'].reset();
            this.specialRequirements = [];
            this.removeEditedCellsAfterCheckboxChanged();
        } else {
            if(this.requests.length > 0){
                this.addRequests();
            }
            this.openToEdit(tmpl);
            this.addFormToStorage();
        }
    }

    syncCommentStats(item: boolean): void {
        if (this.requestForm) {
            if (item) {
                this.requestForm.controls['comment'].enable();
            } else {
                this.requestForm.controls['comment'].disable();
            }
        }
    }

    goToRequestReview(): void {
        this.router.navigate(['../request-review'], { relativeTo: this.route });
    }

    openToEdit(bottomSheetContainer: TemplateRef<any>) {
        this.bottomSheetRef = this.bottomSheet.open(bottomSheetContainer);
        this.onEdit();
    }

    dismissSheet() {
        this.bottomSheet.dismiss();
    }

    onSpecialRequirementsChange(specials: IPrototypeRequestTeamSpecialRequirements[]) {
        this.specialRequirements = specials;
        this.addFormToStorage();
    }

    applyComment(iCommentWithMentions:ICommentWithMentions):void{
        this.commentMention= iCommentWithMentions.commentText;
        this.usersToNotify = iCommentWithMentions.usersToNotify;
        this.addFormToStorage();
    }

    getClassByAdditionalComment(): string{
        if(this.loggedUserEmail && this.commentMention?.includes(this.loggedUserEmail)){
            return 'border-comments yellow';
        }else if(this.commentMention?.match(REGEX_MENTIONS_EMAIL)){
            return 'border-comments blue';
        }else if(this.commentMention?.length){
            return 'border-comments black';
        }
        return '';
    }

    private resetQuantityValue() {
        this.quantityField.reset(null);
        this.quantityField.markAsUntouched();
    }


    private buildForm(): void {
        this.requestForm = this.fb.group({
            justification: this.fb.group({
                AUTOMATED_TESTING: this.fb.control(
                    (this.loadStorage?.justification &&
                        this.loadStorage.justification['AUTOMATED_TESTING']) ||
                        false
                ),
                MANUAL_TESTING: this.fb.control(
                    (this.loadStorage?.justification &&
                        this.loadStorage?.justification['MANUAL_TESTING']) ||
                        false
                ),
                CARRIERS: this.fb.control(
                    (this.loadStorage?.justification &&
                        this.loadStorage.justification['CARRIERS']) ||
                        false
                ),
                SHOW_OFF: this.fb.control(
                    (this.loadStorage?.justification &&
                        this.loadStorage.justification['SHOW_OFF']) ||
                        false
                ),
                MARKETING: this.fb.control(
                    (this.loadStorage?.justification &&
                        this.loadStorage.justification['MARKETING']) ||
                        false
                ),
                OTHER: this.fb.control(
                    (this.loadStorage?.justification && this.loadStorage.justification['OTHER']) ||
                        false
                )
            }),
            comment: this.fb.control(this.loadStorage?.comment || null, { updateOn: 'blur' }),
            overwriteAll: this.fb.control(this.loadStorage?.overwriteAll || false)
        });
        this.syncCommentStats(this.canOverwriteJustifications);
        this.syncJustificationStats(this.canOverwriteJustifications);
        this.quantity = new FormControl('');
        this.requestForm.controls['overwriteAll'].valueChanges.subscribe((v) => {
            this.syncJustificationsCheckboxes(v);
        });
        this.justificationFormGroup.valueChanges.subscribe((value) => {
            this.justificationsChecked = Object.entries(value)
                .filter(([, checked]) => checked)
                .map(
                    ([key]) =>
                        ETeamProtoRequestJustification[key as ETeamProtoRequestJustificationKeys]
                );
        });
        if (this.disabled !== null) {
            this.syncFormStatus();
        }
        this.ref.detectChanges();
    }

    private syncJustificationStats(isEnabled: boolean): void {
        if (this.requestForm) {
            if (!isEnabled) {
                this.requestForm.controls['overwriteAll'].disable();
            } else {
                this.requestForm.controls['overwriteAll'].enable();
            }
            this.syncJustificationsCheckboxes(isEnabled);
        }
    }

    private syncJustificationsCheckboxes(isEnabled: boolean): void {
        if (this.requestForm && this.requestForm.controls['justification']) {
            if (isEnabled && this.requestForm.controls['overwriteAll'].value) {
                this.requestForm.controls['justification'].get('AUTOMATED_TESTING')?.enable();
                this.requestForm.controls['justification'].get('MANUAL_TESTING')?.enable();
                this.requestForm.controls['justification'].get('CARRIERS')?.enable();
                this.requestForm.controls['justification'].get('SHOW_OFF')?.enable();
                this.requestForm.controls['justification'].get('MARKETING')?.enable();
                this.requestForm.controls['justification'].get('OTHER')?.enable();
            } else {
                if(!this.loadStorage){
                    this.requestForm.controls['comment'].setValue('');
                }
                this.requestForm.controls['justification'].get('AUTOMATED_TESTING')?.disable();
                this.requestForm.controls['justification'].get('MANUAL_TESTING')?.disable();
                this.requestForm.controls['justification'].get('CARRIERS')?.disable();
                this.requestForm.controls['justification'].get('SHOW_OFF')?.disable();
                this.requestForm.controls['justification'].get('MARKETING')?.disable();
                this.requestForm.controls['justification'].get('OTHER')?.disable();
            }
        }
    }

    private syncFormStatus() {
        if (this.quantity) {
            if (this._disabled) {
                this.quantity.disable();
            } else {
                this.quantity.enable();
            }
        }
    }
}
