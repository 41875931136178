import { Injectable } from '@angular/core';
import { LocalStorageHelper } from '@galvin/core/storage/local-storage-helper.service';
import { IOptionsModel } from '@galvin/views/partials/layout/configuration-filters/configuration-parameters-filters.component';

export interface IConfigurationSearch {
    parameter: string;
    values: string[];
    id: string;
}

@Injectable({
    providedIn: 'root'
})
export class ConfigurationFilterService {
    private configurationTerms: IConfigurationSearch[];
    private itemChanged = false;
    private FILTER_STORAGE_KEY = 'configurations-filters-key';
    private FILTER_TEXT_KEY = 'configurations-text-key';

    constructor(private localStorageHelper: LocalStorageHelper) {
        this.configurationTerms = [];
    }


    addConfigurationTerms(configurationTerms: IConfigurationSearch): void {
        this.configurationTerms.push(configurationTerms);
    }

    getConfigurationTerms(): IConfigurationSearch[] {
        return this.configurationTerms;
    }

    removeConfigurationFilterTerms(searchValue: IConfigurationSearch): void {
        this.configurationTerms = [
            ...this.configurationTerms.filter((term) => {
                if (
                    term.parameter === searchValue.parameter &&
                    term.values === searchValue.values
                ) {
                    return false;
                }
                return true;
            })
        ];
    }

    removeAllConfigurationFilter(): void {
        this.configurationTerms = [];
    }

    getItemChanged(): boolean {
        return this.itemChanged;
    }

    setItemChanged(item: boolean): void {
        this.itemChanged = item;
    }

    setFiltersOnStorage(filters: IOptionsModel): void {
        this.localStorageHelper.setItem(this.FILTER_STORAGE_KEY, JSON.stringify(filters));
    }

    setTextSearchOnStorage(textSearch: string): void {
        this.localStorageHelper.setItem(this.FILTER_TEXT_KEY, textSearch?textSearch:'');
    }

    getTextSearchOnStorage(): string {
        const val= this.localStorageHelper.getItem(this.FILTER_TEXT_KEY);
        return val?val:'';
    }

    getFiltersOnStorage(): IOptionsModel {
        return JSON.parse(this.localStorageHelper.getItem(this.FILTER_STORAGE_KEY));
    }

    configurationTermsContain(searchValue: IConfigurationSearch): boolean {
        return typeof this.configurationTerms.find((term) => term.parameter === searchValue.parameter) !== 'undefined';
    }
}
