import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'getHeaderSourcingColor'
})
export class GetHeaderSourcingColor implements PipeTransform {

    transform(sourcing: string): any {
        const total = this.getSourcingColorForHeaders(sourcing);
        return total;
    }

    /**
     * Return a css class for header
     * @param sourcing
     */
    getSourcingColorForHeaders(sourcing: string) {
        return sourcing === '1st' ? 'green' : 'yellow';
    }

}