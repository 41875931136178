import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { LayoutUtilsService, ToastrType } from '@galvin/core/_base/crud';
import { EConfigurationStatusLabel } from '@galvin/core/build-plan/configuration-management/enums/configuration-status-label.enum';
import { IFilterParameter } from '@galvin/core/build-plan/configuration-management/interfaces/configuration-filter-parameter.interface';
import { BuildPlanRequestPreviewService } from '@galvin/core/build-plan/management/services/proto-request.service';
import { HwRevisionUtils, PrototypeRequestService } from '@galvin/core/build-plan/prototype-request-management';
import { IPrototypeRequestI18n } from '@galvin/core/build-plan/prototype-request-management/i18n/prototype-request-i18n.interface';
import { IHwRevision } from '@galvin/core/build-plan/prototype-request-management/interfaces/hw-revision.interface';
import { IProtoRequestTableStorage } from '@galvin/core/build-plan/prototype-request-management/interfaces/proto-request-table-storage.interface';
import {
    IPrototypeFilters,
    ITeamsParametersSelected,
    ITeamsParametersSelector
} from '@galvin/core/build-plan/prototype-request-management/interfaces/prototype-filters.interface';
import { IRequestTableStorage } from '@galvin/core/build-plan/prototype-request-management/interfaces/request-table-storage.interface';
import { DateTimeUtils } from '@galvin/core/utils/moment-utils';
import { UtilsFlag } from '@galvin/core/utils/utils.flag';
import { IRequestPreviewI18n } from '@galvin/views/pages/build-plan/build-plan-management/request-preview/i18n/request-preview-i18n.interface';
import {
    IProtoRequestHeader,
    IProtoRequestTeam,
    ProtoRequestTableComponent
} from '@galvin/views/partials/layout/proto-request-table';
import { ToolbarService } from '@galvin/views/theme/services/toolbar.service';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep, isEmpty } from 'lodash';
import { Subscription, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { IProtoRequest } from '../../../../partials/layout/proto-request-table/interfaces/proto-request.interface';
import { IDataRequestDetailsDialog } from '../../../../partials/layout/request-details-dialog/data-request-details-dialog.interface';
import { RequestDetailsDialogComponent } from '../../../../partials/layout/request-details-dialog/request-details-dialog.component';
import { IFieldValue } from './../../../../../core/build-plan/field-value-management/accessories-parameters/interfaces/accessory-parameter.interface';
import { EDataType, TDataType } from './../../../../../core/build-plan/field-value-management/enums/data-type.enum';
import { IOptionsModel } from './../../../../partials/layout/configuration-filters/configuration-parameters-filters.component';
interface ISearchBar {
    searchInput: string;
    filter?: unknown;
}
@Component({
    selector: 'request-preview',
    templateUrl: './request-preview.component.html',
    styleUrls: ['./request-preview.component.scss']
})
export class RequestPreviewComponent implements OnInit, OnDestroy {
    amountRequestsForm!: FormGroup;
    s8n = new Subscription();
    headers!: IProtoRequestHeader[];
    labels: IRequestPreviewI18n;
    labelsRequest: IPrototypeRequestI18n;
    labelsRequestFilters: IPrototypeRequestI18n;
    data!: IProtoRequestTeam[];
    isLoading = true;
    noDataFilters = false;
    noData = false;
    isSearching = false;
    inputSearch = '';
    idBuildPlan!: number;
    buildPlanName!: string;
    filterSelect: Record<string, boolean> = {};
    rowFiltered: Record<string, IProtoRequestTeam[]> = {};
    columnFiltered: Record<string, IProtoRequestHeader[]> = {};
    currentPopover!: NgbPopover;
    hwRevisions: string[] = [];
    hwRevisionSelected!: string;
    hwRevisionsSelected: string[] = [];
    isHeaderInfoVisible = false;
    selectedCellsQuantities: Array<number> = [];
    fullScreen: boolean = this.toolbarService.isFullScreen;
    teamsParameters: ITeamsParametersSelector = {
        function: [],
        category: [],
        region: [],
        team: []
    };
    teamsParametersSelected: ITeamsParametersSelected = {
        function: [],
        category: [],
        region: [],
        team: []
    };
    fvcParameters!: IFilterParameter[];
    parameters: {
        [parameter in keyof typeof EDataType | string]?: {
            name?: string;
            values: string[];
        };
    } = {};
    fvcParameterSelected: {
        [parameter in keyof typeof EDataType | string]?: {
            name?: string;
            values: string[];
        };
    } = {};
    onlyRequestsSelected: Record<number, boolean> = {};
    readonly POPOVER_COMMENT_CLASS = 'popover-comment';
    readonly ALL: string;
    readonly CONFIGS_WITH_REQUESTS: string;
    readonly TEAMS_WITH_REQUESTS: string;
    @ViewChild('protoRequestTable')
    private requestsTable!: ProtoRequestTableComponent<
        IProtoRequestHeader,
        IProtoRequestTeam,
        IProtoRequestTableStorage<IRequestTableStorage>
    >;
    private _dataTable: IProtoRequestTeam[] = [];
    private _headerTable: IProtoRequestHeader[] = [];
    private selectorsSubscriptions: Subscription;
    allHwRevisions: IHwRevision[] = [];
    selectedFilters: IPrototypeFilters;

    constructor(
        private prototypeRequestService: PrototypeRequestService,
        private bpRequestPreviewService: BuildPlanRequestPreviewService,
        private fb: FormBuilder,
        private i18n: TranslateService,
        private cdr: ChangeDetectorRef,
        private route: ActivatedRoute,
        private layout: LayoutUtilsService,
        private utilsFlag: UtilsFlag,
        private dialog: MatDialog,
        public toolbarService:ToolbarService
    ) {
        this.route.parent?.data.subscribe((data) => {
            this.idBuildPlan = data['buildPlan'].id;
            this.buildPlanName = data['buildPlan'].internalProductName;
        });
        this.labels = this.i18n.instant('BUILD_PLAN.REQUEST_PREVIEW');
        this.labelsRequest = this.i18n.instant('BUILD_PLAN.PROTOTYPE_REQUEST');
        this.labelsRequestFilters = this.i18n.instant(
            'BUILD_PLAN.PROTOTYPE_REQUEST.HEADER.FILTERS'
        );
        this.selectorsSubscriptions = new Subscription();
        this.ALL = (this.labelsRequestFilters as any)['ALL'];
        this.CONFIGS_WITH_REQUESTS = (this.labelsRequestFilters as any)['CONFIGS_WITH_REQUESTS'];
        this.TEAMS_WITH_REQUESTS = (this.labelsRequestFilters as any)['TEAMS_WITH_REQUESTS'];
        this.selectedFilters = this.prototypeRequestService.getRequestPreviewFilters();
        this.buildAmountForm();
        this.toolbarService.fullScreenObservable.subscribe(v=>{
            this.fullScreen =v;
        });
    }
    get hasSelection(): boolean {
        return this.requestsTable && this.requestsTable.getSelected().length > 0;
    }
    ngOnInit(): void {
        this.loadAllHwRevisions();
        this.s8n.add(
            this.route.parent?.data
                .pipe(
                    switchMap(({ buildPlan }) => {
                        this.isLoading = true;
                        return this.bpRequestPreviewService
                            .getByBuildPlanRequest(buildPlan.id, true)
                            .pipe(
                                catchError(() => {
                                    this.layout.showToastrNotification(
                                        this.i18n.instant('COMMON.ERROR_REQUEST'),
                                        ToastrType.error
                                    );
                                    return of({ teams: [], configurations: [] });
                                })
                            );
                    })
                )
                .subscribe((data) => {
                    this._headerTable = cloneDeep(data.configurations);
                    this.headers = this._headerTable;
                    data.teams.forEach((v: any) => {
                        v.requests.forEach(
                            (request: any) => {
                                request.firstQuantity = request.quantity || 0;
                                const headCol = this.headers.find(conf => conf.idConfiguration == request.configurationId);
                                if (!headCol || headCol.configStatus?.toUpperCase() === EConfigurationStatusLabel.CANCELED.toUpperCase()) {
                                    request.quantity = 0;
                                }
                            });
                    });
                    this._dataTable = cloneDeep(data.teams);
                    this.data = this._dataTable;
                    this.loadFilterRequests();
                    this.loadHwRevisionFilters();
                    this.loadFilterTeamsParameters();
                    this.loadFvcParametersFilters();
                    this.isLoading = false;
                    this.cdr.markForCheck();
                    this.updateSelectedFilters();
                })
        );
        this.subscribeToFilterSelectionChange();
        this.cdr.markForCheck();
    }
    ngOnDestroy(): void {
        this.s8n.unsubscribe();
        this.selectorsSubscriptions.unsubscribe();
    }

    public getFlag(country: string): string {
        return `fi ${this.utilsFlag.getFlag(country).class}`;
    }

    buildAmountForm(): void {
        this.amountRequestsForm = this.fb.group({
            amount: this.fb.control('0')
        });
    }

    loadAllHwRevisions(): void {
        this.prototypeRequestService.getAllHwRevisions()
            .subscribe((response) => this.allHwRevisions = response);
    }

    /**
     * Open dialog of request prototype
     * @param prototype request header
     */
    showRequestsDialog(header: IProtoRequestHeader, ev: Event): void {
        ev.stopPropagation();
        const param: IDataRequestDetailsDialog = {
            configurationId: header.idConfiguration,
            buildPlanId: this.idBuildPlan,
            buildPlanName: this.buildPlanName
        };
        this.dialog.open(RequestDetailsDialogComponent, {
            height: '796px',
            width: '1269px',
            data: param,
            panelClass: 'custom-dialog',
            hasBackdrop: true,
            disableClose: true
        });
    }

    updateSelectedCells(cells: any[]) {
        this.selectedCellsQuantities = cells.map(c => c.item.quantity || 0);
    }

    disableSPRPopover(item: IProtoRequest): boolean {
        return !(this.showComment(item) || this.renderSpecialRequirements(item));
    }
    showSPRPopover(popover: NgbPopover): void {
        if (this.currentPopover) {
            this.currentPopover.close();
            this.cdr.markForCheck();
        }
        popover.open();
        this.currentPopover = popover;
    }
    closeSPRPopover(popover: NgbPopover): void {
        setTimeout(() => {
            if (!document.querySelectorAll(`.${this.POPOVER_COMMENT_CLASS}:hover`).length) {
                popover.close();
            }
        }, 250);
    }
    renderSpecialRequirements(item: IProtoRequest): boolean {
        if(item.specialRequirementList){
            return (
                (item.quantity as any) &&
                item.quantity > 0 &&
                item.specialRequirementList &&
                item.specialRequirementList.length > 0
            );
        }else{
            return false;
        }
    }
    showComment(item: IProtoRequest): boolean {
        return (item as any) && item.additionalComments && item.additionalComments !== '';
    }
    getComment(item: IProtoRequest): string {
        return item !== undefined ? item.additionalComments : '';
    }
    showHideHeaderInfo(isVisible: boolean): void {
        this.isHeaderInfoVisible = isVisible;
    }
    isSourcing(configSource: string, source: number): boolean {
        return (configSource as any) && parseInt((configSource as any).match(/\d+/g)[0]) === source;
    }
    isCancelledConfiguration(prototype: IProtoRequestHeader): boolean {
        return prototype.configStatus === EConfigurationStatusLabel.CANCELED.toUpperCase();
    }
    verifyFieldFvcSecure(protoRequest: IProtoRequestHeader): boolean {
        return 'fvcSecure' in protoRequest;
    }
    getFvcSecure(protoRequest: IProtoRequestHeader): string {
        return protoRequest.fvcSecure
            ? this.labelsRequest.REQUEST.CARDS.FVC_SECURE
            : this.labelsRequest.REQUEST.CARDS.FVC_NO_SECURE;
    }
    /**
     * Filter columns and rows by active filters
     * @param $search Input search
     */
    filterByActiveFilters($search: ISearchBar = { searchInput: this.inputSearch }): void {
        this.noDataFilters = false;
        this.isSearching = true;
        this.inputSearch = $search.searchInput;
        this.headers = this._headerTable || [];
        this.data = this._dataTable || [];
        const hasOnlyRequests = Object.keys(this.onlyRequestsSelected).filter(
            (id: any) => this.onlyRequestsSelected[id]
        );
        // Filter Teams (rows)
        if (
            this.filterSelect[this.TEAMS_WITH_REQUESTS] ||
            this.inputSearch !== '' ||
            this.hasTeamsParametersSelected() ||
            !isEmpty(hasOnlyRequests)
        ) {
            this.doesFilterRows(hasOnlyRequests);
        }
        // Filter Configurations (Columns)
        if (
            this.filterSelect[this.CONFIGS_WITH_REQUESTS] ||
            this.hwRevisionSelected !== this.ALL ||
            this.fvcParameterSelected
        ) {
            this.doesFilterColumns();
        }
        // Filter teams with requests again, because this filter is in relation
        // configurations filtered, and configurations are filtered in relation teams
        if (this.filterSelect[this.TEAMS_WITH_REQUESTS]) {
            this.data = this.data.filter((team) => {
                const resultTeamsWithRequests = this.filterByTeamsWithRequests(team);
                if (!resultTeamsWithRequests) {
                    return false;
                }
                return true;
            });
        }
        if (this.headers.length === 0 || this.data.length === 0) {
            this.noDataFilters = true;
        }
        this.isSearching = false;
        this.cdr.markForCheck();
    }
    resetAllFiltersSelected(): void {
        this.isLoading = true;
        this.cdr.detectChanges();
        setTimeout(() => {
            this.isLoading = false;
            this.onlyRequestsSelected = {};
            this.teamsParametersSelected = {};
            this.hwRevisionSelected = this.ALL;
            this.fillSelectedHwRevisions(this.ALL);
            this.loadFilterRequests();
            this.clearFvcParameters();
            this.filterByActiveFilters();
        }, 1500);
    }
    filteringOnlyRequests(header: IProtoRequestHeader): boolean {
        return this.onlyRequestsSelected[header.idConfiguration];
    }
    filterByOnlyRequestsInAConfiguration(header: IProtoRequestHeader, ev: Event): void {
        ev.stopPropagation();
        this.onlyRequestsSelected[header.idConfiguration] =
            !this.onlyRequestsSelected[header.idConfiguration];
        this.filterByActiveFilters();
    }
    /**
     * Function called whenever a new Prototype Request filter is selected
     * @param option selected request type
     */
    onRequestTypeSelected(option: string): void {
        switch (option) {
        case this.ALL:
            this.loadFilterRequests();
            break;
        default:
            this.filterSelect[this.ALL] = false;
            this.filterSelect[option] = !this.filterSelect[option];
            if (
                !this.filterSelect[this.CONFIGS_WITH_REQUESTS] &&
                !this.filterSelect[this.TEAMS_WITH_REQUESTS]
            ) {
                this.loadFilterRequests();
            }
            break;
        }
        this.selectedFilters.requested = option;
        this.selectedFilters.requesteds = this.filterSelect;
        this.updateSelectedFilters();
    }

    fillSelectedHwRevisions(hwRevision: string){
        this.hwRevisionsSelected = [];
        if(hwRevision != this.ALL) {
            this.hwRevisionsSelected[0] = hwRevision.toLocaleUpperCase();
        }
    }

    onHwRevisionSelected(hwRevision: string): void {
        this.hwRevisionSelected = hwRevision;
        this.fillSelectedHwRevisions(hwRevision);
        const hwRevisionSelected = [];
        hwRevisionSelected[0] = hwRevision;
        this.selectedFilters.hwRevision = hwRevisionSelected;
        this.updateSelectedFilters();
    }
    /**
     * Checks whether a given filter is active or not
     * @param option: option to be checked
     *
     * @return true if filter is active, false otherwise
     */
    isFilterActive(option: string): boolean {
        return this.filterSelect[option];
    }
    isFilterHwRevisionActive(option: string): boolean {
        return this.hwRevisionSelected === option;
    }
    /**
     * When selector is closed, does filters
     * @param event
     */
    selectorChanged(event: ITeamsParametersSelected): void {
        this.teamsParametersSelected = event;
        this.selectedFilters.teamParametersSelected = event;
        this.updateSelectedFilters();
    }
    titleSelectorTeamsParameters(): string {
        return this.labels.SELECTOR_TEAMS_PARAMETERS;
    }
    /**
     * Get All Options selected from filters
     * @returns a IOptionsModel
     */
    getOptionsSelected(): IOptionsModel {
        const result: IOptionsModel = {};
        Object.keys(this.fvcParameterSelected).forEach((parameter) => {
            const name = (this.fvcParameterSelected as any)[parameter].name as string;
            result[name] = {};
            if (
                (this.fvcParameterSelected as any)[parameter].values &&
                (this.fvcParameterSelected as any)[parameter].values.length > 0
            ) {
                (this.fvcParameterSelected as any)[parameter].values.forEach((value: any) => {
                    result[name][value] = true;
                });
            }
        });
        return result;
    }
    /**
     * Function called whenever a new configuration filter is selected. It updates the prototype filters
     *
     * @param filter the configuration filter
     */
    onParameterFilterSelection(filter: {
        parameter: IFilterParameter;
        value: IFieldValue;
        selected: boolean;
    }): void {
        const dataType =
            filter.parameter.dataType === EDataType.DONT_CARE
                ? (filter.parameter.fieldName as string)
                : (filter.parameter.dataType?.valueOf() as string);
        const value = filter.value.value;
        if (!this.fvcParameterSelected[dataType]) {
            this.fvcParameterSelected[dataType] = {
                name: filter.parameter.fieldName,
                values: []
            };
        }
        const options = (this.fvcParameterSelected as any)[dataType].values;
        if (filter.selected) {
            options.push(value);
        } else {
            options.splice(options.indexOf(value), 1);
        }
        Object.keys(this.fvcParameterSelected).forEach((parameter) => {
            if (this.fvcParameterSelected[parameter]!.values.length === 0) {
                delete this.fvcParameterSelected[parameter];
            }
        });
        this.selectedFilters.parameters = this.fvcParameterSelected;
        this.updateSelectedFilters();
    }
    /**
     * Clear Fvc Parameter Filters
     */
    clearFvcParameters(): void {
        this.fvcParameterSelected = {};
        this.selectedFilters.parameters = {};
        this.updateSelectedFilters();
    }
    /**
     * Render when the button "All" was on
     */
    private renderAll(): void {
        this.loadFilterRequests();
        this.filterByActiveFilters();
    }
    /**
     * To execute filter in columns
     */
    private doesFilterColumns(): void {
        this.headers = this.headers.filter((config) => {
            // Filter by Fvc parameters
            if (this.fvcParameterSelected) {
                const resultFvcParameters = this.doesConfigMatchParameterFilters(config);
                if (!resultFvcParameters) {
                    return false;
                }
            }
            // Filter by HwRevisions
            if (this.hwRevisionSelected !== this.ALL) {
                const resultHwRevision =
                    (config.hwRevision as string).toUpperCase() ===
                    this.hwRevisionSelected.toUpperCase();
                if (!resultHwRevision) {
                    return false;
                }
            }
            // Filter by request types (Configs with requests)
            if (this.filterSelect[this.CONFIGS_WITH_REQUESTS]) {
                const resultConfigWithRequests = this.filterByConfigsWithRequests(config);
                if (!resultConfigWithRequests) {
                    return false;
                }
            }
            return true;
        });
    }
    /**
     * To execute filter in rows
     */
    private doesFilterRows(hasOnlyRequests: string[]): void {
        this.data = this.data.filter((team) => {
            //Filter by input search
            if (this.inputSearch !== '') {
                const resultInputSearch = this.filterBySearch(team);
                if (!resultInputSearch) {
                    return false;
                }
            }
            // Filter by Team Parameters
            if (this.hasTeamsParametersSelected()) {
                const resultTeamParameters = this.filterByTeamParameters(team);
                if (!resultTeamParameters) {
                    return false;
                }
            }
            // Filter by requests types (Teams with requests)
            if (this.filterSelect[this.TEAMS_WITH_REQUESTS]) {
                const resultTeamsWithRequests = this.filterByTeamsWithRequests(team);
                if (!resultTeamsWithRequests) {
                    return false;
                }
            }
            // Filter by a configuration with requests in cell
            if (!isEmpty(hasOnlyRequests)) {
                const resultOnlyRequests = this.doesFilterByOnlyRequests(team, hasOnlyRequests);
                if (!resultOnlyRequests) {
                    return false;
                }
            }
            return true;
        });
    }
    /**
     * Filter by search when the input matches with team alias and team values
     * @param team
     * @returns a boolean
     */
    private filterBySearch(team: IProtoRequestTeam): boolean {
        const teamAlias = team.teamAlias
            ?.toLowerCase()
            .includes(this.inputSearch.toLowerCase()) as boolean;
        const teamValues = team.teamValues
            ?.toLowerCase()
            .includes(this.inputSearch.toLowerCase()) as boolean;
        return teamAlias || teamValues;
    }
    /**
     * Filter by configurations with requests when the button "Configs with Requests"
     * was pressed
     * @param config
     * @returns a boolean
     */
    private filterByConfigsWithRequests(config: IProtoRequestHeader): boolean {
        if (!config.totalRequests || config.totalRequests === 0) {
            return false;
        }
        const teamFound = this.data.some((team) => {
            return team.requests?.some(
                (req) => req.configurationId === config.idConfiguration && req.quantity > 0
            );
        });
        return teamFound;
    }
    /**
     * Filter by teams with requests when the button "Teams with Requests"
     * was pressed
     * @param team
     * @returns a boolean
     */
    private filterByTeamsWithRequests(team: IProtoRequestTeam): boolean {
        if (isEmpty(team.requests)) {
            return false;
        }
        const configFound = this.headers.some((config) => {
            return team.requests?.some(
                (item) => item.configurationId === config.idConfiguration && item.quantity > 0
            );
        });
        return configFound;
    }
    /**
     * Filter by team parameters when the team parameter matches with
     * some selected filter
     * @param team
     * @returns a boolean
     */
    private filterByTeamParameters(team: IProtoRequestTeam): boolean {
        let resultFunction = false;
        let resultRegion = false;
        let resultCategory = false;
        let resultTeam = false;
        if (!isEmpty(this.teamsParametersSelected['function'])) {
            resultFunction = this.teamsParametersSelected['function']?.includes(
                team.function as string
            ) as boolean;
        }
        if (!isEmpty(this.teamsParametersSelected['region'])) {
            resultRegion = this.teamsParametersSelected['region']?.includes(
                team.region as string
            ) as boolean;
        }
        if (!isEmpty(this.teamsParametersSelected['category'])) {
            resultCategory = this.teamsParametersSelected['category']?.includes(
                team.category as string
            ) as boolean;
        }
        if (!isEmpty(this.teamsParametersSelected['team'])) {
            resultTeam = this.teamsParametersSelected['team']?.includes(
                team.team as string
            ) as boolean;
        }
        return resultFunction || resultRegion || resultCategory || resultTeam;
    }
    /**
     * Check if a given configuration matches the parameter filters
     *
     * @param config configuration to check
     */
    private doesConfigMatchParameterFilters(config: IProtoRequestHeader): boolean {
        let noMatch = false;
        Object.keys(this.fvcParameterSelected).some((parameter) => {
            const filter = (this.fvcParameterSelected as any)[parameter].values;
            if (isEmpty(filter)) {
                return false;
            }
            switch (parameter) {
            case EDataType.SYSTEM_BUILD_RECIPE:
                noMatch = !this.isValueInArray(config.recipeConfig, filter);
                break;
            case EDataType.SYSTEM_RAM_SIZE:
                noMatch = !this.isValueInArray(config.ram as string, filter);
                break;
            case EDataType.SYSTEM_STORAGE_SIZE:
                noMatch = !this.isValueInArray(config.storage as string, filter);
                break;
            case EDataType.SYSTEM_SIM:
                noMatch = !this.isValueInArray(config.simCard as string, filter);
                break;
            case EDataType.SYSTEM_SECURE_MARKING:
                noMatch = !this.isValueInArray(
                    this.getSecureMarkingStringFromBoolean(config.secureMarking as string),
                    filter
                );
                break;
            case EDataType.SYSTEM_SECURE:
                noMatch = !this.isValueInArray(
                    this.getFvcSecureStringFromBoolean(config.fvcSecure as boolean),
                    filter
                );
                break;
            case EDataType.SYSTEM_SOURCING:
                noMatch = !this.isValueInArray(config.sourcing as string, filter);
                break;
            case EDataType.SYSTEM_MARKET_REGION:
                noMatch = !this.isValueInArray(config.marketRegion as string, filter);
                break;
            case EDataType.SYSTEM_PRODUCT_SKU:
                noMatch = !this.isValueInArray(config.productSku as string, filter);
                break;
            case EDataType.SYSTEM_CMF_COLOR:
                noMatch = !this.isValueInArray(config.cmfColor as string, filter);
                break;
            case EDataType.SYSTEM_UNIT_TYPE:
                noMatch = !this.isValueInArray(config.unitType as string, filter);
                break;
            case EDataType.SYSTEM_XCVR_KIT:
                noMatch = !this.isValueInArray(config.xcvrKit as string, filter);
                break;
            case this.labelsRequest.HEADER.FILTERS.BUILD_DATE:
                noMatch = !this.isValueInArray(
                    DateTimeUtils.localDateFormatFromDateWithoutTimeZone(
                            config.buildDate as Date
                    ),
                    filter
                );
                break;
            case this.labelsRequest.HEADER.FILTERS.FVC:
                noMatch = !this.isValueInArray(config.configFvc as string, filter);
                break;
            default:
                noMatch = false;
            }
            return noMatch;
        });
        return !noMatch;
    }
    /**
     * Check if configurations checked has request on cells
     * @param team
     * @param hasOnlyRequests
     * @returns
     */
    private doesFilterByOnlyRequests(team: IProtoRequestTeam, hasOnlyRequests: string[]): boolean {
        if (isEmpty(team.requests)) {
            return false;
        }
        let thereAre = true;
        hasOnlyRequests.forEach((item) => {
            if (thereAre) {
                thereAre = team.requests?.some((req) => {
                    return parseInt(item) === req.configurationId && req.quantity > 0;
                }) as boolean;
            }
        });
        return thereAre;
    }
    /**
     * Check if there are teams parameters filters selected
     * @returns A boolean
     */
    private hasTeamsParametersSelected(): boolean {
        return (
            this.teamsParametersSelected &&
            (!isEmpty(this.teamsParametersSelected['function']) ||
                !isEmpty(this.teamsParametersSelected['region']) ||
                !isEmpty(this.teamsParametersSelected['category']) ||
                !isEmpty(this.teamsParametersSelected['team']))
        );
    }
    /**
     * Load status filter of requests types button
     */
    private loadFilterRequests(): void {
        this.filterSelect[this.ALL] = true;
        this.filterSelect[this.CONFIGS_WITH_REQUESTS] = false;
        this.filterSelect[this.TEAMS_WITH_REQUESTS] = false;
    }
    /**
     * Load teams parameters filter with the data from a given a prototype
     */
    private loadFilterTeamsParameters(): void {
        const setFunction = new Set<string>();
        const setRegion = new Set<string>();
        const setCategory = new Set<string>();
        const setTeam = new Set<string>();
        this._dataTable.forEach((data) => {
            data.function && setFunction.add(data.function);
            data.region && setRegion.add(data.region);
            data.category && setCategory.add(data.category);
            data.team && setTeam.add(data.team);
        });
        const teamParameters = {
            function: [],
            region: [],
            category: [],
            team: []
        };
        const createValue = (value: any): any => {
            return { group: (this.labelsRequestFilters as any)['ALL'], value };
        };
        setFunction.forEach((func) => teamParameters.function.push(createValue(func) as never));
        setRegion.forEach((reg) => teamParameters.region.push(createValue(reg) as never));
        setCategory.forEach((ctg) => teamParameters.category.push(createValue(ctg) as never));
        setTeam.forEach((team) => teamParameters.team.push(createValue(team) as never));
        this.teamsParameters = teamParameters;
    }
    /**
     * Load HwRevision filter with the data form a given a prototype
     */
    private loadHwRevisionFilters(): void {
        this.hwRevisionSelected = this.ALL;
        const hwRevisions = new Set<string>();
        this._headerTable.forEach((header) => {
            hwRevisions.add(header.hwRevision as string);
        });
        this.hwRevisions = Array.from(hwRevisions).sort(HwRevisionUtils.sorter);
    }
    /**
     * Load fvc parameters filters options with the data from a given prototype
     */
    private loadFvcParametersFilters(): void {
        this._headerTable.forEach((config) => {
            if (config.ram) {
                this.buildFvcParameter(
                    EDataType.SYSTEM_RAM_SIZE,
                    config.ram,
                    this.labelsRequest.HEADER.FILTERS.RAM_SIZE
                );
            }
            if (config.storage) {
                this.buildFvcParameter(
                    EDataType.SYSTEM_STORAGE_SIZE,
                    config.storage,
                    this.labelsRequest.HEADER.FILTERS.STORAGE_SIZE
                );
            }
            if (config.recipeConfig) {
                this.buildFvcParameter(
                    EDataType.SYSTEM_BUILD_RECIPE,
                    config.recipeConfig,
                    this.labelsRequest.HEADER.FILTERS.BUILD_RECIPE
                );
            }
            if (config.secureMarking) {
                this.buildFvcParameter(
                    EDataType.SYSTEM_SECURE_MARKING,
                    this.getSecureMarkingStringFromBoolean(config.secureMarking),
                    this.labelsRequest.HEADER.FILTERS.SECURE_MARKING
                );
            }
            if (config.fvcSecure) {
                this.buildFvcParameter(
                    EDataType.SYSTEM_SECURE,
                    this.getFvcSecureStringFromBoolean(config.fvcSecure),
                    this.labelsRequest.HEADER.FILTERS.SECURE
                );
            }
            if (config.sourcing) {
                this.buildFvcParameter(
                    EDataType.SYSTEM_SOURCING,
                    config.sourcing,
                    this.labelsRequest.HEADER.FILTERS.SOURCING
                );
            }
            if (config.marketRegion) {
                this.buildFvcParameter(
                    EDataType.SYSTEM_MARKET_REGION,
                    config.marketRegion,
                    this.labelsRequest.HEADER.FILTERS.MARKET_REGION
                );
            }
            if (config.productSku) {
                this.buildFvcParameter(
                    EDataType.SYSTEM_PRODUCT_SKU,
                    config.productSku,
                    this.labelsRequest.HEADER.FILTERS.PRODUCT_SKU
                );
            }
            if (config.cmfColor) {
                this.buildFvcParameter(
                    EDataType.SYSTEM_CMF_COLOR,
                    config.cmfColor,
                    this.labelsRequest.HEADER.FILTERS.CMF_COLOR
                );
            }
            if (config.simCard) {
                this.buildFvcParameter(
                    EDataType.SYSTEM_SIM,
                    config.simCard,
                    this.labelsRequest.HEADER.FILTERS.SIM
                );
            }
            if (config.unitType) {
                this.buildFvcParameter(
                    EDataType.SYSTEM_UNIT_TYPE,
                    config.unitType,
                    this.labelsRequest.HEADER.FILTERS.UNIT_TYPE
                );
            }
            if (config.xcvrKit) {
                this.buildFvcParameter(
                    EDataType.SYSTEM_XCVR_KIT,
                    config.xcvrKit,
                    this.labelsRequest.HEADER.FILTERS.XCVR_KIT
                );
            }
            if (config.buildDate) {
                this.buildFvcParameter(
                    this.labelsRequest.HEADER.FILTERS.BUILD_DATE,
                    DateTimeUtils.localDateFormatFromDateWithoutTimeZone(config.buildDate),
                    this.labelsRequest.HEADER.FILTERS.BUILD_DATE
                );
            }
            if (config.configFvc) {
                this.buildFvcParameter(
                    this.labelsRequest.HEADER.FILTERS.FVC,
                    config.configFvc,
                    this.labelsRequest.HEADER.FILTERS.FVC
                );
            }
        });
        if (this.parameters) {
            const parametersFvc: IFilterParameter[] = [];
            Object.keys(this.parameters).forEach((type) => {
                const dataType: TDataType = EDataType[type as keyof typeof EDataType]
                    ? EDataType[type as keyof typeof EDataType]
                    : EDataType.DONT_CARE;
                parametersFvc.push({
                    fieldName: this.parameters[type]!.name,
                    dataType: dataType,
                    fieldValues: this.getFieldValuesFromArray(this.parameters[type]!.values)
                });
            });
            this.fvcParameters = parametersFvc;
        }
    }
    /**
     * Given the secure marking boolean value, return the display string from the filters
     *
     * @param fvcSecure value to convert
     * @return converted value string
     */
    private getFvcSecureStringFromBoolean(fvcSecure: string | boolean): string {
        return fvcSecure || fvcSecure === 'true'
            ? this.labelsRequest.REQUEST.CARDS.FVC_SECURE
            : this.labelsRequest.REQUEST.CARDS.FVC_NO_SECURE;
    }
    /**
     * Given the secure marking boolean value, return the display string from the filters
     *
     * @param secureMarking value to convert
     * @return converted value string
     */
    private getSecureMarkingStringFromBoolean(secureMarking: string | boolean): string {
        return secureMarking || secureMarking === 'true'
            ? this.labelsRequest.REQUEST.CARDS.SECURE_MARKING
            : this.labelsRequest.REQUEST.CARDS.NO_MARKING;
    }
    /**
     * Build data of fvcParameter from configurations in the header
     *
     * @param type string
     * @param value string
     * @param name string
     */
    private buildFvcParameter(type: string, value: string, name: string): void {
        if (!this.parameters[type]) {
            this.parameters[type] = {
                name,
                values: []
            };
        }
        if (!this.isValueInArray(String(value), (this.parameters as any)[type].values)) {
            (this.parameters as any)[type].values.push(String(value));
        }
    }
    /**
     * Given a value and an array, check if the value is contained within the array
     *
     * @param value string
     * @param array string array
     * @returns boolean indicating if array contains the value
     */
    private isValueInArray(value: string, array: string[]): boolean {
        return array.includes(value);
    }
    /**
     * Given an array of string, return an array of IFieldValue for to use
     * in configurations parameters filters component
     *
     * @param values string array
     * @returns array of IFieldValue
     */
    private getFieldValuesFromArray(values: string[]): IFieldValue[] {
        let fieldValues: IFieldValue[] = [];
        if (values && values.length) {
            fieldValues = values.map((value) => {
                return { value };
            });
        }
        return fieldValues;
    }


    /**
     * Subscribes to changes on the filter selection to load the request preview information of the new selection
     */
    private subscribeToFilterSelectionChange(): void {
        this.selectorsSubscriptions.add(
            this.prototypeRequestService.requestPeviewFilterChange.subscribe(
                (selectedFilters: IPrototypeFilters) => {
                    if (selectedFilters && !this.isLoading) {
                        this.selectedFilters = selectedFilters;
                        this.mapSelectFilterToFilters(selectedFilters);
                        this.filterByActiveFilters();
                        this.cdr.detectChanges();
                    }
                },
                () => {
                    this.noDataLoaded();
                }
            )
        );
    }

    /**
     * No Data avaliable and hidden galvin icon loading
     */
    private noDataLoaded() {
        this.noData = true;
        this.noDataFilters = true;
        this.isLoading = false;
    }

    /**
     * Calls updateRequestPreviewFilterSelections from PrototypeRequestService
     */
    private updateSelectedFilters(): void {
        this.prototypeRequestService.updateRequestPreviewFilterSelections(this.selectedFilters);
    }

    mapSelectFilterToFilters(filter : IPrototypeFilters): void {
        if(filter){
            if(filter.inputSearch != undefined){
                this.inputSearch = filter.inputSearch;
            }

            if(filter.teamParametersSelected){
                this.teamsParametersSelected = {...filter.teamParametersSelected};
            }

            if(filter.parameters){
                this.fvcParameterSelected = {...filter.parameters};
            }

            if(isEmpty(filter.requesteds)) {
                this.loadFilterRequests();
            }else {
                this.filterSelect = {...filter.requesteds};
            }

            if (filter.hwRevision && !isEmpty(filter.hwRevision)) {
                this.hwRevisionSelected = filter.hwRevision[0];
            }else {
                this.hwRevisionSelected = this.ALL;
            }
        }
    }

    /**
     * Function called whenever a new value is entered on the search input. It updates the prototype filters
     *
     * @param value the input value
     */
    onSearchInputChange(value: ISearchBar): void {
        this.selectedFilters.inputSearch = value.searchInput;
        this.updateSelectedFilters();
    }

}
