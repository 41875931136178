export enum EDataTypeCommon {
    COLOR = 'COLOR',
    HW_REVISION = 'HW_REVISION',
    DONT_CARE = 'DONT_CARE',
    OPEN_TEXT = 'OPEN_TEXT',
}

export enum EDataTypeSystem {
    SYSTEM_SOURCING = 'System - Sourcing',
    SYSTEM_MARKET_REGION = 'System - Market Region',
    SYSTEM_PRODUCT_SKU = 'System - Product Sku',
    SYSTEM_CMF_COLOR = 'System - CMF Color',
    SYSTEM_SIM = 'System - SIM',
    SYSTEM_HW_REVISION = 'System - Hw Revision',
    SYSTEM_RAM_SIZE = 'System - Memory Size RAM',
    SYSTEM_STORAGE_SIZE = 'System - Memory Size Storage',
    SYSTEM_BUILD_RECIPE = 'System - Build Recipe',
    SYSTEM_SECURE_MARKING = 'System - Secure Marking',
    SYSTEM_SECURE = 'System - Secure',
    SYSTEM_UNIT_TYPE = 'System - Unit Type',
    SYSTEM_XCVR_KIT = 'System - XCVR Kit #',
    SYSTEM_PCBA_KIT = 'System - PCBA Kit #',
    SYSTEM_STORAGE_SUPPLIER = 'System - Storage Supplier',
    SYSTEM_ACCESSORY = 'System - Accessory',
    SYSTEM_MOTO_PART = 'System - Moto Part#',
    SYSTEM_CATEGORY = 'System - Category',
    SYSTEM_VENDOR = 'System - Vendor',
    SYSTEM_VENDOR_PART = 'System - Vendor Part#',
    SYSTEM_REQUEST_TYPE = 'System - Request Type',
    SYSTEM_REAR_CAMERA_DEPTH_SUPPLIER = 'System - Rear Camera Depth Supplier',
    SYSTEM_REAR_CAMERA_DEPTH= 'System - Rear Camera Depth',
    SYSTEM_50M_FRONT_CAMERA_SUPPLIER = 'System - 50M Front Camera Supplier',
    SYSTEM_INLAY_SUPPLIER = 'System - Inlay supplier',
    SYSTEM_REAR_HOUSING_COLOR = 'System - Rear Housing Color',
    SYSTEM_FRONT_HOUSING_COLOR = 'System - Front Housing Color',
    SYSTEM_FRONT_HOUSING_SUPPLIER = 'System - Front Housing Suplier',
    SYSTEM_REAR_CAMERA_WIDE_SUPPLIER = 'System - Rear Camera Wide Supplier',
    SYSTEM_REAR_CAMERA_WIDE = 'System - Rear Camera Wide',
    SYSTEM_MEMORY_STORAGE_SUPPLIER = 'System - Memory Storage Supplier',
    SYSTEM_REAR_HOUSING_SUPPLIER = 'System - Rear Housing Supplier',
    SYSTEM_DISPLAY_SUPPLIER = 'System - Display Supplier',
    SYSTEM_MAIN_PCB_SUPPLIER = 'System - Main PCB Supplier',
    SYSTEM_FINGERPRINT_SUPPLIER = 'System - Fingerprint Supplier',
    SYSTEM_MEMORY_RAM_SUPPLIER = 'System - Memory RAM Supplier',
    SYSTEM_50M_REAR_MAIN_CAMERA_SUPPLIER = 'System - 50M Rear Main Camera Supplier',
    SYSTEM_BATTERY_SUPPLIER = 'System - Battery Supplier',
}

export type TDataType = EDataTypeCommon | EDataTypeSystem;

export const EDataType = {
    ...EDataTypeCommon,
    ...EDataTypeSystem
};


export enum EDataTypeForAccessoryCommon {
    COLOR = 'Color',
    NA = 'N/A',
    OPEN_TEXT = 'Open Text',
}

export enum EDataTypeForAccessorySystem {
    HW_REVISION = 'System - Hardware Revision',
    SYSTEM_SOURCING = 'System - Sourcing',
    SYSTEM_MARKET_REGION = 'System - Market Region',
    SYSTEM_PRODUCT_SKU = 'System - Product Sku',
    SYSTEM_CMF_COLOR = 'System - CMF Color',
    SYSTEM_SIM = 'System - SIM',
    SYSTEM_UNIT_TYPE = 'System - Unit Type',
    SYSTEM_HW_REVISION = 'System - Hardware Revision',
    SYSTEM_RAM_SIZE = 'System - Memory Size RAM',
    SYSTEM_STORAGE_SIZE = 'System - Memory Size Storage',
    SYSTEM_BUILD_RECIPE = 'System - Build Recipe',
    SYSTEM_SECURE_MARKING = 'System - Secure Marking',
    SYSTEM_SECURE = 'SYSTEM_SECURE',
    SYSTEM_XCVR_KIT = 'System - XCVR Kit #',
    SYSTEM_PCBA_KIT = 'System - PCBA Kit #',
    SYSTEM_ACCESSORY = 'System - Accessory',
    SYSTEM_MOTO_PART = 'System - Moto Part #',
    SYSTEM_CATEGORY = 'System - Category',
    SYSTEM_VENDOR = 'System - Vendor',
    SYSTEM_VENDOR_PART = 'System - Vendor Part #',
}

export type TDataTypeForAccessory = EDataTypeForAccessoryCommon | EDataTypeForAccessorySystem;

export const EDataTypeForAccessory = {
    ...EDataTypeForAccessoryCommon,
    ...EDataTypeForAccessorySystem
};

export const EColors = [EDataType.COLOR, EDataType.SYSTEM_CMF_COLOR];
export type EDataTypeForAccessoryKeys = keyof typeof EDataTypeForAccessory;
