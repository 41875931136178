import { Pipe, PipeTransform } from '@angular/core';
import { ITeamSelectorItem } from '@galvin/core/build-plan/prototype-request-management/interfaces/team-selector-item.interface';
import { IProtoRequestTeam } from '@galvin/views/partials/layout/proto-request-table';

@Pipe({
    name: 'filterFullViewTeams'
})
export class FilterFullViewTeamsPipe implements PipeTransform {
    transform(values: IProtoRequestTeam[], filter: ITeamSelectorItem[]): IProtoRequestTeam[] {
        if (!filter || filter.length === 0) {
            return values;
        }
        return (values || []).filter((team) => filter.find((t) => team.idTeamAssociation === t.id));
    }
}
