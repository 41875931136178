import { SelectionModel } from '@angular/cdk/collections';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ContentChild,
    ElementRef,
    EventEmitter,
    HostListener,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    Output,
    TemplateRef,
    ViewChild
} from '@angular/core';
import { UserPermissionsService } from '@galvin/core/auth/services/user-permissions.service';
import { IAccessoryRequestTeam } from '@galvin/core/build-plan/accessory-request-management/interface/accessory-request-team.interface';
import { PrototypeRequestService } from '@galvin/core/build-plan/prototype-request-management';
import { IProtoRequestTableStorage } from '@galvin/core/build-plan/prototype-request-management/interfaces/proto-request-table-storage.interface';
import { LocalStorageHelper } from '@galvin/core/storage/local-storage-helper.service';
import { LayoutUtilsService, ToastrType } from '@galvin/core/_base/crud';
import { CellRefDirective } from '@galvin/views/partials/layout/proto-request-table/directives/cell-ref.directive';
import { GroupHeaderRefDirective } from '@galvin/views/partials/layout/proto-request-table/directives/group-header-ref.directive';
import { GroupRowDirective } from '@galvin/views/partials/layout/proto-request-table/directives/group-row.directive';
import { TableHeaderRefDirective } from '@galvin/views/partials/layout/proto-request-table/directives/table-header-ref.directive';
import { ProtoRequestTableCellSize } from '@galvin/views/partials/layout/proto-request-table/interfaces/proto-request-cell-size.interface';
import { IProtoRequestCell } from '@galvin/views/partials/layout/proto-request-table/interfaces/proto-request-cell.interface';
import { IProtoRequestHeader } from '@galvin/views/partials/layout/proto-request-table/interfaces/proto-request-header.interface';
import { ProtoRequestTableRange } from '@galvin/views/partials/layout/proto-request-table/interfaces/proto-request-range.interface';
import { IProtoRequestTeam } from '@galvin/views/partials/layout/proto-request-table/interfaces/proto-request-team.interface';
import {
    ProtoRequestTableService,
    PROTO_REQUEST_TABLE_TOKEN
} from '@galvin/views/partials/layout/proto-request-table/proto-request-table.service';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash';
import { merge, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { RowHeaderRefDirective } from './directives/row-header-ref.directive';
import { PrototypeRequestFormComponent } from '../prototype-request-form/prototype-request-form.component';
import { REGEX_MENTIONS_EMAIL } from '@galvin/core/comments/mention-comment/mention-comment.component';
import { IHwRevision } from '@galvin/core/build-plan/prototype-request-management/interfaces/hw-revision.interface';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { PageRenderService } from '@galvin/core/build-plan/prototype-request-management/services/page-render.service';
import { Router, RouterEvent, Event as REvent, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

export type GetColumnFn = (column: any, row: any) => IProtoRequestCell;
export type GetGroupFn = ({ first, last }: { first: number; last: number }) => {
    [key: number]: { [key: string]: any; colSpan: number };
};

export type RangeCell = { maxRow: number; minRow: number; maxCol: number; minCol: number };
export type MinMaxItem = { max: number; min: number };

interface IUndoCellsData {
    idTeamProtoRequest:number;
    accessoryId:number;
    idTeamAssociation:number;
}


@Component({
    selector: 'proto-request-table',
    exportAs: 'protoRequestTable',
    templateUrl: './proto-request-table.component.html',
    styleUrls: ['./proto-request-table.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProtoRequestTableComponent<
    C = IProtoRequestHeader | IAccessoryRequestTeam,
    R = IProtoRequestTeam,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    S = IProtoRequestTableStorage<any>
> implements OnInit, OnDestroy
{
    @ContentChild(GroupHeaderRefDirective)
        groupHeaderRef!: GroupHeaderRefDirective;
    @ContentChild(TableHeaderRefDirective)
        headerTemplateRef!: TableHeaderRefDirective;
    @ContentChild(RowHeaderRefDirective)
        rowHeaderRef!: RowHeaderRefDirective;
    @ContentChild(CellRefDirective)
        cellRef!: CellRefDirective;
    @ViewChild(PrototypeRequestFormComponent)
        requestForm!: PrototypeRequestFormComponent;

    @ViewChild(GroupRowDirective)
        groupRow!: GroupRowDirective;
    @ViewChild('elementRefTmp', { static: true })
        elementRef!: ElementRef<HTMLElement>;

    @ViewChild('leftHeader', { static: true })
        leftHeader!: ElementRef<HTMLElement>;

    @ViewChild('totalRequestCol', { static: false })
        totalRequestCol!: ElementRef<HTMLElement>;
    @ViewChild('totalPortableCol', { static: false })
        totalPortableCol!: ElementRef<HTMLElement>;

    @ViewChild('totalBoardsCol', { static: false })
        totalBoardsCol!: ElementRef<HTMLElement>;
    @ViewChild('tableHeaderElement', { static: true })
        tableHeaderElement!: ElementRef<HTMLElement>;

    @Input()
        renderButtonsTip = true;
    @Input()
        cellSize: ProtoRequestTableCellSize = { width: 50, height: 20 };
    @Input()
        horizontalBuffer = 0;
    @Input() bindRowValue!: string;
    @Input() expandButtonHeader = true;
    @Input() bindColumnValue!: string;
    @Input()
        verticalBuffer = 0;
    @Input()
        getColumnFn?: GetColumnFn;

    @Input()
        reduceGroupsFn?: GetGroupFn;
    @Input()
        isLoading = false;
    @Input()
        hideSpecialRequiments = false;

    @Input() isEditable = false;
    @Input() saveStorageItem!: string;
    @Input() isImportApproval = false;
    @Input() isTotalAccessories = false;
    @Input() showQuickAction = false;
    @Output()
        changeQuickAction = new EventEmitter<boolean>();
    @Output()
        showHeaderInfo = new EventEmitter<boolean>();

    @Output()
        sendNewAccRequests: EventEmitter<any> = new EventEmitter<any>();
    @Output()
        updateSelectedCells: EventEmitter<any> = new EventEmitter<any>();


    isHeaderInfoVisible = false;
    isTotalBoardsVisible = true;
    leftHeaderOffsetWidth = 0;
    totalRequestOffsetWidth = 0;
    totalBoardsOffsetWidth = 0;
    totalPortableOffsetWidth = 0;
    tableHeaderOffsetHeight = 0;
    isTotalPortableExpanded = false;
    isTotalBoardsExpanded = false;
    content: ProtoRequestTableCellSize = { height: 0, width: 0 };
    viewPortCapacity!: { horizontal: number; vertical: number };
    stack: { horizontal: number; vertical: number } = { horizontal: 0, vertical: 0 };
    xRange: { first: number; last: number } = { first: 1, last: 0 };
    yRange: { first: number; last: number } = { first: 0, last: 0 };
    edited: Record<string, IProtoRequestCell<C, R>> = {};
    columnRecord: Record<number, boolean> = {};
    rowRecord: Record<number, boolean> = {};
    portableHwRevisions: IHwRevision[] = [];
    boardsHwRevisions: IHwRevision[] = [];
    readonly selectionModel = new SelectionModel<string>(true, []);
    readonly tempStorageRequests = 'temp-import-approval-requests';
    readonly tempStorageEdited = 'temp-import-approval-edited';
    readonly StorageKey = 'import-approval-requests';
    readonly originalData = 'original-data';
    readonly currentData = 'current-data';

    positionGroupLeft = 0;
    public selected = [];
    localStorage: IProtoRequestTableStorage<any>;
    loggedUser!: string;
    private notLoaded: boolean = true;

    /* endregion Angular component lifecycle */
    private editable?: IProtoRequestCell<C, R>;
    private inputable?: HTMLInputElement;
    private lastSelectedCell?: IProtoRequestCell<C, R>;
    private lastTableCellEl?: HTMLTableCellElement;
    private buffer!: { horizontal: number; vertical: number };
    private s8n = new Subscription();
    private dataSubject = new Subject<R[]>();
    private headerSubject = new Subject<C[]>();
    private lastScrollPosition = { scrollLeft: 0, scrollTop: 0 };
    private columnSelectionModel = new SelectionModel<string>(true, []);
    private rowSelectionModel = new SelectionModel<string>(true, []);
    private cellIndexSelectionModel = new SelectionModel<string>(true, []);
    private insideClick?: boolean;
    private _selectedData: IProtoRequestCell<C, R>[] = [];
    private idBuildPlan!: number;
    private contentCopy: Record<number, { [col: number]: IProtoRequestCell<C, R> }> = {};
    public checkedQuickAction = false;
    private _data?: R[];
    private _groups: { [key: number]: { [key: string]: any; colSpan: number } } = {};
    /* region Getters/Setters */
    private _rows: number[] = [];
    private _tableHeight?: number;
    private _tableWidth?: number;
    private _columns: number[] = [];
    private _all_revisions: IHwRevision[] = [];
    public filteredHwRevisions? : string[] = [];
    constructor(
        private cdr: ChangeDetectorRef,
        private localStorageHelper: LocalStorageHelper,
        private prototypeRequestService: PrototypeRequestService,
        @Inject(PROTO_REQUEST_TABLE_TOKEN)
        private protoRequestTableService: ProtoRequestTableService<C, R>,
        private layoutUtilsService: LayoutUtilsService,
        private userPermissionsService: UserPermissionsService,
        private pageRender: PageRenderService,
        private readonly translate: TranslateService,
        private router: Router
    ) {
        this.loggedUser = this.localStorageHelper.getLoggedUser()?.email || '';
        this.localStorage = this.prototypeRequestService.getEmptyStorageObject();

        router.events.pipe(
            filter((e: REvent | RouterEvent): e is RouterEvent => e instanceof RouterEvent),
        ).subscribe((e: RouterEvent) => {
            if(e instanceof NavigationEnd) {
                this.scrollToOrigin();
            }
        });
    }

    ngOnInit(): void {
        this.s8n.add(
            merge(this.dataSubject, this.headerSubject)
                .pipe(debounceTime(200), distinctUntilChanged())
                .subscribe(() => {
                    this.measureStackSize();
                    this.updateView(false);
                    this.setBoardsHwRevisions();
                    this.setPortableHwRevisions();
                    this.setTableTotalData();
                    this.cdr.detectChanges();
                })
        );

        this.s8n.add(
            this.pageRender.tableDataChange.subscribe(
                () => {
                    this.measureViewPortSize();
                    this.updateView(false);
                    this.cdr.detectChanges();
                }
            )
        );
        this.measureViewPortSize();
        this.cdr.detectChanges();
    }

    get rows(): number[] {
        return this._rows || [];
    }

    get groups(): any {
        return this.data ? this._groups : {};
    }

    private _headers: C[] = [];

    get headers(): C[] {
        return this._headers || [];
    }

    @Input()
    set headers(headers: C[]) {
        if (headers) {
            this._headers = headers;
            if(headers && (headers.length > 0) && (headers[0] as any).configurationOrder) {
                this._headers = headers.sort((a, b) => this.sortIProtoRequestHeader(a as any, b as any));
            } else if (headers && (headers.length > 0) && (headers[0] as any).accessoryOrder) {
                this._headers = headers.sort((a, b) => this.sortIAcessoryRequestTeam(a as any, b as any));
            }
            this._columns = Array.from(headers.keys());
            if(!this.isTotalAccessories){
                this.setTotalsGroups();
            }
            this.measureViewPortSize();
            this.headerSubject.next(headers);
        }
    }

    get tableHeight(): number {
        return this._tableHeight || this.data.length;
    }

    @Input()
    set tableHeight(v: number) {
        this._tableHeight = v;
    }

    get tableWidth(): number {
        return this._tableWidth || this.headers.length;
    }

    @Input()
    set tableWidth(v: number) {
        this._tableWidth = v;
    }


    get columns(): number[] {
        return this._columns;
    }

    get allProtoHwRevisions(): IHwRevision[] {
        return this._all_revisions || [];
    }

    @Input()
    set allProtoHwRevisions(allHwRevisions : IHwRevision[]) {
        if (!this._all_revisions.length){
            this._all_revisions = allHwRevisions;
        }
    }

    portableGroups: string[] = [];
    boardGroups: string[] = [];

    get getPortableHwRevisions(): IHwRevision[] {
        return this._all_revisions.length ?
            this._all_revisions.filter(r => !r.name?.includes('EVB') && r.name!.toUpperCase().indexOf('BOARD') == -1) :
            [];
    }

    get getBoardsHwRevisions(): IHwRevision[] {
        return this._all_revisions.length ?
            this._all_revisions.filter(r => r.name?.includes('EVB') || r.name!.toUpperCase().indexOf('BOARD') > -1) :
            [];
    }



    get data(): R[] {
        return this._data || [];
    }

    @Input()
    set data(data: R[] | undefined) {
        if(this.isImportApproval){
            if (data && data.length > 0) {
                if(!this.localStorageHelper.getItem(this.originalData)){
                    this.localStorageHelper.setItem(this.originalData, JSON.stringify(data));
                }
                this._data = data;
                this._rows = Array.from(data.keys());
                this.dataSubject.next(data);
                this.loadStorage();
            }
        }else{
            if (data && data.length > 0) {
                this.loadStorage();

                this._data = data;
                this._rows = Array.from(data.keys());
                this.dataSubject.next(data);
            }
        }
        this.setTableTotalData();
    }

    @Input()
    set setFilteredHwRevision(hwRevFilters : string[]) {
        if (hwRevFilters){
            this.filteredHwRevisions = hwRevFilters;
        }
    }

    triggerDataChange(): void {
        if(!this.isImportApproval){
            this.data = this._data;
        }
    }

    /**
     * clientWidth and clientHeight is the inner size of element
     * including padding but excluding borders and scrollbars.
     */
    get viewPortSize(): ProtoRequestTableCellSize {
        return {
            width: window.innerWidth,
            height: window.innerHeight
        };
    }

    get tablePositionTop(): number {
        return this.cellSize.height * (this.rows[this.yRange.first] || 0);
    }

    get tablePositionLeft(): number {
        return this.cellSize.width * (this.columns[this.xRange.first] || 0);
    }

    /* endregion Getters/Setters */

    get hasChanges(): boolean {
        return Object.values(this.edited).length > 0;
    }

    get hasReqSelected(): boolean {
        return (
            Object.values(this.edited).some((req: any) => req.item.quantity != null) ||
            this.isReqSelected()
        );
    }

    get canChangeJustificationAndComment(): boolean {
        return (
            this.localStorage == null ||
            this.localStorage.canChangeJustificationAndComment == null ||
            this.localStorage.canChangeJustificationAndComment
        );
    }

    get hasSelection(): boolean {
        return this.getSelected().length > 0;
    }

    get canCopy(): boolean {
        return this.isEditable && this.hasSelection;
    }

    get canPaste(): boolean {
        return this.isEditable && this.hasSelection && this.hasCopy();
    }

    get canErase(): boolean {
        return this.hasSelection && this.isReqSelected();
    }

    get selectedData() {
        return this._selectedData;
    }

    set selectedData(data:IProtoRequestCell<C, R>[]) {
        this._selectedData =  JSON.parse(JSON.stringify(data));
        this.updateSelectedCells.emit(data);
    }

    @HostListener('click') clickInside(): void {
        this.insideClick = true;
    }

    @HostListener('document:click', ['$event'])
    clickOut(event: MouseEvent): void {
        const fullviewCellsSummary = (event.target as HTMLElement).closest('fullview-cells-summary');
        const ngbPopover = (event.target as HTMLElement).closest('ngb-popover-window');

        if(!fullviewCellsSummary && !ngbPopover){
            if (!this.insideClick) {
                this.cleanSelectionCollRowsCell();

            }
            this.insideClick = false;
        }
    }


    /* region Angular Component lifecycle */

    ngOnDestroy(): void {
        this.s8n.unsubscribe();
    }

    setTableTotalData() {
        if(this._data){
            this._data = this._data.map((d: any) => ({
                ...d,
                totalBoards: this.protoRequestTableService.sumBoards(d.requests, this.filteredHwRevisions!, this.boardsHwRevisions),
                totalPortable: this.protoRequestTableService.sumPortable(d.requests, this.filteredHwRevisions!, this.portableHwRevisions),
                totalRequests: this.protoRequestTableService.sumRequests(d.requests)
            }));
        }
    }

    setPortableHwRevisions() {
        this.portableHwRevisions = this._all_revisions.length ?
            this._all_revisions.filter(r => !r.name?.includes('EVB') && r.name!.toUpperCase().indexOf('BOARD') == -1) :
            [];
    }

    setBoardsHwRevisions() {
        this.boardsHwRevisions = this._all_revisions.length ?
            this._all_revisions.filter(r => r.name?.includes('EVB') || r.name!.toUpperCase().indexOf('BOARD') > -1) :
            [];
    }

    trackByFunction(index: number, item: any): unknown {
        if (!item) {
            return null;
        }
        return index;
    }

    clearLastSelectedCell(): void {
        //check if there are last editing cell to apply value
        if (this.editable && this.inputable) {
            this.onSaveCellValue((this.editable as any).$column, (this.editable as any).$row, this.inputable);
        }
        this.lastSelectedCell = undefined;
    }

    /**
     * Validate if incoming is defined otherwise use original template
     * @param original
     * @param incoming
     * @param defaultEdition
     * @param cell
     */
    getTemplate(
        original: TemplateRef<any>,
        incoming?: { template: TemplateRef<any> },
        cell?: IProtoRequestCell<C, R>,
        defaultEdition?: TemplateRef<any>
    ): TemplateRef<any> {
        return this.protoRequestTableService.getTemplate(
            <any>this.editable,
            original,
            incoming,
            cell,
            defaultEdition
        );
    }

    /**
     * Extract headers and groups, measure content size and initialize table data
     */
    updateView(reloadData = true): void {
        /**
         * The method measureContentSize needs the headers
         * to measure the table width
         */
        if(this.notLoaded && this.localStorageHelper.getItem(this.currentData) && this.isImportApproval){
            this.data = JSON.parse(this.localStorageHelper.getItem(this.currentData));
            this.notLoaded = false;
        }
        this.measureContentSize();
        this.measureViewPortSize();
        this.measureStackSize();
        if (reloadData) {
            this.cleanSelectionCollRowsCell();
            this.lastScrollPosition = { scrollLeft: 0, scrollTop: 0 };
            this.elementRef.nativeElement.scrollLeft = 0;
            this.elementRef.nativeElement.scrollTop = 0;
        }
        const scrollTo = {
            target: {
                scrollLeft: this.lastScrollPosition.scrollLeft + 1,
                scrollTop: this.lastScrollPosition.scrollTop + 1
            }
        };
        this.onScroll(scrollTo, true);
        this.cdr.detectChanges();

        this.leftHeaderOffsetWidth = this.leftHeader?.nativeElement?.offsetWidth || 0;
        this.totalRequestOffsetWidth = this.totalRequestCol?.nativeElement?.offsetWidth || 0;
        this.totalBoardsOffsetWidth = this.totalBoardsCol?.nativeElement?.offsetWidth || 0;
        this.tableHeaderOffsetHeight = this.tableHeaderElement?.nativeElement?.offsetHeight || 0;
        this.totalPortableOffsetWidth = this.totalPortableCol?.nativeElement?.offsetWidth || 0;
    }

    public onScroll(event: { target: { scrollLeft: number; scrollTop: number } }, force = false): void {
        const { scrollLeft, scrollTop } = event.target;

        if (this.lastScrollPosition.scrollLeft !== scrollLeft) {
            this.xRange = ProtoRequestTableComponent.getRange(
                scrollLeft,
                this.tableWidth,
                this.cellSize.width,
                this.stack.horizontal,
            );
            this.reduceGroups(this.xRange);
            this.lastScrollPosition.scrollLeft = scrollLeft;
        }
        if(this.lastScrollPosition.scrollTop !== scrollTop || force) {
            this.yRange = ProtoRequestTableComponent.getRange(
                scrollTop,
                this.tableHeight,
                this.cellSize.height,
                this.stack.vertical,
            );
            this.lastScrollPosition.scrollTop = scrollTop;
        }

        this.cdr.detectChanges();
    }
    getPortableHwRevisionsByGroup(group: string): IHwRevision[] {
        return this._all_revisions.length ?
            this._all_revisions.filter(r => r.name?.includes(group) && r.name!.toUpperCase().indexOf('BOARD') == -1) :
            [];
    }

    getBoardsHwRevisionsByGroup(group: string): IHwRevision[] {
        return this._all_revisions.length ?
            this._all_revisions.filter(r => r.name?.includes(group)) :
            [];
    }

    public scrollToOrigin() {
        const scrollTo = {
            target: {
                scrollLeft: 0,
                scrollTop: 0
            }
        };
        this.onScroll(scrollTo, true);
    }

    /**
     *  Get index position from column and row object
     */
    getCellIndex(column: C | 'a', row: R | 'a'): string {
        return this.protoRequestTableService.getCellIndex(column, row);
    }

    @HostListener('window:keydown.control.c')
    public copy(clearSelection=true): void {
        if (!this.canCopy) {
            return;
        }

        this.updateColumnSelectModel();
        this.updateSelected();
        this.contentCopy = this.groupByRow();

        this.localStorageHelper.setContentCopy(this.contentCopy);

        this.layoutUtilsService.showToastrNotification(
            this.translate.instant('BUILD_PLAN.REQUEST_PREVIEW.COPY_SUCCESS'),
            ToastrType.success
        );
        if(clearSelection){
            this.cleanSelectionCollRowsCell();
        }
    }

    private groupByRow() {
        const contentCopy :any= {};
        const selectedCells = this.cellIndexSelectionModel.selected.filter(s => !s.includes('undefined'));
        selectedCells.forEach((key) => {
            const [colIndex, rowIndex] = key.split('-').map((s) => Number(s));

            if (!contentCopy[rowIndex]) {
                contentCopy[rowIndex] = {};
            }

            contentCopy[rowIndex][colIndex] = this.getColumnObject(
                this.headers[colIndex],
                this.data[rowIndex]
            );
        });
        return contentCopy;
    }

    @HostListener('window:keydown.delete')
    public erase(): void {
        if (!this.canErase) {
            return;
        }
        this.selectedData
            .filter((cell: any) => cell.item.quantity != null)
            .forEach((cell: any) => {
                const copyCell: any =
                    ProtoRequestTableComponent.deepCopy<IProtoRequestCell<C, R>>(cell);
                if (copyCell.item.quantity==0){
                    delete copyCell.item.quantity;
                }
                if (Object.getOwnPropertyNames(copyCell.item).length>0){
                    if(copyCell.item?.request || copyCell.item?.firstQuantity){
                        copyCell.item.quantity = 0;
                        copyCell.item.specialRequirementList = [];
                        copyCell.item.justitifications = [];
                        copyCell.item.additionalComments = '';
                        copyCell.item.comment = '';
                        this.onSelectionChange([copyCell]);
                        this.saveContent(copyCell);
                    }else{
                        this.undoEdition(null, cell.$column, cell.$row);
                    }
                }
            });

        this.cleanSelectionCollRowsCell();
        this.cdr.detectChanges();
    }

    public hasCopy(): boolean {
        return Object.keys(this.contentCopy).length > 0;
    }

    @HostListener('window:keydown.control.v')
    public paste(): void {
        this.contentCopy = this.localStorageHelper.getContentCopy();

        if (!this.contentCopy || !this.canPaste) {
            return;
        }

        const range = this.getRangeCells(this.contentCopy);

        const ref = this.getReferencedCell();

        if (this.verifyTableLimits(ref.colIndex, ref.rowIndex, range)) {
            this.layoutUtilsService.showToastrNotification(
                this.translate.instant('BUILD_PLAN.REQUEST_PREVIEW.PASTE_OPERATION_EXCEED_LIMIT'),
                ToastrType.error
            );
            return;
        }
        //Apply paste operation in subtable selected before in copy operation
        for (let rowIndex = ref.rowIndex, i = range.minRow; i <= range.maxRow; i++, rowIndex++) {
            if (!this.contentCopy[i]) {
                continue;
            }
            const cols = this.contentCopy[i];
            for (
                let j = range.minCol, colIndex = ref.colIndex;
                j <= range.maxCol;
                j++, colIndex++
            ) {
                const copiedCell: any = cols[j];
                if ((!copiedCell) ) {
                    continue;
                }
                if (copiedCell.item.quantity == null) {
                    copiedCell.item.quantity == 0;
                }

                const cell: IProtoRequestCell<C, R> = JSON.parse(JSON.stringify(this.getColumnObject(
                    this.headers[colIndex],
                    this.data[rowIndex]
                )));

                if (copiedCell.item['quantity']==null || copiedCell.item['quantity']==0){
                    if (cell.item['quantity']!=null && cell.item['quantity']>0){
                        copiedCell.item['quantity']=0;
                        this.copyCell(copiedCell, cell);
                        this.createNewCell(cell,cell.item);
                        this.saveContent(cell);
                    }else{
                        cell.item['quantity']=null;
                    }
                }else{
                    this.copyCell(copiedCell, cell);
                    this.createNewCell(cell,cell.item);

                    this.saveContent(cell);
                }


            }
        }

        this.cleanSelectionCollRowsCell();
    }

    @HostListener('window:keydown.control.x')
    public cut(): void {
        if (!this.canErase) {
            return;
        }
        this.copy(false);
        this.erase();
    }

    @HostListener('window:keydown.F2', ['$event'])
    @HostListener('window:keydown.Enter', ['$event'])
    @HostListener('window:keydown.1', ['$event'])
    @HostListener('window:keydown.2', ['$event'])
    @HostListener('window:keydown.3', ['$event'])
    @HostListener('window:keydown.4', ['$event'])
    @HostListener('window:keydown.5', ['$event'])
    @HostListener('window:keydown.6', ['$event'])
    @HostListener('window:keydown.7', ['$event'])
    @HostListener('window:keydown.8', ['$event'])
    @HostListener('window:keydown.9', ['$event'])
    public onEnableEdit(event: any) {
        //Check to enable Edit
        if (this.lastSelectedCell && !this.editable) {
            this.toggleCellState(this.lastSelectedCell, this.lastTableCellEl, (event.key != 'F2' && event.key != 'Enter'  ? event.key : null));
        }
    }

    saveContent(cell: IProtoRequestCell<C, R, any>){
        if (!cell.item['quantity']){
            cell.item['quantity'] = 0;
        }
        if(this.localStorageHelper.getPrototypeRequestsChanges(this.idBuildPlan)?.overwriteAll){
            cell.item['specialRequirementList'] = this.localStorageHelper.getPrototypeRequestsChanges(this.idBuildPlan)?.specialRequirements;
        }
        this.protoRequestTableService.onSaveCellValue(cell, cell.$row, cell.$column, this.localStorage, this.isImportApproval);

        this.saveStorage(this.localStorage);
        this.saveCurrentData();
    }

    toggleColSelect(
        column: C | 'a',
        colIndex?: number | null,
        ctrlKey: boolean=false,
        shiftKey: boolean=false
    ): void {
        const rowAll='a';
        const cellIndex = this.getCellIndex(column, rowAll);
        if (this.editable && this.inputable) {
            this.onSaveCellValue((this.editable as any).$column, (this.editable as any).$row, this.inputable);
        }
        delete this.lastSelectedCell;

        if (!ctrlKey){
            this.cleanSelectionCell();
            if (!(ctrlKey || shiftKey)){

                this.columnSelectionModel.selected.forEach(e => {
                    if (cellIndex!==e){
                        this.columnSelectionModel.deselect(e);
                    }
                });
            }
        }

        if (shiftKey && this.columnSelectionModel.selected.length>0){
            this.columnSelectionModel.select(cellIndex);
            this.selectAllCellByCol(colIndex, column );
            this.selectRangeCol();
        }else{
            this.columnSelectionModel.toggle(cellIndex);
            const isSelected=this.columnSelectionModel.isSelected(cellIndex);
            this.selectAllCellByCol(colIndex, column,isSelected );
        }
        this.updatRowSelectModel();
        this.updateSelected();
    }


    private selectAllCellByCol(colIndex: number | null | undefined,  column: 'a' | C,isSelected: boolean=true) {
        this.data.forEach((row, index) => {
            const positionIndex = `${colIndex}-${index}`;
            if (isSelected) {
                this.selectionModel.select(this.getCellIndex(column, row));
                this.cellIndexSelectionModel.select(positionIndex);
            } else {
                this.selectionModel.deselect(this.getCellIndex(column, row));
                this.cellIndexSelectionModel.deselect(positionIndex);
            }
        });
    }

    toggleRowSelect(
        row: R | 'a',
        rowIndex?: number | null,
        ctrlKey: boolean=false,
        shiftKey: boolean=false
    ): void {
        const column='a';
        const cellIndex = this.getCellIndex(column, row);
        if (this.editable && this.inputable) {
            this.onSaveCellValue((this.editable as any).$column, (this.editable as any).$row, this.inputable);
        }

        if (!ctrlKey){
            this.cleanSelectionCell();
            if(!shiftKey){
                this.rowSelectionModel.selected.forEach(e => {
                    if (cellIndex!==e){
                        this.rowSelectionModel.deselect(e);
                    }
                });
            }

        }
        if (shiftKey && this.rowSelectionModel.selected.length>0){
            this.rowSelectionModel.select(cellIndex);
            this.selectAllCellByRow(rowIndex, row);
            this.selectRangeRow();
        }else{
            delete this.lastSelectedCell;
            this.rowSelectionModel.toggle(cellIndex);
            const isSelected=this.rowSelectionModel.isSelected(cellIndex);
            this.selectAllCellByRow(rowIndex, row,isSelected);
        }
        this.updateColumnSelectModel();
        this.updateSelected();
    }

    private selectAllCellByRow(rowIndex: number | null | undefined, row: R | 'a', isSelected: boolean=true) {
        this.headers.forEach((column, index) => {
            const positionIndex = `${index}-${rowIndex}`;
            if (isSelected) {
                this.cellIndexSelectionModel.select(positionIndex);
                this.selectionModel.select(this.getCellIndex(column, row));
            } else {
                this.selectionModel.deselect(this.getCellIndex(column, row));
                this.cellIndexSelectionModel.deselect(positionIndex);
            }
        });
    }

    toggleCellSelect(
        column: C ,
        row: R ,
        rowIndex?: number | null,
        colIndex?: number | null,
        elRef?: HTMLTableCellElement,
        ctrlKey: boolean=false,
        shiftKey: boolean=false
    ): void {
        if (!(ctrlKey || shiftKey)){
            this.cleanSelectionCell();
        }
        const cellIndex = this.getCellIndex(column, row);
        const positionIndex = `${colIndex}-${rowIndex}`;

        if (shiftKey && this.cellIndexSelectionModel.selected.length>0){
            this.selectRange(cellIndex, positionIndex);
        }else{
            delete this.lastSelectedCell;
            this.selectionModel.toggle(cellIndex);
            this.cellIndexSelectionModel.toggle(positionIndex);
        }
        //check if there are last editing cell to apply value
        if (this.editable && this.inputable) {
            this.onSaveCellValue((this.editable as any).$column, (this.editable as any).$row, this.inputable);
        }

        if (this.isSelected(column, row)) {
            this.lastSelectedCell = this.getColumnObject(column, row);
            this.lastTableCellEl = elRef;
        }
        this.updateColumnSelectModel();
        this.updatRowSelectModel();
        this.updateSelected();
    }

    private selectRange(cellIndex: string, positionIndex: string) {
        this.selectionModel.select(cellIndex);
        this.cellIndexSelectionModel.select(positionIndex);
        const contentRefs = this.groupByRow();
        const range = this.getRangeCells(contentRefs);
        this.cleanSelectionCell();
        for (let rowIndex = range.minRow; rowIndex <= range.maxRow; rowIndex++) {
            for (let colIndex = range.minCol; colIndex <= range.maxCol; colIndex++) {
                const positionIndex = `${colIndex}-${rowIndex}`;
                const cellIndex = this.getCellIndex(this.headers[colIndex], this.data[rowIndex]);
                this.selectionModel.select(cellIndex);
                this.cellIndexSelectionModel.select(positionIndex);
            }
        }
    }

    private selectRangeRow() {
        const range = this.getRangeByType('row');
        for (let rowIndex =range.min; rowIndex <= range.max; rowIndex++) {
            const row =  this.data[rowIndex];
            this.rowSelectionModel.select(this.getCellIndex('a', row));
            this.selectAllCellByRow(rowIndex, row);

        }
    }

    private selectRangeCol() {
        const range = this.getRangeByType('col');
        for (let rowIndex =range.min; rowIndex <= range.max; rowIndex++) {
            const col =  this.headers[rowIndex];
            this.columnSelectionModel.select(this.getCellIndex(col,'a'));
            this.selectAllCellByCol(rowIndex, col);

        }
    }

    private updatRowSelectModel() {
        const qtItemByRow = this.rows.length;
        this.rowSelectionModel.selected.forEach(e => {
            const row = e.split('-')[1];
            const qtItemByRowSelected = this.selectionModel.selected.filter(a => a.includes('-' + row)).length;
            if (qtItemByRow != qtItemByRowSelected) {
                this.rowSelectionModel.deselect(e);
            }
        });
    }

    private updateColumnSelectModel() {
        const qtItemByColl  = this.data.length;
        this.columnSelectionModel.selected.forEach(e => {
            const col = e.split('-')[0];
            const qtItemColSelected = this.selectionModel.selected.filter(a => a.includes(col + '-')).length;
            if (qtItemByColl != qtItemColSelected) {
                this.columnSelectionModel.deselect(e);
            }
        });
    }

    loadStorage(): void {
        this.idBuildPlan = this.prototypeRequestService.getBuildPlanSelection();
        if(this.isImportApproval){
            this.edited = {};
        }
        if (this.saveStorageItem) {
            this.localStorage = this.prototypeRequestService.loadStorage(
                this.saveStorageItem,
                this.idBuildPlan
            );
            if (this.localStorage && this.isImportApproval) {
                if(!this.localStorageHelper.getItem(this.tempStorageRequests)){
                    this.populateLocalStorageRequests();
                }else{
                    this.getUpdatedValues();
                }
            } else if(this.localStorage){
                this.protoRequestTableService.applyLoadStorage(this.localStorage, this.edited);
            }
            else {
                this.localStorageHelper.setPrototypeRequestsChanges(
                    this.saveStorageItem,
                    this.localStorage
                );
            }
        }
    }

    updateSelected(): void {
        this.columnRecord = {};
        this.rowRecord = {};
        this.selectedData = this.protoRequestTableService.updateSelected(
            this.columnRecord,
            this.rowRecord,
            this.selectionModel,
            this.headers,
            this.data,
            this.edited
        );
    }

    isSelected(column: C | 'a', row: R | 'a'): boolean {
        const cellIndex = this.getCellIndex(column === 'a' ? 'a' : column, row);
        return (
            this.selectionModel.isSelected(cellIndex) ||
            this.rowSelectionModel.isSelected(cellIndex) ||
            this.columnSelectionModel.isSelected(cellIndex)
        );
    }

    selectedColumn(column: C): boolean {
        return this.protoRequestTableService.selectedColumn(column, this.columnRecord);
    }

    selectedRow(row: R): boolean {
        return this.protoRequestTableService.selectedRow(row, this.rowRecord);
    }

    saveStorage(value: IProtoRequestTableStorage<any>): void {
        this.localStorage = this.prototypeRequestService.saveStorage(
            value,
            this.idBuildPlan,
            this.saveStorageItem
        );
        this.triggerDataChange();
    }

    undoStorage(column: C, row: R): void {
        if((column as any).idConfiguration){
            this.localStorage =
            this.localStorageHelper.getPrototypeRequestsChanges(
                this.idBuildPlan
            ) as IProtoRequestTableStorage<any>;
        }else{
            this.localStorage =
            this.localStorageHelper.getAccessoryRequestsChanges(
                this.idBuildPlan
            ) as IProtoRequestTableStorage<any>;
        }

        const prototypeRequestsChanges = this.localStorage;
        this.protoRequestTableService.undoStorage(
            prototypeRequestsChanges,
            column,
            row,
            this.localStorageHelper,
            this.saveStorageItem
        );
        this.cdr.detectChanges();
    }

    undoAll(): void {
        if (!this.saveStorageItem) {
            return;
        }
        this.prototypeRequestService.undoAll(this.saveStorageItem, this.idBuildPlan);
        this.localStorage = {
            idBuildPlan: 1,
            requests: [],
            comment: undefined,
            overwriteAll: undefined,
            justification: {
                AUTOMATED_TESTING: false,
                MANUAL_TESTING: false,
                CARRIERS: false,
                SHOW_OFF: false,
                MARKETING: false,
                OTHER: false
            },
            specialRequirements: undefined,
            canChangeJustificationAndComment: true,
            hasMixedUp: false
        };
        this.edited ={};
        this.selectionModel.clear();
        this.rowSelectionModel.clear();
        this.columnSelectionModel.clear();
        this.cellIndexSelectionModel.clear();

        this.requestForm.removeEditedCells();
    }

    undoOnlyEdition(): void {
        Object.values(this.edited)
            .filter((cell: IProtoRequestCell<C, R>) => cell.operation === 'edit')
            .forEach((cell: IProtoRequestCell<C, R>) => {
                this.undoEdition(null, cell.$column, cell.$row);
            });
    }

    undoSomeCells(value: IUndoCellsData[]){
        Object.values(this.edited)
            .filter((cell: IProtoRequestCell<C, R>) => {
                const x = value.findIndex(v =>
                    (v.idTeamAssociation && v.idTeamAssociation === cell.item['idTeamAssociation'] &&
                    v.accessoryId && v.accessoryId === cell.item['accessoryId']) ||
                   (v.idTeamProtoRequest && v.idTeamProtoRequest === cell.item['idTeamProtoRequest'])
                );
                return x !== -1;
            })
            .forEach((cell: IProtoRequestCell<C, R>) => {
                this.undoEdition(null, cell.$column, cell.$row);
            });
    }

    getClassByAdditionalCommentRequest(column: C, row: R): string {
        if (this.data) {
            const colaux = <any>column;
            const rowaux = <any>row;

            if (colaux['idConfiguration']) {
                //For Configurations
                // is at localStorage
                const findInLocalStorage = this.localStorage ? this.localStorage?.requests.find(req => {
                    return !!req.comment &&
                            req.configurationId === (column as any).idConfiguration &&
                            req.idTeamAssociation === (row as any).idTeamAssociation;
                }) : null;

                // is at data
                const findInData = rowaux.requests?.find((c: any) =>
                    !!c.additionalComments &&
                    c.configurationId === colaux.idConfiguration &&
                    c.revision === colaux.revision?.fvcValueId
                );

                if(findInData?.additionalComments?.includes(this.loggedUser) || findInLocalStorage?.comment?.includes(this.loggedUser)){
                    return 'border-comments yellow';
                }else if(findInData?.additionalComments?.match(REGEX_MENTIONS_EMAIL) || findInLocalStorage?.comment?.match(REGEX_MENTIONS_EMAIL)){
                    return 'border-comments blue';
                }

                return !!findInLocalStorage || !!findInData ? 'border-comments black' : '';
            }
            else {
                //For Accessories
                // is at localStorage
                const findInLocalStorage = this.localStorage ? this.localStorage?.requests.find(req => {
                    return !!req.comment &&
                            req.accessoryId === (column as any).accessoryId &&
                            req.idTeam === (row as any).idTeam;
                }) : null;
                // is at data
                const findInData = rowaux.requests?.find((c: any) =>
                    !!c.additionalComments &&
                    c.accessoryId === colaux.accessoryId
                );

                if(findInData?.additionalComments?.includes(this.loggedUser) || findInLocalStorage?.comment?.includes(this.loggedUser)){
                    return 'border-comments yellow';
                }else if(findInData?.additionalComments?.match(REGEX_MENTIONS_EMAIL) || findInLocalStorage?.comment?.match(REGEX_MENTIONS_EMAIL)){
                    return 'border-comments blue';
                }

                return !!findInLocalStorage || !!findInData ? 'border-comments black' : '';
            }
        }
        return '';
    }

    getSelected(): IProtoRequestCell<C, R>[] {
        return this.selectedData;
    }

    cleanSelectionCollRowsCell(): void {
        this.columnSelectionModel.clear();
        this.rowSelectionModel.clear();
        this.cleanSelectionCell();
    }

    cleanSelectionCell(): void {
        this.selectionModel.clear();
        this.cellIndexSelectionModel.clear();
        this.selectedData = [];
        delete this.lastSelectedCell;
        this.updateSelected();
    }

    /**
     * return row item
     * @param index
     */
    getRowObject(index: number): R | null {
        return index in this.data ? this.data[index] : null;
    }

    /**
     * return column item
     * @param column
     * @param row
     */
    getColumnObject(column: C, row: R): IProtoRequestCell<C, R> {
        const obj = this.protoRequestTableService.getColumnObject(
            row,
            column,
            this.edited,
            this.data
        );
        return obj;
    }

    onSelectionChange(event: IProtoRequestCell<C, R>[]): void {
        event.forEach((cell) => {
            const item: any = cell.item;
            if (item.quantity >= 0) {
                this.edited[this.getCellIndex(cell.$column, cell.$row)] = cell;
            } else {
                delete this.edited[this.getCellIndex(cell.$column, cell.$row)];
            }
        });
    }

    toggleCellState(cell: IProtoRequestCell<C, R>, elRef?: HTMLTableCellElement, value2?: string): void {
        if (this.isEditable) {
            if (this.isEditingCell(cell.$column, cell.$row)) {
                delete this.editable;
                delete this.inputable;
            } else {
                //check if there are last editing cell to apply value
                if (this.editable && this.inputable) {
                    this.onSaveCellValue((this.editable as any).$column, (this.editable as any).$row, this.inputable);
                }
                this.protoRequestTableService.toggleCellState(
                    cell,
                    <any>this.editable,
                    this.columnRecord,
                    this.rowRecord
                );
                this.editable = cell;
                setTimeout(() => {
                    if (elRef) {
                        const input = elRef.querySelector('input');
                        if (input) {
                            if (value2) {
                                input.value = value2;
                            }
                            input.focus();
                        }
                    }
                }
                , 1);

            }
        }
        this.cdr.detectChanges();
    }

    /**
     *
     * @param row
     * @param column
     * @param inputEl
     */

    onSaveCellValue(column: C, row: R, inputEl: HTMLInputElement): void {
        const cell:IProtoRequestCell<C, R> =  JSON.parse(JSON.stringify(this.getColumnObject(column, row)));

        const item: any = cell.item;
        let entryQty = parseInt(inputEl.value);
        let currQty = item.quantity;

        if (isNaN(entryQty)) {
            entryQty = 0;
        }
        if (!currQty) {
            currQty = 0;
        }

        item.quantity = parseInt(inputEl.value);


        delete this.editable;
        delete this.inputable;

        if(this.isImportApproval){
            this.createNewCell(cell,item);
        }

        if (entryQty !== currQty) {
            this.saveContent(cell);
        }
        if (entryQty !== currQty) {
            this.onSelectionChange([cell]);
        }
        this.updateSelected();
    }

    createNewCell(cell:IProtoRequestCell<C, R, any>, item: any){
        //If not exist cell, create new item to push on array
        if ((cell.$column as any)['accessoryOrder'] && !item.accessoryOrder && this.isImportApproval) {
            const col: any = cell.$column;
            const row: any = cell.$row;

            //find if already exists on localStorage
            let itemFinded = this.localStorage.requests.find((a: any) => a.bpTeamId.newValue == row.team.id && a.accessoryOrder == col.accessoryOrder);
            if (itemFinded) {
                itemFinded.quantity = {currentValue: null, newValue: item.quantity, rawValue: item.quantity, errors: [], updated: true};
            }
            else {
                let newItem = {
                    typeOfChanges : ['UPDATE_QUANTITY'],
                    bpTeamId : {currentValue: row.team.id, newValue: row.team.id, rawValue: row.team.id, errors: Array(0), updated: false},
                    accessoryId:  col.accessoryId,
                    accessoryOrder :  col.accessoryOrder,
                    accessoryAlias : col.name,
                    accessoryType :  col.revision.name,
                    additionalComment : {errors: [], updated: true},
                    quantity : {currentValue: null, newValue: item.quantity, rawValue: item.quantity, errors: [], updated: true},
                    usersToNotify : item.usersToNotify,
                    updated:true
                };
                this.localStorage.requests.push(newItem);
            }
            this.sendNewAccRequests.emit(this.localStorage);
        }

        if ((cell.$column as any)['configurationOrder'] && !item.configurationOrder && item.quantity > 0 && this.isImportApproval) {
            const col: any = cell.$column;
            const row: any = cell.$row;

            //find if already exists on localStorage
            let itemFinded = this.localStorage.requests.find((a: any) => a.bpTeamId.newValue == row.team.id && a.configurationOrder == col.configurationOrder);
            if (itemFinded) {
                itemFinded.quantity = {currentValue: null, newValue: item.quantity, rawValue: item.quantity, errors: [], updated: true};
            }
            else {
                let newItem ={
                    typeOfChanges : ['UPDATE_QUANTITY'],
                    bpTeamId: {currentValue: row.team.id, newValue: row.team.id, rawValue: row.team.id, errors: [], updated: false},
                    configurationId: col.idConfiguration == 0 ? undefined : col.idConfiguration,
                    configurationOrder: col.configurationOrder,
                    configurationAlias: col.name,
                    hwRevision: col.revision.name,
                    quantity: {newValue: item.quantity, rawValue: item.quantity, errors: [], updated: true},
                    additionalComment: {errors: [], updated: false},
                    newHwSpec: {currentValue: false, newValue: false, errors: [], updated: false},
                    specialRequirementList: {currentValue: [],newValue: [],rawValue: '', errors: [],updated: false},
                    usersToNotify : item.usersToNotify,
                    updated: true
                };
                this.localStorage.requests.push(newItem);
            }
            this.sendNewAccRequests.emit(this.localStorage);
        }
    }

    undoCellValue(column: C, row: R): void{
        const colObj:any=this.getColumnObject(column, row);
        const cell = ProtoRequestTableComponent.deepCopy<IProtoRequestCell<C, R>>(
            colObj
        );
        const originalData = JSON.parse(this.localStorageHelper.getItem(this.originalData));
        let quantity='0';

        try {
            const btTeamId = colObj.$row['team']['id'];
            const dataIndex = originalData.findIndex(
                (data: any) =>{
                    return data['team']['id'] === btTeamId;}
            );

            const requestIndex = (originalData[dataIndex] as any)['requests'].findIndex(
                (req: any) =>{
                    if(cell.item['accessoryOrder']){
                        return req['bpTeamId']['currentValue'] === btTeamId && req['accessoryOrder'] === cell.item['accessoryOrder'];
                    }else{
                        return req['bpTeamId']['currentValue'] === btTeamId && req['configurationOrder'] === cell.item['configurationOrder'];
                    }
                }
            );
            if (requestIndex>=0){
                (this.data[dataIndex] as any)['requests'][requestIndex] = (originalData[dataIndex] as any)['requests'][requestIndex];

                quantity = (originalData[dataIndex] as any)['requests'][requestIndex].quantity.newValue;
            }


        } catch (error) {
            quantity = '0';
            const index = this.localStorage.requests.findIndex((req : any) => {
                if(req.accessoryOrder){
                    return req.accessoryOrder === (cell.$column as any).accessoryOrder && req.bpTeamId.currentValue === (cell.$row as any).team.id;
                }else{
                    return req.configurationOrder === (cell.$column as any).configurationOrder && req.bpTeamId.currentValue === (cell.$row as any).team.id;
                }
            });

            this.localStorage.requests[index].quantity.newValue = 0;
            this.localStorage.requests[index].quantity.rawValue = 0;
        }

        const item: any = cell.item;
        item.quantity = parseInt(quantity);
        this.onSelectionChange([cell]);
        delete this.editable;
        delete this.inputable;

        this.saveContent(cell);
    }

    private saveCurrentData(){
        if(this.localStorageHelper.getItem(this.StorageKey)){
            this.localStorageHelper.setItem(
                this.tempStorageRequests,
                this.localStorageHelper.getItem(this.StorageKey));
        }

        this.localStorageHelper.setItem(
            this.tempStorageEdited,
            JSON.stringify(this.edited));

        this.localStorageHelper.setItem(
            this.currentData,
            JSON.stringify(this.data));
    }

    /**
     * Check if the cell is checked using the column Object and row number.
     * @param column
     * @param row
     */
    isEditedCell(column: C, row: R): boolean {
        return this.getCellIndex(column, row) in this.edited;
    }

    /**
     * Undo edition. If the undo action has triggered into selected cell
     * it must be toggled twice to angular recognize the object change.
     * @param event
     * @param column
     * @param row
     */
    undoEdition(event: MouseEvent | null, column: C, row: R): void {
        if(this.isImportApproval){
            event && event.stopImmediatePropagation();
            this.undoCellValue(column,row);
            delete this.edited[this.getCellIndex(column, row)];
            if (this.isSelected(column, row)) {
                this.toggleCellSelect(column, row);
            }
            this.saveCurrentData();
        }else{
            event && event.stopImmediatePropagation();
            if (!this.hasChanges) {
                this.undoAll();
            } else {
                this.undoStorage(column, row);
            }
            if (this.isSelected(column, row)) {
                this.toggleCellSelect(column, row);
            }
            delete this.edited[this.getCellIndex(column, row)];
        }
    }

    isEditingCell(column: C, row: R): boolean {
        return this.protoRequestTableService.isEditingCell(column, row, this.editable);

    }

    showHideTotalBoards(): void {
        this.isTotalBoardsVisible = !this.isTotalBoardsVisible;
    }

    showHideHeaderInfo(): void {
        this.isHeaderInfoVisible = !this.isHeaderInfoVisible;
        this.showHeaderInfo.emit(this.isHeaderInfoVisible);
        this.cdr.detectChanges();
        this.tableHeaderOffsetHeight = this.tableHeaderElement?.nativeElement?.offsetHeight || 0;
    }

    showHideExpandedTotalPortable(){
        this.isTotalPortableExpanded = !this.isTotalPortableExpanded;
    }

    showHideExpandedTotalBoards(){
        this.isTotalBoardsExpanded = !this.isTotalBoardsExpanded;
    }

    isCanceled(column: C): boolean {
        return this.protoRequestTableService.isCanceled(column);
    }

    private verifyTableLimits(lastCol: number, lastRow: number, range: RangeCell) {
        const totalCols = this.headers.length;
        const totalRows = this.data.length;

        const exceededTableLimits =
            lastRow < 0 ||
            lastCol < 0 ||
            lastRow + (range.maxRow - range.minRow) + 1 > totalRows ||
            lastCol + (range.maxCol - range.minCol) + 1 > totalCols;

        return exceededTableLimits;
    }

    private getRangeCells(contentCopy:any): RangeCell {
        const rowsIndex = Object.keys(contentCopy).map((s) => Number(s));
        const colsIndex = rowsIndex.reduce(
            (acc, s) => acc.concat(<never[]>Object.keys(contentCopy[s])),
            []
        );

        const maxRow = Math.max(...rowsIndex);
        const minRow = Math.min(...rowsIndex);
        const minCol = Math.min(...colsIndex);
        const maxCol = Math.max(...colsIndex);
        return { maxRow, minRow, maxCol, minCol };
    }

    private getValRow(item:any):string{
        const data=this.getCellIndex(item,'a');
        return data.split('-')[0];
    }
    private geValCol(item:any):string{
        const data=this.getCellIndex('a',item);
        return data.split('-')[1];
    }

    private getRangeByType(type:'col'|'row'): MinMaxItem {
        let rowsIndex:any[]=[];
        if (type=='col'){
            rowsIndex = this.columnSelectionModel.selected.map((s) => {
                const idConfiguration=s.split('-')[0];
                return  this.headers.findIndex((e:any)=> this.getValRow(e)==idConfiguration);
            }
            );
        }else{
            rowsIndex = this.rowSelectionModel.selected.map((s) => {
                const idTeam=s.split('-')[1];
                return  this.data.findIndex((e:any)=>this.geValCol(e)==idTeam);
            }
            );
        }
        const max = Math.max(...rowsIndex);
        const min= Math.min(...rowsIndex);
        return { max, min };
    }




    private isReqSelected(): boolean {
        return this.selectedData.some(
            (req: IProtoRequestCell<any, any, { quantity: number }>) => req.item.quantity != null
        );
    }

    private getReferencedCell(): {
        rowIndex: number;
        colIndex: number;
        } {
        const selected = this.cellIndexSelectionModel.selected;
        let [firstCol, firstRow] = selected[0].split('-').map((s) => Number(s));
        if (selected.length>1){
            let cols = selected.map(e=> e.split('-').map((s) => Number(s))[0]);
            let rows = selected.map(e=> e.split('-').map((s) => Number(s))[1]);
            firstCol = Math.min(...cols);
            firstRow = Math.min(...rows);
        }
        return { rowIndex: firstRow, colIndex: firstCol };
    }

    private copyCell(copiedCell: IProtoRequestCell<C, R, any>, cell: IProtoRequestCell<C, R, any>) {

        cell.item['quantity'] = copiedCell.item['quantity'];
        if (copiedCell.item['specialRequirements']) {
            cell.item['specialRequirementList'] = [...copiedCell.item['specialRequirements']];
        }else if (copiedCell.item['specialRequirementList']) {
            const specialRequirementList=copiedCell.item['specialRequirementList'].map((e:any)=>{
                if(!('specialRequirement' in e) && !('specialRequirementsValue' in e))
                {
                    return  {
                        specialRequirement:{id:e.idSpecialRequirement,fieldName:e.fieldName},
                        specialRequirementsValue:{id:e.idSpecialRequirementValue},
                        value:e.value
                    };
                }else{
                    return e;
                }
            }
            );
            cell.item['specialRequirementList'] = [...specialRequirementList];
        }

        if (copiedCell.item['justifications']) {
            cell.item['justifications'] = { ...copiedCell.item['justifications'] };
        }

        if(copiedCell.item['usersToNotify']) {
            cell.item['usersToNotify'] = copiedCell.item['usersToNotify'];
        }

        const currentUser = this.userPermissionsService.getInformationUser();

        cell.item['user'] = {
            name: currentUser?.name,
            lastName: currentUser?.lastName,
            email: currentUser?.email
        };
        cell.item['requestDate'] = new Date();
        cell.item['additionalComments'] = copiedCell.item['additionalComments'];
        this.onSelectionChange([cell]);
        this.saveContent(cell);
    }

    @HostListener('window:resize')
    public onResize() {
        this.measureViewPortSize();
        this.updateView(false);
    }

    @HostListener('window:storage')
    private onStorage() {
        this.contentCopy = this.localStorageHelper.getContentCopy();
    }

    private reduceGroups({ first, last }: { first: number; last: number }) {
        this._groups = this.protoRequestTableService.reduceGroups({ first, last }, this._headers);
    }

    /**
     * Measure content size, used to force scrollbars
     * @private
     */
    private measureContentSize() {
        this.content = {
            height: this.tableHeight * this.cellSize.height,
            width: this.tableWidth * this.cellSize.width
        };
    }

    /**
     * Measure view port size and set to viewPortCapacity property
     * @private
     */
    private measureViewPortSize() {
        const viewPort = this.viewPortSize;
        const horizontal = Math.ceil(viewPort.width / this.cellSize.width);
        const vertical = Math.ceil(viewPort.height / this.cellSize.height);
        this.viewPortCapacity = { horizontal, vertical };
        this.buffer = {
            horizontal: this.horizontalBuffer || Math.floor(this.viewPortCapacity.horizontal / 2),
            vertical: this.verticalBuffer || Math.floor(this.viewPortCapacity.vertical / 2)
        };
    }

    private measureStackSize() {
        this.stack = {
            horizontal: this.viewPortCapacity.horizontal,
            vertical: this.viewPortCapacity.vertical
        };
    }

    private static deepCopy<T>(el: T): T {
        return cloneDeep(el);
    }

    /**
     * Measure first and last item based on the scroll position
     * e.g.: getRange(AXIS.X) return first and last position of columns
     * @param scrollPosition scroll Position
     * @param lastOfStack :number
     * @param itemDimension :number
     * @param capacity :number
     */
    private static getRange(
        scrollPosition: number,
        lastOfStack: number,
        itemDimension: number,
        capacity: number,
    ): ProtoRequestTableRange {
        let first = Math.floor(scrollPosition / itemDimension);

        if (first < 0) {
            first = 0;
        }

        let last = first + capacity;
        if (last > lastOfStack) {
            first = lastOfStack - capacity;
            if (first < 0) {
                first = 0;
            }
            last = lastOfStack;
        }

        return { first, last};
    }

    private getUpdatedValues(){
        if(this.localStorageHelper.getItem(this.tempStorageEdited)){
            this.edited = JSON.parse(this.localStorageHelper.getItem(this.tempStorageEdited));
        }
        if(this.localStorageHelper.getItem(this.tempStorageRequests)){
            this.localStorage = JSON.parse(this.localStorageHelper.getItem(this.tempStorageRequests))[0];
        }
    }

    private populateLocalStorageRequests() {
        const lista = this.data.flatMap((v: any) => v.requests);
        this.localStorage.requests = [];
        this.localStorage.requests = lista;
        this.saveStorage(this.localStorage);
    }

    onKeyPressCell(event: any, column: C, row: R, inputEl: HTMLInputElement): void {
        if (event.key === 'Enter') {
            this.onSaveCellValue(column, row, inputEl);
            return;
        }
    }

    onKeyDownCell(event: any, cell: IProtoRequestCell<C, R>, elRef?: HTMLTableCellElement): void {
        if (event.key === 'Escape') {
            this.toggleCellState(cell, elRef);
        }

    }

    onBlurCell(event: any, inputEl: HTMLInputElement): void {
        this.inputable = inputEl;
    }

    sortIProtoRequestHeader(a: IProtoRequestHeader, b: IProtoRequestHeader) {
        if (a.configurationOrder && b.configurationOrder) {
            return a.configurationOrder < b.configurationOrder ? -1 : 1;
        } else {
            return -1;
        }
    }

    sortIAcessoryRequestTeam(a: IAccessoryRequestTeam, b: IAccessoryRequestTeam) {
        if (a.accessoryOrder && b.accessoryOrder) {
            return a.accessoryOrder < b.accessoryOrder ? -1 : 1;
        } else {
            return -1;
        }
    }

    stringKeytoNumber = (a: any, b: any): number => {
        if (a.key && b.key) {
            return Number(a) < Number(b) ? -1 : 1;
        } else {
            return -1;
        }
    };

    onChangeQuick(event:MatSlideToggleChange){
        this.checkedQuickAction=event.checked;
        this.changeQuickAction.emit(event.checked);
    }

    setPortableGroups(subtotalsGroups: string[]){
        this.portableGroups = subtotalsGroups.filter(r => !r?.includes('EVB') && r!.toUpperCase().indexOf('BOARD') == -1);
    }
    setBoardsGroups(subtotalsGroups: string[]){
        this.boardGroups = subtotalsGroups.filter(r => r?.includes('EVB') || r!.toUpperCase().indexOf('BOARD') > -1);
    }
    getSizeTotals(){
        let size = 1 + (this.getBooleanValue(this.isTotalBoardsVisible) +
        (this.getBooleanValue(this.isTotalPortableExpanded)*this.portableGroups.length) +
        (this.getBooleanValue(this.isTotalBoardsExpanded && this.isTotalBoardsVisible)*this.boardGroups.length))*
        this.getBooleanValue(!this.isTotalAccessories);
        return [...Array(size).keys()];
    }
    getBooleanValue(bool: boolean){
        if(bool==true){
            return 1;
        }
        else{
            return 0;
        }

    }
    setTotalsGroups(){
        let hwRevGroups: any = [];
        this.headers.forEach(r=> {
            if(!(hwRevGroups.includes(Object(r).revision.name.toUpperCase()))){
                hwRevGroups.push(Object(r).revision.name.toUpperCase());
            }});
        this.setBoardsGroups(hwRevGroups);
        this.setPortableGroups(hwRevGroups);
    }
}
