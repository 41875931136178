//Angular
import { Component, Input } from '@angular/core';
import {
    EBuildPlanStatusColor,
    EBuildPlanStatusLabel,
    IBuildPlan
} from '@galvin/core/build-plan/management';
import { EBuildPlanStatusColorkey } from '@galvin/core/build-plan/management/enums/build-type-status-color.enum';
import { EBuildPlanStatusLabelKey } from '@galvin/core/build-plan/management/enums/build-type-status-label.enum';
import { TranslateService } from '@ngx-translate/core';
import { checkNullPipe } from './../../../../core/_base/layout/pipes/check-null.pipe';
import { ParseDatePipe } from './../../../../core/_base/layout/pipes/parse-date.pipe';
import { ParseDateTimePipe } from './../../../../core/_base/layout/pipes/parse-datetime.pipe';

@Component({
    selector: 'build-plan-information',
    exportAs: 'buildPlanInformation',
    templateUrl: './build-plan-information.component.html',
    styleUrls: ['./build-plan-information.component.scss']
})
export class BuildPlanInformationComponent {
    @Input() buildPlan!: IBuildPlan;
    @Input() displayDates!: boolean;
    @Input() displayLockdown = true;

    constructor(
        private translate: TranslateService,
        public checkNull: checkNullPipe,
        public parseDateTime: ParseDateTimePipe,
        public parseDate: ParseDatePipe
    ) {}

    /**
     * Returns a CSS class to be used on the view.
     */
    getStatusCSSClass(): string {
        const key = Object.keys(EBuildPlanStatusLabel).find(
            (key) =>
                EBuildPlanStatusLabel[key as EBuildPlanStatusLabelKey] === this.buildPlan.status
        );
        return EBuildPlanStatusColor[key as EBuildPlanStatusColorkey];
    }

    isValidDate(lockDownDate: string | Date): boolean {
        return lockDownDate !== null && lockDownDate !== undefined;
    }
}
