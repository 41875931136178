import { Pipe, PipeTransform } from '@angular/core';
import { IMenuShowColumnsGroup } from '@galvin/core/build-plan/configuration-management';
import { IMenuShowColumnsColumn } from '../../menu-show-columns/menu-show-columns.component';

@Pipe({
    name: 'getDisplayColumnByGroup'
})
export class GetDisplayColumnByGroup implements PipeTransform {
    transform(group: IMenuShowColumnsGroup, columnsByGroups: Record<string, number[]>, displayColumns: IMenuShowColumnsColumn[]) {
        const columns: { value: any; key: number }[] = [];
        if (group.type in columnsByGroups) {
            columnsByGroups[group.type].forEach((i) => {
                columns.push({value: displayColumns[i], key: i});
            });
        }
        return columns;
    }
}