import { IPermissions } from '../_interfaces/permissions.interface';
import { User } from './user.model';

export class LocalStorageUser {
    email?: string;
    name?: string;
    lastName?: string;
    coreId?: string;
    picture?: string;
    domain?: string;
    active?: boolean;
    lastLogin?: Date;
    firstAccess?: Date;
    preferences?: string;
    roles?: IPermissions;
    token?: string;
    sub?: string;
    expirationDate?:string;
    qtRefreshToken?: number;
}

/**
 * Converts a LocalStorageUser to User model
 *
 * @param localStorageUser
 */
export function localStorageUserToUser(localStorageUser: LocalStorageUser): User {
    return {
        email: localStorageUser.email,
        name: localStorageUser.name,
        lastName: localStorageUser.lastName,
        coreId: localStorageUser.coreId,
        picture: localStorageUser.picture,
        domain: localStorageUser.domain,
        token: undefined,
        active: localStorageUser.active,
        lastLogin: localStorageUser.lastLogin,
        firstAccess: localStorageUser.firstAccess,
        preferences: localStorageUser.preferences,
        roles: undefined,
        expirationDate: localStorageUser.expirationDate,
        qtRefreshToken: localStorageUser.qtRefreshToken
    };
}


/**
 * Converts a User to LocalStorageUser model
 *
 * @param user
 */
export function userToLocalStorageUser(user: User): LocalStorageUser {
    return {
        email: user.email,
        name: user.name,
        lastName: user.lastName,
        coreId: user.coreId,
        picture: user.picture,
        domain: user.domain,
        active: user.active,
        lastLogin: user.lastLogin,
        firstAccess: user.firstAccess,
        preferences: user.preferences,
        token: user.token,
        sub: user.sub,
        expirationDate: user.expirationDate,
        qtRefreshToken: user.qtRefreshToken
    };
}