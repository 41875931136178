import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
    selector: '[genericFieldTemplateConfig]',
    exportAs: 'genericFieldTemplateConfig'
})
export class GenericFieldTemplateConfigDirective {
    private config!: Record<string, string>;

    constructor(private elRef: ElementRef<HTMLElement>) {}

    @Input('genericFieldTemplateConfig')
    set fieldConfig(config: Record<string, string>) {
        if (config) {
            this.config = config;
            Object.entries(this.config || {}).forEach(([prop, val]) =>
                this.elRef.nativeElement.setAttribute(prop, val)
            );
        }
    }
}
