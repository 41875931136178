<!-- begin:: Page -->
<ng-container *ngIf="selfLayout !== 'blank'; else blankLayout">
    <!-- begin:: Header Mobile -->
    <kt-header-mobile [ngClass]="{ invisible: fullScreen }" *ngIf="!fullScreen"></kt-header-mobile>
    <!-- end:: Header Mobile -->
    <kt-header-fullscreen *ngIf="fullScreen"></kt-header-fullscreen>

    <banner-information></banner-information>

    <div class="kt-grid kt-grid--hor kt-grid--root">
        <!-- begin::Body -->
        <div
            class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page"
            [ngClass]="{ 'gv-full-screen': fullScreen }"
        >
            <div
                class="padding-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper"
                id="kt_wrapper"
            >
                <!-- begin:: Header -->
                <kt-header
                    *ngIf="desktopHeaderDisplay"
                    [ngClass]="{ invisible: fullScreen }"
                ></kt-header>

                <!-- end:: Header -->

                <!-- begin:: Content -->
                <div
                    class=" kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor"
                    id="kt_content"
                    [ngClass]="{ 'kt-content--fit-top': fitTop || fullScreen }"
                >
                    <!-- begin:: Content Head -->
                    <kt-subheader
                        *ngIf="subheaderDisplay"
                        [ngClass]="{ invisible: fullScreen }"
                    ></kt-subheader>
                    <!-- end:: Content Head -->

                    <!-- begin:: Aside Left -->
                    <ng-container  *ngIf="asideDisplay">
                        <button class="kt-aside-close" id="kt_aside_close_btn">
                            <i class="la la-close"></i>
                        </button>
                        <kt-aside-left></kt-aside-left>
                    </ng-container>
                    <!-- end:: Aside Left -->

                    <!-- begin:: Content Body -->
                    <div
                        ktContentAnimate
                        class=" kt-container kt-grid__item kt-grid__item--fluid"
                        [ngClass]="{ 'kt-container--fluid': fluid }"
                    >
                        <router-outlet></router-outlet>
                    </div>
                    <!-- end:: Content Body -->

                </div>
                <!-- end:: Content -->

                <!-- begin:: Chatbot -->
                <kt-chatbot-side-panel></kt-chatbot-side-panel>
                <!-- begin:: Chatbot -->

                <floating-button></floating-button>

                <kt-footer class="height-footer"></kt-footer>
            </div>

        </div>
        <!-- end:: Body -->
    </div>
</ng-container>
<!-- end:: Page -->

<ng-template #blankLayout>
    <router-outlet></router-outlet>
</ng-template>
