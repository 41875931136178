// AUTO-GENERATED: DO NOT MODIFY

export const environment = {
    production: false,
    featureFlag: false,
    name: 'dev',
    baseUrl: '',
    baseUrlApi: '/api',
    googleClientID: '609527354320-ji6rho2kfnelb534hecppg9csmo7jjva.apps.googleusercontent.com',
    dialogAgentID: '90e900b3-6921-466e-b39c-ec4a9a545cd2',
    sha1: 'FE:ac7e7f0 BE:801b393'
};
