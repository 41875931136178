import {Injectable} from '@angular/core';
import {GenericResource, ResourceService} from '@galvin/core/_base/resource';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {
    ENotificationStatus,
    INotification,
    INotificationCreate
} from '@galvin/core/notification/notification.interface';

@Injectable({
    providedIn: 'root'
})
export class NotificationService extends ResourceService {
    private readonly LATEST_PATH = '/latest';
    private readonly TEMPLATES_PATH = '/templates';

    constructor(http: HttpClient) {
        super(http, '/galvin/notifications');
    }

    getLatest(): Observable<INotification[]> {
        return this.get(new GenericResource(this.LATEST_PATH)).pipe(
            map<GenericResource<INotification>, INotification[]>((gr) =>
                gr.content as INotification[]
            )
        );
    }

    getLastThirtyDays(startAt: Date = new Date(), endAt?: Date): Observable<INotification[]> {
        let params = new HttpParams();
        let resource = new GenericResource(this.LAST_THIRTY_DAYS_PATH(startAt));

        if (endAt) {
            params = params.append('endDate', startAt.toISOString().slice(0, 10));
            resource = new GenericResource<any>(this.LAST_THIRTY_DAYS_PATH(endAt));
        }

        return this.get(
            resource,
            {params}
        )
            .pipe(
                map<GenericResource<INotification>, INotification[]>(gr => <Array<INotification>>gr.content)
            );
    }

    dismiss(notification: INotification): Observable<INotification> {
        return this.updateStatus(notification, ENotificationStatus.DISMISSED);
    }

    getById(id: number): Observable<INotification> {
        return this.get(this.BY_ID_PATH(id)).pipe(map((n) => n.content));
    }

    maskAsSeen(notification: INotification): Observable<INotification> {
        return this.updateStatus(notification, ENotificationStatus.SEEN);
    }

    updateStatus(
        notification: INotification,
        status: ENotificationStatus
    ): Observable<INotification> {
        return this.post(new GenericResource(this.STATUS_PATH(notification.id), status)).pipe(
            tap((n) => (notification.status = n.status))
        );
    }

    listTemplates(): Observable<any[]> {
        return this.get(new GenericResource(this.TEMPLATES_PATH))
            .pipe(map(r => r.content));
    }

    create(notification: INotificationCreate) {
        const resource = new GenericResource(undefined, notification);
        return this.post(resource);
    }

    private readonly STATUS_PATH = (id: any) => `/${id}/status`;

    private readonly BY_ID_PATH = (id: any) => `/${id}`;

    private readonly LAST_THIRTY_DAYS_PATH = (date: Date) => `/paginated-by-date/${date.toISOString().slice(0, 10)}`;
}
