export enum EConfigurationStatus {
    NEW = 'NEW',
    OPEN = 'OPEN',
    LOCKED = 'LOCKED',
    BUILDING = 'BUILDING',
    APPROVED = 'APPROVED',
    MANUFACTURED = 'MANUFACTURED',
    IN_TRANSIT = 'IN_TRANSIT',
    COMPLETED = 'COMPLETED',
    ARCHIVED = 'ARCHIVED',
    CANCELED = 'CANCELED'
}

export enum EConfigurationStatusLabel {
    NEW = 'New',
    OPEN = 'Open',
    LOCKED = 'Locked',
    BUILDING = 'Building',
    APPROVED = 'Approved',
    MANUFACTURED = 'Manufactured',
    IN_TRANSIT = 'In Transit',
    COMPLETED = 'Completed',
    ARCHIVED = 'Archived',
    CANCELED = 'Canceled'
}

export enum EConfigurationStatusLabelKey {
    New = 'NEW',
    Open = 'OPEN',
    Locked = 'LOCKED',
    Approved = 'APPROVED',
    Manufactured = 'MANUFACTURED',
    'In Transit' = 'IN_TRANSIT',
    Completed = 'COMPLETED',
    Archived = 'ARCHIVED',
    Canceled = 'CANCELED'
}

export enum EAccessoryStatus {
    NEW = 'NEW',
    OPEN = 'OPEN',
    LOCKED = 'LOCKED',
    READY_TO_DELIVER = 'READY_TO_DELIVER',
    IN_TRANSIT = 'IN_TRANSIT',
    COMPLETED = 'COMPLETED',
    CANCELED = 'CANCELED'
}
