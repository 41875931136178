import { Injectable } from '@angular/core';
import { MatSlideToggle } from '@angular/material/slide-toggle';

@Injectable({
    providedIn: 'root'
})
export class ToggleService {
    private toggle!: MatSlideToggle;

    public setToggle(toggle: MatSlideToggle): void {
        this.toggle = toggle;
    }

    public getToggle(): MatSlideToggle {
        return this.toggle;
    }

    public destroyToggle(): void {
        this.toggle.ngOnDestroy();
    }
}
