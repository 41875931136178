import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
//Services
import {ResourceService} from '@galvin/core/_base/resource';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {
    ISkuMatrixManagement,
    ISkuMatrixManagementVO
} from '../interfaces/sku-matrix-management.interface';
import {SkuMatrixManagementResource} from './sku-matrix-management-resource';

@Injectable({
    providedIn: 'root'
})
export class SkuMatrixManagementService extends ResourceService {
    private skuResource = new SkuMatrixManagementResource();

    private readonly URL_BASE = '/build-plan/build-plan-management/sku-matrix';

    constructor(http: HttpClient) {
        super(http);
    }

    getAllSkuMatrix(idBuildPlan: number): Observable<ISkuMatrixManagement[]> {
        this.skuResource._links['self'] = {
            href: `${this.URL_BASE}/${idBuildPlan}`
        };
        return this.get<ISkuMatrixManagement[], SkuMatrixManagementResource>(this.skuResource).pipe(
            map((response) => {
                return response.content;
            })
        );
    }

    getSkuMatrixByBPId(idBuildPlan: number): Observable<ISkuMatrixManagement> {
        this.skuResource._links['self'] = {
            href: `${this.URL_BASE}/${idBuildPlan}`
        };
        return this.get<ISkuMatrixManagement, SkuMatrixManagementResource>(this.skuResource).pipe(
            map((response) => {
                return response.content;
            })
        );
    }

    getSkuMatrixbyId(skuId: number): Observable<ISkuMatrixManagement[]> {

        this.skuResource._links['self'] = {
            href: `${this.URL_BASE}/find-by-id/${skuId}`
        };
        return this.get<ISkuMatrixManagement[], SkuMatrixManagementResource>(this.skuResource).pipe(
            map((response) => {
                return response.content;
            })
        );
    }

    createSkuMatrix(sku: ISkuMatrixManagementVO): Observable<ISkuMatrixManagement> {
        this.skuResource._links['self'] = {
            href: this.URL_BASE
        };
        this.skuResource.content = sku;
        return this.post<ISkuMatrixManagementVO, SkuMatrixManagementResource>(this.skuResource).pipe(
            map((response) => {
                return response.content;
            })
        );
    }

    updateSkuMatrix(sku: ISkuMatrixManagementVO): Observable<ISkuMatrixManagement> {
        this.skuResource._links['self'] = {
            href: this.URL_BASE
        };
        this.skuResource.content = sku;
        return this.put<ISkuMatrixManagementVO, SkuMatrixManagementResource>(this.skuResource).pipe(
            map((response) => {
                return response.content;
            })
        );
    }

    bulkDeleteSkuMatrixManagement(skuMatrixIds: number[]): Observable<any> {
        this.skuResource._links['self'] = {
            href: `${this.URL_BASE}/bulk-delete`
        };
        this.skuResource.content = {skuIds: skuMatrixIds};

        return this.post<ISkuMatrixManagement, SkuMatrixManagementResource>(this.skuResource).pipe(
            map((response) => {
                return response.content;
            })
        );
    }
}
