<ng-container *ngIf="show">
    <ngb-alert type="primary" class="banner-info justify-content-between" [dismissible]="false">
        <div class="d-flex align-items-center w-100">
            <i class=" mr-2 fa fa-info-circle text-primary"></i>
            <div class="content-info text-justify" [ngClass]="{'text-truncate':!collapsed}" #contentInfo
                [innerHtml]="content"></div>
            <a class="text-primary font-weight-bold cursor-pointer align-self-end" *ngIf="isBigText"
                (click)="collapsed = !collapsed">
                <ng-container *ngIf="!collapsed">
                    {{'COMMON.READ_MORE'|translate}}
                </ng-container>
                <ng-container *ngIf="collapsed">
                    {{'COMMON.SHOW_LESS'|translate}}
                </ng-container>
            </a>
        </div>
        <i class="ml-2 fa fa-times text-primary cursor-pointer" (click)="closed()"></i>
    </ngb-alert>
</ng-container>