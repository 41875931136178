<title-expanded [title]="labels.COMMON.FACTORY" [expanded]="expanded" (expandedOut)="expanded = $event">
</title-expanded>
<div class="d-flex flex-column mr-4" id="factory-container" [@detailExpand]="expanded  ? 'expanded' : 'collapsed'">
    <ul class="p-0 d-flex flex-column flex-wrap h-100 mt-4 justify-content-center">
        <li *ngFor="let item of items" class="d-flex justify-content-between align-items-center mb-3 mr-3 width-200">
            <span [id]="getId(item.id,'param')" container="galvin-dialog-generic" [ngbTooltip]="item.label"
                class=" text-default text-normal property-text">
                {{item.label}}
            </span>
            <div
                class="d-flex justify-content-center align-items-center tag-prototypes color-dark-gray width-min-width-36-perc">
                <span [id]="getId(item.id,'value')" container="galvin-dialog-generic" [ngbTooltip]="item.value"
                    class="text-normal color-dark-gray">
                    {{item.value}}
                </span>
            </div>
        </li>
    </ul>
</div>
