import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {IScopeManagement} from '../../../scope-management/interfaces/scope-management.interface';
import {GenericResource, ResourceService} from '../../../../../core/_base/resource';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {IBuildPlanScopeAdd} from '../interfaces/build-plan-scope-add.interface';
import {IBuildPlanScopeAssociationValue} from '../interfaces/build-plan-scope-association.interface';
import {IBuildPlanScopeDelete} from '../interfaces/build-plan-scope-delete.interface';
import {IScopeScopeValue} from './../../../scope-management/interfaces/scope-scope-value.interface';

export interface IBuildPlanScope {
    id: number;
    name: string;
    scopeOrder: number;
    labels: string[];
    enabled: boolean;
    scopeValueList: IScopeScopeValue[];
    checked?: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class BuildPlanScopeAssociationService extends ResourceService {
    constructor(http: HttpClient) {
        super(http);
    }

    override get uri(): string {
        return `${this.baseUrl}/build-plan/build-plan-management/${this._buildPlanId}/scope-association`;
    }

    private _buildPlanId!: number;

    set buildPlanId(v: number) {
        this._buildPlanId = v;
    }

    getAll(buildPlanId?: number): Observable<GenericResource<any>> {
        if (buildPlanId) {
            this.buildPlanId = buildPlanId;
        }
        return this.get('');
    }

    getScopesByTeams(teamIds: number[]): Observable<IBuildPlanScope[]> {
        return this.post(new GenericResource('/by-teams', teamIds)).pipe(
            map((response) => {
                return response.content;
            })
        );
    }

    updateScopeValueAssociationByType(
        buildPlanId: number,
        scopeAssociationId: number,
        associationValue: IBuildPlanScopeAssociationValue
    ): Observable<GenericResource<IScopeManagement>> {
        this.buildPlanId = buildPlanId;
        const resource = new GenericResource(
            `/${scopeAssociationId}/update-by-type`,
            associationValue
        );
        return this.post(resource);
    }

    addScope(
        scopeId: number,
        params: IBuildPlanScopeAdd
    ): Observable<GenericResource<IScopeManagement>> {
        return this.post(new GenericResource(`/${scopeId}`, params));
    }

    removeScopes(scopes: IBuildPlanScopeDelete): Observable<void> {
        const generic = new GenericResource<IBuildPlanScopeDelete>(undefined);
        generic.content = scopes;

        return this.put(generic);
    }
}
