import { isEmpty } from 'lodash';
import { IGalvinUser } from './../../../auth/_interfaces/galvin-user.interface';
// Angular
import { Pipe, PipeTransform } from '@angular/core';

/**
 * Returns quantity from array request
 */
@Pipe({
    name: 'galvinUser'
})
export class GalvinUserPipe implements PipeTransform {
    /**
     * Transform
     *
     * @param user: IGalvinUser
     * @param placeholder: the string will return
     */
    transform(user: IGalvinUser | undefined, placeholder = '--'): string {
        if (isEmpty(user) || user == undefined) return placeholder;
        if (isEmpty(user.email)) return placeholder;
        if (isEmpty(user.name)) return user.email;
        if (isEmpty(user.lastName)) return user.name;
        return `${user.name} ${user.lastName}`;
    }
}
