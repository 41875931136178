<section class="popover-container">
    <div class=" d-flex justify-content-between" *ngIf="showRequestDate">
        <div class="d-flex align-items-center">
            <mat-icon class="d-flex align-items-center user-icon">person</mat-icon>
            <span class="lead text-wrap font-weight-bold">
                {{userFullName}}
            </span>
        </div>
    </div>
    <div class="d-flex mt-2 text-wrap" *ngIf="showRequestDate">
        <i>{{data.requestDate | date: formatDate }}</i>
    </div>
    <div class="d-flex mt-3" *ngIf="showRequestDate">
        <blockquote class="blockquote">
            <p class="mb-0 text-right" [innerHTML]="mentionsHighlight(data.additionalComments || $any(data).comment)"></p>
        </blockquote>
    </div>

    <ng-container *ngIf="hasSpecialRequirements(data)">
        <div class="d-inline-flex align-items-center mb-2">
            <mat-icon class="d-flex align-items-center">flash_on</mat-icon>
            <div class="d-flex align-items-center">
                <span class="lead text-wrap font-weight-bold">
                    {{labels.SPECIAL_REQUIREMENT}}
                </span>
            </div>
        </div>
        <div class="special-requirement-list">
            <div *ngFor="let spr of data.specialRequirementList;">
                <strong>{{spr.fieldName}}:</strong> <span class="text-justify"> {{spr.value}}
                </span>
            </div>
        </div>
    </ng-container>
</section>