/* eslint-disable @angular-eslint/no-output-on-prefix */
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { ERolesBuildPlan } from '@galvin/core/build-plan/permission-management';
import { RolesComponent } from '@galvin/views/pages/build-plan/build-plan-permissions';
import { TranslateService } from '@ngx-translate/core';
import { UserPermissionsService } from '../../../../../core/auth/services/user-permissions.service';
import { ToggleService } from './toggle.service';

@Component({
    selector: 'app-toggle-component',
    templateUrl: './toggle.component.html',
    styleUrls: ['./toggle.component.scss']
})
export class ToggleComponent {
    @ViewChild('toggle') toggleElement!: MatSlideToggle;

    @Output() onChangeToggleEvent: EventEmitter<any> = new EventEmitter();

    @Input() checkedToggle!: boolean;

    @Input() disableToggle = false;

    @Input() checkSuperUserPermission = false;

    @Input() messageToolTip!: string;

    @Input() buildPlanPermittedRoles!: ERolesBuildPlan[];

    constructor(
        private userPermissionService: UserPermissionsService,
        private toggleService: ToggleService,
        private translate: TranslateService
    ) {}

    public sendEventToParent(): void {
        this.toggleService.setToggle(this.toggleElement);
        this.onChangeToggleEvent.emit();
    }

    getPermission(): boolean {
        if (this.disableToggle) {
            return false;
        }
        if (this.checkSuperUserPermission) {
            return this.userPermissionService.verifySuperAdminPermission();
        }

        if (this.buildPlanPermittedRoles) {
            return this.userPermissionService.verifyPermissionsForBuildPlan(
                this.buildPlanPermittedRoles
            );
        }
        return this.userPermissionService.verifyPermissionsForBuildPlan(RolesComponent.BP_ADMIN);
    }

    getToolTip(): string {
        if (this.disableToggle) {
            return this.translate.instant('COMMON.MANDATORY_TOGGLE_ENABLED');
        }
        return this.getPermission()
            ? this.messageToolTip
            : this.translate.instant('COMMON.USER_WITHOUT_PERMISSION');
    }
}
