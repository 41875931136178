export enum ERequestChangeType {
    INCREASE = 'INCREASE',
    DECREASE = 'DECREASE',
    EQUAL = 'EQUAL'
}

export enum ERequestApprovalStatus {
    ALL = 'ALL',
    APPROVED = 'APPROVED',
    PENDING = 'PENDING',
    REJECTED = 'REJECTED',
    CANCELLED = 'CANCELLED'
}
