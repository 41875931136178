import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {
    ConfigurationUpdatedFieldsResponse,
    IConfigurationData,
    IConfigurationTableData,
    IConfigurationTableValue,
    IFVCParametersAndSources,
    IFVCSource
} from '@galvin/core/build-plan/configuration-management';
import {
    IConfigurationUpdateMultipleColumns
} from '@galvin/core/build-plan/configuration-management/interfaces/configuration-table-value.interface';
import {
    ConfigurationsManagementResource
} from '@galvin/core/build-plan/configuration-management/services/configurations-management-resource';
import {GenericResource, ResourceService} from '@galvin/core/_base/resource';
import {
    ConfigurationFilterService
} from '@galvin/views/pages/build-plan/build-plan-management/configurations/configurations-filters/configurations-filters.service';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {map} from 'rxjs/operators';
import {IConfiguration} from '../interfaces/configuration.interface';
import {IBPOnlyFVCS} from '../interfaces/fvc-value-relation.interface';
import {IFvcParameter} from '@galvin/core/build-plan/field-value-management';
import { IGroupId } from '@galvin/views/partials/layout/table-sort/table-config-sort/table-config-sort/table-config-sort.component';
import { LocalStorageHelper } from '@galvin/core/storage/local-storage-helper.service';
import { IBuildPlan } from '../../management/interfaces/build-plan.interface';

@Injectable({
    providedIn: 'root'
})
export class ConfigurationsManagementService extends ResourceService {
    resetDateDefault$ = new Subject();
    configSortingGroups = new BehaviorSubject<IGroupId[]>([]);
    configSortingGroups$ = this.configSortingGroups.asObservable();
    private configurationManagementResource = new ConfigurationsManagementResource();
    private BP_LOCAL_STORAGE_KEY = 'build_plan_configuration';
    private loadBuildPlan = new Subject<IBuildPlan>();

    loadBuildPlan$ = this.loadBuildPlan.asObservable();

    constructor(http: HttpClient, private configurationFilterService: ConfigurationFilterService) {
        super(http);
    }

    public setConfigSortingGroups(groups: IGroupId[]) {
        this.configSortingGroups.next(groups);
    }

    /* get configuration by build plan id */
    public getConfigurationByBuildPlan(id: number): Observable<IConfiguration[]> {
        this.configurationManagementResource._links.self = {
            href: `/build-plan/configuration-management/build-plan/${id}`
        };
        return this.get<IConfiguration[], ConfigurationsManagementResource>(
            this.configurationManagementResource
        ).pipe(
            map((response) => {
                return response.content;
            })
        );
    }

    public getAllConfigurationByBuildPlan(id: number): Observable<IConfigurationData> {
        this.configurationManagementResource._links.self = {
            href: `/build-plan/configuration-management/build-plan/${id}/table`
        };
        return this.get<IConfigurationData, ConfigurationsManagementResource>(
            this.configurationManagementResource
        ).pipe(
            map((response) => {
                return response.content;
            })
        );
    }

    public getAllConfigurationByBuildPlanToConfigTab(id: number): Observable<IConfigurationData> {
        this.configurationManagementResource._links.self = {
            href: `/build-plan/configuration-management/build-plan/${id}/configuration-table`
        };
        return this.get<IConfigurationData, ConfigurationsManagementResource>(
            this.configurationManagementResource
        ).pipe(
            map((response) => {
                return response.content;
            })
        );
    }

    /* get options by datatype */
    public getOptions(type: string, columnId: string, buildPlanId: number): Observable<any> {
        this.configurationManagementResource._links.self = {
            href: `/build-plan/configuration-management/build-plan/${buildPlanId}/type/${type}/column/${columnId}/values`
        };
        return this.get<any, ConfigurationsManagementResource>(
            this.configurationManagementResource
        ).pipe(
            map((response) => {
                return response.content;
            })
        );
    }

    /**
     * Get configuration status options by configuration ID
     * @param configurationId configuration
     * @param type column type
     * @param columnId column ID
     */
    public getOptionsConfigStatus(
        configurationId: number,
        type: string,
        columnId: string
    ): Observable<any> {
        this.configurationManagementResource._links.self = {
            href: `/build-plan/configuration-management/${configurationId}/type/${type}/column/${columnId}/value`
        };
        return this.get<any, ConfigurationsManagementResource>(
            this.configurationManagementResource
        ).pipe(
            map((response) => {
                return response.content;
            })
        );
    }

    /* get fvc parameters by datatype */
    public getFvcParametersNotRelatedToBuildPlan(idBuildPlan: number): Observable<IFvcParameter[]> {
        this.configurationManagementResource._links.self = {
            href: `/build-plan/configuration-management/build-plan/${idBuildPlan}/fvc-parameters/not-related`
        };
        return this.get<IFvcParameter, ConfigurationsManagementResource>(
            this.configurationManagementResource
        ).pipe(
            map((response) => {
                return response.content;
            })
        );
    }

    /* get fvc parameters by datatype */
    public addFvcParameters(idBuildPlan: number, body: number[]): Observable<any> {
        this.configurationManagementResource._links.self = {
            href: `/build-plan/configuration-management/build-plan/${idBuildPlan}/fvc-parameters`
        };
        this.configurationManagementResource.content = body;

        return this.put<any, ConfigurationsManagementResource>(
            this.configurationManagementResource
        ).pipe(
            map((response) => {
                return response.content;
            })
        );
    }

    /* get fvc parameters by datatype */
    public deleteFvcParameters(idBuildPlan: number, idFvcParameter: number): Observable<any> {
        this.configurationManagementResource._links.self = {
            href: `/build-plan/configuration-management/build-plan/${idBuildPlan}/fvc-parameters/${idFvcParameter}`
        };

        return this.delete<ConfigurationsManagementResource>(
            this.configurationManagementResource
        ).pipe(
            map((response) => {
                return response.content;
            })
        );
    }

    /* save configuration by Column */
    public saveConfiguration(
        configurationId: number,
        type: string,
        columnId: string,
        body: any
    ): Observable<IConfigurationTableValue> {
        this.configurationManagementResource._links.self = {
            href: `/build-plan/configuration-management/${configurationId}/type/${type}/column/${columnId}/value`
        };
        this.configurationManagementResource.content = body;
        return this.put<any, ConfigurationsManagementResource>(
            this.configurationManagementResource
        ).pipe(
            map((response) => {
                if (type === 'FVC_VALUE' || columnId === 'CONFIG_STATUS') {
                    this.configurationFilterService.setItemChanged(true);
                }
                return response.content;
            })
        );
    }

    /* apply sku matrix configuration */
    public applySkuMatrixConfigurations(
        body: any
    ): Observable<IConfigurationTableValue> {
        this.configurationManagementResource._links.self = {
            href: '/build-plan/configuration-management/applySkuMatrix'
        };
        this.configurationManagementResource.content = body;
        return this.put<any, ConfigurationsManagementResource>(
            this.configurationManagementResource
        ).pipe(
            map((response) => {
                if (response) {
                    this.configurationFilterService.setItemChanged(true);
                }
                return response.content;
            })
        );
    }


    /* save configuration by Column */
    public saveConfigurationNames(
        body: any
    ): Observable<ConfigurationUpdatedFieldsResponse> {
        this.configurationManagementResource._links.self = {
            href: '/build-plan/configuration-management/names'
        };
        this.configurationManagementResource.content = body;
        return this.put<any, ConfigurationsManagementResource>(
            this.configurationManagementResource
        ).pipe(
            map((response) => {
                return response.content;
            })
        );
    }

    /* save new configuration */
    public createNewConfiguration(idBuildPlan: number): Observable<IConfigurationTableData> {
        const configurationManagementResource = new GenericResource(
            `/build-plan/configuration-management/build-plan/${idBuildPlan}/create`
        );
        return this.post<any, ConfigurationsManagementResource>(
            configurationManagementResource
        ).pipe(
            map((response) => {
                return response.content;
            })
        );
    }

    public cloneConfiguration(
        idBuildPlan: number,
        body: any
    ): Observable<IConfigurationTableValue[]> {
        this.configurationManagementResource._links.self = {
            href: `/build-plan/configuration-management/build-plan/${idBuildPlan}/clone`
        };
        this.configurationManagementResource.content = body;
        return this.post<any, ConfigurationsManagementResource>(
            this.configurationManagementResource
        ).pipe(
            map((response) => {
                return response.content;
            })
        );
    }

    public updateMultipleConfigurationOrders(
        idBuildPlan: number,
        body: any
    ): Observable<IConfigurationTableValue[]> {
        this.configurationManagementResource._links.self = {
            href: `/build-plan/configuration-management/build-plan/${idBuildPlan}/update-many-configuration-orders`
        };
        this.configurationManagementResource.content = body;
        return this.post<any, ConfigurationsManagementResource>(
            this.configurationManagementResource
        ).pipe(
            map((response) => {
                return response.content;
            })
        );
    }

    public setConfigurationOrders(
        idBuildPlan: number,
        body: any
    ): Observable<IConfigurationTableValue[]> {
        this.configurationManagementResource._links.self = {
            href: `/build-plan/configuration-management/build-plan/${idBuildPlan}/configuration-orders`
        };
        this.configurationManagementResource.content = body;
        return this.post<any, ConfigurationsManagementResource>(
            this.configurationManagementResource
        ).pipe(
            map((response) => {
                return response.content;
            })
        );
    }

    public deleteConfiguration(idBuildPlan: number, body: number[]): Observable<any> {
        this.configurationManagementResource._links.self = {
            href: `/build-plan/configuration-management/build-plan/${idBuildPlan}`
        };
        this.configurationManagementResource.content = body;

        return this.post<any, ConfigurationsManagementResource>(
            this.configurationManagementResource
        ).pipe(
            map((response) => {
                return response.content;
            })
        );
    }

    public findPrototypes(idBuildPlan: number, ids: number[]): Observable<any> {
        this.configurationManagementResource._links.self = {
            href: `/build-plan/configuration-management/build-plan/${idBuildPlan}/with-proto-requests`
        };
        this.configurationManagementResource.content = ids;

        return this.post(this.configurationManagementResource).pipe(
            map((response) => {
                return response.content;
            })
        );
    }

    public updateMuiltipleFields(
        buildPlanID: number,
        body: IConfigurationUpdateMultipleColumns
    ): Observable<IConfigurationTableValue[]> {
        this.configurationManagementResource._links.self = {
            href: `/build-plan/configuration-management/build-plan/${buildPlanID}/edit-multiple-fields`
        };
        this.configurationManagementResource.content = body;
        return this.put<any, ConfigurationsManagementResource>(
            this.configurationManagementResource
        ).pipe(
            map((response) => {
                return response.content;
            })
        );
    }

    public updateRelativeDatesForcedByDatesDefault(buildPlanID: number, body: any) {
        this.configurationManagementResource._links.self = {
            href: `/build-plan/configuration-management/build-plan/${buildPlanID}/undo-all-forced-dates-by-date-default`
        };
        this.configurationManagementResource.content = body;

        return this.post<any, ConfigurationsManagementResource>(
            this.configurationManagementResource
        ).pipe(
            map((response) => {
                return response.content;
            })
        );
    }

    public resetAllRelativeDatesByConfiguration(buildPlanID: number, body: any) {
        this.configurationManagementResource._links.self = {
            href: `/build-plan/configuration-management/build-plan/${buildPlanID}/reset-relative-dates`
        };
        this.configurationManagementResource.content = body;

        return this.post<any, ConfigurationsManagementResource>(
            this.configurationManagementResource
        ).pipe(
            map((response) => {
                return response.content;
            })
        );
    }

    public resetRelativeDatesById(buildPlanID: number, relativeDateAssID: number) {
        this.configurationManagementResource._links.self = {
            href: `/build-plan/configuration-management/build-plan/${buildPlanID}/relative-date-association/${relativeDateAssID}/reset-relative-date`
        };
        return this.post(this.configurationManagementResource).pipe(
            map((response) => {
                return response.content;
            })
        );
    }

    /**
     * Retrieve the list FVC Parameters and values for the form selectors, and existing FVC Sources of the given Build Plan
     *
     * @param idBuildPlan id of the Build Plan to get the information from
     * @return instance of IFVCParametersAndSources
     */
    public getFVCParametersAndSources(idBuildPlan: number): Observable<IFVCParametersAndSources> {
        this.configurationManagementResource._links.self = {
            href: `/build-plan/configuration-management/build-plan/${idBuildPlan}/fvc-sources`
        };

        return this.get<IFVCParametersAndSources, ConfigurationsManagementResource>(
            this.configurationManagementResource
        ).pipe(
            map((response) => {
                return response.content;
            })
        );
    }

    /**
     * Send a list of the updated FVC Sources to save in the database
     *
     * @param buildPlanID
     * @param body list of FVC Sources to save
     * @return instance of the updated FVC Sources of the Build Plan
     */
    public saveFVCSources(buildPlanID: number, body: IFVCSource[]): Observable<IFVCSource[]> {
        this.configurationManagementResource._links.self = {
            href: `/build-plan/configuration-management/build-plan/${buildPlanID}/fvc-sources`
        };
        this.configurationManagementResource.content = body;

        return this.post<IFVCSource[], ConfigurationsManagementResource>(
            this.configurationManagementResource
        ).pipe(
            map((response) => {
                return response.content;
            })
        );
    }

    public getBPOnlyFVCs(buildPlanID: number): Observable<IBPOnlyFVCS[]> {
        this.configurationManagementResource._links.self = {
            href: `/build-plan/configuration-management/build-plan/${buildPlanID}/fvc-value-only-relation`
        };

        return this.get<IBPOnlyFVCS, ConfigurationsManagementResource>(
            this.configurationManagementResource
        ).pipe(map((response) => response.content));
    }

    public saveBPOnlyFVCs(buildPlanID: number, body: IBPOnlyFVCS[]): Observable<IBPOnlyFVCS[]> {
        this.configurationManagementResource._links.self = {
            href: `/build-plan/configuration-management/build-plan/${buildPlanID}/fvc-value-only-relation`
        };
        this.configurationManagementResource.content = body;

        return this.post<IBPOnlyFVCS, ConfigurationsManagementResource>(
            this.configurationManagementResource
        ).pipe(map((response) => response.content));
    }

    saveInLocalStorage(localStorageHelper: LocalStorageHelper, bp: IBuildPlan){
        localStorageHelper.setItem(this.BP_LOCAL_STORAGE_KEY, JSON.stringify(bp));
    }

    getBuildPlanByLocalstorage(localStorageHelper: LocalStorageHelper): IBuildPlan{
        let item = JSON.parse(localStorageHelper.getItem(this.BP_LOCAL_STORAGE_KEY)) as IBuildPlan;
        return item;
    }
}
