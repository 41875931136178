import { ILocationI18n } from './location-i18n.interface';

export const LOCATION: ILocationI18n = {
    TABLE: {
        SHORT_DESCRIPTION: 'Short Description',
        LOCATION_CODE: 'Location Code',
        COUNTRY: 'Country',
        CITY: 'City',
        ADDRESS: 'Address',
        SITE_NAME: 'Site Name',
        ZIP_CODE: 'Zip Code',
        TELEPHONE_NUMBER: 'Telephone Number',
        MAIN_CONTACT_NAME: 'Main Contact Name',
        MAIN_CONTACT: 'Main Contact',
        BUSINESS_UNIT: 'Business unit',
        BUSINESS_REGION: 'Business Region',
        ORGANIZATION: 'Organization',
        FAX: 'Fax',
        REGION: 'Region',
        GMT: 'GMT OFF SET',
        SPECIAL_INSTRUCTIONS: 'Special Instructions',
        LOCATION_TYPE: 'Location Type',
        LOCATION_ORDER: 'Location Order'
    },
    DIALOG_CREATE: {
        CREATE_TITLE: 'Creating a new Location',
        EDIT_TITLE: 'Editing the Location',
        MESSAGE_CREATED: 'Location created',
        MESSAGE_EDITED: 'Location edited',
        MESSAGE_TO_BE_RETURNED: 'With this field enabled, you will indicate that samples and accessories can return to this location',
        TO_BE_RETURNED_FIELD: 'To be returned',
    },
    COMMON: {
        BUTTONS: {
            CREATE_LOCATION: 'Add Location',
            EDIT_LOCATION: 'Edit Location'
        },
        TOOLTIP_EDIT_LOCATION: 'Edit Location',
        ERRORS: {
            LOCATION_DISABLED: 'Location is disabled'
        },
        DELETE_TITLE: 'Do you want to delete this location?',
        DELETE_CONTENT: 'This action will delete: {{name}} and could not be undone.',
        WARNING_TOOLTIP: 'The location is already being used and cannot be deleted.',
        DELETE_TOOLTIP: 'Delete location.'
    }
};
