import { PoQuotationI18n } from './po-quotation-i18n.interface';

export const PO_QUOTATION: PoQuotationI18n = {
    SELECTED_BUILD_PLAN_NOT_FOUND: 'Selected BuildPlan not found.',
    TABLE: {
        NO_DATA: 'No data found for the selected Build Plan',
        MANUALLY_ADDED_ENTRY: 'Manually added entry',
        MANUAL_COLOR: 'MANUAL_COLOR',
        BUFFER_ENTRY: 'Buffer',
        BUFFER_COLOR: 'BUFFER_COLOR',
        CLEAR_COLUMN_FILTERS: 'Clear all column input filters',
        TABLE_NO_DATA: 'No data found for the given filter inputs',
        FILTER: 'Filter',
        PO_TYPE: 'Type',
        HW_REVISION: 'HW Revision / Accessory',
        BUILD_SITE: 'Build Site',
        SKU: 'SKU',
        PART_NUMBER: 'Part Number',
        PO_TOTAL_QTY: 'PO Total Qty',
        TOTAL_REQUESTS: 'Total Requests',
        PLAN_TO_SHIP: 'Plan to Ship',
        CDC_LOCATION: 'CDC Location',
        TRAKING_TO: 'mTRAC to',
        BUSINESS_UNIT: 'BU/Non BU',
        SMART_PR: 'SMART PR',
        PO_NUMBER: 'Order #',
        UNIT_PRICE_RMB: 'Unit Price (RMB)',
        UNIT_PRICE_USD: 'Unit Price ($)',
        PO_COST_TOTAL: 'PO cost total ($)',
        CHARGE_CODE: 'Charge code',
        N_A: 'N/A',
        PASTE_VALUES: 'Paste values here',
        TYPE_PARAMETER: 'Type',
        UPDATE_SUCCESS: 'Column updated successfully.',
        UPDATE_FAIL: 'Failed to update column.',
        POSITIVE_VALUE: 'Only non-negative numbers are accepted.',
    },
    TOOLTIPS: {
        COST_TOTAL_POPOVER: 'This field is auto calculated based on <br><b>PO Total Qty</b> times <b>Unit Price ($).</b>',
        CRON_JOB_INFORMATION: 'PO/Quote data is automatically generated or updated every 10 minutes. Only unedited entries will be updated, so they will remain as user entries.'
    },
    TABS: {
        PO_QUOTATION: 'PO/Quotation',
        SUMMARY: 'Summary',
        BU_INFORMATION: 'BU Information'
    },
    DIALOGS: {
        DELETE_TITLE: 'Delete Confirmation',
        DELETE_MANUAL_INPUT_CONTENT: 'Are you sure you want to delete this row? This action will be permanent.',
        DELETE_ROW_CONTENT: 'Are you sure you want to delete {{dataLength}} cell(s)? This action will be permanent.'
    }
};