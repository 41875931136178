// Angular
import { Injectable } from '@angular/core';
// RxJS
import { IConfigurationTableValue } from '@galvin/core/build-plan/configuration-management';
import { ISkuMatrixFvcValue, ISkuMatrixManagement } from '../interfaces/sku-matrix-management.interface';
import { AbstractControl } from '@angular/forms';
import { SkuMatrixConstants } from '../constants/sku-matrix-constants';

@Injectable()
export class SkuMatrixConigurationService {

    private checkMatchingValues(skuParam:ISkuMatrixFvcValue, configuration:any): boolean {
        if (configuration) {
            if (configuration instanceof AbstractControl) {
                //By FullView
                return configuration?.value?.value
                    ? skuParam.value === configuration?.value?.value
                    : skuParam.value === SkuMatrixConstants.NULL_VALUE;
            } else {
                return configuration?.value
                    ? skuParam.value === configuration?.value
                    : skuParam.value === SkuMatrixConstants.NULL_VALUE;
            }
        }
        else {
            return skuParam.value === SkuMatrixConstants.NULL_VALUE;
        }
    }

    checkMatchAndConfigFill(
        sku: ISkuMatrixManagement,
        marketRegion?: IConfigurationTableValue | AbstractControl,
        cmfColor?: IConfigurationTableValue | AbstractControl,
        ramSize?: IConfigurationTableValue | AbstractControl,
        sim?: IConfigurationTableValue | AbstractControl
    ): boolean {
        return (
            this.checkMatchingValues(sku.marketRegionFvcValue!, marketRegion) &&
            this.checkMatchingValues(sku.cmfColorFvcValue!, cmfColor) &&
            this.checkMatchingValues(sku.memorySizeFvcValue!, ramSize) &&
            this.checkMatchingValues(sku.simFvcValue!, sim)
        );
    }

}
