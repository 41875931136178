// Angular
import { Pipe, PipeTransform } from '@angular/core';
import { DateTimeUtils } from './../../../utils/moment-utils';

@Pipe({
    name: 'parseDateTime'
})
export class ParseDateTimePipe implements PipeTransform {
    /**
     * Transform to parse the input to Datetime as MMM DD, YYYY HH:mm Z
     *
     * @param input That represents a Date to format
     * @param hasTimezone (default false) If the input has timezone info it should be true
     */
    transform(input: string | undefined | Date, hasTimezone = false): string {
        if (input != '--' && input != undefined && input) {
            if (input instanceof Date) {
                return DateTimeUtils.galvinLocalDateTimeFormatFromDate(input, hasTimezone);
            } else {
                return DateTimeUtils.galvinLocalDateTimeFormat(input, hasTimezone);
            }
        }
        return '--';
    }
}
