// Angular
import { Pipe, PipeTransform } from '@angular/core';

/**
 * Returns quantity from array request
 */
@Pipe({
    name: 'checkNull'
})
export class checkNullPipe implements PipeTransform {
    /**
     * Transform
     *
     * @param value: any
     * @param placeholder: the string will return
     */
    transform(value: any, placeholder: any = '--'): any {
        if (value) {
            return value;
        }
        return placeholder;
    }
}
