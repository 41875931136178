<div class="suggest-alias-container">
    <h4 mat-dialog-title class="suggest-title">
        {{ 'BUILD_PLAN.BUILD_PLAN_MANAGEMENT.CONFIGURATION_MANAGEMENT.SUGGEST_ALIAS.TITLE' | translate:
        {
            columnToUpdate: data.columnToUpdate
        }
    }}</h4>
    <div id="data-suggestion">
        <p innerHTML="{{ 'BUILD_PLAN.BUILD_PLAN_MANAGEMENT.CONFIGURATION_MANAGEMENT.SUGGEST_ALIAS.SUGGESTION' | translate:
        {
            current: data.currentDataFromAlias,
            suggest:data.dataToSuggest
        }
        }}"></p>
    </div>
    <mat-dialog-content class="d-block">
        <mat-form-field appearance="outline" class="justify-content-between w-100">
            <mat-label>{{data.columnToUpdate}}</mat-label>
            <input matInput [(ngModel)]="nameUpdate" class="suggestion-input"
                maxlength="235">
        </mat-form-field>
        <mat-dialog-actions>
            <div class="d-flex justify-content-end w-100 p-0">
                <button mat-raised-button class="galvin-btn-success" (click)="onButtonUpdateClick()"
                    disabled="{{buttonDisabled}}" id="btn-suggest-confirm-update">
                    {{ labels.SUGGEST_ALIAS.BUTTONS.UPDATE_COLUMN }}&nbsp;{{data.columnToUpdate}}
                </button>
                <button mat-raised-button color="primary" id="btn-suggest-not-update" class="close-button"
                    (click)="onCloseDialog()">{{ labels.SUGGEST_ALIAS.BUTTONS.NOT_UPDATE_COLUMN }}
                </button>
            </div>
        </mat-dialog-actions>
    </mat-dialog-content>
</div>