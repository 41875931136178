import { Pipe, PipeTransform } from '@angular/core';
import { ISkuBoardSamplesSourcing } from '@galvin/core/build-plan/reports/interfaces/sku-board-samples-sourcing';
import { ReportUtils } from '@galvin/core/build-plan/reports/utils/report.utils';

@Pipe({
    name: 'getSourcingTotal'
})
export class GetSourcingTotalPipe implements PipeTransform {

    transform(hwRevision: any, sourcing: string, reportData: ISkuBoardSamplesSourcing): any {
        const total = this.getSourcingTotal(hwRevision, sourcing, reportData);
        return total;
    }

    getSourcingTotal(hwRevision: string, sourcing: string, reportData: ISkuBoardSamplesSourcing): string | number {
        const filteredItem = reportData.reportDTO?.filter(
            item => item.hwRevisions === hwRevision && item.sourcing === sourcing
        );

        const sourceTotal = filteredItem?.reduce((acc, number) => {
            return acc + number.totalRequests;
        }, 0);

        return ReportUtils.getValue(sourceTotal);
    }
}