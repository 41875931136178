import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input } from '@angular/core';
import { IParameterWithOneValueAndOrderOfString } from '@galvin/core/build-plan/field-value-management/fvc-parameters/interfaces/parameter-with-one-value-and-order-of-string.interface';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'accessory-parameters-details',
    templateUrl: './accessory-parameters-details.component.html',
    styleUrls: ['./accessory-parameters-details.component.scss'],
    animations: [
        trigger('detailExpand', [
            state(
                'collapsed',
                style({ visibility: 'hidden', height: '0px', opacity: 0, overflow: 'hidden' })
            ),
            state(
                'expanded',
                style({
                    height: 'auto',
                    visibility: 'visible'
                })
            ),
            transition('expanded <=> collapsed', animate('225ms ease'))
        ])
    ]
})
export class AccessoryParametersDetailsComponent {
    @Input() accessories!: IParameterWithOneValueAndOrderOfString[];
    expanded = true;

    constructor(private i18n: TranslateService) {}

    getValue(text: string): string {
        if (text === 'true') {
            return this.i18n.instant('COMMON.BUTTONS.YES');
        }

        if (text === 'false') {
            return this.i18n.instant('COMMON.BUTTONS.NO');
        }

        return text;
    }
}
