import { Component, Input } from '@angular/core';

export interface IPopoverGenericData {
    header: string;
    info: string;
}
@Component({
    selector: 'popover-list-generic',
    templateUrl: 'popover-list-generic.component.html',
    styleUrls: ['./popover-list-generic.component.scss']
})
export class PopoverListGenericComponent {
    @Input() title: string = '';

    @Input() data: IPopoverGenericData[] = [];
}
