import {Pipe, PipeTransform} from '@angular/core';
import { ICDCAccessoryTrackerTable } from '@galvin/core/cdc/sorting';

@Pipe({
    name: 'enabledDeleteAccessoryTracker'
})
export class FilterEnableDeleteAccessoryTrackerCdcPipe implements PipeTransform {

    transform(
        value: ICDCAccessoryTrackerTable[]
    ): boolean {
        return this.filterAccessoryTrackerRows(value || []);
    }

    filterAccessoryTrackerRows(manualRows: ICDCAccessoryTrackerTable[]): boolean {
        if (manualRows) {
            const foundManualValues = this.getManualEntries(manualRows);
            return (foundManualValues > 0 && foundManualValues == manualRows.length);
        }
        return false;
    }

    private getManualEntries(manualRows: ICDCAccessoryTrackerTable[]): number {
        return manualRows.filter(selected => selected.fromBPRequest == false).length;
    }
}