import {Pipe, PipeTransform} from '@angular/core';
import {
    IAccessoryFilters,
    IAccessoryRequestTeam
} from '@galvin/core/build-plan/accessory-request-management/interface/accessory-request-team.interface';
import {AccessoryRequestService} from '@galvin/core/build-plan/accessory-request-management/services/accessory-request.service';


@Pipe({
    name: 'accessoryFilters'
})
export class AccessoryFiltersPipe implements PipeTransform {
    constructor(private service: AccessoryRequestService) {

    }

    transform(accessories: Array<IAccessoryRequestTeam | string>, filters: IAccessoryFilters): Array<IAccessoryRequestTeam | string> {
        return this.service.applyFilters(accessories, filters);
    }

}
