// Angular
import { Component, OnInit } from '@angular/core';
import { ConfigurationsManagementService } from '@galvin/core/build-plan/configuration-management/services/configurations-management.service';
import { BuildPlanService } from '@galvin/core/build-plan/management';
import { LocalStorageHelper } from '@galvin/core/storage/local-storage-helper.service';

@Component({
    selector: 'kt-topbar',
    templateUrl: './topbar.component.html',
    styleUrls: ['./topbar.component.scss'],
})
export class TopbarComponent implements OnInit {
    configLink: string = '';

    private readonly MANAGEMENTLINK = '/build-plan/management/';
    private readonly DETAILSLINK = '/build-plan/management/details/';
    private readonly CONFIGVIEWLINK = '/configurations';

    constructor(
        private buildPlanService: BuildPlanService,
        private configurationManagementService: ConfigurationsManagementService,
        private localStorageHelper: LocalStorageHelper,
    ){}

    ngOnInit(): void {
        this.redirectToConfiguration();
        setTimeout(this.adjustWid, 10);
    }

    redirectToConfiguration(){
        let bpLocalStorage = this.configurationManagementService.getBuildPlanByLocalstorage(this.localStorageHelper);
        if(bpLocalStorage){
            this.configLink = this.DETAILSLINK + encodeURI(bpLocalStorage.internalProductName) + this.CONFIGVIEWLINK;
        }
        else{
            this.configLink = this.MANAGEMENTLINK;
            this.buildPlanService.getBuildPlans().subscribe(
                (response) => {
                    this.configLink = this.DETAILSLINK + encodeURI(response[0].internalProductName) + this.CONFIGVIEWLINK;
                }
            );
        }
    }

    getRedirectToConfiguration(){
        return this.configLink;
    }

    adjustWid(){
        let app = document.getElementsByClassName('app')  as HTMLCollectionOf<HTMLElement>;
        let wid = app[0].offsetWidth;
        for(let i = 0; i < app.length; i++){
            if(app[i]){
                if(app[i].getElementsByClassName('dropdown')[0]){
                    (app[i].getElementsByClassName('dropdown')[0] as HTMLElement).style.display = 'block';
                    app[i].getElementsByClassName('dropdown')[0].querySelectorAll('a').forEach(a=>{
                        if(a.offsetWidth > wid){
                            wid = a.offsetWidth;
                        }
                    });
                    (app[i].getElementsByClassName('dropdown')[0] as HTMLElement).style.display = 'flex';
                }
            }
        }
        for(let i = 0; i < app.length; i++){
            app[i].style.width =  wid.toString() + 'px';
        }
    }


}
