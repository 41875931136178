import { Directive, Input, ElementRef, HostListener, OnInit } from '@angular/core';
import { EnterFocusService } from '../services/enter-focus.service';

@Directive({
    selector: '[enterFocusIndex]'
})
export class EnterFocusDirective implements OnInit {

    private _index: number = 0;
    get index(): any{
        return this._index;
    }
  @Input('enterFocusIndex')
    set index(i: any){
        this._index = parseInt(i);
    }

  @HostListener('keydown', ['$event'])
  onInput(e: any) {
      if (e.which === 13) {
          this.enterFocusService.selectedInput.next(this.index + 1);
          e.preventDefault();
      }
  }
  constructor(private el: ElementRef, private enterFocusService: EnterFocusService) {
  }

  ngOnInit(){
      this.enterFocusService.selectedInput.subscribe((i) => {
          if (i === this.index){
              this.el.nativeElement.focus();
          }
      });
  }
}