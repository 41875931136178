//Enums
import { ERolesBuildPlan } from '../../../core/build-plan/permission-management';

export class RolesComponent {
    public static readonly ALL_ROLES: ERolesBuildPlan[] = [
        ERolesBuildPlan.BP_GTM,
        ERolesBuildPlan.BP_GPM,
        ERolesBuildPlan.BP_FACTORY_PM,
        ERolesBuildPlan.BP_HW_PM,
        ERolesBuildPlan.BP_GROUP_MANAGER,
        ERolesBuildPlan.BP_PM,
        ERolesBuildPlan.BP_ADMIN,
        ERolesBuildPlan.BP_REQUESTOR,
        ERolesBuildPlan.BP_EXTERNAL_USER,
        ERolesBuildPlan.CDC_OPERATOR
    ];
    public static readonly MANAGEMENT: ERolesBuildPlan[] = [
        ERolesBuildPlan.BP_GTM,
        ERolesBuildPlan.BP_GPM,
        ERolesBuildPlan.BP_FACTORY_PM,
        ERolesBuildPlan.BP_HW_PM,
        ERolesBuildPlan.BP_GROUP_MANAGER,
        ERolesBuildPlan.BP_PM,
        ERolesBuildPlan.BP_ADMIN
    ];
    public static readonly PROTOTYPE_ACCESSORY_REQUEST: ERolesBuildPlan[] = [
        ERolesBuildPlan.BP_GTM,
        ERolesBuildPlan.BP_GPM,
        ERolesBuildPlan.BP_FACTORY_PM,
        ERolesBuildPlan.BP_HW_PM,
        ERolesBuildPlan.BP_GROUP_MANAGER,
        ERolesBuildPlan.BP_PM,
        ERolesBuildPlan.BP_ADMIN,
        ERolesBuildPlan.BP_REQUESTOR
    ];
    public static readonly DSR: ERolesBuildPlan[] = [
        ERolesBuildPlan.BP_GTM,
        ERolesBuildPlan.BP_GPM,
        ERolesBuildPlan.BP_FACTORY_PM,
        ERolesBuildPlan.BP_HW_PM,
        ERolesBuildPlan.BP_GROUP_MANAGER,
        ERolesBuildPlan.BP_PM,
        ERolesBuildPlan.BP_ADMIN,
        ERolesBuildPlan.BP_REQUESTOR,
        ERolesBuildPlan.CDC_OPERATOR
    ];
    public static readonly REPORTS: ERolesBuildPlan[] = [
        ERolesBuildPlan.BP_GTM,
        ERolesBuildPlan.BP_GPM,
        ERolesBuildPlan.BP_FACTORY_PM,
        ERolesBuildPlan.BP_HW_PM,
        ERolesBuildPlan.BP_GROUP_MANAGER,
        ERolesBuildPlan.BP_PM,
        ERolesBuildPlan.BP_ADMIN,
        ERolesBuildPlan.BP_REQUESTOR
    ];

    public static readonly BUILD_PLAN_MANAGEMENT: ERolesBuildPlan[] = [...RolesComponent.ALL_ROLES];

    public static readonly BUILD_PLAN_MANAGEMENT_MAIN_PRODUCT_DATA: ERolesBuildPlan[] = [
        ...RolesComponent.ALL_ROLES,
        ERolesBuildPlan.CDC_OPERATOR
    ];

    public static readonly BUILD_PLAN_MANAGEMENT_CONFIGURATIONS: ERolesBuildPlan[] = [
        ...RolesComponent.ALL_ROLES
    ];

    public static readonly BUILD_PLAN_MANAGEMENT_CONFIG_HISTORY: ERolesBuildPlan[] = [
        ...RolesComponent.ALL_ROLES
    ];

    public static readonly BUILD_PLAN_MANAGEMENT_ACCESSORIES: ERolesBuildPlan[] = [
        ...RolesComponent.ALL_ROLES
    ];

    public static readonly BUILD_PLAN_MANAGEMENT_SCOPES: ERolesBuildPlan[] = [
        ...RolesComponent.ALL_ROLES
    ];

    public static readonly BUILD_PLAN_MANAGEMENT_TEAMS: ERolesBuildPlan[] = [
        ...RolesComponent.ALL_ROLES
    ];

    public static readonly BUILD_PLAN_MANAGEMENT_GROUPS: ERolesBuildPlan[] = [
        ...RolesComponent.ALL_ROLES
    ];

    public static readonly BUILD_PLAN_MANAGEMENT_REQUEST_PREVIEW: ERolesBuildPlan[] = [
        ...RolesComponent.ALL_ROLES,
        ERolesBuildPlan.CDC_OPERATOR
    ];

    public static readonly BUILD_PLAN_MANAGEMENT_STATUS_APPROVAL: ERolesBuildPlan[] = [
        ...RolesComponent.ALL_ROLES,
        ERolesBuildPlan.CDC_OPERATOR
    ];

    public static readonly BUILD_PLAN_MANAGEMENT_EXPORT: ERolesBuildPlan[] = [
        ...RolesComponent.ALL_ROLES,
        ERolesBuildPlan.CDC_OPERATOR
    ];

    public static readonly BUILD_PLAN_SKU_MATRIX: ERolesBuildPlan[] = [
        ...RolesComponent.ALL_ROLES,
        ERolesBuildPlan.CDC_OPERATOR
    ];

    public static readonly PERMISSIONS: ERolesBuildPlan[] = [
        ERolesBuildPlan.BP_GTM,
        ERolesBuildPlan.BP_GPM,
        ERolesBuildPlan.BP_FACTORY_PM,
        ERolesBuildPlan.BP_HW_PM,
        ERolesBuildPlan.BP_PM,
        ERolesBuildPlan.BP_ADMIN
    ];

    public static readonly FVC_PARAMETERS_MANAGEMENT: ERolesBuildPlan[] = [
        ERolesBuildPlan.BP_GTM,
        ERolesBuildPlan.BP_GPM,
        ERolesBuildPlan.BP_FACTORY_PM,
        ERolesBuildPlan.BP_HW_PM,
        ERolesBuildPlan.BP_GROUP_MANAGER,
        ERolesBuildPlan.BP_PM,
        ERolesBuildPlan.BP_ADMIN,
        ERolesBuildPlan.BP_REQUESTOR,
        ERolesBuildPlan.CDC_OPERATOR
    ];

    public static readonly ACCESSORY_PARAMETER_MANAGEMENT: ERolesBuildPlan[] = [
        ERolesBuildPlan.BP_GTM,
        ERolesBuildPlan.BP_GPM,
        ERolesBuildPlan.BP_FACTORY_PM,
        ERolesBuildPlan.BP_HW_PM,
        ERolesBuildPlan.BP_GROUP_MANAGER,
        ERolesBuildPlan.BP_PM,
        ERolesBuildPlan.BP_ADMIN,
        ERolesBuildPlan.BP_REQUESTOR,
        ERolesBuildPlan.CDC_OPERATOR
    ];

    public static readonly SPECIAL_REQUIREMENTS_MANAGEMENT: ERolesBuildPlan[] = [
        ERolesBuildPlan.BP_HW_PM,
        ERolesBuildPlan.BP_GPM,
        ERolesBuildPlan.BP_PM,
        ERolesBuildPlan.BP_FACTORY_PM,
        ERolesBuildPlan.BP_REQUESTOR,
        ERolesBuildPlan.BP_GROUP_MANAGER,
        ERolesBuildPlan.BP_GTM,
        ERolesBuildPlan.BP_ADMIN,
        ERolesBuildPlan.CDC_OPERATOR
    ];

    public static readonly SCOPE_PARAMETERS_MANAGEMENT: ERolesBuildPlan[] = [
        ERolesBuildPlan.BP_HW_PM,
        ERolesBuildPlan.BP_GPM,
        ERolesBuildPlan.BP_PM,
        ERolesBuildPlan.BP_FACTORY_PM,
        ERolesBuildPlan.BP_REQUESTOR,
        ERolesBuildPlan.BP_GROUP_MANAGER,
        ERolesBuildPlan.BP_GTM,
        ERolesBuildPlan.BP_ADMIN,
        ERolesBuildPlan.CDC_OPERATOR
    ];

    public static readonly DATES: ERolesBuildPlan[] = [
        ERolesBuildPlan.BP_HW_PM,
        ERolesBuildPlan.BP_GPM,
        ERolesBuildPlan.BP_PM,
        ERolesBuildPlan.BP_FACTORY_PM,
        ERolesBuildPlan.BP_REQUESTOR,
        ERolesBuildPlan.BP_GROUP_MANAGER,
        ERolesBuildPlan.BP_GTM,
        ERolesBuildPlan.BP_ADMIN,
        ERolesBuildPlan.CDC_OPERATOR
    ];

    public static readonly BP_ADMIN: ERolesBuildPlan[] = [ERolesBuildPlan.BP_ADMIN];

    public static readonly TEAM_PARAMETERS: ERolesBuildPlan[] = [
        ERolesBuildPlan.BP_HW_PM,
        ERolesBuildPlan.BP_GPM,
        ERolesBuildPlan.BP_PM,
        ERolesBuildPlan.BP_FACTORY_PM,
        ERolesBuildPlan.BP_REQUESTOR,
        ERolesBuildPlan.BP_GROUP_MANAGER,
        ERolesBuildPlan.BP_GTM,
        ERolesBuildPlan.BP_ADMIN,
        ERolesBuildPlan.CDC_OPERATOR
    ];

    public static readonly LOCATIONS_MANAGEMENT: ERolesBuildPlan[] = [
        ERolesBuildPlan.BP_HW_PM,
        ERolesBuildPlan.BP_GPM,
        ERolesBuildPlan.BP_PM,
        ERolesBuildPlan.BP_FACTORY_PM,
        ERolesBuildPlan.BP_REQUESTOR,
        ERolesBuildPlan.BP_GROUP_MANAGER,
        ERolesBuildPlan.BP_GTM,
        ERolesBuildPlan.BP_ADMIN,
        ERolesBuildPlan.CDC_OPERATOR
    ];

    public static readonly FIELD_VALUES_MANAGEMENT: ERolesBuildPlan[] = [
        ...RolesComponent.TEAM_PARAMETERS,
        ...RolesComponent.DATES,
        ...RolesComponent.SCOPE_PARAMETERS_MANAGEMENT,
        ...RolesComponent.LOCATIONS_MANAGEMENT,
        ...RolesComponent.FVC_PARAMETERS_MANAGEMENT,
        ...RolesComponent.SPECIAL_REQUIREMENTS_MANAGEMENT
    ];

    public static readonly GROUP_MANAGEMENT: ERolesBuildPlan[] = [
        ERolesBuildPlan.BP_GROUP_MANAGER,
        ERolesBuildPlan.BP_ADMIN,
        ERolesBuildPlan.BP_REQUESTOR,
        ERolesBuildPlan.CDC_OPERATOR
    ];

    public static readonly SCOPE_MANAGEMENT: ERolesBuildPlan[] = [
        ERolesBuildPlan.BP_HW_PM,
        ERolesBuildPlan.BP_GPM,
        ERolesBuildPlan.BP_PM,
        ERolesBuildPlan.BP_FACTORY_PM,
        ERolesBuildPlan.BP_REQUESTOR,
        ERolesBuildPlan.BP_GROUP_MANAGER,
        ERolesBuildPlan.BP_GTM,
        ERolesBuildPlan.BP_ADMIN,
        ERolesBuildPlan.CDC_OPERATOR
    ];

    public static readonly TEAM_MANAGEMENT: ERolesBuildPlan[] = [
        ERolesBuildPlan.BP_HW_PM,
        ERolesBuildPlan.BP_GPM,
        ERolesBuildPlan.BP_PM,
        ERolesBuildPlan.BP_FACTORY_PM,
        ERolesBuildPlan.BP_REQUESTOR,
        ERolesBuildPlan.BP_GROUP_MANAGER,
        ERolesBuildPlan.BP_GTM,
        ERolesBuildPlan.BP_ADMIN,
        ERolesBuildPlan.CDC_OPERATOR
    ];

    public static readonly PROTOTYPE_REQUEST_MANAGEMENT: ERolesBuildPlan[] = [
        ERolesBuildPlan.BP_ADMIN,
        ERolesBuildPlan.BP_GPM,
        ERolesBuildPlan.BP_PM,
        ERolesBuildPlan.BP_HW_PM,
        ERolesBuildPlan.BP_FACTORY_PM,
        ERolesBuildPlan.BP_REQUESTOR,
        ERolesBuildPlan.BP_GROUP_MANAGER,
        ERolesBuildPlan.CDC_OPERATOR
    ];
    public static readonly PROTOTYPE_REQUEST_APPROVAL_MANAGEMENT: ERolesBuildPlan[] = [
        ERolesBuildPlan.BP_ADMIN,
        ERolesBuildPlan.BP_GPM
    ];
    public static readonly ACCESSORY_REQUEST_MANAGEMENT: ERolesBuildPlan[] = [
        ERolesBuildPlan.BP_ADMIN,
        ERolesBuildPlan.BP_GPM,
        ERolesBuildPlan.BP_PM,
        ERolesBuildPlan.BP_HW_PM,
        ERolesBuildPlan.BP_FACTORY_PM,
        ERolesBuildPlan.BP_REQUESTOR,
        ERolesBuildPlan.BP_GROUP_MANAGER,
        ERolesBuildPlan.CDC_OPERATOR
    ];

    public static readonly ACCESSORY_REQUEST_APPROVAL_MANAGEMENT: ERolesBuildPlan[] = [
        ERolesBuildPlan.BP_ADMIN,
        ERolesBuildPlan.BP_GPM
    ];

    public static readonly SKU_MANAGEMENT_MANAGEMENT: ERolesBuildPlan[] = [
        ERolesBuildPlan.BP_HW_PM,
        ERolesBuildPlan.BP_GPM,
        ERolesBuildPlan.BP_PM,
        ERolesBuildPlan.BP_FACTORY_PM,
        ERolesBuildPlan.BP_REQUESTOR,
        ERolesBuildPlan.BP_GROUP_MANAGER,
        ERolesBuildPlan.BP_GTM,
        ERolesBuildPlan.BP_ADMIN,
        ERolesBuildPlan.CDC_OPERATOR
    ];
}
