<div class="accessory-header-content header position-relative" [ngbTooltip]="tooltip">
    <ng-template #tooltip>
        {{accessory.name |checkNull}} #{{accessory.accessoryId}}
        <br>
        {{ 'BUILD_PLAN.REQUEST_PREVIEW.TOTAL_REQUEST_FULL_VIEW' | translate}}
        ({{ accessory.totalRequests | checkNull: 0 }})
        <ng-container *ngIf="isHeaderInfoVisible">
            <br>
            {{ LABELS_ACCESSORY_REQUEST.HEADER.FILTERS.ACCESSORY_TYPE }}:
            {{ accessory.accessoryType |checkNull }}
            <br>
            {{ LABELS_ACCESSORY_REQUEST.HEADER.FILTERS.CATEGORY }}:
            {{ accessory.category |checkNull }}
            <br>
            {{ LABELS_ACCESSORY_REQUEST.HEADER.FILTERS.VENDOR }}:
            {{ accessory.vendor | parseDate }}
            <br>
            {{ LABELS_ACCESSORY_REQUEST.HEADER.FILTERS.MOTO_PART }}:
            {{ accessory.motoPart | checkNull }}
            <br>
            {{ LABELS_ACCESSORY_REQUEST.HEADER.FILTERS.VENDOR_PART }}:
            {{ accessory.vendorPart | checkNull }}
        </ng-container>
    </ng-template>

    <div class="header-font-12 text-truncate accessory-name-id">
        {{ accessory.name | checkNull }} <br>
        #{{ accessory.accessoryId }}
    </div>

    <div class="position-absolute r-0 position-btn-accessory-details" (click)="showRequestsDialog(accessory, $event)">
        <button class="btn-accessory-details" color="primary" mat-icon-button>
            <mat-icon [ngbTooltip]="LABELS_REQUEST_PREV.DETAILS">loupe</mat-icon>
        </button>
    </div>

    <div class="m-0 text-truncate">
        <ng-container>
            <span class="ml-2 header-font-12 acc-request-total">
                ({{ accessory.totalRequests || '--' }})
            </span>
        </ng-container>
    </div>

    <div class="header-extra-block" *ngIf="isHeaderInfoVisible">
        <small class="m-0 d-block accessory-type text-center" [class]="getColor(accessory.accessoryType)">
            <strong>{{ accessory.accessoryType | checkNull }}</strong>
        </small>
        <small class="m-0 d-block text-center category">
            {{accessory.category}}
        </small>
        <small class="m-0 d-block text-center vendor">
            {{accessory.vendor}}
        </small>
        <small class="m-0 d-block text-center moto-pn">
            {{accessory.motoPart}}
        </small>
        <small class="m-0 d-block text-center vendor-pn">
            {{accessory.vendorPart}}
        </small>
    </div>
</div>