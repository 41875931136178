import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { ChatbotSidePanelService } from '../../../../../core/_base/layout';

@Component({
    selector: 'kt-chatbot-button',
    templateUrl: './chatbot-button.component.html',
    styleUrls: ['./chatbot-button.component.scss'],
    animations: [
        trigger('insertRemoveItem', [
            transition(':enter', [style({ opacity: 0 }), animate('200ms', style({ opacity: 1 }))]),
            transition(':leave', [
                animate(
                    '200ms',
                    style({
                        opacity: 0,
                        transform: 'translateX(-100%)'
                    })
                )
            ])
        ])
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatbotButtonComponent implements OnInit {

    public showChatbotSidePanel: boolean = false;

    constructor(
        private changeDetector: ChangeDetectorRef,
        private sidePanelService: ChatbotSidePanelService,
    ) {
    }

    ngOnInit(): void {
        this.showChatbotSidePanel = this.sidePanelService.isSidePanelOpen;
        this.sidePanelService.sidePanelOpenCloseListener$.subscribe((isOpen: boolean) => {
            this.showChatbotSidePanel = isOpen;
            this.changeDetector.detectChanges();
        });
    }

    /**
     * Called whenever the side panel is shown/hidden
     */
    public onSidePanelToggle() {
        this.showChatbotSidePanel = !this.sidePanelService.isSidePanelOpen;
        // The timeout accounts for the sidebar open/close animation
        this.sidePanelService.sidePanelOpenCloseChanged(this.showChatbotSidePanel);
        this.changeDetector.detectChanges();
    }
}
