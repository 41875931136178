// Angular
import { Injectable } from '@angular/core';
import { LocalStorageHelper } from '@galvin/core/storage/local-storage-helper.service';
import { Subject } from 'rxjs';
// Environment
import { IBuildPlanSelectorItem } from '../interfaces/build-plan-selector-item.interface';

@Injectable({
    providedIn: 'root'
})
export class BuildPlanSelectorService {

    private readonly BUILD_PLAN_SELECTOR: string = 'buildPlanSelector';
    private selectedBuildPlan!: IBuildPlanSelectorItem | null;
    private selectedBuildPlanId!: number;

    selectedBuildPlanIdChange: Subject<number> = new Subject<number>();

    constructor(
        private localStorageHelper: LocalStorageHelper,
    ) {}

    /**
     * Set a new selected build plan and saves it to the local storage
     * @param buildPlan: the build plan selected
     */
    setBuildPlanSelection(buildPlan: IBuildPlanSelectorItem | null): void {
        if (buildPlan) {
            this.localStorageHelper.setItem(this.BUILD_PLAN_SELECTOR, JSON.stringify(buildPlan));
            this.selectedBuildPlan = buildPlan;
            this.selectedBuildPlanId = buildPlan.id;
            this.selectedBuildPlanIdChange.next(this.selectedBuildPlanId);
        } else {
            this.selectedBuildPlan = null;
            this.selectedBuildPlanId = -1;
        }
    }

    /**
     * Gets the currently selected build plan id from the local storage
     */
    getBuildPlanSelectionId(): number {
        const storageBuildPlan = this.localStorageHelper.getItem(this.BUILD_PLAN_SELECTOR);
        if (storageBuildPlan) {
            this.selectedBuildPlan = JSON.parse(storageBuildPlan);
            this.selectedBuildPlanId = this.selectedBuildPlan?.id as number;
        }
        return this.selectedBuildPlanId;
    }
}
