import { ShipmentI18n } from './shipment-18n.interface';

export const CDC_SHIPMENT: ShipmentI18n = {
    INFORMATION: 'Shipment Information',
    CARRIER: 'Carrier',
    SHIPFROM: 'Ship From',
    SHIPTO: 'Ship To',
    TRACKINGAWS: 'Tracking Number',
    TRACKINGMAWS: 'Master Tracking Number',
    SCPECIALINSTRUCTION: 'Special Instruction',
    SAVE: 'Save'
};
