<div *ngFor="let activity of requestActivity; let i=index;" class="activity-list">
    <div class="d-flex flex-column mt-4 mb-2 content-activity">
        <div class="d-flex">
            <div class="d-flex justify-content-center align-items-center activity-icon-content"
                [attr.data-change-type]="activity.requestChangeType" [attr.operation-change-type]="activity.operation">
                <i class="material-icons" placement="right-top" *ngIf="isChangeType(activity, INCREASE)"
                    container="galvin-dialog-generic"
                    [ngbTooltip]="'BUILD_PLAN.PROTOTYPE_REQUEST.DIALOG.LABELS.INCREASED_QUANTITY'| translate">add</i>
                <i class="material-icons" placement="right-top" *ngIf="isChangeType(activity, DECREASE)"
                    container="galvin-dialog-generic"
                    [ngbTooltip]="'BUILD_PLAN.PROTOTYPE_REQUEST.DIALOG.LABELS.DECREASED_QUANTITY'| translate">remove</i>
                <i class="equal-sign" placement="right-top" *ngIf="isChangeType(activity, EQUAL)"
                    container="galvin-dialog-generic"
                    [ngbTooltip]="'BUILD_PLAN.PROTOTYPE_REQUEST.DIALOG.LABELS.EQUAL_QUANTITY'| translate">=</i>
                <i class="galvin-icon-configuration-history" placement="right-top"
                    *ngIf="isChangeRequestOperationTypeFromConfiguration(activity.operation)"
                    container="galvin-dialog-generic"
                    [ngbTooltip]="'BUILD_PLAN.PROTOTYPE_REQUEST.DIALOG.LABELS.EQUAL_QUANTITY'| translate"></i>
                <i class="galvin-icon-team-history" placement="right-top"
                    *ngIf="isChangeRequestOperationTypeFromTeam(activity.operation)" container="galvin-dialog-generic"
                    [ngbTooltip]="'BUILD_PLAN.PROTOTYPE_REQUEST.DIALOG.LABELS.EQUAL_QUANTITY'| translate"></i>
                <i class="galvin-icon-special-requirements-history" placement="right-top"
                    *ngIf="isChangeRequestOperationTypeFromSpecialRequirements(activity.operation)"
                    container="galvin-dialog-generic"
                    [ngbTooltip]="'BUILD_PLAN.PROTOTYPE_REQUEST.DIALOG.LABELS.EQUAL_QUANTITY'| translate"></i>
            </div>
            <div class="d-flex flex-column ml-3 mr-3 activity-text-content">
                <div class="mb-1">
                    <div class="activity-user font-weight-bold">{{ getActivityUser(activity) }}</div>
                    <div class="activity-date property-text">
                        {{activity.requestedTime | date: 'MMM dd yyyy, HH:mm:ss' }}
                    </div>
                </div>
                <div class="modified-fields">
                    <div *ngIf="isWasAChangeInRequest(activity.operation)">

                        <div *ngIf="activity.quantity">
                            {{'BUILD_PLAN.PROTOTYPE_REQUEST.DIALOG.LABELS.QUANTITY'| translate }}:
                            <span class="value">{{ activity.quantity }}</span>
                        </div>
                        <div class="checkbox-field display-flex" *ngIf="activity.devicesActivated !== null">
                            <span>
                                {{'BUILD_PLAN.PROTOTYPE_REQUEST.DIALOG.LABELS.DEVICES_ACTIVATED_ACTIVITY'| translate }}:
                                <i class="material-icons" *ngIf="activity.devicesActivated"
                                    container="galvin-dialog-generic"
                                    [ngbTooltip]="'BUILD_PLAN.PROTOTYPE_REQUEST.DIALOG.LABELS.CHECKED'| translate">check_box</i>
                                <i class="material-icons" *ngIf="!activity.devicesActivated"
                                    container="galvin-dialog-generic"
                                    [ngbTooltip]="'BUILD_PLAN.PROTOTYPE_REQUEST.DIALOG.LABELS.UNCHECKED'| translate">check_box_outline_blank</i>
                            </span>
                        </div>
                        <div *ngIf="activity.activationPercentage || activity.activationQuantity">
                            <span>{{'BUILD_PLAN.PROTOTYPE_REQUEST.DIALOG.LABELS.DEVICES_TO_ACTIVATE'| translate }}:</span>
                            <span *ngIf="activity.activationPercentage">
                                <span class="value">{{ activity.activationPercentage }}%</span>
                            </span>
                            <span *ngIf="activity.activationQuantity">
                                <span *ngIf="activity.activationPercentage">
                                    (<span class="value-no-margin">{{ activity.activationQuantity }}</span>
                                    {{'BUILD_PLAN.PROTOTYPE_REQUEST.DIALOG.LABELS.DEVICES'| translate }})
                                </span>
                                <span *ngIf="!activity.activationPercentage">
                                    <span class="value">{{ activity.activationQuantity }}</span>
                                    {{'BUILD_PLAN.PROTOTYPE_REQUEST.DIALOG.LABELS.DEVICES'| translate }}
                                </span>
                            </span>
                        </div>
                        <div *ngIf="activity.justifications">
                            {{'BUILD_PLAN.PROTOTYPE_REQUEST.DIALOG.LABELS.JUSTIFICATION.JUSTIFICATION'| translate }}:
                            <span class="value"
                                *ngIf="activity.justifications.length">{{ getJustificationAsString(activity.justifications) }}</span>
                            <span class="value"
                                *ngIf="!activity.justifications.length">{{'BUILD_PLAN.PROTOTYPE_REQUEST.DIALOG.LABELS.NO_JUSTIFICATIONS'| translate }}</span>
                        </div>
                        <div class="checkbox-field display-flex" *ngIf="activity.specialRequirementsList">
                            <span>
                                {{'BUILD_PLAN.PROTOTYPE_REQUEST.DIALOG.LABELS.SPECIAL_REQUIREMENT'| translate }}:
                                <i class="material-icons" *ngIf="activity.specialRequirementsList.length > 0"
                                    container="galvin-dialog-generic"
                                    [ngbTooltip]="'BUILD_PLAN.PROTOTYPE_REQUEST.DIALOG.LABELS.CHECKED'| translate">check_box</i>
                                <i class="material-icons" *ngIf="!activity.specialRequirementsList.length"
                                    container="galvin-dialog-generic"
                                    [ngbTooltip]="'BUILD_PLAN.PROTOTYPE_REQUEST.DIALOG.LABELS.UNCHECKED'| translate">check_box_outline_blank</i>
                                <div class="special-requirement-list">
                                    <div *ngFor="let spr of activity.specialRequirementsList">
                                        <strong>{{spr.specialRequirement.fieldName}}:</strong> <span
                                            class="text-justify"> {{spr.value}}
                                        </span>
                                    </div>
                                </div>
                            </span>
                        </div>

                        <div class="checkbox-field display-flex" *ngIf="activity.isNewHWSpec !== null">
                            <span>
                                {{'BUILD_PLAN.PROTOTYPE_REQUEST.DIALOG.LABELS.IS_NEW_HW_SPEC'| translate }}:
                                <i class="material-icons" *ngIf="activity.isNewHWSpec" container="galvin-dialog-generic"
                                    [ngbTooltip]="'BUILD_PLAN.PROTOTYPE_REQUEST.DIALOG.LABELS.CHECKED'| translate">check_box</i>
                                <i class="material-icons" *ngIf="!activity.isNewHWSpec"
                                    container="galvin-dialog-generic"
                                    [ngbTooltip]="'BUILD_PLAN.PROTOTYPE_REQUEST.DIALOG.LABELS.UNCHECKED'| translate">check_box_outline_blank</i>
                            </span>
                        </div>
                        <div class="display-flex" *ngIf="activity.additionalComments">
                            {{'BUILD_PLAN.PROTOTYPE_REQUEST.DIALOG.LABELS.COMMENT'| translate }}:
                            <div class="value activity-comment" container="galvin-dialog-generic"
                                [ngbTooltip]="activity.additionalComments">{{ activity.additionalComments }}</div>
                        </div>
                    </div>
                    <div *ngIf="!isWasAChangeInRequest(activity.operation)">
                        <span>
                            {{getMessageForIndirectEdition(activity.operation)| translate}}
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="vertical-divider" *ngIf="i + 1 < requestActivity.length"></div>
    </div>
</div>