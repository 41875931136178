export enum ERequestOperationType {
    INSERT = 'INSERT',
    EDIT = 'EDIT',
    CONFIGURATION_EDIT = 'CONFIGURATION_EDIT',
    CONFIGURATION_EDIT_IGNORE = 'CONFIGURATION_EDIT_IGNORE',
    CONFIGURATION_EDIT_MASSIVE = 'CONFIGURATION_EDIT_MASSIVE',
    CONFIGURATION_PROPAGATE = 'CONFIGURATION_PROPAGATE',
    CONFIGURATION_FVC_DELETE = 'CONFIGURATION_FVC_DELETE',
    CONFIGURATION_DELETE = 'CONFIGURATION_DELETE',
    BP_TEAM_EDIT = 'BP_TEAM_EDIT',
    BP_TEAM_LABELS = 'BP_TEAM_LABELS',
    BP_TEAM_PROPAGATE = 'BP_TEAM_PROPAGATE',
    BP_TEAM_DELETE = 'BP_TEAM_DELETE',
    SPECIAL_REQUIREMENTS_PROPAGATE = 'SPECIAL_REQUIREMENTS_PROPAGATE'
}

export enum ERequestOperationGroup {
    PROTOTYPE_REQUEST = 'PROTOTYPE_REQUEST',
    PROTOTYPE_EDIT = 'PROTOTYPE_EDIT',
    CONFIGURATION_CHANGE_STATUS = 'CONFIGURATION_CHANGE_STATUS',
    CONFIGURATION_DELETE = 'CONFIGURATION_DELETE',
    BP_TEAM_DELETE = 'BP_TEAM_DELETE',
    CONFIGURATION_CHANGE = 'CONFIGURATION_CHANGE',
    BP_TEAM_CHANGE = 'BP_TEAM_CHANGE'
}
