<form #formContainer [formGroup]="searchForm" class="search-row d-flex">
  <div class="col-12 pl-0 pr-0">
    <div
      class="bg-white search-input-container d-flex"
      [ngClass]="{ disabled: isDisabled }"
    >
      <div
        class="d-flex align-self-center flex-grow-1"
        [ngClass]="{ 'col-5 border-left rounded': filter }"
      >
        <i class="fas fa-search icon-search"></i>
        <input
          #searchInput
          [placeholder]="placeholder"
          class="search-input form-control-lg"
          type="text"
          maxlength="255"
          formControlName="searchInput"
          (keyup.enter)="doSearch()"
          [ngbTooltip]="searchTip"
        />
        <mat-icon
          *ngIf="clearButton"
          class="input-clear"
          (click)="onSearchInputClear()"
          >close</mat-icon
        >
      </div>

      <div class="d-flex justify-content-end" [ngClass]="{ 'col-7': filter }">
        <ng-container *ngIf="hasOptionsSelected()">
          <div
            class="filter-applied text-truncate"
            (click)="onClickEvent()"
            [ngbPopover]="optionsTooltip"
            #popover="ngbPopover"
            container="body"
            placement="bottom"
            [popoverClass]="POPOVER_FILTERS_CLASS"
            (mouseenter)="showFilterPopover(popover)"
            autoClose="outside"
            triggers="manual"
            (mouseleave)="closeFilterPopover(popover)"
          >
            <span>
              {{ getValueFromParameterShort(", ", 5) }}
            </span>
          </div>
          <ng-template #optionsTooltip>
            <div
              class="options-search-galvin-tooltip"
              (mouseleave)="closeFilterPopover(popover)"
            >
              <ng-container *ngFor="let parameter of fvcSelected()">
                <ng-container
                  *ngIf="getValueFromParameter(parameter).length > 0"
                >
                  <p>
                    <b>{{ parameter }}</b
                    >: {{ getValueFromParameter(parameter) | join : " | " }}
                  </p>
                </ng-container>
              </ng-container>
            </div>
          </ng-template>

          <button
            id="clear-all-filters-button"
            (click)="clearFilterBy.next()"
            [ngbTooltip]="'GENERAL_FORM.CLEAR_ALL_FITLERS' | translate"
            mat-icon-button
            color="warn"
          >
            <mat-icon>close</mat-icon>
          </button>
        </ng-container>

        <div class="spinner">
          <mat-spinner *ngIf="loadSearch" [diameter]="15"></mat-spinner>
        </div>

        <div
          *ngIf="filter"
          class="filter-button"
          matRipple
          (click)="onClickEvent()"
          [ngClass]="{ disabled: isDisabled }"
        >
          <span class="filter-text">
            {{ "GENERAL_FORM.FILTER_BY" | translate }}
          </span>
          <i class="material-icons">keyboard_arrow_down</i>
        </div>
      </div>
    </div>
    <div
      [style.width.px]="formContainer.offsetWidth"
      class="filter-options"
      [@detailExpand]="expandedElement ? 'expanded' : 'collapsed'"
    >
      <ng-container
        [ngTemplateOutletContext]="{
          form: searchForm,
          detailExpand: expandedElement
        }"
        [ngTemplateOutlet]="cellRef"
      >
      </ng-container>
    </div>
  </div>
</form>
