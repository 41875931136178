// Angular
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IDeletableValue } from '@galvin/core/build-plan/field-value-management/fvc-parameters/interfaces/deletable-parameter.interface';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
// Enviroments
import { environment } from '../../../../../../environments/environment';
import { TDataType } from '../../enums/data-type.enum';
import { EFieldType } from '../../enums/field-type.enum';
import { IBpValueAssociation, IFieldValue } from '../../interfaces/field-value.interface';
// Interfaces
import { IFvcParameter } from '../interfaces/fvc-parameter.interface';

@Injectable({
    providedIn: 'root'
})
export class FvcParametersService {
    private readonly URL_FVC = 'build-plan/field-values-management/fvc';
    private readonly API_FVC_URL = `${environment.baseUrlApi}/` + this.URL_FVC;

    constructor(private http: HttpClient) {}

    /**
     * Get All Data types
     */
    getDataTypes(): Observable<Array<TDataType>> {
        return this.http.get<any>(`${this.API_FVC_URL}/data-type`).pipe(map((res) => res.content));
    }

    /**
     * Get All Field types
     */
    getFieldTypes(): Observable<Array<EFieldType>> {
        return this.http.get<any>(`${this.API_FVC_URL}/field-type`).pipe(map((res) => res.content));
    }

    /**
     * Get All FVC Parameters
     */
    getAllFVC(): Observable<IFvcParameter[]> {
        return this.http.get<any>(this.API_FVC_URL).pipe(map((res) => res.content));
    }

    /**
     * Get All FVC Parameters By Status
     */
    getAllFVCByStatus(status: string): Observable<IFvcParameter[]> {
        const stat = encodeURIComponent(status);
        return this.http.get<any>(`${this.API_FVC_URL}/enabled?${stat}`).pipe(map((res) => res.content));
    }

    /**
     * Get a list of deletable parameters.
     */
    getDeletableParameters(): Observable<IDeletableValue[]> {
        return this.http.get<any>(`${this.API_FVC_URL}/can-delete`).pipe(map((res) => res.content));
    }

    /**
     * Load fvc parameters by id
     * @param {number} id
     *
     */
    loadFvcParameter(id?: number): Observable<IFvcParameter> {
        return this.http.get<any>(`${this.API_FVC_URL}/${id}`).pipe(map((res) => res.content));
    }

    /**
     * Get a list of deletable values.
     * @param parameter id-fvcValue
     */
    getDeletableValues(parameter: number): Observable<IDeletableValue[]> {
        return this.http.get<any>(`${this.API_FVC_URL}/${parameter}/values/can-delete`).pipe(map((res) => res.content));
    }

    /**
     * Get BP Team Values Associations by team value ID
     */
    getBpFvcAssociations(parameterId: number, valueId: number): Observable<IBpValueAssociation> {
        return this.http
            .get<any>(`${this.API_FVC_URL}/parameters/${parameterId}/values/${valueId}/build-plans/fvc-associations`)
            .pipe(map((res) => res.content));
    }

    /**
     * Save a FVC Parameters
     */
    save(value: IFvcParameter): Observable<IFvcParameter> {
        return this.http.post<any>(`${this.API_FVC_URL}`, value).pipe(map((res) => res.content));
    }

    /**
     * add value by id parameter
     */
    addFvcValue(parameterId: number, fieldValue: IFieldValue): Observable<IFvcParameter> {
        return this.http
            .post<any>(`${this.API_FVC_URL}/${parameterId}/add-fvc-value/`, fieldValue)
            .pipe(map((res) => res.content));
    }

    /**
     * update a FVC Parameters
     */
    update(value: IFvcParameter): Observable<IFvcParameter> {
        return this.http.put<any>(`${this.API_FVC_URL}/update-fvc`, value).pipe(map((res) => res.content));
    }

    /**
     * update a FVC Parameters and All FVC Values
     */
    updateAll(value: IFvcParameter): Observable<IFvcParameter> {
        return this.http.put<any>(`${this.API_FVC_URL}/${value.id}`, value).pipe(map((res) => res.content));
    }

    /**
     * Update a FVC Status (Enabled/ Disabled)
     */
    updateFvcParameterStatus(id: number): Observable<IFvcParameter> {
        return this.http.put<any>(`${this.API_FVC_URL}/${id}/update-fvc-status`, null).pipe(map((res) => res.content));
    }

    /**
     * Update a FVC Required Status (Enabled/ Disabled)
     */
    updateFvcParameterRequiredStatus(id: number): Observable<IFvcParameter> {
        return this.http
            .put<any>(`${this.API_FVC_URL}/${id}/update-fvc-required-status`, null)
            .pipe(map((res) => res.content));
    }

    /**
     * Update status of value from a parameter  (Enabled/ Disabled)
     */
    updateFvcValueStatus(parameterId: number, valueId: number): Observable<IFvcParameter> {
        return this.http
            .put<any>(`${this.API_FVC_URL}/${parameterId}/update-fvc-value-status/${valueId}`, null)
            .pipe(map((res) => res.content));
    }

    /**
     * Update value by id parameter
     * @param parameterId id-fvcValue
     * @param fieldValue body fvcValue
     */
    updateFvcValue(parameterId: number, fieldValue: IFieldValue): Observable<IFieldValue> {
        return this.http
            .put<any>(`${this.API_FVC_URL}/${parameterId}/update-fvc-value`, fieldValue)
            .pipe(map((res) => res.content));
    }

    /**
     * Update value by id parameter
     * @param parameterId id-fvcValue
     * @param fieldValue body fvcValue
     */
    checkSkuMatrixFvcValue(parameterId: number, fieldValue: IFieldValue): Observable<IFieldValue> {
        return this.http
            .put<any>(`${this.API_FVC_URL}/${parameterId}/check-sku-matrix`, fieldValue)
            .pipe(map((res) => res.content));
    }

    /**
     * Delete a parameter by parameter id.
     */
    deleteParameter(parameter: number): Observable<void> {
        return this.http.delete<any>(`${this.API_FVC_URL}/${parameter}`);
    }

    /**
     * Delete a value by value id.
     * @param value id-fvcValue
     */
    deleteValue(value: number): Observable<void> {
        return this.http.delete<any>(`${this.API_FVC_URL}/values/${value}`);
    }
}
