import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IConfigurationI18n } from '@galvin/core/build-plan/configuration-management/i18n/configuration-i18n.interface';
import { IPrototypeRequestI18n } from '@galvin/core/build-plan/prototype-request-management/i18n/prototype-request-i18n.interface';
import { EnumUtils } from '@galvin/core/utils/utils.enum';
import { TranslateService } from '@ngx-translate/core';
import { ParseDatePipe } from './../../../../../../core/_base/layout/pipes/parse-date.pipe';

import {
    EAccessoryStatusLabel,
    EAccessoryTypeStatusIcon
} from '@galvin/core/build-plan/accessory-management/enums/accessory-type.enum';
import { IAccessoryRequestTeam } from '@galvin/core/build-plan/accessory-request-management/interface/accessory-request-team.interface';
import { IBuildPlan } from '@galvin/core/build-plan/management';
import { EAccessoryStatusColor } from '@galvin/core/build-plan/management/enums/type-status-color';
@Component({
    selector: 'accessory-header-request-details',
    templateUrl: './accessory-header-request-details.component.html',
    styleUrls: ['./accessory-header-request-details.component.scss']
})
export class AccessoryHeaderRequestDetailsComponent implements OnInit {
    @Input() buildPlan!: IBuildPlan;
    @Input() accessoryData!: IAccessoryRequestTeam;
    @Input() requestPreview!: boolean;
    @Output() closeDialog = new EventEmitter(true);

    labelsAccessory!: IPrototypeRequestI18n;
    labelsAccessoryStatus!: IConfigurationI18n;

    constructor(
        private i18n: TranslateService,
        private enumUtils: EnumUtils,
        private parseDate: ParseDatePipe
    ) {}

    ngOnInit(): void {
        this.labelsAccessory = this.i18n.instant('BUILD_PLAN.PROTOTYPE_REQUEST');
        this.labelsAccessoryStatus = this.i18n.instant('BUILD_PLAN.CONFIGURATION');
    }

    /**
     * Return the class of the accessory status
     * @param status of the accessory
     */
    getStatusCssClass(status: string): string {
        return EAccessoryStatusColor[
            this.enumUtils.getKeyEnumByValue<keyof typeof EAccessoryStatusColor>(
                EAccessoryStatusLabel,
                status
            )
        ];
    }

    /**
     * Return the icon class from the accessory status
     * @param status of the accessory
     */
    getStatusIcon(status: string): string {
        return EAccessoryTypeStatusIcon[
            this.enumUtils.getKeyEnumByValue<keyof typeof EAccessoryTypeStatusIcon>(
                EAccessoryStatusLabel,
                status
            )
        ];
    }

    getDate(): string {
        return this.accessoryData.dueDate
            ? this.parseDate.transform(this.accessoryData.dueDate)
            : this.labelsAccessory.REQUEST_REVIEW.TO_BE_DEFINED;
    }

    getParameterValue(key: string): string {
        if (this.accessoryData && this.accessoryData.sections) {
            const item = this.accessoryData.sections.accessoryValueList.find(
                (value) => value.parameterName == key
            );
            return item?.value as string;
        }
        return '';
    }
}
