// Angular
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
// Partials for CRUD
import {
    ActionNotificationComponent,
    DeleteEntityDialogComponent,
    FetchEntityDialogComponent,
    UpdateStatusDialogComponent
} from '../../../../views/partials/content/crud';

import { ComponentType } from '@angular/cdk/portal';
import { ActiveToast, ToastrService } from 'ngx-toastr';

export enum MessageType {
    Create,
    Read,
    Update,
    Delete
}

export enum ToastrType {
    success,
    error,
    warning,
    info,
    show
}
export interface GenericDialogData {
    title: string;
    description: string;
    buttonClass?: string;
    contentClass?: string;
    isForCdc: boolean;
}

export interface ConsentDialogData {
    title: string;
    description: string;
    listData: string[];
    buttonClass?: string;
}

@Injectable()
export class LayoutUtilsService {
    /**
     * Service constructor
     *
     * @param snackBar: MatSnackBar
     * @param dialog: MatDialog
     */
    constructor(
        private snackBar: MatSnackBar,
        private dialog: MatDialog,
        private toastr: ToastrService
    ) {}

    /**
     * Showing (Mat-Snackbar) Notification
     *
     * @param message: string
     * @param type: MessageType
     * @param duration: number
     * @param showCloseButton: boolean
     * @param showUndoButton: boolean
     * @param undoButtonDuration: number
     * @param verticalPosition: 'top' | 'bottom' = 'top'
     */
    showActionNotification(
        _message: string,
        _type: MessageType = MessageType.Create,
        _duration = 10000,
        _showCloseButton = true,
        _showUndoButton = false,
        _undoButtonDuration = 3000,
        _verticalPosition: 'top' | 'bottom' = 'top',
        _horizontalPosition: 'start' | 'center' | 'end' | 'left' | 'right' = 'center'
    ) {
        const _data = {
            message: _message,
            snackBar: this.snackBar,
            showCloseButton: _showCloseButton,
            showUndoButton: _showUndoButton,
            undoButtonDuration: _undoButtonDuration,
            type: _type,
            action: 'Undo'
        };

        const data = new MatSnackBarConfig();
        data.duration = _duration;
        data.horizontalPosition = _horizontalPosition;
        data.verticalPosition = _verticalPosition;
        data.data = _data;

        return this.snackBar.openFromComponent(ActionNotificationComponent, data);
    }

    showToastrNotification(
        _message: string,
        _type: ToastrType = ToastrType.info,
        _disabledTimeOut = false,
        _enabledHtml = false,
        _title: string | null = null,
        _timeOut = 5000,
        _showCloseButton = true,
        _showEasing = 'swing',
        _progressBar = true,
        _positionClass:
            | 'toast-bottom-right'
            | 'toast-bottom-left'
            | 'toast-bottom-full-width'
            | 'toast-bottom-center'
            | 'toast-top-left'
            | 'toast-top-center'
            | 'toast-top-full-width'
            | 'toast-top-right' = 'toast-top-right'
    ): ActiveToast<any> {
        let service: ActiveToast<any>;
        switch (_type) {
        case ToastrType.error:
            service = this.toastr.error(_message, <string>_title, {
                enableHtml: _enabledHtml,
                timeOut: _timeOut,
                closeButton: _showCloseButton,
                easing: _showEasing,
                progressBar: _progressBar,
                positionClass: _positionClass,
                disableTimeOut: _disabledTimeOut
            });
            break;
        case ToastrType.info:
            service = this.toastr.info(_message, <string>_title, {
                enableHtml: _enabledHtml,
                timeOut: _timeOut,
                closeButton: _showCloseButton,
                easing: _showEasing,
                progressBar: _progressBar,
                positionClass: _positionClass,
                disableTimeOut: _disabledTimeOut
            });
            break;
        case ToastrType.show:
            service = this.toastr.show(_message, <string>_title, {
                enableHtml: _enabledHtml,
                timeOut: _timeOut,
                closeButton: _showCloseButton,
                easing: _showEasing,
                progressBar: _progressBar,
                positionClass: _positionClass,
                disableTimeOut: _disabledTimeOut
            });
            break;
        case ToastrType.success:
            service = this.toastr.success(_message, <string>_title, {
                enableHtml: _enabledHtml,
                timeOut: _timeOut,
                closeButton: _showCloseButton,
                easing: _showEasing,
                progressBar: _progressBar,
                positionClass: _positionClass,
                disableTimeOut: _disabledTimeOut
            });
            break;
        case ToastrType.warning:
            service = this.toastr.warning(_message, <string>_title, {
                enableHtml: _enabledHtml,
                timeOut: _timeOut,
                closeButton: _showCloseButton,
                easing: _showEasing,
                progressBar: _progressBar,
                positionClass: _positionClass,
                disableTimeOut: _disabledTimeOut
            });
            break;
        }
        return service;
    }

    clearToastrNotification(toastId: number = 0): void {
        if (toastId == 0) {
            this.toastr.clear();
        } else {
            this.toastr.remove(toastId);
        }
    }

    /**
     * Showing Confirmation (Mat-Dialog) before Entity Removing
     *
     * @param title: string
     * @param description: string
     * @param isForCdc: allows user to confirm with ENTER key and cancel with ESC key
     */
    genericDialogConfirmation<T>(
        title = '',
        description = '',
        component?: ComponentType<T | DeleteEntityDialogComponent>,
        buttonClass = 'galvin-btn-danger',
        contentClass = '',
        isForCdc = false
    ): MatDialogRef<T | DeleteEntityDialogComponent> {
        return this.dialog.open(component || DeleteEntityDialogComponent, {
            data: { title, description, buttonClass, contentClass, isForCdc },
            panelClass: 'custom-panel',
            width: '440px',
            disableClose: true
        });
    }

    consentDialogConfirmation<T>(
        title = '',
        description = '',
        listData: any[] = [],
        component?: ComponentType<T | DeleteEntityDialogComponent>,
        buttonClass = 'galvin-btn-danger'
    ): MatDialogRef<T | DeleteEntityDialogComponent> {
        return this.dialog.open(component || DeleteEntityDialogComponent, {
            data: { title, description, buttonClass, listData },
            panelClass: 'custom-panel',
            width: '440px',
            disableClose: true
        });
    }

    /**
     * Showing Fetching Window(Mat-Dialog)
     *
     * @param _data: any
     */
    fetchElements(_data: any) {
        return this.dialog.open(FetchEntityDialogComponent, {
            data: _data,
            width: '400px'
        });
    }

    /**
     * Showing Update Status for Entites Window
     *
     * @param title: string
     * @param statuses: string[]
     * @param messages: string[]
     */
    updateStatusForEntities(title: string, statuses: string[], messages: string[]) {
        return this.dialog.open(UpdateStatusDialogComponent, {
            data: { title, statuses, messages },
            width: '480px'
        });
    }
}
