<div class="filters-container">
    <div class="configurations-filters">
        <p>{{ 'BUILD_PLAN.BUILD_PLAN_MANAGEMENT.CONFIGURATION_MANAGEMENT.CONFIGURATION_FILTER.TITLE'|translate}}</p>
        <div class="spinner" *ngIf="loading">
            <galvin-progress-spinner></galvin-progress-spinner>
        </div>
        <div class="table d-flex flex-wrap" *ngIf="!loading">
            <div class="parameter" [attr.data-type]="parameter.dataType" *ngFor="let parameter of fvcParameters"
                [ngClass]="{hide: !$any(parameter.fieldValues).length}">
                <div class="content" *ngIf="$any(parameter.fieldValues).length">
                    <div class="title">{{ parameter.fieldName }}
                        <div class="values">
                            <ng-container *ngFor="let fieldValue of parameter.fieldValues">
                                <button (click)="onOptionSelected(fieldValue, parameter)"
                                    [ngbTooltip]="getValue(fieldValue.value)"
                                    [ngClass]="{ buttonFilter: isSelected(fieldValue.value, $any(parameter.fieldName)) }"
                                    [attr.data-parameter]="parameter.id + '-' + getValue(fieldValue.value).toLocaleLowerCase()"
                                    type="button" mat-icon-button
                                    class="parameter-value-button">{{ getValue(fieldValue.value) }}</button>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>