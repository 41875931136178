// Angular
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
// RxJS
import { Subscription } from 'rxjs';
// Object-Path
import * as objectPath from 'object-path';
// Layout
import { Router } from '@angular/router';
import {
    LayoutConfigService,
    MenuConfigService,
    PageConfigService
} from '../../../core/_base/layout';
import { LayoutConfig } from '../../../core/_config/layout.config';
import { MenuConfig } from '../../../core/_config/menu.config';
import { PageConfig } from '../../../core/_config/page.config';
import { HtmlClassService } from '../html-class.service';
import { ToolbarService } from '../services/toolbar.service';

declare var document: Document;

@Component({
    selector: 'kt-base',
    templateUrl: './base.component.html',
    styleUrls: ['./base.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class BaseComponent implements OnInit, OnDestroy {
    // Public variables
    selfLayout!: string;
    asideDisplay!: boolean;
    asideSecondary!: boolean;
    subheaderDisplay!: boolean;
    desktopHeaderDisplay!: boolean;
    fitTop!: boolean;
    fluid!: boolean;
    fullScreen: boolean = this.toolbarService.isFullScreen;

    // Private properties
    private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

    /**
     * Component constructor
     *
     * @param layoutConfigService: LayoutConfigService
     * @param menuConfigService: MenuConfifService
     * @param pageConfigService: PageConfigService
     * @param htmlClassService: HtmlClassService
     * @param store
     * @param permissionsService
     */
    constructor(
        private layoutConfigService: LayoutConfigService,
        private menuConfigService: MenuConfigService,
        private pageConfigService: PageConfigService,
        private htmlClassService: HtmlClassService,
        private router: Router,
        public toolbarService:ToolbarService

    ) {
        // register configs by demos
        switch(this.router.url.split('/')[1]) {
        case 'cdc':
            this.layoutConfigService.loadConfigs(new LayoutConfig().cdcConfigs);
            break;
        case 'finance':
            this.layoutConfigService.loadConfigs(new LayoutConfig().financeConfigs);
            break;
        default:
            this.layoutConfigService.loadConfigs(new LayoutConfig().configs);
            break;
        }

        this.menuConfigService.loadConfigs(new MenuConfig().configs);
        this.pageConfigService.loadConfigs(new PageConfig().configs);

        // setup element classes
        this.htmlClassService.setConfig(this.layoutConfigService.getConfig());

        const subscr = this.layoutConfigService.onConfigUpdated$.subscribe((layoutConfig) => {
            // reset body class based on global and page level layout config, refer to html-class.service.ts
            document.body.className = '';
            this.htmlClassService.setConfig(layoutConfig);
        });
        this.unsubscribe.push(subscr);


        this.toolbarService.fullScreenObservable.subscribe(v=>{
            this.fullScreen =v;
        });
    }

    /**
     * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
     */

    /**
     * On init
     */
    ngOnInit(): void {
        const config = this.layoutConfigService.getConfig();
        this.selfLayout = objectPath.get(config, 'self.layout');
        this.asideDisplay = objectPath.get(config, 'aside.self.display');
        this.subheaderDisplay = objectPath.get(config, 'subheader.display');
        this.desktopHeaderDisplay = objectPath.get(config, 'header.self.fixed.desktop');
        this.fitTop = objectPath.get(config, 'content.fit-top');
        this.fluid = objectPath.get(config, 'content.width') === 'fluid';

        // let the layout type change
        const subscr = this.layoutConfigService.onConfigUpdated$.subscribe((cfg) => {
            // eslint-disable-next-line no-undef
            setTimeout(() => {
                this.selfLayout = objectPath.get(cfg, 'self.layout');
            });
        });
        this.unsubscribe.push(subscr);
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        this.unsubscribe.forEach((sb) => sb.unsubscribe());
    }
}
