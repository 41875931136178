<galvin-progress-spinner *ngIf="isLoading">
</galvin-progress-spinner>

<div *ngIf="!isLoading">

    <span class="special-title m-2">
        {{ 'BUILD_PLAN.PROTOTYPE_REQUEST.DIALOG.LABELS.SPECIAL_REQUIREMENT' | translate }}
    </span>
    <div class="d-flex flex-row flex-wrap align-content-start">
        <form #ngForm="ngForm">

            <ng-container *ngFor="let specialRequirement of specialRequirements; let i=index">

                <mat-form-field class="p-2" appearance="outline">

                    <mat-label matTooltip="specialRequirement?.fieldName" class="text-primary">
                        <span>
                            {{ specialRequirement?.fieldName }}
                        </span>
                    </mat-label>

                    <ng-select ngSelectMat clearAllText="Clear" [name]="specialRequirement.id + ''"
                        [ngModel]="specialRequirementsSelected[$any(specialRequirement.id)]?.id" bindValue="id"
                        bindLabel="value" [items]="specialRequirement.fieldValues || []"
                        (change)="onChangeSelect(specialRequirement, $event)" [markFirst]="true"
                        [required]="specialRequirement.required || false">
                        <ng-template ng-label-tmp ng-option-tmp let-item="item">
                            <div class="text-truncate" [ngbTooltip]="item?.value">
                                {{item.value}}
                            </div>
                        </ng-template>
                    </ng-select>
                </mat-form-field>
            </ng-container>

        </form>
    </div>
</div>