import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostListener,
    Input,
    OnDestroy,
    OnInit,
    ViewChild
} from '@angular/core';
import { MenuAsideService } from '@galvin/core/_base/layout';
import { Subscription } from 'rxjs';

@Component({
    selector: 'floating-scrollbar',
    templateUrl: './floating-scrollbar.component.html',
    styleUrls: ['./floating-scrollbar.component.scss']
})
export class FloatingScrollbarComponent implements OnInit, OnDestroy, AfterViewInit {
    private readonly PX = 'px';

    private offset!: { top: number; left: number };
    private subscription = new Subscription();

    @ViewChild('scroll') scrollRef!: ElementRef;
    @ViewChild('content') scrollContentRef!: ElementRef;

    // Element which will have the floating scroll
    @Input() element!: HTMLElement;

    constructor(private sidebarService: MenuAsideService, protected cdr: ChangeDetectorRef) {}

    get containerElement(): HTMLElement | null {
        if (this.scrollRef) {
            return this.scrollRef.nativeElement;
        }
        return null;
    }

    get scrollElement(): HTMLElement | null {
        if (this.scrollContentRef) {
            return this.scrollContentRef.nativeElement;
        }
        return null;
    }

    ngOnInit() {
        this.subscription.add(
            this.sidebarService.sidebarOpenCloseListener$.subscribe(() => {
                this.resizeScrollbar();
            })
        );
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    ngAfterViewInit() {
        this.initFloatingScrollbar();
    }

    /**
     * Resize floating scroll if window was resized
     */
    @HostListener('window:resize')
    onResize(): void {
        this.resizeScrollbar();
    }

    /**
     * Fix scrollbar position on window scroll. If the window was scrolled past element's height, floating scroll is hidden
     */
    @HostListener('window:scroll')
    onScroll(): void {
        if (this.containerElement && this.element) {
            this.offset = this.globalOffset(this.element);
            this.updateScrollbarVisibility();
            this.containerElement.scrollLeft = this.element.scrollLeft;
        }
    }

    /**
     * Init the floating scroll component
     */
    initFloatingScrollbar(): void {
        if (!this.element) {
            return;
        }

        this.offset = this.globalOffset(this.element);
        const scrollbar = this.containerElement;
        const inner = this.scrollElement;

        if (scrollbar && inner) {
            scrollbar.removeAttribute('style');
            inner.removeAttribute('style');

            this.element.style.overflowX = 'auto';
            scrollbar.style.left = this.offset.left + this.PX;
            scrollbar.style.width = this.element.getBoundingClientRect().width + this.PX;
            inner.style.height = 1 + this.PX;
            inner.style.width = this.getLargestChildWidth() + this.PX;

            this.updateScrollbarVisibility();

            scrollbar.onscroll = () => {
                this.element.scrollLeft = scrollbar.scrollLeft;
            };

            this.element.onscroll = () => {
                scrollbar.scrollLeft = this.element.scrollLeft;
            };
        }
    }

    /**
     * Get offset of given element
     *
     * @param element
     */
    private globalOffset(element: HTMLElement): { top: number; left: number } {
        const { top, left } = element.getBoundingClientRect();
        const { pageYOffset, pageXOffset } = window;

        return {
            top: top + pageYOffset,
            left: left + pageXOffset
        };
    }

    /**
     * Called whenever scrollbar needs to be resized
     */
    private resizeScrollbar(): void {
        if (this.containerElement && this.scrollElement && this.element) {
            this.offset = this.globalOffset(this.element);
            this.scrollElement.style.width = this.getLargestChildWidth() + this.PX;
            this.containerElement.style.width =
                this.element.getBoundingClientRect().width + this.PX;
            this.containerElement.style.left = this.offset.left + this.PX;
            this.cdr.detectChanges();
        }
    }

    /**
     * Updates if the floating scrollbar should be visible or not. It should only be visible if the current window
     * scroll position is within the original container's boundaries
     */
    private updateScrollbarVisibility(): void {
        const windowScrollPosition = window.scrollY + window.innerHeight;
        const isScrollPastTable =
            this.element.getBoundingClientRect().height + this.offset.top <= windowScrollPosition;
        const isScrollBeforeTable = this.offset.top > windowScrollPosition;
        if (this.containerElement) {
            this.containerElement.style.display =
                isScrollPastTable || isScrollBeforeTable ? 'none' : 'block';
        }
    }

    /**
     * Get the width of the largest child of the original element. That defines the size of the scrollbar.
     */
    private getLargestChildWidth(): number {
        let largestChildWidth = 0;
        for (let i = 0; i < this.element.children.length; i++) {
            const childWidth = this.element.children[i].getBoundingClientRect().width;
            if (largestChildWidth < childWidth) {
                largestChildWidth = childWidth;
            }
        }
        return largestChildWidth;
    }
}
