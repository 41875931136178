import { IProtoRequest } from '@galvin/views/partials/layout/proto-request-table/interfaces/proto-request.interface';
// Angular
import { Inject, Pipe, PipeTransform } from '@angular/core';
import {
    ProtoRequestTableService,
    PROTO_REQUEST_TABLE_TOKEN
} from '@galvin/views/partials/layout/proto-request-table/proto-request-table.service';

/**
 * Returns quantity from array request
 */
@Pipe({
    name: 'sumBoards'
})

export class SumBoardsPipe implements PipeTransform {
    constructor(
        @Inject(PROTO_REQUEST_TABLE_TOKEN)
        private readonly prototypeRequestService: ProtoRequestTableService<any, any>
    ) {}

    /**
     * Transform
     *
     * @param value: IProtoRequest[]
     *
     */
    transform(value: Array<IProtoRequest | any>, filteredRevisions: string[], allRevisions: any): number {
        if (Array.isArray(value)) {
            const response = this.prototypeRequestService.sumBoards(value, filteredRevisions, allRevisions);
            return response;
        }
        return 0;
    }
}
