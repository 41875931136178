import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SpecialRequirementFormComponent} from '@galvin/views/partials/layout/request-details-dialog/special-requirement-form/special-requirement-form.component';
import {GalvinProgressSpinnerModule} from '@galvin/views/partials/layout/galvin-progress-spinner/galvin-progress-spinner.module';
import {TranslateModule} from '@ngx-translate/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {NgSelectModule} from '@ng-select/ng-select';
import {NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {CoreModule} from '@galvin/core/core.module';


@NgModule({
    declarations: [
        SpecialRequirementFormComponent
    ],
    imports: [
        CommonModule,
        CoreModule,
        GalvinProgressSpinnerModule,
        FormsModule,
        ReactiveFormsModule,
        MatInputModule,
        NgSelectModule,
        NgbTooltipModule,
        TranslateModule.forChild(),
    ],
    exports: [SpecialRequirementFormComponent]
})
export class SpecialRequirementFormModule {
}
