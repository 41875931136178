// Angular
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
// Layout Directives
// Services
import {
    FilterMyPrototypeBuildPlanPipe,
    FilterMyPrototypeConfigurationPipe
} from '@galvin/core/_base/layout';
import { BuildPlanResolver } from '@galvin/core/build-plan/management/services/build-plan.resolver';
import { NotificationService } from '@galvin/core/notification/notification.service';
import {
    AbsPipe,
    ContentAnimateDirective,
    FirstLetterPipe,
    GetObjectPipe,
    GetObjectKeysPipe,
    HeaderDirective,
    InvalidFieldDirective,
    JoinPipe,
    MenuDirective,
    NgSelectFormFieldControlDirective,
    OffcanvasDirective,
    SafePipe,
    ScrollTopDirective,
    SortPipe,
    StickyDirective,
    SumRequestsPipe,
    SumPortablePipe,
    SumBoardsPipe,
    TabClickEventDirective,
    TimeElapsedPipe,
    ToggleDirective,
    VisibleToDirective
} from './_base/layout';
import { DisableControlDirective } from './_base/layout/directives/disable-control.directive';
import { NumberDirective } from './_base/layout/directives/numbers-only.directive';
import { ShowByRulesDirective } from './_base/layout/directives/show-to-rules.directive';
import { FilterMyAccessoryBuildPlanPipe } from './_base/layout/pipes/filter-my-accessory-build-plan.pipe';
import { FilterMyAccessoryPipe } from './_base/layout/pipes/filter-my-accessory.pipe';
import { TimeAgoPipe } from './_base/layout/pipes/time-ago.pipe';
import { ResourceService } from './_base/resource';
import { AccessoryFiltersPipe } from './build-plan/accessory-request-management/pipe/accessory-filters.pipe';
import { LimitColumnPipe } from './build-plan/field-value-management';
import { MentionCommentComponent } from './comments/mention-comment/mention-comment.component';
import { MentionModule } from 'angular-mentions';


import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatTreeModule } from '@angular/material/tree';
import { MatFormFieldModule } from '@angular/material/form-field';
// NgBootstrap
import {
    NgbAlertModule,
    NgbCollapseModule,
    NgbDropdownModule,
    NgbNavModule,
    NgbPopoverModule,
    NgbTooltipModule,
    NgbModule
} from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';

// Translate
import { TranslateModule } from '@ngx-translate/core';
import { TableSelectionDirective } from './_base/layout/directives/table-selection.directive';
import { TextClipboardDirective } from './_base/layout/directives/text-clipboard.directive';
import { FullviewMentionsCommentDirective } from './_base/layout/directives/fullview-mentions-comment-indicator.directive';
import { FloatActionsComponent } from './float-actions/float-actions.component';
import { EnterFocusDirective } from './_base/layout/directives/enter-focus.directive';
import { ToolbarModule } from '@galvin/views/partials/layout/toolbar/toolbar.module';
import { MatSidenavModule } from '@angular/material/sidenav';
// angular material modules
const angularMaterialModules = [
    MatButtonModule,
    MatListModule,
    MatMenuModule,
    MatSelectModule,
    MatInputModule,
    MatTableModule,
    MatAutocompleteModule,
    MatRadioModule,
    MatIconModule,
    MatNativeDateModule,
    MatProgressBarModule,
    MatDatepickerModule,
    MatCardModule,
    MatPaginatorModule,
    MatSortModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatSlideToggleModule,
    MatTabsModule,
    MatTooltipModule,
    MatDialogModule,
    MatRippleModule,
    DragDropModule,
    MatChipsModule,
    MatTreeModule,
    MatFormFieldModule,
    MatSidenavModule];


const bootstrapModules = [
    NgbPopoverModule,
    NgbAlertModule,
    NgbNavModule,
    NgbCollapseModule,
    NgbDropdownModule,
    NgbTooltipModule,
    NgSelectModule,
    NgbModule
];


@NgModule({
    imports: [
        CommonModule,
        MentionModule,
        FormsModule,
        TranslateModule.forChild(),
        ReactiveFormsModule,

        angularMaterialModules,
        bootstrapModules,
        // commonComponents,
        ToolbarModule,
        NgbPopoverModule,
    ],
    declarations: [
        // directives
        ScrollTopDirective,
        HeaderDirective,
        OffcanvasDirective,
        ToggleDirective,
        MenuDirective,
        TabClickEventDirective,
        ContentAnimateDirective,
        StickyDirective,
        VisibleToDirective,
        ShowByRulesDirective,
        InvalidFieldDirective,
        NgSelectFormFieldControlDirective,
        NumberDirective,
        TextClipboardDirective,
        FullviewMentionsCommentDirective,
        TableSelectionDirective,
        EnterFocusDirective,
        // pipes
        TimeElapsedPipe,
        JoinPipe,
        GetObjectPipe,
        GetObjectKeysPipe,
        SafePipe,
        FirstLetterPipe,
        LimitColumnPipe,
        DisableControlDirective,
        FilterMyPrototypeConfigurationPipe,
        FilterMyAccessoryPipe,
        SumRequestsPipe,
        SumPortablePipe,
        SumBoardsPipe,
        FilterMyPrototypeBuildPlanPipe,
        FilterMyAccessoryBuildPlanPipe,
        AbsPipe,
        SortPipe,
        TimeAgoPipe,
        AccessoryFiltersPipe,
        MentionCommentComponent,
        FloatActionsComponent

    ],
    exports: [
        // directives
        ScrollTopDirective,
        HeaderDirective,
        OffcanvasDirective,
        ToggleDirective,
        MenuDirective,
        TabClickEventDirective,
        ContentAnimateDirective,
        StickyDirective,
        VisibleToDirective,
        ShowByRulesDirective,
        InvalidFieldDirective,
        NgSelectFormFieldControlDirective,
        DisableControlDirective,
        NumberDirective,
        TextClipboardDirective,
        FullviewMentionsCommentDirective,
        TableSelectionDirective,
        EnterFocusDirective,
        // pipes
        TimeElapsedPipe,
        JoinPipe,
        GetObjectPipe,
        GetObjectKeysPipe,
        SafePipe,
        FirstLetterPipe,
        LimitColumnPipe,
        SumRequestsPipe,
        SumPortablePipe,
        SumBoardsPipe,
        FilterMyPrototypeConfigurationPipe,
        FilterMyAccessoryPipe,
        FilterMyPrototypeBuildPlanPipe,
        FilterMyAccessoryBuildPlanPipe,
        SortPipe,
        AbsPipe,
        TimeAgoPipe,
        AccessoryFiltersPipe,
        MentionCommentComponent,
        angularMaterialModules,
        bootstrapModules,
        // commonComponents,
        ToolbarModule,
        FormsModule,
        TranslateModule,
        ReactiveFormsModule,
        FloatActionsComponent
    ],
    providers: [
        ResourceService,
        BuildPlanResolver,
        TimeElapsedPipe,
        JoinPipe,
        GetObjectPipe,
        GetObjectKeysPipe,
        SafePipe,
        FirstLetterPipe,
        LimitColumnPipe,
        SumRequestsPipe,
        SumPortablePipe,
        SumBoardsPipe,
        FilterMyPrototypeConfigurationPipe,
        FilterMyAccessoryPipe,
        FilterMyPrototypeBuildPlanPipe,
        FilterMyAccessoryBuildPlanPipe,
        SortPipe,
        AbsPipe,
        TimeAgoPipe,
        NotificationService,
        AccessoryFiltersPipe
    ]
})
export class CoreModule {}
