import { ERolesBuildPlan } from '../../../auth/_enums/roles-build-plan.enum';
import { UserPermissionsService } from '../../../auth/services/user-permissions.service';
import { Directive,  OnInit, Input } from '@angular/core';
import {ElementRef} from '@angular/core';


@Directive({
    selector: '[showToRules]',
    exportAs: 'showToRules'
})
export class ShowByRulesDirective implements OnInit {

    constructor(private userPermissionsService: UserPermissionsService,
         private elementRef: ElementRef) {}

    @Input()
        showToRules: ERolesBuildPlan[] = [];

    @Input()
        ktTakeSpaceOff = false;

    ngOnInit() {
        if (!this.userPermissionsService.verifyPermissionsForBuildPlan(this.showToRules)) {
            this.elementRef.nativeElement.remove();
        }
    }
}
