<div [ngClass]="{ 'clean-grid-template-areas': requestPreview }" class="grid-header">
    <section class="d-flex justify-content-center align-items-center" id="sectionIconHeader">
        <div class="build-plan-selector-side galvin-icon-phone-large pt-4 pr-3 pb-4 pl-4">
        </div>
        <div [style.min-width.px]="200" [style.max-width.px]="300" class="d-flex flex-column text-default pt-3">
            <label class="d-inline-block text-truncate width-250 m-0 title-header m-0" container="galvin-dialog-generic"
                id="product-name">
                <div [ngbTooltip]="buildPlan.internalProductName" container="galvin-dialog-generic"
                    class="text-truncate">
                    {{buildPlan.internalProductName}}
                </div>
            </label>
            <label class="d-inline-block text-truncate width-250 m-0 text-default sub-title-bold-header"
                id="accessory-name">
                <div [ngbTooltip]="accessoryData.accessoryName" container="galvin-dialog-generic" class="text-truncate">
                    <b container="galvin-dialog-generic">
                        {{accessoryData.accessoryName}}
                    </b>
                </div>
            </label>
            <label class="d-inline-block text-truncate width-250 m-0 text-default" id="accessory-id">
                <b [ngbTooltip]="$any(accessoryData.accessoryId)" container="galvin-dialog-generic"
                    container="galvin-dialog-generic">
                    {{accessoryData.accessoryId}}
                </b>
            </label>
            <label class="d-inline-block text-truncate width-250 m-0 text-default sub-title-normal-header" id="vendor">
                <div class="text-truncate" container="galvin-dialog-generic"
                    [ngbTooltip]="accessoryData.vendor || getParameterValue('Vendor') | checkNull">
                    {{ accessoryData.vendor || getParameterValue('Vendor') | checkNull}}
                </div>
            </label>
        </div>
    </section>

    <section class="d-flex flex-row align-items-center section-status">
        <div class="d-flex flex-column align-items-center">
            <label class="text-top color-main-blue m-0" id="accessory-status-header">
                {{labelsAccessoryStatus.COMMON.ACCESSORY_STATUS | translate }}
            </label>
            <div [ngClass]="getStatusCssClass(accessoryData.accessoryStatus)" class="d-flex flex-row text-down m-0"
                id="accessory-status">
                <span class="material-icons"> {{getStatusIcon(accessoryData.accessoryStatus)}} </span>
                {{accessoryData.accessoryStatus}}
            </div>
        </div>
        <div class="d-flex flex-column">
            <label class="text-top color-main-blue m-0" id="accessory-type-header">
                {{labelsAccessoryStatus.COMMON.ACCESSORY_TYPE | translate }}
            </label>
            <label [attr.data-source]="accessoryData.accessoryType" [style.min-width.%]="36"
                class="text-down justify-content-center tag-prototypes m-0" id="accessory-type">
                {{accessoryData.accessoryType}}
            </label>
        </div>
        <div class="d-flex flex-column">
            <label class="text-top color-main-blue m-0" id="due-date-header">
                {{labelsAccessory.DIALOG.LABELS.DUE_DATE | translate}}
            </label>
            <label class="text-down mb-0 mr-2" id="due-date">
                <div container="galvin-dialog-generic" [ngbTooltip]="accessoryData.dueDate | parseChineseDate">
                    {{getDate()}}
                </div>
            </label>
        </div>
        <div *ngIf="requestPreview" class="d-flex mr-2">
            <button (click)="closeDialog.emit(true)" container="galvin-dialog-generic"
                [ngbTooltip]="'COMMON.CLOSE_DIALOG' | translate"
                class="btn btn-clean btn-sm btn-icon btn-icon-md dialog-close-button" container="galvin-dialog-generic"
                type="button">
                <i class="la la-times"></i>
            </button>
        </div>
    </section>
</div>