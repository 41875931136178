<div id="fullview-cells-summary" class="fullview-cells-container d-flex flex-row-reverse">
    <ng-container *ngIf="hasSelectedValues()" >
        <ng-template #optionsPopover>
            <div class="options-popover">
                <ng-container *ngFor="let option of options">
                    <button mat-button color="primary" (click)="optionChange(option)"
                        [ngClass]="{'button-selected': option === selectedOption}"
                    >
                        <span class="button-label" >
                            {{getOptionLabel(option)}}: {{getValue(option)}}
                        </span>
                    </button>
                </ng-container>
            </div>
        </ng-template>

        <button
            mat-flat-button
            id="btn-cells-summary"
            [ngbPopover]="optionsPopover"
            [value]="selectedOption"
            class="button-container"
            popoverClass="popover-fullview-cells-summary"
            placement="top"
            container="body"
        >
            <div class="button-content">
                <div id="btn-cells-summary-label" class="button-label" >
                    {{getOptionLabel(selectedOption)}}: {{getValue(selectedOption)}}
                </div>
                <div>
                    <mat-icon>
                        arrow_drop_down
                    </mat-icon>
                </div>
            </div>
        </button>
    </ng-container>
</div>
