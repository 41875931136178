import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { Component, Input, OnDestroy, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';

@Component({
    selector: 'float-actions',
    templateUrl: './float-actions.component.html',
    styleUrls: ['./float-actions.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class FloatActionsComponent implements OnDestroy{
    @ViewChild('floatActionTemplate')
    private floatActionTemplate!: TemplateRef<any>;
    private ctrlBottomSheet: MatBottomSheetRef | null = null;
    private _open = true;

    constructor(private bottomSheet: MatBottomSheet,
    ) { }


    openBottomSheet(): void {
        this.ctrlBottomSheet = this.bottomSheet.open(this.floatActionTemplate, {
            hasBackdrop: false,
            scrollStrategy: new NoopScrollStrategy()
        });
        this._open=true;

    }

    closeBottomSheet(): void {
        this.ctrlBottomSheet?.dismiss();
        this._open=false;

    }

    get open() {
        return this._open;
    }

    @Input()
    set open(open: boolean) {
        if (this._open!=open){
            if(open){
                this.openBottomSheet();
            }else{
                this.closeBottomSheet();
            }
        }
    }

    ngOnDestroy(): void {

        this.closeBottomSheet();
    }

}
