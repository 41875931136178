<!-- begin:: Header Topbar -->
<div class="kt-header__topbar">
    <div class="navbar">
        <div class="app">
            <a class="app-name" [routerLink]="'/build-plan'">{{'TOPBAR.BUILD_PLAN' | translate}}</a>
            <div class="dropdown">
               <a [routerLink]="'/build-plan/prototype-request/full-view'">
                    {{'TOPBAR.PROTOTYPE_REQUEST' | translate}}
               </a>
               <a [routerLink]="'/build-plan/prototype-request/my-prototypes'">
                    {{'TOPBAR.MY_PROTOTYPES' | translate}}
               </a>
               <a [routerLink]="getRedirectToConfiguration()">
                    {{'TOPBAR.CONFIGURATIONS' | translate}}
               </a>
               <a [routerLink]="'/build-plan/dsr'">
                    {{'TOPBAR.DSR' | translate}}
               </a>
                <a [routerLink]="'/build-plan/accessory-request/full-view'">
                    {{'TOPBAR.ACCESSORY_REQUEST' | translate}}
                </a>
                <a [routerLink]="'/build-plan/accessory-request/my-accessories'">
                    {{'TOPBAR.MY_ACCESSORIES' | translate}}
                </a>
            </div>
        </div>
        <div class="app">
            <a class="app-name" [routerLink]="'/cdc'"> {{'TOPBAR.CDC' | translate}} </a>
            <div class="dropdown">
                <a [routerLink]="'/cdc/prototype-sorting'">
                    {{'TOPBAR.PROTOTYPE_SORTING' | translate}}
                </a>
                <a [routerLink]="'/cdc/accessory-tracker'">
                    {{'TOPBAR.TRACKER' | translate}}
                </a>
                <a [routerLink]="">
                    {{'TOPBAR.CREATE_SHIPMENT' | translate}} <span [style.font-size.px]="10">(Soon)</span>
                </a>
                <a [routerLink]="">
                    {{'TOPBAR.LIST_SHIPMENT' | translate}} <span [style.font-size.px]="10">(Soon)</span>
                </a>
            </div>
        </div>
        <div class="app">
            <a class="app-name" [routerLink]="'/finance'">{{'TOPBAR.FINANCE' | translate}} </a>
            <div class="dropdown">
                <a [routerLink]="'/finance/po-quotation'">
                    {{'TOPBAR.PO_QUOTATION' | translate}}
                </a>
            </div>
        </div>
    </div>
    <!--begin: User bar -->
    <kt-notification></kt-notification>
    <kt-user-profile></kt-user-profile>
    <!--end: User bar -->
</div>
<!-- end:: Header Topbar -->

