import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input } from '@angular/core';
import { IParameterWithOneValueAndOrderOfString } from '@galvin/core/build-plan/field-value-management/fvc-parameters/interfaces/parameter-with-one-value-and-order-of-string.interface';

@Component({
    selector: 'dates-default-details',
    templateUrl: './dates-default-details.component.html',
    styleUrls: ['./dates-default-details.component.scss'],
    animations: [
        trigger('detailExpand', [
            state(
                'collapsed',
                style({ visibility: 'hidden', height: '0px', opacity: 0, overflow: 'hidden' })
            ),
            state(
                'expanded',
                style({
                    height: 'auto',
                    visibility: 'visible'
                })
            ),
            transition('expanded <=> collapsed', animate('225ms ease'))
        ])
    ]
})
export class DatesDefaultDetailsComponent {
    @Input() dates!: IParameterWithOneValueAndOrderOfString[];
    expanded = true;
    constructor() {}
}
