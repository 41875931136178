import {GenericResource} from '@galvin/core/_base/resource';
import { IAccessoryRequest } from '../../prototype-request-management/interfaces/prototype-request-with-configuration-and-team.interface';
import { IAccessoryRequestTeamPayloadRequest } from './accessory-request-team.interface';

export class AccessoryRequestManagementResource extends GenericResource<IAccessoryRequest> {
    constructor(public override content?: IAccessoryRequest) {
        super('/build-plan/accessory-team-request', content);
    }
}


export class AccessoryRequestTeamManagementResource extends GenericResource<IAccessoryRequestTeamPayloadRequest> {
    constructor(public override content?: IAccessoryRequestTeamPayloadRequest) {
        super('/build-plan/accessory-team-request', content);
    }
}


