export enum EAccessoryType {
    ACCESSORY = 'ACCESSORY',
    ACCESSORY_STATUS = 'ACCESSORY_STATUS',
    ACCESSORY_VALUE = 'ACCESSORY_VALUE',
    ACCESSORY_BUFFER_PERCENT = 'ACCESSORY_BUFFER_PERCENT'
}

export enum EAccessoryTypeValue {
    ACCESSORY = 'ACCESSORY',
    ACCESSORY_STATUS = 'ACCESSORY STATUS',
    ACCESSORY_VALUE = 'ACCESSORY PARAMETERS'
}

export type EAccessoryTypeValueKeys  = keyof typeof EAccessoryTypeValue;

export enum EAccessoryStatusLabel {
    NEW = 'New',
    OPEN = 'Open',
    LOCKED = 'Locked',
    READY_TO_DELIVER = 'Ready to Deliver',
    IN_TRANSIT = 'In Transit',
    COMPLETED = 'Completed',
    CANCELED = 'Canceled'
}

export type EAccessoryStatusLabelKeys = keyof typeof EAccessoryStatusLabel;

export enum EAccessoryStatus {
    NEW = 'NEW',
    OPEN = 'OPEN',
    LOCKED = 'LOCKED',
    READY_TO_DELIVER = 'READY_TO_DELIVER',
    IN_TRANSIT = 'IN_TRANSIT',
    COMPLETED = 'COMPLETED',
    CANCELED = 'CANCELED'
}

export enum EAccessoryTypeStatusIcon {
    NEW = 'new_releases',
    OPEN = 'brightness_1',
    LOCKED = 'lock',
    READY_TO_DELIVER = 'done_all',
    IN_TRANSIT = 'flight_takeoff',
    COMPLETED = 'check',
    CANCELED = 'cancel'
}

export type EAccessoryTypeStatusIconKeys = keyof typeof EAccessoryTypeStatusIcon;

export enum EAccessoryStatusClass {
    NEW = 'configuration-new',
    OPEN = 'build-plan-open',
    LOCKED = 'build-plan-locked',
    READY_TO_DELIVER = 'build-plan-building',
    COMPLETED = 'build-plan-completed',
    CANCELED = 'build-plan-canceled'
}

export enum EAccessoryUtilsConstants {
    ACCESSORY = 'ACCESSORY',
    ACCESSORY_STATUS = 'ACCESSORY_STATUS',
    ORDER = 'ORDER',
    NAME = 'NAME',
    ID = 'ID',
    BUFFER_PERCENT = 'BUFFER_PERCENT',
    IS_OPENED = 'IS_OPENED',
    IS_TEMPORARY = 'IS_TEMPORARY',
    TEMPORARY_DISABLE_DATE = 'TEMPORARY_DISABLE_DATE',
    ACCESSORY_TEMPORARY_DISABLE_DATE = 'ACCESSORY_TEMPORARY_DISABLE_DATE',
    ACCESSORY_STATUS_IS_TEMPORARY = 'ACCESSORY_STATUS_IS_TEMPORARY',
    ACCESSORY_STATUS_DUE_DATE = 'ACCESSORY_STATUS_DUE_DATE'
}

export enum EAccessoryTypeColors {
    CHARGER = 'CHARGER',
    RF = 'RF',
    CABLE = 'CABLE',
    PROTECTION = 'PROTECTION',
    SIM = 'SIM',
    DANGLE = 'DANGLE',
    HEADSET = 'HEADSET',
    BATTERY = 'BATTERY',
    OTHER = 'OTHER',
    PACKAGE = 'PACKAGE'
}
