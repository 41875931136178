import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class GalvinDialogGenericService {
    save = new Subject();
    save$ = this.save.asObservable();
    cancel$ = new Subject();
    close$ = new Subject();
    custom = new Subject();
    custom$ = this.custom.asObservable();
    buttonEnabled = true;
    showProgressBar = false;
}
