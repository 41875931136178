import { IRequestSpecialRequirementSimple } from './request-special-requirement.interface';
import { IGalvinUserPermission } from '@galvin/core/build-plan/permission-management';

export interface IProtoRequest {
    idTeamAssociation: number;
    quantity: number;
    configurationId: number;
    revision: number;
    configurationOrder: number;
    sectionOrder: number;
    additionalComments: string;
    comment: string;
    user: IGalvinUserPermission;
    requestDate: string;
    firstQuantity?: number;
    idTeamProtoRequest?: number;
    specialRequirementList?: IRequestSpecialRequirementSimple[];
    hasSpecialRequirement: boolean;
}
