<section class="popover-container">
    <div class="d-inline-flex align-items-center mb-2">
        <div class="d-flex align-items-center">
            <span class="lead text-wrap font-weight-bold">{{title}}</span>
        </div>
    </div>
    <br>
    <ng-container>
        <div class="section-list">
            <div *ngFor="let d of data">
                <strong>{{d.header}}: </strong> <span class="text-justify">{{d.info}}</span>
            </div>
        </div>
    </ng-container>
</section>