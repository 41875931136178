import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class NotificationComponentService {
    loadNotifications$: BehaviorSubject<boolean>;

    constructor() {
        this.loadNotifications$ = new BehaviorSubject<boolean>(false);
    }

    loadNotification() {
        this.loadNotifications$.next(true);
    }
}
