import {Injectable} from '@angular/core';
//Interfaces
import {IPermissions} from '../_interfaces/permissions.interface';
//Enums
import {ERolesBuildPlan} from '../_enums/roles-build-plan.enum';
import {User} from '../_models/user.model';

@Injectable({
    providedIn: 'root'
})
export class UserPermissionsService {
    private user?: User;
    private userRoles?: IPermissions;
    private guardValidation = false;

    public getGuardValidation(): boolean {
        return this.guardValidation;
    }

    public verifyPermissionsForBuildPlan(userRolesAllowedToComponent: ERolesBuildPlan[]): boolean {
        this.guardValidation = true;
        const permissionsBuildPlan: ERolesBuildPlan[] = this.getUserRoles()?.rolesBuildPlan || [];
        if (this.getUserRoles()?.superUser) {
            return true;
        }
        return this.validatePermissions(userRolesAllowedToComponent, permissionsBuildPlan);
    }

    public verifySuperAdminPermission(): boolean {
        if (this.getUserRoles()?.superUser) {
            return true;
        }
        return false;
    }

    private validatePermissions(
        userRolesAllowedToComponent: ERolesBuildPlan[],
        userRolesPermissions: ERolesBuildPlan[]
    ): boolean {
        if (!userRolesAllowedToComponent || !userRolesPermissions) {
            return false;
        }
        for (const allowedRole of userRolesAllowedToComponent) {
            for (const userRole of userRolesPermissions) {
                if (allowedRole === userRole) {
                    return true;
                }
            }
        }
        return false;
    }

    public getUserRoles(): IPermissions | undefined {
        return this.userRoles;
    }

    public hasRoleOrAdmin(){
        let hasRolesOrAdmin=false;
        let iPermissions = this.getUserRoles();
        if (iPermissions){
            hasRolesOrAdmin=iPermissions.rolesBuildPlan.length>0 || iPermissions.superUser;
        }
        return hasRolesOrAdmin;
    }

    public setUserRoles(userRolesBuildPlan: IPermissions): void {
        this.userRoles = userRolesBuildPlan;
    }

    public setInformationUser(user: User): void {
        this.user = user;
    }

    public getInformationUser(): User | undefined {
        return this.user;
    }

    /**
     * Given a list of allowed roles, check if those permissions are allowed for the CDC application
     *
     * @param userRolesAllowedToComponent allowed roles
     */
    public verifyPermissionsForCDC(userRolesAllowedToComponent: ERolesBuildPlan[]): boolean {
        this.guardValidation = true;
        const permissions: ERolesBuildPlan[] = this.getUserRoles()?.rolesBuildPlan || [];
        if (this.getUserRoles()?.superUser) {
            return true;
        }
        return this.validatePermissions(userRolesAllowedToComponent, permissions);
    }
}
