<button class="galvin-btn-primary btn-open-sort-table" mat-raised-button color="primary" (click)="openDialog()">
    <span class="material-icons">sort</span>
    <ng-container *ngIf="!smallMode">
        {{ 'COMMON.SORT_COLUMNS' | translate }}
    </ng-container>
</button>

<ng-template #tableSortDialog>

    <div mat-dialog-title class="d-flex justify-content-between">
        <div class="kt-portlet__head-label mw-100">
            <h3 [ngbTooltip]="title" container="galvin-dialog-generic" placement="bottom"
                class="kt-portlet__head-title dialog-generic-header-text text-primary mw-100 text-truncate"
                [innerHtml]="title"></h3>
        </div>
        <div class="kt-portlet__head-toolbar">
            <div class="kt-portlet__head-group">
                <button ngbTooltip="{{'COMMON.CLOSE_DIALOG' | translate}}" placement="bottom" id="btn-header-close"
                    container="galvin-dialog-generic" type="button" (click)="closeDialog()"
                    class="btn btn-clean btn-sm btn-icon btn-icon-md">
                    <i class="la la-times"></i>
                </button>
            </div>
        </div>
    </div>

    <ng-container>
        <form class="form-sort-columns" [formGroup]="formGroup" novalidate>
                <mat-dialog-content>
                    <mat-checkbox #checkbox class="mb-2 ml-2 default-sorting-checkbox" (change)="checkBoxChange($event)" [checked]="getCheckboxStatus">
                        {{'BUILD_PLAN.CONFIGURATION.COMMON.AUTOMATED_SORTING' | translate}}
                    </mat-checkbox>
                    <mat-icon class="info-icon" [ngbTooltip]='tooltipDefaultSort'>info</mat-icon>
                    <div formArrayName="sortGroup" *ngFor="let group of getFormData.controls; let i=index">
                        <div class="row" [formGroupName]="i">

                            <div class="col-md-4 col-sm-12 column">
                                <mat-form-field appearance="outline" class="w-100">
                                    <mat-label class="text-primary">
                                        {{ 'COMMON.COLUMNS' | translate}}
                                    </mat-label>

                                    <ng-select appendTo="body" ngSelectMat [closeOnSelect]="true" [items]="notSelectedColumns"
                                        [searchable]="false" [selectableGroupAsModel]="false"
                                        [selectableGroup]="true" bindLabel="name" formControlName="column" groupBy="group"
                                        [required]="true" (change)="updateSelectedListAndDisableCheckbox()">
                                    </ng-select>

                                    <mat-error *ngIf="column && column.hasError('required')">
                                        {{ 'GENERAL_FORM.VALIDATION.REQUIRED_FIELD' | translate }}
                                    </mat-error>
                                </mat-form-field>
                            </div>

                            <div class="col-md-4 col-sm-12 type-order">
                                <mat-form-field appearance="outline" class="w-100">

                                    <mat-label class="text-primary">
                                        {{ 'COMMON.TYPE_ORDER' | translate}}
                                    </mat-label>

                                    <ng-select appendTo="body" ngSelectMat formControlName="typeOrder" [items]="typeOrders"
                                        bindLabel="displayValue" (change)="checkbox.checked = false" [required]="true" (change)="updateSelectedListAndDisableCheckbox()">
                                    </ng-select>

                                    <mat-error *ngIf="typeOrder && typeOrder.hasError('required')">
                                        {{ 'GENERAL_FORM.VALIDATION.REQUIRED_FIELD' | translate }}
                                    </mat-error>
                                </mat-form-field>
                            </div>

                            <div class="col-md-3 col-sm-11 type-null">
                                <mat-form-field appearance="outline" class="w-100">

                                    <mat-label class="text-primary">
                                        {{ 'COMMON.TYPE_NULL' | translate}}
                                    </mat-label>

                                    <ng-select appendTo="body" ngSelectMat formControlName="typeNull" [items]="typeNulls"
                                        bindLabel="displayValue" (change)="checkbox.checked = false" [required]="true" (change)="updateSelectedListAndDisableCheckbox()">
                                    </ng-select>

                                    <mat-error *ngIf="typeNull && typeNull.hasError('required')">
                                        {{ 'GENERAL_FORM.VALIDATION.REQUIRED_FIELD' | translate }}
                                    </mat-error>
                                </mat-form-field>
                            </div>

                            <mat-icon class="delete-btn col-md-1 col-sm-1" (click)="remove(i)">delete_forever</mat-icon>

                        </div>
                    </div>

                </mat-dialog-content>

                <mat-dialog-actions class="justify-content-center">
                    <button mat-mini-fab class="justify-content-center" color="primary" (click)="addSortGroup()">
                        <mat-icon class="add-course-btn">add</mat-icon>
                    </button>
                </mat-dialog-actions >

                <mat-dialog-actions class="justify-content-end">
                    <button mat-button class="galvin-btn-danger"
                        (click)="closeDialog()">{{'COMMON.BUTTONS.CANCEL' | translate}}</button>
                    <button mat-button class="galvin-btn-success btn-sort-table" (click)="closeDialog(true)"
                        [disabled]="!formGroup.valid || disableSortButton()">{{'COMMON.BUTTONS.SORT' | translate}}</button>
                </mat-dialog-actions>
        </form>
    </ng-container>
</ng-template>

<ng-template #tooltipDefaultSort><div [innerHtml]="'BUILD_PLAN.CONFIGURATION.COMMON.AUTOMATED_SORTING_TOOLTIP' | translate"></div></ng-template>