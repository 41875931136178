<!-- begin:: Page -->
<ng-container>

    <div class="kt-grid kt-grid--hor kt-grid--root">
        <!-- begin:: Header Mobile -->
        <kt-header-mobile class="min"></kt-header-mobile>
        <!-- end:: Header Mobile -->

        <!-- begin::Body -->
        <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver">

            <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper kt-wrapper-min"
                id="kt_wrapper">
                <!-- begin:: Header -->
                <kt-header *ngIf="desktopHeaderDisplay"></kt-header>
                <!-- end:: Header -->

                <!-- begin:: Content -->
                <div class="kt-content kt-grid__item kt-grid__item--fluid kt-grid" id="kt_content">

                    <!-- begin:: Content Body -->
                    <div ktContentAnimate class="kt-container kt-grid__item kt-grid__item--fluid">
                        <banner-information></banner-information>
                        <router-outlet></router-outlet>
                    </div>
                    <!-- end:: Content Body -->

                    <!-- begin:: Chatbot -->
                    <kt-chatbot-side-panel class="kt-wrapper-chatbot-size"></kt-chatbot-side-panel>
                    <!-- end:: Chatbot -->
                </div>
                <!-- end:: Content -->

                <floating-button></floating-button>

                <kt-footer></kt-footer>

            </div>
        </div>
        <!-- end:: Body -->
    </div>
</ng-container>
<!-- end:: Page -->
