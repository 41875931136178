import { IPermissions } from '../_interfaces/permissions.interface';

export class User {
    email?: string;
    name?: string;
    lastName?: string;
    coreId?: string;
    picture?: string;
    domain?: string;
    token?: string;
    active?: boolean;
    lastLogin?: Date;
    firstAccess?: Date;
    preferences?: string;
    roles?: IPermissions;
    sub?: string;
    expirationDate?:string;
    qtRefreshToken?: number;
}
