// Angular
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// Enviroments
import { environment } from '../../../../../../environments/environment';

// Interfaces
import { EFieldType } from '../../enums/field-type.enum';
import { TDataType } from '../../enums/data-type.enum';
import { map } from 'rxjs/operators';
import { IDeletableValue } from '../../fvc-parameters/interfaces/deletable-parameter.interface';
import {
    IBpStatusAssociation,
    ISpecialRequirements
} from '../interfaces/special-requirements.interface';
import { ISpecialRequirementsValue } from '../interfaces/special-requirements-value.interface';

@Injectable({
    providedIn: 'root'
})
export class SpecialRequirementsService {
    private readonly URL = 'build-plan/field-values-management/special-requirements';
    private readonly API_URL = `${environment.baseUrlApi}/` + this.URL;

    constructor(private http: HttpClient) {}

    /**
     * Get All Data types
     */
    getDataTypes(): Observable<Array<TDataType>> {
        return this.http.get<any>(`${this.API_URL}/data-type`).pipe(map((res) => res.content));
    }

    /**
     * Get All Field types
     */
    getFieldTypes(): Observable<Array<EFieldType>> {
        return this.http.get<any>(`${this.API_URL}/field-type`).pipe(map((res) => res.content));
    }

    /**
     * Load special requirements by id
     * @param {number} id
     *
     */
    loadSpecialRequirements(id?: number): Observable<ISpecialRequirements> {
        return this.http.get<any>(`${this.API_URL}/${id}`).pipe(map((res) => res.content));
    }

    /**
     * Save a special requirements
     */
    save(value: ISpecialRequirements): Observable<ISpecialRequirements> {
        return this.http.post<any>(`${this.API_URL}`, value).pipe(map((res) => res.content));
    }

    /**
     * update a special requirements and values
     */
    update(value: ISpecialRequirements): Observable<ISpecialRequirements> {
        return this.http.put<any>(`${this.API_URL}/${value.id}`, value).pipe(map((res) => res.content));
    }

    /**
     * Get All special requirements
     */
    getAllSpecialRequirements(): Observable<ISpecialRequirements[]> {
        return this.http.get<any>(this.API_URL).pipe(map((res) => res.content));
    }

    /**
     * Get All  special requirements By Status
     */
    getAllSpecialRequirementsByStatusEnabled(): Observable<ISpecialRequirements[]> {
        return this.http.get<any>(`${this.API_URL}/enabled`).pipe(map((res) => res.content));
    }

    /**
     * Get BP Team Values Associations by team value ID
     */
    getBpAssociations(parameterId: number, valueId: number): Observable<IBpStatusAssociation[]> {
        return this.http
            .get<any>(
                `${this.API_URL}/${parameterId}/special-requirement-values/${valueId}/build-plans/special-associations`
            )
            .pipe(map((res) => res.content));
    }

    /**
     * Update a special requirements Status (Enabled/ Disabled)
     */
    updateSpecialRequirementsStatus(id: number): Observable<ISpecialRequirements> {
        return this.http.put<any>(`${this.API_URL}/${id}/status`, null).pipe(map((res) => res.content));
    }

    /**
     * Update a special requirements required status (Enabled/ Disabled)
     */
    updateRequiredStatus(id: number): Observable<ISpecialRequirements> {
        return this.http.put<any>(`${this.API_URL}/${id}/required`, null).pipe(map((res) => res.content));
    }

    /**
     * Get a list of deletable special requirements.
     */
    getDeletableSpecialRequirements(): Observable<IDeletableValue[]> {
        return this.http.get<any>(`${this.API_URL}/can-delete`).pipe(map((res) => res.content));
    }

    /**
     * Delete a special requirements by id.
     */
    delete(parameter: number): Observable<void> {
        return this.http.delete<any>(`${this.API_URL}/${parameter}`);
    }

    /**
     * Get a list of deletable values.
     * @param id id-special-requirements-value
     */
    getDeletableValues(id: number): Observable<IDeletableValue[]> {
        return this.http
            .get<any>(`${this.API_URL}/${id}/special-requirement-values/can-delete`)
            .pipe(map((res) => res.content));
    }

    /**
     * Delete a value by value id.
     * @param id
     * @param valueId
     */
    deleteValue(id: number, valueId: number): Observable<void> {
        return this.http.delete<any>(`${this.API_URL}/${id}/special-requirement-values/${valueId}`);
    }

    /**
     * Update status of value from a special requirements  (Enabled/ Disabled)
     */
    updateValueStatus(id: number, valueId: number): Observable<ISpecialRequirements> {
        return this.http
            .put<any>(`${this.API_URL}/${id}/special-requirement-values/${valueId}/status`, null)
            .pipe(map((res) => res.content));
    }

    /**
     * Update value by id parameter
     * @param id id special requirements
     * @param fieldValue body special requirements value
     */
    updateValue(id: number, fieldValue: ISpecialRequirementsValue): Observable<ISpecialRequirementsValue> {
        return this.http
            .put<any>(`${this.API_URL}/${id}/special-requirement-values`, fieldValue)
            .pipe(map((res) => res.content));
    }

    /**
     * add value by id special requirements
     */
    addValue(id: number, value: ISpecialRequirementsValue): Observable<ISpecialRequirements> {
        return this.http
            .post<any>(`${this.API_URL}/${id}/special-requirement-values`, value)
            .pipe(map((res) => res.content));
    }
}
